import React from 'react';
import { Link } from 'react-router-dom';
import ContratContentSqueleton from '../../components/contratContentSqueleton/contratContentSqueleton';
import './bailLocationNonMeublee.css';

export default function BailLocationNonMeublee() {
  const itemsContent = [
    {title: 'Le modèle de contrat location non meublée de LegalStation', link: '#contratLocationNonMeublee'},
    {title: 'Qu’est-ce qu’un contrat de location vide ?', link: '#definition'},
    {title: 'Quelles sont les règles à respecter ?', link: '#regles'},
    {title: 'Comment rédiger un contrat de location non meublée ?', link: '#redactionContrat'},
    {title: 'Quels sont les documents annexes d’un contrat de location vide ?', link: '#documentAnnexes'},
    {title: 'Quelle est la durée du contrat de location ?', link: '#dureeContrat'},
    {title: 'FAQ', link: '#faq'},
  ];
  return (
    <ContratContentSqueleton
      title='Bail de location non meublée'
      subTitle='Formulaire de contrat de location non meublée (maison et appartement)'
      titleLeftContent=''
      textButton='Remplir mon bail de location non meublée'
      titleContent='A propos du contrat de location non meublée'
      rightContent={<BailLocationNonMeubleeContent />}
      itemsContent={itemsContent}
    />
  );
}

function BailLocationNonMeubleeContent() {
  return (
    <div className='bailLocationNonMeubleeContent'>
      <h3 id='contratLocationNonMeublee'>Le modèle de contrat location non meublée de LegalStation</h3>
  
      <p>Rédiger un <Link className='linkContent' to='#'>contrat de location</Link> non meublée est nécessaire pour louer un logement vide. LegalPlace met à votre disposition un modèle de contrat de location non meublée.</p>
      <p>Notre modèle de contrat vous permet ainsi de <strong>gagner du temps</strong> et de bénéficier d’un document conforme à la loi. Pour cela, il vous suffit de compléter le questionnaire en ligne.</p>
      <p>Vous avez également la possibilité de télécharger directement le formulaire au <strong>format Word ou PDF</strong> puis de le compléter.</p>
      
      <h3 id='definition'>Qu’est-ce qu’un contrat de location vide ?</h3>

      <p>La location d’un logement nécessite l’établissement d’un bail d’habitation. Le propriétaire du logement doit donc respecter certaines règles spécifiques.</p>
      <p>Le contrat de location non meublée doit être rédigé en plusieurs exemplaires pour chacune des parties (propriétaire, locataire, caution).</p>
      <p>Par ailleurs, le propriétaire et le locataire doivent respecter des obligations réciproques. En effet, le propriétaire s’engage à mettre à disposition du locataire un logement décent pendant une période définie. Quant au locataire, il s’engage à payer tous les mois un loyer et des charges.</p>
      <p className='infoContent'><strong>Bon à savoir :</strong> Les locations saisonnières, celles à des fins de résidence secondaire, certains types de colocations ainsi que certaines locations HLM sont exclues du champ de ces dispositions impératives.</p>
      
      <h3 id='regles'>Quelles sont les règles à respecter ?</h3>

      <p>Lors de la rédaction du contrat de location non meublée, le propriétaire doit veiller à respecter certaines règles.</p>
      <h6>Les caractéristiques principales</h6>
      <p>Tout d’abord, le logement mis en location doit être décent. Cela signifie que le logement doit répondre à plusieurs critères :</p>
      <ul>
        <li>Surface minimale habitable ;</li>
        <li>Absence de risque pour la sécurité et la santé du locataire ;</li>
        <li>Absence d’animaux nuisibles et de parasites ;</li>
        <li>Performance énergétique minimale ;</li>
        <li>Mise à disposition d’équipements.</li>
      </ul>
      <p>Par ailleurs, la surface minimum d’un logement à louer est de <strong>9m2</strong> (avec une hauteur sous plafond d’au mois 2,20m) soit un volume habitable d’au moins <strong>20m3</strong>.</p>
      <p>De plus, le logement doit prévoir certains éléments d’équipement tels que des installations sanitaires, une cuisine, l’accès à l’eau potable, un système de chauffage fonctionnel, etc.</p>
      <h6>Les conditions de forme</h6>
      <p>Le propriétaire du logement vide doit nécessairement rédiger un contrat de location par écrit. Le bail peut être rédigé sous la forme d’un acte sous signature privée ou d’un acte authentique.</p>
      <p>Le contrat de location doit être rédigé en autant d’exemplaires qu’il y a de parties (propriétaire, locataire, caution).</p>
      <p className='infoContent'><strong>Bon à savoir :</strong> Le propriétaire doit insérer dans le contrat de location une clause résolutoire qui autorise la résiliation du bail en cas de non-paiement du loyer ou des charges.</p>

      <h3 id='redactionContrat'>Comment rédiger un contrat de location non meublée ?</h3>

      <p>Pour que le contrat de bail soit valable, il doit être conforme à la loi du 6 juillet 1989 qui établit le cadre juridique des locations vides.</p>
      <h6>Les clauses obligatoires</h6>
      <p>Le contrat de bail doit comporter certaines clauses obligatoires :</p>
      <ul>
        <li>Identité du bailleur et du locataire ;</li>
        <li>Date de prise d’effet du contrat ;</li>
        <li>Durée du contrat ;</li>
        <li>Montant du loyer ;</li>
        <li>Loyer de référence, incluant les <Link className='linkContent' to='#'>loyers de référence majoré</Link> et <Link className='linkContent' to='#'>minoré</Link> ;</li>
        <li>Surface habitable et consistance du logement ;</li>
        <li>Dépôt de garantie ;</li>
        <li>Modalités de résiliation du contrat ;</li>
        <li><Link className='linkContent' to='#'>Modalités de révision du loyer</Link>.</li>
      </ul>
      <h6>Les obligations des parties</h6>
      <p>Le contrat de bail doit mentionner les obligations de chacune des parties. D’une part, le propriétaire s’engage à fournir au locataire un logement décent et d’autre part, le locataire s’engage à payer le loyer et les charges.</p>
      <h6>Les informations sur le logement</h6>
      <p>Par ailleurs, le contrat de bail doit contenir les informations relatives au logement telles que la surface habitable, la description du logement et de ses équipements à usage privatif et commun, la nature et le montant des travaux effectués depuis le dernier bail.</p>
      <p className='infoContentRed'><strong>Attention :</strong> Si la surface habitable du logement n’est pas expressément mentionnée, le locataire peut intenter une action en diminution de loyer.</p>
      <h6>Les informations sur le loyer, les charges et le dépôt de garantie</h6>
      <p>En outre, le propriétaire doit mentionner le <strong>montant du loyer et des charges à payer</strong> par le locataire. Il doit également mentionner les modalités de révision et de paiement du loyer.</p>
      <p>Le contrat de location doit également prévoir minimale de location qui est en principe de <strong>3 ans</strong> pour les logements vides. Toutefois, cette durée peut être <strong>réduite à 1 an</strong> pour des raisons familiales ou professionnelles.</p>
      <p className='infoContent'><strong>Bon à savoir :</strong> En cas de renouvellement tacite du bail initial, les mêmes conditions s’appliquent.</p>
      <p>Par ailleurs, le contrat de location doit prévoir les <strong>modalités de résiliation du bail</strong>, du préavis pour donner congé et le <Link className='linkContent' to='#'>dépôt de garantie</Link>.</p>
      <h6>Les clauses supplémentaires</h6>
      <p>Le contrat de peut également prévoir certaines <strong>clauses non obligatoires</strong> peuvent être ajoutées au contrat. C’est notamment le cas des clauses résolutoires, des clauses de révision annuelles du loyer ou encore des clauses de solidarité.</p>
      <table>
        <tr>
          <td>Résolutoire</td>
          <td>Permet au bailleur de résilier le contrat en cas de non-respect de la clause par le locataire</td>
          <td>Non-paiement du loyer ; absence d’assurance habitation ; non versement du dépôt de garantie ; trouble du voisinage par le locataire</td>
        </tr>
        <tr>
          <td>Révision annuelle du loyer</td>
          <td>Permet au bailleur d’augmenter le montant du loyer en cours de bail</td>
          <td>Possibilité d’augmenter le loyer en se basant sur l’indice de référence des loyers publiés par l’INSEE</td>
        </tr>
        <tr>
          <td>Solidarité des locataires</td>
          <td>Rend chacun des locataires d’un logement responsable du paiement et des charges</td>
          <td>Permet au bailleur de solliciter le locataire le plus solvable pour payer les éventuels retards de loyers et ce même si le locataire en question a déjà payé sa part</td>
        </tr>
      </table>
      <p>En plus de ces clauses, le bailleur peut demander au locataire de payer, en plus du loyer, une <strong>provision mensuelle pour les charges</strong>. Le montant de cette provision se base sur le budget prévisionnel annuel. Une fois par an, il procède à la régularisation de ces charges. Cependant, si le logement est en colocation, le bailleur peut désormais proposer un montant forfaitaire de charges.</p>
      <h6>Les clauses abusives</h6>
      <p>Il existe une série de clauses inapplicables, même si le locataire a donné son consentement.</p>
      <p><strong>Sont donc considérées comme abusives</strong> les clauses qui prévoient :</p>
      <ul>
        <li>L’interdiction au locataire d’héberger des invités, d’utiliser le logement pour pratiquer une activité associative, politique ou syndicale ou encore de détenir un animal domestique ;</li>
        <li>Imposant au locataire la souscription d’un contrat d’assurance habitation choisi par le bailleur ;</li>
        <li>L’obligation d’un prélèvement automatique ou de billets à ordre comme seul mode de paiement du loyer ;</li>
        <li>La facturation de l’envoi des quittances de loyer ;</li>
        <li>L’obligation pour le locataire de verser un montant supplémentaire pour l’entrée dans les lieux autre que le dépôt de garantie convenu initialement entre les parties ;</li>
        <li>L’allocation au bailleur des indemnités en cas de non-respect des clauses du bail ;</li>
        <li>L’autorisation pour le propriétaire de modifier les prestations convenues dans le bail initial sans contrepartie pour le locataire ;</li>
        <li>Prévoir la possibilité de prélever le loyer directement sur le salaire du locataire ;</li>
        <li>D’imposer une responsabilité automatique du locataire pour les dégradations des locaux loués ;</li>
        <li>D’instaurer des clauses de résolution non prévues par la loi, ou des clauses de renouvellement/reconduction du bail pour une durée inférieure à 3 ans.</li>
      </ul>
      
      <h3 id='documentAnnexes'>Quels sont les documents annexes d’un contrat de location vide ?</h3>

      <p>Le bailleur doit joindre au contrat de location vide plusieurs <Link className='linkContent' to='#'>annexes obligatoires</Link>, incluant :</p>
      <ul>
        <li>Un <Link className='linkContent' to='#'>état des lieux</Link> d’entrée ;</li>
        <li>Une notice d’information en application de l’arrêté du 29 mai 2015 visant à informer les parties sur les droits et les obligations qui leur incombent ;</li>
        <li>Le cas échéant, un exemplaire du règlement de copropriété ;</li>
        <li>Un dossier de diagnostics techniques établi en amont de la mise en location du logement.</li>
      </ul>
      <p>De plus, l’<Link className='linkContent' to='#'>assurance PNO</Link> est fortement recommandée car elle peut s’avérer très utile.</p>
      <p className='infoContentBlue'><strong>A noter :</strong> Certains documents supplémentaires peuvent être demandés en cas de situation particulière.</p>

      <h3 id='dureeContrat'>Quelle est la durée du contrat de location ?</h3>

      <p>La durée du contrat de location d’un bien non meublé est strictement encadrée par la loi.</p>
      <h6>La durée de conclusion du contrat</h6>
      <p>Le bail doit être conclu pour une <Link className='linkContent' to='#'>durée minimum</Link> de :</p>
      <ul>
        <li>3 ans lorsque le propriétaire est un particulier ;</li>
        <li>6 ans quand le propriétaire est une personne morale (par exemple une société, une association).</li>
      </ul>
      <p>Toutefois, une durée inférieure à 3 ans (ou 6 ans) <strong>peut être convenue, mais elle ne peut être inférieure à 1 an</strong>, si le propriétaire envisage de reprendre le logement pour des raisons familiales ou professionnelles. Par exemple, si le propriétaire prend sa retraite et doit récupérer son logement.</p>
      <p>Le motif de reprise justifiant cette durée plus courte doit obligatoirement être mentionné dans le contrat de location.</p>
      <p>Le propriétaire doit confirmer au locataire, <strong>au moins 2 mois avant la fin du contrat</strong>, que l’événement s’est produit et qu’il reprend le logement à la date prévue. Le propriétaire doit envoyer cette confirmation par lettre recommandée avec accusé de réception.</p>
      <p>Si l’événement ne s’est pas produit ou n’a pas été confirmé, le contrat doit être prolongé jusqu’au délai de 3 ans.</p>
      <h6>Le renouvellement du contrat</h6>
      <p>À la fin du bail, et en l’absence de <Link className='linkContent' to='#'>préavis (congé) donné par le propriétaire</Link> ou <Link className='linkContent' to='#'>le locataire</Link>, le bail est :</p>
      <ul>
        <li>Soit renouvelé sur proposition du propriétaire,</li>
        <li>Soit reconduit automatiquement.</li>
      </ul>
      <p>Comme pour la durée initiale, le contrat de bail est reconduit pour trois ans, si le propriétaire est un particulier, ou six ans si le propriétaire est une personne morale (société, compagnie d’assurance).</p>
      <p>Dans ce cas, il <strong>n’est pas nécessaire de faire un nouveau contrat</strong> : la reconduction de la location se fait aux conditions du bail initial.</p>

      <h3 id='faq'>FAQ</h3>
      
      <div className='faq'>
        <h5>Est-il obligatoire de signer un bail de location ?</h5>
        <p>C&apos;est la loi du 6 juillet 1989 qui réglemente la location de logement. Et selon cette loi, la signature d&apos;un bail écrit et signé par les deux parties (locataire et propriétaire) est nécessaire. Le fait de s&apos;exonérer de cette obligation ne permet pas au bailleur d&apos;être libre de gérer cette location comme il l&apos;entend.</p>
      </div>
      <div className='faq'>
        <h5>Quel est le délai légal du préavis pour une location vide ?</h5>
        <p>Le préavis est de 3 mois et aucun motif n&apos;est nécessaire pour justifier votre départ. Si vous quittez le logement et rendez les clés avant la fin du préavis, la durée du préavis peut être réduite proportionnellement si le propriétaire accepte un nouveau locataire ou s&apos;il réalise des travaux de rénovation importants.</p>
      </div>
      <div className='faq'>
        <h5>Est-ce qu&apos;un propriétaire peut casser un bail ?</h5>
        <p>Le propriétaire peut donner congé (son préavis) au locataire pour vendre le logement, ou pour le reprendre (pour y habiter ou pour loger un proche), ou pour un motif légitime et sérieux (notamment en cas de faute du locataire). Il doit respecter des conditions de forme et de délais.</p>
      </div>
    </div>
  );
}
