import React from 'react';
import { Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';

Font.register({
  family: 'abeezee',
  src: 'http://fonts.gstatic.com/s/abeezee/v9/JYPhMn-3Xw-JGuyB-fEdNA.ttf',
});

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    fontFamily: 'abeezee',
  },
  section: {
    marginBottom: 30,
  },
  heading: {
    fontSize: 20,
    fontWeight: 900,
    marginBottom: 20,
    textAlign: 'center',
  },
  subheading: {
    fontSize: 16,
    fontWeight: 900,
    marginBottom: 35,
  },
  paragraph: {
    marginBottom: 5,
    textAlign: 'justify',
    lineHeight: 1.5,
  },
  subparagraph: {
    marginBottom: 35,
    textAlign: 'center',
    lineHeight: 1,
    fontSize: 8,
  },
  listItem: {
    marginLeft: 25,
    lineHeight: 1.5,
    marginBottom: 10,
  },
  sublistItem: {
    marginLeft: 25,
    lineHeight: 1,
    marginBottom: 2.5,
  },
  italicSouligneGray: {
    textDecoration: 'underline',
    color: 'gray',
  },
  laws: {
    color: '#666',
    fontSize: 10,
  },
});

function Page1(props) {
  const {
    chooseDomaineSite,
    // ------------------------------
    vendeur,
    numéroTéléphoneVendeur,
    adresseEmailVendeur,
    isNuméroTVAVendeur,
    numéroTVAVendeur,
    // morale
    formeSocialeVendeur,
    nomSociétéVendeur,
    montantCapitalVendeur,
    villeImmatriculation_RCSVendeur,
    numéroSIRENVendeur,
    adresseSiègeSocialVendeur,
    // physique
    nomVendeur,
    adresseDomiciliationVendeur,
    numéroInscriptionRCS_RépertoireMétiers,
    // ------------------------------
    territoires,
    territoiresList,
    produitProposés,
    dateMiseVigueurCGV,
  } = props;
  return (
    <Page size="A4" style={styles.page}>
      <Text style={styles.heading}>CONDITIONS GÉNÉRALES DE VENTE (CGV)</Text>
      <Text style={styles.subparagraph}>
        En vigueur au {dateMiseVigueurCGV || '________________'}
      </Text>
      <View style={styles.section}>
        {/* 1. Champ d'application et législation */}
        <Text style={styles.subheading}>
          ARTICLE 1 - Champ d&apos;application
        </Text>
        <Text style={styles.paragraph}>
          Les présentes Conditions Générales de Vente (dites « CGV ») s&apos;
          appliquent, sans restriction ni réserve à l&apos;ensemble des ventes
          conclues par le Vendeur auprès d&apos;acheteurs non professionnels («
          Les Clients ou le Client »), désirant acquérir les produits proposés à
          la vente (« Les Produits ») par le Vendeur sur le site{' '}
          {chooseDomaineSite || '________________'}. Les Produits proposés à la
          vente sur le site sont les suivants :
        </Text>
        <Text style={styles.paragraph}>{produitProposés || '________________'}</Text>
        <Text style={styles.paragraph}>
          Les caractéristiques principales des Produits et notamment les
          spécifications, illustrations et indications de dimensions ou de
          capacité des Produits, sont présentées sur le site{' '}
          {chooseDomaineSite || '________________'} ce dont le client est tenu
          de prendre connaissance avant de commander.
        </Text>
        <Text style={styles.paragraph}>
          Le choix et l&apos;achat d&apos;un Produit sont de la seule
          responsabilité du Client.
        </Text>
        <Text style={styles.paragraph}>
          Les offres de Produits s&apos;entendent dans la limite des stocks
          disponibles, tels que précisés lors de la passation de la commande.
        </Text>
        <Text style={styles.paragraph}>
          Ces CGV sont accessibles à tout moment sur le site{' '}
          {chooseDomaineSite || '________________'} et prévaudront sur toute
          autre document.
        </Text>
        <Text style={styles.paragraph}>
          Le Client déclare avoir pris connaissance des présentes CGV et les
          avoir acceptées en cochant la case prévue à cet effet avant la mise en
          œuvre de la procédure de commande en ligne du site{' '}
          {chooseDomaineSite || '________________'}.
        </Text>
        <Text style={styles.paragraph}>
          Sauf preuve contraire, les données enregistrées dans le système
          informatique du Vendeur constituent la preuve de l&apos;ensemble des
          transactions conclues avec le Client.
        </Text>
        <Text style={styles.paragraph}>
          Les coordonnées du Vendeur sont les suivantes :
        </Text>
        {vendeur === 'morale' ? (
          <>
            <Text style={styles.paragraph}>
              {nomSociétéVendeur || '________________'},{' '}
              {formeSocialeVendeur || '________________'}
            </Text>
            <Text style={styles.paragraph}>
              Capital social de {montantCapitalVendeur || '________________'}{' '}
              MAD
            </Text>
            <Text style={styles.paragraph}>
              Immatriculé au RC de{' '}
              {villeImmatriculation_RCSVendeur || '________________'}, sous le
              numéro {numéroSIRENVendeur || '________________'}
            </Text>
            <Text style={styles.paragraph}>
              {adresseSiègeSocialVendeur || '________________'}
            </Text>
            <Text style={styles.paragraph}>
              Email : {adresseEmailVendeur || '________________'}
            </Text>
            <Text style={styles.paragraph}>
              Téléphone : {numéroTéléphoneVendeur || '________________'}
            </Text>
            {isNuméroTVAVendeur && (
              <Text style={styles.paragraph}>
                Numéro de TVA intracommunautaire :{' '}
                {numéroTVAVendeur || '________________'}
              </Text>
            )}
          </>
        ) : (
          <>
            <Text style={styles.paragraph}>
              {nomVendeur || '________________'}
            </Text>
            <Text style={styles.paragraph}>
              {adresseDomiciliationVendeur || '________________'}
            </Text>
            <Text style={styles.paragraph}>
              Numéro d&apos;immatriculation
              {numéroInscriptionRCS_RépertoireMétiers || '________________'}
            </Text>
            <Text style={styles.paragraph}>
              Email : {adresseEmailVendeur || '________________'}
            </Text>
            <Text style={styles.paragraph}>
              Téléphone : {numéroTéléphoneVendeur || '________________'}
            </Text>
            {isNuméroTVAVendeur && (
              <Text style={styles.paragraph}>
                Numéro de TVA intracommunautaire :{' '}
                {numéroTVAVendeur || '________________'}
              </Text>
            )}
          </>
        )}
        {territoires && (
          <>
            <Text style={styles.paragraph}>
              Les Produits présentés sur le site{' '}
              {chooseDomaineSite || '________________'} sont proposés à la vente
              pour les territoires suivants :
            </Text>
            <Text style={styles.paragraph}>
              {territoiresList || '________________'}
            </Text>
            <Text style={styles.paragraph}>
              En cas de commande vers un pays autre que le Maroc
              métropolitaine, le Client est l&apos;importateur du ou des Produits
              concernés.
            </Text>
            <Text style={styles.paragraph}>
              Pour tous les Produits expédiés hors Union européenne et DOM-TOM,
              le prix sera calculé hors taxes automatiquement sur la facture.
            </Text>
            <Text style={styles.paragraph}>
              Des droits de douane ou autres taxes locales ou droits
              d&apos;importation ou taxes d&apos;état sont susceptibles d&apos;être exigibles.
              Ils seront à la charge et relèvent de la seule responsabilité du
              Client.
            </Text>
          </>
        )}
      </View>
    </Page>
  );
}

export default Page1;
