import React from 'react';
import { Link } from 'react-router-dom';
import ContratContentSqueleton from '../../components/contratContentSqueleton/contratContentSqueleton';
import './cdi.css';

export default function Cdi() {
  const itemsContent = [
    {title: 'Pour qui ? Pourquoi ?', link: '#pourQui'},
    {title: 'Durée indéterminée – Période d’essai', link: '#duree'},
    {title: 'Durée de travail', link: '#dureeTravail'},
    {title: 'Forme du CDI', link: '#forme'},
    {title: 'Contenu du CDI', link: '#contenu'},
    {title: 'Fin du CDI', link: '#fin'},
    {title: 'Spécificité des particuliers employeur : a-t-on l’obligation de conclure un contrat de travail écrit ?', link: '#specificite'},
    {title: 'Particulier employeur : quelles sont les dispositions spécifiques du CDI ?', link: '#particulier'},
  ];
  return (
    <ContratContentSqueleton
      title='Contrat de travail à durée indéterminée CDI'
      subTitle='Modèle de contrat de travail CDI'
      link={'form'}
      titleLeftContent='Contrat de travail à durée indéterminée CDI'
      textButton='Remplir mon contrat de travail CDI'
      titleContent='A propos du contrat de travail à durée indéterminée'
      rightContent={<CdiContent />}
      itemsContent={itemsContent}
    />
  );
}

function CdiContent() {
  return (
    <div className='cdi'>
      <h3 id='pourQui'>Pour qui ? Pourquoi ?</h3>
      <p>
        Le contrat de travail à durée indéterminée (CDI) constitue le contrat de droit commun applicable
        <strong>  entre un salarié et l’entreprise ou le particulier qui l’emploie dans le cadre d’une 
        relation de travail.</strong> Toute autre forme de 
        <Link className='linkContent' to='#'> contrat de travail </Link> ne peut être conclue que dans 
        des cas d’exception.
      </p>
      <p>L’employeur peut être :</p>
      <p><strong> – toute personne morale (société, association) ou entreprise individuelle ;</strong></p>
      <p>
        <strong> – mais également un particulier</strong> qui a recours à un salarié employé à son domicile 
        ou dans l’une de ses résidences (voir dans ce cas les spécificités du contrat ci-après).
      </p>
      <p>
        Toute personne physique a le droit d’être salarié. Il existe toutefois des restrictions 
        applicables aux mineurs et aux étrangers (qui doivent être autoriser à travailler en France).
      </p>
      <h3 id='duree'>Durée indéterminée – Période d’essai</h3>
      <p>
        Le CDI est par essence à durée indéterminée : il est donc conclu sans limitation de durée.
        Il doit être distingué du <Link className='linkContent' to='#'> contrat à durée déterminée </Link> 
        et de <Link className='linkContent' to='#'> l’avenant au contrat de travail </Link>.
      </p>
      <p>
        Il peut toutefois prévoir une période d’essai au terme de laquelle les parties pourront mettre 
        fin au contrat sans justification particulière et sans indemnité.
      </p>
      <h3 id='dureeTravail'>Durée de travail</h3>
      <p>Le CDI peut être conclu à temps plein ou à temps partiel.</p>
      <p>
        Le contrat de travail à temps plein sera conclu en principe sur la base d’un temps 
        de travail hebdomadaire de 35 heures. Certaines fonctions telle que celle de cadre 
        dirigeant peuvent toutefois justifier de déroger aux 35 heures hebdomadaires. De la même manière, 
        certaines conventions collectives autorisent les parties à prévoir des forfaits jours ou heures 
        au titre permettant une plus grande liberté dans l’organisation de l’emploi du temps du salarié.
        le salarié peut faire des heures supplémentaires au delà de son contingent annuel d’heures au 
        titre de son contrant de travail, tout en ayant droit a un 
        <Link className='linkContent' to='#'> repos compensateur </Link>. Il est à souligner que le temps 
        de travail applicable au stagiaire est soumis aux mêmes règles de celles applicables aux salariés 
        de l’entreprise.
      </p>
      <p>
        <strong>NB : </strong>pour la loi qui encadre les stages, il existe quelques nouvelles 
        règles qui ont changé complètement la donne.
      </p>
      <h3 id='forme'>Forme du CDI</h3>
      <p>
        <strong> Pour les entreprises</strong>, le CDI, lorsqu’il est à temps plein, 
        peut ne pas être conclu par écrit. C’est la raison pour laquelle beaucoup de relations de 
        travail sont requalifiées en CDI. Le CDI à temps plein naît ainsi de la relation effective 
        entre les deux parties qui sera qualifiée de relation entre un salarié et son employeur si 
        les conditions du CDI son réunies.
      </p>
      <p>
        Il est toutefois fortement recommandé de conclure un écrit. En effet,
        certaines conventions collectives imposent que le CDI fasse l’objet d’un écrit.
      </p>
      <p>Enfin, le CDI à temps partiel devra nécessairement être écrit.</p>
      <p>
        Le CDI devra être conclu en langue française. Lorsque les fonctions sont indiquées dans 
        une langue étrangère (très souvent en Anglais) n’ayant pas d’équivalent en Français, alors 
        le CDI devra comporter une définition des termes concernés.
      </p>
      <p>
        <strong> Concernant les employeurs particuliers</strong>, le chèque emploi-service peut être 
        utilisé pour des prestations de travail occasionnelles dont la durée hebdomadaire n’excède 
        pas 8 heures ou pour une durée dans l’année d’un mois non renouvelable. Pour ces emplois, 
        le chèque emploi-service tient lieu de contrat de travail. En revanche, quel que soit 
        le type de contrat, l’obligation d’écrire un écrit est impérative à partir du moment où 
        la durée du contrat excède 8 heures par semaine ou 4 semaines consécutives sur l’année.
      </p>
      <h3 id='contenu'>Contenu du CDI</h3>
      <p>
        Les termes du CDI sont librement négociés entre l’employeur et le salarié. 
        Ils pourront ainsi prévoir des clauses particulières telles que 
        <Link className='linkContent' to='#'> la clause de non-concurrence avec toutes ses conditions 
        de validité </Link>, la clause d’exclusivité, la clause de mobilité ou encore la 
        <Link className='linkContent' to='#'> clause de dédit-formation </Link>  ou une 
        <Link className='linkContent' to='#'> clause de hardship </Link>.
      </p>
      <p>
        Le contrat devra toutefois respecter les dispositions légales (prévues par le Code du travail) 
        et celles de la convention collective applicable, qu’elles s’appliquent au CDI à temps plein ou 
        à d’autres formes de CDI tel que le CDI à temps partiel.
      </p>
      <p>
        Enfin, certaines clauses contraires à l’ordre public seront expressément interdites 
        (notamment les clauses discriminatoires, les clauses stipulant une rémunération inférieure 
        au minimum légal, etc.).
      </p>
      <h3 id='fin'>Fin du CDI</h3>
      <p>
        Le CDI étant à durée indéterminée, il ne peut y être mis un terme que par la volonté (ou le décès)
        de l’une des parties ou des deux parties.
      </p>
      <p>
        Le CDI peut donc prendre fin à l’initiative du salarié, par sa démission ou son départ volontaire 
        à la retraite, ou à l’initiative de l’employeur par un licenciement ou une mise à la retraite 
        d’office.
      </p>
      <p>
        Il peut également être terminé d’un commun accord entre le salarié et l’employeur, au titre d’une 
        <Link className='linkContent' to='#'> rupture conventionnelle </Link>.
      </p>
      <p>
        Lorsque il y’a une <Link className='linkContent' to='#'> rupture du CDI </Link> à l’initiative 
        de l’employeur, le salarié a droit, dans la majeure partie des cas, à des indemnité dont 
        la nature et les montants varient selon la forme et la cause du licenciement, ainsi que l’ancienneté 
        du salarié (et, le cas échéant, toute autre clause particulière du CDI). La salarié peut également 
        continuer de profiter de son plan d’épargne entreprise (<Link className='linkContent' to='#'> PEE </Link>) 
        s’il en possède un.
      </p>
      <h3 id='specificite'>
        Spécificité des particuliers employeur : a-t-on l’obligation de conclure un contrat de travail écrit ?
      </h3>
      <p>
        Le chèque emploi-service fait office de contrat de travail dans certains cas. Ainsi, la rédaction 
        d’un contrat de travail n’est pas obligatoire si la durée hebdomadaire de travail du salarié 
        ne dépasse pas 8 heures par semaine ou moins de 4 semaines consécutives par an.
      </p>
      <p>
        L’obligation de rédiger un contrat écrit est donc obligatoire à partir du moment où la durée 
        du contrat dépasse 8 heures par semaine ou 4 semaines consécutives sur l’année.
      </p>
      <h3 id='particulier'>Particulier employeur : quelles sont les dispositions spécifiques du CDI ?</h3>
      <p>La loi prévoit des exclusions spécifiques concernant le particulier employeur.</p>
      <p>
        Ainsi, <strong> les dispositions légales sur la durée du travail ne sont pas applicables aux 
        employés de maison</strong> qui exercent leur métier au domicile de leur employeur. 
        Ces derniers relèvent de la convention collective nationale (CCN) des salariés du particulier 
        employeur.
      </p>
      <p>
        Selon les dispositions conventionnelles, le contrat de travail est à temps plein ou, 
        s’il est conclu pour une durée inférieure à 40 heures par semaine, à temps partiel.
      </p>
      <p>
        <strong> Les heures de présence responsable</strong> ne concernent que les emplois de garde d’enfant. 
        Ces heures de présence sont celles où le salarié peut utiliser son temps pour lui-même. 
        Il doit toutefois rester vigilant pour intervenir s’il y a lieu 
        (lors de la sieste d’un enfant par exemple).
      </p>
      <p>Les heures de présence responsables sont rémunérées aux 2/3 du salaire conventionnel de base.</p>
      <p>
        <strong> En cas de garde partagée</strong> (une nounou embauchée par plusieurs familles pour 
        la garde en commun de leurs enfants), un contrat de travail écrit est établi avec le salarié 
        par chaque famille d’employeur.
      </p>
      <p>La durée du travail s’entend du total des heures effectuées au domicile de l’une et de l’autre.</p>
      <p>Chaque famille rémunère les heures effectuées à son domicile.</p>
      <p>La date des congés est fixée par les deux employeurs d’un commun accord.</p>
      <p>Le contrat inclut une clause identique précisant le lien avec l’autre famille employeur.</p>
      <p>
        La rupture de l’un des contrats constitue une modification substantielle de l’autre : 
        dans ce cas, la famille qui n’a pas rompu le contrat, doit proposer au salarié soit de continuer 
        la relation contractuelle avec elle, soit d’augmenter la durée du temps de travail. 
        Le salarié doit alors bénéficier d’un délai de réflexion suffisant, et son refus ne peut 
        en aucun cas être fautif (et donnera lieu à une rupture de contrat non disciplinaire assortie 
        des indemnités légales de rupture).
      </p>
      <p>La médecine du travail à la charge des deux employeurs.</p>
    </div>
  );
}

