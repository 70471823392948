import React from 'react';
import bilanComyableIcon from '../../../../../../../assets/icons/bilanComptableIcon.png';
import declarationTvaIcon from '../../../../../../../assets/icons/declarationTvaIcon.png';
import optimisationFiscalIcon from '../../../../../../../assets/icons/optimisationFiscalIcon.png';
import conseilIllimiteIcon from '../../../../../../../assets/icons/conseilIllimiteIcon.png';
// import './avantageComptabilite.css';

function AvantageComptabiliteItem({ icon, title, description }) {
  return (
    <div className=' flex flex-col justify-center items-center gap-4'>
      <img src={icon} />
      <h6 >{title}</h6>
      <p className='w-[250px] lg:w-[200px] text-balance text-justify'>{description}</p>
    </div>
  );
}

export default function AvantageComptabilite() {
  const items = [
    {
      icon: bilanComyableIcon,
      title: 'Bilan comptable',
      description: 'Simplifiez-vous la vie en déléguant la réalisation de votre bilan comptable à ' 
        + 'votre expert-comptable de confiance : vous pouvez ainsi vous concentrer sur votre activité '
        + 'en toute sérénité.',
    },
    {
      icon: declarationTvaIcon,
      title: 'Déclaration de TVA',
      description: 'Évitez les pénalités de retard et les tracas administratifs en confiant votre '
        + 'conformité fiscale à un expert-comptable qualifié.',
    },
    {
      icon: optimisationFiscalIcon,
      title: 'Optimisation fiscale',
      description: 'Vous avez des projets ambitieux mais vous manquez de financement ? Votre comptable '
        + 'peut vous aider à optimiser votre bilan pour obtenir les fonds dont vous avez besoin.',
    },
    {
      icon: conseilIllimiteIcon,
      title: 'Conseils illimités',
      description: 'Vous cherchez un partenaire de confiance pour vous accompagner dans la gestion de '
        + 'votre entreprise ? Notre équipe de comptables qualifiés est là pour vous aider à chaque étape '
        + 'et répondre à toutes vos questions.',
    },
  ];
  return (
    <section className='p-5 md:px-[120px]'>
      <div className='flex flex-col justify-center items-center gap-20'>
        <h1 className='text-center text-2xl lg:text-3xl text-darkblue font-bold w-[300px] lg:w-[500px]'>Découvrez les avantages incroyables que vous pouvez obtenir avec notre service !</h1>
        <div className='flex items-start justify-center flex-wrap gap-10'>
          {items.map((item) => (
            <AvantageComptabiliteItem
              key={item.title}
              icon={item.icon}
              title={item.title}
              description={item.description}
            />
          ))}

        </div>
      </div>
    </section>
  );
}
