// /* eslint-disable */
import React from 'react';
import { Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';

Font.register({
  family: 'abeezee',
  src: 'http://fonts.gstatic.com/s/abeezee/v9/JYPhMn-3Xw-JGuyB-fEdNA.ttf',
});

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    fontFamily: 'abeezee',
  },
  section: {
    marginBottom: 30,
  },
  heading: {
    fontSize: 20,
    fontWeight: 900,
    marginBottom: 20,
    textAlign: 'center',
  },
  subheading: {
    fontSize: 16,
    fontWeight: 900,
    marginBottom: 35,
  },
  paragraph: {
    marginBottom: 10,
    textAlign: 'justify',
    lineHeight: 1.5,
  },
  subparagraph: {
    marginBottom: 35,
    textAlign: 'center',
    lineHeight: 1,
    fontSize: 8,
  },
  listItem: {
    marginLeft: 25,
    lineHeight: 1.5,
    marginBottom: 10,
  },
  sublistItem: {
    marginLeft: 25,
    lineHeight: 1,
    marginBottom: 2.5,
  },
  italicSouligneGray: {
    textDecoration: 'underline',
    color: 'gray',
  },
  laws: {
    color: '#666',
    fontSize: 10,
  },
});

function Page3(props) {
  const {
    chooseDomaineSite,
    isClientCompte,
    clientCompteEmail,
    modesPaiementPossibles,
    isPaiementVirementPossible,
    momentPaiement,
    isEchéancierSansFrais,
    EchéancierSansFrais,
    isExigiblePassationCommande,
    pourcentagePrix,
    joursAprèsCommande,
    Echéancier,
  } = props;

  const getQuestion = () => {
    switch (momentPaiement) {
    case 'paiementImmediat':
      return (
        <>
          <Text style={styles.paragraph}>
            Le prix est payable comptant par le Client, en totalité au jour de
            la pasation de la commande.
          </Text>
        </>
      );
    case 'paiementExpedition':
      return (
        <>
          <Text style={styles.paragraph}>
            Pour le paiement par carte bancaire, le débit de la carte n&apos;est
            effectué qu&apos;au moment de l&apos;expédition de la commande.
          </Text>
        </>
      );
    case 'paiementLivraison':
      return (
        <>
          <Text style={styles.paragraph}>
            Pour le paiement par carte bancaire, le débit de la carte n&apos;est
            effectué qu&apos;au moment de la livraison des Produits.
          </Text>
        </>
      );
    case 'paiementALaLivraison':
      return (
        <>
          {isExigiblePassationCommande ? (
            <>
              <Text style={styles.paragraph}>
                Le prix est payable comptant, en totalité au jour de la
                livraison des Produits, intervenant selon les conditions
                définies à l&apos;article « Livraisons » et comme indiqué sur la
                facture remise au Client.
              </Text>
              <Text style={styles.paragraph}>
                Un acompte correspondant à{' '}
                {pourcentagePrix || '________________'} % du prix total
                d&apos;acquisition des Produits commandés est exigé lors de la
                passation de la commande par le Client.
              </Text>
              <Text style={styles.paragraph}>
                Cet acompte ne pourra en aucun cas être qualifié d&apos;arrhes.
              </Text>
              <Text style={styles.paragraph}>
                Le solde du prix est payable à la livraison des Produits,
                intervenant selon les conditions définies à l&apos;article «
                Livraisons » et comme indiqué sur la facture remise au Client.
              </Text>
            </>
          ) : (
            <>
              <Text style={styles.paragraph}>
                Le prix est payable comptant, en totalité au jour de la
                livraison des Produits, intervenant selon les conditions
                définies à l&apos;article « Livraisons » et comme indiqué sur la
                facture remise au Client.
              </Text>
            </>
          )}
        </>
      );
    case 'paiementTermine':
      return (
        <>
          {isExigiblePassationCommande ? (
            <>
              <Text style={styles.paragraph}>
                Le prix est payable en totalité et en un seul versement dans
                un délai de {joursAprèsCommande || '_______________'}
                jours à compter de la livraison, telle que définie à l&apos;article
                « Livraisons » et comme précisé sur la facture adressée au
                Client.
              </Text>
              <Text style={styles.paragraph}>
                Un acompte correspondant à{' '}
                {pourcentagePrix || '________________'}% du prix total
                d&apos;acquisition des Produits commandés est exigé lors de la
                passation de la commande par le Client.
              </Text>
              <Text style={styles.paragraph}>
                Cet acompte ne pourra en aucun cas être qualifié d&apos;arrhes.
              </Text>
              <Text style={styles.paragraph}>
                Le solde du prix est payable au terme du délai de paiement
                indiqué ci-avant et comme indiqué sur la facture remise au
                Client.
              </Text>
            </>
          ) : (
            <>
              <Text style={styles.paragraph}>
                Le prix est payable en totalité et en un seul versement dans
                un délai de {joursAprèsCommande || '_______________'} jours à
                compter de la livraison, telle que définie à l&apos;article «
                Livraisons » et comme précisé sur la facture adressée au
                Client.
              </Text>
            </>
          )}
        </>
      );
    case 'paiementEcheancier':
      return (
        <>
          <Text style={styles.paragraph}>
            Le prix est payable selon les conditions et l&apos;échéancier suivants:
          </Text>
          <Text style={styles.paragraph}>
            {Echéancier || '___________________'}.
          </Text>
          <Text style={styles.paragraph}>
            En cas de retard de paiement et de versement des sommes dues par
            le Client au-delà des délais ci-dessus fixés, et après la date de
            paiement figurant sur la facture adressée à celui-ci, des
            pénalités de retard calculées au taux légal applicable au montant
            TTC du prix d&apos;acquisition figurant sur ladite facture, seront
            acquises automatiquement et de plein droit au Vendeur, sans
            formalité aucune ni mise en demeure préalable.
          </Text>
          <Text style={styles.paragraph}>
            Le retard de paiement entraînera l&apos;exigibilité immédiate de
            l&apos;intégralité des sommes dues par le Client, sans préjudice de
            toute autre action que le Vendeur serait en droit d&apos;intenter, à ce
            titre, à l&apos;encontre du Client.
          </Text>
          <Text style={styles.paragraph}>
            En outre, le Vendeur se réserve le droit, en cas de non respect
            des conditions de paiement figurant ci-dessus, de suspendre ou
            d&apos;annuler la livraison des commandes en cours effectuées par le
            Client.
          </Text>
        </>
      );
    default:
      return <></>;
    }
  };

  return (
    <Page size="A4" style={styles.page}>
      {/* ARTICLE 3 Bis - Espace client - Compte */}
      {isClientCompte && (
        <View style={styles.section}>
          <Text style={styles.subheading}>
            ARTICLE 3 Bis - Espace client - Compte
          </Text>
          <Text style={styles.paragraph}>
            Afin de passer commande, le Client est invité à créer un compte
            (espace personnel).
          </Text>
          <Text style={styles.paragraph}>
            Pour ce faire, il doit s’inscrire en remplissant le formulaire qui
            lui sera proposé au moment de sa commande et s’engage à fournir des
            informations sincères et exactes concernant son état civil et ses
            coordonnées, notamment son adresse email.
          </Text>
          <Text style={styles.paragraph}>
            Le Client est responsable de la mise à jour des informations
            fournies. Il lui est précisé qu’il peut les modifier en se
            connectant à son compte.
          </Text>
          <Text style={styles.paragraph}>
            Pour accéder à son espace personnel et aux historiques de commande,
            le Client devra s&apos;identifier à l&apos;aide de son nom
            d’utilisateur et de son mot de passe qui lui seront communiqués
            après son inscription et qui sont strictement personnels. A ce
            titre, le Client s’en interdit toute divulgation. Dans le cas
            contraire, il restera seul responsable de l’usage qui en sera fait.
          </Text>
          <Text style={styles.paragraph}>
            Le Client pourra également solliciter sa désinscription en se
            rendant à la page dédiée sur son espace personnel ou envoyant un
            email à :{clientCompteEmail || '________________'}. Celle-ci sera
            effective dans un délai raisonnable.
          </Text>
          <Text style={styles.paragraph}>
            En cas de non respect des conditions générales de vente et/ou
            d’utilisation, le site {chooseDomaineSite || '________________'}{' '}
            aura la possibilité de suspendre voire de fermer le compte d’un
            client après mise en demeure adressée par voie électronique et
            restée sans effet.
          </Text>
          <Text style={styles.paragraph}>
            Toute suppression de compte, quel qu’en soit le motif, engendre la
            suppression pure et simple de toutes informations personnelles du
            Client. sous réserve des obligations légales de conservation des
            données.
          </Text>
          <Text style={styles.paragraph}>
            Tout événement dû à un cas de force majeure ayant pour conséquence
            un dysfonctionnement du site ou serveur et sous réserve de toute
            interruption ou modification en cas de maintenance, n&apos;engage pas
            la responsabilité du Vendeur. conformément aux dispositions de la
            législation marocaine.
          </Text>
          <Text style={styles.paragraph}>
            La création du compte entraine l’acceptation des présentes
            conditions générales de vente.
          </Text>
        </View>
      )}
      <View style={styles.section}>
        {/* ARTICLE 4 - Conditions de paiement */}
        <Text style={styles.subheading}>
          ARTICLE 4 - Conditions de paiement
        </Text>
        <Text style={styles.paragraph}>
          Le prix est payé par voie de paiement sécurisé, selon les modalités
          suivantes :
        </Text>
        {modesPaiementPossibles === 'Cartes_bancaires' ? (
          <>
            <Text style={styles.listItem}>- paiement par carte bancaire</Text>
          </>
        ) : (
          <>
            <Text style={styles.listItem}>- paiement par carte bancaire</Text>
            <Text style={styles.listItem}>- ou paiement par chèque</Text>
          </>
        )}
        {isPaiementVirementPossible && (
          <Text style={styles.listItem}>
            ou paiement par virement bancaire sur le compte bancaire du Vendeur
            (dont les coordonnées sont communiquées au Client lors de la
            passation de la commande)
          </Text>
        )}
        {getQuestion()}
        {isEchéancierSansFrais && (
          <>
            {momentPaiement !== 'paiementEcheancier' && (
              <>
                <Text style={styles.paragraph}>
                  Toutefois, le Client pourra, lorsque cette possibilité est
                  indiquée sur le site
                  {chooseDomaineSite || '________________'}, payer selon les
                  conditions et l&apos;échéancier suivants :
                </Text>
                <Text style={styles.paragraph}>
                  {EchéancierSansFrais || '________________'}
                </Text>
                <Text style={styles.paragraph}>
                  Dans ce cas, en cas de retard de paiement et de versement des
                  sommes dues par le Client au-delà des délais ci-dessus fixés,
                  et après la date de paiement figurant sur la facture adressée
                  à celui-ci, des pénalités de retard calculées au taux légal
                  applicable au montant TTC du prix d&apos;acquisition figurant sur
                  ladite facture, seront acquises automatiquement et de plein
                  droit au Vendeur, sans formalité aucune ni mise en demeure
                  préalable.
                </Text>
                <Text style={styles.paragraph}>
                  Le retard de paiement entraînera l&apos;exigibilité immédiate de
                  l&apos;intégralité des sommes dues par le Client, sans préjudice de
                  toute autre action que le Vendeur serait en droit d&apos;intenter,
                  à ce titre, à l&apos;encontre du Client.
                </Text>
                <Text style={styles.paragraph}>
                  En outre, le Vendeur se réserve le droit, en cas de non
                  respect des conditions de paiement figurant ci-dessus, de
                  suspendre ou d&apos;annuler la livraison des commandes en cours
                  effectuées par le Client.
                </Text>
              </>
            )}
          </>
        )}
        {modesPaiementPossibles === 'Cartes_bancaires&chèques' && (
          <>
            <Text style={styles.listItem}>
              En cas de paiement par chèque bancaire, celui-ci doit être émis
              par une banque domiciliée en Maroc.
            </Text>
            <Text style={styles.listItem}>
              La mise à l&apos;encaissement du chèque est réalisée à réception.
            </Text>
          </>
        )}
        <Text style={styles.paragraph}>
          Les données de paiement sont échangées en mode crypté grâce au
          protocole défini par le prestataire de paiement agréé intervenant pour
          les transactions bancaires réalisée sur le site{' '}
          {chooseDomaineSite || '________________'}.
        </Text>
        <Text style={styles.paragraph}>
          Les paiements effectués par le Client ne seront considérés comme
          définitifs qu&apos;après encaissement effectif par le Vendeur des
          sommes dues.
        </Text>
        <Text style={styles.paragraph}>
          Le Vendeur ne sera pas tenu de procéder à la délivrance des Produits
          commandés par le Client si celui-ci ne lui en paye pas le prix en
          totalité dans les conditions ci-dessus indiquées.
        </Text>
      </View>
    </Page>
  );
}

export default Page3;
