import React from 'react';
import google from '../../../../../assets/images/google.png';
import rating from '../../../../../assets/images/rating.png';

function Rateandgoogle() {
  return (
    <div className='flex items-center justify-between mb-4 lg:mb-20  w-1/2 md:w-1/3 mx-auto '>
      <div><img className=' lg:w-[150px]' src={google} /></div>
      <div><img className='lg:w-[150px]' src={rating} /></div>
    </div>
  );
}

export default Rateandgoogle;