import React from 'react';
import DoneIcon from '@mui/icons-material/Done';
import { Button } from '@mui/material';
import './pack.css';

function PackBlock({ title, price, items }) {
  return (
    <div className='packBlock'>
      <div>
        <h3>{title}</h3>
        <h3>{price}</h3>
        {items.map((item, index) =>
          <li className='itemPackBlock' key={index}>
            <DoneIcon className='icon' style={{ fontSize: 15 }} />
            {item}
          </li>
        )}
      </div>
      <div className='packButton'>
        <Button className='button'>Devis</Button>
      </div>
    </div>
  );
}


export default function Pack() {
  return (
    <section className='packContainer'>
      <div className='pack'>
        <h1>Choisissez le Pack pour créer votre entreprise</h1>
        <p>
          Nous avons plusieurs plans puissants pour mettre en valeur votre entreprise et vous 
          faire découvrir en tant qu’entrepreneur créatif. Tout ce dont vous avez besoin.
        </p>
        <div className='packBlockContainer'>
          <PackBlock
            title='Basique'
            price='3 400,00 Dhs'
            items={[
              'Certificat Négatif',
              'L’enregistrement des statuts',
              'Inscription à la Patente',
              'Registre de Commerce',
              'Identifiant Fiscal',
              'Affiliation à la CNSS',
              'Annonce Légale',
              '12 Mois Domiciliation dans une adresse prestigieuse',
              'Model J',
              'Cachet',
              'Accompagnement à l’ouverture de compte bancaire',
            ]}
          />
          <PackBlock
            title='Pro'
            price='12 000,00 Dhs'
            items={[
              'Certificat Négatif',
              'L’enregistrement des statuts',
              'Inscription à la Patente',
              'Registre de Commerce',
              'Identifiant Fiscal',
              'Affiliation à la CNSS',
              'Annonce Légale',
              '12 Mois Domiciliation dans une adresse prestigieuse',
              'Model J',
              'Logo',
              '1 000 Carte visite',
            ]}
          />
          <PackBlock
            title='Gold'
            price='15 000,00 Dhs'
            items={[
              'Certificat Négatif',
              'L’enregistrement des statuts',
              'Inscription à la Patente',
              'Registre de Commerce',
              'Identifiant Fiscal',
              'Affiliation à la CNSS',
              'Annonce Légale',
              '12 Mois Domiciliation dans une adresse prestigieuse',
              'Model J',
              'Cachet',
              'Accompagnement à l’ouverture de compte bancaire',
              'Logo',
            ]}
          />
        </div>
      </div>
    </section>
  );
}
