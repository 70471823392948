import React from 'react';
import './infoCreationEntreprise.css';

export default function InfoCreationEntreprise() {
  return (
    <section className='infoCreationEntrepriseContainer'>
      <div className='infoCreationEntreprise'>
        <div className='bloc'>
          <p className='number'>1</p>
          <div>
            <h1>1- Choisir la forme juridique</h1>
            <p>
              La loi marocaine donne la possibilité de choisir entre plusieurs formes juridiques pour 
              votre entreprise :
            </p>
            <p>
              Choisir la forme juridique de votre entreprise est une décision importante, 
              beaucoup de conséquences en découlent, surtout en matière de :
            </p>
            <ul>
              <li>Responsabilité sur les dettes de l’entreprise ;</li>
              <li>Mode d’imposition ;</li>
              <li>Liberté de cession des parts sociales (ou actions) ;</li>
              <li>Gestion de l’entreprise ;</li>
              <li>Prise de décision au niveau de l’assemblée générale (AG) ;</li>
              <li>Contrôle de la société ;.</li>
            </ul>
          </div>
        </div>
        <div className='bloc'>
          <p className='number'>2</p>
          <div>
            <h1>2- Obtenir l’attestation de dénomination commerciale (Certificat négatif)</h1>
            <p>
              La dénomination commerciale est un document qui atteste que la dénomination, 
              sigle ou enseigne demandé peut être utilisé pour l’immatriculation au Registre du Commerce. 
              Le certificat négatif est délivré par l’OMPIC pour une durée d’un an. 
              Si vous immatriculez l’entreprise, la dénomination devient sa propriété jusqu’à sa radiation 
              du Registre de Commerce. Si vous n’immatriculez pas votre entreprise pendant cette durée, 
              la dénomination, sigle ou enseigne redeviendra disponible pour d’autres personnes.
            </p>
          </div>
        </div>
        <div className='bloc'>
          <p className='number'>3</p>
          <div>
            <h1>3- Établir le contrat de bail ou l’attestation de domiciliation le cas échéant</h1>
            <p>
              La dénomination commerciale est un document qui atteste que la dénomination, 
              sigle ou enseigne demandé peut être utilisé pour l’immatriculation au Registre du Commerce. 
              Le certificat négatif est délivré par l’OMPIC pour une durée d’un an. 
              Si vous immatriculez l’entreprise, la dénomination devient sa propriété jusqu’à sa radiation 
              du Registre de Commerce. Si vous n’immatriculez pas votre entreprise pendant cette durée, 
              la dénomination, sigle ou enseigne redeviendra disponible pour d’autres personnes.
            </p>
          </div>
        </div>
        <div className='bloc'>
          <p className='number'>4</p>
          <div>
            <h1>4- Établir les statuts</h1>
            <p>
              C’est l’acte fondateur de la société. Les statuts se présentent un peu comme un contrat 
              qui définit les règles de fonctionnement d’une société. Ils régissent les relations :
            </p>
            <ul>
              <li>Entre les associés ;</li>
              <li>Entre les associés et la société ;</li>
              <li>Entre la société et les tiers.</li>
            </ul>
          </div>
        </div>
        <div className='bloc'>
          <p className='number'>5</p>
          <div>
            <h1>5- Assemblée Générale Constitutive et Déclaration de conformité</h1>
            <p>L’Assemblée Générale Constitutive est une réunion des associés, qui a pour ordre du jour :</p>
            <ul>
              <li>Étude et approbation des statuts ;</li>
              <li>
                Vérification et approbation de régularité des démarches administratives entreprises pour 
                la constitution de la société ;
              </li>
              <li>Quitus aux fondateurs ;</li>
              <li>Nomination du (es) gérant (s) ;</li>
              <li>Discussion des divers points relatifs à l’activité de la société.</li>
              <li>
                La Déclaration de conformité atteste la conformité des information contenues dans 
                les pièces de création (Statut, Contrat de bail et PV de l’Assemblée Générale)
              </li>
            </ul>
          </div>
        </div>
        <div className='bloc'>
          <p className='number'>6</p>
          <div>
            <h1>6- Accomplir les formalités d’enregistrement</h1>
            <p>
              Les statuts, le contrat de bail et les PV de l’Assemblée générale le cas échéant, 
              doivent être enregistrés au niveau du Service d’Enregistrement et de Timbre concerné.
            </p>
          </div>
        </div>
        <div className='bloc'>
          <p className='number'>7</p>
          <div>
            <h1>7- Déposer le dossier de création</h1>
            <p>les démarches de création :</p>
            <ul>
              <li>L’enregistrement des statuts, contrat de bail et PVs.</li>
              <li>L’inscription à la Taxe Professionnelle (Ex Patente)</li>
              <li>L’obtention de l’Identifiant Fiscal</li>
              <li>L’immatriculation au Registre de Commerce</li>
              <li>L’affiliation à la CNSS</li>
              <li>La publication au Bulletin officiel et au journal d’annonces légales</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
