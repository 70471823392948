// /* eslint-disable */
import React from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 12,
  },
  section: {
    marginBottom: 20,
  },
  heading: {
    fontSize: 17,
    fontWeight: 800,
    marginBottom: 17,
    textAlign: 'center',
  },
  subheading: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  paragraph: {
    marginBottom: 10,
    textAlign: 'justify',
    lineHeight: 1.5,
  },
  listItem: {
    marginLeft: 15,
    lineHeight: 1.5,
    marginBottom: 10,
  },
  sublistItem: {
    marginLeft: 25,
    lineHeight: 1.5,
    marginBottom: 5,
  },
  Signature: {
    display: 'flex',
    flexWrap: 'no-wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    gap: 10,
  },
  subSignature: {
    display: 'flex',
    flexWrap: 'no-wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 10,
  },
});

function Page1(props) {
  const {
    // F1
    bailleurSociete,
    bailleurCapital,
    bailleurVille,
    bailleurRepresentant,
    bailleurCin,
    // F2
    locataireSociete,
    locataireCapital,
    locataireSiegeSocial,
    locataireRepresentant,
    locataireQualite,
    locataireCin,
    // F3
    dateDebutBail,
    localUsage,
    localTitreFoncier,
    localSuperficie,
    localActivites,
    // F4
    localDescription,
    localRemiseClesDate,
    // F5
    dureeBail,
    preavisResiliation,
  } = props;

  return (
    <Page size="A4" style={styles.page}>
      <Text style={styles.heading}>CONTRAT DE BAIL</Text>
      {/* Entre les soussignés */}
      <View style={styles.section}>
        <Text style={styles.paragraph}>Entre les soussignés :</Text>
        <Text style={styles.paragraph}>
          1 – La société {bailleurSociete || '..........................'}, au capital de{' '}
          {bailleurCapital || '..........................'} dhs, sise à
          {bailleurVille || '..........................'}. Représentée par{' '}
          {bailleurRepresentant || '..........................'}. Titulaire de la CIN n°
          {bailleurCin || '..........................'}
        </Text>
        <Text style={styles.paragraph}>D’une part.</Text>
        <Text style={styles.paragraph}>
          2 – La société {locataireSociete || '..........................'}, au capital
          de {locataireCapital || '..........................'} dhs, dont le siège social
          est à {locataireSiegeSocial || '..........................'}, ci-après désignée
          par « le locataire » et représentée par{' '}
          {locataireRepresentant || '..........................'} en qualité de{' '}
          {locataireQualite || '..........................'} Titulaire de la CIN n°
          {locataireCin || '..........................'}
        </Text>
        <Text style={styles.paragraph}>D’autre part.</Text>
      </View>
      {/* Article premier : Objet du bail et description des locaux */}
      <View style={styles.section}>
        <Text style={styles.subheading}>
          Article premier : Objet du bail et description des locaux.
        </Text>
        <Text style={styles.paragraph}>
          La société {bailleurSociete || '..........................'} représentée par{' '}
          {bailleurRepresentant || '..........................'}, donne en location par
          la présente à la société à responsabilité limitée{' '}
          {locataireSociete || '..........................'} à compter du{' '}
          {dateDebutBail || '..........................'} un local à usage commercial,
          situés {localUsage || '..........................'},{' '}
          {bailleurVille || '..........................'}, et faisant l’objet des titres
          fonciers n° {localTitreFoncier || '..........................'} d’une
          superficie de {localSuperficie || '..........................'} m².
        </Text>
        <Text style={styles.paragraph}>
          Ledit bail est consenti par le bailleur et accepté par le locataire
          pour l’exercice des activités {localActivites || '..........................'}.
          Les parties conviennent d’un commun accord que l’exercice de toute
          autre activité devra faire l’objet d’un consentement par écrit du
          propriétaire.
        </Text>
      </View>
      {/* Description des locaux. */}
      <View style={styles.section}>
        <Text style={styles.subheading}>Description des locaux.</Text>
        <Text style={styles.paragraph}>
          Lesdits locaux existent ; s’étendent et se poursuivent sans exception,
          ni réserve, et sans qu’il soit besoin d’en faire une plus ample
          désignation, le locataire déclarant les biens connaître pour les avoir
          visités en vue du présent bail.
        </Text>
        <Text style={styles.paragraph}>
          Le local objet du titre foncier n°{' '}
          {localTitreFoncier || '..........................'} étant aménagé et agencé
          sous forme de : {localDescription || '..........................'}. Les parties
          conviennent qu’un état des lieux sera dressé et signé à la date prévue
          pour la remise des clés, prévue le{' '}
          {localRemiseClesDate || '..........................'}.
        </Text>
      </View>
      {/* Article 2 : Durée 3/6/9 */}
      <View style={styles.section}>
        <Text style={styles.subheading}>Article 2 : Durée 3/6/9</Text>
        <Text style={styles.paragraph}>
          Le présent bail est consenti et accepté pour une durée de{' '}
          {dureeBail || '..........................'} ans à compter du{' '}
          {dateDebutBail || '..........................'}, renouvelable par tacite
          reconduction, pour une période équivalente, avec obligation pour la
          partie qui voudrait résilier le présent bail, de prévenir l’autre
          partie par lettre recommandée avec accusé de réception au moins{' '}
          {preavisResiliation || '..........................'} mois à l’avance.
        </Text>
      </View>
      {/* Article 3 : Conditions */}
      <View style={styles.section}>
        <Text style={styles.subheading}>Article 3 : Conditions</Text>
        <Text style={styles.paragraph}>
          Le présent bail est consenti sous les charges et conditions ordinaires
          et notamment sous celles suivantes que le locataire s’oblige
          conjointement et solidairement à exécuter et à accomplir, savoir :
        </Text>
        <Text style={styles.listItem}>
          1. Le locataire ne pourra faire dans les locaux loués aucune
          construction, ni démolition, aucun percement des murs, cloison ou
          planchers, ni aucun changement de distribution sans le consentement
          express et écrit au bailleur.
        </Text>
        <Text style={styles.listItem}>
          2. Tous travaux, embellissements et décors, quels qu’ils soient, ainsi
          que toute canalisation d’eau ou d’électricité qui seraient faits par
          le locataire, resteront en fin de bail, de quelque manière et à
          quelque époque qu’elle arrive, la propriétaire du bailleur, sans
          indemnité, et devront être remis en bon état d’entretien en fin de
          jouissance.
        </Text>
        <Text style={styles.listItem}>
          3. Le locataire devra faire assurer son matériel, son mobilier contre
          l’incendie, toutes explosion, bris de glace et tous sinistres dus à
          l’électricité, au gaz, ou autres causes, auprès d’une compagnie
          notoirement solvable, et justifier des primes à toutes réquisition du
          bailleur
        </Text>
        <Text style={styles.listItem}>
          4. Le locataire devra acquitter tous les impôts, taxes et charges
          auxquels il pourrait être assujetti, et notamment l’impôt des patentes
        </Text>
        <Text style={styles.listItem}>
          5. Le locataire fera son affaire personnelle de l’évaluation de la
          puissance de l’électricité dont il aura besoin pour l’installation de
          ses appareils. A cet effet, il fera le nécessaire auprès des services
          compétents de la compagnie de distribution de l’électricité de{' '}
          {bailleurVille || '..........................'} pour obtenir le reliquat de
          puissance manquante nécessaire à son installation, et pour régler
          toutes les taxes exigées par ladite compagnie.
        </Text>
        <Text style={styles.listItem}>
          6. Le présent bail n’est pas divisible ; le locataire ne pourra en
          aucun cas prétendre à la division du présent bail ni procéder à une
          sous location.
        </Text>
        <Text style={styles.listItem}>
          7. Le locataire s’engage à occuper les locaux loués ainsi que les
          parties communes, en se comportant en bon père de famille. Il s’engage
          notamment à réparer toute détérioration dont il sera responsable.
        </Text>
        <Text style={styles.listItem}>
          8. Le locataire s’interdit d’exercer une activité incompatible avec la
          notion de bureau, ou causant un quelconque préjudice aux autres
          colocataires. Il s’interdit notamment d’utiliser les locaux loués pour
          y installer une école d’enseignement quelle qu’elle soit, ou pour en
          faire un lieu d’habitation ou de dépôt.
        </Text>
        <View style={styles.paragraph}>
          <Text style={styles.listItem}>9. Le locataire s’engage à :</Text>
          <Text style={styles.sublistItem}>
            - Ne pas domicilier d’autres sociétés dans les locaux loués.
          </Text>
          <Text style={styles.sublistItem}>
            - Ne pas nantir le fond de commerce.
          </Text>
          <Text style={styles.sublistItem}>
            - Ne pas céder le droit au bail.
          </Text>
        </View>
      </View>
    </Page>
  );
}

export default Page1;
