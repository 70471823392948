import React from 'react';
// import './vousCherchezPartenaire.css';

export default function VousCherchezPartenaire() {
  return (
    <section className='p-10 lg:p-[90px]'>
      <div className='w-full md:w-[700px] mx-auto'>
        <h1 className='text-center text-2xl lg:text-3xl lg:text-start font-bold mb-4'>
          Vous cherchez un partenaire fiable pour vous accompagner dans la création et la gestion de votre 
          entreprise ?
        </h1>
        <h3 className='text-center text-xl lg:text-2xl lg:text-start font-medium mb-4'>LegalStation est là pour vous offrir une solution complète et adaptée à vos besoins.</h3>
        <div className='flex items-center h-[250px] gap-10'>
          <div className='w-full text-center lg:text-left lg:w-1/2 flex justify-between flex-col gap-5 mt-4'>
            <p>Évitez les sanctions fiscales grâce à un comptable dédié : découvrez nos services chez LegalStation</p>
            <p>Gérez votre comptabilité sans stress grâce à notre logiciel accessible à tous</p>
            <p>
              Des conseils sur-mesure pour vous guider vers la réussite : faites confiance à notre équipe 
              chez LegalStation
            </p>
          </div>
          <div className='bg-lightredv3 h-full w-1/2 rounded-md hidden md:block' />
        </div>
      </div>
    </section>
  );
}
