import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './headerTitles.css';

export default function HeaderTitles() {
  const navigate = useNavigate();

  const navigateToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <section className='headerTitlesContainer'>
      <div className='headerTitles'>
        <p className='title t1'>Création d&apos;entreprise , Domiciliation et Comptabilité.</p>
        <p className='title t2'>La procédure efficace et rapide pour lancer votre business</p>
        <p className='title t3'>
          Avec Legalstation, créer votre entreprise de A à Z y compris la domiciliation, la tenue de la comptabilité le conseil et l’accompagnement
        </p>
        <Button className='button' variant="contained" size='large' onClick={navigateToDashboard}>Click here</Button>
      </div>
    </section>
  );
}