import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import axios from 'axios';
import StepProgress from '../../../../components/stepProgress/stepProgress';
import HeaderContainer from '../../../../components/headerContainer/headerContainer';
import CustomTextInput from '../../../../components/inputs/customTextInput/customTextInput';
import getEnvironement from '../../../../environnement';
import number from '../../../../assets/icons/number.png';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, userSelector } from '../../../../store/userSlice';
import Packs from '../../packs';

export default function CarteGriseForm() {
  const [currentStep, setCurrentStep] = useState(1);

  // personne
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [dateNaissance, setDateNaissance] = useState('');
  const [nationalite, setNationalite] = useState('');
  const [cni, setCni] = useState('');
  const [adresse, setAdresse] = useState('');
  const [tel, setTel] = useState('');
  const [email, setEmail] = useState('');

  // véhicule
  const [marqueVehicule, setMarqueVehicule] = useState('');
  const [modeleVehicule, setModeleVehicule] = useState('');
  const [numeroChasis, setNumeroChasis] = useState('');
  const [typeVehicule, setTypeVehicule] = useState('');
  const [dateMiseCirculation, setDateMiseCirculation] = useState('');
  const [puissanceFiscale, setPuissanceFiscale] = useState('');
  const [numeroImmatriculation, setNumeroImmatriculation] = useState('');

  //pack
  const [selectedPack, setSelectedPack] = useState();

  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  const saveData = async () => {
    const url = `${getEnvironement().API_URL}/carteGrise`;
    const data = {
      user_id: user.id,
      nom,
      prenom,
      dateNaissance,
      nationalite,
      cni,
      adresse,
      tel,
      email,
      marqueVehicule,
      modeleVehicule,
      numeroChasis,
      typeVehicule,
      dateMiseCirculation,
      puissanceFiscale,
      numeroImmatriculation,
      packId: selectedPack
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      await axios.post(url, data, config)
        .then((response) => {
          if (response.status === 200) {
            dispatch(setUser(response.data));
            navigate('/dashboard');
          }
        });
    } catch (error) {
      console.log('Error saving data', error);
    }
  };

  const getQuestion = (currentStep) => {
    switch(currentStep) {
    case 1 :
      return (
        <QPersonne
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          nom={nom}
          setNom={setNom}
          prenom={prenom}
          setPrenom={setPrenom}
          dateNaissance={dateNaissance}
          setDateNaissance={setDateNaissance}
          nationalite={nationalite}
          setNationalite={setNationalite}
          cni={cni}
          setCni={setCni}
          adresse={adresse}
          setAdresse={setAdresse}
          tel={tel}
          setTel={setTel}
          email={email}
          setEmail={setEmail}
        />
      );
    case 2 :
      return (
        <QVehicule
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          marqueVehicule={marqueVehicule}
          setMarqueVehicule={setMarqueVehicule}
          modeleVehicule={modeleVehicule}
          setModeleVehicule={setModeleVehicule}
          numeroChasis={numeroChasis}
          setNumeroChasis={setNumeroChasis}
          typeVehicule={typeVehicule}
          setTypeVehicule={setTypeVehicule}
          dateMiseCirculation={dateMiseCirculation}
          setDateMiseCirculation={setDateMiseCirculation}
          puissanceFiscale={puissanceFiscale}
          setPuissanceFiscale={setPuissanceFiscale}
          numeroImmatriculation={numeroImmatriculation}
          setNumeroImmatriculation={setNumeroImmatriculation}
          handelEnregisterClick={saveData}
        />
      );
    case 3 :
      return (
        <Packs
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          userId={user.id}
          typePack='autres démarches'
          productType='carteGrise'
          setSelectedPack={setSelectedPack}
          data={{
            user_id: user.id,
            nom,
            prenom,
            dateNaissance,
            nationalite,
            cni,
            adresse,
            tel,
            email,
            marqueVehicule,
            modeleVehicule,
            numeroChasis,
            typeVehicule,
            dateMiseCirculation,
            puissanceFiscale,
            numeroImmatriculation,
            packId: selectedPack
          }}
        />
      );
    case 4:
      return (
        <div className='mb-10'>
          <p className='text-base font-medium text-neutral mb-2'>Confirmation :</p>
          <div className='flex gap-5 mt-5'>
            <Button
              color='info'
              variant='outlined'
              onClick={() => {
                setCurrentStep(currentStep - 1);
              }}
            >
              {'< Retour'}
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={saveData}
            >
              Enregistrer
            </Button>
          </div>
        </div>
      );
    default :
      return (<div />);
    }
  };

  return (
    <div>
      <HeaderContainer />
      <div className='h-screen flex flex-col justify-start items-center px-10 mt-10'>
        <h3 className=''>DEMANDE DE CARTE GRISE</h3>
        <StepProgress numberSteps={4} currentStep={currentStep}/>
        <div className='w-fit md:w-[700px]'>
          {getQuestion(currentStep)}
        </div>
      </div>
    </div>

  );
}

//---------------Questions---------------------
function QPersonne({
  currentStep,
  setCurrentStep,
  nom,
  setNom,
  prenom,
  setPrenom,
  dateNaissance,
  setDateNaissance,
  adresse,
  setAdresse,
  nationalite,
  setNationalite,
  cni,
  setCni,
  tel,
  setTel,
  email,
  setEmail,
}) {
  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Informations sur le propriétaire du véhicule :</p>
      <div className='flex flex-col md:flex-row'>
        <span className='w-1/2'>
          <CustomTextInput
            image={number}
            type='text'
            value={nom}
            setValue={setNom}
            placeholder='Nom'
          />
        </span>
        <span className='w-1/2'>
          <CustomTextInput
            image={number}
            type='text'
            value={prenom}
            setValue={setPrenom}
            placeholder='Prénom'
          />
        </span>
      </div>
      <CustomTextInput
        image={number}
        type='date'
        value={dateNaissance}
        setValue={setDateNaissance}
        placeholder='Date de naissance'
      />
      <div className='flex flex-col md:flex-row'>
        <span className='w-1/2'>
          <CustomTextInput
            image={number}
            type='text'
            value={nationalite}
            setValue={setNationalite}
            placeholder='Nationalité'
          />
        </span>
        <span className='w-1/2'>
          <CustomTextInput
            image={number}
            type='text'
            value={cni}
            setValue={setCni}
            placeholder='CNI'
          />
        </span>
      </div>
      <CustomTextInput
        image={number}
        type='text'
        value={adresse}
        setValue={setAdresse}
        placeholder='adresse'
      />
      <div className='flex flex-col md:flex-row'>
        <span className='w-1/2'>
          <CustomTextInput
            image={number}
            type='text'
            value={tel}
            setValue={setTel}
            placeholder='Numéro de téléphone'
          />
        </span>
        <span className='w-1/2'>
          <CustomTextInput
            image={number}
            type='email'
            value={email}
            setValue={setEmail}
            placeholder='Email'
          />
        </span>
      </div>
      <div className='flex gap-5 mt-5'>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
      </div>
    </div>
  );
}

function QVehicule({
  currentStep,
  setCurrentStep,
  marqueVehicule,
  setMarqueVehicule,
  modeleVehicule,
  setModeleVehicule,
  numeroChasis,
  setNumeroChasis,
  typeVehicule,
  setTypeVehicule,
  dateMiseCirculation,
  setDateMiseCirculation,
  puissanceFiscale,
  setPuissanceFiscale,
  numeroImmatriculation,
  setNumeroImmatriculation,
  // handelEnregisterClick
}) {
  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Informations sur le véhicule</p>
      <div className='flex flex-col md:flex-row'>
        <span className='w-1/2'>
          <CustomTextInput
            image={number}
            type='text'
            value={marqueVehicule}
            setValue={setMarqueVehicule}
            placeholder='Marque du véhicule'
          />
        </span>
        <span className='w-1/2'>
          <CustomTextInput
            image={number}
            type='text'
            value={modeleVehicule}
            setValue={setModeleVehicule}
            placeholder='Modèle du véhicule'
          />
        </span>
      </div>
      <div className='flex flex-col md:flex-row'>
        <span className='w-1/2'>
          <CustomTextInput
            image={number}
            type='text'
            value={numeroChasis}
            setValue={setNumeroChasis}
            placeholder='Numéro de châssis'
          />
        </span>
        <span className='w-1/2'>
          <CustomTextInput
            image={number}
            type='text'
            value={numeroImmatriculation}
            setValue={setNumeroImmatriculation}
            placeholder='Numéro d’immatriculation'
          />
        </span>
      </div>
      <CustomTextInput
        image={number}
        type='text'
        value={typeVehicule}
        setValue={setTypeVehicule}
        placeholder='Type de véhicule (voiture, moto, camion, etc.)'
      />
      <div className='flex flex-col md:flex-row'>
        <span className='w-1/2'>
          <CustomTextInput
            image={number}
            type='date'
            value={dateMiseCirculation}
            setValue={setDateMiseCirculation}
            placeholder='Date de mise en circulation'
          />
        </span>
        <span className='w-1/2'>
          <CustomTextInput
            image={number}
            type='text'
            value={puissanceFiscale}
            setValue={setPuissanceFiscale}
            placeholder='Puissance fiscale'
          />
        </span>
      </div>
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        {/* <Button
          color='primary'
          variant='contained'
          onClick={handelEnregisterClick}
        >
          Enregistrer
        </Button> */}
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
          Continuer
        </Button>
      </div>
    </div>
  );
}
