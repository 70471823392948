import React from 'react';
import { Link } from 'react-router-dom';
import ContratContentSqueleton from '../../components/contratContentSqueleton/contratContentSqueleton';
import './cdd.css';

export default function Cdd() {
  const itemsContent = [
    {title: 'Définition du CDD', link: '#1'},
    {title: 'Durée et renouvellement du CDD', link: '#2'},
    {title: 'Forme du CDD et requalification en CDI', link: '#3'},
    {title: 'Mentions obligatoires du CDD', link: '#4'},
    {title: 'Interdiction de recours au CDD', link: '#5'},
    {title: 'Fin du CDD', link: '#6'},
    {title: 'Règles spécifiques aux particuliers employeurs', link: '#7'},
  ];
  return (
    <ContratContentSqueleton
      title='Contrat de travail à durée déterminée CDD'
      subTitle='Obtenez votre contrat de travail CDD'
      link={'form'}
      titleLeftContent=''
      textButton='Remplir mon contrat de travail CDI'
      titleContent='A propos du contrat de travail à durée déterminée (CDD)'
      rightContent={<CddContent />}
      itemsContent={itemsContent}
    />
  );
}

function CddContent() {
  return (
    <div className='cddContent'>
      <h3 id='1'>Définition du CDD</h3>
      
      <p>Le Contrat à Durée Déterminée (CDD) est un <Link className='linkContent' to='#'>contrat de travail</Link> conclu pour une durée précise. Il doit être conclu pour une tâche précise et ne doit pas avoir pour but de garantir un emploi stable et permanent au sein de l’entreprise, contrairement au <Link className='linkContent' to='#'>contrat de travail à durée indéterminée (CDI)</Link></p>
      <p><strong>Il en va de même lorsque l’employeur est un particulier</strong> (pour un emploi à domicile par exemple).</p>
      <p><Link className='linkContent' to='#'>Il n’est possible que pour l’exécution d’une tache précise et temporaire dans certains cas</Link>:</p>
      <p>•    Un Contrat à Durée Déterminée (CDD) peut être conclu pour remplacer un salarié temporairement absent. Le salarié remplacé peut être absent pour plusieurs raisons :</p>
      <ul className='list-wtl'>
        <li>–    congé maternité, parental, congé maladie,</li>
        <li>–    passage provisoire à temps partiel d’un salarié remplacé,</li>
        <li>–    départ définitif d’un salarié avant la suppression de son poste,</li>
        <li>–    attente de l’entrée en service du nouveau titulaire du poste en CDI (durée de 9 mois maximum).</li>
      </ul>
      <p>•    Un Contrat à Durée Déterminée (CDD) peut être conclu en cas d’accroissement temporaire d’activité. Ce cas de recours vise :</p>
      <ul className='list-wtl'>
        <li>–    l’augmentation temporaire de l’activité habituelle de l’entreprise,</li>
        <li>–    l’exécution d’une tache occasionnelle définie qui ne relève pas de l’activité normale de l’entreprise,</li>
        <li>–    les travaux urgents,</li>
        <li>–    la survenance d’une commande exceptionnelle à l’exportation.</li>
      </ul>
      <p>•    Un Contrat à Durée Déterminée (CDD) peut être conclu pour des emplois saisonniers (CDD saisonnier).</p>
      <p>•    Un Contrat à Durée Déterminée (CDD) peut être conclu pour des emplois pour lesquels, dans certains secteurs d’activité définis par décret ou convention ou accord collectif de travail étendu, on ne peut pas recourir au CDI, notamment en raison de l’activité exercée et du caractère ponctuel de l’emploi. Dès lors que cela est justifié (activité non permanente), la succession de CDD avec le même salarié est alors autorisée (<Link className='linkContent' to='#'>CDD d’usage</Link>).</p>

      <h3 id='2'>Durée et renouvellement du CDD</h3>

      <p>Le Contrat à Durée Déterminée (CDD) doit comporter un terme précis. Il est conclu (sauf exceptions légales) pour une durée de 18 mois maximum, <Link className='linkContent' to='#'>renouvellement du CDD inclus</Link>.</p>
      <p>Il est <Link className='linkContent' to='#'>renouvelable 2 fois maximum</Link>.</p>
      <p>La <Link className='linkContent' to='#'>durée maximale du CDD</Link> est de 24 mois en cas de :</p>
      <ul>
        <li>Contrat exécuté à l’étranger,</li>
        <li>Commande exceptionnelle à l’exportation,</li>
        <li>Le contrat est conclu dans le cadre du départ définitif d’un salarié avant la suppression de son poste.</li>
      </ul>
      <p>La durée maximale est de 9 mois en cas de :</p>
      <ul>
        <li>Travaux urgents,</li>
        <li>En attendant qu’un salarié soit recruté en CDI.</li>
      </ul>
      <p>Le Contrat à Durée Déterminée (CDD) peut comporter une période d’essai qui ne peut excéder :</p>
      <ul>
        <li>1 jour par semaine, dans la limite de 2 semaines pour un contrat d’une durée initiale de 6 mois maximum,</li>
        <li>1 mois pour un contrat d’une durée initiale de plus de 6 mois.</li>
      </ul>
      <p>Délai de carence sur un même poste :</p>
      <p>A l’issu d’un Contrat à Durée Déterminée (CDD), un délai de carence doit être respecté avant la conclusion d’un nouveau CDD ou d’un <Link className='linkContent' to='#'>contrat de travail temporaire</Link>. Ce délai est de :</p>
      <ul>
        <li>1/3 de la durée du contrat initial, renouvellement inclus, si cette durée est de 14 jours au plus,</li>
        <li>1/2 de la durée du contrat initial, renouvellement inclus, si cette durée est inférieure à 14 jours.</li>
      </ul>
      <p>Le <Link className='linkContent' to='#'>délai entre 2 contrats CDD successifs</Link> sur le même poste s’applique, que le nouveau contrat soit conclu avec le même salarié ou un autre.</p>

      <h3 id='3'>Forme du CDD et requalification en CDI</h3>

      <p>Pour les entreprises, le Contrat à Durée Déterminée (CDD) doit être conclu pour l’un des motifs précités et doit respecter un formalisme particulier.</p>
      <p>Il doit obligatoirement être établi par écrit et conclu dans les 2 jours ouvrables de l’embauche.</p>
      <p>A défaut, le Contrat à Durée Déterminée (CDD) court le risque d’être requalifié en CDI.</p>
      <p>Le contrat sera réputé à durée indéterminée dans les cas suivants :</p>
      <ul>
        <li>poursuite de la relation contractuelle à l’échéance du terme,</li>
        <li>conclusion d’un Contrat à Durée Déterminée (CDD) en dehors des cas autorisés précités ou pour pourvoir un emploi stable et permanent au sein de l’entreprise,</li>
        <li>conclusion d’un Contrat à Durée Déterminée (CDD) pour remplacer un salarié gréviste ou effectuer des travaux dangereux,</li>
        <li>absence d’écrit ou d’une mention essentielle,</li>
        <li>non-respect du délai de carence entre 2 contrats successifs sur le même poste,</li>
        <li>violation des règles sur le terme, le renouvellement et la durée du contrat.</li>
      </ul>
      <p><strong>Pour les employeurs particuliers</strong>, le chèque emploi-service peut être utilisé pour s’exonérer de l’obligation d’élaborer un contrat de travail écrit. Cependant, il ne peut être utilisé que pour des prestations occasionnelles, dont la durée hebdomadaire n’excède pas 8 heures ou pour une durée dans l’année d’un mois non renouvelable. Pour ces emplois, le chèque emploi-service fait donc office de contrat de travail. En revanche, il est obligatoire d’élaborer un contrat de travail écrit à partir du moment où la durée du contrat excède 8 heures par semaine ou 4 semaines consécutives sur l’année. Il est d’autant plus obligatoire dans le cadre d’un CDD qu’à défaut la requalification en CDI sera encouru. Le contrat de travail doit être conclu dans les 2 jours ouvrables de l’embauche. En cas de discrimination à l’embauche, il est possible que le candidat a l’embauche envisage de faire <Link className='linkContent' to='#'>des recours</Link>.</p>

      <h3 id='4'>Mentions obligatoires du CDD</h3>

      <p>Le Contrat à Durée Déterminée (CDD) doit comporter les <Link className='linkContent' to='#'>mentions obligatoires suivantes</Link> :</p>
      <ul>
        <li>Le motif du recours à ce type de contrat,</li>
        <li>La date du terme et une clause de renouvellement en cas de terme précis,</li>
        <li>La désignation du poste de travail,</li>
        <li>Le nom et la qualification de la personne remplacée le cas échéant,</li>
        <li>La durée de la période d’essai le cas échéant,</li>
        <li>La durée minimale.</li>
      </ul>
      <p>En cas d’absence de ces mentions le Contrat à Durée Déterminée (CDD) est requalifié en CDI.</p>
      <ul>
        <li>Le nom et l’adresse de la caisse de retraite complémentaire,</li>
        <li>La convention collective applicable,</li>
        <li>Le montant de la rémunération.</li>
      </ul>
      <p>L’absence d’une de ces mentions n’entraine pas la requalification du Contrat à Durée Déterminée (CDD) en CDI.</p>

      <h3 id='5'>Interdiction de recours au CDD</h3>
      

      <p>Il est interdit pour une entreprise de conclure un Contrat à Durée Déterminée (CDD) dans plusieurs cas :</p>
      <ul>
        <li>Lorsque l’entreprise a procédé à un licenciement économique, elle ne pourra recourir au Contrat à Durée Déterminée (CDD) seulement en cas d’accroissement temporaire d’activité dans les 6 mois suivant le licenciement économique, sauf si la durée du CDD n’excède pas 3 mois ou en cas de survenance d’une commande exceptionnelle à l’exportation,</li>
        <li>Pour remplacer un salarié gréviste,</li>
        <li>Pour effectuer des travaux particulièrement dangereux.</li>
      </ul>

      <h3 id='6'>Fin du CDD</h3>
      
      <p>Le Contrat à Durée Déterminée <Link className='linkContent' to='#'>(CDD) prend fin</Link> à l’échéance du terme, il cesse alors de plein droit.</p>
      <p>Avant l’échéance du terme, il ne peut être rompu que dans certains cas limitatifs de <Link className='linkContent' to='#'>rupture anticipée</Link> :</p>
      <ul>
        <li>Accord des parties, volonté claire et non équivoque,</li>
        <li>Force majeure,</li>
        <li>Faute grave de l’une des parties,</li>
        <li>Embauche du salarié en CDI.</li>
      </ul>
      <p>En cas de rupture anticipée par l’employeur en dehors des cas autorisés, le salarié a notamment droit à des dommages et intérêt d’un montant au moins égal aux rémunérations qu’il aurait perçues jusqu’au terme du contrat.</p>
      <p>Au terme du Contrat à Durée Déterminée (CDD), le salarié a droit à une indemnité de fin de contrat de 10 % de la totalité des salaires bruts perçus, sauf, en l’absence de dispositions conventionnelles plus favorables, dans les cas suivants :</p>
      <ul>
        <li>Pour les emplois saisonnier ou d’usage,</li>
        <li>Pour les contrats conclus dans le cadre de la politique de l’emploi,</li>
        <li>Pour les contrats conclu avec un jeune ou pendant ses vacances scolaires,</li>
        <li>En cas de refus du salarié d’accepter un CDI pour occuper le même emploi ou un emploi similaire,</li>
        <li>En cas de poursuite de la relation contractuelle sous la forme d’un CDI à l’issu du Contrat à Durée Déterminée (CDD).</li>
      </ul>

      <h3 id='7'>Règles spécifiques aux particuliers employeurs</h3>

      <p>Des spécificités sont prévues par la loi concernant le <strong>particulier employeur</strong>.</p>
      <p>Ainsi, les employés de maison exerçant leur profession au domicile privé de leur employeur sont soumis aux dispositions de la convention collective nationale, connue sous l’acronyme CNN. Selon les dispositions conventionnelles, la durée de travail hebdomadaire est de 40 heures par semaine pour les salariés du particulier employeur.</p>
      <p>Pour les contrats supérieurs à 8 heures par semaine, un écrit doit être formalisé. Il doit préciser la durée du contrat de travail.</p>
      <p><strong>Les heures de présence responsable</strong> ne concernent que les emplois à caractère familial. Ce sont les heures où un salarié peut utiliser son temps pour lui, pendant son temps de travail, mais tout en restant vigilant pour intervenir s’il y a lieu (lors de la sieste d’un enfant par exemple).</p>
      <p><strong>En cas de garde partagée</strong> (une nounou embauchée par plusieurs familles pour la garde en commun de leurs enfants), un contrat de travail écrit doit être établi avec le salarié par chaque famille.</p>
      <p>La durée du travail correspond au total des heures effectuées au domicile de l’une et de l’autre famille. De plus, chaque famille rémunère les heures effectuées à son propre domicile.</p>
      <p>Une clause identique précisant le lien avec l’autre famille employeur doit être présente dans le contrat.</p>
      <p>En cas de rupture de l’un des contrats, l’autre contrat est modifié : la famille qui n’a pas rompu le contrat, doit proposer au salarié soit de continuer la relation contractuelle avec elle, soit de rompre le contrat. Le refus du salarié n’est en aucun cas fautif (et donnera lieu à une rupture de contrat non disciplinaire assortie des indemnités légales de rupture).</p>
    </div>
  );
}