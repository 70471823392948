import React from 'react';
import { Link } from 'react-router-dom';
import ContratContentSqueleton from '../../components/contratContentSqueleton/contratContentSqueleton';
import './bailProfessionnel.css';

export default function BailProfessionnel() {
  const itemsContent = [
    {title: 'Définition du bail professionnel', link: '#1'},
    {title: 'Quelle forme et quel contenu ?', link: '#2'},
    {title: 'Quelle durée ?', link: '#3'},
    {title: 'Quelles conditions de résiliation ou renouvellement ?', link: '#4'},
    {title: 'Quel loyer ? Quelle révision du loyer ?', link: '#5'},
    {title: 'Quelles répartition des charges entre le bailleur et le locataire ?', link: '#6'},
    {title: 'Est-il possible d’opter pour le statut du bail commercial ?', link: '#7'},
    {title: 'Est-il possible de céder le bail professionnel ou sous-louer les locaux ?', link: '#8'},
    {title: 'Annexes', link: '#9'},
    {title: 'Etat des lieux', link: '#10'},
  ];
  return (
    <ContratContentSqueleton
      title='Bail professionnel'
      subTitle='Bail professionnel – Bail de location de bureaux'
      titleLeftContent=''
      textButton='Remplir mon bail professionnel'
      titleContent=''
      rightContent={<BailProfessionnelContent />}
      itemsContent={itemsContent}
    />
  );
}

function BailProfessionnelContent() {
  return (
    <div className='bailProfessionnelContent'>
      <h3>Définition du bail professionnel</h3>
      <p>Le bail professionnel est un <Link className='linkContent' to='#'>contrat de bail</Link> proche du <Link className='linkContent' to='#'>bail commercial</Link> utilisé pour la location de locaux à des professionnels qui exercent une activité qui n’est ni commerciale, ni artisanale, ni agricole.</p>
      <p><strong>Ce contrat n’est donc pas adapté pour les activités commerciales, pour lesquelles il faut utiliser un bail commercial.</strong></p>
      <p><strong>Le bail professionnel concerne ainsi, par excellence, les professions libérales réglementées</strong> (médecins, avocats, experts-comptables, notaires, architectes, vétérinaires, etc.) ou non réglementées (conseil en gestion de patrimoine, apporteurs d’affaires, etc.).</p>
      <p>Il peut ainsi être conclu uniquement par des locataires qui exercent des professions dont les revenus sont imposés dans la catégorie des bénéfices non commerciaux (BNC).</p>
      <p>Ce bail est conclu entre le propriétaire des locaux qui seront mis en location et le professionnel qui exploitera son activité (le plus souvent libérale) dans les locaux. Le bail professionnel peut également donner naissance à une <Link className='linkContent' to='#'>colocation</Link>.</p>
      <p><strong>Le bail professionnel est souvent privilégié par les propriétaires bailleurs</strong> car il est moins protecteur du locataire que le bail commercial. Le législateur a en effet considéré que la clientèle de ces professionnels libéraux est probablement moins attachée à l’emplacement dans lequel ils exercent leur activité qu’à la qualité du professionnel lui-même, c’est pourquoi il est possible de les soumettre la location de leurs locaux à un contrat de location ne sanctuarisant pas la “propriété commerciale” comm c’est le cas pour le bail commercial, au titre duquel le locataire dispose d’un droit à une durée de bail plus longue (9 ans au lieu de 6 ans pour le bail professionnel) et d’un droit à <Link className='linkContent' to='#'>indemnité d’éviction</Link> si le bailleur refuse de renouveler le bail à terme (sauf exception), principe qui n’existe pas pour le bail professionnel.</p>
      <p>Il est toutefois possible pour les parties au contrat de bail, même si le locataire exerce une profession libérale, de se soumettre volontairement au régime du bail commercial, écartant ainsi le régime moins protecteur du bail professionnel. Ce sera souvent le cas quand le locataire est en position de force et exige une protection contractuelle accrue.</p>
      <p>LegalStation vous propose un <Link className='linkContent' to='#'>modèle gratuit de bail professionnel</Link>, prêt à imprimer.</p>

      <h3>Quelle forme et quel contenu ?</h3>
      <p><strong>Le bail professionnel est principalement soumis aux dispositions des articles 57 A et 57 B de la <Link className='linkContent' to='#'>loi n°86-1290 du 23 décembre 1986</Link> ainsi que les articles du Code civil.</strong></p>
      <p>Il doit être écrit et comporter les clauses habituelles en matière de contrats de location et notamment :</p>
      <p>– la désignation claire des parties,</p>
      <p>– la description précise des locaux loués et le cas échéant des locaux annexes,</p>
      <p>– la <Link className='linkContent' to='#'>durée du bail</Link>, qui ne peut pas être inférieure à 6 ans</p>
      <p>– le montant du loyer, les conditions de paiement du loyer et les conditions de révision du loyer (en précisant en particulier que indice de référence est choisi pour l’indexation de la révision du loyer),</p>
      <p>– le montant et le versement du dépôt de garantie,</p>
      <p>– les obligations des parties,</p>
      <p>– la répartition des charges,</p>
      <p>– les annexes obligatoires,</p>
      <p>– etc.</p>

      <h3>Quelle durée ?</h3>
      <p><strong>Le bail professionnel est conclu pour une durée minimale de 6 ans.</strong></p>
      <p>Les parties peuvent convenir d’une durée supérieure à 6 ans. il n’est en revanche pas possible de prévoir une durée inférieure à 6 ans. La loi n’a pas prévu, comme pour le bail commercial, la possibilité d’avoir recours à un <Link className='linkContent' to='#'>bail précaire</Link> au bail professionnel. Il pourrait néanmoins être envisageable, pour des parties souhaitant se soumettre à l’équivalent d’un bail courte durée, de se soumettre volontairement au régime prévu à cet effet par l’<Link className='linkContent' to='#'>article L. 145-5 du Code de commerce</Link> en prenant soins toutefois de ne pas excéder la durée maximale de 3 ans prévue en ce sens. Le modèle de bail professionnel présenté ici ne serait alors pas approprié à un tel contrat de bail.</p>

      <h3>Quelles conditions de résiliation ou renouvellement ?</h3>
      <p><strong>Chaque partie peut décider de ne pas renouveler le bail professionnel en respectant un préavis de 6 mois avant l’arrivée du terme du contrat de bail</strong>, sans justification nécessaire et sans contrepartie financière ou indemnité d’éviction. Des cas spécifiques autorisent la <Link className='linkContent' to='#'>résiliation anticipée</Link> du bail professionnel.</p>
      <p><strong>Attention :</strong> contrairement à ce qui est prévu pour le bail commercial, <strong>le locataire ne dispose pas d’un droit au renouvellement du bail professionnel</strong>. Le propriétaire bailleur peut donc décider de ne pas renouveler le bail professionnel, sans besoin de motiver sa décision, et sans avoir à payer une indemnité d’éviction au locataire.</p>
      <p>En l’absence de volonté de ne pas renouveler le contrat, le bail professionnel est tacitement reconduit pour une durée égale à la durée initiale.</p>
      <p><strong>La résiliation du bail professionnel en cours de vie est également possible, mais uniquement pour le locataire, qui doit ici aussi respecter un préavis de 6 mois.</strong></p>
      <p>Le bailleur, lui, ne peut pas résilier le bail professionnel en cours de vie du bail.</p>

      <h3>Quel loyer ? Quelle révision du loyer ?</h3>
      <p><strong>Le montant du loyer, ainsi que ses modalités de paiement, seront librement fixés par les parties.</strong></p>
      <p>Les parties peuvent stipuler, dans le contrat de bail, que le loyer sera automatiquement révisé tous les ans (ou tous les 2 ou 3 ans).</p>
      <p><strong>Les choix de l’<Link className='linkContent' to='#'>indice d’indexation applicable à la révision du loyer</Link> est également libre</strong> (sous réserve de présenter un lien avec l’objet du contrat). Les parties peuvent choisir ici l’indice du coût de la construction (ICC) ou l’indice des loyers des activités tertiaires (ILAT). Il est à noter que l’ICC peut donc être utilisé dans le bail professionnel alors qu’il a été proscrit (sauf exceptions strictes) du bail commercial depuis l’entrée en vigueur de la <Link className='linkContent' to='#'>loi Pinel</Link>.</p>
      <p>L’utilisation de l’ICC sera en principe privilégiée par le bailleur car il dépend principalement du coût des matières premières et augmente historiquement plus vite que les autres indices.</p>
      <p>Au contraire, si le locataire est en position de force, il exigera le plus souvent que la révision du loyer soit indexée sur l’ILAT qui a été créé pour répondre à la demande des professionnels libéraux et exerçant leurs activités dans des bureaux ou cabinets et est principalement assis sur l’indice des prix à la consommation. Il augmente ainsi bien moins fortement que l’ICC.</p>
      <p>Bon à savoir : Selon le type de locaux loués, le loyer pourra être également le <Link className='linkContent' to='#'>bail professionnel tva</Link>.</p>

      <h3>Quelles répartition des charges entre le bailleur et le locataire ?</h3>
      <p><strong><Link className='linkContent' to='#'>Les charges</Link> liées à la jouissance des locaux sont librement réparties entre le bailleur et le locataire à l’exception de certaines charges</strong> (notamment les grosses réparations) nécessairement supportées par le bailleur. En général, toutes les charges autres que le minimum légal supporté par le bailleur sont refacturées au locataire.</p>
      <p>Le minimum légal devant être supporté par le bailleur est ici identique à ce qui s’applique en matière de bail commercial, à savoir :</p>
      <p>– les dépenses relatives aux grosses réparations touchant au bâti (ravalement, charpente, toiture, etc.),</p>
      <p>– les dépenses de travaux attachés à la vétusté de l’immeuble,</p>
      <p>– les honoraires du bailleur liés à la gestion des loyers du bien loué,</p>
      <p>– les impôts, notamment la contribution économique territoriale, taxes et redevances dont le redevable légal est le bailleur ou le propriétaire du local ou de l’immeuble, et</p>
      <p>– les charges, impôts, taxes, redevances et coût des travaux portant sur des locaux vacants ou imputables à d’autres locataires.</p>
      <p>Les autres charges peuvent librement être refacturées ou non au locataire. Le contrat de bail professionnel devra toutefois bien préciser cette répartition. Notre modèle vous laisse une large gamme de choix sur ces questions.</p>

      <h3>Est-il possible d’opter pour le statut du bail commercial ?</h3>
      <p>Même si le locataire ne relève pas du statut des baux commerciaux, il est possible d’opter pour le bail commercial. La volonté de se soumettre volontairement au régime du bail commercial devra être explicite dans le contrat (qui devra alors impérativement être intitulé “bail commercial” – il conviendra à cet effet de choisir le modèle de bail commercial que nous proposons).</p>
      <p><strong>Attention : opter pour le statut du bail commercial est globalement moins favorable pour le bailleur</strong> (puisque le locataire sera bien plus protégé sous ce statut), qui préfèrera en toute logique demeurer sous le régime du bail professionnel.</p>

      <h3>Est-il possible de céder le bail professionnel ou sous-louer les locaux ?</h3>
      <p>La cession du bail professionnel par le locataire est possible sauf clause expresse contraire qui, en général (comme dans le présent modèle) exigera qu’il obtienne préalablement l’accord du bailleur avant de céder son bail.</p>
      <p>En toutes hypothèses, la cession du bail professionnel devra être signifiée au bailleur.</p>
      <p>De la même manière, la sous-location des locaux est possible sauf clause contraire du bail professionnel. Mais le locataire devra également signifier la sous-location au bailleur et il restera tenu envers le bailleurs de l’exécution des obligations du locataire au titre du bail comme s’il continuait à exploiter lui-même les locaux. Le locataire ne peut bien-entendu pas concéder, au titre de la sous-location, plus de droit qu’il n’en dispose lui-même au titre du contrat principal.</p>

      <h3>Annexes</h3>
      <p>La loi et les différentes réglementations imposent des annexes obligatoires au bail professionnel (comme pour les autres types de baux).</p>
      <p><strong>Un dossier de diagnostic techniques doit ainsi impérativement être annexé au bail commercial</strong>. Il comprend :</p>
      <p>– L’état des risques naturels, miniers et technologiques (ERNMT)</p>
      <p>– Le diagnostic de performance énergétique</p>
      <p>– Le constat de risque d’exposition au plomb pour les biens immobiliers dont le permis de construire a été délivré avant le 1er janvier 1949 (les peintures au plomb n’étant en principe plus utilisées après cette date)</p>
      <p>– Le diagnostic technique amiante (y compris des parties communes si le logement est dans une copropriété), obligatoire pour tous les biens immobiliers dont le permis de construire a été</p>
      <p>Prévoyez de faire réaliser les diagnostics nécessaires par des professionnels.</p>

      <h3>Etat des lieux</h3>
      <p><strong>Il conviendra également d’établir un <Link className='linkContent' to='#'>état des lieux</Link> précis détaillé à l’entrée et à la sortie du locataire au même titre que pour les baux commerciaux.</strong></p>
      <p>L’état des lieux doit être établi :</p>
      <p>– soit de façon contradictoire et à l’amiable entre le bailleur et le locataire,</p>
      <p>– soit par un huissier de justice, à l’initiative du bailleur ou du locataire, à frais partagés par moitié entre le locataire et le bailleur, notamment lorsque le bailleur et le locataire n’arrivent pas à s’entendre sur la date d’un rendez-vous pour établir un état des lieux contradictoire ou sur le contenue de l’état des lieux lui-même.</p>
      <p>L’état des lieux sera conservé par chaque partie et joint au contrat de bail.</p>
      <p>Attention : si aucun état des lieux d’entrée n’a été établi, alors le locataire sera présumé avoir reçu les locaux en bon état de réparation locative. Ici encore, la différence avec le régime des baux commerciaux est notable, puisque la loi Pinel a supprimé cette présomption en l’absence d’état des lieux, qui est particulièrement défavorable au locataire.</p>
      <p>Vous pouvez consulter un avocat pour toute demande de conseils ou besoin d’assistance juridique en ce qui concerne la préparation de votre bail professionnel, sa relecture, ou une prise en charge personnalisée de votre document.</p>
    </div>
  );
}