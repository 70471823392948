import React from 'react';
import './section3.css';
import Carousel from '../../components/carousel/carousel';

export default function Section3() {
  /* eslint-disable */
  const items = [
    {
      what: "Félicitations pour les conseils très pertinents et le service non seulement rapide mais en plus efficace. L'interlocuteur est disponible, agréable et c'est une personne de confiance. Le prix est doux, je suis très satisfait de cette prestation et je la recommande sans hésiter.”",
      who: 'Lorem, Président',
    },
    {
      what: "Félicitations pour les conseils très pertinents et le service non seulement rapide mais en plus efficace. L'interlocuteur est disponible, agréable et c'est une personne de confiance. Le prix est doux, je suis très satisfait de cette prestation et je la recommande sans hésiter.”",
      who: 'Lorem, Président',
    },
  ];
  /* eslint-enable */
  return (
    <div className="sarl-section3">
      <h1 className="w-auto text-darkblue text-balance text-center text-3xl font-bold font-inter">Témoignages de nos clients</h1>
      <div className="sarl-section3-part">
        <Carousel listItems={items} />
      </div>
    </div>
  );
}
