import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useFetch from './useFetch';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import getEnvironement from '../../environnement';
import Article from './article';

export default function Blogs() {
  const {loading, error, data} = useFetch(`${getEnvironement().BLOG_API_URL}?populate=*`);
  if (error) console.log(error, loading);
  console.log(data);

  const [activeTab, setActiveTab] = useState('Tous les articles');
  const rubriques = [
    'Tous les articles',
    'Finance',
    'Juridique',
    'Entrepreneuriat',
    'Ressources humaines',
    'Marketing'
  ];

  const navigate = useNavigate();

  const getArticles = (activeRubrique) => {
    let articles;
    switch (activeRubrique) {
    case 'Tous les articles':
      articles = (
        <div className='w-full px-10 my-5'>
          {data && (
            <Article
              key={data['data'][0].id}
              image={`${getEnvironement().BLOG_URL}${data['data'][0].attributes.image.data.attributes.url}`}
              rubrique={data['data'][0].attributes.rubrique}
              date={data['data'][0].attributes.date}
              title={data.data[0].attributes.title}
              description={data['data'][0].attributes.description}
              actorName={data['data'][0].attributes.actorName}
              actorProfession={data['data'][0].attributes.actorProfession}
              isFirstArticle
              onClick={() => navigate(`${data['data'][0].id}`)}
            />
          )}
          <div className='flex gap-10 justify-center my-10'>
            {data && data.data.slice(1, 3).map((blog) => {
              return (
                <Article
                  key={blog.id}
                  image={`${getEnvironement().BLOG_URL}${blog.attributes.image.data.attributes.url}`}
                  rubrique={blog.attributes.rubrique}
                  date={blog.attributes.date}
                  title={blog.attributes.title}
                  description={blog.attributes.description}
                  actorName={blog.attributes.actorName}
                  actorProfession={blog.attributes.actorProfession}
                  onClick={() => navigate(`${blog.id}`)}
                />
              );
            })}
          </div>
        </div>
      );
      break;
  
    default:
      break;
    }
    return articles;
  };

  return (
    <div className='min-h-screen flex flex-col justify-between'>
      <Header />
      <div className='bg-gradient p-10'>
        <p className='font-inter font-large text-4xl text-[#0A093D]'>Blog And News</p>
        <p className='font-inter font-normal text-base text-[#656464] w-1/2 mt-5 leading-loose'>Stay updated with the latest trends in fashion, technology, entertainment, and more with our comprehensive coverage and insightful analysis</p>
      </div>
      <div className='flex justify-between px-10 pt-5 mb-5 flex-wrap border-b border-b-[#D9D9D9]'>
        {rubriques.map((rubrique) => {
          
          return (
            <p key={rubrique} className={`pb-2 font-inter font-medium text-2xl cursor-pointer hover:text-[#0A093D] ${activeTab === rubrique ? 'text-[#0A093D] border-b-2 border-b-[#0A093D]' : 'text-[#656464]'}`} onClick={() => setActiveTab(rubrique)}>{rubrique}</p>
          );
        })}
      </div>
      {getArticles(activeTab)}
      {/* <div className='flex-1 px-10 mb-5 mt-5 flex-wrap'>
        <div className='flex justify-center'>
          <h1 className='font-poppins font-medium text-2xl py-5'>A la une</h1>
        </div>
        <div className='flex gap-5 justify-center'>
          {data && data.data.filter((item) => item.attributes.featured).map((blog) => {
            return (
              <Article
                key={blog.id}
                icon={`${getEnvironement().BLOG_URL}${blog.attributes.image.data.attributes.url}`}
                title={blog.attributes.title}
                onClick={() => navigate(`${blog.id}`)}
              />
            );
          })}
          {data && getTreeNumber(data.data.filter((item) => item.attributes.featured)).map((index) => {
            return (
              <Article
                key={index}
                empty
              />
            );
          })}
        </div>
      </div>
      <div className='flex-1 px-10 mb-5 flex-wrap'>
        <div className='flex justify-center'>
          <h1 className='font-poppins font-medium text-2xl py-5'>Derniers articles</h1>
        </div>
        <div className='flex gap-5 justify-center'>
          {data && data.data.filter((item) => item.attributes.lastArticles).map((blog) => {
            return (
              <Article
                key={blog.id}
                icon={`${getEnvironement().BLOG_URL}${blog.attributes.image.data.attributes.url}`}
                title={blog.attributes.title}
                onClick={() => navigate(`${blog.id}`)}
              />
            );
          })}
          {data && getTreeNumber(data.data.filter((item) => item.attributes.lastArticles)).map((index) => {
            return (
              <Article
                key={index}
                empty
              />
            );
          })}
        </div>
      </div>
      <div className='flex-1 px-10 mb-5 flex-wrap'>
        <div className='flex justify-center'>
          <h1 className='font-poppins font-medium text-2xl py-5'>Tous les articles</h1>
        </div>
        <div className='flex gap-5 justify-center'>
          {data && data.data.map((blog) => {
            return (
              <Article
                key={blog.id}
                icon={`${getEnvironement().BLOG_URL}${blog.attributes.image.data.attributes.url}`}
                title={blog.attributes.title}
                onClick={() => navigate(`${blog.id}`)}
              />
            );
          })}
          {data && getTreeNumber(data.data).map((index) => {
            return (
              <Article
                key={index}
                empty
              />
            );
          })}
        </div>
      </div> */}
      <div className='mt-5'>
        <Footer />
      </div>
    </div>
  );
}
