import React from 'react';
import { Button } from '@mui/material';
import './handleContent.css';

export default function HandleContent({
  textButton,
  items,
  rightContent,
  isAutresDemarche=false,
  onClick
}) {
  return (
    <div className='handleContentContainer'>
      <div className='handleContent'>
        <div className='leftContent'>
          <div className='left'>
            <div className='choisirModelContainer'>
              <Button
                className='button'
                variant='contained'
                onClick={() => isAutresDemarche ? onClick() : window.scrollTo(0, 0)}
              >
                {textButton}
              </Button>
            </div>
            <p className='sommaire'>SOMMAIRE</p>
            {items.map((item, index) => (
              <a className='link' key={index} href={item.link}>{item.title}</a>
            ))}
          </div>
        </div>
        {rightContent}
      </div>
    </div>
  );
}
