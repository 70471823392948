import React from 'react';
import { Button, LinearProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SaveIcon from '@mui/icons-material/Save';
import DownloadIcon from '@mui/icons-material/Download';
import './contratFormSqueleton.css';

export default function ContratFormSqueleton({
  progressValue,
  currentStep,
  setCurrentStep,
  maxStep,
  onClickEnregistrer,
  onClickTelecharger,
  children
}) {
  return (
    <div id='contratFormSqueleton'>
      <div className='enregistrerButtonContainer'>
        <Button
          className='en'
          variant='contained'
          onClick={onClickEnregistrer}
        >
          <SaveIcon className='iconMarginRight'/>
          Enregistrer
        </Button>
      </div>
      <div className='progressContainer'>
        <LinearProgress
          value={progressValue}
          variant='determinate'
          sx={{ backgroundColor: 'lightgrey' }}
        />
      </div>
      {children}
      <div className='stepButtonContainer'>
        {currentStep !== 0 
          && (
            <>
              <Button
                variant='contained'
                onClick={() => setCurrentStep(currentStep === 0 ? 0 : currentStep - 1)}
              >
                <ArrowBackIcon className='iconMarginRight' />
                Précédent
              </Button>
              {
                currentStep === maxStep
                  ? (
                    <Button
                      variant='contained'
                      onClick={onClickTelecharger}
                    >
                      <DownloadIcon className='iconMarginRight' fontSize='small'/>
                      Télécharger
                    </Button>
                  )
                  : (
                    <Button
                      variant='contained'
                      onClick={() => setCurrentStep(currentStep === maxStep ? maxStep : currentStep + 1)}
                    >
                      Suivant
                      <ArrowForwardIcon className='iconMarginLeft' />
                    </Button>
                  )
              }
            </>
          )}
      </div>
    </div>
  );
}
