import React from 'react';

function ColoredRectangle({
  rectangleColor, hoverColor, icon, number, text,  
}) {
  return (
    <div className='flex gap-4 min-w-32'>
      <div className={`cursor-pointer flex flex-col items-center justify-center rounded-lg w-[52px] h-[52px] min-w-[52px] min-h-[52px] 
        ${rectangleColor} hover:${hoverColor}`}>
        <img src={icon} alt="Icon" className="w-8" />
      </div>
      <div>
        <div className="text-lg font-bold">{number}</div>
        <div className="text-sm w-44">{text}</div>
      </div>
    </div>
  );
}


export default ColoredRectangle;
