import React from 'react';
import { Link } from 'react-router-dom';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import legalStationLogo from '../../../../../../assets/images/LOGO-LS@2x.png';
import './content.css';

export default function Content() {
  return (
    <div className='contratLocationContent'>
      <h3 id='definitionContratLocation'>Qu’est-ce qu’un contrat de location ?</h3>
      <p>
        Le contrat de location, plus fréquemment désigné “bail”, est utilisé dans le cadre de la location 
        d’un bien immobilier. Il est conclu entre le bailleur et le preneur pour une durée déterminée et 
        moyennant un loyer que le preneur s’engage à payer.
      </p>
      <h6>Les différents types de contrat de location</h6>
      <p>
        Selon le type de bien loué, il existe <strong>différents types de baux </strong> qu’il convient 
        d’étudier afin de ne pas commettre d’erreurs :
      </p>
      <ul className='list-wtl'>
        <li>
          <Link className='linkContent' href='#'>Bail commercial </Link>concerne les locaux commerciaux, 
          industriels ou artisanaux pour exercer une activité professionnelle ;
        </li>
        <li>
          <Link className='linkContent' href='#'>Bail professionnel </Link>concerne les locations à un 
          professionnel libéral (avocat, comptable, notaire, médecin etc.) ;
        </li>
        <li>
          <Link className='linkContent' href='#'>Contrat de location non meublée </Link>consiste en la 
          location d’un logement vide à un particulier afin qu’il y installe sa résidence principale ;
        </li>
        <li>
          <Link className='linkContent' href='#'>Contrat de location meublée </Link>: location meublée à un 
          particulier pour y installer sa résidence principale
        </li>
        <li>
          <Link className='linkContent' href='#'>Bail précaire </Link>consiste à louer un local pour 
          une courte durée, a un commerçant ou un artisan ;
        </li>
        <li>
          <Link className='linkContent' href='#'>Contrat de location saisonnière </Link>: il s’agit d’une 
          location à des particuliers pour la période des vacances.
        </li>
      </ul>
      <p className='infoContent'>
        <strong>Bon à savoir :</strong> Il existe également des baux plus spécifiques, notamment 
        <Link className='linkContent' href='#'> pour les étudiants</Link>, les 
        <Link className='linkContent' href='#'> locations de parking </Link>ou encore ceux relatifs à la 
        <Link className='linkContent' href='#'> sous-location</Link>.
      </p>
      <h6>Les modèles PDF et Word proposés par LegalPlace</h6>
      <p>
        Maintenant que vous savez quel type de bail s’applique à votre situation, il ne vous reste qu’à 
        le rédiger. Pour ce faire, LegalPlace vous propose différents modèles de contrat de location, 
        qu’il vous suffit de compléter. Ces modèles, rédigés par nos juristes, 
        sont <strong>à jour de la législation actuelle et entièrement personnalisables</strong>.
      </p>
      <p>
        Sélectionnez le contrat de location adapté à vos besoins, répondez à un questionnaire rapide, 
        et vous obtiendrez votre bail complété, <strong>téléchargeable au format Word ou PDF</strong>.
      </p>
      <h3 id='reglementationContratLocation'>Quelles sont les réglementations encadrant le contrat de location ?</h3>
      <p>
        Selon le type de location et les caractéristiques du bien loué, le cadre juridique peut varier. 
        Ainsi, si certains contrats de location sont régis par le droit commun des contrats, d’autres baux 
        vont quant à eux dépendre de législations différentes.
      </p>
      <h6>Le droit commun des contrats</h6>
      <p>
        Les <Link className='linkContent' href='#'>articles 1713 et suivants du Code civil </Link>régissent 
        les baux d’habitation qui ne comportent aucune spécificité. Concrètement, ces articles s’appliquent 
        sous réserve que ni les dispositions de la loi ALUR, ni celles de la loi ELAN ne s’appliquent.
      </p>
      <h6>La loi ELAN (2018)</h6>
      <p>
        Cette <Link className='linkContent' href='#'>loi ELAN </Link>portant évolution du logement, de 
        l’aménagement et du numérique à mis en place plusieurs dispositifs :
      </p>
      <ul className='list-wtl'>
        <li>Le bail électronique, permettant de dématérialiser la procédure à l’aide d’une signature électronique notamment ;</li>
        <li>Le bail mobilité, un contrat de location non renouvelable qu’il est possible de conclure pour une courte durée (entre 1 et 10 mois) ;</li>
        <li>La garantie Visale : gratuite et sous conditions, elle est destinée aux locataires qui éprouvent des difficultés à trouver un garant.</li>
      </ul>
      <h6>Loi climat et résilience (2020)</h6>
      <p>
        Cette loi a été instaurée dans le but <strong>d’améliorer la performance énergétique des logements 
        </strong> loués. Ainsi, désormais, les baux d’habitation doivent être assortis de 2 documents 
        informatifs concernant la performance énergétique du logement :
      </p>
      <ul className='list-wtl'>
        <li>L’étiquette de performance énergétique</li>
        <li>Le montant total annuel des dépenses d’énergies.</li>
      </ul>
      <p>
        Autre mesure phare de cette loi, l’interdiction de réviser à la hausse le loyer d’un logement 
        considéré comme « passoire énergétique ». Concrètement, tous les logements classés F ou G, ne 
        pourront plus voir leurs loyers révisés à la hausse tant qu’ils n’augmenteront pas leurs 
        performances énergétiques.
      </p>
      <h3 id='redactionContratLocation'>Comment rédiger un bail ?</h3>
      <p>
        La rédaction d’un contrat de location est plus ou moins stricte selon le type de bien loué, et sa 
        destination. Il existe des mentions obligatoires à inserer lors de sa rédaction, mais aussi des 
        clauses abusives qu’il est interdit de faire figurer au contrat.
      </p>
      <h6>Le contenu obligatoire du contrat de location</h6>
      <p>Les mentions suivantes sont impératives pour conclure un contrat de location :</p>
      <ul className='list-wtl'>
        <li>Identification des parties</li>
        <li>Désignation des lieux loués et leur destination</li>
        <li>Durée de la location</li>
        <li>Conditions relatives au loyer et aux charges</li>
        <li>Conditions relatives au dépôt de garantie</li>
        <li>Le cas échéant, les informations relatives aux frais d’agence</li>
      </ul>
      <p>
        Certains documents destinés à la parfaite information du locataire seront également à fournir, en <strong>annexe du bail</strong> .
      </p>
      <h6>Les parties au contrat</h6>
      <p>
        Un contrat de location est conclu entre deux parties : le bailleur (propriétaire du bien) et le 
        locataire (le preneur). Ces parties doivent être identifiées et identifiables dans le contenu du 
        contrat, c’est pourquoi il est impératif de mentionner :
      </p>
      <ul className='list-wtl'>
        <li>Les coordonnées du propriétaire</li>
        <li>Celles des locataires</li>
        <li>Les coordonnées (nom et siège social) du gestionnaire dans le cas ou le logement ne serait pas directement géré par le propriétaire</li>
        <li>La date de prise d’effet et la durée du bail</li>
      </ul>
      <h6>Désignation et destination du bien loué</h6>
      <p>
        Certaines <strong>informations concernant le logement </strong>doivent impérativement être 
        mentionnées dans le contrat de location, telles que :
      </p>
      <ul className='list-wtl'>
        <li>Le type de bien immobilier loué (maison, appartement, hangar etc.)</li>
        <li>Les caractéristiques du bâtiment (immeuble ou copropriété) et sa date de construction ;</li>
        <li>La surface habitable du logement</li>
        <li>Le nombre de pièces</li>
        <li>La liste des équipements privatif et commun</li>
        <li>La nature et le montant des travaux effectués dans le logement depuis le dernier bail</li>
      </ul>
      <p>
        Il arrive que le bien loué soit destiné à <strong>plusieurs usages.</strong> C’est pourquoi il est 
        nécessaire de faire figurer au contrat la <strong>destination des locaux loués </strong>qu’il 
        s’agisse d’un simple bail d’habitation, d’un bail commercial ou encore d’un bail mixte.
      </p>
      <h6>Durée du bail et préavis</h6>
      <p>
        La durée du bail dépend de son type. En effet, selon le type de bail conclu, la durée varie. Ainsi, 
        la <Link className='linkContent' href='#'>durée d’un bail commercial </Link>diffère de 
        <Link className='linkContent' href='#'>celle d’un bail professionnel </Link>par exemple. Le tableau 
        suivant répertorie les durées correspondant au type de bail :
      </p>
      <table className='dureeBailPreavis'>
        <tr>
          <th>Type de bail</th>
          <th>Durée de location</th>
        </tr>
        <tr>
          <td>Professionnel</td>
          <td>Ne peut excéder 3 ans</td>
        </tr>
        <tr>
          <td>Location vide</td>
          <td>
            <p>3 ans pour les particuliers et les SCI familiales</p>
            <p>6 ans pour les sociétés</p>
          </td>
        </tr>
        <tr>
          <td>Commercial</td>
          <td>9 ans minimum</td>
        </tr>
        <tr>
          <td>Location meublée</td>
          <td>1 an minimum (9 mois pour les étudiants)</td>
        </tr>
        <tr>
          <td>Dérogatoire</td>
          <td>6 ans minimum</td>
        </tr>
      </table>
      <p className='infoContentBlue'>
        <strong>A noter : </strong>pour les locations de parking et les locations saisonnières, la durée du 
        bail est fixée librement entre les parties. Toutefois, la location saisonnière doit impérativement 
        être fixée pour une durée déterminée.
      </p>
      <p>
        Les délais de préavis applicables diffèrent en fonction du type de bail conclu ainsi qu’en fonction de la situation du locataire, comme suit :
      </p>
      <ul className='list-wtl'>
        <li>1 mois pour les locations meublées ou étudiantes ;</li>
        <li>3 mois pour les autres locations.</li>
      </ul>
      <p>
        La situation personnelle et professionnelle du locataire peut également permettre de diminuer la 
        durée du préavis, passant de 3 mois à 1 mois. Cette diminution est appliquée quand :
      </p>
      <ul className='list-wtl'>
        <li>Le logement est situé en zone tendue ;</li>
        <li>
          Le locataire :
          <ul className='list-wtl'>
            <li>se voit attribuer un logement social ;</li>
            <li>est bénéficiaire du RSA ou de l’AAH ;</li>
            <li>fait face à un changement de cadre professionnel l’obligeant à déménager ;</li>
            <li>est victime de violences conjugales ;</li>
            <li>justifie d’un état de santé lui imposant de déménager.</li>
          </ul>
        </li>
      </ul>
      <div className='delaisPreavisContainer'>
        <div className='delaisPreavis'>
          <h1>Les <span>délais de préavis</span> applicables à une location de logement</h1>
          <table>
            <tr>
              <td><p className='border'>1 mois</p></td>
              <td><EastOutlinedIcon className='arrow'/></td>
              <td className='pl-3'>location meublé ou étudiante</td>
            </tr>
            <tr>
              <td><p className='border'>3 mois</p></td>
              <td><EastOutlinedIcon className='arrow' /></td>
              <td className='pl-3'>location vide</td>
            </tr>
            <tr>
              <td><p className='border'>3 mois réductible à un mois</p></td>
              <td><EastOutlinedIcon className='arrow' /></td>
              <td>
                <ul>
                  <li>logement situé en zone tendue</li>
                  <li>situation personnelle du locataire le justifiant</li>
                  <li>attribution d&apos;un logement social</li>
                  <li>locatiare bénéficiaire du RSA ou de l&apos;AAH</li>
                </ul>
              </td>
            </tr>
          </table>
          <div>
            <img src={legalStationLogo} className='logo'/>
          </div>
        </div>
      </div>
      <h6>Charges et loyer</h6>
      <p>
        Le montant du loyer, des charges, ainsi que les modalités de paiement et de révision <strong>sont 
        des éléments essentiels du contrat </strong>de location. A ces éléments s’ajoute le montant du 
        dernier loyer appliqué au précèdent locataire, s’il a quitté le logement moins de 18 mois avant la 
        conclusion du nouveau bail.
      </p>
      <p className='infoContentBlue'>
        <strong>A noter : </strong>il est possible de procéder ultérieurement à la 
        <Link className='linkContent' href='#'>modification du montant des charges à l’aide d’un avenant au 
        contrat de location</Link>.
      </p>
      <p>
        Concernant le montant du loyer, il est important de noter que certaines zones font l’objet d’un 
        <strong>encadrement strict</strong>. Ainsi, si en principe le montant est fixé librement, il existe 
        plusieurs zones dans lesquelles ce n’est pas le cas : Paris intra-muros, Lille et les autres zones 
        tendues.
      </p>
      <p>
        Les baux concernant les logements situés dans les zones susmentionnées devront alors indiquer en 
        supplément :
      </p>
      <ul className='list-wtl'>
        <li>Le montant du <Link className='linkContent' href='#'>loyer de référence</Link>;</li>
        <li>Le montant du <Link className='linkContent' href='#'>loyer de référence majorée</Link>;</li>
        <li>L’éventuel complément de loyer.</li>
      </ul>
      <p className='infoContent'>
        <strong>Bon à savoir :</strong> si un complément de loyer est appliqué, il faudra également joindre 
        au contrat des documents le justifiant.
      </p>
      <h6>Dépôt de garantie</h6>
      <p>
        Versé au propriétaire au moment de la signature du bail, le dépôt de garantie (ou caution) n’est en 
        principe utilisé qu’en cas de <strong>dégradation du logement </strong>ou d’impayés de loyer par le 
        locataire. En l’absence de dégradation, il lui sera ainsi <strong>restitué dans un délai d’un 
        mois </strong> suivant la date de fin du contrat de location.
      </p>
      <p>
        Son montant varie en fonction du type de bail signé. Par exemple, pour un logement vide, le dépôt de 
        garantie est limité à un mois de loyer, contre deux mois pour un logement meublé.
      </p>
      <p className='infoContent'>
        <strong>Bon à savoir :</strong> pour les baux commerciaux, il est d’usage qu’une caution bancaire 
        soit exigée par le bailleur avant la signature du contrat.
      </p>
      <p>Informations relatives aux frais d’agence</p>
      <p>
        Dans le cas où une agence serait mandatée pour la gestion du logement, le contrat de bail doit 
        impérativement mentionner :
      </p>
      <ul className='list-wtl'>
        <li>Les modalités de partage des frais d’agence ;</li>
        <li>Le montant des honoraires appliqués.</li>
      </ul>
      <p>
        La loi ALUR précise sur ce point que la part des frais dus par le locataire ne peut excéder celle du 
        propriétaire du logement concerné.
      </p>
      <h6>Les annexes au contrat de location</h6>
      <p>Pour signer le bail, divers documents doivent être annexés au contrat :</p>
      <ul className='list-wtl'>
        <li>
          Le dossier des <Link className='linkContent' href='#'>diagnostics techniques </Link>(état des 
          installations électriques et de gaz, diagnostic de performance énergétique, éventuelle présence de 
          plomb ou d’amiante etc.) ;
        </li>
        <li>L’<Link className='linkContent' href='#'>état des lieux </Link>d’entrée ;</li>
        <li>L’attestation d’assurance habitation du locataire ;</li>
        <li>Une notice informative concernant les droits et obligations des parties ;</li>
        <li>Si le logement est conventionné, un exemplaire de la convention ANAH ;</li>
        <li>
          Une copie de la <Link className='linkContent' href='#'>grille de vétusté </Link>s’il a été convenu entre les parties de l’appliquer ;
        </li>
        <li>Le cas échéant, un exemplaire du règlement de copropriété.</li>
      </ul>
      <p className='infoContentRed'>
        <strong>Attention : </strong>en l’absence de transmission des diagnostics techniques du logement, le contrat de bail peut être annulé.
      </p>
      <h6>Les clauses abusives du contrat de bail</h6>
      <p>
        Dans un souci de protection des locataires, certaines clauses du contrat de bail sont considérées 
        comme abusives et ne disposent ainsi d’aucune valeur juridique. Sont interdites et réputées non écrites les clauses :
      </p>
      <ul className='list-wtl'>
        <li>Autorisant le propriétaire à diminuer ou à supprimer, sans contrepartie équivalente, des prestations prévues au contrat de bail ;</li>
        <li>Autorisant le propriétaire à diminuer ou à supprimer, sans contrepartie équivalente, des prestations prévues au contrat de bail ;</li>
        <li>Qui autorisent le prélèvement du loyer directement sur le salaire du locataire ;</li>
        <li>Autorisant le bailleur à percevoir des indemnités en cas de non-respect des clauses du contrat ;</li>
        <li>Imposant au locataire de verser un montant supplémentaire au dépôt de garantie convenu lors de l’état des lieux ;</li>
        <li>Facturant au locataire l’envoi des <Link className='linkContent' href='#'>quittances de loyer</Link>;</li>
        <li>Obligeant le locataire à souscrire une assurance habitation choisie par le bailleur :</li>
        <li>
          Interdisant au locataire : d’héberger des invités, d’utiliser le logement pour pratiquer une 
          activité associative, politique ou syndicale ;
        </li>
        <li>Imposant que le locataire soit automatiquement reconnu responsable des dégradations du logement ;</li>
        <li>Prévoyant de renouveler ou de reconduire automatiquement le bail pour une durée inférieure à 3 ans ;</li>
        <li>Prévoyant d’autres motifs que les clauses de résolution légales pour la résiliation du contrat.</li>
      </ul>
      <h6>Les clauses résolutoires du contrat de bail</h6>
      <p>
        Certaines clauses permettent au bailleur de résilier le contrat de location si le locataire ne les 
        respecte pas. Ainsi, chaque propriétaire est libre d’inclure dans le bail les clauses résolutoires suivantes :
      </p>
      <ul className='list-wtl'>
        <li>Une clause de non-paiement du loyer ou des charges ;</li>
        <li>Défaut de souscription d’une assurance habitation ;</li>
        <li>Non-versement du dépôt de garantie ;</li>
        <li>Non-versement du dépôt de garantie ;</li>
      </ul>
      <h3 id='modificationContratLocation'>Comment modifier, résilier ou renouveler un contrat de location ?</h3>
      <p>
        Comme tout contrat, un bail est susceptible d’être modifié en cours de vie, de même qu’il est possible de le renouveler ou de le résilier.
      </p>
      <h6>La modification du bail</h6>
      <p>
        Pour apporter des modifications à un contrat de location, il faut impérativement <strong>rédiger 
        un avenant </strong>au bail. Une fois signé par les deux parties, cet avenant sera annexé au 
        contrat initial.
      </p>
      <h6>Le renouvellement du bail</h6>
      <p>
        En principe, le <strong>renouvellement d’un contrat de location est tacite</strong>. Cela implique 
        qu’à défaut de congé posé par le locataire ou le bailleur, le contrat se poursuit dans les 
        conditions initiales.
      </p>
      <p>
        Le bailleur peut toutefois renouveler en imposant une hausse du loyer, sous réserve qu’elle soit 
        conforme aux dispositions encadrant le montant des loyers en France.
      </p>
      <h6>La résiliation du bail</h6>
      <p>
        Pour résilier votre contrat de location, il vous faudra en premier lieu informer votre bailleur de 
        votre volonté de partir par lettre recommandée avec accusé de réception. Une période de préavis sera 
        à respecter dont la durée dépend des caractéristiques du logement habité :
      </p>
      <ul className='list-wtl'>
        <li>1 mois pour un logement meublé ;</li>
        <li>3 mois pour un logement vide.</li>
      </ul>
      <p>
        Si la résiliation est à l’initiative du bailleur, ce dernier ne dispose en principe que de 3 motifs 
        légaux pour se sortir du contrat : le congé pour reprise, celui pour vendre le bien et enfin un 
        congé pour motif légitime et sérieux.
      </p>
      <h3 id='faq'>FAQ</h3>
      <div className='faq'>
        <h5>Comment faire un contrat de colocation ?</h5>
        <p>
          Deux options s’offrent aux parties : rédiger plusieurs baux (un pour chacun des colocataires) ou 
          bien un contrat unique sous la forme d’un bail solidaire.
        </p>
      </div>
      <div className='faq'>
        <h5>Peut-on louer sans contrat de location ?</h5>
        <p>
          S’il n’est pas conseillé de choisir cette option, sachez qu’il est possible de conclure un bail 
          verbal pour une location destinée à l’habitation. Il pourra être prouvé, en cas de litige, par 
          tout moyen (justificatif de l’encaissement d’un loyer, témoignage des voisins etc.).
        </p>
      </div>
      <div className='faq'>
        <h5>Peut-on vendre un logement en cours de bail de location ?</h5>
        <p>
          Le propriétaire d’un logement peut décider de le mettre en vente en dépit la présence de 
          locataires. Dans ce cas, ces derniers n’ont pas à déménager : le bail se poursuit aux mêmes 
          conditions. Le nouveau propriétaire du logement se substitue alors dans les obligations de 
          l’ancien propriétaire.
        </p>
      </div>
    </div>
  );
}
