import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import axios from 'axios';
import StepProgress from '../../../../components/stepProgress/stepProgress';
import HeaderContainer from '../../../../components/headerContainer/headerContainer';
import CustomTextInput from '../../../../components/inputs/customTextInput/customTextInput';
import CustomRadioBox from '../../../../components/inputs/customRadioBox/customRadioBox';
import CustomCheckBox from '../../../../components/inputs/customCheckBox/customCheckBox';
import number from '../../../../assets/icons/number.png';
import CustomSelect from '../../../../components/inputs/customSelect';
import getEnvironement from '../../../../environnement';
import { setUser, userSelector } from '../../../../store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import Packs from '../../packs';

export default function AutoEntrepriseForm() {
  const [currentStep, setCurrentStep] = useState(1);

  const [delaiCreationEntreprise, setDelaiCreationEntreprise] = useState('');
  const [isDejaAutoEntrepreneur, setIsDejaAutoEntrepreneur] = useState('');
  const [siegeSocial, setSiegeSocial] = useState('');
  const [domaineActivite, setDomaineActivite] = useState('');
  const [plateformeCoursier, setPlateformeCoursier] = useState('');
  const [situationActuelle, setSituationActuelle] = useState('');
  const [isConnaissanceAutoEntreprise, setIsConnaissanceAutoEntreprise] = useState('');
  const [isAccompagnementCreationMicroEntreprise, setIsAccompagnementCreationMicroEntreprise] = useState('');
  const [genre, setGenre] = useState('');
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [adresseDomicile, setAdresseDomicile] = useState('');
  const [codePostal, setCodePostal] = useState('');
  const [ville, setVille] = useState('');
  const [adresseEmail, setAdresseEmail] = useState('');
  const [numeroTelephone, setNumeroTelephone] = useState('');
  const [recherche, setRecherche] = useState([]);
  const [selectedPack, setSelectedPack] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  const typeMicroEntreprise = location.state?.typeMicroEntreprise;

  const handelEnregisterClick = () => {
    const url = `${getEnvironement().API_URL}/autoEntreprise`;

    const data = new FormData();
    data.append('user_id', user.id);
    data.append('typeMicroEntreprise', typeMicroEntreprise);
    data.append('delaiCreationEntreprise', delaiCreationEntreprise);
    data.append('isDejaAutoEntrepreneur', isDejaAutoEntrepreneur);
    data.append('siegeSocial', siegeSocial);
    data.append('domaineActivite', domaineActivite);
    data.append('plateformeCoursier', plateformeCoursier);
    data.append('situationActuelle', situationActuelle);
    data.append('isConnaissanceAutoEntreprise', isConnaissanceAutoEntreprise);
    data.append('isAccompagnementCreationMicroEntreprise', isAccompagnementCreationMicroEntreprise);
    data.append('genre', genre);
    data.append('nom', nom);
    data.append('prenom', prenom);
    data.append('adresseDomicile', adresseDomicile);
    data.append('codePostal', codePostal);
    data.append('ville', ville);
    data.append('adresseEmail', adresseEmail);
    data.append('numeroTelephone', numeroTelephone);
    data.append('recherche', JSON.stringify(recherche));
    data.append('packId', selectedPack);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    axios.post(url, data, config)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setUser(response.data));
          navigate('/dashboard');
        }
      })
      .catch((err) => console.log(err));
  };

  const getQuestion = (currentStep) => {
    if (typeMicroEntreprise == 'coursier') {
      switch(currentStep) {
      case 1 :
        return (
          <Q1Coursier
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            value={plateformeCoursier}
            setValue={setPlateformeCoursier}
          />
        );
      case 2 :
        return (
          <QQuandActivite
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            value={delaiCreationEntreprise}
            setValue={setDelaiCreationEntreprise}
            typeMicroEntreprise={typeMicroEntreprise}
          />
        );
      case 3 :
        return (
          <Q3Coursier
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            value={situationActuelle}
            setValue={setSituationActuelle}
          />
        );
      case 4 :
        return (
          <Q4Coursier
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            value={isConnaissanceAutoEntreprise}
            setValue={setIsConnaissanceAutoEntreprise}
          />
        );
      case 5 :
        return (
          <Q5Coursier
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            value={isAccompagnementCreationMicroEntreprise}
            setValue={setIsAccompagnementCreationMicroEntreprise}
          />
        );
      case 6 :
        return (
          <QIdentite
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            genre={genre}
            setGenre={setGenre}
            prenom={prenom}
            setPrenom={setPrenom}
            nom={nom}
            setNom={setNom}
          />
        );
      case 7 :
        return (
          <QCoordonee
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            adresseDomicile={adresseDomicile}
            setAdresseDomicile={setAdresseDomicile}
            codePostal={codePostal}
            setCodePostal={setCodePostal}
            ville={ville}
            setVille={setVille}
          />
        );
      case 8 :
        return (
          <QEmail
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            value={adresseEmail}
            setValue={setAdresseEmail}
          />
        );
      case 9 :
        return (
          <QPhone
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            value={numeroTelephone}
            setValue={setNumeroTelephone}
          />
        );
      case 10 :
        return (
          <QRecherche
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            setValue={setRecherche}
            typeMicroEntreprise={typeMicroEntreprise}
            handelEnregisterClick={handelEnregisterClick}
          />
        );
      case 11 :
        return (
          <Packs
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            userId={user.id}
            typePack='entreprise'
            productType='autoEntreprise'
            setSelectedPack={setSelectedPack}
            data={{
              user_id: user.id,
              typeMicroEntreprise,
              delaiCreationEntreprise,
              isDejaAutoEntrepreneur,
              siegeSocial,
              domaineActivite,
              plateformeCoursier,
              situationActuelle,
              isConnaissanceAutoEntreprise,
              isAccompagnementCreationMicroEntreprise,
              genre,
              nom,
              prenom,
              adresseDomicile,
              codePostal,
              ville,
              adresseEmail,
              numeroTelephone,
              recherche,
              packId: selectedPack,
            }}
          />
        );
      case 12:
        return (
          <div className='mb-10'>
            <p className='text-base font-medium text-neutral mb-2'>Confirmation :</p>
            <div className='flex gap-5 mt-5'>
              <Button
                color='info'
                variant='outlined'
                onClick={() => {
                  setCurrentStep(currentStep - 1);
                }}
              >
                {'< Retour'}
              </Button>
              <Button
                color='primary'
                variant='contained'
                onClick={handelEnregisterClick}
              >
                Enregistrer
              </Button>
            </div>
          </div>
        );
      default :
        break;
      }
    } else {
      switch(currentStep) {
      case 1 :
        return (
          <QQuandActivite
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            value={delaiCreationEntreprise}
            setValue={setDelaiCreationEntreprise}
            typeMicroEntreprise={typeMicroEntreprise}
          />
        );
      case 2 :
        return (
          <Q2
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            value={isDejaAutoEntrepreneur}
            setValue={setIsDejaAutoEntrepreneur}
          />
        );
      case 3 :
        return (
          <Q3
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            value={siegeSocial}
            setValue={setSiegeSocial}
          />
        );
      case 4 :
        return (
          <Q4
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            value={domaineActivite}
            setValue={setDomaineActivite}
          />);
      case 5 :
        return (
          <QIdentite
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            genre={genre}
            setGenre={setGenre}
            prenom={prenom}
            setPrenom={setPrenom}
            nom={nom}
            setNom={setNom}
          />
        );
      case 6 :
        return (
          <QCoordonee
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            adresseDomicile={adresseDomicile}
            setAdresseDomicile={setAdresseDomicile}
            codePostal={codePostal}
            setCodePostal={setCodePostal}
            ville={ville}
            setVille={setVille}
          />
        );
      case 7 :
        return (
          <QEmail
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            value={adresseEmail}
            setValue={setAdresseEmail}
          />
        );
      case 8 :
        return (
          <QPhone
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            value={numeroTelephone}
            setValue={setNumeroTelephone}
          />
        );
      case 9 :
        return (
          <QRecherche
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            setValue={setRecherche}
            typeMicroEntreprise={typeMicroEntreprise}
            handelEnregisterClick={handelEnregisterClick}
          />
        );
      case 10 :
        return (
          <Packs
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            userId={user.id}
            typePack='entreprise'
            productType='autoEntreprise'
            setSelectedPack={setSelectedPack}
            data={{
              user_id: user.id,
              typeMicroEntreprise,
              delaiCreationEntreprise,
              isDejaAutoEntrepreneur,
              siegeSocial,
              domaineActivite,
              plateformeCoursier,
              situationActuelle,
              isConnaissanceAutoEntreprise,
              isAccompagnementCreationMicroEntreprise,
              genre,
              nom,
              prenom,
              adresseDomicile,
              codePostal,
              ville,
              adresseEmail,
              numeroTelephone,
              recherche,
              packId: selectedPack,
            }}
          />
        );
      case 11:
        return (
          <div className='mb-10'>
            <p className='text-base font-medium text-neutral mb-2'>Confirmation :</p>
            <div className='flex gap-5 mt-5'>
              <Button
                color='info'
                variant='outlined'
                onClick={() => {
                  setCurrentStep(currentStep - 1);
                }}
              >
                {'< Retour'}
              </Button>
              <Button
                color='primary'
                variant='contained'
                onClick={handelEnregisterClick}
              >
                Enregistrer
              </Button>
            </div>
          </div>
        );
      default :
        break;
      }
    }
  };

  return (
    <div>
      <HeaderContainer />
      <div className='h-screen flex flex-col justify-start items-center px-10 mt-10'>
        <h3 className=''>CREATION D&apos;UNE MICRO ENTREPRISE</h3>
        <StepProgress numberSteps={typeMicroEntreprise == 'coursier' ? 12 : 11} currentStep={currentStep}/>
        <div className='w-fit md:w-[700px]'>
          {getQuestion(currentStep)}
        </div>
      </div>
    </div>
  );
}

//---------------Questions---------------------
function QQuandActivite({
  currentStep,
  setCurrentStep,
  value,
  setValue,
  typeMicroEntreprise
}) {
  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Quand souhaitez-vous démarrer votre activité ?</p>
      <CustomSelect
        value={value}
        setValue={setValue}
        items={[
          {label: 'Dès que possible', value: 'Dès que possible'},
          {label: 'Dans la semaine', value: 'Dans la semaine'},
          {label: 'Dans le mois', value: 'Dans le mois'},
          {label: 'Je ne sais pas encore', value: 'Je ne sais pas encore'},
        ]}
      />
      <div className='flex gap-5 mt-5'>
        {typeMicroEntreprise == 'coursier' && (
          <Button
            color='info'
            variant='outlined'
            onClick={() => {
              setCurrentStep(currentStep - 1);
            }}
          >
            {'< Retour'}
          </Button>
        )}
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
      </div>
    </div>
  );
}

function Q1Coursier({currentStep, setCurrentStep, value, setValue}) {
  return (
    <div className='Q1Coursier'>
      <p className='text-base font-medium text-neutral mb-2'>Sur quelle plateforme souhaitez-vous devenir coursier ?</p>
      <CustomSelect
        value={value}
        setValue={setValue}
        items={[
          {label: 'Uber Eats', value: 'Uber Eats'},
          {label: 'Deliveroo', value: 'Deliveroo'},
          {label: 'Stuart', value: 'Stuart'},
          {label: 'Autres', value: 'Autres'},
        ]}
      />
      <div className='flex gap-5 mt-5'>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
      </div>
    </div>
  );
}

function Q2({currentStep, setCurrentStep, value, setValue}) {
  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Avez vous déjà été auto-entrepreneur ?</p>
      <CustomRadioBox
        value={value}
        setValue={setValue}
        items={[
          {label: 'Oui', value: 'Oui'},
          {label: 'Non', value: 'Non'},
        ]}
      />
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
      </div>
    </div>
  );
}

function Q3({currentStep, setCurrentStep, value, setValue}) {
  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Où sera fixé le siège social de la Société ?</p>
      <CustomSelect
        value={value}
        setValue={setValue}
        items={[
          {label: 'Domiciliation avec LegalStation', value: 'Domiciliation avec LegalStation'},
          {label: 'Au domicile du Gérant', value: 'Au domicile du Gérant'},
          {label: 'Dans un centre de domiciliation déjà choisi', value: 'Dans un centre de domiciliation déjà choisi'},
          {label: 'Dans d\'autres locaux', value: 'Dans d\'autres locaux'},
        ]}
      />
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
      </div>
    </div>
  );
}

function Q3Coursier({currentStep, setCurrentStep, value, setValue}) {
  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Quelle est votre situation actuelle ?</p>
      <CustomSelect
        value={value}
        setValue={setValue}
        items={[
          {label: 'Étudiant', value: 'Étudiant'},
          {label: 'Salarié', value: 'Salarié'},
          {label: 'Chômage / Sans emploi', value: 'Chômage / Sans emploi'},
          {label: 'Intérimaire', value: 'Intérimaire'},
          {label: 'Autre', value: 'Autre'},
        ]}
      />
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
      </div>
    </div>
  );
}

function Q4({currentStep, setCurrentStep, value, setValue}) {
  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Quelle est votre domaine d&apos;activité ?</p>
      <CustomSelect
        value={value}
        setValue={setValue}
        items={[
          {label: 'Consultants et freelance', value: 'Consultants et freelance'},
          {label: 'Construction et travaux', value: 'Construction et travaux'},
          {label: 'Coursier à vélo', value: 'Coursier à vélo'},
          {label: 'Automobile et transport', value: 'Automobile et transport'},
          {label: 'Commerce', value: 'Commerce'},
          {label: 'Achat revente', value: 'Achat revente'},
          {label: 'Service à la personne', value: 'Service à la personne'},
          {label: 'Autres', value: 'Autres'},
        ]}
      />
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
      </div>
    </div>
  );
}

function Q4Coursier({currentStep, setCurrentStep, value, setValue}) {
  return (
    <div className='Q4Coursier'>
      <p className='text-base font-medium text-neutral mb-2'>Savez-vous ce qu’est une micro-entreprise ?</p>
      <CustomRadioBox
        value={value}
        setValue={setValue}
        items={[
          {label: 'Oui', value: 'Oui'},
          {label: 'Non', value: 'Non'},
        ]}
      />
      <p className='text-base font-medium text-neutral mb-2'>
        <strong>A savoir :</strong> Indépendants, micro-entreprise, auto-entreprise, tous ces termes désignent la même structure. Légalement, micro-entreprise est le terme officiel.
      </p>
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
      </div>
    </div>
  );
}

function Q5Coursier({currentStep, setCurrentStep, value, setValue}) {
  return (
    <div className='Q5Coursier'>
      <p className='text-base font-medium text-neutral mb-2'>Bénéficiez-vous d’un accompagnement pour la création de votre micro-entreprise ?</p>
      <CustomRadioBox
        value={value}
        setValue={setValue}
        items={[
          {label: 'Oui', value: 'Oui'},
          {label: 'Non', value: 'Non'},
        ]}
      />
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
      </div>
    </div>
  );
}

function QIdentite({
  currentStep, setCurrentStep, genre, setGenre, prenom, setPrenom, nom, setNom
}) {
  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Quelle est votre identité ?</p>
      <CustomRadioBox
        value={genre}
        setValue={setGenre}
        items={[
          {label: 'Madame', value: 'Madame'},
          {label: 'Monsieur', value: 'Monsieur'},
        ]}
      />
      <CustomTextInput
        image={number}
        value={prenom}
        setValue={setPrenom}
        placeholder='Prénom'
      />
      <CustomTextInput
        image={number}
        value={nom}
        setValue={setNom}
        placeholder='Nom'
      />
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
      </div>
    </div>
  );
}

function QCoordonee({
  currentStep, setCurrentStep, adresseDomicile, setAdresseDomicile, codePostal, setCodePostal, ville, setVille
}) {
  return (
    <div className='QCoordonee'>
      <p className='text-base font-medium text-neutral mb-2'>Quelle est votre identité ?</p>
      <CustomTextInput
        image={number}
        value={adresseDomicile}
        setValue={setAdresseDomicile}
        placeholder='Adresse de votre domicile'
      />
      <CustomTextInput
        image={number}
        value={codePostal}
        setValue={setCodePostal}
        placeholder='Code postal'
      />
      <CustomTextInput
        image={number}
        value={ville}
        setValue={setVille}
        placeholder='Ville'
      />
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
      </div>
    </div>
  );
}

function QEmail({currentStep, setCurrentStep, value, setValue}) {
  return (
    <div className='QEmail'>
      <p className='text-base font-medium text-neutral mb-2'>Adresse email</p>
      <p className='text-base font-medium text-neutral mb-2'>Nous avons besoin de votre adresse mail pour sauvegarder vos réponses et finaliser votre dossier. Vous pourrez modifier vos réponses plus tard</p>
      <CustomTextInput
        image={number}
        value={value}
        setValue={setValue}
        placeholder='Adresse email'
      />
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
      </div>
    </div>
  );
}

function QPhone({currentStep, setCurrentStep, value, setValue}) {
  return (
    <div className='QPhone'>
      <p className='text-base font-medium text-neutral mb-2'>Numéro de téléphone</p>
      <CustomTextInput
        image={number}
        value={value}
        setValue={setValue}
        placeholder='Numéro de téléphone'
      />
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
          Continuer
        </Button>
      </div>
    </div>
  );
}

function QRecherche({
  currentStep,
  setCurrentStep,
  typeMicroEntreprise,
  setValue,
  // handelEnregisterClick
}) {
  const [recherche1, setRecherche1] = useState('');
  const [recherche2, setRecherche2] = useState('');
  const [recherche3, setRecherche3] = useState('');
  const [recherche4, setRecherche4] = useState('');

  useEffect(() => {
    setValue([
      recherche1 ? 'Lancer mon activité en déléguant toutes les démarches' : '',
      recherche2 ? 'Une solution tout en un (création, comptabilité, banque, etc..)' : '',
      recherche3 ? 'Du conseil juridique et fiscal avec un expert comptable ou/et avocat' : '',
      recherche4 ? 'Je me renseigne' : '',
    ]);
  }, [
    recherche1,
    recherche2,
    recherche3,
    recherche4,
  ]);
  return (
    <div className='QRecherche'>
      <p className='text-base font-medium text-neutral mb-2'>Que recherchez-vous ?</p>
      <div className='checkBoxContainer'>
        <CustomCheckBox
          setValue={setRecherche1}
          label='Lancer mon activité en déléguant toutes les démarches'
        />
        <CustomCheckBox
          setValue={setRecherche2}
          label='Une solution tout en un (création, comptabilité, banque, etc..)'
        />
        {typeMicroEntreprise != 'coursier'
            && <CustomCheckBox
              setValue={setRecherche3}
              label='Du conseil juridique et fiscal avec un expert comptable ou/et avocat'
            />}
        <CustomCheckBox
          setValue={setRecherche4}
          label='Je me renseigne'
        />
      </div>
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        {/* <Button
          color='primary'
          variant='contained'
          onClick={handelEnregisterClick}
        >
          Enregistrer
        </Button> */}
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
          Continuer
        </Button>
      </div>
    </div>
  );
}
