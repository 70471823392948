import { Button } from '@mui/material';
import React from 'react';
import comptabiliteHeaderImage from '../../../../../../../assets/images/comptabiliteHeaderImage.png';
import { useNavigate } from 'react-router-dom';

export default function InfoComptabiliteHeader() {
  const navigate = useNavigate();
  return (
    <section className='p-10 lg:p-[120px] flex md:justify-center lg:justify-between items-center bg-gradient-to-l from-gradient to-lightprimaryv4'>
      <div className='flex flex-col sm:justify-center sm:items-center lg:items-start gap-5'>
        <div className='w-[250px] lg:w-[400px] self-center lg:self-start'>
          <h1 className=' text-2xl text-center text-darkblue lg:text-start lg:text-4xl font-bold tracking-tight'>Notre expert-comptable, votre allié comptable réactif.</h1>
        </div>
        <div>
          <ul className="list-outside">
            <li className="text-sm font-medium"><span className='text-lightgreen text-2xl'>✓</span> Conseils illimités avec votre comptable</li>
            <li className="text-sm font-medium"><span className='text-lightgreen text-2xl'>✓</span> Logiciel simple et sans saisie manuelle</li>
            <li className="text-sm font-medium"><span className='text-lightgreen text-2xl'>✓</span> Ultra réactif par tchat, téléphone ou visio</li>
          </ul>
        </div>
        <div className='flex gap-3'>
          <Button onClick={() => navigate('/comptabilite/form')} variant='contained'>Démarrer gratuitement</Button>
          <Button onClick={() => navigate('/comptabilite/CreationComForm')} variant='outlined'>Créer ma société gratuitement</Button>
        </div>
      </div>
      <div className='hidden lg:block'>
        <img className='w-[350px]' src={comptabiliteHeaderImage} alt="" />
      </div>
    </section>
  );
}
