import React from 'react';
import dernierArticleIcon1 from '../../../../assets/images/dernierArticle1.png';
import dernierArticleIcon2 from '../../../../assets/images/dernierArticle2.png';
import dernierArticleIcon3 from '../../../../assets/images/dernierArticle3.png';
import './dernierArticles.css';

function Article({ icon, title }) {
  return (
    <div className='article'>
      <img src={icon} />
      <h3>{title}</h3>
      <p>EN SAVOIR PLUS</p>
    </div>
  );
}

export default function DernierArticles() {
  return (
    <section className='dernierArticlesContainer'>
      <div className='dernierArticles'>
        <h1>Derniers articles</h1>
        <div className='articleContainer'>
          <Article
            icon={dernierArticleIcon1}
            title='Domiciliation au Maroc : Les points importants à savoir'
          />
          <Article
            icon={dernierArticleIcon2}
            title='Morocco Now : Quelles nouveautés pour les sociétés?'
          />
          <Article
            icon={dernierArticleIcon3}
            title='Liquidation vs dissolution : quelle différence ?'
          />
        </div>
      </div>
    </section>
  );
}
