import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

export default function ConfirmAchatPackDialog({
  open,
  onClose,
  onClickNext
}) {
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Confirmation:</DialogTitle>
      <DialogContent>Vous n&apos;avez pas encors acheter ce pack ou vous avez arriver à la limite de produits possibles par ce pack, vous devez acheter ce pack pour continuer</DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={onClose}>Annuler</Button>
        <Button variant='contained' onClick={onClickNext}>Continuer</Button>
      </DialogActions>    
    </Dialog>
  );
}
