import React from 'react';
import { Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';

Font.register({
  family: 'abeezee',
  src: 'http://fonts.gstatic.com/s/abeezee/v9/JYPhMn-3Xw-JGuyB-fEdNA.ttf',
});

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    fontFamily: 'abeezee',
  },
  section: {
    marginBottom: 30,
  },
  heading: {
    fontSize: 20,
    fontWeight: 900,
    marginBottom: 20,
    textAlign: 'center',
  },
  subheading: {
    fontSize: 16,
    fontWeight: 900,
    marginBottom: 35,
  },
  paragraph: {
    marginBottom: 10,
    textAlign: 'justify',
    lineHeight: 1.5,
  },
  subparagraph: {
    marginBottom: 35,
    textAlign: 'center',
    lineHeight: 1,
    fontSize: 8,
  },
  listItem: {
    marginLeft: 25,
    lineHeight: 1.5,
    marginBottom: 10,
  },
  sublistItem: {
    marginLeft: 25,
    lineHeight: 1,
    marginBottom: 2.5,
  },
  italicSouligneGray: {
    textDecoration: 'underline',
    color: 'gray',
  },
  laws: {
    color: '#666',
    fontSize: 10,
  },
});

function Page5(props) {
  const {
    chooseDomaineSite,
    délaiMaximal_Remboursement_Remplacement_Réparation,
    isGarantieContractuelle,
    conditionsGarantieContractuelle,
    situationsGarantieApplique,
    situationsGarantiePasApplique,
    conditionsApplicationGarantieContractuelle,
  } = props;
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.subheading}>
          ARTICLE 8 - Responsabilité du Vendeur - Garanties
        </Text>
        <Text style={styles.paragraph}>
          Les Produits fournis par le Vendeur bénéficient :
        </Text>
        <Text style={styles.listItem}>
          * de la garantie légale de conformité, pour les Produits défectueux,
          abîmés ou endommagés ou ne correspondant pas à la commande,
        </Text>
        <Text style={styles.listItem}>
          * de la garantie légale contre les vices cachés provenant d&apos;un
          défaut de matière, de conception ou de fabrication affectant les
          produits livrés et les rendant impropres à l&apos;utilisation,
        </Text>
        <Text style={styles.italicSouligneGray}>
          Dispositions relatives aux garanties légales au Maroc
        </Text>
        <Text style={styles.paragraph}>
          Article 62 de la loi n° 31-08 sur la protection des consommateurs
        </Text>
        <Text style={styles.laws}>
          « Dans les contrats conclus entre les fournisseurs et les
          consommateurs, le fournisseur ne peut proposer sa garantie
          conventionnelle sans mentionner clairement que s’applique, en tout
          état de cause, la garantie légale qui oblige le vendeur à garantir le
          consommateur contre les défauts ou les vices cachés de la chose
          vendue. »
        </Text>
        <Text style={styles.paragraph}>
          Article 35 de la loi n° 31-08 sur la protection des consommateurs
        </Text>
        <Text style={styles.laws}>
          « Si la possibilité en a été prévue préalablement à la conclusion du
          contrat ou dans le contrat, le fournisseur peut fournir un produit, un
          bien ou un service d&apos;une qualité et d&apos;un prix équivalents.
          Le consommateur est informé de cette possibilité de manière claire et
          compréhensible. Les frais de retour consécutifs à l&apos;exercice du
          droit de rétractation sont, dans ce cas, à la charge du fournisseur et
          le consommateur doit en être informé.»
        </Text>
        <Text style={styles.paragraph}>
          Article 42 de la loi n° 31-08 sur la protection des consommateurs
        </Text>
        <Text style={styles.laws}>
          « Les mentions que doit contenir le formulaire visé à l’article 41
          précèdent sont fixées par voie réglementaire. »
        </Text>
        <Text style={styles.paragraph}>
          Article 60 de la loi n° 31-08 sur la protection des consommateurs
        </Text>
        <View style={styles.laws}>
          <Text>
            « Les dispositions relatives à la garantie légale des défauts de la
            chose vendue prévues aux articles 549 à 575 du Dahir du 9 Ramadan
            1331 (12 Août 1913)
          </Text>
          <Text style={styles.listItem}>
            formant Code des Obligations et des Contrats, sont applicables aux
            contrats de vente de biens ou de produits liant les consommateurs
            aux fournisseurs.
          </Text>
          <Text style={styles.listItem}>
            Toutefois, les dispositions du paragraphe 2° de l’article 571 du
            Dahir du 9 Ramadan 1331 (12 Août 1913) précité ne sont pas
            applicables aux contrats de vente de biens ou de produits conclus
            entre les fournisseurs et les consommateurs.
          </Text>
          <Text>»</Text>
        </View>
        <Text style={styles.paragraph}>
          Article 68 de la loi n° 31-08 sur la protection des consommateurs
        </Text>
        <Text style={styles.laws}>
          « La durée de validité d’une garantie conventionnelle et /ou le
          service aprèsvente prévue dans le contrat est prolongée d’un délai
          égal au temps pendant lequel le fournisseur a eu le bien ou le
          produit, en totalité ou en partie en sa possession aux fins
          d’exécution de la garantie et/ou du service après-vente. <br />
          Le fournisseur doit remettre au consommateur un accusé de réception
          qui fixe la date pendant laquelle il a eu en sa possession la
          marchandise objet de la garantie et/ou du service après-vente . »
        </Text>
        <Text style={styles.paragraph}>
          Afin de faire valoir ses droits, le Client devra informer le Vendeur,
          par écrit (mail ou courrier), de la non-conformité des Produits ou de
          l&apos;existence des vices cachés à compter de leur découverte.
        </Text>
        <Text style={styles.paragraph}>
          Le Vendeur remboursera, remplacera ou fera réparer les Produits ou
          pièces sous garantie jugés non conformes ou défectueux.
        </Text>
        <Text style={styles.paragraph}>
          Les frais d&apos;envoi seront remboursés sur la base du tarif facturé
          et les frais de retour seront remboursés sur présentation des
          justificatifs.
        </Text>
        <Text style={styles.paragraph}>
          Les remboursements, remplacements ou réparations des Produits jugés
          non conformes ou défectueux seront effectués dans les meilleurs délais
          et au plus tard dans les{' '}
          {délaiMaximal_Remboursement_Remplacement_Réparation ||
            '_______________'}{' '}
          jours suivant la constatation par le Vendeur du défaut de conformité
          ou du vice caché. Ce remboursement pourra être fait par virement ou
          chèque bancaire.
        </Text>
        <Text style={styles.paragraph}>
          La responsabilité du Vendeur ne saurait être engagée dans les cas
          suivants :
        </Text>
        <Text style={styles.listItem}>
          * non respect de la législation du pays dans lequel les produits sont
          livrés, qu&apos;il appartient au Client de vérifier,
        </Text>
        <Text style={styles.listItem}>
          * en cas de mauvaise utilisation, d&apos;utilisation à des fins
          professionnelles, négligence ou défaut d&apos;entretien de la part du
          Client, comme en cas d&apos;usure normale du Produit, d&apos;accident
          ou de force majeure.
        </Text>
        <Text style={styles.listItem}>
          * Les photographies et graphismes présentés sur le site ne sont pas
          contractuels et ne sauraient engager la responsabilité du Vendeur.
        </Text>
        <Text style={styles.paragraph}>
          La garantie du Vendeur est, en tout état de cause, limitée au
          remplacement ou au remboursement des Produits non conformes ou
          affectés d&apos;un vice.
        </Text>
        {isGarantieContractuelle && (
          <>
            <Text style={styles.italicSouligneGray}>
              Dispositions relatives à la garantie du Vendeur
            </Text>
            <Text style={styles.paragraph}>
              Les Produits achetés sur le site{' '}
              {chooseDomaineSite || '________________'} peuvent bénéficier,
              outre les garanties légales de conformité et des vices cachés
              d&apos;une garantie contractuelle comme indiqué le cas échéant au
              descriptif d&apos;un Produit donné, selon les termes, conditions et
              tarifs suivants :
            </Text>
            <Text style={styles.paragraph}>
              {conditionsGarantieContractuelle || '_________________________'}
            </Text>
            <Text style={styles.paragraph}>
              Cette garantie contractuelle couvre les situations suivantes :
            </Text>
            <Text style={styles.paragraph}>
              {situationsGarantieApplique || '_________________________'}
            </Text>
            <Text style={styles.paragraph}>
              Cette garantie est exclue dans les situations suivantes :
            </Text>
            <Text style={styles.paragraph}>
              {situationsGarantiePasApplique || '_________________________'}
            </Text>
            <Text style={styles.paragraph}>
              Elle est applicable dans les conditions suivantes :
            </Text>
            <Text style={styles.paragraph}>
              {conditionsApplicationGarantieContractuelle ||
                '_________________________'}
            </Text>
          </>
        )}
      </View>
    </Page>
  );
}

export default Page5;
