import React from 'react';
import { Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';

Font.register({
  family: 'abeezee',
  src: 'http://fonts.gstatic.com/s/abeezee/v9/JYPhMn-3Xw-JGuyB-fEdNA.ttf',
});

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    fontFamily: 'abeezee',
  },
  section: {
    marginBottom: 30,
  },
  heading: {
    fontSize: 20,
    fontWeight: 900,
    marginBottom: 20,
    textAlign: 'center',
  },
  subheading: {
    fontSize: 16,
    fontWeight: 900,
    marginBottom: 35,
  },
  paragraph: {
    marginBottom: 10,
    textAlign: 'justify',
    lineHeight: 1.5,
  },
  subparagraph: {
    marginBottom: 35,
    textAlign: 'center',
    lineHeight: 1,
    fontSize: 8,
  },
  listItem: {
    marginLeft: 25,
    lineHeight: 1.5,
    marginBottom: 10,
  },
  sublistItem: {
    marginLeft: 25,
    lineHeight: 1,
    marginBottom: 2.5,
  },
  italicSouligneGray: {
    textDecoration: 'underline',
    color: 'gray',
  },
  laws: {
    color: '#666',
    fontSize: 10,
  },
});

function Page7(props) {
  const {
    chooseDomaineSite,
    nomServiceMédiation,
    adressePostaleSrviceMédiation,
    siteInternetServiceMédiation,
    adresseEmailServiceMédiation,
  } = props;
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.subheading}>
          ARTICLE 10 - Propriété intellectuelle
        </Text>
        <Text style={styles.paragraph}>
          Le contenu du site {chooseDomaineSite || '________________'} est la propriété du Vendeur et de
          ses partenaires et est protégé par les lois françaises et
          internationales relatives à la propriété intellectuelle.
        </Text>
        <Text style={styles.paragraph}>
          Toute reproduction totale ou partielle de ce contenu est strictement
          interdite et est susceptible de constituer un délit de contrefaçon.
        </Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.subheading}>
          ARTICLE 11 - Droit applicable - Langue
        </Text>
        <Text style={styles.paragraph}>
          Les présentes CGV et les opérations qui en découlent sont régies et
          soumises au droit marocain.
        </Text>
        <Text style={styles.paragraph}>
          Les présentes CGV sont rédigées en langue française. Dans le cas où
          elles seraient traduites en une ou plusieurs langues étrangères, seul
          le texte français ferait foi en cas de litige.
        </Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.subheading}>ARTICLE 12 - Litiges</Text>
        <Text style={styles.paragraph}>
          Pour toute réclamation merci de contacter le service clientèle à
          l’adresse postale ou mail du Vendeur indiquée à l’ARTICLE 1 des
          présentes CGV.
        </Text>
        <Text style={styles.paragraph}>
          Le Client est informé qu&apos;il peut en tout état de cause recourir à une
          médiation conventionnelle, auprès des instances de médiation
          sectorielles existantes ou à tout mode alternatif de règlement des
          différends (conciliation, par exemple) en cas de contestation.{' '}
        </Text>
        <Text style={styles.paragraph}>
          En l’espèce, le médiateur désigné est :
        </Text>
        <Text style={styles.paragraph}>{nomServiceMédiation || '_______________'}</Text>
        <Text style={styles.paragraph}>{adressePostaleSrviceMédiation || '_______________'}</Text>
        <Text style={styles.paragraph}>{siteInternetServiceMédiation || '_______________'}</Text>
        <Text style={styles.paragraph}>E-mail : {adresseEmailServiceMédiation || '_______________'}. </Text>
        <Text style={styles.paragraph}>
          Le Client est également informé qu’il peut, également recourir à la
          plateforme de Règlement en Ligne des Litige (RLL)
          Cependant, pour les litiges non couverts par cette plateforme, le
          Client peut également se tourner vers les juridictions compétentes
          marocaines.
        </Text>
        <Text style={styles.paragraph}>
          Tous les litiges auxquels les opérations d&apos;achat et de vente conclues
          en application des présentes CGV et qui n’auraient pas fait l’objet
          d’un règlement amiable entre le vendeur ou par médiation, seront
          soumis aux tribunaux compétents au Maroc.
        </Text>
        <Text style={styles.paragraph}>
          Réalisé sur https://www.legalstation.com
        </Text>
      </View>
    </Page>
  );
}

export default Page7;
