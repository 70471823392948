import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Sidebar from './sidebar';
import Dashbody from './dashbody';
import { useNavigate } from 'react-router-dom';
import { userSelector } from '../../store/userSlice';

export default function Dashboard() {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [activeTab, setActiveTab] = useState('dashboard');

  const user = useSelector(userSelector);
  const navigate = useNavigate();

  useEffect(() => {
    if (user === null) {
      navigate('/login');
    }
  }, [user]);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return user && (
    <div className="flex bg-white max-h-screen overflow-hidden">
      <Sidebar
        isCollapsed={isCollapsed}
        handleToggle={handleToggle}
        handleTabClick={handleTabClick}
        activeTab={activeTab}
      />
      <Dashbody activeTab={activeTab} />
    </div>
  );
}
