import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import ContratFormSqueleton from '../../components/contratFormSqueleton/contratFormSqueleton';
import {
  QEmployeur,
  QSalarie,
  QDureeContrat,
  QFonctionExercee,
  QPeriodeEssai,
  QLieuTravail,
  QAutresClauses,
  QRemuniration,
  QEmail,
} from './questions';
import './formContratTravail.css';
import axios from 'axios';
import getEnvironement from '../../../../../environnement';
import { useNavigate } from 'react-router-dom';

export default function FormContratTravail({ typeContart }) {
  const maxStep = 9;

  const [currentStep, setCurrentStep] = useState(0);
  const [currentProgress, setCurrentProgress] = useState(0);

  // type employeur
  const [typeEmployeur, setTypeEmployeur] = useState('');
  
  // employeur
  const [nomPrenomEmployeurIndividu, setNomPrenomEmployeurIndividu] = useState('');
  const [adresseEmployeurIndividu, setAdresseEmployeurIndividu] = useState('');
  const [denomination, setDenomination] = useState('');
  const [adresseSiege, setAdresseSiege] = useState('');
  const [forme, setforme] = useState('');
  const [numeroRcs, setNumeroRcs] = useState('');
  const [villeRc, setVilleRc] = useState('');
  const [nomPrenomRepresentantSociete, setNomPrenomRepresentantSociete] = useState('');
  // salarié
  const [civilite, setCivilite] = useState('');
  const [nomSalarie, setNomSalarie] = useState('');
  const [prenomSalarie, setPrenomSalarie] = useState('');
  const [dateNaissanceSalarie, setDateNaissanceSalarie] = useState('');
  const [lieuNaissanceSalarie, setLieuNaissanceSalarie] = useState('');
  const [cin, setCin] = useState('');
  const [adresseSalarie, setAdresseSalarie] = useState('');
  const [nationaliteSalarie, setNationaliteSalarie] = useState('');
  // durée contrat
  const [dateDebutContrat, setDateDebutContrat] = useState('');
  const [dateFinContrat, setDateFinContrat] = useState('');
  // fonction exércée
  const [fonctionExercce, setFonctionExercce] = useState('');
  // periode d'éssai
  const [isPeriodeEssai, setIsPeriodeEssai] = useState(false);
  const [dureePeriodeEssai, setDureePeriodeEssai] = useState('');
  // lieu travail - mobilité
  const [lieuTravail, setLieuTravail] = useState('');
  const [isClauseMobilite, setIsClauseMobilite] = useState('');
  // autres clauses
  const [isRemboursementFraisProfessionnels, setIsRemboursementFraisProfessionnels] = useState(false);
  const [isFormation, setIsFormation] = useState(false);
  const [isCouvertureSociale, setIsCouvertureSociale] = useState(false);
  const [isCongesAnnuelsPayes, setIsCongesAnnuelsPayes] = useState(false);
  const [nbreJoursCongesPayes, setNbreJoursCongesPayes] = useState(0);
  const [isAbsence, setIsAbsence] = useState(false);
  const [isClauseMateriel, setIsClausemateriel] = useState(false);
  const [isSecretProfessionnel, setIsSecretProfessionnel] = useState(false);
  const [isClauseNonConcurrence, setIsClauseNonConcurrence] = useState(false);
  const [dureeClauseNonConcurrence, setDureeClauseNonConcurrence] = useState(0);
  const [isClauseExclusivite, setIsClauseExclusivite] = useState(false);
  const [isPreavis, setIsPreavis] = useState(false);
  const [dureePreavis, setDureePreavis] = useState(0);
  // rémunération
  const [montantRemuneration, setMontantRemuneration] = useState('');
  const [isRemunerationComplementaire, setIsRemunerationComplementaire] = useState(false);
  const [descriptionMontant, setDescriptionMontant] = useState('');
  const [periodeVersement, setPeriodeVersement] = useState('');

  const [emailDocument, setEmailDocument] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (!isPeriodeEssai) {
      setDureePeriodeEssai(0);
    }
    if (!isRemunerationComplementaire) {
      setDescriptionMontant('');
      setPeriodeVersement('');
    }
    if (!isCongesAnnuelsPayes) {
      setNbreJoursCongesPayes(0);
    }
    if (!isClauseNonConcurrence) {
      setDureeClauseNonConcurrence(0);
    }
    if (!isPreavis) {
      setDureePreavis(0);
    }
  }, [
    isPeriodeEssai,
    isRemunerationComplementaire,
    isCongesAnnuelsPayes,
    isClauseNonConcurrence,
    isPreavis,
  ]);

  useEffect(() => {
    const progressValue = (step) =>  {
      if (step === 0 || step === 1) {
        return 0;
      } else if (step === maxStep) {
        return 100;
      } else {
        return ((currentStep - 1) / (maxStep - 2)) * 100;
      }
    };
    setCurrentProgress(progressValue(currentStep));
    window.scrollTo({ top: 0 });
  }, [currentStep]);

  const handelEnregistrer = () => {
    const url = `${getEnvironement().API_URL}/contratTravail`;
    const body = {
      typeContart,
      typeEmployeur,
      nomPrenomEmployeurIndividu,
      adresseEmployeurIndividu,
      denomination,
      adresseSiege,
      forme,
      numeroRcs,
      villeRc,
      nomPrenomRepresentantSociete,
      civilite,
      nomSalarie,
      prenomSalarie,
      dateNaissanceSalarie,
      lieuNaissanceSalarie,
      cin,
      adresseSalarie,
      nationaliteSalarie,
      dateDebutContrat,
      dateFinContrat,
      fonctionExercce,
      isPeriodeEssai,
      dureePeriodeEssai,
      lieuTravail,
      isClauseMobilite,
      isRemboursementFraisProfessionnels,
      isFormation,
      isCouvertureSociale,
      isCongesAnnuelsPayes,
      nbreJoursCongesPayes,
      isAbsence,
      isClauseMateriel,
      isSecretProfessionnel,
      isClauseNonConcurrence,
      dureeClauseNonConcurrence,
      isClauseExclusivite,
      isPreavis,
      dureePreavis,
      montantRemuneration,
      isRemunerationComplementaire,
      descriptionMontant,
      periodeVersement,
      emailDocument,
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    axios.post(url, body, config)
      .then((response) => {
        if (response.status === 201) {
          navigate('/dashboard');
        }
      })
      .catch((err) => console.log(err));
  };

  const questionsIndividu = (step) => {
    switch (step) {
    case 1:
      return (
        <QEmployeur
          typeEmployeur={typeEmployeur}
          nomPrenomEmployeurIndividu={nomPrenomEmployeurIndividu}
          setNomPrenomEmployeurIndividu={setNomPrenomEmployeurIndividu}
          denomination={denomination}
          setDenomination={setDenomination}
          forme={forme}
          setforme={setforme}
          adresseEmployeurIndividu={adresseEmployeurIndividu}
          setAdresseEmployeurIndividu={setAdresseEmployeurIndividu}
          adresseSiege={adresseSiege}
          setAdresseSiege={setAdresseSiege}
          numeroRcs={numeroRcs}
          setNumeroRcs={setNumeroRcs}
          villeRc={villeRc}
          setVilleRc={setVilleRc}
          nomPrenomRepresentantSociete={nomPrenomRepresentantSociete}
          setNomPrenomRepresentantSociete={setNomPrenomRepresentantSociete}
        />
      );
    case 2:
      return (
        <QSalarie
          civilite={civilite}
          setCivilite={setCivilite}
          nomSalarie={nomSalarie}
          setNomSalarie={setNomSalarie}
          prenomSalarie={prenomSalarie}
          setPrenomSalarie={setPrenomSalarie}
          dateNaissanceSalarie={dateNaissanceSalarie}
          setDateNaissanceSalarie={setDateNaissanceSalarie}
          lieuNaissanceSalarie={lieuNaissanceSalarie}
          setLieuNaissanceSalarie={setLieuNaissanceSalarie}
          cin={cin}
          setCin={setCin}
          adresseSalarie={adresseSalarie}
          setAdresseSalarie={setAdresseSalarie}
          nationaliteSalarie={nationaliteSalarie}
          setNationaliteSalarie={setNationaliteSalarie}
        />
      );
    case 3:
      return (
        <QDureeContrat
          typeContart={typeContart}
          dateDebutContrat={dateDebutContrat}
          setDateDebutContrat={setDateDebutContrat}
          dateFinContrat={dateFinContrat}
          setDateFinContrat={setDateFinContrat}
        />
      );
    case 4:
      return (
        <QFonctionExercee
          fonctionExercce={fonctionExercce}
          setFonctionExercce={setFonctionExercce}
        />
      );
    case 5:
      return (
        <QPeriodeEssai
          isPeriodeEssai={isPeriodeEssai}
          setIsPeriodeEssai={setIsPeriodeEssai}
          dureePeriodeEssai={dureePeriodeEssai}
          setDureePeriodeEssai={setDureePeriodeEssai}
        />
      );
    case 6:
      return (
        <QRemuniration
          montantRemuneration={montantRemuneration}
          setMontantRemuneration={setMontantRemuneration}
          isRemunerationComplementaire={isRemunerationComplementaire}
          setIsRemunerationComplementaire={setIsRemunerationComplementaire}
          descriptionMontant={descriptionMontant}
          setDescriptionMontant={setDescriptionMontant}
          periodeVersement={periodeVersement}
          setPeriodeVersement={setPeriodeVersement}
        />
      );
    case 7:
      return (
        <QLieuTravail
          lieutravail={lieuTravail}
          setLieuTravail={setLieuTravail}
          isClauseMobilite={isClauseMobilite}
          setIsClauseMobilite={setIsClauseMobilite}
        />
      );
    case 8:
      return (
        <QAutresClauses
          isRemboursementFraisProfessionnels={isRemboursementFraisProfessionnels}
          setIsRemboursementFraisProfessionnels={setIsRemboursementFraisProfessionnels}
          isFormation={isFormation}
          setIsFormation={setIsFormation}
          isCouvertureSociale={isCouvertureSociale}
          setIsCouvertureSociale={setIsCouvertureSociale}
          isCongesAnnuelsPayes={isCongesAnnuelsPayes}
          setIsCongesAnnuelsPayes={setIsCongesAnnuelsPayes}
          nbreJoursCongesPayes={nbreJoursCongesPayes}
          setNbreJoursCongesPayes={setNbreJoursCongesPayes}
          isAbsence={isAbsence}
          setIsAbsence={setIsAbsence}
          isClauseMateriel={isClauseMateriel}
          setIsClausemateriel={setIsClausemateriel}
          isSecretProfessionnel={isSecretProfessionnel}
          setIsSecretProfessionnel={setIsSecretProfessionnel}
          isClauseNonConcurrence={isClauseNonConcurrence}
          setIsClauseNonConcurrence={setIsClauseNonConcurrence}
          dureeClauseNonConcurrence={dureeClauseNonConcurrence}
          setDureeClauseNonConcurrence={setDureeClauseNonConcurrence}
          isClauseExclusivite={isClauseExclusivite}
          setIsClauseExclusivite={setIsClauseExclusivite}
          isPreavis={isPreavis}
          setIsPreavis={setIsPreavis}
          dureePreavis={dureePreavis}
          setDureePreavis={setDureePreavis}
        />
      );
    case 9:
      return (
        <QEmail
          emailDocument={emailDocument}
          setEmailDocument={setEmailDocument}
        />
      );
    default:
      return null;
    }
    
  };

  
  return (
    <div id='formContratTravail'>
      <ContratFormSqueleton
        progressValue={currentProgress}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        maxStep={maxStep}
        onClickEnregistrer={handelEnregistrer}
      >
        {currentStep === 0
          && (<EntrepriseIndividuQuestion
            setEntrepriseIndividu={setTypeEmployeur}
            setCurrentStep={setCurrentStep}
          />)
        }
        {questionsIndividu(currentStep)}
      </ContratFormSqueleton>
    </div>
  );
}

function EntrepriseIndividuQuestion({ setEntrepriseIndividu, setCurrentStep }) {
  return (
    <>
      <h1>En tant qu’employeur je suis :</h1>
      <div className='entrepriseIndividuContainer'>
        <Button
          variant='contained'
          onClick={() => {
            setEntrepriseIndividu('entreprise');
            setCurrentStep(1);
          }}
        >
          Une entreprise
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            setEntrepriseIndividu('individu');
            setCurrentStep(1);
          }}
        >
          Un individu
        </Button>
      </div>
    </>
  );
}
