import React from 'react';
import connecteBanque1 from '../../../../../../../assets/images/connecteBanque1.png';
import connecteBanque2 from '../../../../../../../assets/images/connecteBanque2.png';
import './connecteBanque.css';

export default function ConnecteBanque() {
  return (
    <section className='connecteBanqueContainer'>
      <div className='connecteBanque'>
        <h1>Connecté à votre banque</h1>
        <p>Synchronisé de manière sécurisée avec plus de 60 banques professionnelles</p>
        <div className='connecteBanqueImagesContainer'>
          <img src={connecteBanque1} />
          <img src={connecteBanque2} />
        </div>
      </div>
    </section>
  );
}
