import React from 'react';
import './btnform.css';
export default function SarlBtnForm({
  Icon,
  isMuiIcon = false,
  isActive = false,
  text = '',
  componentStyle = {},
  onClick = () => { },
}) {
  return (
    <div
      className={`btn-form ${isActive && 'active'} w-1/5 min-w-[130px]`}
      style={componentStyle}
      onClick={onClick}
    >
      <div className="btn-icon">
        {isMuiIcon ? Icon : <img src={Icon} alt="icon" />}
      </div>
      <p className="btn-text">{text}</p>
    </div>
  );
}
