import React from 'react';
import HandleContent from '../../../../../components/handleContent/handleContent';
import './autresDemarchesContentSqueleton.css';

export default function AutresDemarchesContentSqueleton({
  titleContent,
  titleLeftContent,
  textButton,
  rightContent,
  itemsContent,
  isAutresDemarche = false,
  onClick=() => {}
}) {
  return (
    <div>
      <div className='titleAutresDemarchesContentContainer'>
        <div className='emptyDiv' />
        <h1 className='titleAutresDemarchesContent'>{titleContent}</h1>
      </div>
      <HandleContent
        title={titleLeftContent}
        textButton={textButton}
        items={itemsContent}
        rightContent={rightContent}
        isAutresDemarche={isAutresDemarche}
        onClick={onClick}
      />
    </div>
  );
}
