import React from 'react';
import './demarcheCreation.css';
import user from '../../../../assets/icons/user.svg';
import sac from '../../../../assets/icons/sac.svg';
import coeur from '../../../../assets/icons/coeur.svg';
import process from '../../../../assets/images/Procces.png';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

function BlockIcon({ icon, title }) {
  return (
    <div className='blockIcon'>
      <img src={icon} />
      <p>{title}</p>
    </div>
  );
}


export default function DemarcheCreation() {
  return (
    <section className='demarcheCreationContainer'>
      <div className='demarcheCreation'>
        <h1>Démarches de création</h1>
        <p>La loi marocaine donne la possibilité de choisir entre plusieurs formes juridiques pour votre entreprise</p>
        <div className='blockIconContainer'>
          <BlockIcon icon={user} title='Personne physique' />
          <BlockIcon icon={sac} title='Personne morale (société)' />
          <BlockIcon icon={coeur} title='Personne morale (société)' />
        </div>
        <img className='process' src={process} />
        <div className='buttonProcess'>
          <Link to='/creationEntreprise' onClick={() => window.scrollTo(0, 0)}>
            <Button className='button' variant="contained" size='small'>En Savoir +</Button>
          </Link>
        </div>
      </div>
    </section>
  );
}
