import React from 'react';
import { Link } from 'react-router-dom';
// import HeaderContainer from '../../../../components/headerContainer/headerContainer';
import ContratContentSqueleton from '../components/contratContentSqueleton/contratContentSqueleton';
// import Footer from '../../../../components/footer/footer';
import './pacteAssocies.css';

export default function InfoPacteAssocies() {
  const itemsContent = [
    {title: 'Qu\'est-ce qu\'un pacte d\'associés ?', link: '#1'},
    {title: 'Dans quels cas conclure un pacte d\'associés ?', link: '#2'},
    {title: 'Quelle différence entre pacte d\'associé et statuts de la société ?', link: '#3'},
    {title: 'Quels sont les différents types de clauses ?', link: '#4'},
    {title: 'Quelles sanctions en cas de violation du pacte d\'associés ?', link: '#5'},
    {title: 'Comment modifier le pacte d\'associés ?', link: '#6'},
    {title: 'Quand le pacte d\'associés prend-il fin ?', link: '#7'},
  ];
  return (
    <ContratContentSqueleton
      link='form'
      title='Pacte d&apos;associés'
      subTitle='Pacte d’associés adapté aux sociétés par actions simplifiées'
      titleLeftContent=''
      textButton='Remplir ce modèle'
      titleContent='A propos du pacte d&apos;associés'
      rightContent={<PacteAssociesContent />}
      itemsContent={itemsContent}
    />

  );
}

function PacteAssociesContent() {
  return (
    <div className='pacteAssociesContent'>
      <h3 id='1'>Qu&apos;est-ce qu&apos;un pacte d&apos;associés ?</h3>
      <p><strong>Le pacte d’associés</strong> (ou pacte d’actionnaires) est une convention extra-statutaire, venant compléter les <Link className='linkContent' to='#'>statuts de SAS</Link>, par laquelle les associés de la société fixent les règles qu’ils choisissent de s’imposer entre eux, en sus des statuts de la société. Il est également possible de rédiger un <Link className='linkContent' to='#'>pacte d’associés pour une start-up</Link>.</p>
      <p>Ces règles peuvent concerner divers aspects de la société, tels que :</p>
      <ul className='list-wtl'>
        <li>Sa gouvernance, sa gestion et la conduite de ses affaires,</li>
        <li>La composition et l’évolution de son capital,</li>
        <li>Le traitement des hommes clés dans la société,</li>
        <li>Et, le cas échéant, tout autre engagement particulier pris par tout ou partie des associés de la SAS.</li>
      </ul>
      <p>Les signataires disposent d’une <strong>grande liberté contractuelle</strong> dans la fixation des termes et conditions du pacte.</p>
      <p>Ce pacte n’est <strong>pas nécessairement signé par tous les associés de la SAS</strong> : il peut par exemple n’être signé qu’entre les associés majoritaires fondateurs (à l’exclusion des investisseurs), entre le fondateur et de nouveaux investisseurs, etc.</p>
      <p>Il est par ailleurs <strong>important que la société soit elle-même signataire du pacte</strong>, afin de lui faire prendre certains engagements, notamment celui d’être le gestionnaire du pacte, en charge de sa bonne exécution.</p>
      <p className='infoContentRed'><strong>Attention :</strong> le modèle de pacte d’associé présenté ici n’est applicable qu’aux sociétés par actions simplifiées. En effet, le <Link className='linkContent' to='#'>pacte d’associés de SARL</Link> est différent.</p>
      
      <h3 id='2'>Dans quels cas conclure un pacte d&apos;associés ?</h3>
      <p><strong>Le pacte d’associés n’est pas un document obligatoire</strong>, contrairement aux statuts de la société.</p>
      <p>Néanmoins, <strong>la signature d’un pacte est recommandée</strong> lorsque les associés souhaitent organiser leur relation dans la société de manière plus détaillée que dans les seuls statuts, à titre confidentiel, ou lorsqu’ils s’octroient des avantages particuliers.</p>
      <p>La signature d’un pacte par ailleurs <strong>recommandée dans certaines situations</strong> au cours de la vie d’une société, notamment :</p>
      <ul className='list-wtl'>
        <li>A la création de la société</li>
        <li>Lors de l’entrée de nouveaux partenaires ou collaborateurs au capital</li>
        <li>Lors de l’entrée d’investisseurs au capital</li>
      </ul>
      <h6>Lors de la création de la société</h6>
      <p>Le pacte sera alors conclu entre les fondateurs, afin de <strong>fixer les objectifs commun, d’organiser la gouvernance et les transferts d’actions</strong>.</p>
      <h6>Lors de l’entrée de partenaires ou de collaborateurs au capital</h6>
      <p>Le pacte sera alors conclu afin de <strong>lier leur qualité d’associé au partenariat envisagé</strong>, de leur <strong>interdire de concurrencer</strong> par ailleurs la société et de prévoir un <strong>mécanisme de rachat forcé de leurs actions s’ils cessent leur collaboration</strong>.</p>
      <h6>Lors de l’entrée d’investisseurs au capital</h6>
      <p>Cette fois, un pacte d’associés sera conclu <strong>afin d’organiser les droits qu’ils exigeront</strong>, tels que :</p>
      <ul className='list-wtl'>
        <li>Leur droit à l’information sur la conduite des affaires,</li>
        <li>Le contrôle des décisions importantes prises dans le cadre de la gouvernance de la société,</li>
        <li>Le droit de sortie conjointe avec les majoritaires, assortie d’une obligation de sortie conjointe des investisseurs si les fondateurs majoritaires décident de vendre la société, etc.</li>
      </ul>

      <h3 id='3'>Quelle différence entre pacte d&apos;associé et statuts de la société ?</h3>
      <p>La différence principale entre le pacte d’associés et les statuts tient en la <Link className='linkContent' to='#'>sanction de la violation de leurs stipulations</Link>.</p>
      <h6>La sanction de la violation du pacte d’associé</h6>
      <p>En principe, la violation d’un pacte d’associés engage la <strong>responsabilité civile contractuelle de la partie</strong> concernée et l’octroi de <strong>dommages-intérêts à la partie lésée</strong>.</p>
      <p>Toutefois, l’<Link className='linkContent' to='#'>ordonnance n°2016-131 du 10 février 2016</Link> renforce de manière générale les droits des parties au pacte. Dans certains cas, il sera donc <strong>possible d’obtenir l’exécution forcée du pacte</strong>, notamment dans le cas de violations de promesses de cession ou de <Link className='linkContent' to='#'>droit de préférence</Link> insérés dans le pacte d’associés.</p>
      <h6>La sanction de la violation d’une clause statutaire</h6>
      <p><strong>A l’inverse</strong>, la <strong>sanction de la violation d’une clause statutaire est en principe lourde</strong>. Elle peut résulter (dans les cas visés par le code de commerce, telle que la clause d’agrément statutaire) en l’<strong>annulation de l’acte conclu en violation des statuts</strong>.</p>
      <p>Dans une SAS, les statuts présentent une grande liberté rédactionnelle et il peut être intéressant d’y stipuler un certain nombre d’engagements relatifs au relations capitalistiques et managériales.</p>
      <p>Toutefois, l’inconvénient d’insérer de telles clauses dans les statuts est qu’<strong>elle ne seront pas confidentielles</strong>. En effet, les statuts sont déposés au greffe du tribunal de commerce et à la disposition du public, tandis que <strong>le pacte contiendra le plus souvent une clause de confidentialité</strong>.</p>
      <p>Par ailleurs, dès lors que certaines clauses statutaires offrent des droits particuliers à certains associés ou à une catégorie d’associés, il conviendra de respecter la procédure du commissariat aux avantages particuliers qui est coûteuse. Dans ce cas précis, il sera plus avantageux de faire figurer ces avantages dans un pacte d’associé, afin de limiter les frais relatifs à la procédure précédemment citée.</p>

      <h3 id='4'>Quels sont les différents types de clauses ?</h3>
      <p>Il existe de très nombreuses variétés de clauses dans les pactes d’associés, dont l’utilité diffère selon le type de relations souhaitées : entre fondateurs, entre les fondateurs et les investisseurs, entre les repreneurs et les fondateurs restés minoritaires au capital, etc.</p>
      <p><strong>Les clauses concernant l’organisation, <Link className='linkContent' to='#'>la gouvernance</Link>, le fonctionnement ou le financement de la société</strong></p>
      <ul className='list-wtl'>
        <li>Clause instaurant un organe de gouvernance ou par laquelle les associés s’accordent sur la direction de la société</li>
        <li>Clause de contrôle des décisions importantes qui peut prendre la forme de l’instauration d’un droit de veto ou la création d’un organe en charge de donner son approbation aux décisions importantes</li>
        <li>Clause d’information renforcée</li>
        <li>Clause de résolution de situation de blocage en cas d’associés égalitaires (également appelée clause de buy or sell)</li>
        <li>Clause de confidentialité</li>
        <li>Clause relative aux distributions de dividendes, imposant des distributions de dividendes sous certaines conditions (afin que, par exemple, des associés minoritaires s’assurent que la société effectuera des distributions)</li>
        <li>Clause de financement fixant les conditions de financement de la société par les avances en comptes courant de ses associés ou fixant les conditions de futurs investissements ou endettements financiers</li>
      </ul>
      <p><strong>Les clauses concernant le capital et les cessions d’actions</strong></p>
      <ul className='list-wtl'>
        <li>Clause d’inaliénabilité (ou d’incessibilité) temporaire ne pouvant excéder 10 ans.</li>
        <li><Link className='linkContent' to='#'>Clause d’agrément</Link> de toute nouvelle cession ou transferts d’actions (soit à des tiers, soit même entre associés)</li>
        <li><Link className='linkContent' to='#'>Clause de droit de préemption</Link> permettant à ses bénéficiaire d’acheter en priorité les actions que les associés ont prévu de vendre à un tiers</li>
        <li><Link className='linkContent' to='#'>Clauses de sortie conjointe</Link>, et notamment celle de droit de sortie conjointe (également appelé tag along) permettant à ses bénéficiaire de s’assurer vendre leurs actions en même temps (et au même prix) que les majoritaires</li>
        <li><Link className='linkContent' to='#'>Clause d’obligation de sortie conjointe</Link> (également appelé drag along) permettant aux majoritaires de forcer les minoritaires à vendre leurs actions en même temps qu’eux.</li>
        <li>Clause de ratchet ou de relution ou de liquidation préférentielle permettant aux investisseurs de se reluer dans le capital en cas de survenance de certains évènement ou de bénéficier d’une quote-part supplémentaire du prix de cession en cas de vente de la société</li>
        <li><Link className='linkContent' to='#'>Clause d’anti-dilution</Link> permettant à ses bénéficiaires de s’assurer qu’il pourront maintenir leur niveau de participation lors de toute augmentation de capital (pour autant qu’ils puissent y participer)</li>
        <li>Clause de changement de contrôle d’un associé permettant d’anticiper les conséquences du changement de contrôle d’un associé personne morale</li>
        <li><Link className='linkContent' to='#'>Clause de liquidité</Link>  permettant d’organiser la sortie des investisseurs à terme. Comme il existe une <Link className='linkContent' to='#'>clause de sortie garantie</Link> qui est une forme de clause de liquidité.</li>
      </ul>
      <p>Les clauses concernant les associés exerçant des fonctions opérationnelles clés</p>
      <ul className='list-wtl'>
        <li>Clause de non concurrence interdisant les associés opérationnels de concurrencer la société (y compris pendant une certaine durée après leur départ)</li>
        <li>Clause d’exclusivité obligeant les associés opérationnels à consacrer tout ou partie de leur temps à leurs fonctions dans la société</li>
        <li>Clause de good leaver ou de bad leaver obligeant les associés opérationnels (ou leurs héritiers) à vendre leurs actions aux autres associés s’ils cessent leurs fonctions involontairement (good leaver), notamment en cas de décès ou d’incapacité, ou pour des raisons qui leur sont imputables (bad leaver) notamment en cas de démission ou licenciement pour faute grave.</li>
      </ul>

      <h3 id='5'>Quelles sanctions en cas de violation du pacte d&apos;associés ?</h3>
      <p>La sanction de la violation des clauses du pacte d’associés <strong>diffère selon la nature de la clause qui n’a pas été respectée</strong>. En principe, elle consiste en la condamnation de la partie ayant violé ses engagement à l’octroi de dommages intérêts. Néanmoins, <strong>dans certains cas, la sanction peut résulter en l’exécution forcée des engagements non tenus</strong> (notamment en ce qui concerne les promesses de cession ou les droits de préemption, suite à la réforme du droit des contrats).</p>
      <p>Par ailleurs, <strong>le pacte peut prévoir ses propres sanctions</strong>, telle que l’obligation pour l’associé qui aura violé des engagements importants à céder ses actions aux autres associés (le cas échéant à un prix subissant une décote par rapport à la valeur de marché).</p>
      <p>Le gestionnaire du pacte aura le droit de forcer les cessions ou de refuser de reporter sur le registre de mouvements de titres des cessions passées en violation des stipulations du pactes, afin de s’assurer une certaine efficacité dans la mise en oeuvre du pacte d’associés.</p>

      <h3 id='6'>Comment modifier le pacte d&apos;associés ?</h3>
      <p>Le pacte d’associés est un contrat et donc, contrairement aux statuts, <strong>il ne peut être modifié qu’à l’unanimité des parties</strong>.</p>
      <p>Il peut toutefois être prévu dans le pacte que de <strong>nouveaux associés</strong> qui ont acheté ou souscrit des actions <strong>seront automatiquement parties au pacte par la seule signature d’un bulletin d’adhésion</strong> également signé par la société.</p>

      <h3 id='7'>Quand le pacte d&apos;associés prend-il fin ?</h3>
      <p>Un pacte d’associés ou d’actionnaires doit utilement avoir <strong>une durée de vie équivalente à celle pendant laquelle les parties resteront associées dans la société concernée</strong>.</p>
      <p>Toutefois, <strong>afin de ne pas signer un contrat à durée indéterminée</strong> (qui pourrait être résilié unilatéralement par n’importe quelle partie), <strong>il convient de prévoir une durée fixe</strong>.</p>
      <p>Dans les modèles proposés par LegalPlace, cette dernière est fixée à 10 ans avec renouvellements possibles par périodes de 5 ans. Cela présente l’inconvénient d’offrir la possibilité à chaque partie de sortir du pacte si elle en manifeste la volonté au terme de chaque période, mais cet inconvénient s’avère bien moindre que la faculté de résiliation unilatérale à tout moment attachée à la durée indéterminée.</p>
      <p>Par ailleurs, certaines clauses du pacte comportent leurs propres durées selon la nature des engagements stipulés (la clause de non concurrence pouvant durer plus longtemps, les clauses de good et bad leaver pouvant avoir une durée plus limitée, etc.).</p>
    </div>
  );
}