import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function ChangeAdresseHeader() {
  const navigate = useNavigate();
  return (
    <div className=' p-10 lg:p-[120px] bg-gradient-to-b from-gradient to-white lg:mb-10 flex flex-col justify-center items-center gap-6 border-b-[1px] border-lightgrayv2'>
      <div className='flex flex-col justify-center items-center'>
        <h1 className='text-lg text-center mb-4 text-darkblue lg:text-start lg:text-2xl font-bold lg:mb-2'>Changement de <span className='text-primary'>statuts </span> simple et rapide !</h1>
        <p className='text-center text-darkblue font-medium'>Procédure 100% en ligne pour modifier vos statuts en toute simplicité.<br></br> Dossier prêt en 10mn. <span className='font-bold'>Traitement sous 24h.</span> Assistance téléphonique gratuite !</p>
      </div>
      <div className=''>
        <Button variant='contained' onClick={() => navigate('/changementStatut/form')}>Modifier les statuts de ma société</Button>
      </div>
    </div>
  );
}

export default ChangeAdresseHeader;