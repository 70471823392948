import React from 'react';
import './footer.css';

export default function Footer() {
  return (
    <div>
      <div className='footerContainer'>
        <div className='footer'>

          <div>
            <h4>À propos</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
              Aenean eu fermentum augue, sit amet convallis augue. 
              Integer eu iaculis sem, sed euismod eros. Nulla facilisi. Proin
            </p>
          </div>
          <div>
            <h4>Adresse</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </div>
          <div>
            <h4>Pour nous contacter</h4>
            <p>
              Loremipsum@contact.com
            </p>
            <h4>Suivez-nous</h4>
          </div>
          <div>
            <h4>S&apos;abonner à la newsletter</h4>
            <p>
              Inscrivez-vous pour être le premier à être informé des mises à jour. Entrez votre email
            </p>
          </div>
        </div>
      </div>
      <div className='copywriteContainer'>
        <div className='copywrite'>
          <p>Copyright © 2023 legal station</p>
        </div>
      </div>
    </div>
  );
}
