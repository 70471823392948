// /* eslint-disable */
import React from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 12,
  },
  section: {
    marginBottom: 20,
  },
  heading: {
    fontSize: 17,
    fontWeight: 800,
    marginBottom: 17,
    textAlign: 'center',
  },
  subheading: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  paragraph: {
    marginBottom: 10,
    textAlign: 'justify',
    lineHeight: 1.5,
  },
  listItem: {
    marginLeft: 15,
    lineHeight: 1.5,
    marginBottom: 10,
  },
  sublistItem: {
    marginLeft: 25,
    lineHeight: 1.5,
    marginBottom: 5,
  },
  Signature: {
    display: 'flex',
    flexWrap: 'no-wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    gap: 10,
  },
  subSignature: {
    display: 'flex',
    flexWrap: 'no-wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 10,
  },
});

function Page2(props) {
  const {
    bailleurRepresentant,
    locataireRepresentant,
    // F6
    loyerMensuel,
    loyerPrincipal,
    charges,
    taxeEdilite,
    loyerTotal,
    paiementLoyerDate,
    // F7
    majorationLoyer,
    // periodeTriennale,
    // F8
    cautionMontant,
    cautionBancaire,
    // F9
    domicileBailleur,
    // domicileLocataire,
    // F10
    signatureDate,
  } = props;

  return (
    <Page size="A4" style={styles.page}>
      {/* Article 4 : Clause particulières */}
      <View style={styles.section}>
        <Text style={styles.subheading}>Article 4 : Clause particulières</Text>
        <Text style={styles.paragraph}>
          Le locataire a versé au propriétaire qui le reconnaît la somme de
          {cautionMontant || '..........................'} dhs (
          {cautionBancaire || '..........................'} dirhams) à titre de caution.
          Cette somme lui sera restituée à son départ après remise des locaux
          libres de tout occupant et sous déduction éventuelle de toute somme
          due à quelque titre que ce soit (Loyer, ou remise en état des locaux
          etc.…).
        </Text>
      </View>

      {/* Article 5 : Loyer */}
      <View style={styles.section}>
        <Text style={styles.subheading}>Article 5 : Loyer</Text>
        <Text style={styles.paragraph}>
          Le présent bail est consenti et accepté moyennant un loyer mensuel
          forfaitaire de {loyerMensuel || '..........................'} dhs ({' '}
          {loyerTotal || '..........................'} ) se décomposant comme suit :
        </Text>
        <Text style={styles.listItem}>
          - Loyer principal : {loyerPrincipal || '..........................'} dhs
        </Text>
        <Text style={styles.listItem}>
          - Charges : {charges || '..........................'} dhs
        </Text>
        <Text style={styles.listItem}>
          - Taxe d’édilité (10% actuellement) :{' '}
          {taxeEdilite || '..........................'} dhs
        </Text>
        <Text style={styles.listItem}>
          - Total : {loyerTotal || '..........................'} dhs
        </Text>
        <Text style={styles.paragraph}>
          Ledit loyer est payable trimestriellement par chèque bancaire
          certifié, au plus tard le 5 du premier mois de chaque trimestre, les
          trimestres du présent bail commençant le 1er juillet,{' '}
          {paiementLoyerDate || '..........................'} de chaque année.
        </Text>
        <Text style={styles.paragraph}>
          Le locataire reconnaît que le taux du loyer ci-dessous fixé avec son
          plein accord, correspond à la valeur locative réelle des locaux loués.
          Il s’interdit de contester ce taux de loyer pour quelque cause que ce
          soit.
        </Text>
      </View>
      {/* Article 6 : Révision du loyer */}
      <View style={styles.section}>
        <Text style={styles.subheading}>Article 6 : Révision du loyer</Text>
        <Text style={styles.paragraph}>
          Pour éviter tout litige judiciaire sur la révision du loyer, (y
          compris charges et taxe d’édilité) les parties conviennent d’un commun
          accord d’appliquer une majoration de {majorationLoyer || '10%'} sur le
          loyer à l’expiration de chaque période triennale.
        </Text>
        <Text style={styles.paragraph}>
          Cette majoration sera appliquée automatiquement sans qu’il soit besoin
          d’une mise en demeure ou procédure judiciaire quelconque.
        </Text>
        <Text style={styles.paragraph}>
          Le locataire s’oblige d’ores et déjà à accepter sans aucune réserve
          ladite majoration triennale.
        </Text>
      </View>
      {/* Article 7 : Caution bancaire */}
      <View style={styles.section}>
        <Text style={styles.subheading}>Article 7 : Caution bancaire</Text>
        <Text style={styles.paragraph}>
          Le locataire doit présenter une caution bancaire par laquelle la
          banque s’engage à payer le loyer trimestriellement dans le cas où le
          locataire serait défaillant, et ce sur simple lettre recommandée du
          bailleur. Cette caution bancaire, d’une durée de{' '}
          {cautionBancaire || 'deux'} ans, doit être renouvelée 15 jours avant
          son expiration. Son non-renouvellement signifie la résiliation du bail
          par le locataire.
        </Text>
      </View>
      {/* Article 8 : Clause résolutoire */}
      <View style={styles.section}>
        <Text style={styles.subheading}>Article 8 : Clause résolutoire</Text>
        <Text style={styles.paragraph}>
          En cas de non-paiement d’un seul terme de loyer à l’échéance prévue
          par l’article 4, ou d’inexécution par la partie locataire d’une seule
          clause du présent bail, celui-ci pourra être considéré comme résilié
          de plein droit, si bon semble au bailleur, 15 jours après l’envoi au
          locataire d’une mise en demeure de payer ou de se conformer à la
          clause du bail, et contenant déclaration par le bailleur de son
          intention de se prévaloir de la présente clause résolutoire.
        </Text>
        <Text style={styles.paragraph}>
          Ladite mise en demeure pourra être effectuée par simple lettre
          recommandée avec accusé de réception, adressée par le bailleur au
          locataire, et produira tous ses effets même si elle n’était pas
          retirée par son destinataire. Dans ce cas, pleine compétence est
          attribuée à Monsieur le Juge des Référés auprès du tribunal de 1ère
          instance de Casablanca, pour ordonner l’expulsion du locataire des
          locaux, et pour ordonner le règlement des sommes encore dues.
        </Text>
      </View>

      {/* Article 9 : Frais */}
      <View style={styles.section}>
        <Text style={styles.subheading}>Article 9 : Frais</Text>
        <Text style={styles.paragraph}>
          Tous les frais des présentes, droit d’enregistrement et autres, ainsi
          que leurs suites et conséquences, seront à la charge du locataire qui
          s’y oblige.
        </Text>
      </View>
      {/* Article 10 : Clause attributive de juridiction */}
      <View style={styles.section}>
        <Text style={styles.subheading}>
          Article 10 : Clause attributive de juridiction
        </Text>
        <Text style={styles.paragraph}>
          Tout litige entre le bailleur et le locataire est du ressort exclusif
          des tribunaux de Casablanca.
        </Text>
      </View>
      {/* Article 11 : Election de domicile */}
      <View style={styles.section}>
        <Text style={styles.subheading}>Article 11 : Election de domicile</Text>
        <Text style={styles.paragraph}>
          Pour l’exécution des présentes et de leur suite, les parties font
          élection de domicile :
        </Text>
        <Text style={styles.listItem}>
          - Pour le bailleur en son siège social au{' '}
          {domicileBailleur || '..........................'}
        </Text>
        <Text style={styles.listItem}>
          - Pour la société locataire dans les lieux loués.
        </Text>
      </View>
      {/* Article 12 : Pouvoirs */}
      <View style={styles.section}>
        <Text style={styles.subheading}>Article 12 : Pouvoirs</Text>
        <Text style={styles.paragraph}>
          Tous les pouvoirs sont donnés au porteur d’un exemplaire des présentes
          pour accomplir toutes les formalités requises par la loi.
        </Text>
      </View>
      {/* Signature */}
      <View style={styles.section}>
        <Text style={styles.paragraph}>
          Fait à Casablanca, le {signatureDate || '..........................'}
        </Text>
        <Text style={styles.paragraph}>Signatures légalisées :</Text>
        <View style={styles.Signature}>
          <View style={styles.subSignature}>
            <Text style={styles.paragraph}>
              M. {bailleurRepresentant || '..........................'}
            </Text>
            <Text style={styles.paragraph}>Es-qualité</Text>
          </View>
          <View style={styles.subSignature}>
            <Text style={styles.paragraph}>
              M. {locataireRepresentant || '..........................'}
            </Text>
            <Text style={styles.paragraph}>Es-qualité</Text>
          </View>
        </View>
      </View>
    </Page>
  );
}

export default Page2;
