import React, { useState } from 'react';
import ArrowUp from '../../../../../../../assets/icons/ArrowUp.png';
import ArrowDown from '../../../../../../../assets/icons/ArrowDown.png';
import './questionFrequente.css';

function QuestionFrequentesItem({ id, question, response }) {
  const [activeItem, setActiveItem] = useState(false);
  return (
    <div
      className='questionFrequentesItem'
      onClick={() => setActiveItem(!activeItem)}
    >
      <p className='idQuestion'>{id}</p>
      <div className='question'>
        <h3>{question}</h3>
        {activeItem && <p>{response}</p>}
      </div>
      {activeItem ? <img className='arrow' src={ArrowUp} /> : <img  className='arrow' src={ArrowDown} />}
    </div>
  );
}

export default function QuestionFrequentes() {
  const dataQuestionFrequentes = [
    {
      id: '01',
      question: 'Qui sommes-nous ?',
      response: 'Jurisociete.fr est une plateforme de gestion en ligne de toutes vos formalités juridiques : ' 
        + 'création de sociétés, modifications statutaires, Ago annuelle.',
    },
    {
      id: '02',
      question: 'Comment Legalstation se distingue-t-il des autres sites juridiques en ligne ?',
      response: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus pariatur minima odio '
        + 'reiciendis alias, enim, hic doloremque recusandae odit nemo perspiciatis quae porro nihil quis '
        + 'rerum corporis maiores, praesentium quibusdam.',
    },
    {
      id: '03',
      question: 'Comment puis-je partager mon avis sur mon expérience Legalstation?',
      response: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus pariatur minima odio '
        + 'reiciendis alias, enim, hic doloremque recusandae odit nemo perspiciatis quae porro nihil quis '
        + 'rerum corporis maiores, praesentium quibusdam.',
    },
    {
      id: '04',
      question: 'Que faites-vous de mes données personnelles et comment sont-elles stockées ?',
      response: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus pariatur minima odio '
        + 'reiciendis alias, enim, hic doloremque recusandae odit nemo perspiciatis quae porro nihil quis '
        + 'rerum corporis maiores, praesentium quibusdam.',
    },
  ];
  return (
    <section className='questionFrequentesContainer'>
      <div className='questionFrequentes'>
        <h1>Questions fréquentes</h1>
        <div className='questionFrequentesItemsContainer'>
          {dataQuestionFrequentes.map((item) => (
            <QuestionFrequentesItem
              key={item.id}
              id={item.id}
              question={item.question}
              response={item.response}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
