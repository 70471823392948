import React from 'react';
import ContratContentSqueleton from '../components/contratContentSqueleton/contratContentSqueleton';
import './contratCGV.css';

export default function InfoContratCGV() {
  const itemsContent = [
    {title: 'Pourquoi ? Pour qui ?', link: '#destination'},
    {title: 'Quel est le contenu des CGV ?', link: '#contenu'},
    {title: 'Quelle est la sanction de l’absence de CGV ?', link: '#sanction'},
  ];
  return (
    <div className="contratCGV">
      <ContratContentSqueleton
        link="form"
        title="Conditions générales de vente"
        subTitle="Modèle de conditions générales de vente"
        titleLeftContent=""
        textButton="Remplir mes conditions"
        titleContent="A propos des conditions générales de vente"
        rightContent={<ContratCGVContent />}
        itemsContent={itemsContent}
      />
    </div>
  );
}

function ContratCGVContent() {
  return (
    <div className='ContratCGVContent'>
      <h3 id='destination'>Pourquoi ? Pour qui ?</h3>
      
      <p>Dans le cadre de vos relations commerciales avec des consommateurs ou des professionnels et quelque soit le mode de vente de produits (e-commerce, vente classique, etc.), <strong>la loi vous oblige à communiquer vos Conditions Générales de Vente (CGV)</strong>.</p>
      
      <p>Sur un site E-commerce, <strong>le lien permettant d’accéder à la page des CGV doit être visible sur chaque page du site</strong> et ces <strong>CGV doivent expressément être acceptées par l’acheteur avant la conclusion du contrat</strong>.</p>
      
      <p>Elles sont d’autant plus importantes pour le site e-commerce qu’elles sécurisent en amont la relation entre le consommateur et le commerçant.</p>
      
      <p>En vente classique, on conseillera au professionnel de faire parapher ou signer les CGV aux clients pour qu’elles lui soient opposables.</p>
      
      <p>Ces CGV permettent, entre autre, de prévoir les modalités de paiement des produits ou services, mais également les conditions de retour et de remboursement, les diverses garanties contractuelles …</p>
      
      <p>Plus qu’une simple obligation légale, les CGV sont le reflet de votre politique commerciale et doivent donc être adaptée à votre activité. Recopier celle du concurrent peut s’avérer être une très mauvaise idée.</p>
      
      <p>Les présentes CGV concernent des ventes de produits ou des prestations de services délivrées à des consommateurs et non-professionnels.</p>

      <h3 id='contenu'>Quel est le contenu des CGV ?</h3>

      <p><strong>C’est l’article L.111-1 du Code de la consommation qui précise le contenu essentiel des Conditions Générales de Ventes avec les consommateurs.</strong></p>

      <p>Cet article dispose ainsi que :</p>

      <p>« Avant que le consommateur ne soit lié par un contrat de vente de biens ou de fourniture de services, le professionnel communique au consommateur, de manière lisible et compréhensible, les informations suivantes : 1° Les caractéristiques essentielles du bien ou du service, compte tenu du support de communication utilisé et du bien ou service concerné;</p>

      <p>2° Le prix du bien ou du service, en application des articles L. 112-1 à L. 112-4 ;</p>

      <p>3° En l’absence d’exécution immédiate du contrat, la date ou le délai auquel le professionnel s’engage à livrer le bien ou à exécuter le service ;</p>

      <p>4° Les informations relatives à son identité, à ses coordonnées postales, téléphoniques et électroniques et à ses activités, pour autant qu’elles ne ressortent pas du contexte ;</p>

      <p>5° S’il y a lieu, les informations relatives aux garanties légales, aux fonctionnalités du contenu numérique et, le cas échéant, à son interopérabilité, à l’existence et aux modalités de mise en œuvre des garanties et aux autres conditions contractuelles ;</p>

      <p>6° La possibilité de recourir à un médiateur de la consommation dans les conditions prévues au titre Ier du livre VI.</p>

      <p>La liste et le contenu précis de ces informations sont fixés par décret en Conseil d’Etat.</p>

      <p>Les dispositions du présent article s’appliquent également aux contrats portant sur la fourniture d’eau, de gaz ou d’électricité, lorsqu’ils ne sont pas conditionnés dans un volume délimité ou en quantité déterminée, ainsi que de chauffage urbain et de contenu numérique non fourni sur un support matériel. Ces contrats font également référence à la nécessité d’une consommation sobre et respectueuse de la préservation de l’environnement. »</p>

      <p>C’est l’article L441-6 du Code de commerce qui régit quant à lui les CGV entre professionnels :</p>

      <p>« Tout producteur, prestataire de services, grossiste ou importateur est tenu de communiquer ses conditions générales de vente à tout acheteur de produits ou tout demandeur de prestations de services qui en fait la demande pour une activité professionnelle. Elles comprennent :</p>

      <p>– les conditions de vente ;</p>

      <p>– le barème des prix unitaires ;</p>

      <p>– les réductions de prix ;</p>

      <p>– les conditions de règlement.</p>

      <p>Les conditions générales de vente peuvent être différenciées selon les catégories d’acheteurs de produits ou de demandeurs de prestation de services comme pour le photographe et ses clients. Dans ce cas, l’obligation de communication prescrite au premier alinéa porte sur les conditions générales de vente applicables aux acheteurs de produits ou aux demandeurs de prestation de services d’une même catégorie.</p>

      <p>Les conditions générales de vente constituent le socle unique de la négociation commerciale. Dans le cadre de cette négociation, tout producteur, prestataire de services, grossiste ou importateur peut convenir avec un acheteur de produits ou demandeur de prestation de services de conditions particulières de vente qui ne sont pas soumises à l’obligation de communication prescrite au premier alinéa. (…) »</p>

      <h3 id='sanction'>Quelle est la sanction de l’absence de CGV ?</h3>

      <p>La loi sanctionne le non-respect de l’obligation de fournir ou rédiger des CGV par une amende administrative de 3 000€ pour les personnes physiques et de 15 000€ pour les personnes morales.</p>
    </div>
  );
}