import React from 'react';
import { Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';

Font.register({
  family: 'abeezee',
  src: 'http://fonts.gstatic.com/s/abeezee/v9/JYPhMn-3Xw-JGuyB-fEdNA.ttf',
});

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    fontFamily: 'abeezee',
  },
  section: {
    marginBottom: 30,
  },
  heading: {
    fontSize: 20,
    fontWeight: 900,
    marginBottom: 20,
    textAlign: 'center',
  },
  subheading: {
    fontSize: 16,
    fontWeight: 900,
    marginBottom: 35,
  },
  paragraph: {
    marginBottom: 10,
    textAlign: 'justify',
    lineHeight: 1.5,
  },
  subparagraph: {
    marginBottom: 35,
    textAlign: 'center',
    lineHeight: 1,
    fontSize: 8,
  },
  listItem: {
    marginLeft: 25,
    lineHeight: 1.5,
    marginBottom: 10,
  },
  sublistItem: {
    marginLeft: 25,
    lineHeight: 1,
    marginBottom: 2.5,
  },
  italicSouligneGray: {
    textDecoration: 'underline',
    color: 'gray',
  },
  laws: {
    color: '#666',
    fontSize: 10,
  },
});

function Page8(props) {
  const {
    chooseDomaineSite,
    vendeur,
    formeSocialeVendeur,
    nomSociétéVendeur,
    adresseSiègeSocialVendeur,
    nomVendeur,
    adresseDomiciliationVendeur,
  } = props;
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.heading}>ANNEXE I</Text>
        <Text style={styles.subheading}>Formulaire de rétractation</Text>
        <Text style={styles.paragraph}>Date ______________________</Text>
        <Text style={styles.paragraph}>
          Le présent formulaire doit être complété et renvoyé uniquement si le
          Client souhaite se rétracter de la commande passée sur{' '}
          {chooseDomaineSite || '________________'}
          sauf exclusions ou limites à l&apos;exercice du droit de rétractation
          suivant les Conditions Générales de Vente applicables.
        </Text>
        {vendeur === 'morale' ? (
          <>
            <Text style={styles.paragraph}>
              A l&apos;attention de {formeSocialeVendeur || '_______________'},{' '}
              {nomSociétéVendeur || '_______________'}
            </Text>
            <Text style={styles.paragraph}>
              {adresseSiègeSocialVendeur || '_______________'}
            </Text>
          </>
        ) : (
          <>
            <Text style={styles.paragraph}>
              A l&apos;attention de {nomVendeur || '_______________'}
            </Text>
            <Text style={styles.paragraph}>
              {adresseDomiciliationVendeur || '_______________'}
            </Text>
          </>
        )}
        <Text style={styles.paragraph}>
          Je notifie par la présente la rétractation du contrat portant sur le
          bien ci-dessous :
        </Text>
        <Text style={styles.listItem}>- Commande du (indiquer la date)</Text>
        <Text style={styles.listItem}>
          - Numéro de la commande :
          ...........................................................
        </Text>
        <Text style={styles.listItem}>
          - Nom du Client :
          ...........................................................................
        </Text>
        <Text style={styles.listItem}>
          - Adresse du Client :
          .......................................................................
        </Text>
        <Text style={styles.paragraph}>
          Signature du Client (uniquement en cas de notification du présent
          formulaire sur papier)
        </Text>
      </View>
    </Page>
  );
}

export default Page8;
