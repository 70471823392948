import React from 'react';
import camera from '../../../assets/icons/camera.png';

function DashHeader({ picture, name, onClick, size = '3rem' }) {
  const handleClick = (e) => {
    if (typeof onClick === 'function') {
      onClick(e);
    }
  };

  return (
    <div className="flex items-center gap-6 py-2 mb-5">
      <div className="relative" style={{ width: size, height: size }}>
        <div className="rounded-full overflow-hidden h-full">
          <img className='h-full w-full object-cover' src={picture} alt="profile" />
        </div>
        {onClick && (
          <button onClick={handleClick} className="absolute bottom-0 right-0 mb-[-3px] mr-[-3px] w-8 h-8 bg-white rounded-full flex justify-center items-center shadow-md">
            <div className="w-6 h-6 bg-primary rounded-full flex justify-center items-center">
              <img src={camera} alt="Camera" className="w-4 h-4" />
            </div>
          </button>
        )}
      </div>
      <div className='font-inter font-normal font-bold text-2xl'>
        Hello, {name}
      </div>
    </div>
  );
}

export default DashHeader;