import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../../../../components/footer/footer';
import HeaderContainer from '../../../../components/headerContainer/headerContainer';

export default function ContratPrestation() {
  return (
    <div className='bg-white'>
      <HeaderContainer />
      <Outlet />
      <Footer />
    </div>
  );
}
