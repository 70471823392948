/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';
import ContratContentSqueleton from '../../contratViews/components/contratContentSqueleton/contratContentSqueleton';
import './CarteGrise.css';

export default function CarteGrise() {
  const itemsContent = [
    {title: 'Quelles formalités accomplir pour obtenir une carte grise ?', link: '#1'},
    {title: 'Quels sont les justificatifs à fournir à l’ANTS ?', link: '#2'},
    {title: 'Où réaliser les démarches ?', link: '#3'},
    {title: 'Comment calculer le coût d’une carte grise ?', link: '#4'},
    {title: 'Quels sont les délais d’obtention du titre sécurisé ?', link: '#5'},
    {title: 'Quelles sont les sanctions en l’absence de carte grise ?', link: '#6'},
    {title: 'Quelles autres démarches liées à la carte grise peut-on réaliser en ligne ?', link: '#7'},
    {title: 'FAQ', link: '#faq'},
  ];
  return (
    <ContratContentSqueleton
      title='Comment faire sa carte grise ?'
      link='/carteGrise/form'
      subTitle=''
      titleLeftContent=''
      textButton='Demande de carte grise en ligne'
      titleContent=''
      rightContent={<CarteGriseContent />}
      itemsContent={itemsContent}
    />
  );
}

function CarteGriseContent() {
  return (
    <div className='CgriseContent'>
      <p>La <Link className='linkContent' to='#'>carte grise</Link> est un document officiel recensant les informations sur un véhicule donné et sur son propriétaire. Délivré par l’Agence nationale des titres sécurisés (ANTS), le certificat d’immatriculation est obligatoire pour circuler légalement sur la voie publique avec un véhicule.</p>
      <p>
      Il vous sera réclamé en cas de contrôle routier, et l’absence de présentation du document vous expose à des sanctions pécuniaires et administratives. Son obtention doit ainsi être votre principale préoccupation lors de l’achat d’un véhicule.
      </p>
      <h3 id='1'>Quelles formalités accomplir pour obtenir une carte grise ?</h3>
      <p>Qu’il s’agisse d’une demande de carte grise suite à l’achat d’un véhicule neuf ou d’un véhicule d’occasion, les formalités sont sensiblement similaires.</p>
      <p>Après avoir créé un compte ANTS, ou vous être <strong>connecté à l’aide de vos identifiants</strong>, il faut vous rendre dans la rubrique « Immatriculation ». Plusieurs options s’offriront alors à vous, et il faudra sélectionner le champ « <Link className='linkContent'to='#'>Demande d’immatriculation</Link> », puis vous laissez guider jusqu’au paiement du prix de la carte grise.</p>
      <p className='infoContentBlue'>
        <strong>A noter :</strong> les justificatifs demandés au cours de la procédure sont à fournir au format dématérialisé. Pensez à numériser vos documents au préalable afin de gagner du temps.
      </p>
      <h3 id='2'>Quels sont les justificatifs à fournir à l’ANTS ?</h3>
      <p>
      Au cours de la procédure de demande de carte grise, l’ANTS vous demandera de fournir des justificatifs, qui diffèrent selon que le véhicule concerné soit neuf ou d’occasion.
      </p>
      <p className='infoContent'>
      Bon à savoir : Pensez à ajouter à la liste des documents requis, un <Link className='linkContent' to='#'>mandat pour l’immatriculation</Link> si vous passez par un prestataire habilité.
      </p>
      <h3>Pour un véhicule neuf</h3>
      <p>
      La liste des <Link className='linkContent' to='#'>documents à fournir pour immatriculer</Link> un véhicule neuf est la suivante :
      </p>
      <ul>
        <li>Formulaire de demande d’immatriculation (à compléter en ligne) ;</li>
        <li>Facture ou autre justificatif d’achat ;</li>
        <li>Justificatif d’identité en cours de validité ;</li>
        <li>Copie du permis de conduire du titulaire ;</li>
        <li>Attestation d’assurance du véhicule ;</li>
        <li>Justificatif de domicile de moins de 6 mois ;</li>
        <li><Link className='linkContent' to='#'>Certificat de conformité européen ;</Link></li>
        <li>Quitus fiscal si le véhicule a été acquis à l’étranger.</li>
      </ul>
      <h3>Pour un véhicule d’occasion</h3>
      <p>La liste des documents à fournir pour <strong>changer le titulaire de la carte grise</strong> suite à l’achat d’un véhicule d’occasion est la suivante :</p>
      <ul>
        <li>Le formulaire de demande d’immatriculation (à compléter en ligne) </li>
        <li>Votre exemplaire du certificat de cession du véhicule (Cerfa n°15776) et le <Link className='linkContent'to='#'>code de cession</Link></li>
        <li>Un justificatif d’identité en cours de validité ;</li>
        <li>Un justificatif de domicile de moins de 6 mois ;</li>
        <li>Une copie du permis de conduire ;</li>
        <li>L’attestation d’assurance du véhicule ;</li>
        <li>L’ancienne carte grise barrée ;</li>
        <li>La preuve d’un contrôle technique valide de moins de 6 mois.</li>
      </ul>
      <h3 id='3'>Où réaliser les démarches ?</h3>
      <p>
      Depuis 2017, il n’est plus possible de faire une demande de <Link className='linkContent' to='#'>carte grise en préfecture</Link>. En effet, la démarche s’effectue <strong>exclusivement en ligne</strong>.
      </p>
      <p>
      Pour cela, le titulaire dispose de plusieurs options :
      </p>
      <ul>
        <li>Réaliser la démarche seul sur le site de l’ANTS ;</li>
        <li>Faire appel à un professionnel habilité de l’automobile (garage ou concessionnaire)</li>
        <li>Recourir à un prestataire en ligne habilité.</li>
      </ul>
      <h3>En ligne sur le site de l’ANTS</h3>
      <p>
      Si vous choisissez de réaliser les formalités seul, il faut respecter un certain nombre d’étapes. Ainsi, après avoir <strong>créé un compte sur la plateforme ANTS</strong>, il vous faudra :
      </p>
      <ol className='list-decimal'>
        <li>Sélectionner « Demande de certificat d’immatriculation » dans la rubrique « Immatriculation » ;</li>
        <li>Répondre au questionnaire proposé avec les informations du véhicule et vos informations personnelles</li>
        <li>Envoyer vos justificatifs au format numérique ;</li>
        <li>Vérifier et valider les renseignements transmis ;</li>
        <li>Payer le prix de la carte grise en ligne.</li>
      </ol>
      <p className='infoContent'><strong>Bon à savoir :</strong> si vous ne disposez pas du matériel informatique nécessaire à la réalisation des démarches, sachez qu’il existe en préfecture, des points numériques qu’il vous est possible d’utiliser.</p>
      <h3>Auprès d’un professionnel habilité</h3>
      <p>Il est également possible de <strong>donner mandat à un professionnel habilité</strong> pour la réalisation des démarches d’immatriculation. Vous pouvez recourir :</p>
      <ul>
        <l>A un professionnel de l’automobile, tel qu’un <Link className='linkContent' to='#'>garage</Link> ou un concessionnaire ;</l>
        <l>A un prestataire en ligne spécialisé dans la réalisation des demandes de carte grise.</l>
      </ul>
      <p>
      Avant de prendre cette décision, il est judicieux de <strong>vérifier l’habilitation dudit professionnel</strong>. Pour ce faire, il suffit de vérifier la présence de ce professionnel sur les listes des habilitations délivrées par le ministère de l’Intérieur.
      </p>
      <p>
      L’avantage de passer par un prestataire est en premier lieu de <strong>bénéficier de son expertise</strong>, ce qui vous évitera les erreurs susceptibles de mener au rejet de votre demande d’immatriculation. De plus, cette expertise vous permet de gagner un temps considérable.
      </p>
      <p className='infoContentBlue'>
      🔎 <strong>Zoom</strong> : Pensez à LegalPlace pour réaliser votre <Link className='linkContent' to='#'>demande de carte grise en ligne</Link> ! Pour cela, il vous suffit de compléter vos informations en moins de 3 minutes et de nous transmettre vos pièces justificatives. Nos équipes traitent votre dossier dans les 24h et il ne vous reste plus qu’à attendre de recevoir le titre à votre domicile.
      </p>
      <h3 id='4'>Comment calculer le coût d’une carte grise ?</h3>
      <p>
      Le <Link>prix d’une carte grise</Link> s’obtient après un calcul impliquant diverses taxes. A ce résultat peuvent s’appliquer des exonérations ou des réductions de taxe selon la situation du demandeur et les caractéristiques du véhicule.
      </p>
      <h3>Calcul du prix de la carte grise</h3>
      <p>
      Le montant à payer pour obtenir une carte grise s’obtient <strong>en additionnant les 5 taxes suivantes</strong> :
      </p>
      <ul>
        <li>Taxe régionale dont le montant varie selon les régions ;</li>
        <li>Taxe forfaitaire de 11€ ;</li>
        <li>Redevance d’acheminement de 2,76€ ;</li>
        <li>Taxe CO2, également appelée <Link className='linkContent' to='#'> malus écologique</Link> ou encore taxe sur les véhicules polluants dont le montant varie selon les caractéristiques du véhicule ;</li>
        <li>Taxe professionnelle, applicable aux véhicules utilitaires seulement.</li>
      </ul>
      <p>Ainsi, le prix d’un carte grise n’est pas fixe et <strong>dépend de plusieurs critères</strong> tels que la puissance fiscale du véhicule, l’énergie utilisée pour le fonctionnement de son moteur, la nature du véhicule (utilitaire, tourisme, etc.) ou encore la région du domicile du titulaire.</p>
      <p className='infoContentBlue'>
        <strong>À noter :</strong> Vous pouvez utiliser le simulateur disponible sur le site du Service Public pour estimer le prix de votre carte grise en fonction des caractéristiques de votre véhicule. </p>
      <h3>Exonérations et réductions de taxes</h3>
      <p>
      Des exonérations sont notamment prévues <strong>pour les véhicules de plus de 10 ans</strong>. Ce type de véhicule bénéficie :
      </p>
      <ul>
        <li>D’une exonération à hauteur de 50% de la taxe régionale ;</li>
        <li>D’une dispense de paiement du malus écologique.</li>
      </ul>
      <p>Ainsi, le montant à payer pour la carte grise d’un véhicule de plus de 10 ans s’obtient en additionnant <strong>50% de la taxe régionale</strong> (elle-même obtenue grâce au prix du cheval fiscal par région), les 11€ de taxe forfaitaire ainsi que les 2,76€ correspondants aux frais d’acheminement du titre à votre domicile.</p>
      <p className='infoContent'>
        <strong>Bon à savoir :</strong> si votre véhicule a plus de 30 ans et est éligible à la mention « véhicule de collection » alors le prix à payer pour l’ajout de cette mention sur le certificat d’immatriculation s’élève à 13,76€.</p>
      <p>
        De plus, les véhicules neufs peuvent bénéficier d’une <strong>exonération totale de la taxe CO2</strong>, si leur taux d’émission par kilomètre est inférieur à 123g. Au-delà de ce taux, le montant de la taxe applicable sera proportionnel au montant du malus écologique appliqué : plus le taux est haut, plus la taxe sera élevée.
      </p>
      <h3 id='5'>Quels sont les délais d’obtention du titre sécurisé ?</h3>
      <p>En règle générale, le <Link className='linkContent' to='#'>délai de réception de la carte grise</Link> varie <strong>entre 5 et 7 jours suivant le dépôt et la validation de la demande d’immatriculation</strong></p>
      <p>
      Ce délai peut être allongé dans les périodes de forte activité pour l’ANTS. En effet, l’Agence est également en charge des demandes de passeport, de permis de conduire ou encore de carte d’identité. La <strong>période estivale est donc généralement très animée</strong>, et il est ainsi possible que votre carte grise prenne un peu plus de temps pour être acheminée à votre domicile.
      </p>
      <p>
      Quoi qu’il en soit, le certificat provisoire d’immatriculation obtenu après la validation de votre demande en ligne vous <strong>permet de circuler légalement pendant 1 mois</strong>, en attendant la réception du certificat définitif.
      </p>
      <h3 id='6'>Quelles sont les sanctions en l’absence de carte grise ?</h3>
      <p>
      Si lors d’un contrôle routier, un automobiliste se trouve dans l’incapacité de présenter sa carte grise aux forces de l’ordre, il sera <strong>passible de sanctions</strong>.
      </p>
      <p className='infoContent'>
        <strong>Bon à savoir :</strong> s’il s’agit d’un simple oubli de la carte grise, alors sachez qu’il est possible de la présenter dans un délai de 5 jours au commissariat ou à la gendarmerie afin d’éviter des sanctions plus lourdes
      </p>
      <p>
      Il est en effet obligatoire de détenir en tout temps un certificat d’immatriculation à jour. Toute <strong>transgression de cette règle constitue une infraction</strong> au Code de la route.
      </p>
      <p>
      Voici donc un tableau récapitulatif des sanctions encourues en fonction des situations :
      </p>
      <table>
        <tr>
          <th>Infraction</th>
          <th>Type de contravention</th>
          <th>Montant de l’amende</th>
          <th>Amende minorée</th>
          <th>Amende majorée</th>
        </tr>
        <tr>
          <td>Absence de carte grise lors d’un contrôle	</td>
          <td>1ère classe</td>
          <td>11€</td>
          <td>/</td>
          <td>33€</td>
        </tr>
        <tr>
          <td>Non-présentation de la carte grise aux autorités dans le délai de 5 jours suivant un contrôle	</td>
          <td>4ème classe</td>
          <td>135€</td>
          <td>90€</td>
          <td>375€</td>
        </tr>
        <tr>
          <td>Véhicule mis en circulation sans carte grise</td>
          <td>4ème classe</td>
          <td>135€</td>
          <td>90€</td>
          <td>375€</td>
        </tr>
        <tr>
          <td>Carte grise obsolète</td>
          <td>4ème classe</td>
          <td>135€</td>
          <td>90€</td>
          <td>375€</td>
        </tr>
      </table>
      <p className='infoContent'>
      Bon à savoir : Le titulaire peut contester l’amende dans un délai de 45 jours en envoyant le formulaire par courrier à l’adresse indiquée sur son avis de contravention ou en ligne sur le site de l’ANTAI (Agence Nationale de Traitement Automatisé des Infractions).
      </p>
      <h3 id='7'>
      Quelles autres démarches liées à la carte grise peut-on réaliser en ligne ?
      </h3>
      <p>
      Depuis la mise en place du plan préfecture nouvelle génération (PPNG) en 2017, l’ensemble des formalités liées à la <strong>carte grise s’effectue de manière dématérialisée</strong>.
      </p>
      <p>L’ANTS est ainsi en charge des demandes telles que :</p>
      <ul>
        <li>Déclaration de cession ;</li>
        <li><Link className='linkContent' to ='#'>Duplicata de carte grise ;</Link></li>
        <li>Changement d’adresse ou d’état civil du titulaire (<Link className='linkContent' to='#'>changement de nom sur la carte grise</Link> suite à un mariage par exemple) ;</li>
        <li>Immatriculation de tous véhicules (étranger, collection, occasion, neuf, etc.).</li>
      </ul>
      <p>
      Bien entendu, il est également possible de <strong>passer par un prestataire habilité pour la réalisation de ces démarches</strong>, afin de vous soulager de la partie administrative, de gagner du temps et d’éviter les erreurs lors de la saisie du formulaire en ligne.
      </p>
      <h3 id='faq'>FAQ</h3>
      <div className='faq'>
        <h6>A quoi sert une carte grise</h6>
        <p>
      La carte grise est un document officiel permettant de identifier formellement un véhicule et son propriétaire. A ce titre, le document recense toute information utiles sur les caractéristiques du véhicule, ainsi que les coordonnées du propriétaire, et doit obligatoirement être à jour afin de éviter les sanctions lors de un controle
        </p>
      </div>
      <div className='faq'>
        <h6>Le code de cession est-il obligatoire pour obtenir la carte grise d’un véhicule d’occasion ?</h6>
        <p>En passant par un professionnel agissant en tant que mandataire, le code de cession n’est pas nécessaire pour réaliser la demande.</p>
      </div>
      <div className='faq'>
        <h6>Qui doit remettre le certificat de cession à la préfecture ?</h6>
        <p>Les parties n'ont plus à transmettre le certificat de cession à la préfecture. Cela vaut à la fois pour le vendeur et pour l'acheteur. Toutefois, le cédant doit déclarer la cession sur le site de l'ANTS et le nouveau propriétaire du véhicule doit l'immatriculer à son nom.</p>
      </div>
    </div>
  );
}

