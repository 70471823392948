import React from 'react';
import Header from '../../../../components/header/header';
// import Steps from '../sarl/form/steps';
import Section1 from '../sarl/sections/section1/section1';
import Section2 from '../sarl/sections/section2/section2';
import Section3 from '../sarl/sections/section3/section3';
import Section4 from '../sarl/sections/section4/section4';
import Footer from '../../../../components/footer/footer';
import AssociationHeader from './components/associationHeader';
import Rateandgoogle from './components/rateandgoogle';


export default function Association() {
  return (
    <div className="bg-white">
      <Header />
      <AssociationHeader/>
      <Rateandgoogle />
      <div>
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
      </div>
      <div className=" bg-link py-16 px-10 md:px-[120px] ">
        <h3 className='text-4xl  text-white font-bold'>Vous souhaitez démarrer <br></br> votre projet avec nous?</h3>
      </div>
      <Footer />
    </div>
  );
}
