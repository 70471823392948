// Sidebar.js
import React from 'react';
import DashboardIcon from '../../assets/icons/DashboardIcon.png';
import ProfileIcon from '../../assets/icons/ProfileIcon.png';
import RecordsIcon from '../../assets/icons/RecordsIcon.png';
import ContactIcon from '../../assets/icons/ContactIcon.png';
import exit from '../../assets/icons/exit.png';
import BottomBar from './bottombar';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/userSlice';

function Sidebar({ isCollapsed, handleToggle, handleTabClick, activeTab }) {
  const dispatch = useDispatch();
  const buttonClasses = 'flex items-center p-4 focus:outline-none transition-colors duration-200';

  return (
    <>
      <div className={`hidden md:flex flex-col ${isCollapsed ? 'w-24' : 'w-64'} h-screen text-black transition-width duration-300 bg-white overflow-hidden shadow-md`}>
        <button onClick={handleToggle} className="p-4 focus:outline-none">
          {isCollapsed ? <div className='p-4 rounded-xl'>☰</div> : <div className='p-4 rounded-xl'>X</div>}
        </button>
        
        <div className='flex flex-col gap-48'>
          <nav className="flex flex-col mt-4 space-y-2 w-full">
            {[
              { name: 'dashboard', icon: DashboardIcon, label: 'Dashboard' },
              { name: 'profile', icon: ProfileIcon, label: 'Profile' },
              { name: 'records', icon: RecordsIcon, label: 'Mes Enregistrements' },
              { name: 'contact', icon: ContactIcon, label: 'Contact' },
            ].map(tab => (
              <button
                key={tab.name}
                onClick={() => handleTabClick(tab.name)}
                className={buttonClasses}
              >
                <div className={`flex items-center ${isCollapsed ? 'justify-start w-full' : 'justify-start w-full'}`}>
                  <div className="w-5 flex-shrink-0 flex justify-center">
                    {activeTab === tab.name && (
                      <div className="w-1 h-1 rounded-full bg-primary"></div>
                    )}
                  </div>
                  <img
                    src={tab.icon}
                    alt={tab.label}
                    className="h-4 w-4"
                  />
                  {!isCollapsed && <span className="ml-2">{tab.label}</span>}
                </div>
              </button>
            ))}
          </nav>
          <div className='flex flex-col items-center'>
            <button onClick={() => {
              const confirmLogout = window.confirm('Are you sure you want to logout?');
              if (confirmLogout) {
                dispatch(setUser(null));
              }
            }}>
              <img className='h-4 w-4' src={exit} alt="Exit" />
            </button>
          </div>
        </div>
      </div>
      <BottomBar activeTab={activeTab} handleTabClick={handleTabClick} />
    </>
  );
}

export default Sidebar;
