import React from 'react';
import { Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';

Font.register({
  family: 'abeezee',
  src: 'http://fonts.gstatic.com/s/abeezee/v9/JYPhMn-3Xw-JGuyB-fEdNA.ttf',
});

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    fontFamily: 'abeezee',
  },
  section: {
    marginBottom: 30,
  },
  heading: {
    fontSize: 20,
    fontWeight: 900,
    marginBottom: 20,
    textAlign: 'center',
  },
  subheading: {
    fontSize: 16,
    fontWeight: 900,
    marginBottom: 35,
  },
  paragraph: {
    marginBottom: 10,
    textAlign: 'justify',
    lineHeight: 1.5,
  },
  subparagraph: {
    marginBottom: 35,
    textAlign: 'center',
    lineHeight: 1,
    fontSize: 8,
  },
  listItem: {
    marginLeft: 25,
    lineHeight: 1.5,
    marginBottom: 10,
  },
  sublistItem: {
    marginLeft: 25,
    lineHeight: 1,
    marginBottom: 2.5,
  },
  italicSouligneGray: {
    textDecoration: 'underline',
    color: 'gray',
  },
  laws: {
    color: '#666',
    fontSize: 10,
  },
});

function Page4(props) {
  const {
    isLivraisonsAutreZones,
    livraisonsAutreZonesList,
    délaisLivraisons,
    délaisAnnulerCommande,
    isTransporteurIndépendant,
    isLivraisonsAvoirLieuEnMagasin,
    adressesMagasins,
    délaiMaxRéclamation,
    modeRéclamation,
    momentTransfertPropriété,
  } = props;
  return (
    <Page size="A4" style={styles.page}>
      {/* ARTICLE 5 - Livraisons */}
      <View style={styles.section}>
        <Text style={styles.subheading}>ARTICLE 5 - Livraisons</Text>
        {isLivraisonsAutreZones && (
          <>
            <Text style={styles.paragraph}>
              Les Produits commandés par le Client seront livrés au Maroc ou
              dans la/les zone(s) suivantes :
            </Text>
            <Text style={styles.paragraph}>
              {livraisonsAutreZonesList || '_______________'}.
            </Text>
          </>
        )}
        <Text style={styles.paragraph}>
          Les livraisons interviennent dans un délai de{' '}
          {délaisLivraisons || '_______________'} à l&apos;adresse indiquée par
          le Client lors de sa commande sur le site.
        </Text>
        <Text style={styles.paragraph}>
          La livraison est constituée par le transfert au Client de la
          possession physique ou du contrôle du Produit. Sauf cas particulier ou
          indisponibilité d&apos;un ou plusieurs Produits, les Produits
          commandés seront livrés en une seule fois.
        </Text>
        <Text style={styles.paragraph}>
          Le Vendeur s&apos;engage à faire ses meilleurs efforts pour livrer les
          produits commandés par le Client dans les délais ci-dessus précisés.
        </Text>
        <Text style={styles.paragraph}>
          Si les Produits commandés n&apos;ont pas été livrés dans un délai de
          {délaisAnnulerCommande || '_______________'} après la date indicative
          de livraison, pour toute autre cause que la force majeure ou le fait
          du Client, la vente pourra être résolue à la demande écrite du Client
          conformément à la législation en vigueur. Les sommes versées par le
          Client lui seront alors restituées au plus tard dans les quatorze
          jours qui suivent la date de dénonciation du contrat, à
          l&apos;exclusion de toute indemnisation ou retenue.
        </Text>
        {isTransporteurIndépendant && (
          <>
            <Text style={styles.paragraph}>
              Les livraisons sont assurées par un transporteur indépendant, à
              l&apos;adresse mentionnée par le Client lors de la commande et à
              laquelle le transporteur pourra facilement accéder.
            </Text>
            <Text style={styles.paragraph}>
              Lorsque le Client s&apos;est lui-même chargé de faire appel à un
              transporteur qu&apos;il choisit lui-même, la livraison est réputée
              effectuée dès la remise des Produits commandés par le Vendeur au
              transporteur qui les a acceptés sans réserves. Le Client reconnaît
              donc que c&apos;est au transporteur qu&apos;il appartient
              d&apos;effectuer la livraison et ne dispose d&apos;aucun recours
              en garantie contre le Vendeur en cas de défaut de livraison des
              marchandises transportées.
            </Text>
          </>
        )}
        {isLivraisonsAvoirLieuEnMagasin && (
          <>
            <Text style={styles.paragraph}>
              Le Vendeur propose également la livraison gratuite dans l&apos;un
              de ses magasins accessibles via le lien ou les localisations
              suivantes :{adressesMagasins || '_______________'}
            </Text>
          </>
        )}
        <Text style={styles.paragraph}>
          En cas de demande particulière du Client concernant les conditions
          d&apos;emballage ou de transport des produits commandés, dûment
          acceptées par écrit par le Vendeur, les coûts y liés feront
          l&apos;objet d&apos;une facturation spécifique complémentaire, sur
          devis préalablement accepté par écrit par le Client.
        </Text>
        <Text style={styles.paragraph}>
          Le Client est tenu de vérifier l&apos;état des produits livrés. Il
          dispose d&apos;un délai de {délaiMaxRéclamation || '_______________'}{' '}
          à compter de la livraison pour formuler des réclamations par{' '}
          {modeRéclamation || '_______________'}, accompagnées de tous les
          justificatifs y afférents (photos notamment). Passé ce délai et à
          défaut d&apos;avoir respecté ces formalités, les Produits seront
          réputés conformes et exempts de tout vice apparent et aucune
          réclamation ne pourra être valablement acceptée par le Vendeur.
        </Text>
        <Text style={styles.paragraph}>
          Le Vendeur remboursera ou remplacera dans les plus brefs délais et à
          ses frais, les Produits livrés dont les défauts de conformité ou les
          vices apparents ou cachés auront été dûment prouvés par le Client,
          conformément aux lois applicables au Maroc.
        </Text>
        <Text style={styles.paragraph}>
          Le transfert des risques de perte et de détérioration s&apos;y
          rapportant, ne sera réalisé qu&apos;au moment où le Client prendra
          physiquement possession des Produits. Les Produits voyagent donc aux
          risques et périls du Vendeur sauf lorsque le Client aura lui-même
          choisi le transporteur. A ce titre, les risques sont transférés au
          moment de la remise du bien au transporteur.
        </Text>
      </View>
      {/* ARTICLE 6 - Transfert de propriété */}
      <View style={styles.section}>
        <Text style={styles.subheading}>
          ARTICLE 6 - Transfert de propriété
        </Text>
        {momentTransfertPropriété === 'AuMoment' ? (
          <>
            <Text style={styles.paragraph}>
              Le transfert de propriété des Produits du Vendeur au Client sera
              réalisé dès acceptation de la commande par le Vendeur,
              matérialisant l&apos;accord des parties sur la chose et sur le prix et
              ce quelle que soit la date du paiement et de la livraison.
            </Text>
          </>
        ) : (
          <>
            <Text style={styles.paragraph}>
              Le transfert de propriété des Produits du Vendeur au Client ne
              sera réalisé qu&apos;après complet paiement du prix par ce
              dernier, et ce quelle que soit la date de livraison desdits
              Produits.
            </Text>
          </>
        )}
      </View>
      {/* ARTICLE 7 - Droit de rétractation */}
      <View style={styles.section}>
        <Text style={styles.subheading}>ARTICLE 7 - Droit de rétractation</Text>
        <Text style={styles.paragraph}>
          Conformément à la loi n° 31-08 relative à la protection du
          consommateur,{' '}
          <Text style={styles.laws}>
            « le Client dispose d&apos;un délai de 7 jours à compter de la
            réception du Produit pour exercer son droit de rétractation, sans
            avoir à justifier de motifs ni à payer de pénalités, à
            l&apos;exception des frais de retour qui restent à sa charge. »
          </Text>
        </Text>
        <Text style={styles.paragraph}>
          Le droit de rétractation peut être exercé en ligne, à l&apos;aide du
          formulaire de rétractation ci-joint et également disponible sur le
          site ou de toute autre déclaration, dénuée d&apos;ambiguïté, exprimant
          la volonté de se rétracter et notamment par courrier postal adressé au
          Vendeur aux coordonnées postales ou mail indiquées à l’ARTICLE 1 des
          présentes CGV.
        </Text>
        <Text style={styles.paragraph}>
          Les retours sont à effectuer dans leur état d&apos;origine et complets
          (emballage, accessoires, notice...) permettant leur
          recommercialisation à l&apos;état neuf, accompagnés de la facture
          d&apos;achat.
        </Text>
        <Text style={styles.paragraph}>
          Les Produits endommagés, salis ou incomplets ne sont pas repris.
        </Text>
        <Text style={styles.paragraph}>
          Les frais de retour restant à la charge du Client.
        </Text>
        <Text style={styles.paragraph}>
          L&apos;échange (sous réserve de disponibilité) ou le remboursement
          sera effectué dans un délai de 14 jours à compter de la réception, par
          le Vendeur, des Produits retournés par le Client dans les conditions
          prévues au présent article.
        </Text>
      </View>
    </Page>
  );
}

export default Page4;
