
import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import './customCheckBox.css';

export default function CustomCheckBox({value, setValue, label }) {
  return (
    <div className='customCheckBox'>
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            onChange={(e) => {
              if (e.target.checked) {
                setValue(true);
              } else {
                setValue(false);
              }
            }}
            size='small'
          />
        }
        label={label}
      />
    </div>
  );
}
