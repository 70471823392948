import React, { useState } from 'react';
import Header from '../../components/header/header';
import SideBarPack from './sideBarPack';
import PackItem from './packItem';
import entreprisePack1Image from '../../assets/images/entreprisePack1.png';
import entreprisePack2Image from '../../assets/images/entreprisePack2.png';
import entreprisePack3Image from '../../assets/images/entreprisePack3.png';

export default function Packs() {
  const [activeTab, setActiveTab] = useState('Création d\'entreprise');

  const packs = {
    'Création d\'entreprise' : {
      title: 'Création d\'entreprise',
      data: [
        {
          price: 6000,
          image: entreprisePack1Image,
          items: [
            'Le certificat négatif',
            'La rédaction et enregistrement des statuts',
            'L’inscription à la taxe professionnelle',
            'L’inscription au registre de commerce',
            'L\'identification fiscal',
            'Affiliation à la CNSS',
            'Annonce légale',
            'Model J de la société',
            'Adhésion au service de télédéclaration DGI',
            '12 mois de domiciliation',
          ]
        },
        {
          price: 9800,
          image: entreprisePack2Image,
          items: [
            '3 mois de comptabilité gratuite',
            'Le certificat négatif',
            'La rédaction et enregistrement des statuts',
            'L’inscription à la taxe professionnelle',
            'L’inscription au registre de commerce',
            'L\'identification fiscale',
            'Affiliation à la CNSS',
            'Annonce légale',
            'Model J de la société',
            'Adhésion au service de télédéclaration DGI',
            'Adhésion au service de DAMANCOM',
            '18 mois de domiciliation',
            'Pack Marketing initial',
            'Carte visite',
            'Logo',
            'Add on / ou pass' ,

          ]
        },
        {
          price: 14000,
          subPrice: '25000 dh',
          image: entreprisePack3Image,
          items: [
            '12 mois de comptabilité gratuite',
            'Le certificat négatif',
            'La rédaction et enregistrement des statuts',
            'L’inscription à la taxe professionnelle',
            'L’inscription au registre de commerce',
            'L\'identification fiscale',
            'Affiliation à la CNSS',
            'Annonce légale',
            'Model J de la société',
            'Adhésion au service de télédéclaration DGI',
            'Adhésion au service de DAMANCOM',
            'Cachet de la société',
            '24 mois de domiciliation',
            'Pack Marketing initial',
            'Carte visite',
            'Logo',
            'Site Web, Nom de domaine, Hébergement',
            'Perforiom : Plateforme de gestion, factures ',
            'Abonnement 12 mois : Pack Standard (2500DH/an) ',
            'Add on / ou pass',
          ]
        }
      ]
    },
    'Comptabilité d\'entreprise' : {
      title : 'Comptabilité d\'entreprise',
      data: [],
    },
    'Domiciliation d\'entreprise' : {
      title : 'Domiciliation d\'entreprise',
      data: [],
    },
    'Compte professionnel' : {
      title : 'Compte professionnel',
      data: [],
    },
    'Modification de statuts' : {
      title : 'Modification de statuts',
      data: [],
    },
    'Dissolution d\'entreprise' : {
      title : 'Dissolution d\'entreprise',
      data: [],
    },
    'Gestion des salariés' : {
      title : 'Gestion des salariés',
      data: [],
    },
    'Autres services' : {
      title : 'Autres services',
      data: [],
    }
  };

  const getPacks = (activeItem) => {
    return (
      <div className='p-10 w-full'>
        <p className='font-large font-inter text-2xl text-center'>{packs[activeItem].title}</p>
        <div className='flex flex-col justify-between items-start gap-5 mt-10 lg:flex-row'>
          {packs[activeItem].data.map((pack, index) => {
            return (
              <PackItem
                key={index}
                price={pack.price}
                image={pack.image}
                items={pack.items}
                subPrice={pack.subPrice}
                onClick={() => {}}
              />
            );
          })}
        </div>
      </div>
    );
  };
  return (
    <div className='lg:flex lg:justify-center'>
      <div className='min-h-screen w-full flex flex-col 2xl:w-[1536px] '>
        <Header active='Packs' />
        <div className='flex flex-1'>
          <SideBarPack
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          {getPacks(activeTab)}
        </div>
      </div>
    </div>
  );
}
