import React from 'react';
import { Button } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import './headerImage.css';
import { Link } from 'react-router-dom';

function Modele({ title, modelImage, link }) {
  return (
    <>
      <div className='modele'>
        <p>{title}</p>
        <img className='modeleImage' src={modelImage} />
        <Link to={link} onClick={() => window.scrollTo(0, 0)}>
          <Button className='button' variant="contained">Remplir ce modèle</Button>
        </Link>
      </div>
      <Link className='modele-767-link' to={link} onClick={() => window.scrollTo(0, 0)}>
        <div className='modele-767'>
          <p>{title}</p>
          <DescriptionIcon className='icon' color='primary'/>
        </div>
      </Link>
    </>
  );
}

export default function HeaderImage({ title, image, modeles}) {
  return (
    <div className='headerImage'>
      <div className='imageHeaderContainer'>
        <p>{title}</p>
        <img className='imageHeader' src={image} />
      </div>
      <div className='modeleSection'>
        <div className='modeleContainer'>
          {modeles.map((item, index) => (
            <Modele
              key={index}
              title={item.title}
              modelImage={item.modelImage}
              link={item.link}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
