import React from 'react';
import DecouvrirSatisfactionIcon1 from '../../../../../../../assets/icons/decouvrirSatisfactionIcon1.png';
import DecouvrirSatisfactionIcon2 from '../../../../../../../assets/icons/decouvrirSatisfactionIcon2.png';
import DecouvrirSatisfactionIcon3 from '../../../../../../../assets/icons/decouvrirSatisfactionIcon3.png';
import DecouvrirSatisfactionIcon4 from '../../../../../../../assets/icons/decouvrirSatisfactionIcon4.png';
import DecouvrirSatisfactionIcon5 from '../../../../../../../assets/icons/decouvrirSatisfactionIcon5.png';
import DecouvrirSatisfactionIcon6 from '../../../../../../../assets/icons/decouvrirSatisfactionIcon6.png';
// import './decouvrirSatisfaction.css';

function DecouvrirSatisfactionItem({ icon, title, description}) {
  return (
    <div className=' flex flex-col gap-4 '>
      <div className='bg-white w-fit p-2 rounded-md shadow-md'><img className='w-6 h-6 object-contain' src={icon} /></div>
      <h6 className='text-sm text-darkblue font-semibold'>{title}</h6>
      <p>{description}</p>
    </div>
  );
}


export default function DecouvrirSatisfaction() {
  const dataDecouvrirSatisfaction = [
    {
      icon: DecouvrirSatisfactionIcon1,
      title: 'Réactif selon vos besoins',
      description: 'Contactez votre comptable dès que vous en avez besoin par tchat, mails, visio '
        + 'ou téléphone'
    },
    {
      icon: DecouvrirSatisfactionIcon2,
      title: 'Faire son bilan sans faire son bilan',
      description: 'Votre comptable s\'occupe de tout : rédaction du bilan, émission de la liasse fiscale '
        + 'et dépôt aux impôts'
    },
    {
      icon: DecouvrirSatisfactionIcon3,
      title: 'Accompagnement personnalisé',
      description: 'Suivez en temps réel votre activité ! Cela devient beaucoup plus simple de prendre '
        + 'les bonnes décisions'
    },
    {
      icon: DecouvrirSatisfactionIcon4,
      title: 'Synchronisation avec votre banque',
      description: 'Même sans aucune connaissance comptable grâce à notre synchronisation sécurisée '
        + 'avec votre compte bancaire pro'
    },
    {
      icon: DecouvrirSatisfactionIcon5,
      title: 'Un prix transparent et adapté',
      description: 'Le même service qu\'un comptable de proximité, la réactivité et le logiciel en plus, '
        + 'un prix adapté'
    },
    {
      icon: DecouvrirSatisfactionIcon6,
      title: 'Création de société sereine',
      description: 'Finalisez l\'immatriculation de votre société avec votre comptable'
    },
  ];
  return (
    <section className='p-10 lg:px-[120px] '>
      <div className='flex flex-col gap-4'>
        <h1 className='text-center text-2xl lg:text-3xl text-darkblue font-bold'>Découvrez ce qui fait la satisfaction de nos<br></br>clients chez LegalStation</h1>
        <p className='text-center text-base lg:text-sm text-darkblue'>
          Optimisez votre comptabilité avec LegalStation. Logiciel intuitif et comptable dédié pour 
          respecter toutes vos obligations<br></br>fiscales et gagner en sérénité
        </p>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-6 mt-4'>
          {dataDecouvrirSatisfaction.map((item) => (
            <DecouvrirSatisfactionItem
              key={item.title}
              icon={item.icon}
              title={item.title}
              description={item.description}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
