import React from 'react';
import { Button } from '@mui/material';
import domiciliationImageHeader from '../../../../../../../assets/images/domiciliationHeader.png';
import './headerDomiciliation.css';
import { useNavigate } from 'react-router-dom';
// import { useNavigation } from 'react-router-dom';

export default function HeaderDomiciliation() {
  const navigate = useNavigate();
  return (
    <section className='headerDomiciliation'>
      <div className='leftBlock'>
        <div className='titleHeaderContainer'>
          <h1 className='title'>Domiciliez votre société en quelques clics</h1>
        </div>
        <ul className="listHeaderContainer">
          <li><span className='type-list'>✓</span> Scan et tri automatique du courrier</li>
          <li><span className='type-list'>✓</span> Scan et tri automatique du courrier</li>
          <li><span className='type-list'>✓</span> Simple, rapide et efficace</li>
        </ul>
        <div className='buttonHeaderContainer'>
          <Button onClick={() => navigate('/domiciliation/form')} variant='contained'>Domicilier ma société</Button>
          <Button onClick={() => navigate('/domiciliation/CreationDomForm')} variant='outlined'>Création et domiciliation</Button>
        </div>
      </div>
      <img className='domiciliationImageHeader' src={domiciliationImageHeader} alt="" />
    </section>
  );
}
