import React from 'react';
import {
  Page, Text, View, Document, StyleSheet, Font
} from '@react-pdf/renderer';

// font
Font.register({
  family: 'Inter',
  fonts: [
    { src: './font/Inter-Regular.ttf' }, // Normal
    { src: './font/Inter-Bold.ttf', fontWeight: 'bold' }, // Gras
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 50,
  },
  header: {
    margin: 10,
    padding: 10,
    textAlign: 'center',
    fontSize: 14
  },
  body: {
    fontSize: 10,
  },
  section: {
    marginTop: 10,
  },
  titre: {
    textAlign: 'center',
    textDecoration: 'underline',
    marginTop: 15,
  },
  article: {
    textDecoration: 'underline',
    marginTop: 15,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    fontSize: 10,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol1: {
    width: '65%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol2: {
    width: '35%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
});

function Table({ data, header }) {
  return (
    <View style={styles.table}>
      
      <View style={styles.tableRow}>
        <View style={styles.tableCol1}>
          <Text style={styles.tableCell}>{header[0]}</Text>
        </View>
        <View style={styles.tableCol2}>
          <Text style={styles.tableCell}>{header[1]}</Text>
        </View>
      </View>
      
      {data.map((row, index) => (
        <View style={styles.tableRow} key={index}>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>{row[0]}</Text>
          </View>
          <View style={styles.tableCol2}>
            <Text style={styles.tableCell}>{row[1]}</Text>
          </View>
        </View>
      ))}
    </View>
  );
}

function PDFDocument({
  denomination,
  formeJuridique,
  ville,
  soussignes,
  villeTribunalCommerce,
  siegeSocial,
  dureeSociete,
  objetsSocial,
  capitalSocial,
  apports
}) {
  const companies = {
    'Société à responsabilité limitée': 'S.A.R.L.',
    'Société à responsabilité limitée à associé unique': 'S.A.R.L. A.U.',
    'Société anonyme': 'S.A.',
    'Société en nom collectif': 'S.N.C.',
    'Société en commandite par actions': 'S.C.A.',
    'Groupement d\'intérêt économique': 'G.I.E.',
    'Société civile immobilière': 'S.C.I.',
    'Société en participation': 'S.P.',
    'Société par actions simplifiée': 'S.A.S.',
    'Succursale d’une société étrangère': 'Succursale d’une société étrangère'
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text fixed>SOCIETE {denomination}</Text>
          <Text>=====================================</Text>
          <Text>{formeJuridique}</Text>
          <Text>Capital Social : {capitalSocial} Dirhams</Text>
          <Text>Siège Social : {siegeSocial}</Text>
          <Text>{ville}</Text>
          <Text>===========================</Text>
          <Text>STATUTS</Text>
        </View>
        <View style={styles.body}>
  
          <Text style={styles.article}>Les soussignés :</Text>
          {soussignes.map((soussigne) => {
            return (
              soussigne.data.type == 'Personne physique'
                ? (
                  <Text key={soussigne.id} style={styles.section}>
                    - M. {`${soussigne.data.nom} ${soussigne.data.prenom}`}, {soussigne.data.nationalite}, demeurant à {soussigne.data.villeResidence}, {soussigne.data.adresse}, titulaire de la CIN n° {soussigne.data.cin};
                  </Text>
                )
                : (
                  <Text key={soussigne.id} style={styles.section}>
                    - {soussigne.data.denomination}, {soussigne.data.formeJuridique} au capital social de {soussigne.data.capitalSocial} Dirhams, dont le siège social est fixé à {soussigne.data.villeSciege}, {soussigne.data.adresseSiege}, immatriculée au registre de commerce sous le n° {soussigne.data.rc} et représentée par M. {`${soussigne.data.nomRepresentant} ${soussigne.data.prenomRepresentant}`}, titulaire de la CIN {soussigne.data.cinRepresentant};
                  </Text>
                )
            );
          })}
          <Text style={styles.section}>
            ils ont convenus de constituer entre eux et qu’ils déposeront au greffe du tribunal de commerce de {villeTribunalCommerce}.
          </Text>
          
          <Text style={styles.titre}>  
            TITRE PREMIER : FORME - DENOMINATION - OBJET - SIEGE - DUREE
          </Text>
  
          <Text style={styles.article}>ARTICLE 1 : FORME</Text>
          <View style={styles.section}>
            <Text>
              Il est formé entre les soussignés, propriétaires des parts ci-après créées et de celles qui pourraient l’être ultérieurement, une {formeJuridique} qui sera régie par les lois et règlements en vigueur et notamment le dahir 1-97-49 du 13 février 1997 portant promulgation de la loi 5-96, le dahir 1-06-21 du 14 février 2006 portant promulgation de la loi 21-05 et le dahir 1-11-39 du 2 juin 2011 portant promulgation de la loi 24-10 ainsi que par les présents statuts.
            </Text>
          </View>
  
          <Text style={styles.article}>
            ARTICLE 2 : DENOMINATION SOCIALE
          </Text>
          <View style={styles.section}>
            <Text>
              La dénomination de la société est : {denomination}.
            </Text>
            <Text>
              Dans tous les actes, factures, annonces, publications et autres documents émanant de la société, la dénomination sociale doit toujours être précédée ou suivie des mots &quot;{formeJuridique}&quot; ou de l&#39;abréviation &quot;{companies[formeJuridique]}&quot; et de l&#39;énonciation du montant du capital social et du numéro d’immatriculation au registre de commerce.
            </Text>
          </View>
          
          <Text style={styles.article}>ARTICLE 3 : OBJET SOCIAL</Text>
          <View style={styles.section}>
            <Text style={styles.text}>
              La société a pour objet tant au Maroc qu’en tous autres pays :
            </Text>
            <View style={{marginTop: 10}}>
              {objetsSocial.map((item) => {
                return (
                  <Text key={item.id}>- {item.objetSocial}</Text>
                );
              })}
            </View>
          </View>

          <Text style={styles.article}>ARTICLE 4 : SIEGE SOCIAL</Text>
          <View style={styles.section}>
            <Text>Le siège social est fixé à : {siegeSocial}</Text>
            <Text>
              Il pourra être transféré en tout autre lieu en vertu d’une décision collective des associés prise à la majorité requise pour les modifications statutaires.
            </Text>
            <Text>
              La création, le déplacement, la fermeture d&#39;établissements annexes en tous lieux interviennent sur simple décision de la gérance, sous réserve du respect des limitations de pouvoirs éventuellement stipulées pour ces
              opérations.
            </Text>
          </View>

          <Text style={styles.article}>ARTICLE 5 : DUREE DE LA SOCIETE</Text>
          <View style={styles.section}>
            <Text>
              La durée de la société est fixée à {dureeSociete} années à compter du jour de son immatriculation au registre du commerce sauf les cas de dissolution anticipée ou de prorogation prévues aux présents statuts et par la loi.
            </Text>
            <Text>
              La décision de prorogation devra être prise un an au moins avant la date d&#39;expiration de la société, dans les conditions requises pour les modifications statutaires, à l’initiative de la gérance.
            </Text>
          </View>

          <Text style={styles.titre}>TITRE DEUXIEME : APPORTS – CAPITAL SOCIAL – PARTS SOCIALES</Text>
          
          <Text style={styles.article}>ARTICLE 6 : APPORTS</Text>
          <View style={styles.section}>
            <Text>
              Les associés suivants ont effectué les apports en numéraires à raison de :
            </Text>
            <Table
              header={['Associés', 'Montant du capital']}
              data={apports.map(item => [item.soussigne, `${item.apport} Dirhams`])}
            />
          </View>

        </View>
      </Page>
    </Document>
  );
}

export default PDFDocument;