import React from 'react';
import Header from '../../../../components/header/header';
import ChangeAdresseHeader from './components/ChangeAdresseHeader';
import Rateandgoogle from '../../creationEntreprise/association/components/rateandgoogle';
import Section1 from '../../creationEntreprise/sarl/sections/section1/section1';
import Section2 from '../../creationEntreprise/sarl/sections/section2/section2';
import Section3 from '../../creationEntreprise/sarl/sections/section3/section3';
import Section4 from '../../creationEntreprise/sarl/sections/section4/section4';
import Footer from '../../../../components/footer/footer';

function ChangeAdresse() {
  return (
    <div className="bg-white"> 
      <Header />
      <ChangeAdresseHeader/>
      <Rateandgoogle />
      <div>
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
      </div>
      <div className=" bg-link py-16 px-10 md:px-[120px] ">
        <h3 className='text-4xl  text-white font-bold'>Vous souhaitez démarrer <br></br> votre projet avec nous?</h3>
      </div>
      <Footer />
    </div>
  );
}

export default ChangeAdresse;