/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import StepProgress from '../../../../../components/stepProgress/stepProgress';
import { Button } from '@mui/material';
import CustomRadioBox from '../../../../../components/inputs/customRadioBox/customRadioBox';
import CustomTextInput from '../../../../../components/inputs/customTextInput/customTextInput';
import usericon from '../../../../../assets/icons/usericon.png';
import CustomCheckBox from '../../../../../components/inputs/customCheckBox/customCheckBox';
import CustomSelect from '../../../../../components/inputs/customSelect/customSelect';
import getEnvironement from '../../../../../environnement';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

function PacteAssociesForm() {
  const [currentStep, setCurrentStep] = useState(1);
  const [typeAssocie, setTypeAssocie] = useState('');
  const [oneInvestisseur, setOneInvestisseur] = useState('');
  const [oneFounder, setFounder] = useState('');

  //step2
  const [isImmatriculated, setIsImmatriculated] = useState('');
  const [dateImmatriculation, setDateImmatriculation] = useState('');
  const [denomination, setDenomination] = useState('');
  const [adresseSiege, setAdresseSiege] = useState('');
  const [numeroRCS, setNumeroRCS] = useState('');
  const [villeRCS, setVilleRCS] = useState('');
  const [signataire, setSignataire] = useState('');
  const [qualiteSignataire, setQualiteSignataire] = useState('');
  const [activite, setActivite] = useState('');
  const [montantCapital, setMontantCapital] = useState('');
  const [nombreParts, setNombreParts] = useState('');

  //step3
  const [relationsOperationnelles, setRelationsOperationnelles] = useState('');
  const [gerant, setGerant] = useState('');
  const [gerantName, setGerantName] = useState('');
  const [dureeGerant, setDureeGerant] = useState('');
  const [dureeAnnees, setDureeAnnees] = useState('');
  const [fonctionsSalariees, setFonctionsSalariees] = useState('');
  const [salarieName, setSalarieName] = useState('');
  const [fonctions, setFonctions] = useState('');
  const [relationsContractuelles, setRelationsContractuelles] = useState('');
  const [contractName, setContractName] = useState('');
  const [objetContrat, setObjetContrat] = useState('');

  //step4
  const [accordPrealable, setAccordPrealable] = useState('');
  const [budgetAnnuel, setBudgetAnnuel] = useState(false);
  const [acquisitionCession, setAcquisitionCession] = useState(false);
  const [lancementNouveauProduit, setLancementNouveauProduit] = useState(false);
  const [souscriptionEmprunt, setSouscriptionEmprunt] = useState(false);
  const [transfertAcquisitionActif, setTransfertAcquisitionActif] = useState(false);
  const [embaucheSalarie, setEmbaucheSalarie] = useState(false);
  const [recoursPrestataire, setRecoursPrestataire] = useState(false);
  const [investissementSociete, setInvestissementSociete] = useState(false);
  const [augmentationCapital, setAugmentationCapital] = useState(false);
  const [emissionValeursMobilieres, setEmissionValeursMobilieres] = useState(false);
  const [transformationSociete, setTransformationSociete] = useState(false);
  const [modificationStatuts, setModificationStatuts] = useState(false);
  const [fusionScission, setFusionScission] = useState(false);
  const [nominationDirigeant, setNominationDirigeant] = useState(false);
  const [autres, setAutres] = useState(false);
  const [quiAccordPrealable, setQuiAccordPrealable] = useState('');
  const [associesMajorite, setAssociesMajorite] = useState('');
  const [associesUnanimite, setAssociesUnanimite] = useState('');
  const [associesNoms, setAssociesNoms] = useState('');
  const [organeNom, setOrganeNom] = useState('');
  const [organeMajorite, setOrganeMajorite] = useState('');
  const [majoriteRequise, setMajoriteRequise] = useState('');
  const [montantAccordLancement, setMontantAccordLancement] = useState('');
  const [lancementAccord, setLancementAccord] = useState('');
  const [montantPrestataire, setMontantPrestataire] = useState('');
  const [montantInvestissement, setMontantInvestissement] = useState('');
  const [autresPrecisez, setAutresPrecisez] = useState('');
  const [accordEmbauche, setAccordEmbauche] = useState('');
  const [montantSalaireAnnuel, setMontantSalaireAnnuel] = useState('');

  //step5
  const [clauseReporting, setClauseReporting] = useState('');
  const [beneficiaireReporting, setBeneficiaireReporting] = useState('');
  const [autreBeneficiaire, setAutreBeneficiaire] = useState('');
  const [natureReporting, setNatureReporting] = useState('');

  //step6
  const [droitPreemption, setDroitPreemption] = useState('');
  const [exceptionsPreemption, setExceptionsPreemption] = useState('');
  const [ventesEntreAssocies, setVentesEntreAssocies] = useState('');
  const [ventesPersonneMorale, setVentesPersonneMorale] = useState('');
  const [ventesHoldingPersonnelle, setVentesHoldingPersonnelle] = useState('');
  const [autreCas, setAutreCas] = useState('');

  //step7
  const [clauseQuiForce, setClauseQuiForce] = useState('');

  //step8
  const [prevoirClauseQuiOblige, setPrevoirClauseQuiOblige] = useState('');
  const [majoritePourAccepter, setMajoritePourAccepter] = useState('');

  //step9
  const [premunirContreDepart, setPremunirContreDepart] = useState('');
  const [personneCleName, setPersonneCleNam] = useState('');
  const [fonctionAcutelle, setFonctionAcutelle] = useState('');
  const [valorisationType, setValorisationType] = useState('');
  const [mecanismeDecote, setMecanismeDecote] = useState('');
  const [dateFinPeriode, setDateFinPeriode] = useState('');
  const [pourcentageDecoteApplicable, setPourcentageDecoteApplicable] = useState('');
  const [dateFinPeriodeSuivante, setDateFinPeriodeSuivante] = useState('');
  const [pourcentageDecoteApplicableSuivante, setPourcentageDecoteApplicableSuivante] = useState('');
  const [dateFinPeriodeUnique, setDateFinPeriodeUnique] = useState('');
  const [pourcentageUnique, setPourcentageUnique] = useState('');
  const [isApplicable, setIsApplicable] = useState('');
  const [applicationDate, setApplicationDate] = useState('');

  //step10
  const [majoriteEnAction, setMajoriteEnAction] = useState('');
  const [clauseQuiPermetRacheter, setClauseQuiPermetRacheter] = useState('');
  const [valorisationDeBase, setValorisationDeBase] = useState('');
  const [formuleDeValorisation, setFormuleDeValorisation] = useState('');

  //step11
  const [clauseForceVente, setClauseForceVente] = useState('');

  //step12
  const [dureePacteAssocie, setDureePacteAssoocie] = useState('');
  const [dureEnAnneePacte, setDureEnAnneePacte] = useState('');
  const [isRenouvelable, setIsRenouvelable] = useState('');

  //step 13
  const [identitePremierAssocie, setIdentitePremierAssocie] = useState('');
  const [PremierAssocieGenre, setPremierAssocieGenre] = useState('');
  const [firstAssocieName, setFirstAssocieName] = useState('');
  const [firstAssocieFamilyName, setFirstAssocieFamilyName] = useState('');
  const [firstAssocieBirthDate, setFirstAssocieBirthDate] = useState('');
  const [firstAssocieLieuNaissance, setFirstAssocieLieuNaissance] = useState('');
  const [firstAssocieAdresse, setFirstAssocieAdresse] = useState('');
  const [firstAssocieNationalite, setFirstAssocieNationalite] = useState('');
  const [firstAssocieNumberOfParts, setFirstAssocieNumberOfParts] = useState('');
  const [identiteSecondAssocie, setIdentiteSecondAssocie] = useState('');
  const [SecondAssocieGenre, setSecondAssocieGenre] = useState('');
  const [nextAssocieName, setNextAssocieName] = useState('');
  const [nextAssocieFamilyName, setNextAssocieFamilyName] = useState('');
  const [nextAssocieBirthDate, setNextAssocieBirthDate] = useState('');
  const [nextAssocieLieuNaissance, setNextAssocieLieuNaissance] = useState('');
  const [nextAssocieAdresse, setNextAssocieAdresse] = useState('');
  const [nextAssocieNationalite, setNextAssocieNationalite] = useState('');
  const [nextAssocieNumberOfParts, setNextAssocieNumberOfParts] = useState('');
  const [denominationSocieteSecond, setDenominationSocieteSecond] = useState('');
  const [formeSocieteSecond, setFormeSocieteSecond] = useState('');
  const [adresseSiegeSocialSecond, setAdresseSiegeSocialSecond] = useState('');
  const [numeroRcsSecond, setNumeroRcsSecond] = useState('');
  const [villeRcsSecond, setVilleRcsSecond] = useState('');
  const [montantCapitalSecond, setMontantCapitalSecond] = useState('');
  const [prenomNomSignataireSecond, setPrenomNomSignataireSecond] = useState('');
  const [qualiteSignataireSecond, setQualiteSignataireSecond] = useState('');
  const [nombrePartsSecond, setNombrePartSecond] = useState('');

  //step 14
  const [lieuSignature, setLieuSignature] = useState('');
  const [dateSignature, setDateSignature] = useState('');
  const [nombreExemplaires, setNombreExemplaires] = useState('');

  const getQuestion = (currentStep) => {
    switch (currentStep) {
    case 1:
      return (
        <Q1
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          typeAssocie={typeAssocie}
          setTypeAssocie={setTypeAssocie}
          oneInvestisseur={oneInvestisseur}
          setOneInvestisseur={setOneInvestisseur}
          oneFounder={oneFounder}
          setFounder={setFounder}
        />
      );
    case 2:
      return (
        <Q2
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          isImmatriculated={isImmatriculated}
          setIsImmatriculated={setIsImmatriculated}
          dateImmatriculation={dateImmatriculation}
          setDateImmatriculation={setDateImmatriculation}
          denomination={denomination}
          setDenomination={setDenomination}
          adresseSiege={adresseSiege}
          setAdresseSiege={setAdresseSiege}
          numeroRCS={numeroRCS}
          setNumeroRCS={setNumeroRCS}
          villeRCS={villeRCS}
          setVilleRCS={setVilleRCS}
          signataire={signataire}
          setSignataire={setSignataire}
          qualiteSignataire={qualiteSignataire}
          setQualiteSignataire={setQualiteSignataire}
          activite={activite}
          setActivite={setActivite}
          montantCapital={montantCapital}
          setMontantCapital={setMontantCapital}
          nombreParts={nombreParts}
          setNombreParts={setNombreParts}
        />
      );
    case 3:
      return (
        <Q3
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          relationsOperationnelles={relationsOperationnelles}
          setRelationsOperationnelles={setRelationsOperationnelles}
          gerant={gerant}
          setGerant={setGerant}
          gerantName={gerantName}
          setGerantName={setGerantName}
          dureeGerant={dureeGerant}
          setDureeGerant={setDureeGerant}
          dureeAnnees={dureeAnnees}
          setDureeAnnees={setDureeAnnees}
          fonctionsSalariees={fonctionsSalariees}
          setFonctionsSalariees={setFonctionsSalariees}
          salarieName={salarieName}
          setSalarieName={setSalarieName}
          fonctions={fonctions}
          setFonctions={setFonctions}
          relationsContractuelles={relationsContractuelles}
          setRelationsContractuelles={setRelationsContractuelles}
          contractName={contractName}
          setContractName={setContractName}
          objetContrat={objetContrat}
          setObjetContrat={setObjetContrat}
        />
      );
    case 4:
      return (
        <Q4
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          accordPrealable={accordPrealable}
          setAccordPrealable={setAccordPrealable}
          budgetAnnuel={budgetAnnuel}
          setBudgetAnnuel={setBudgetAnnuel}
          acquisitionCession={acquisitionCession}
          setAcquisitionCession={setAcquisitionCession}
          lancementNouveauProduit={lancementNouveauProduit}
          setLancementNouveauProduit={setLancementNouveauProduit}
          souscriptionEmprunt={souscriptionEmprunt}
          setSouscriptionEmprunt={setSouscriptionEmprunt}
          transfertAcquisitionActif={transfertAcquisitionActif}
          setTransfertAcquisitionActif={setTransfertAcquisitionActif}
          embaucheSalarie={embaucheSalarie}
          setEmbaucheSalarie={setEmbaucheSalarie}
          recoursPrestataire={recoursPrestataire}
          setRecoursPrestataire={setRecoursPrestataire}
          investissementSociete={investissementSociete}
          setInvestissementSociete={setInvestissementSociete}
          augmentationCapital={augmentationCapital}
          setAugmentationCapital={setAugmentationCapital}
          emissionValeursMobilieres={emissionValeursMobilieres}
          setEmissionValeursMobilieres={setEmissionValeursMobilieres}
          transformationSociete={transformationSociete}
          setTransformationSociete={setTransformationSociete}
          modificationStatuts={modificationStatuts}
          setModificationStatuts={setModificationStatuts}
          fusionScission={fusionScission}
          setFusionScission={setFusionScission}
          nominationDirigeant={nominationDirigeant}
          setNominationDirigeant={setNominationDirigeant}
          autres={autres}
          setAutres={setAutres}
          quiAccordPrealable={quiAccordPrealable}
          setQuiAccordPrealable={setQuiAccordPrealable}
          associesMajorite={associesMajorite}
          setAssociesMajorite={setAssociesMajorite}
          associesUnanimite={associesUnanimite}
          setAssociesUnanimite={setAssociesUnanimite}
          associesNoms={associesNoms}
          setAssociesNoms={setAssociesNoms}
          organeNom={organeNom}
          setOrganeNom={setOrganeNom}
          organeMajorite={organeMajorite}
          setOrganeMajorite={setOrganeMajorite}
          majoriteRequise={majoriteRequise}
          setMajoriteRequise={setMajoriteRequise}
          montantAccordLancement={montantAccordLancement}
          setMontantAccordLancement={setMontantAccordLancement}
          lancementAccord={lancementAccord}
          setLancementAccord={setLancementAccord}
          montantPrestataire={montantPrestataire}
          setMontantPrestataire={setMontantPrestataire}
          montantInvestissement={montantInvestissement}
          setMontantInvestissement={setMontantInvestissement}
          autresPrecisez={autresPrecisez}
          setAutresPrecisez={setAutresPrecisez}
          accordEmbauche={accordEmbauche}
          setAccordEmbauche={setAccordEmbauche}
          montantSalaireAnnuel={montantSalaireAnnuel}
          setMontantSalaireAnnuel={setMontantSalaireAnnuel}


        />
      );
    case 5:
      return (
        <Q5
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          clauseReporting={clauseReporting}
          setClauseReporting={setClauseReporting}
          beneficiaireReporting={beneficiaireReporting}
          setBeneficiaireReporting={setBeneficiaireReporting}
          autreBeneficiaire={autreBeneficiaire}
          setAutreBeneficiaire={setAutreBeneficiaire}
          natureReporting={natureReporting}
          setNatureReporting={setNatureReporting}
        />
      );
    
    case 6:
      return (
        <Q6
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          droitPreemption={droitPreemption}
          setDroitPreemption={setDroitPreemption}
          exceptionsPreemption={exceptionsPreemption}
          setExceptionsPreemption={setExceptionsPreemption}
          ventesEntreAssocies={ventesEntreAssocies}
          setVentesEntreAssocies={setVentesEntreAssocies}
          ventesPersonneMorale={ventesPersonneMorale}
          setVentesPersonneMorale={setVentesPersonneMorale}
          ventesHoldingPersonnelle={ventesHoldingPersonnelle}
          setVentesHoldingPersonnelle={setVentesHoldingPersonnelle}
          autreCas={autreCas}
          setAutreCas={setAutreCas}
        />
      );
    case 7:
      return (
        <Q7
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          clauseQuiForce={clauseQuiForce}
          setClauseQuiForce={setClauseQuiForce}


        />
      );
    case 8:
      return (
        <Q8
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          prevoirClauseQuiOblige={prevoirClauseQuiOblige}
          setPrevoirClauseQuiOblige={setPrevoirClauseQuiOblige}
          majoritePourAccepter={majoritePourAccepter}
          setMajoritePourAccepter={setMajoritePourAccepter}



        />
      );

    case 9:
      return (
        <Q9
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          premunirContreDepart={premunirContreDepart}
          setPremunirContreDepart={setPremunirContreDepart}
          personneCleName = {personneCleName}
          setPersonneCleNam={setPersonneCleNam}
          fonctionAcutelle={fonctionAcutelle}
          setFonctionAcutelle={setFonctionAcutelle}
          valorisationType={valorisationType}
          setValorisationType={setValorisationType}
          mecanismeDecote={mecanismeDecote}
          setMecanismeDecote={setMecanismeDecote}
          dateFinPeriode={dateFinPeriode}
          setDateFinPeriode={setDateFinPeriode}
          pourcentageDecoteApplicable={pourcentageDecoteApplicable}
          setPourcentageDecoteApplicable={setPourcentageDecoteApplicable}
          dateFinPeriodeSuivante={dateFinPeriodeSuivante}
          setDateFinPeriodeSuivante={setDateFinPeriodeSuivante}
          pourcentageDecoteApplicableSuivante={pourcentageDecoteApplicableSuivante}
          setPourcentageDecoteApplicableSuivante={setPourcentageDecoteApplicableSuivante}
          dateFinPeriodeUnique={dateFinPeriodeUnique}
          setDateFinPeriodeUnique={setDateFinPeriodeUnique}
          pourcentageUnique={pourcentageUnique}
          setPourcentageUnique={setPourcentageUnique}
          isApplicable={isApplicable}
          setIsApplicable={setIsApplicable}
          applicationDate={applicationDate}
          setApplicationDate={setApplicationDate}

        />
      );

    case 10:
      return (
        <Q10
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          majoriteEnAction={majoriteEnAction}
          setMajoriteEnAction={setMajoriteEnAction}
          clauseQuiPermetRacheter={clauseQuiPermetRacheter}
          setClauseQuiPermetRacheter={setClauseQuiPermetRacheter}
          valorisationDeBase={valorisationDeBase}
          setValorisationDeBase={setValorisationDeBase}
          formuleDeValorisation={formuleDeValorisation}
          setFormuleDeValorisation={setFormuleDeValorisation}
      

        />
      );
    
    case 11:
      return (
        <Q11
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          clauseForceVente={clauseForceVente}
          setClauseForceVente={setClauseForceVente}

        />
      );

    case 12:
      return (
        <Q12
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          dureePacteAssocie={dureePacteAssocie}
          setDureePacteAssoocie={setDureePacteAssoocie}
          dureEnAnneePacte={dureEnAnneePacte}
          setDureEnAnneePacte={setDureEnAnneePacte}
          isRenouvelable={isRenouvelable}
          setIsRenouvelable={setIsRenouvelable}
        />
      );

    case 13:
      return (
        <Q13
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          identitePremierAssocie={identitePremierAssocie}
          setIdentitePremierAssocie= {setIdentitePremierAssocie}
          PremierAssocieGenre= {PremierAssocieGenre}
          setPremierAssocieGenre= {setPremierAssocieGenre}
          firstAssocieName= {firstAssocieName}
          setFirstAssocieName=  {setFirstAssocieName}
          firstAssocieFamilyName= {firstAssocieFamilyName}
          setFirstAssocieFamilyName= {setFirstAssocieFamilyName}
          firstAssocieBirthDate=  {firstAssocieBirthDate}
          setFirstAssocieBirthDate= {setFirstAssocieBirthDate}
          firstAssocieLieuNaissance= {firstAssocieLieuNaissance}
          setFirstAssocieLieuNaissance= {setFirstAssocieLieuNaissance}
          firstAssocieAdresse= {firstAssocieAdresse}
          setFirstAssocieAdresse= {setFirstAssocieAdresse}
          firstAssocieNationalite= {firstAssocieNationalite}
          setFirstAssocieNationalite= {setFirstAssocieNationalite}
          firstAssocieNumberOfParts= {firstAssocieNumberOfParts}
          setFirstAssocieNumberOfParts= {setFirstAssocieNumberOfParts}
          identiteSecondAssocie = {identiteSecondAssocie}
          setIdentiteSecondAssocie = {setIdentiteSecondAssocie}
          SecondAssocieGenre = { SecondAssocieGenre}
          setSecondAssocieGenre = { setSecondAssocieGenre}
          nextAssocieName = { nextAssocieName}
          setNextAssocieName = { setNextAssocieName}
          nextAssocieFamilyName = { nextAssocieFamilyName}
          setNextAssocieFamilyName = { setNextAssocieFamilyName}
          nextAssocieBirthDate = { nextAssocieBirthDate}
          setNextAssocieBirthDate = { setNextAssocieBirthDate}
          nextAssocieLieuNaissance = { nextAssocieLieuNaissance}
          setNextAssocieLieuNaissance = { setNextAssocieLieuNaissance}
          nextAssocieAdresse = { nextAssocieAdresse}
          setNextAssocieAdresse = { setNextAssocieAdresse}
          nextAssocieNationalite = { nextAssocieNationalite}
          setNextAssocieNationalite = { setNextAssocieNationalite}
          nextAssocieNumberOfParts = { nextAssocieNumberOfParts}
          setNextAssocieNumberOfParts = { setNextAssocieNumberOfParts}
          denominationSocieteSecond = { denominationSocieteSecond}
          setDenominationSocieteSecond = { setDenominationSocieteSecond}
          formeSocieteSecond = { formeSocieteSecond}
          setFormeSocieteSecond = { setFormeSocieteSecond}
          adresseSiegeSocialSecond = { adresseSiegeSocialSecond}
          setAdresseSiegeSocialSecond = { setAdresseSiegeSocialSecond}
          numeroRcsSecond = { numeroRcsSecond}
          setNumeroRcsSecond = { setNumeroRcsSecond}
          villeRcsSecond = { villeRcsSecond}
          setVilleRcsSecond = { setVilleRcsSecond}
          montantCapitalSecond = { montantCapitalSecond}
          setMontantCapitalSecond = { setMontantCapitalSecond}
          prenomNomSignataireSecond = { prenomNomSignataireSecond}
          setPrenomNomSignataireSecond = { setPrenomNomSignataireSecond}
          qualiteSignataireSecond = { qualiteSignataireSecond}
          setQualiteSignataireSecond = { setQualiteSignataireSecond}
          nombrePartsSecond = { nombrePartsSecond}
          setNombrePartSecond = { setNombrePartSecond}
          

        />
      );

    case 14:
      return (
        <Q14
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          lieuSignature={lieuSignature}
          setLieuSignature={setLieuSignature}
          dateSignature={dateSignature}
          setDateSignature={setDateSignature}
          nombreExemplaires={nombreExemplaires}
          setNombreExemplaires={setNombreExemplaires}
          handleClick={handleEnregisterClick}


        />
      );
      
      
                  
    default:
      return <Q1
        setCurrentStep={setCurrentStep}
        currentStep={currentStep}
        typeAssocie={typeAssocie}
        setTypeAssocie={setTypeAssocie}
        oneInvestisseur={oneInvestisseur}
        setOneInvestisseur={setOneInvestisseur}
        oneFounder={oneFounder}
        setFounder={setFounder}
       
      />;
    }
  };

  const handleEnregisterClick = () => {
    console.log('testss');
    const url = `${getEnvironement().API_URL}/pacteAssocies`;
    const data = {
      typeAssocie,
      oneInvestisseur,
      oneFounder,
      isImmatriculated,
      dateImmatriculation,
      denomination,
      adresseSiege,
      numeroRCS,
      villeRCS,
      signataire,
      qualiteSignataire,
      activite,
      montantCapital,
      nombreParts,
      relationsOperationnelles,
      gerant,
      gerantName,
      dureeGerant,
      dureeAnnees,
      fonctionsSalariees,
      salarieName,
      fonctions,
      relationsContractuelles,
      contractName,
      objetContrat,
      accordPrealable,
      budgetAnnuel,
      acquisitionCession,
      lancementNouveauProduit,
      souscriptionEmprunt,
      transfertAcquisitionActif,
      embaucheSalarie,
      recoursPrestataire,
      investissementSociete,
      augmentationCapital,
      emissionValeursMobilieres,
      transformationSociete,
      modificationStatuts,
      fusionScission,
      nominationDirigeant,
      autres,
      quiAccordPrealable,
      associesMajorite,
      associesUnanimite,
      associesNoms,
      organeNom,
      organeMajorite,
      majoriteRequise,
      montantAccordLancement,
      lancementAccord,
      montantPrestataire,
      montantInvestissement,
      autresPrecisez,
      accordEmbauche,
      montantSalaireAnnuel,
      clauseReporting,
      beneficiaireReporting,
      autreBeneficiaire,
      natureReporting,
      droitPreemption,
      exceptionsPreemption,
      ventesEntreAssocies,
      ventesPersonneMorale,
      ventesHoldingPersonnelle,
      autreCas,
      clauseQuiForce,
      prevoirClauseQuiOblige,
      majoritePourAccepter,
      premunirContreDepart,
      personneCleName,
      fonctionAcutelle,
      valorisationType,
      mecanismeDecote,
      dateFinPeriode,
      pourcentageDecoteApplicable,
      dateFinPeriodeSuivante,
      pourcentageDecoteApplicableSuivante,
      dateFinPeriodeUnique,
      pourcentageUnique,
      isApplicable,
      applicationDate,
      majoriteEnAction,
      clauseQuiPermetRacheter,
      valorisationDeBase,
      formuleDeValorisation,
      clauseForceVente,
      dureePacteAssocie,
      dureEnAnneePacte,
      isRenouvelable,
      identitePremierAssocie,
      PremierAssocieGenre,
      firstAssocieName,
      firstAssocieFamilyName,
      firstAssocieBirthDate,
      firstAssocieLieuNaissance,
      firstAssocieAdresse,
      firstAssocieNationalite,
      firstAssocieNumberOfParts,
      identiteSecondAssocie,
      SecondAssocieGenre,
      nextAssocieName,
      nextAssocieFamilyName,
      nextAssocieBirthDate,
      nextAssocieLieuNaissance,
      nextAssocieAdresse,
      nextAssocieNationalite,
      nextAssocieNumberOfParts,
      denominationSocieteSecond,
      formeSocieteSecond,
      adresseSiegeSocialSecond,
      numeroRcsSecond,
      villeRcsSecond,
      montantCapitalSecond,
      prenomNomSignataireSecond,
      qualiteSignataireSecond,
      nombrePartsSecond,
      lieuSignature,
      dateSignature,
      nombreExemplaires,
      
      
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    axios.post(url, data, config)
      .then((response) => {
        if (response.status === 201) {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'prestation créée avec succès !',
            confirmButtonText: 'OK'
          });
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'Une erreur est survenue lors de la création de la prestation.',
          confirmButtonText: 'OK'
        });
      });
  };

  return (
    <div className=" flex flex-col justify-start items-center px-10 mt-10 bg-white">
      <h3 className="">pacte d'associés</h3>
      <StepProgress numberSteps={14} currentStep={currentStep} />
      <div className="w-fit md:w-[700px]">
        {getQuestion(currentStep)}
        <div className="flex justify-between gap-5 my-5">
          <Button
            color="info"
            variant="outlined"
            disabled={currentStep > 1 ? false : true}
            onClick={() => {
              if (currentStep > 1) {
                setCurrentStep(currentStep - 1);
              }
            }}
          >
            {'< Retour'}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              currentStep !== 14
                ? setCurrentStep(currentStep + 1)
                : handleEnregisterClick();
            }}
          >
            {currentStep !== 14 ? 'Continuer': 'signer'}
          </Button>
        </div>
      </div>
    </div>
  );
}

function Q1 (props){
  const {
    typeAssocie,
    setTypeAssocie,
    oneInvestisseur,
    setOneInvestisseur,
    oneFounder,
    setFounder,
  } = props;
  return (
    <div className="mb-10">
      <h4 className="text-xl font-bold">01. Type d'associés dans la société</h4>
      <p className="my-2 text-md">
        Le présent modèle s'applique aux SARL. Si votre société est une SAS, il
        convient de compléter le modèle de pacte d'associés pour <Link to="/PacteAssocies/formSas">SAS</Link>.
      </p>
      <h3 className="text-lg">
        Quels sont les types d'associés de la société ?
      </h3>

      <CustomRadioBox
        row={false}
        isBlue={true}
        value={typeAssocie}
        setValue={setTypeAssocie}
        labelClass="my-2"
        items={[
          {
            label:
              'Il n\'y a que des associés opérationnels (dirigeant la société ou travaillant pour elle ou travaillant avec la société en lui fournissant des prestations diverses)',
            value: 'operational',
          },
          {
            label:
              'Il y a au moins un associé investisseur non opérationnel (qui a simplement investi de l\'argent dans la société sans travailler pour elle ou avec elle)',
            value: 'non-operational',
          },
        ]}
      />

      {typeAssocie === 'non-operational' && (
        <div className="my-2">
          <CustomRadioBox
            row={false}
            isBlue={true}
            value={oneInvestisseur}
            setValue={setOneInvestisseur}
            items={[
              { label: 'Il y a un seul investisseur', value: 'one-investor' },
              {
                label: 'Il y a plusieurs investisseurs',
                value: 'multiple-investors',
              },
            ]}
          />

          <h4 className="text-base font-medium">Combien y a-t-il d'associés fondateurs opérationnels ?</h4>

          <CustomRadioBox
            row={false}
            isBlue={true}
            value={oneFounder}
            setValue={setFounder}
            items={[
              { label: 'Il y en a un seul', value: 'one-founder' },
              { label: 'Il y en a plusieurs', value: 'multiple-founders' },
            ]}
          />
        </div>
      )}
    </div>
  );
}
function Q2 (props){
  const {
    isImmatriculated,
    setIsImmatriculated,
    dateImmatriculation,
    setDateImmatriculation,
    denomination,
    setDenomination,
    adresseSiege,
    setAdresseSiege,
    numeroRCS,
    setNumeroRCS,
    villeRCS,
    setVilleRCS,
    signataire,
    setSignataire,
    qualiteSignataire,
    setQualiteSignataire,
    activite,
    setActivite,
    montantCapital,
    setMontantCapital,
    nombreParts,
    setNombreParts,
  } = props;
  return (
    <div className="mb-10">
      <h3 className="text-xl font-bold">
        02. La SARL concernée par le pacte d'associés
      </h3>

      <h3 className="text-lg">La société est-elle déjà immatriculée ?</h3>
      <div className="my-2">
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={isImmatriculated}
          setValue={setIsImmatriculated}
          items={[
            { label: 'Oui', value: 'oui' },
            { label: 'La société est en cours de création', value: 'creation' },
          ]}
        />
      </div>

      {isImmatriculated === 'oui' && (
        <div className="mt-4">
          <h4 className="text-base font-medium">
            Identification de la SARL concernée par le pacte
          </h4>
          <div className="my-2">
            <CustomTextInput
              image={usericon}
              type="text"
              value={dateImmatriculation}
              setValue={setDateImmatriculation}
              placeholder="Date d'immatriculation de la société"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={denomination}
              setValue={setDenomination}
              placeholder="Dénomination de la société"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={adresseSiege}
              setValue={setAdresseSiege}
              placeholder="Adresse du siège social"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={numeroRCS}
              setValue={setNumeroRCS}
              placeholder="Numéro RCS"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={villeRCS}
              setValue={setVilleRCS}
              placeholder="Ville du RCS"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={signataire}
              setValue={setSignataire}
              placeholder="Prénoms et nom de la personne qui signe pour la société"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={qualiteSignataire}
              setValue={setQualiteSignataire}
              placeholder="Qualité de la personne qui signe pour la société"
            />
          </div>
          <h4 className="text-base font-medium">
            Quelle est l'activité de la SARL ?
          </h4>
          <div className="my-2">
            <CustomTextInput
              image={usericon}
              type="text"
              value={activite}
              setValue={setActivite}
              placeholder="Décrivez activité de la SARL"
            />
          </div>
          <h4 className="text-base font-medium">Capital de la SARL</h4>
          <div className="my-2">
            <CustomTextInput
              image={usericon}
              type="text"
              value={montantCapital}
              setValue={setMontantCapital}
              placeholder="Montant du capital"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={nombreParts}
              setValue={setNombreParts}
              placeholder="Nombre total de parts sociales"
            />
          </div>
        </div>
      )}

      {isImmatriculated === 'creation' && (
        <div className="mt-4">
          <h4 className="text-base font-medium">
            Identification de la SARL concernée par le pacte
          </h4>
          <div className="my-2">
            <CustomTextInput
              image={usericon}
              type="text"
              value={denomination}
              setValue={setDenomination}
              placeholder="Dénomination de la société"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={adresseSiege}
              setValue={setAdresseSiege}
              placeholder="Adresse du siège social"
            />
          </div>
          <h4 className="text-base font-medium">
            Quelle est l'activité de la SARL ?
          </h4>
          <div className="my-2">
            <CustomTextInput
              image={usericon}
              type="text"
              value={activite}
              setValue={setActivite}
              placeholder="Décrivez activité de la SARL"
            />
          </div>
          <h4 className="text-base font-medium">Capital de la SARL</h4>
          <div className="my-2">
            <CustomTextInput
              image={usericon}
              type="text"
              value={montantCapital}
              setValue={setMontantCapital}
              placeholder="Montant du capital"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={nombreParts}
              setValue={setNombreParts}
              placeholder="Nombre total de parts sociales"
            />
          </div>
        </div>
      )}
    </div>
  );
}
function Q3 (props){
  const {
    relationsOperationnelles,
    setRelationsOperationnelles,
    gerant,
    setGerant,
    gerantName,
    setGerantName,
    dureeGerant,
    setDureeGerant,
    dureeAnnees,
    setDureeAnnees,
    fonctionsSalariees,
    setFonctionsSalariees,
    salarieName,
    setSalarieName,
    fonctions,
    setFonctions,
    relationsContractuelles,
    setRelationsContractuelles,
    contractName,
    setContractName,
    objetContrat,
    setObjetContrat,
    
    
  } = props;
  return (
    <div className='mb-10'>
      <h3 className="text-xl font-bold">03. Relations opérationnelles</h3>
      <h3 className="text-lg">Souhaitez-vous préciser les fonctions opérationnelles de certains associés ?</h3>
      <div className="my-2">
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={relationsOperationnelles}
          setValue={setRelationsOperationnelles}
          items={[
            { label: 'Oui', value: 'oui' },
            { label: 'Non', value: 'non' },
          ]}
        />
      </div>

      {relationsOperationnelles === 'oui' && (
        <div className='-4'>
          <CustomCheckBox
            value={gerant === 'gerant'}
            setValue={(value) => {
              setGerant(value ? 'gerant' : '');
            }}
            label='Vous souhaitez indiquer qui est gérant (ou co-gérant)'
          />
          {gerant === 'gerant' && (
            <div className='-4'>
              <CustomTextInput
                image={usericon}
                type='text'
                value={gerantName}
                setValue={setGerantName}
                placeholder='Prénom et nom de la personne concernée'
              />
              <CustomRadioBox
                row={false}
                isBlue={true}
                value={dureeGerant}
                setValue={setDureeGerant}
                items={[
                  { label: 'Durée indéterminée', value: 'indeterminee' },
                  { label: 'Durée déterminée', value: 'determinee' },
                ]}
              />
              {dureeGerant === 'determinee' && (
                <CustomTextInput
                  image={usericon}
                  type='text'
                  value={dureeAnnees}
                  setValue={setDureeAnnees}
                  placeholder='Précisez la durée (en années)'
                />
              )}
            </div>
          )}

          <CustomCheckBox
            value={fonctionsSalariees === 'fonctionsSalariees'}
            setValue={(value) => {
              setFonctionsSalariees(value ? 'fonctionsSalariees' : '');
            }}
            label='Vous souhaitez indiquer des fonctions salariées de certains associés'
          />
          {fonctionsSalariees === 'fonctionsSalariees' && (
            <div className='-4'>
              <CustomTextInput
                image={usericon}
                type='text'
                value={salarieName}
                setValue={setSalarieName}
                placeholder='Prénom et nom de la personne concernée'
              />
              <CustomTextInput
                image={usericon}
                type='text'
                value={fonctions}
                setValue={setFonctions}
                placeholder='Fonctions de salariée'
              />
            </div>
          )}

          <CustomCheckBox
            value={relationsContractuelles === 'relationsContractuelles'}
            setValue={(value) => {
              setRelationsContractuelles(value ? 'relationsContractuelles' : '');
            }}
            label="Vous souhaitez indiquer des relations contractuelles opérationnelles entre la Société et d'autres associés"
          />
          {relationsContractuelles === 'relationsContractuelles' && (
            <div className='-4'>
              <CustomTextInput
                image={usericon}
                type='text'
                value={contractName}
                setValue={setContractName}
                placeholder='Prénom et nom de la personne concernée'
              />
              <CustomTextInput
                image={usericon}
                type='text'
                value={objetContrat}
                setValue={setObjetContrat}
                placeholder="Précisez l'objet du contrat entre cet associé et la Société"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
function Q4(props) {
  const {
    // decisionsImportantes,
    // setDecisionsImportantes,
    accordPrealable,
    setAccordPrealable,
    budgetAnnuel,
    setBudgetAnnuel,
    acquisitionCession,
    setAcquisitionCession,
    lancementNouveauProduit,
    setLancementNouveauProduit,
    souscriptionEmprunt,
    setSouscriptionEmprunt,
    transfertAcquisitionActif,
    setTransfertAcquisitionActif,
    embaucheSalarie,
    setEmbaucheSalarie,
    recoursPrestataire,
    setRecoursPrestataire,
    investissementSociete,
    setInvestissementSociete,
    augmentationCapital,
    setAugmentationCapital,
    emissionValeursMobilieres,
    setEmissionValeursMobilieres,
    transformationSociete,
    setTransformationSociete,
    modificationStatuts,
    setModificationStatuts,
    fusionScission,
    setFusionScission,
    nominationDirigeant,
    setNominationDirigeant,
    autres,
    setAutres,
    quiAccordPrealable,
    setQuiAccordPrealable,
    associesMajorite,
    setAssociesMajorite,
    associesUnanimite,
    setAssociesUnanimite,
    organeNom,
    setOrganeNom,
    organeMajorite,
    setOrganeMajorite,
    majoriteRequise,
    setMajoriteRequise,
    associesNoms,
    setAssociesNoms,
    montantAccordLancement,
    setMontantAccordLancement,
    lancementAccord,
    setLancementAccord,
    montantPrestataire,
    setMontantPrestataire,
    montantInvestissement,
    setMontantInvestissement,
    setAutresPrecisez,
    autresPrecisez,
    accordEmbauche,
    setAccordEmbauche,
    montantSalaireAnnuel,
    setMontantSalaireAnnuel,
  } = props;

  return (
    <div className="mb-10">
      <h3 className="text-xl font-bold">04. Décisions importantes</h3>
      <h3 className="text-lg">
        Souhaitez-vous que certaines décisions importantes soient soumises à un
        accord préalable ?
      </h3>
      <div className="my-2">
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={accordPrealable}
          setValue={setAccordPrealable}
          items={[
            { label: 'Oui', value: 'oui' },
            { label: 'Non', value: 'non' },
          ]}
        />
      </div>

      {accordPrealable === 'oui' && (
        <div className="mt-4">
          <h4 className="text-base font-medium">Qui doit donner cet accord préalable ?</h4>
          <div className="my-2">
            <CustomRadioBox
              row={false}
              isBlue={true}
              value={quiAccordPrealable}
              setValue={setQuiAccordPrealable}
              items={[
                { label: 'Les associés ou certains associés', value: 'associes' },
                {
                  label: 'Les autres dirigeants (président, DG, DGD...)',
                  value: 'dirigeants',
                },
                {
                  label:
                    'Un organe collégial existant dans les statuts (type conseil de surveillance ou conseil d\'administration)',
                  value: 'organe',
                },
              ]}
            />
          </div>
          {quiAccordPrealable === 'associes' && (
            <div className="mt-4">
              <CustomRadioBox
                row={false}
                isBlue={true}
                value={associesMajorite}
                setValue={setAssociesMajorite}
                items={[
                  {
                    label:
                      'Les associés à une certaine majorité (en nombres d\'actions détenues)',
                    value: 'majorite',
                  },
                  {
                    label:
                      'Certains associés (qui doivent unanimement être d\'accord)',
                    value: 'unanimite',
                  },
                ]}
              />

              {associesMajorite === 'majorite' && (
                <CustomTextInput
                  image={usericon}
                  type="text"
                  value={majoriteRequise}
                  setValue={setMajoriteRequise}
                  placeholder="Précisez en pourcentage quelle est la majorité requise en nombre d'actions"
                />
              )}
              {associesMajorite === 'unanimite' && (
                <CustomTextInput
                  image={usericon}
                  type="text"
                  value={associesUnanimite}
                  setValue={setAssociesUnanimite}
                  placeholder="Précisez lesquels"
                />
              )}
            </div>
          )}
          {quiAccordPrealable === 'dirigeants' && (
            <div className="mt-4">
              <CustomTextInput
                image={usericon}
                type="text"
                value={associesNoms}
                setValue={setAssociesNoms}
                placeholder="Précisez leurs noms et prénoms"
              />
            </div>
          )}

          {quiAccordPrealable === 'organe' && (
            <div className="mt-4">
              <CustomTextInput
                image={usericon}
                type="text"
                value={organeNom}
                setValue={setOrganeNom}
                placeholder="Quel est le nom de cet organe ?"
              />
              <CustomTextInput
                image={usericon}
                type="text"
                value={organeMajorite}
                setValue={setOrganeMajorite}
                placeholder="A quelle majorité (en nombre de voix) cet organe approuve-t-il les décisions importantes ?"
              />
            </div>
          )}

          <h4 className="text-base font-medium">
            Quelles sont les décisions importantes devant être soumises à un
            accord préalable ?
          </h4>
          <CustomCheckBox
            value={budgetAnnuel}
            setValue={setBudgetAnnuel}
            label="Approbation et modification du budget annuel de la société"
          />
          <CustomCheckBox
            value={acquisitionCession}
            setValue={setAcquisitionCession}
            label="Acquisition ou cession par la société d’entreprise, création de filiale ou de succursale, prise de participation dans toute société"
          />
          <CustomCheckBox
            value={lancementNouveauProduit}
            setValue={setLancementNouveauProduit}
            label="Lancement de nouveau produit, service ou toute nouvelle activité"
          />
          {lancementNouveauProduit && (
            <div className="-4">
              <CustomRadioBox
                row={false}
                isBlue={true}
                value={lancementAccord}
                setValue={setLancementAccord}
                items={[
                  {
                    label: 'Accord nécessaire au premier euro',
                    value: 'premier_euro',
                  },
                  {
                    label: 'Accord nécessaire au delà d\'un certain montant',
                    value: 'delà_montant',
                  },
                ]}
              />
              {lancementAccord === 'delà_montant' && (
                <CustomTextInput
                  image={usericon}
                  type="text"
                  value={montantAccordLancement}
                  setValue={setMontantAccordLancement}
                  placeholder="A partir de quel montant laccord est-il nécessaire ?"
                />
              )}
            </div>
          )}
          <CustomCheckBox
            value={souscriptionEmprunt}
            setValue={setSouscriptionEmprunt}
            label="Souscription de tout emprunt"
          />
          <CustomCheckBox
            value={transfertAcquisitionActif}
            setValue={setTransfertAcquisitionActif}
            label="Transfert ou acquisition de tout actif important pour la société"
          />
          <CustomCheckBox
            value={embaucheSalarie}
            setValue={setEmbaucheSalarie}
            label="Embauche par la société d’un salarié"
          />
          {embaucheSalarie && (
            <div className="-4">
              <CustomRadioBox
                row={false}
                isBlue={true}
                value={accordEmbauche}
                setValue={setAccordEmbauche}
                items={[
                  {
                    label: 'Accord nécessaire pour toute embauche',
                    value: 'toute_embauche',
                  },
                  {
                    label: 'Accord nécessaire dès lors que le salaire annuel',
                    value: 'salaire_annuel',
                  },
                ]}
              />
              {accordEmbauche === 'salaire_annuel' && (
                <CustomTextInput
                  image={usericon}
                  type="text"
                  value={montantSalaireAnnuel}
                  setValue={setMontantSalaireAnnuel}
                  placeholder="Précisez le montant du salaire annuel (en brut)"
                />
              )}
            </div>
          )}
          <CustomCheckBox
            value={recoursPrestataire}
            setValue={setRecoursPrestataire}
            label="Recours à tout prestataire extérieur dont les prestations annuelles dépassent un certain montant"
          />
          {recoursPrestataire && (
            <CustomTextInput
              image={usericon}
              type="text"
              value={montantPrestataire}
              setValue={setMontantPrestataire}
              placeholder="Précisez le montant par prestataire (hors taxes)"
            />
          )}
          <CustomCheckBox
            value={investissementSociete}
            setValue={setInvestissementSociete}
            label="Investissement réalisé par la société"
          />
          {investissementSociete && (
            <CustomTextInput
              image={usericon}
              type="text"
              value={montantInvestissement}
              setValue={setMontantInvestissement}
              placeholder="Précisez le montant annuel d\investissement au-delà duquel laccord est requis"
            />
          )}
          <CustomCheckBox
            value={augmentationCapital}
            setValue={setAugmentationCapital}
            label="Toute augmentation, amortissement ou réduction du capital"
          />
          <CustomCheckBox
            value={emissionValeursMobilieres}
            setValue={setEmissionValeursMobilieres}
            label="Toute émission de valeurs mobilières par la société"
          />
          <CustomCheckBox
            value={transformationSociete}
            setValue={setTransformationSociete}
            label="Toute décision de transformation de la société"
          />
          <CustomCheckBox
            value={modificationStatuts}
            setValue={setModificationStatuts}
            label="Toute modification des statuts de la société"
          />
          <CustomCheckBox
            value={fusionScission}
            setValue={setFusionScission}
            label="Toute fusion, scission, apport partiel d\actif ou dissolution de la société"
          />
          <CustomCheckBox
            value={nominationDirigeant}
            setValue={setNominationDirigeant}
            label="Nomination ou révocation ou changement de rémunération de tout dirigeant"
          />
          <CustomCheckBox value={autres} setValue={setAutres} label="Autres" />
          {autres && (
            <CustomTextInput
              image={usericon}
              type="text"
              value={autresPrecisez}
              setValue={setAutresPrecisez}
              placeholder="Précisez"
            />
          )}
        </div>
      )}
    </div>
  );
}
function Q5 (props) {
  const {
    clauseReporting,
    setClauseReporting,
    beneficiaireReporting,
    setBeneficiaireReporting,
    autreBeneficiaire,
    setAutreBeneficiaire,
    natureReporting,
    setNatureReporting,

    
  } = props;
  return (
    <div className='mb-10'>
      <h3 className="text-xl font-bold">05. Reporting - Information renforcée</h3>
      <h3 className="text-lg">Souhaitez-vous instaurer une clause de reporting (droit d'information renforcé des associés ou certains associés) ?</h3>
      <div className="my-2">
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={clauseReporting}
          setValue={setClauseReporting}
          items={[
            { label: 'Oui (le plus commun)', value: 'oui' },
            { label: 'Non', value: 'non' },
          ]}
        />
      </div>

      {clauseReporting === 'oui' && (
        <div className='mt-4'>
          <CustomRadioBox
            row={false}
            isBlue={true}
            value={beneficiaireReporting}
            setValue={setBeneficiaireReporting}
            items={[
              { label: 'Tous les associés', value: 'tous_les_associes' },
              { label: 'Une autre personne associée ou groupe de personnes associées', value: 'autre_personne' },
            ]}
            label='A qui bénéficie le reporting ?'
          />
          {beneficiaireReporting === 'autre_personne' && (
            <CustomTextInput
              image={usericon}
              type='text'
              value={autreBeneficiaire}
              setValue={setAutreBeneficiaire}
              placeholder='Précisez leur identité'
            />
          )}

          <CustomTextInput
            image={usericon}
            type='text'
            value={natureReporting}
            setValue={setNatureReporting}
            placeholder='En quoi consiste ce droit d\information renforcé ? Précisez la nature des informations et la fréquence de reporting.'
          />
        </div>
      )}
    </div>

  );
}
function Q6 (props){
  const {
    droitPreemption,
    setDroitPreemption,
    exceptionsPreemption,
    setExceptionsPreemption,
    ventesEntreAssocies,
    setVentesEntreAssocies,
    ventesPersonneMorale,
    setVentesPersonneMorale,
    ventesHoldingPersonnelle,
    setVentesHoldingPersonnelle,
    autreCas,
    setAutreCas,
  } = props;
  return (
    <div className='mb-10'>
      <h3 className="text-xl font-bold">06. Clause de droit de préemption</h3>
      <h3 className="text-lg">Souhaitez-vous prévoir un droit de priorité (droit de préemption) des autres associés si un associé souhaite vendre ses actions ?</h3>
      <div className="my-2">
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={droitPreemption}
          setValue={setDroitPreemption}
          items={[
            { label: 'Oui', value: 'oui' },
            { label: 'Non', value: 'non' },
          ]}
        />
      </div>

      {droitPreemption === 'oui' && (
        <div className='mt-4'>
          <h4 className="text-sm font-medium">Souhaitez-vous prévoir des exceptions pour lesquelles le droit de préemption ne s'applique pas ?</h4>
          <CustomRadioBox
            row={false}
            isBlue={true}
            value={exceptionsPreemption}
            setValue={setExceptionsPreemption}
            items={[
              { label: 'Oui', value: 'oui' },
              { label: 'Non', value: 'non' },
            ]}
          />

          {exceptionsPreemption === 'oui' && (
            <div className='-4'>
              <CustomCheckBox
                value={ventesEntreAssocies}
                setValue={setVentesEntreAssocies}
                label='Ventes (ou transferts) d\actions entre associés'
              />
              <CustomCheckBox
                value={ventesPersonneMorale}
                setValue={setVentesPersonneMorale}
                label='Ventes (ou transferts) d\actions par un associé personne morale à une société de son groupe'
              />
              <CustomCheckBox
                value={ventesHoldingPersonnelle}
                setValue={setVentesHoldingPersonnelle}
                label='Ventes (ou transferts) d\actions par un associé à sa holding personnelle'
              />
              <CustomCheckBox
                value={autreCas}
                setValue={setAutreCas}
                label='Dans un autre cas'
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
function Q7 (props){
  const {
    clauseQuiForce,
    setClauseQuiForce,
  } = props;
  return (
    <div className="mb-10">
      <h3 className="text-xl font-bold">
        07.Clause de droit de sortie conjointe
      </h3>
      <h3 className="text-lg">
        Souhaitez-vous prévoir une clause qui permet aux minoritaires de forcer
        la vente de leurs parts sociales si les majoritaires vendent les leurs ?
      </h3>

      <div className="my-2">
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={clauseQuiForce}
          setValue={setClauseQuiForce}
          items={[
            { label: 'Oui', value: 'oui' },
            { label: 'Non', value: 'non' },
          ]}
        />
      </div>
    </div>
  );
}

function Q8 (props){
  const {
    prevoirClauseQuiOblige,
    setPrevoirClauseQuiOblige,
    majoritePourAccepter,
    setMajoritePourAccepter,
  } = props;
  return (
    <div className="mb-10">
      <h3 className="text-xl font-bold">
        8.Clause d'obligation de sortie conjointe
      </h3>
      <h3 className="text-lg">
        Souhaitez-vous prévoir une clause qui oblige tous les associés à vendre
        leurs parts sociales si la majorité d'entre eux accepte une offre
        d'achat de 100% de la société ?
      </h3>
      <div className="my-2">
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={prevoirClauseQuiOblige}
          setValue={setPrevoirClauseQuiOblige}
          items={[
            { label: 'Oui', value: 'oui' },
            { label: 'Non', value: 'non' },
          ]}
        />
      </div>
      {prevoirClauseQuiOblige === 'oui' && (
        <div className="mt-4">
          <h4 className="text-base font-medium">
            Quelle majorité (en parts sociales) faut-il pour accepter l'offre
            d'achat et déclencher l'obligation de sortie conjointe ?
          </h4>
          <div className="my-2">
            <CustomTextInput
              image={usericon}
              type="text"
              value={majoritePourAccepter}
              setValue={setMajoritePourAccepter}
              placeholder="Précisez (en pourcentage de parts sociales)"
            />
          </div>
        </div>
      )}
    </div>
  );
}

function Q9 (props){
  const {
    premunirContreDepart,
    setPremunirContreDepart,
    personneCleName,
    setPersonneCleName,
    fonctionAcutelle,
    setFonctionAcutelle,
    valorisationType,
    setValorisationType,
    mecanismeDecote,
    setMecanismeDecote,
    dateFinPeriode,
    setDateFinPeriode,
    pourcentageDecoteApplicable,
    setPourcentageDecoteApplicable,
    dateFinPeriodeSuivante,
    setDateFinPeriodeSuivante,
    pourcentageDecoteApplicableSuivante,
    setPourcentageDecoteApplicableSuivante,
    dateFinPeriodeUnique,
    setDateFinPeriodeUnique,
    pourcentageUnique,
    setPourcentageUnique,
    isApplicable,
    setIsApplicable,
    applicationDate,
    setApplicationDate,

    
  } = props;
  return (
    <div className="mb-10">
      <h3 className="text-xl font-bold">
        09.Clause de cessation d'activité des personnes-clés (bad leaver / good
        leaver)
      </h3>
      <h3 className="text-lg">
        Souhaitez-vous vous prémunir contre le départ anticipé d'un associé en
        incluant une clause de good leaver et bad leaver ?
      </h3>
      <div className="my-2">
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={premunirContreDepart}
          setValue={setPremunirContreDepart}
          items={[
            { label: 'Oui', value: 'oui' },
            { label: 'Non', value: 'non' },
          ]}
        />
      </div>
      {premunirContreDepart === 'oui' && (
        <div className="mt-4">
          <div className="mt-2">
            <h4 className="text-base font-medium">
              Identité des personnes-clés auxquelles cette clause s'applique
            </h4>
            <div className="my-2">
              <CustomTextInput
                image={usericon}
                type="text"
                value={personneCleName}
                setValue={setPersonneCleName}
                placeholder="Prénom et nom"
              />
              <CustomSelect
                value={fonctionAcutelle}
                setValue={setFonctionAcutelle}
                items={[
                  { label: 'Président de la societe', value: 'president' },
                  {
                    label: 'Directeur général de la societe',
                    value: 'directeur_general',
                  },
                  {
                    label: 'Directeur général délégué de la societe',
                    value: 'directeur_delegue',
                  },
                  {
                    label: 'Salarie de la societe',
                    value: 'directeur_adjoint',
                  },
                  { label: 'Autre', value: 'autre' },
                ]}
                placeholder="Fonction"
              />
            </div>
          </div>
          <div className="mt-2">
            <h4 className="text-base font-medium">
              Quelle serait la valorisation servant de base au calcul du prix
              des parts sociales cédées suite à la mise en oeuvre de cette
              clause ?
            </h4>
            <div className="my-2">
              <CustomRadioBox
                row={false}
                isBlue={true}
                value={valorisationType}
                setValue={setValorisationType}
                items={[
                  {
                    label:
                      'La valorisation retenue lors de la dernière augmentation de capital (ou une valorisation par expert si aucune augmentation de capital n\'est intervenue depuis 6 mois) (cas fréquent)',
                    value: 'valorisation retenue',
                  },
                  {
                    label: 'Une valorisation par expert',
                    value: 'valorisation expert',
                  },
                  { label: 'Valeur d\'expertise', value: 'expertise' },
                  {
                    label: 'Une valorisation selon une formule précise',
                    value: 'valorisation precise',
                  },
                ]}
              />
            </div>
          </div>
          <div className="mt-2">
            <h4 className="text-base font-medium">
              Quel mécanisme de décote s'applique au prix des parts sociales en
              cas de départ volontaire ou fautif (bad leaver) ?
            </h4>
            <div className="my-2">
              <CustomRadioBox
                row={false}
                isBlue={true}
                value={mecanismeDecote}
                setValue={setMecanismeDecote}
                items={[
                  {
                    label:
                      'Décote dégressive dans le temps (cas le plus fréquent)',
                    value: 'degressive',
                  },
                  { label: 'Décote unique', value: 'unique' },
                ]}
              />
            </div>
            {mecanismeDecote === 'degressive' && (
              <div className="my-2">
                <div className="mt-2">
                  <h4 className="text-base font-medium">
                    Décote applicable à la première période
                  </h4>
                  <div className="my-2">
                    <CustomTextInput
                      image={usericon}
                      type="date"
                      value={dateFinPeriode}
                      setValue={setDateFinPeriode}
                      placeholder="Date de fin de la période pendant laquelle cette décote est applicable"
                    />
                    <CustomTextInput
                      image={usericon}
                      type="text"
                      value={pourcentageDecoteApplicable}
                      setValue={setPourcentageDecoteApplicable}
                      placeholder="Pourcentage de la décote applicable"
                      label="%"
                    />
                  </div>
                </div>
                <div className="mt-2">
                  <h4 className="text-base font-medium">
                    Décote applicable à la période suivante
                  </h4>
                  <div className="my-2">
                    <CustomTextInput
                      image={usericon}
                      type="date"
                      value={dateFinPeriodeSuivante}
                      setValue={setDateFinPeriodeSuivante}
                      placeholder="Date de fin de la période pendant laquelle cette décote est applicable"
                    />
                    <CustomTextInput
                      image={usericon}
                      type="text"
                      value={pourcentageDecoteApplicableSuivante}
                      setValue={setPourcentageDecoteApplicableSuivante}
                      placeholder="Pourcentage de la décote applicable"
                      label="%"
                    />
                  </div>
                </div>
              </div>
            )}
            {mecanismeDecote === 'unique' && (
              <div className="my-2">
                <h4 className="text-base font-medium">Décote applicable</h4>
                <div className="my-2">
                  <CustomTextInput
                    image={usericon}
                    type="date"
                    value={dateFinPeriodeUnique}
                    setValue={setDateFinPeriodeUnique}
                    placeholder="Date de fin de la période pendant laquelle cette décote est applicable"
                  />
                  <CustomTextInput
                    image={usericon}
                    type="text"
                    value={pourcentageUnique}
                    setValue={setPourcentageUnique}
                    placeholder="Pourcentage de la décote applicable"
                    label="%"
                  />
                </div>
              </div>
            )}
            <div className="my-2">
              <h4 className="text-base font-medium">
                La présente clause de bad leaver / good leaver est applicable
              </h4>
              <div className="my-2">
                <CustomRadioBox
                  row={false}
                  isBlue={true}
                  value={isApplicable}
                  setValue={setIsApplicable}
                  items={[
                    {
                      label: 'Pendant toute la durée du pacte d\'associés',
                      value: 'toute durée',
                    },
                    {
                      label:
                        'Uniquement pendant une certaine durée (au delà de cette durée, les personnes clés ne seront plus obligées de céder leurs actions si elles cessent leur activité)',
                      value: 'pendant certaine durée',
                    },
                  ]}
                />
              </div>
              {isApplicable === 'toute durée' && (
                <div className="my-2">
                  <CustomTextInput
                    image={usericon}
                    type="text"
                    value={applicationDate}
                    setValue={setApplicationDate}
                    placeholder="Précisez la date à laquelle cette clause cessera appliquer"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function Q10 (props){
  const {
    majoriteEnAction,
    setMajoriteEnAction,
    clauseQuiPermetRacheter,
    setClauseQuiPermetRacheter,
    valorisationDeBase,
    setValorisationDeBase,
    formuleDeValorisation,
    setFormuleDeValorisation,
  } = props;
  return (
    <div className="mb-10">
      <h3 className="text-xl font-bold">10.Clause de changement de contrôle</h3>
      <h3 className="text-lg">
        Si un associé est une société et que son actionnariat change de
        contrôle, souhaitez-vous prévoir une clause qui permet aux autres
        associés de racheter ses parts sociales ?
      </h3>
      <div className="my-2">
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={clauseQuiPermetRacheter}
          setValue={setClauseQuiPermetRacheter}
          items={[
            { label: 'Oui', value: 'oui' },
            { label: 'Non', value: 'non' },
          ]}
        />
      </div>
      {clauseQuiPermetRacheter === 'oui' && (
        <div className="mt-4">
          <h4 className="text-base font-medium">
            Quelle majorité (en actions) faut-il pour accepter l'offre d'achat
            et déclencher l'obligation de sortie conjointe ?
          </h4>
          <div className="my-2">
            <CustomTextInput
              image={usericon}
              type="text"
              value={majoriteEnAction}
              setValue={setMajoriteEnAction}
              placeholder="Précisez (en pourcentage d actions)"
            />
          </div>
          <div className="my-2">
            <h4 className="text-base font-medium">
              Quelle serait la valorisation servant de base au calcul du prix
              des parts sociales cédées suite à la mise en oeuvre de cette
              clause ?
            </h4>
            <div className="my-2">
              <CustomRadioBox
                row={false}
                isBlue={true}
                value={valorisationDeBase}
                setValue={setValorisationDeBase}
                items={[
                  {
                    label:
                      'La même que dans le cas d\'un good leaver (cas le plus fréquent)',
                    value: 'meme cas',
                  },
                  {
                    label:
                      'La valorisation retenue lors de la dernière augmentation de capital (ou une valorisation par expert si aucune augmentation de capital n\'est intervenue depuis 6 mois)',
                    value: 'retenu',
                  },
                  {
                    label:
                      'Par accord des parties ou par une valorisation par expert en cas de désaccord',
                    value: 'par accord',
                  },
                  {
                    label: 'Une valorisation selon une formule précise',
                    value: 'formule',
                  },
                ]}
              />
            </div>
            {valorisationDeBase === 'formule' && (
              <div className="my-2">
                <CustomTextInput
                  image={usericon}
                  type="text"
                  value={formuleDeValorisation}
                  setValue={setFormuleDeValorisation}
                  placeholder="Indiquez la formule de valorisation de la société en définissant ses termes avec précision"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
function Q11 (props){
  const {
    clauseForceVente,
    setClauseForceVente,
  } = props;
  return (
    <div className="mb-10">
      <h3 className="text-xl font-bold">11.Clause anti-dilution</h3>
      <h3 className="text-lg">
        Souhaitez-vous prévoir une clause qui permet à un associé de forcer la
        vente de ses parts sociales si un désaccord grave survient entre les
        associés ?
      </h3>
      <div className="my-2">
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={clauseForceVente}
          setValue={setClauseForceVente}
          items={[
            { label: 'Oui (cas le plus fréquent)', value: 'oui' },
            { label: 'Non', value: 'non' },
          ]}
        />
      </div>
    </div>
  );
}

function Q12 (props){
  const {
    dureePacteAssocie,
    setDureePacteAssoocie,
    dureEnAnneePacte,
    setDureEnAnneePacte,
    isRenouvelable,
    setIsRenouvelable,
  } = props;
  return (
    <div className="mb-10">
      <h3 className="text-xl font-bold">12. Durée du pacte d'associés</h3>
      <h3 className="text-lg">Quelle est la durée du pacte d'associés ?</h3>
      <div className="my-2">
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={dureePacteAssocie}
          setValue={setDureePacteAssoocie}
          items={[
            {
              label:
                'Duree de 10 ans tacitement renouvelable par periode de 5 ans (case le plus frequent)',
              value: '10ans',
            },
            { label: 'Autre durée', value: 'Autre' },
          ]}
        />
      </div>
      {dureePacteAssocie === 'Autre' && (
        <div className="my-2">
          <CustomTextInput
            image={usericon}
            type="text"
            value={dureEnAnneePacte}
            setValue={setDureEnAnneePacte}
            placeholder="Précisez la durée (en années)"
          />
          <CustomRadioBox
            row={false}
            isBlue={true}
            value={isRenouvelable}
            setValue={setIsRenouvelable}
            items={[
              {
                label:
                  'Cette durée est tacitement renouvelable pour la même durée',
                value: 'renouvelable',
              },
              {
                label: 'Cette durée n\'est pas tacitement renouvelablee',
                value: 'pas renouvelable',
              },
            ]}
          />
        </div>
      )}
    </div>
  );
}

function Q13 (props){
  const {
    identitePremierAssocie,
    setIdentitePremierAssocie,
    PremierAssocieGenre,
    setPremierAssocieGenre,
    firstAssocieName,
    setFirstAssocieName,
    firstAssocieFamilyName,
    setFirstAssocieFamilyName,
    firstAssocieBirthDate,
    setFirstAssocieBirthDate,
    firstAssocieLieuNaissance,
    setFirstAssocieLieuNaissance,
    firstAssocieAdresse,
    setFirstAssocieAdresse,
    firstAssocieNationalite,
    setFirstAssocieNationalite,
    firstAssocieNumberOfParts,
    setFirstAssocieNumberOfParts,
    denominationSocietePremier,
    setDenominationSocietePremier,
    formeSocietePremier,
    setFormeSocietePremier,
    adresseSiegeSocialPremier,
    setAdresseSiegeSocialPremier,
    numeroRcsPremier,
    setNumeroRcsPremier,
    villeRcsPremier,
    setVilleRcsPremier,
    montantCapitalPremier,
    setMontantCapitalPremier,
    prenomNomSignatairePremier,
    setPrenomNomSignatairePremier,
    qualiteSignatairePremier,
    setQualiteSignatairePremier,
    nombrePartsPremier,
    setNombrePartPremier,
    identiteSecondAssocie,
    setIdentiteSecondAssocie,
    SecondAssocieGenre,
    setSecondAssocieGenre,
    nextAssocieName,
    setNextAssocieName,
    nextAssocieFamilyName,
    setNextAssocieFamilyName,
    nextAssocieBirthDate,
    setNextAssocieBirthDate,
    nextAssocieLieuNaissance,
    setNextAssocieLieuNaissance,
    nextAssocieAdresse,
    setNextAssocieAdresse,
    nextAssocieNationalite,
    setNextAssocieNationalite,
    nextAssocieNumberOfParts,
    setNextAssocieNumberOfParts,
    denominationSocieteSecond,
    setDenominationSocieteSecond,
    formeSocieteSecond,
    setFormeSocieteSecond,
    adresseSiegeSocialSecond,
    setAdresseSiegeSocialSecond,
    numeroRcsSecond,
    setNumeroRcsSecond,
    villeRcsSecond,
    setVilleRcsSecond,
    montantCapitalSecond,
    setMontantCapitalSecond,
    prenomNomSignataireSecond,
    setPrenomNomSignataireSecond,
    qualiteSignataireSecond,
    setQualiteSignataireSecond,
    nombrePartsSecond,
    setNombrePartSecond,


  } = props;
  return (
    <div className="mb-10">
      <h3 className="text-xl font-bold">
        13. Les associés signataires du pacte
      </h3>
      <h3 className="text-lg">
        <span>Attention :</span> dans le présent modèle, tous les associés
        fondateurs sont signataires du pacte Si vous n'avez pas encore toutes
        ces informations, vous pouvez passer à l'étape suivante et compléter
        ultérieurement
      </h3>

      <div className="mt-4">
        <h4 className="text-base font-medium">Identité du premier associé</h4>
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={identitePremierAssocie}
          setValue={setIdentitePremierAssocie}
          items={[
            {
              label: 'Il s\'agit d\'une personne physique (un particulier)',
              value: 'particulier',
            },
            { label: 'Il s\'agit d\'une société', value: 'societe' },
          ]}
        />
        {identitePremierAssocie === 'particulier' && (
          <div className="my-2">
            <CustomSelect
              value={PremierAssocieGenre}
              setValue={setPremierAssocieGenre}
              items={[
                { label: 'Monsieur', value: 'monsieur' },
                { label: 'Madame', value: 'madame' },
              ]}
              placeholder="Civilité"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={firstAssocieName}
              setValue={setFirstAssocieName}
              placeholder="Prenom"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={firstAssocieFamilyName}
              setValue={setFirstAssocieFamilyName}
              placeholder="Nom"
            />
            <CustomTextInput
              image={usericon}
              type="date"
              value={firstAssocieBirthDate}
              setValue={setFirstAssocieBirthDate}
              placeholder="Date de Naissance"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={firstAssocieLieuNaissance}
              setValue={setFirstAssocieLieuNaissance}
              placeholder="Lieu de naissance"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={firstAssocieAdresse}
              setValue={setFirstAssocieAdresse}
              placeholder="Adresse"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={firstAssocieNationalite}
              setValue={setFirstAssocieNationalite}
              placeholder="Nationalité"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={firstAssocieNumberOfParts}
              setValue={setFirstAssocieNumberOfParts}
              placeholder="Nombre de parts sociales de la SARL détenues par cet associés"
            />
          </div>
        )}
        {identitePremierAssocie === 'societe' && (
          <div className="my-2">
            <CustomTextInput
              image={usericon}
              type="text"
              value={denominationSocietePremier}
              setValue={setDenominationSocietePremier}
              placeholder="Dénomination de la société"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={formeSocietePremier}
              setValue={setFormeSocietePremier}
              placeholder="Forme de la société"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={adresseSiegeSocialPremier}
              setValue={setAdresseSiegeSocialPremier}
              placeholder="Adresse du siège social"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={numeroRcsPremier}
              setValue={setNumeroRcsPremier}
              placeholder="Numéro RCS"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={villeRcsPremier}
              setValue={setVilleRcsPremier}
              placeholder="Ville du RCS"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={montantCapitalPremier}
              setValue={setMontantCapitalPremier}
              placeholder="Montant du capital de la société"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={prenomNomSignatairePremier}
              setValue={setPrenomNomSignatairePremier}
              placeholder="Prénom et nom de la personne qui signe pour la société"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={qualiteSignatairePremier}
              setValue={setQualiteSignatairePremier}
              placeholder="Qualité de la personne qui signe pour la société"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={nombrePartsPremier}
              setValue={setNombrePartPremier}
              placeholder="Nombre de parts sociales de la SARL détenues par cet associé"
            />
          </div>
        )}
      </div>
      <h4 className="text-base font-medium">Identité de l'associé suivant</h4>
      <div className="my-2">
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={identiteSecondAssocie}
          setValue={setIdentiteSecondAssocie}
          items={[
            {
              label: 'Il s\'agit d\'une personne physique (un particulier)',
              value: 'particulier',
            },
            { label: 'Il s\'agit d\'une société', value: 'societe' },
          ]}
        />
      </div>
      {identiteSecondAssocie === 'particulier' && (
        <div className="my-2">
          <CustomSelect
            value={SecondAssocieGenre}
            setValue={setSecondAssocieGenre}
            items={[
              { label: 'Monsieur', value: 'monsieur' },
              { label: 'Madame', value: 'madame' },
            ]}
            placeholder="Civilité"
          />
          <CustomTextInput
            image={usericon}
            type="text"
            value={nextAssocieName}
            setValue={setNextAssocieName}
            placeholder="Prenom"
          />
          <CustomTextInput
            image={usericon}
            type="text"
            value={nextAssocieFamilyName}
            setValue={setNextAssocieFamilyName}
            placeholder="Nom"
          />
          <CustomTextInput
            image={usericon}
            type="date"
            value={nextAssocieBirthDate}
            setValue={setNextAssocieBirthDate}
            placeholder="Date de Naissance"
          />
          <CustomTextInput
            image={usericon}
            type="text"
            value={nextAssocieLieuNaissance}
            setValue={setNextAssocieLieuNaissance}
            placeholder="Lieu de naissance"
          />
          <CustomTextInput
            image={usericon}
            type="text"
            value={nextAssocieAdresse}
            setValue={setNextAssocieAdresse}
            placeholder="Adresse"
          />
          <CustomTextInput
            image={usericon}
            type="text"
            value={nextAssocieNationalite}
            setValue={setNextAssocieNationalite}
            placeholder="Nationalité"
          />
          <CustomTextInput
            image={usericon}
            type="text"
            value={nextAssocieNumberOfParts}
            setValue={setNextAssocieNumberOfParts}
            placeholder="Nombre de parts sociales de la SARL détenues par cet associés"
          />
        </div>
      )}
      {identiteSecondAssocie === 'societe' && (
        <div className="my-2">
          <CustomTextInput
            image={usericon}
            type="text"
            value={denominationSocieteSecond}
            setValue={setDenominationSocieteSecond}
            placeholder="Dénomination de la société"
          />
          <CustomTextInput
            image={usericon}
            type="text"
            value={formeSocieteSecond}
            setValue={setFormeSocieteSecond}
            placeholder="Forme de la société"
          />
          <CustomTextInput
            image={usericon}
            type="text"
            value={adresseSiegeSocialSecond}
            setValue={setAdresseSiegeSocialSecond}
            placeholder="Adresse du siège social"
          />
          <CustomTextInput
            image={usericon}
            type="text"
            value={numeroRcsSecond}
            setValue={setNumeroRcsSecond}
            placeholder="Numéro RCS"
          />
          <CustomTextInput
            image={usericon}
            type="text"
            value={villeRcsSecond}
            setValue={setVilleRcsSecond}
            placeholder="Ville du RCS"
          />
          <CustomTextInput
            image={usericon}
            type="text"
            value={montantCapitalSecond}
            setValue={setMontantCapitalSecond}
            placeholder="Montant du capital de la société"
          />
          <CustomTextInput
            image={usericon}
            type="text"
            value={prenomNomSignataireSecond}
            setValue={setPrenomNomSignataireSecond}
            placeholder="Prénom et nom de la personne qui signe pour la société"
          />
          <CustomTextInput
            image={usericon}
            type="text"
            value={qualiteSignataireSecond}
            setValue={setQualiteSignataireSecond}
            placeholder="Qualité de la personne qui signe pour la société"
          />
          <CustomTextInput
            image={usericon}
            type="text"
            value={nombrePartsSecond}
            setValue={setNombrePartSecond}
            placeholder="Nombre de parts sociales de la SARL détenues par cet associé"
          />
        </div>
      )}
    </div>
  );
}

function Q14 (props){
  const {
    lieuSignature,
    setLieuSignature,
    dateSignature,
    setDateSignature,
    nombreExemplaires,
    setNombreExemplaires,
  } =props;
  return (
    <div className="mb-10">
      <h3 className="text-xl font-bold">
        14.Lieu et date de signature du pacte d'associés - Nombre d'exemplaires
      </h3>
      <p className="text-md">
        Si vous ne connaissez pas encore la date de signature, vous pouvez
        passer à l'étape suivante et compléter ultérieurement (informatiquement
        ou à la main)
      </p>
      <div className="mt-4">
        <h4 className="text-base font-medium">Précisez</h4>
        <div className="my-2">
          <CustomTextInput
            image={usericon}
            type="text"
            value={lieuSignature}
            setValue={setLieuSignature}
            placeholder="Nom de la ville où le contrat est signé"
          />
          <CustomTextInput
            image={usericon}
            type="text"
            value={dateSignature}
            setValue={setDateSignature}
            placeholder="Date de signature"
          />
          <CustomTextInput
            image={usericon}
            type="text"
            value={nombreExemplaires}
            setValue={setNombreExemplaires}
            placeholder="Nombre d\'exmplaires"
          />
        </div>
      </div>
      <div>

      </div>
    </div>
  );
}

export default PacteAssociesForm;