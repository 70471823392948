import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { format } from 'date-fns';
import productIdIcon from '../../../assets/icons/productIdIcon.png';
import { useNavigate } from 'react-router-dom';

function PacksTable({
  header,
  data,
}) {
  const navigate = useNavigate();
  return (
    <>
      <table className='table-auto w-full'>
        <thead>
          <tr className='border-solid border-b border-[#EBEBEB]'>
            {header.map((item, index) => {
              return (
                <th 
                  key={item}
                  className={`font-poppins font-medium text-sm/[16px] opacity-50 py-5 ${index === 0 && 'text-start w-24'}`}
                >
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((pack, index) => {
            return (
              <tr key={index} className='border-solid border-b border-[#EBEBEB]'>
                <td>
                  <div className='min-w-32 md:min-w-none flex py-2 items-center font-poppins font-normal text-sm/[16px]'>
                    <img src={productIdIcon} />
                    <p className='ml-3 max-w-14 break-words'>{pack.pivot.id}</p>
                  </div>
                </td>
                <td className='font-poppins font-normal text-sm/[16px] text-center'>{pack.title}</td>
                <td className='font-poppins font-normal text-sm/[16px] text-center opacity-50'>{pack.type}</td>
                <td className='font-poppins font-normal text-sm/[16px] text-center max-w-[25vw]'>{pack.description}</td>
                <td className='font-poppins font-normal text-sm/[16px] text-center'>{pack.maxProducts == 0 ? 'illimité' : pack.maxProducts}</td>
                <td className='font-poppins font-normal text-sm/[16px] text-center'>{pack.price}</td>
                <td className='font-poppins font-normal text-sm/[16px] text-center'>{format(new Date(pack.created_at), 'yyyy-MM-dd HH:mm')}</td>
                <td className='py-5 text-center'>
                  <Tooltip title='Consulter'>
                    <IconButton onClick={() => navigate('/products', {state: {packId: pack.pivot.id}})}>
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>

                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default PacksTable;