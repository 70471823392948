import React from 'react';
import { Outlet } from 'react-router-dom';

export default function Comptabilite() {
  return (
    <div className='comptabilite'>
      <Outlet />
    </div>
  );
}
