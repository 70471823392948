import React from 'react';
import { Link } from 'react-router-dom';
import ContratContentSqueleton from '../../contratViews/components/contratContentSqueleton/contratContentSqueleton';
import './Cse.css';

export default function Cse() {
  const itemsContent = [
    {title: 'Le principe : le droit à la formation des membres du CSE', link: '#definitionBC'},
    {title: 'Quelles sont les formations accessibles aux membres du CSE ?', link: '#caracteristiqueBC'},
    {title: 'Comment sont organisées les formations du CSE ?', link: '#dureeBC'},
    {title: 'Comment est financée la formation des membres du CSE ?', link: '#financee'},
    {title: 'L’employeur peut-il refuser la demande de formation ?', link: '#employeur'},
    {title: 'FAQ', link: '#faq'},
  ];
  return (
    <ContratContentSqueleton
      title='La formation des membres du CSE'
      subTitle=''
      titleLeftContent=''
      textButton='Remplir mon bail commercial'
      titleContent=''
      rightContent={<CseContent />}
      itemsContent={itemsContent}
    />
  );
}

function CseContent() {
  return (
    <div className='CseContent'>
      <p>
      La formation des membres du CSE est consacrée par le Code du travail.
       Les membres du <Link className='linkContent' to ='#'>Comité social et économique</Link> bénéficient de la formation nécessaire à l’exercice de leurs missions en matière de santé,
        de sécurité et de conditions de travail.
      </p>
      <p>
      Afin de remplir correctement leurs missions de représentation du personnel et de participer à la gestion de l’entreprise,
       il est important que les<Link className='linkContent' to ='#'> membres composant le CSE</Link> puissent acquérir des connaissances et des compétences sur le <Link className='linkContent' to='#'>fonctionnement du CSE</Link>.
      </p>
      <h3 id='definitionBC'>Le principe : le droit à la formation des membres du CSE</h3>
      <p>
      Le Comité social et économique est une instance représentative du personnel instituée par les <Link className='linkContent' to='#'> ordonnances Macron du 22 septembre 2017</Link> 
        afin de fusionner les rôles des délégués du personnel (DP),
       du <Link className='linkContent' to='#'>Comité d’entreprise (CE)</Link> et du <Link className='linkContent' to='#'>Comité d’hygiène, de santé et des conditions de travail</Link> (CHSCT).
      </p>
      <p>Il est obligatoire de mettre en place un CSE dans les entreprises de plus de 11 salariés, au plus tard le 1er janvier 2020. Cette date étant écoulée, il est impératif de procéder aux élections du Comité, si cela n’a pas été fait auparavant. En effet, aucune tolérance n’est admise et des sanctions pourront être appliquées pour les entreprises qui n’ont pas encore mis en place cette instance représentative du personnel.</p>
      <p className='infoContentBlue'>
     🔎  <strong>Zoom </strong>: les élections du CSE vous semblent complexes ? Pas de panique, LegalPlace vous propose une solution de vote électronique, qui constitue une manière simple, pratique et sécurisée d’élire le CSE dans votre entreprise. Par le biais d’une plateforme en ligne, tous les électeurs peuvent voter, suivre l’évolution des élections et connaître le résultat final.
      </p>
      <p>
        <h6 className='h7'>Sa principale tâche consiste à garantir le respect des droits du personnel auprès de l’employeur</h6>mais également d’assurer la communication avec les salariés.
       A ce titre, il est crucial d’assurer l’indépendance de ses membres,
        notamment par l’organisation d’<Link className='linkContent' to='#'>élections</Link> au sein de l’entreprise et l’institution d’un <Link className='linkContent' to='#'>règlement intérieur propre au CSE.</Link>
      </p>
      <p>
      Le droit à la formation dont bénéficient les membres du CSE présente deux caractéristiques :
      </p>
      <ul>
        <li><h6 className='h7'>Droit exclusivement réservé aux membres du CSE :</h6>
            seuls les membres du CSE bénéficient de ce droit à la formation,
            qui est censé leur permettre d’accomplir l’ensemble de leurs missions au cours de leur mandat.
        </li>
        <li><h6 className='h7'>Droit renouvelable : </h6>les membres du CSE ont la possibilité de demander à renouveler la formation afin de mettre à jour leurs connaissances,
         notamment lorsqu’une loi nouvelle susceptible d’impacter l’entreprise entre en vigueur,
         ou lorsque de nouveaux outils technologiques sont employés par les salariés.
        </li>
      </ul>
      <p>
      Les membres du CSE sont donc tenus de suivre une formation variée en matière de santé et de sécurité, mais également en matière économique et financière.
      </p>
      
      <h3 id='caracteristiqueBC'>Quelles sont les formations accessibles aux membres du CSE ?</h3>
      <p>
      Les types de formations dont bénéficient les membres du CSE varient selon la taille de l’entreprise. En effet, la structure de cette instance représentative du personnel se révèle plus complexe lorsque l’entreprise au sein de laquelle elle est établie dépasse certains seuils d’effectifs, nécessitant de la part des membres des compétences variées et spécifiques.
      </p>
      <p className='infoContent'>
        <strong>Bon à savoir :</strong> de manière générale, l’ensemble des membres du CSE sont initiés à son fonctionnement et au détail des missions qui leur sont confiées.
      </p>
      <h3>Les formations obligatoires</h3>
      <p>
      Selon la taille de l’entreprise, 2 catégories de formations doivent obligatoirement être dispensées aux membres du CSE :
      </p>
      <ul>
        <li><h6 className='h7'>Formation santé et sécurité :</h6> obligatoire pour toutes les entreprises.</li>
        <li><h6 className='h7'>Formation économique et financière :</h6> obligatoire uniquement pour les entreprises dont l’effectif excède 50 salariés.</li>
      </ul>
      <p>
      Les membres du CSE doivent donc impérativement suivre ces formations.
      </p>
      <h6>La formation santé et sécurité</h6>
      <p>
      Quelle que soit la taille de l’entreprise,
       les membres du CSE, titulaires ou suppléants, doivent impérativement suivre une formation santé et sécurité.
        C’est l’<Link className='linkContent' to='#'>article L.2315-18 du Code du travail</Link> qui prévoit que tous les membres du Comité social et économique bénéficient de la formation relative à la santé, la sécurité et aux conditions de travail.
      </p>
      <p>
      Cette formation leur permet d’acquérir un certain nombre de compétences.
      </p>
      <p>
      Elle leur permet de déceler et mesurer les risques professionnels, mais également :
      </p>
      <ul>
        <li>Analyser les conditions de travail ;</li>
        <li>Prévention des risques professionnels ;</li>
        <li>Amélioration des conditions de travail ;</li>
        <li>Compréhension des conditions d’exercice du <Link className='linkContent' to="#">droit d’alerte du CSE.</Link></li>
      </ul>
      <p className='infoContent'>
      Bon à savoir : les entreprises dont l’effectif excède 300 salariés doivent obligatoirement constituer une Commission santé, sécurité et conditions de travail (<Link className='linkContent'to='#'>CSSCT</Link>) au sein du CSE.
      </p>
      <h6>La formation économique et financière</h6>
      <p>
      Conformément à l’<Link className='linkContent' to='#'>article L.2315-63 du Code du Travail</Link>, les membres titulaires du CSE bénéficient de la formation économique dès lors
       que l’entreprise compte un <Link className='linkContent' to='#'>effectif de plus de 50 salariés</Link>.
       Elle a pour objectif de les sensibiliser au fonctionnement du CSE et de les initier à l’analyse des comptes de l’entreprise au moyen
        d’un module juridique et d’un module financier.
      </p>
      <table>
        <tr>
          <th>Module juridique</th>
          <th>Module financier</th>
        </tr>
        <tr>
          <td>
          Comprendre les règles d’organisation et de fonctionnement du CSE Maitriser le processus de 
            <Link className='linkContent' to='#'>consultation du CSE</Link>
              Modalités d’exercice du mandat des membres du CSE Organisation interne du CSE : rôle du président, des titulaires et des suppléants, modalités de gestion et d’administration, spécificités du règlement intérieur. Fonctionnement des
            <Link className='linkContent'to='#'>réunions du CSE</Link>
              : préparation des réunions, élaboration de l’
            <Link className='linkContent'to='#'>ordre du jour</Link>
              , établissement du procès-verbal de réunion. Analyse des impacts d’une restructuration d’entreprise Licenciement pour motif économique Comprendre les spécificités de la rupture conventionnelle collective
          </td>
          <td>
          Analyse du <Link className='linkContent' to='#'>budget</Link> de fonctionnement du CSE Comprendre les mécanismes de base de la comptabilité et de l’analyse financière Reconnaissance des éléments financiers de l’entreprise Analyse des comptes de résultat Découverte des notions de bilan et d’épargne salariale
          </td>
        </tr>
      </table>
      <p>Ainsi, la formation économique est ouverte aux seuls membres titulaires du CSE dans les entreprises d’au moins 50 salariés. Le stage de formation économique ne peut excéder 5 jours.</p>
      <p>De plus, le temps consacré à cette formation est pris sur le temps de travail et est rémunéré comme tel, sans être déduit du crédit d’heures de délégation.</p>
      <p className='infoContent'>
        <strong>Bon à savoir : </strong> le Code du travail n’ouvre pas de droit à une formation économique aux membres suppléants. Cette formation est prévue pour les membres titulaires.
      </p>
      <h3>Les formations facultatives</h3>
      <p>
      Bien qu’elles ne soient pas imposées par le Code du travail, certaines formations peuvent se révéler utiles afin de garantir le bon fonctionnement du CSE :
      </p>
      <ul>
        <li><h6>Formation du président du CSE : </h6>une formation lui permettant de présider efficacement le Comité, de se familiariser à <Link className='linkContent' to='#'>ses obligations</Link> et à son rôle ainsi qu’au fonctionnement du CSE peut faciliter le déroulement des réunions ainsi que l’exécution de ses missions.</li>
        <li><h6>Formations propres aux commissions constituées au sein du CSE :</h6>lorsqu’un accord d’entreprise impose la <Link className='linkContent' to='#'>création de commissions</Link> spécialisées au sein du CSE (commission chargée de l’égalité professionnelle, de l’information et de l’aide au logement…), une formation adaptée au sujet abordé peut favoriser l’efficacité des membres qui y sont affectés.</li>
      </ul>
      <h3>Comment sont organisées les formations du CSE ? </h3>
      <h6>Formation intra-entreprise ou inter-entreprise</h6>
      <p>
      La formation des membres du CSE peut s’organiser de plusieurs manières, de façon à l’adapter aux problématiques de l’entreprise.
      </p>
      <table>
        <tr>
          <th></th>
          <th>Formation intra-entreprise</th>
          <th>Formation inter-entreprise</th>
        </tr>
        <tr>
          <td className='text-center'><strong>Définition</strong></td> 
          <td>Formation privée dispensée exclusivement aux membres du CSE de l’entreprise, se déroulant la plupart du temps dans les locaux de la société.</td>
          <td>Formation collective dispensée aux membres des CSE de plusieurs entreprises.</td>
        </tr>
        <tr>
          <td className='text-center'><strong>Avantages</strong></td>  
          <td>Garantit la confidentialité des documents échangés et des sujets abordés.
              Le formateur se déplace dans les locaux : c’est un véritable avantage 
          </td>
          <td>
          Permet d’échanger avec d’autres membres et de comparer les solutions apportées à un même problème.
          </td>
        </tr>
      </table>
      <h3>La durée des formations</h3>
      <p>La durée de la formation dispensée aux membres du CSE dépend de son objet ainsi que de l’effectif de l’entreprise.</p>
      <table>
        <tr>
          <th>Formation santé et sécurité</th>
          <th>Formation économique</th>
        </tr>
        <tr>
          <td><p>D’après l’
            <Link className='linkContent' to='#'>article L. 2315-40 du Code du travail</Link>
                , en présence d’une Commission santé, sécurité et conditions de travail :</p>
          <ul>
            <li>
              <strong>Entreprises de moins de 300 salariés:</strong> durée minimale de 3 jours.
            </li>
            <li>
              <strong>Entreprises de moins de 300 salariés:</strong> durée minimale de 5 jours.
            </li>
          </ul>
          <p>
            <span className='underline'>En l’absence d’une Commission santé, sécurité et conditions de travail :</span> aucune durée imposée.
          </p>
                
          </td>
          <td>Durée maximale de 5 jours</td>
        </tr>
      </table>
      <p>
      Ainsi, la formation économique, financée par le Comité social et économique sur son budget de fonctionnement, ne peut excéder 5 jours.
      </p>
      <p>
      Les formations destinées aux membres du Comité social et économique sont dispensées, selon <Link className='linkContent' to='#'>l’article L.2315-17 du Code du travail</Link>
      </p>
      <ul>
        <li>soit par des organismes figurant sur une liste arrêtée par le ministre chargé du travail ;</li>
        <li>soit par des organismes agréés par le préfet de région selon la procédure prévue à <Link className='linkContent' to='#'>l’article R.2315-8</Link> qui prévoit que la liste des organismes de formation mentionnée à l’article L.2315-17 est arrêtée par le préfet de région après avis du comité régional de l’emploi, de la formation de l’orientation professionnelles.</li>
      </ul>
      <p>
      Un <Link className='linkContent' to='#'>arrêté du 2 janvier 2019 fixant la liste des organismes dont les stages sont consacrés à la formation économique</Link>, sociale et syndicale, énumère la liste des organismes agréés pour la formation des membres du Comité social et économique :
      </p>
      <ul>
        <li>Les centres de formation des organisations syndicales de travailleurs reconnues représentatives au niveau national : CGT, CFDT, CGT-FO, CFTC,…</li>
        <li>Les instituts spécialisés ;</li>
        <li>Les organismes spécialisés</li>
      </ul>
      <h3>Les formalités administratives</h3>
      <p>Au moins 30 jours avant le début de la formation,<h6>chaque membre du CSE est tenu d’adresser une demande de formation à l’employeur</h6> faisant mention d’un certain nombre d’informations :</p>
      <ul>
        <li>Date du congé pour formation ;</li>
        <li>Durée du congé pour formation ;</li>
        <li>Informations relatives à l’organisme de formation ;</li>
        <li>Tarif de la formation.</li>
      </ul>
      <p>L’employeur peut refuser la demande de formation à un membre du CSE, s’il estime que l’absence du salarié pourrait avoir des conséquences sur la marche de l’entreprise. La formation pourra alors être reportée dans la limite de 6 mois.</p>
      <p>A l’issue de la formation, une attestation d’assiduité est délivrée par l’organisme et le stagiaire doit la remettre à son employeur.</p>
      <p className='infoContent'><strong>À noter :</strong> une formation est renouvelée lorsque les membres ont exercé leur mandat pendant 4 ans. Ainsi, le renouvellement de la formation vise à actualiser ses connaissances et à se perfectionner.</p>
      <h3 id='financee'>Comment est financée la formation des membres du CSE ?</h3>
      <p>Les modalités de financement des formations des membres varient selon le type de formation dispensée.</p>
      <h3>Formation santé et sécurité</h3>
      <p>Les frais liés à la formation santé et sécurité sont entièrement pris en charge par l’employeur, dans une certaine limite :</p>
      <ul>
        <li><h6>Frais de déplacement :</h6>l’employeur prend en charge les frais de déplacement à hauteur du tarif d’un billet de train de 2e classe permettant d’effectuer le trajet directement jusqu’au lieu de dispense de la formation.</li>
        <li><h6>Frais de séjour :</h6>l’employeur prend en charge les frais de séjour à hauteur du montant de l’indemnité de mission applicable aux déplacements temporaires des fonctionnaires. Ce montant est actuellement fixé à 60€.</li>
        <li><h6>Rémunération des organismes de formation :</h6>l’employeur prend en charge la rémunération des formateurs à concurrence d’un montant dont le taux horaire, par jour et par membre formé, doit être inférieur à l’équivalent de 36 fois le SMIC.</li>
      </ul>
      <p>Le Comité social et économique peut, sur son budget de fonctionnement, compléter ces dépenses.</p>
      <h3>Formation économique</h3>
      <p>
        Conformément à l’<Link className='linkContent'to='#'>article L.2315-63 du Code du travail</Link>, “le financement de la formation est pris en charge par le Comité social et économique.” Il résulte de cette disposition législative que les frais de formation économique doivent être déduits du budget de fonctionnement du CSE.
      </p>
      <p>
      Ce budget est alimenté par une subvention de fonctionnement versée annuellement par l’employeur, dont le montant varie selon l’effectif de l’entreprise :
      </p>
      <ul>
        <li>Entre 50 et 2000 salariés : 0,20% de la masse salariale brute.</li>
        <li>2000 salariés et plus : 0,22% de la masse salariale brute.</li>
      </ul>
      <h3>Les organismes pouvant dispenser la formation</h3>
      <p>Plusieurs organismes agréés sont habilités à dispenser des formations aux membres du CSE :</p>
      <ul>
        <li><h6>Organismes agréés par arrêté ministériel :</h6>ils figurent sur une liste arrêtée par le ministre chargé du Travail.</li>
        <li><h6>Organismes agréés par le préfet de région :</h6>ils figurent sur une liste arrêtée par le préfet de région après avis du Comité régional de l’emploi, de la formation de l’orientation professionnelle.</li>
      </ul>
      <p>
      Il peut s’agir de centres rattachés aux organisations syndicales mentionnées à l’<Link className='linkContent'to='#'>article L.2135-12 du Code du travail</Link>, d’instituts universitaires ou d’autres organismes spécialisés dans la formation au CSE.
      </p>
      <p>
      Il est également possible pour les membres du Comité social et économique d’effectuer la formation en ligne sur des plateformes de digital learning, qui peuvent prendre différents aspects :
      </p>
      <ul>
        <li><h6>Massive Online Open Course (MOOC) :</h6>la formation se déroule exclusivement en ligne sur une période de plusieurs semaines, et se base sur des contenus pédagogiques interactifs (vidéos, quizz…). Au terme de cette formation, le membre participant se voit remettre un certificat.</li>
        <li><h6>Corporate Online Open Course (COOC) :</h6>la formation est dispensée tant aux membres du CSE qu’aux fournisseurs ou aux clients de l’organisme formateur, afin de les initier au fonctionnement de cette instance représentative du personnel.</li>
        <li><h6>Small Private Online Courses (SPOC) :</h6>la formation est exclusivement dispensée en ligne à un nombre de participants limités.</li>
        <li><h6>Blended learning :</h6>les participants assimilent la partie théorique de la formation en ligne, puis se font accompagner au sein de l’entreprise par un formateur afin de mettre en pratique les enseignements.</li>
      </ul>
      <h3 id='employeur'>L’employeur peut-il refuser la demande de formation ?</h3>
      <p>En principe, l’employeur ne peut refuser la demande de formation du CSE. Il existe toutefois des exceptions à cette règle soigneusement encadrées par le Code du travail :</p>
      <ul>
        <li><h6>Refus pour épuisement du congé de formation :</h6>l’employeur n’est pas tenu d’accepter la demande de formation introduite par les membres du CSE lorsque ceux-ci ont déjà effectué le nombre maximal de jours de formation exigé par la loi.</li>
        <i><h6>Refus du fait de circonstances liées à l’activité de l’entreprise :</h6>l’employeur qui estime que l’absence du salarié membre du CSE est susceptible de porter préjudice à l’entreprise est en droit, selon l’<Link className='linkContent' to='#'>article R.2315-19 du Code de travail</Link>, de refuser sa demande de formation et de la reporter dans une limite de 6 mois.</i>
      </ul>
      <p>
      La décision de <strong>refus de l’employeur doit être notifiée au salarié membre du CSE dans un délai de 8 jours</strong>
      </p>
      <h3 id='faq'>FAQ</h3>
      <h6>Qui finance la formation CSE ?</h6>
      <p>
      La formation est prise en charge par le budget de fonctionnement du CSE.
      </p>
      <h3>
      Quelle formation est obligatoire pour les membres du CSE ?
      </h3>
      <p>Deux formations doivent obligatoirement être dispensées aux membres du CSE : une formation santé et sécurité, ainsi qui une formation économique, obligatoire uniquement pour les entreprises dont effectif dépasse 50 salariés.</p>
    </div>
  );
}

