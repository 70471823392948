import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logoLegalStation from '../../assets/images/LOGO-LS@2x.png';
import './header.css';

export default function Header({ active }) {
  const [activeMenu, setActiveMenu] = useState(false);
  const [startAnimationDisactivateMenu, setStratAnimationDisactivateMenu] = useState(false);
  const [activeItem, setActiveItem] = useState(active);
  const menuItems = [
    ['Acceuil', '/'],
    ['Créer une entreprise', '/creationEntreprise'],
    ['About', '/apropos'],
    ['Packs', '/packs'],
    ['Blogs', '/blogs'],
    ['Contact', '/contact'],
  ];
  return (
    <div className='headerContainer py-2 px-5'>
      <div className='header'>
        <Link to='/'>
          <img className='logoLegalStation' src={logoLegalStation} alt='logo-legal-station' />
        </Link>
        <div className='menuItemsContainer-1024'>
          {menuItems.map(([item, link]) => 
            <Link
              className={`item ${activeItem === item && 'activeItem'}`}
              onClick={() => {
                setActiveItem(item);
                window.scrollTo(0, 0);
              }}
              key={item}
              to={link}
            >
              {item}
            </Link>
          )}
        </div>
        <Link className='login_button' to={'/login'}>
          <Button variant="contained">connexion</Button>
        </Link>
        <div className='buttonMenuIcon'>
          <IconButton
            onClick={() => {
              setActiveMenu(!activeMenu);
              setStratAnimationDisactivateMenu(true);
            }}
          >
            <MenuIcon className='menuIcon' style={{ fontSize: 40, color: 'black' }} />
          </IconButton>
        </div>
      </div>
      <div
        className={
          `menuItemsContainer-767 ${activeMenu ? 'menuItemsContainerActive' : startAnimationDisactivateMenu ? 'menuItemsContainerDisactivate' : ''}`
        }
      >
        {menuItems.map(([item, link]) => 
          <Link
            className={`item ${activeItem === item && 'activeItem'}`}
            onClick={() => {
              setActiveItem(item);
              window.scrollTo(0, 0);
            }}
            key={item}
            to={link}
          >
            {item}
          </Link>
        )}
        <Link
          className={`item ${activeItem === 'login' && 'activeItem'}`}
          onClick={() => {
            setActiveItem('login');
            window.scrollTo(0, 0);
          }}
          key={'login'}
          to={'/login'}>
            Connexion
        </Link>
      </div>
    </div>
  );
}
