import React from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import './customSelect.css';

export default function CustomSelect({ value, setValue, label, items}) {
  return (
    <div className='customSelect'>
      <FormControl fullWidth size='small'>
        <label>{label}</label>
        <div className='sizedBox' />
        <Select
          className='py-1'
          value={value}
          onChange={(e) => setValue(e.target.value)}
        >
          {items.map((item) => {
            return (<MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>);
          })}
        </Select>
      </FormControl>
    </div>
  );
}
