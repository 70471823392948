/* eslint-disable object-curly-newline */
import React from 'react';
import ColoredRectangle from './components/coloredRectangle';
import Box from '../../assets/icons/box.png';
import BoxRemove from '../../assets/icons/box-remove.png';
import BoxTime from '../../assets/icons/box-time.png';
import BoxTick from '../../assets/icons/box-tick.png';
import EmptyWalletTick from '../../assets/icons/empty-wallet-tick.png';
import EmptyWalletTime from '../../assets/icons/empty-wallet-time.png';
import EmptyWalletRemove from '../../assets/icons/empty-wallet-remove.png';
import Chart from './components/chart';
import getEnvironement from '../../environnement';
// import userprofile from '../../assets/images/userprofile.jpg';
import DashHeader from './components/dashHeader';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/userSlice';



function Statistics() {

  const user = useSelector(userSelector);

  const rectanglesData = [
    { id: 1, rectangleColor: 'bg-gradient', hoverColor: 'bg-blue-300', icon: Box, number: 50, text: 'Nombre de produits enregistrés' },
    { id: 2, rectangleColor: 'bg-staticred', hoverColor: 'bg-green-300', icon: BoxRemove , number: 50, text: 'Nombre de produits rejetées' },
    { id: 3, rectangleColor: 'bg-staticyellow', hoverColor: 'bg-yellow-300', icon: BoxTime , number: 3, text: 'Nombre de produits en cours de traitement' },
    { id: 4, rectangleColor: 'bg-staticpink', hoverColor: 'bg-red-300', icon: BoxTick , number: 4, text: 'Nombre de produits validée' },
    { id: 5, rectangleColor: 'bg-staticpink', hoverColor: 'bg-purple-300', icon: EmptyWalletTick , number: 5, text: 'Nombre de produits totalement payés' },
    { id: 6, rectangleColor: 'bg-staticgreen', hoverColor: 'bg-indigo-300', icon: EmptyWalletTime , number: 6, text: 'Nombre de produits avec un paiement non complet' },
    { id: 7, rectangleColor: 'bg-staticpurple', hoverColor: 'bg-pink-300', icon: EmptyWalletRemove , number: 7, text: 'Nombre de produits non payés' },
    { id: 8, rectangleColor: 'bg-primary', hoverColor: 'bg-gray-300', icon: EmptyWalletTick , number: 8, text: 'Montant total payée' },
  ];
  
  return (
    <>
      <DashHeader picture= {`${getEnvironement().BACKEND_URL}/storage/app/public/` + user?.profile_picture} name={user?.nom} />
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6 ">
        {rectanglesData.map(rectangle => (
          <ColoredRectangle
            key={rectangle.id}
            rectangleColor={rectangle.rectangleColor}
            hoverColor={rectangle.hoverColor}
            icon={rectangle.icon}
            number={rectangle.number}
            text={rectangle.text}
            onClick={() => console.log(`Clicked on ${rectangle.text}`)}
          />
        ))}
      </div>
      <div className='mt-10 flex flex-col lg:flex-row gap-4'>
        <div className='sm:w-full md:w-1/2 space-y-4'>
          <p className='font-bold text-balance'>Nombre de produits validés </p>
          <Chart />
        </div>

        <div className='sm:w-full md:w-1/2 space-y-4'>
          <p className='font-bold text-balance'>Paiement </p>
          <Chart />
        </div>
      </div>

    </>
  );
}

export default Statistics;