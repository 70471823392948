import React, { useState } from 'react';
import StepProgress from '../../../../../components/stepProgress/stepProgress';
import { Alert, Button, TextField, Tooltip } from '@mui/material';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import CustomTextInput from '../../../../../components/inputs/customTextInput/customTextInput';
import getEnvironement from '../../../../../environnement';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BlobProvider } from '@react-pdf/renderer';
import CGVPdfContent from '../CGVPdf/pdfcontent';
import CustomRadioBox from '../../../../../components/inputs/customRadioBox/customRadioBox';
import usericon from '../../../../../assets/icons/usericon.png';
import CustomCheckBox from '../../../../../components/inputs/customCheckBox/customCheckBox';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, userSelector } from '../../../../../store/userSlice';

function ContratCGVForm() {
  const [currentStep, setCurrentStep] = useState(1);
  const nombreStep = 18;

  /* STEP 1 : 01. Nature des conditions générales de vente */
  const [isCGV_Online, setIsCGV_Online] = useState(true);

  /* STEP 2 : Nature de l'activité */
  const [natureActivite, setNatureActivité] = useState('');
  /*
   * Des produits (cela inclut les biens digitaux)
   * Des prestations de services
   * Des produits et prestations de services
   */

  /* STEP 3 : 03. Le site internet */
  const [chooseDomaineSite, setChoosenDomaineSite] = useState(''); // https://www.site.com

  /* STEP 4 : 04. Vendeur */
  const [vendeur, setVendeur] = useState(''); // morale || physique
  const [numéroTéléphoneVendeur, setNuméroTéléphoneVendeur] = useState('');
  const [adresseEmailVendeur, setAdresseEmailVendeur] = useState('');
  const [isNuméroTVAVendeur, setIsNuméroTVAVendeur] = useState(false);
  const [numéroTVAVendeur, setNuméroTVAVendeur] = useState('');
  // morale
  const [formeSocialeVendeur, setFormeSocialeVendeur] = useState(''); //  SAS, SARL, SASU, etc...
  const [nomSociétéVendeur, setNomSociétéVendeur] = useState('');
  const [montantCapitalVendeur, setMontantCapitalVendeur] = useState('');
  const [villeImmatriculation_RCSVendeur, setVilleImmatriculation_RCSVendeur] =
    useState('');
  const [numéroSIRENVendeur, setNuméroSIRENVendeur] = useState('');
  const [adresseSiègeSocialVendeur, setAdresseSiègeSocialVendeur] =
    useState('');
  // physique
  const [nomVendeur, setNomVendeur] = useState('');
  const [adresseDomiciliationVendeur, setAdresseDomiciliationVendeur] =
    useState('');
  const [
    numéroInscriptionRCS_RépertoireMétiers,
    setNuméroInscriptionRCS_RépertoireMétiers,
  ] = useState('');

  /* STEP 5 : 05. Territoires concernés par la vente */
  const [territoires, setTerritoires] = useState('');
  const [territoiresList, setTerritoiresList] = useState('');

  /* STEP 6 : 06. Produits proposés à la vente */
  const [produitProposés, setProduitProposés] = useState('');

  /* STEP 7 : 07. Prix */
  const [isPrixShow, setIsPrixShow] = useState(false);
  const [DuréeValidité, setDuréeValidité] = useState('');

  /* STEP 8 : 08. Conditions de paiement */
  const [modesPaiementPossibles, setModesPaiementPossibles] = useState('');
  const [isPaiementVirementPossible, setIsPaiementVirementPossible] =
    useState(false);
  const [momentPaiement, setMomentPaiement] = useState('');
  /*
   * Le paiement intervient dès la passation de la commande et est débité immédiatement
   * Le paiement intervient dès la passation de la commande mais n'est débité qu'à l'expédition de la commande
   * Le paiement intervient dès la passation de la commande mais n'est débité qu'à la livraison de la commande
   * Le paiement n'intervient qu'à la livraison de la commande
   * Le paiement intervient en une fois à un terme précis
   * Le paiement intervient selon un échéancier sans frais
   */
  const [isEchéancierSansFrais, setIsEchéancierSansFrais] = useState(false);
  const [EchéancierSansFrais, setEchéancierSansFrais] = useState('');
  const [isExigiblePassationCommande, setIsExigiblePassationCommande] =
    useState(false);
  const [pourcentagePrix, setPourcentagePrix] = useState('');
  const [joursAprèsCommande, setJoursAprèsCommande] = useState('');
  const [Echéancier, setEchéancier] = useState(''); // périodicité et quote-part de paiement à chaque période

  /* STEP 9 : 09. Espace et compte client */
  const [isClientCompte, setIsClientCompte] = useState(false);
  const [clientCompteEmail, setClientCompteEmail] = useState('');

  /* STEP 10 : 10. Commandes */
  const [processusCommandes, setProcessusCommandes] = useState(''); // Exemple : Le Client choisi un Produit qu’il met dans son panier, Produit qu’il pourra supprimer ou modifier avant de valider sa commande et d’accepter les présentes conditions générales de vente. Il rentrera ensuite ses coordonnées ou se connectera à son espace et choisira le mode de livraison. Après validation des informations, la commande sera considérée comme définitive et exigera paiement de la part du Client selon les modalités prévues.

  /* STEP 11 : 11. Livraisons */
  const [isLivraisonsAutreZones, setIsLivraisonsAutreZones] = useState(false);
  const [livraisonsAutreZonesList, setLivraisonsAutreZonesList] = useState('');
  const [délaisLivraisons, setDélaisLivraisons] = useState('');
  const [délaisAnnulerCommande, setDélaisAnnulerCommande] = useState('');
  const [isTransporteurIndépendant, setIsTransporteurIndépendant] =
    useState(false);
  const [isLivraisonsAvoirLieuEnMagasin, setIsLivraisonsAvoirLieuEnMagasin] =
    useState(false);
  const [adressesMagasins, setAdressesMagasins] = useState('');
  const [délaiMaxRéclamation, setDélaiMaxRéclamation] = useState('');
  const [modeRéclamation, setModeRéclamation] = useState('');

  /* STEP 12 : 12. Transfert de la propriété */
  const [momentTransfertPropriété, setMomentTransfertPropriété] = useState('');

  /* STEP 13 : 13. Annulation de la commande et droit de retractation */
  const [isDroitRétractation, setIsDroitRétractation] = useState(false);
  const [isAnnullerCommandAvantLivraison, setIsAnnullerCommandAvantLivraison] =
    useState(false);
  const [
    typeAnnullerCommandAvantLivraison,
    setTypeAnnullerCommandAvantLivraison,
  ] = useState('');
  const [délaisPourAnnulerCommande, setDélaisPourAnnulerCommande] =
    useState('');

  /* STEP 14 : 14. Responsabilité du Vendeur - Garanties */
  const [
    délaiMaximal_Remboursement_Remplacement_Réparation,
    setDélaiMaximal_Remboursement_Remplacement_Réparation,
  ] = useState('');
  const [isGarantieContractuelle, setIsGarantieContractuelle] = useState(false);
  const [conditionsGarantieContractuelle, setConditionsGarantieContractuelle] =
    useState('');
  const [situationsGarantieApplique, setSituationsGarantieApplique] =
    useState('');
  const [situationsGarantiePasApplique, setSituationsGarantiePasApplique] =
    useState('');
  const [
    conditionsApplicationGarantieContractuelle,
    setConditionsApplicationGarantieContractuelle,
  ] = useState('');

  /* STEP 15 : 15. Traitement des données personnelles */
  const [
    finalitéTraitementDonnéesPersonnellesCollectées,
    setFinalitéTraitementDonnéesPersonnellesCollectées,
  ] = useState('');
  const [
    isAutreFinalitéTraitementDonnéesPersonnelles,
    setIsAutreFinalitéTraitementDonnéesPersonnelles,
  ] = useState(false);
  const [
    autreFinalitéTraitementDonnéesPersonnelles,
    setAutreFinalitéTraitementDonnéesPersonnelles,
  ] = useState('');
  const [
    dPCC_OuvertureCompte_VenteProduits,
    setDonnéesPersonnellesClientsCollectées_OuvertureCompte_VenteProduits,
  ] = useState('');
  const [
    autreDonnéesPersonnellesClientsCollectéesList,
    setAutreDonnéesPersonnellesClientsCollectéesList,
  ] = useState('');
  const [
    donnéesPersonnellesCollectées_Entreprise_Partenaires,
    setDonnéesPersonnellesCollectées_Entreprise_Partenaires,
  ] = useState('');
  const [DPCEP_isPrestataireTransport, setDPCEP_IsPrestataireTransport] =
    useState('');
  const [
    DPCEP_isPrestataireEtablissementPaiement,
    setDPCEP_IsPrestataireEtablissementPaiement,
  ] = useState('');
  const [DPCEP_autres, setDPCEP_Autre] = useState('');
  const [DPCEP_isAutres, setDPCEP_IsAutre] = useState(false);
  const [
    responsableTraitementDonnéesPersonnelles,
    setResponsableTraitementDonnéesPersonnelles,
  ] = useState('');
  const [
    responsableTraitementDonnéesPersonnellesIdentité,
    setResponsableTraitementDonnéesPersonnellesIdentité,
  ] = useState('');
  const [isDPOexiste, setIsDPOexiste] = useState(false);
  const [DPOIdentité, setDPOIdentité] = useState('');
  const [
    moyensDontDisposeClientSupprimerDonnéesPersonnelles,
    setMoyensDontDisposeClientSupprimerDonnéesPersonnelles,
  ] = useState('');

  /* STEP 16 : 16. Litiges */
  const [nomServiceMédiation, setNomServiceMédiation] = useState('');
  const [adressePostaleSrviceMédiation, setAdressePostaleSrviceMédiation] =
    useState('');
  const [siteInternetServiceMédiation, setSiteInternetServiceMédiation] =
    useState('');
  const [adresseEmailServiceMédiation, setAdresseEmailServiceMédiation] =
    useState('');

  /* STEP 17 : 17. Date de mise en vigueur des CGV */
  const [dateMiseVigueurCGV, setDateMiseVigueurCGV] = useState('');

  /* STEP 17 : 17. Adresse e-mail où votre document doit être envoyé */
  const [adresseEmail, setAdresseEmail] = useState('');

  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  const saveData = async (pdfBlob) => {
    const url = `${getEnvironement().API_URL}/contratCGV`;
    if (user == null) {
      return alert('try to sign in pls');
    }

    const data = new FormData();
    data.append('user_id', user.id);
    data.append('isCGV_Online', isCGV_Online ? 1 : 0);
    data.append('natureActivite', natureActivite);
    data.append('chooseDomaineSite', chooseDomaineSite);
    data.append('vendeur', vendeur);
    data.append('numéroTéléphoneVendeur', numéroTéléphoneVendeur);
    data.append('adresseEmailVendeur', adresseEmailVendeur);
    data.append('isNuméroTVAVendeur', isNuméroTVAVendeur ? 1 : 0);
    data.append('numéroTVAVendeur', numéroTVAVendeur);
    data.append('formeSocialeVendeur', formeSocialeVendeur);
    data.append('nomSociétéVendeur', nomSociétéVendeur);
    data.append('montantCapitalVendeur', montantCapitalVendeur);
    data.append(
      'villeImmatriculation_RCSVendeur',
      villeImmatriculation_RCSVendeur
    );
    data.append('numéroSIRENVendeur', numéroSIRENVendeur);
    data.append('adresseSiègeSocialVendeur', adresseSiègeSocialVendeur);
    data.append('nomVendeur', nomVendeur);
    data.append('adresseDomiciliationVendeur', adresseDomiciliationVendeur);
    data.append(
      'numéroInscriptionRCS_RépertoireMétiers',
      numéroInscriptionRCS_RépertoireMétiers
    );
    data.append('territoires', territoires);
    data.append('territoiresList', territoiresList);
    data.append('produitProposés', produitProposés);
    data.append('isPrixShow', isPrixShow ? 1 : 0);
    data.append('DuréeValidité', DuréeValidité);
    data.append('modesPaiementPossibles', modesPaiementPossibles);
    data.append('isPaiementVirementPossible', isPaiementVirementPossible ? 1 : 0);
    data.append('momentPaiement', momentPaiement);
    data.append('isEchéancierSansFrais', isEchéancierSansFrais ? 1 : 0);
    data.append('EchéancierSansFrais', EchéancierSansFrais);
    data.append('isExigiblePassationCommande', isExigiblePassationCommande ? 1 : 0);
    data.append('pourcentagePrix', pourcentagePrix);
    data.append('joursAprèsCommande', joursAprèsCommande);
    data.append('Echéancier', Echéancier);
    data.append('isClientCompte', isClientCompte ? 1 : 0);
    data.append('clientCompteEmail', clientCompteEmail);
    data.append('processusCommandes', processusCommandes);
    data.append('isLivraisonsAutreZones', isLivraisonsAutreZones ? 1 : 0);
    data.append('livraisonsAutreZonesList', livraisonsAutreZonesList);
    data.append('délaisLivraisons', délaisLivraisons);
    data.append('délaisAnnulerCommande', délaisAnnulerCommande);
    data.append('isTransporteurIndépendant', isTransporteurIndépendant ? 1 : 0);
    data.append(
      'isLivraisonsAvoirLieuEnMagasin',
      isLivraisonsAvoirLieuEnMagasin ? 1 : 0
    );
    data.append('adressesMagasins', adressesMagasins);
    data.append('délaiMaxRéclamation', délaiMaxRéclamation);
    data.append('modeRéclamation', modeRéclamation);
    data.append('momentTransfertPropriété', momentTransfertPropriété);
    data.append('isDroitRétractation', isDroitRétractation ? 1 : 0);
    data.append(
      'isAnnullerCommandAvantLivraison',
      isAnnullerCommandAvantLivraison ? 1 : 0
    );
    data.append(
      'typeAnnullerCommandAvantLivraison',
      typeAnnullerCommandAvantLivraison
    );
    data.append('délaisPourAnnulerCommande', délaisPourAnnulerCommande);
    data.append(
      'délaiMaximal_Remboursement_Remplacement_Réparation',
      délaiMaximal_Remboursement_Remplacement_Réparation
    );
    data.append('isGarantieContractuelle', isGarantieContractuelle ? 1 : 0);
    data.append(
      'conditionsGarantieContractuelle',
      conditionsGarantieContractuelle
    );
    data.append('situationsGarantieApplique', situationsGarantieApplique);
    data.append('situationsGarantiePasApplique', situationsGarantiePasApplique);
    data.append(
      'conditionsApplicationGarantieContractuelle',
      conditionsApplicationGarantieContractuelle
    );
    data.append(
      'finalitéTraitementDonnéesPersonnellesCollectées',
      finalitéTraitementDonnéesPersonnellesCollectées
    );
    data.append(
      'isAutreFinalitéTraitementDonnéesPersonnelles',
      isAutreFinalitéTraitementDonnéesPersonnelles ? 1 : 0
    );
    data.append(
      'autreFinalitéTraitementDonnéesPersonnelles',
      autreFinalitéTraitementDonnéesPersonnelles
    );
    data.append(
      'dPCC_OuvertureCompte_VenteProduits',
      dPCC_OuvertureCompte_VenteProduits
    );
    data.append(
      'autreDonnéesPersonnellesClientsCollectéesList',
      autreDonnéesPersonnellesClientsCollectéesList
    );
    data.append(
      'donnéesPersonnellesCollectées_Entreprise_Partenaires',
      donnéesPersonnellesCollectées_Entreprise_Partenaires
    );
    data.append('DPCEP_isPrestataireTransport', DPCEP_isPrestataireTransport);
    data.append(
      'DPCEP_isPrestataireEtablissementPaiement',
      DPCEP_isPrestataireEtablissementPaiement
    );
    data.append('DPCEP_isAutres', DPCEP_isAutres ? 1 : 0);
    data.append('DPCEP_autres', DPCEP_autres);
    data.append(
      'responsableTraitementDonnéesPersonnelles',
      responsableTraitementDonnéesPersonnelles
    );
    data.append(
      'responsableTraitementDonnéesPersonnellesIdentité',
      responsableTraitementDonnéesPersonnellesIdentité
    );
    data.append('isDPOexiste', isDPOexiste ? 1 : 0);
    data.append('DPOIdentité', DPOIdentité);
    data.append(
      'moyensDontDisposeClientSupprimerDonnéesPersonnelles',
      moyensDontDisposeClientSupprimerDonnéesPersonnelles
    );
    data.append('nomServiceMédiation', nomServiceMédiation);
    data.append('adressePostaleSrviceMédiation', adressePostaleSrviceMédiation);
    data.append('siteInternetServiceMédiation', siteInternetServiceMédiation);
    data.append('adresseEmailServiceMédiation', adresseEmailServiceMédiation);
    data.append('dateMiseVigueurCGV', dateMiseVigueurCGV);
    data.append('adresseEmail', adresseEmail);
    data.append('pdf', pdfBlob, 'document.pdf');

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    try {
      await axios.post(url, data, config).then((response) => {
        if (response.status === 200) {
          dispatch(setUser(response.data));
          navigate('/dashboard');
        }
      });
    } catch (error) {
      console.log('Error saving data', error);
    }
  };

  const handleSubmitCGV = (pdfBlob) => {
    if (pdfBlob) {
      // const pdfURL = URL.createObjectURL(pdfBlob);
      // window.open(pdfURL);
      saveData(pdfBlob);
    } else {
      alert('PDF is null or undefined.');
    }
  };

  const getQuestion = (currentStep) => {
    switch (currentStep) {
    case 1:
      return (
        <STEP1
          isCGV_Online={isCGV_Online}
          setIsCGV_Online={setIsCGV_Online}
        />
      );
    case 2:
      return (
        <STEP2
          natureActivite={natureActivite}
          setNatureActivité={setNatureActivité}
        />
      );
    case 3:
      return (
        <STEP3
          chooseDomaineSite={chooseDomaineSite}
          setChoosenDomaineSite={setChoosenDomaineSite}
        />
      );
    case 4:
      return (
        <STEP4
          // vendeur
          vendeur={vendeur}
          setVendeur={setVendeur}
          numéroTéléphoneVendeur={numéroTéléphoneVendeur}
          setNuméroTéléphoneVendeur={setNuméroTéléphoneVendeur}
          adresseEmailVendeur={adresseEmailVendeur}
          setAdresseEmailVendeur={setAdresseEmailVendeur}
          isNuméroTVAVendeur={isNuméroTVAVendeur}
          setIsNuméroTVAVendeur={setIsNuméroTVAVendeur}
          numéroTVAVendeur={numéroTVAVendeur}
          setNuméroTVAVendeur={setNuméroTVAVendeur}
          // morale
          formeSocialeVendeur={formeSocialeVendeur}
          setFormeSocialeVendeur={setFormeSocialeVendeur}
          nomSociétéVendeur={nomSociétéVendeur}
          setNomSociétéVendeur={setNomSociétéVendeur}
          montantCapitalVendeur={montantCapitalVendeur}
          setMontantCapitalVendeur={setMontantCapitalVendeur}
          villeImmatriculation_RCSVendeur={villeImmatriculation_RCSVendeur}
          setVilleImmatriculation_RCSVendeur={
            setVilleImmatriculation_RCSVendeur
          }
          numéroSIRENVendeur={numéroSIRENVendeur}
          setNuméroSIRENVendeur={setNuméroSIRENVendeur}
          adresseSiègeSocialVendeur={adresseSiègeSocialVendeur}
          setAdresseSiègeSocialVendeur={setAdresseSiègeSocialVendeur}
          // physique
          nomVendeur={nomVendeur}
          setNomVendeur={setNomVendeur}
          adresseDomiciliationVendeur={adresseDomiciliationVendeur}
          setAdresseDomiciliationVendeur={setAdresseDomiciliationVendeur}
          numéroInscriptionRCS_RépertoireMétiers={
            numéroInscriptionRCS_RépertoireMétiers
          }
          setNuméroInscriptionRCS_RépertoireMétiers={
            setNuméroInscriptionRCS_RépertoireMétiers
          }
        />
      );
    case 5:
      return (
        <STEP5
          territoires={territoires}
          setTerritoires={setTerritoires}
          territoiresList={territoiresList}
          setTerritoiresList={setTerritoiresList}
        />
      );
    case 6:
      return (
        <STEP6
          produitProposés={produitProposés}
          setProduitProposés={setProduitProposés}
        />
      );
    case 7:
      return (
        <STEP7
          isPrixShow={isPrixShow}
          setIsPrixShow={setIsPrixShow}
          DuréeValidité={DuréeValidité}
          setDuréeValidité={setDuréeValidité}
        />
      );
    case 8:
      return (
        <STEP8
          modesPaiementPossibles={modesPaiementPossibles}
          setModesPaiementPossibles={setModesPaiementPossibles}
          isPaiementVirementPossible={isPaiementVirementPossible}
          setIsPaiementVirementPossible={setIsPaiementVirementPossible}
          momentPaiement={momentPaiement}
          setMomentPaiement={setMomentPaiement}
          // -----
          isEchéancierSansFrais={isEchéancierSansFrais}
          setIsEchéancierSansFrais={setIsEchéancierSansFrais}
          EchéancierSansFrais={EchéancierSansFrais}
          setEchéancierSansFrais={setEchéancierSansFrais}
          isExigiblePassationCommande={isExigiblePassationCommande}
          setIsExigiblePassationCommande={setIsExigiblePassationCommande}
          pourcentagePrix={pourcentagePrix}
          setPourcentagePrix={setPourcentagePrix}
          joursAprèsCommande={joursAprèsCommande}
          setJoursAprèsCommande={setJoursAprèsCommande}
          Echéancier={Echéancier}
          setEchéancier={setEchéancier}
        />
      );
    case 9:
      return (
        <STEP9
          isClientCompte={isClientCompte}
          setIsClientCompte={setIsClientCompte}
          clientCompteEmail={clientCompteEmail}
          setClientCompteEmail={setClientCompteEmail}
        />
      );
    case 10:
      return (
        <STEP10
          processusCommandes={processusCommandes}
          setProcessusCommandes={setProcessusCommandes}
        />
      );
    case 11:
      return (
        <STEP11
          isLivraisonsAutreZones={isLivraisonsAutreZones}
          setIsLivraisonsAutreZones={setIsLivraisonsAutreZones}
          livraisonsAutreZonesList={livraisonsAutreZonesList}
          setLivraisonsAutreZonesList={setLivraisonsAutreZonesList}
          délaisLivraisons={délaisLivraisons}
          setDélaisLivraisons={setDélaisLivraisons}
          délaisAnnulerCommande={délaisAnnulerCommande}
          setDélaisAnnulerCommande={setDélaisAnnulerCommande}
          isTransporteurIndépendant={isTransporteurIndépendant}
          setIsTransporteurIndépendant={setIsTransporteurIndépendant}
          isLivraisonsAvoirLieuEnMagasin={isLivraisonsAvoirLieuEnMagasin}
          setIsLivraisonsAvoirLieuEnMagasin={
            setIsLivraisonsAvoirLieuEnMagasin
          }
          adressesMagasins={adressesMagasins}
          setAdressesMagasins={setAdressesMagasins}
          délaiMaxRéclamation={délaiMaxRéclamation}
          setDélaiMaxRéclamation={setDélaiMaxRéclamation}
          modeRéclamation={modeRéclamation}
          setModeRéclamation={setModeRéclamation}
        />
      );
    case 12:
      return (
        <STEP12
          momentTransfertPropriété={momentTransfertPropriété}
          setMomentTransfertPropriété={setMomentTransfertPropriété}
        />
      );
    case 13:
      return (
        <STEP13
          isDroitRétractation={isDroitRétractation}
          setIsDroitRétractation={setIsDroitRétractation}
          isAnnullerCommandAvantLivraison={isAnnullerCommandAvantLivraison}
          setIsAnnullerCommandAvantLivraison={
            setIsAnnullerCommandAvantLivraison
          }
          typeAnnullerCommandAvantLivraison={
            typeAnnullerCommandAvantLivraison
          }
          setTypeAnnullerCommandAvantLivraison={
            setTypeAnnullerCommandAvantLivraison
          }
          délaisPourAnnulerCommande={délaisPourAnnulerCommande}
          setDélaisPourAnnulerCommande={setDélaisPourAnnulerCommande}
        />
      );
    case 14:
      return (
        <STEP14
          délaiMaximal_Remboursement_Remplacement_Réparation={
            délaiMaximal_Remboursement_Remplacement_Réparation
          }
          setDélaiMaximal_Remboursement_Remplacement_Réparation={
            setDélaiMaximal_Remboursement_Remplacement_Réparation
          }
          isGarantieContractuelle={isGarantieContractuelle}
          setIsGarantieContractuelle={setIsGarantieContractuelle}
          conditionsGarantieContractuelle={conditionsGarantieContractuelle}
          setConditionsGarantieContractuelle={
            setConditionsGarantieContractuelle
          }
          situationsGarantieApplique={situationsGarantieApplique}
          setSituationsGarantieApplique={setSituationsGarantieApplique}
          situationsGarantiePasApplique={situationsGarantiePasApplique}
          setSituationsGarantiePasApplique={setSituationsGarantiePasApplique}
          conditionsApplicationGarantieContractuelle={
            conditionsApplicationGarantieContractuelle
          }
          setConditionsApplicationGarantieContractuelle={
            setConditionsApplicationGarantieContractuelle
          }
        />
      );
    case 15:
      return (
        <STEP15
          finalitéTraitementDonnéesPersonnellesCollectées={
            finalitéTraitementDonnéesPersonnellesCollectées
          }
          setFinalitéTraitementDonnéesPersonnellesCollectées={
            setFinalitéTraitementDonnéesPersonnellesCollectées
          }
          isAutreFinalitéTraitementDonnéesPersonnelles={
            isAutreFinalitéTraitementDonnéesPersonnelles
          }
          setIsAutreFinalitéTraitementDonnéesPersonnelles={
            setIsAutreFinalitéTraitementDonnéesPersonnelles
          }
          autreFinalitéTraitementDonnéesPersonnelles={
            autreFinalitéTraitementDonnéesPersonnelles
          }
          setAutreFinalitéTraitementDonnéesPersonnelles={
            setAutreFinalitéTraitementDonnéesPersonnelles
          }
          dPCC_OuvertureCompte_VenteProduits={
            dPCC_OuvertureCompte_VenteProduits
          }
          setDonnéesPersonnellesClientsCollectées_OuvertureCompte_VenteProduits={
            setDonnéesPersonnellesClientsCollectées_OuvertureCompte_VenteProduits
          }
          autreDonnéesPersonnellesClientsCollectéesList={
            autreDonnéesPersonnellesClientsCollectéesList
          }
          setAutreDonnéesPersonnellesClientsCollectéesList={
            setAutreDonnéesPersonnellesClientsCollectéesList
          }
          donnéesPersonnellesCollectées_Entreprise_Partenaires={
            donnéesPersonnellesCollectées_Entreprise_Partenaires
          }
          setDonnéesPersonnellesCollectées_Entreprise_Partenaires={
            setDonnéesPersonnellesCollectées_Entreprise_Partenaires
          }
          DPCEP_isPrestataireTransport={DPCEP_isPrestataireTransport}
          setDPCEP_IsPrestataireTransport={setDPCEP_IsPrestataireTransport}
          DPCEP_isPrestataireEtablissementPaiement={
            DPCEP_isPrestataireEtablissementPaiement
          }
          setDPCEP_IsPrestataireEtablissementPaiement={
            setDPCEP_IsPrestataireEtablissementPaiement
          }
          DPCEP_isAutres={DPCEP_isAutres}
          setDPCEP_IsAutre={setDPCEP_IsAutre}
          DPCEP_autres={DPCEP_autres}
          setDPCEP_Autre={setDPCEP_Autre}
          responsableTraitementDonnéesPersonnelles={
            responsableTraitementDonnéesPersonnelles
          }
          setResponsableTraitementDonnéesPersonnelles={
            setResponsableTraitementDonnéesPersonnelles
          }
          responsableTraitementDonnéesPersonnellesIdentité={
            responsableTraitementDonnéesPersonnellesIdentité
          }
          setResponsableTraitementDonnéesPersonnellesIdentité={
            setResponsableTraitementDonnéesPersonnellesIdentité
          }
          isDPOexiste={isDPOexiste}
          setIsDPOexiste={setIsDPOexiste}
          DPOIdentité={DPOIdentité}
          setDPOIdentité={setDPOIdentité}
          moyensDontDisposeClientSupprimerDonnéesPersonnelles={
            moyensDontDisposeClientSupprimerDonnéesPersonnelles
          }
          setMoyensDontDisposeClientSupprimerDonnéesPersonnelles={
            setMoyensDontDisposeClientSupprimerDonnéesPersonnelles
          }
        />
      );
    case 16:
      return (
        <STEP16
          nomServiceMédiation={nomServiceMédiation}
          setNomServiceMédiation={setNomServiceMédiation}
          adressePostaleSrviceMédiation={adressePostaleSrviceMédiation}
          setAdressePostaleSrviceMédiation={setAdressePostaleSrviceMédiation}
          siteInternetServiceMédiation={siteInternetServiceMédiation}
          setSiteInternetServiceMédiation={setSiteInternetServiceMédiation}
          adresseEmailServiceMédiation={adresseEmailServiceMédiation}
          setAdresseEmailServiceMédiation={setAdresseEmailServiceMédiation}
        />
      );
    case 17:
      return (
        <STEP17
          dateMiseVigueurCGV={dateMiseVigueurCGV}
          setDateMiseVigueurCGV={setDateMiseVigueurCGV}
        />
      );
    case 18:
      return (
        <STEP18
          adresseEmail={adresseEmail}
          setAdresseEmail={setAdresseEmail}
        />
      );
    default:
      return <p>Error: Step {currentStep} does not exist.</p>;
    }
  };

  return (
    <div className="flex flex-col justify-start items-center px-10 bg-white">
      <h3 className="text-md font-black">Conditions Générales de Vente</h3>
      <StepProgress numberSteps={nombreStep} currentStep={currentStep} />
      <BlobProvider
        document={
          <CGVPdfContent
            // Page 1
            chooseDomaineSite={chooseDomaineSite}
            vendeur={vendeur}
            numéroTéléphoneVendeur={numéroTéléphoneVendeur}
            adresseEmailVendeur={adresseEmailVendeur}
            isNuméroTVAVendeur={isNuméroTVAVendeur}
            numéroTVAVendeur={numéroTVAVendeur}
            formeSocialeVendeur={formeSocialeVendeur}
            nomSociétéVendeur={nomSociétéVendeur}
            montantCapitalVendeur={montantCapitalVendeur}
            villeImmatriculation_RCSVendeur={villeImmatriculation_RCSVendeur}
            numéroSIRENVendeur={numéroSIRENVendeur}
            adresseSiègeSocialVendeur={adresseSiègeSocialVendeur}
            nomVendeur={nomVendeur}
            adresseDomiciliationVendeur={adresseDomiciliationVendeur}
            numéroInscriptionRCS_RépertoireMétiers={
              numéroInscriptionRCS_RépertoireMétiers
            }
            territoires={territoires}
            territoiresList={territoiresList}
            produitProposés={produitProposés}
            dateMiseVigueurCGV={dateMiseVigueurCGV}
            // Page 2
            isPrixShow={isPrixShow}
            DuréeValidité={DuréeValidité}
            processusCommandes={processusCommandes}
            // Page 3
            isClientCompte={isClientCompte}
            clientCompteEmail={clientCompteEmail}
            modesPaiementPossibles={modesPaiementPossibles}
            isPaiementVirementPossible={isPaiementVirementPossible}
            momentPaiement={momentPaiement}
            isEchéancierSansFrais={isEchéancierSansFrais}
            EchéancierSansFrais={EchéancierSansFrais}
            isExigiblePassationCommande={isExigiblePassationCommande}
            pourcentagePrix={pourcentagePrix}
            joursAprèsCommande={joursAprèsCommande}
            Echéancier={Echéancier}
            // Page 4
            isLivraisonsAutreZones={isLivraisonsAutreZones}
            livraisonsAutreZonesList={livraisonsAutreZonesList}
            délaisLivraisons={délaisLivraisons}
            délaisAnnulerCommande={délaisAnnulerCommande}
            isTransporteurIndépendant={isTransporteurIndépendant}
            isLivraisonsAvoirLieuEnMagasin={isLivraisonsAvoirLieuEnMagasin}
            adressesMagasins={adressesMagasins}
            délaiMaxRéclamation={délaiMaxRéclamation}
            modeRéclamation={modeRéclamation}
            momentTransfertPropriété={momentTransfertPropriété}
            // Page 5
            délaiMaximal_Remboursement_Remplacement_Réparation={
              délaiMaximal_Remboursement_Remplacement_Réparation
            }
            isGarantieContractuelle={isGarantieContractuelle}
            conditionsGarantieContractuelle={conditionsGarantieContractuelle}
            situationsGarantieApplique={situationsGarantieApplique}
            situationsGarantiePasApplique={situationsGarantiePasApplique}
            conditionsApplicationGarantieContractuelle={
              conditionsApplicationGarantieContractuelle
            }
            // Page 6
            finalitéTraitementDonnéesPersonnellesCollectées={
              finalitéTraitementDonnéesPersonnellesCollectées
            }
            isAutreFinalitéTraitementDonnéesPersonnelles={
              isAutreFinalitéTraitementDonnéesPersonnelles
            }
            autreFinalitéTraitementDonnéesPersonnelles={
              autreFinalitéTraitementDonnéesPersonnelles
            }
            dPCC_OuvertureCompte_VenteProduits={
              dPCC_OuvertureCompte_VenteProduits
            }
            autreDonnéesPersonnellesClientsCollectéesList={
              autreDonnéesPersonnellesClientsCollectéesList
            }
            donnéesPersonnellesCollectées_Entreprise_Partenaires={
              donnéesPersonnellesCollectées_Entreprise_Partenaires
            }
            DPCEP_isPrestataireTransport={DPCEP_isPrestataireTransport}
            DPCEP_isPrestataireEtablissementPaiement={
              DPCEP_isPrestataireEtablissementPaiement
            }
            DPCEP_autres={DPCEP_autres}
            DPCEP_isAutres={DPCEP_isAutres}
            responsableTraitementDonnéesPersonnelles={
              responsableTraitementDonnéesPersonnelles
            }
            responsableTraitementDonnéesPersonnellesIdentité={
              responsableTraitementDonnéesPersonnellesIdentité
            }
            isDPOexiste={isDPOexiste}
            DPOIdentité={DPOIdentité}
            moyensDontDisposeClientSupprimerDonnéesPersonnelles={
              moyensDontDisposeClientSupprimerDonnéesPersonnelles
            }
            // Page 7
            nomServiceMédiation={nomServiceMédiation}
            adressePostaleSrviceMédiation={adressePostaleSrviceMédiation}
            siteInternetServiceMédiation={siteInternetServiceMédiation}
            adresseEmailServiceMédiation={adresseEmailServiceMédiation}
          />
        }
      >
        {({ blob }) => {
          return (
            <div className="w-fit md:w-[700px]">
              {getQuestion(currentStep)}
              <div className="flex justify-between gap-5 my-5">
                <Button
                  color="info"
                  variant="outlined"
                  disabled={currentStep > 1 ? false : true}
                  onClick={() => {
                    if (currentStep > 1) {
                      setCurrentStep(currentStep - 1);
                    }
                  }}
                >
                  {'< Retour'}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    currentStep !== nombreStep
                      ? setCurrentStep(currentStep + 1)
                      : handleSubmitCGV(blob);
                  }}
                >
                  {currentStep !== nombreStep ? 'Continuer' : 'ENREGISTRER'}
                </Button>
              </div>
            </div>
          );
        }}
      </BlobProvider>
    </div>
  );
}

function STEP1(props) {
  const { isCGV_Online, setIsCGV_Online } = props;

  return (
    <div className="mb-10">
      <h4 className="font-sans text-2xl font-xlarge">
        01. Nature des conditions générales de vente
      </h4>
      <h3 className="text-lg font-large mt-2">
        Les conditions générales de vente s&apos;appliquent-elles à un site
        internet ?
      </h3>
      <div className="ml-2 my-2">
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={isCGV_Online}
          setValue={setIsCGV_Online}
          labelClass=""
          items={[
            { value: true, label: 'Oui' },
            {
              value: false,
              label:
                'Non, je souhaite rédiger des CGV pour des ventes hors ligne',
            },
          ]}
        />
        {isCGV_Online === false && (
          <h6 className="text-md font-black">
            Attention : ce modèle ne s&apos;applique pas aux CGV hors ligne.
          </h6>
        )}
      </div>
    </div>
  );
}

function STEP2(props) {
  const { natureActivite, setNatureActivité } = props;

  return (
    <div className="mb-10">
      <h4 className="font-sans text-2xl font-xlarge">
        02. Nature de l&apos;activité
      </h4>
      <h3 className="text-lg font-large mt-2">
        Le site internet commercialise t-il des produits ou des prestations de
        services ?
      </h3>
      <div className="ml-2 my-2">
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={natureActivite}
          setValue={setNatureActivité}
          labelClass=""
          items={[
            {
              value: 'produits',
              label: 'Des produits (cela inclut les biens digitaux)',
            },
            { value: 'prestations', label: 'Des prestations de services' },
            {
              value: 'produits&prestations',
              label: 'Des produits et prestations de services',
            },
          ]}
        />
        {natureActivite === 'produits&prestations' && (
          <h6 className="text-md font-black">
            Attention : ce modèle ne s&apos;applique pas aux CGV pour produits
            et prestations de services founis ensemble.
          </h6>
        )}
      </div>
    </div>
  );
}

function STEP3(props) {
  const { chooseDomaineSite, setChoosenDomaineSite } = props;

  return (
    <div className="mb-10">
      <h4 className="font-sans text-2xl font-xlarge">03. Le site internet</h4>
      <h3 className="text-lg font-large mt-2">
        Quelle est l&apos;adresse web du site internet ?
      </h3>
      <div className="ml-2 my-2">
        <CustomTextInput
          image={usericon}
          type="text"
          value={chooseDomaineSite}
          setValue={setChoosenDomaineSite}
          placeholder="Adresse du site internet (https://legalstation.luceomtechnology.com)"
        />
      </div>
    </div>
  );
}

function STEP4(props) {
  const {
    // vendeur
    vendeur,
    setVendeur,
    numéroTéléphoneVendeur,
    setNuméroTéléphoneVendeur,
    adresseEmailVendeur,
    setAdresseEmailVendeur,
    isNuméroTVAVendeur,
    setIsNuméroTVAVendeur,
    numéroTVAVendeur,
    setNuméroTVAVendeur,
    // morale
    formeSocialeVendeur,
    setFormeSocialeVendeur,
    nomSociétéVendeur,
    setNomSociétéVendeur,
    montantCapitalVendeur,
    setMontantCapitalVendeur,
    villeImmatriculation_RCSVendeur,
    setVilleImmatriculation_RCSVendeur,
    numéroSIRENVendeur,
    setNuméroSIRENVendeur,
    adresseSiègeSocialVendeur,
    setAdresseSiègeSocialVendeur,
    // physique
    nomVendeur,
    setNomVendeur,
    adresseDomiciliationVendeur,
    setAdresseDomiciliationVendeur,
    numéroInscriptionRCS_RépertoireMétiers,
    setNuméroInscriptionRCS_RépertoireMétiers,
  } = props;

  return (
    <div className="mb-10">
      <h4 className="font-sans text-2xl font-xlarge">01. Le vendeur</h4>
      <h3 className="text-lg font-large mt-2">
        Quelles sont les coordonnées du vendeur ?
      </h3>
      <div className="ml-2 my-2">
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={vendeur}
          setValue={setVendeur}
          labelClass=""
          items={[
            { value: 'morale', label: 'Il s’agit d’une personne Morale' },
            { value: 'physique', label: 'Il s’agit d’une personne Physique' },
          ]}
        />
      </div>
      {vendeur === 'morale' && (
        <div className="mt-4">
          <div className="ml-2 my-2">
            <CustomTextInput
              image={usericon}
              type="text"
              value={formeSocialeVendeur}
              setValue={setFormeSocialeVendeur}
              placeholder="Forme sociale (SAS, SARL, SASU, etc...)"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={nomSociétéVendeur}
              setValue={setNomSociétéVendeur}
              placeholder="Nom de la société"
            />
            <CustomTextInput
              image={usericon}
              type="number"
              value={montantCapitalVendeur}
              setValue={setMontantCapitalVendeur}
              placeholder="Montant du capital social en Dirham marocain"
              min={0}
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={villeImmatriculation_RCSVendeur}
              setValue={setVilleImmatriculation_RCSVendeur}
              placeholder="Ville d'immatriculation au RCS"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={numéroSIRENVendeur}
              setValue={setNuméroSIRENVendeur}
              placeholder="Numéro SIREN"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={adresseSiègeSocialVendeur}
              setValue={setAdresseSiègeSocialVendeur}
              placeholder="Adresse du siège social"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={numéroTéléphoneVendeur}
              setValue={setNuméroTéléphoneVendeur}
              placeholder="Numéro de téléphone"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={adresseEmailVendeur}
              setValue={setAdresseEmailVendeur}
              placeholder="Adresse e-mail"
            />
            <CustomCheckBox
              value={isNuméroTVAVendeur === true}
              setValue={(value) => {
                setIsNuméroTVAVendeur(value ? true : false);
              }}
              label="Ajouter le numéro de TVA intracommunautaire s'il est connu"
            />
            {isNuméroTVAVendeur && (
              <CustomTextInput
                image={usericon}
                type="text"
                value={numéroTVAVendeur}
                setValue={setNuméroTVAVendeur}
                placeholder="Précisez"
              />
            )}
          </div>
        </div>
      )}
      {vendeur === 'physique' && (
        <div className="mt-4">
          <div className="ml-2 my-2">
            <CustomTextInput
              image={usericon}
              type="text"
              value={nomVendeur}
              setValue={setNomVendeur}
              placeholder="Nom et prénom"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={adresseDomiciliationVendeur}
              setValue={setAdresseDomiciliationVendeur}
              placeholder="Adresse de domiciliation (de la personne en charge de l'édition et de la publication)"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={numéroTéléphoneVendeur}
              setValue={setNuméroTéléphoneVendeur}
              placeholder="Numéro de téléphone"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={adresseEmailVendeur}
              setValue={setAdresseEmailVendeur}
              placeholder="Adresse e-mail"
            />
            <CustomTextInput
              image={usericon}
              type="text"
              value={numéroInscriptionRCS_RépertoireMétiers}
              setValue={setNuméroInscriptionRCS_RépertoireMétiers}
              placeholder="Numéro d'inscription au RCS ou au Répertoire des métiers"
            />
            <CustomCheckBox
              value={isNuméroTVAVendeur === true}
              setValue={(value) => {
                setIsNuméroTVAVendeur(value ? true : false);
              }}
              label="Ajouter le numéro de TVA intracommunautaire s'il est connu"
            />
            {isNuméroTVAVendeur && (
              <CustomTextInput
                image={usericon}
                type="text"
                value={numéroTVAVendeur}
                setValue={setNuméroTVAVendeur}
                placeholder="Précisez"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

function STEP5(props) {
  const { territoires, setTerritoires, territoiresList, setTerritoiresList } =
    props;

  return (
    <div className="mb-10">
      <h4 className="font-sans text-2xl font-xlarge">
        05. Territoires concernés par la vente
      </h4>
      <h3 className="text-lg font-large mt-2">
        La vente concerne-t-elle des territoires en particulier (autres que le
        Maroc) ?
      </h3>
      <div className="ml-2 my-2">
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={territoires}
          setValue={setTerritoires}
          labelClass=""
          items={[
            { value: 'oui', label: 'Oui' },
            { value: 'non', label: 'Non (que le Maroc)' },
          ]}
        />
        {territoires === 'oui' && (
          <CustomTextInput
            image={usericon}
            type="text"
            value={territoiresList}
            setValue={setTerritoiresList}
            placeholder="Liste des territoires concernés (éléments séparés par des ',')"
          />
        )}
      </div>
    </div>
  );
}

function STEP6(props) {
  const { produitProposés, setProduitProposés } = props;

  return (
    <div className="mb-10">
      <h4 className="font-sans text-2xl font-xlarge">
        06. Produits proposés à la vente
      </h4>
      <h3 className="text-lg font-large mt-2">
        Quels sont les produits proposés à la vente?
      </h3>
      <div className="ml-2 my-2">
        <CustomTextInput
          image={usericon}
          type="text"
          value={produitProposés}
          setValue={setProduitProposés}
          placeholder="Description des produits proposés à la vente (éléments séparés par des ',')"
        />
      </div>
    </div>
  );
}

function STEP7(props) {
  const { isPrixShow, setIsPrixShow, DuréeValidité, setDuréeValidité } = props;

  return (
    <div className="mb-10">
      <h4 className="font-sans text-2xl font-xlarge">07. Prix</h4>
      <h3 className="text-lg font-large mt-2">
        La commande de produits nécessite-t-elle l&apos;établissement d&apos;un
        devis ?
      </h3>
      <div className="ml-2 my-2">
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={isPrixShow}
          setValue={setIsPrixShow}
          labelClass=""
          items={[
            { value: true, label: 'Oui' },
            { value: false, label: 'Non' },
          ]}
        />
        {isPrixShow == 'true' && (
          <CustomTextInput
            image={usericon}
            type="text"
            value={DuréeValidité}
            setValue={setDuréeValidité}
            placeholder="Durée de validité des devis après établissement"
          />
        )}
      </div>
    </div>
  );
}

function STEP8(props) {
  const {
    modesPaiementPossibles,
    setModesPaiementPossibles,
    isPaiementVirementPossible,
    setIsPaiementVirementPossible,
    momentPaiement,
    setMomentPaiement,
    // -----
    isEchéancierSansFrais,
    setIsEchéancierSansFrais,
    EchéancierSansFrais,
    setEchéancierSansFrais,
    isExigiblePassationCommande,
    setIsExigiblePassationCommande,
    pourcentagePrix,
    setPourcentagePrix,
    joursAprèsCommande,
    setJoursAprèsCommande,
    Echéancier,
    setEchéancier,
  } = props;

  const getQuestion = (momentPaiement) => {
    switch (momentPaiement) {
    case 'paiementImmediat':
      return (
        <div>
          <CustomCheckBox
            value={isEchéancierSansFrais === true}
            setValue={(value) => {
              setIsEchéancierSansFrais(value ? true : false);
            }}
            label="Le client peut opter pour un échéancier sans frais"
          />
          {isEchéancierSansFrais && (
            <CustomTextInput
              image={usericon}
              type="text"
              value={EchéancierSansFrais}
              setValue={setEchéancierSansFrais}
              placeholder="Précisez l'échéancier (périodicité et quote-part de paiement à chaque période)"
            />
          )}
        </div>
      );
    case 'paiementExpedition':
      return (
        <div>
          <CustomCheckBox
            value={isEchéancierSansFrais === true}
            setValue={(value) => {
              setIsEchéancierSansFrais(value ? true : false);
            }}
            label="Le client peut opter pour un échéancier sans frais"
          />
          {isEchéancierSansFrais && (
            <CustomTextInput
              image={usericon}
              type="text"
              value={EchéancierSansFrais}
              setValue={setEchéancierSansFrais}
              placeholder="Précisez l'échéancier (périodicité et quote-part de paiement à chaque période)"
            />
          )}
        </div>
      );
    case 'paiementLivraison':
      return (
        <div>
          <CustomCheckBox
            value={isEchéancierSansFrais === true}
            setValue={(value) => {
              setIsEchéancierSansFrais(value ? true : false);
            }}
            label="Le client peut opter pour un échéancier sans frais"
          />
          {isEchéancierSansFrais && (
            <CustomTextInput
              image={usericon}
              type="text"
              value={EchéancierSansFrais}
              setValue={setEchéancierSansFrais}
              placeholder="Précisez l'échéancier (périodicité et quote-part de paiement à chaque période)"
            />
          )}
        </div>
      );
    case 'paiementALaLivraison':
      return (
        <div className="ml-2">
          <div className="my-2">
            <CustomCheckBox
              value={isEchéancierSansFrais === true}
              setValue={(value) => {
                setIsEchéancierSansFrais(value ? true : false);
              }}
              label="Le client peut opter pour un échéancier sans frais"
            />
            {isEchéancierSansFrais && (
              <CustomTextInput
                image={usericon}
                type="text"
                value={EchéancierSansFrais}
                setValue={setEchéancierSansFrais}
                placeholder="Précisez l'échéancier (périodicité et quote-part de paiement à chaque période)"
              />
            )}
          </div>
          <div className="my-2">
            <h3 className="text-lg">
              Un acompte est-il exigible à la passation de la commande ?
            </h3>
            <CustomRadioBox
              row={false}
              isBlue={false}
              value={isExigiblePassationCommande}
              setValue={setIsExigiblePassationCommande}
              labelClass=""
              items={[
                {
                  value: true,
                  label: 'Oui (le solde est payable à la livraison)',
                },
                { value: false, label: 'Non' },
              ]}
            />
            {isExigiblePassationCommande == 'true' && (
              <CustomTextInput
                image={usericon}
                type="text"
                value={pourcentagePrix}
                setValue={setPourcentagePrix}
                placeholder="L'acompte correspond à quel pourcentage du prix de la commande ?"
              />
            )}
          </div>
        </div>
      );
    case 'paiementTermine':
      return (
        <div className="ml-2">
          <CustomTextInput
            image={usericon}
            type="text"
            value={joursAprèsCommande}
            setValue={setJoursAprèsCommande}
            placeholder="Combien de jours après la commande ? (Précisez en jours)"
          />
          <div className="my-2">
            <CustomCheckBox
              value={isEchéancierSansFrais === true}
              setValue={(value) => {
                setIsEchéancierSansFrais(value ? true : false);
              }}
              label="Le client peut opter pour un échéancier sans frais"
            />
            {isEchéancierSansFrais && (
              <CustomTextInput
                image={usericon}
                type="text"
                value={EchéancierSansFrais}
                setValue={setEchéancierSansFrais}
                placeholder="Précisez l'échéancier (périodicité et quote-part de paiement à chaque période)"
              />
            )}
          </div>
          <div className="my-2">
            <h3 className="text-lg">
              Un acompte est-il exigible à la passation de la commande ?
            </h3>
            <CustomRadioBox
              row={false}
              isBlue={false}
              value={isExigiblePassationCommande}
              setValue={setIsExigiblePassationCommande}
              labelClass=""
              items={[
                {
                  value: true,
                  label:
                    'Oui (le solde est payable au terme indiqué ci-dessus)',
                },
                { value: false, label: 'Non' },
              ]}
            />
            {isExigiblePassationCommande == 'true' && (
              <CustomTextInput
                image={usericon}
                type="text"
                value={pourcentagePrix}
                setValue={setPourcentagePrix}
                placeholder="L'acompte correspond à quel pourcentage du prix de la commande ?"
              />
            )}
          </div>
        </div>
      );
    case 'paiementEcheancier':
      return (
        <div className="ml-2">
          <h3 className="text-lg">
            Précisez l&apos;échéancier (périodicité et quote-part de paiement
            à chaque période)
          </h3>
          <CustomTextInput
            image={usericon}
            type="text"
            value={Echéancier}
            setValue={setEchéancier}
            placeholder="Exemple : en 3 versements égaux, tous les 5 du mois."
          />
        </div>
      );
    default:
      return <></>;
    }
  };

  return (
    <div className="mb-10">
      <h4 className="font-sans text-2xl font-xlarge">
        08. Conditions de paiement
      </h4>
      <h3 className="text-lg font-large mt-2">
        Quels sont les modes de paiement possibles ?
      </h3>
      <div className="ml-2 my-2">
        <CustomRadioBox
          row={false}
          isBlue={false}
          value={modesPaiementPossibles}
          setValue={setModesPaiementPossibles}
          labelClass=""
          items={[
            { value: 'Cartes_bancaires', label: 'Cartes bancaires' },
            {
              value: 'Cartes_bancaires&chèques',
              label: 'Cartes bancaires et chèques',
            },
          ]}
        />
        <CustomCheckBox
          value={isPaiementVirementPossible === true}
          setValue={(value) => {
            setIsPaiementVirementPossible(value ? true : false);
          }}
          label="Le paiement par virement est possible"
        />
      </div>
      <h3 className="text-lg font-large mt-2">
        A quel moment le paiement d&apos;une commande est-il effectué ?
      </h3>
      <div className="ml-2 my-2">
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={momentPaiement}
          setValue={setMomentPaiement}
          labelClass=""
          items={[
            {
              value: 'paiementImmediat',
              label:
                'Le paiement intervient dès la passation de la commande et est débité immédiatement',
            },
            {
              value: 'paiementExpedition',
              label:
                'Le paiement intervient dès la passation de la commande mais n’est débité qu’à l’expédition de la commande',
            },
            {
              value: 'paiementLivraison',
              label:
                'Le paiement intervient dès la passation de la commande mais n’est débité qu’à la livraison de la commande',
            },
            {
              value: 'paiementALaLivraison',
              label:
                'Le paiement n’intervient qu’à la livraison de la commande',
            },
            {
              value: 'paiementTermine',
              label: 'Le paiement intervient en une fois à un terme précis',
            },
            {
              value: 'paiementEcheancier',
              label: 'Le paiement intervient selon un échéancier sans frais',
            },
          ]}
        />
        {getQuestion(momentPaiement)}
      </div>
    </div>
  );
}

function STEP9(props) {
  const {
    isClientCompte,
    setIsClientCompte,
    clientCompteEmail,
    setClientCompteEmail,
  } = props;

  return (
    <div className="mb-10">
      <h4 className="font-sans text-2xl font-xlarge">
        09. Espace et compte client
      </h4>
      <h3 className="text-lg font-large mt-2">
        Les commandes nécessitent-elles la création d&apos;un compte client ?
      </h3>
      <div className="ml-2 my-2">
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={isClientCompte}
          setValue={setIsClientCompte}
          labelClass=""
          items={[
            { value: true, label: 'Oui' },
            { value: false, label: 'Non' },
          ]}
        />
        {isClientCompte == 'true' && (
          <CustomTextInput
            image={usericon}
            type="text"
            value={clientCompteEmail}
            setValue={setClientCompteEmail}
            placeholder="Précisez l'adresse e-mail à laquelle le client peut adresser une demande de désincription"
          />
        )}
      </div>
    </div>
  );
}
function STEP10(props) {
  const { processusCommandes, setProcessusCommandes } = props;

  return (
    <div className="mb-10">
      <h4 className="font-sans text-2xl font-xlarge">10. Commandes</h4>
      <h3 className="text-lg font-large mt-2">
        Quelle est le processus de commande ?
      </h3>
      <h5 className="text-md">
        Description du processus de passation et de validation de la commande,
        de confirmation de ladite commande et de paiement
      </h5>
      <div className="ml-2 my-2">
        <TextField
          id="outlined-multiline-static"
          placeholder="Exemple : Le Client choisi un Produit qu’il met dans son panier, Produit qu’il pourra supprimer ou modifier avant de valider sa commande et d’accepter les présentes conditions générales de vente. Il rentrera ensuite ses coordonnées ou se connectera à son espace et choisira le mode de livraison. Après validation des informations, la commande sera considérée comme définitive et exigera paiement de la part du Client selon les modalités prévues."
          multiline
          fullWidth
          rows={6}
          value={processusCommandes}
          onChange={(e) => setProcessusCommandes(e.target.value)}
        />
      </div>
    </div>
  );
}
function STEP11(props) {
  const {
    isLivraisonsAutreZones,
    setIsLivraisonsAutreZones,
    livraisonsAutreZonesList,
    setLivraisonsAutreZonesList,
    délaisLivraisons,
    setDélaisLivraisons,
    délaisAnnulerCommande,
    setDélaisAnnulerCommande,
    isTransporteurIndépendant,
    setIsTransporteurIndépendant,
    isLivraisonsAvoirLieuEnMagasin,
    setIsLivraisonsAvoirLieuEnMagasin,
    adressesMagasins,
    setAdressesMagasins,
    délaiMaxRéclamation,
    setDélaiMaxRéclamation,
    modeRéclamation,
    setModeRéclamation,
  } = props;

  const tooltipText =
    'Le transporteur peut être choisi par le vendeur ou par le client.\n S’il est choisi par le client, le transfert des risques(déterioration des biens par exemple) au client se fera dès remise du bien au transporteur.Dans le cas contraire, ce transfert ne se fera qu’à la livraison effective.';

  return (
    <div className="mb-10">
      <h4 className="font-sans text-2xl font-xlarge">11. Livraisons</h4>
      <h3 className="text-lg font-large mt-2">
        Les livraisons ont-elles lieu dans d&apos;autres zones que le Maroc
        Métropolitaine ?
      </h3>
      <div className="ml-2 my-2">
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={isLivraisonsAutreZones}
          setValue={setIsLivraisonsAutreZones}
          labelClass=""
          items={[
            { value: true, label: 'Oui' },
            { value: false, label: 'Non' },
          ]}
        />
        {isLivraisonsAutreZones == 'true' && (
          <CustomTextInput
            image={usericon}
            type="text"
            value={livraisonsAutreZonesList}
            setValue={setLivraisonsAutreZonesList}
            placeholder="Zones de livraison hors Maroc Métropolitaine"
          />
        )}
      </div>
      <h3 className="text-lg font-large mt-2">
        Quels sont les délais de livraison ?
      </h3>
      <div className="ml-2 my-2">
        <div className="my-2">
          <Alert
            icon={<ReportGmailerrorredIcon fontSize="inherit" />}
            severity="warning"
          >
            Précisez également s&apos;il s&apos;agit d&apos;heures, de jours, de
            mois...
          </Alert>
        </div>
        <CustomTextInput
          image={usericon}
          type="text"
          value={délaisLivraisons}
          setValue={setDélaisLivraisons}
          placeholder="Quels sont les délais de livraison ?"
        />
      </div>
      <h3 className="text-lg font-large mt-2">
        Si le délai n&apos;est pas respecté, sous quel délai (après le
        dépassement de délai de livraison) le client pourra annuler la commande
        ?
      </h3>
      <div className="ml-2 my-2">
        <div className="my-2">
          <Alert
            icon={<ReportGmailerrorredIcon fontSize="inherit" />}
            severity="warning"
          >
            Précisez également s&apos;il s&apos;agit d&apos;heures, de jours, de
            mois... Ce délai ne doit pas être abusivement élevé
          </Alert>
        </div>
        <CustomTextInput
          image={usericon}
          type="text"
          value={délaisAnnulerCommande}
          setValue={setDélaisAnnulerCommande}
          placeholder="Si le délai n'est pas respecté, sous quel délai (après le dépassement de délai de livraison) le client pourra annuler la commande ?"
        />
      </div>
      <h3 className="text-lg font-large mt-2 underline">
        <Tooltip disableFocusListener disableTouchListener title={tooltipText}>
          Les livraisons sont-elles assurées par un transporteur indépendant ?
        </Tooltip>
      </h3>
      <div className="ml-2 my-2">
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={isTransporteurIndépendant}
          setValue={setIsTransporteurIndépendant}
          labelClass=""
          items={[
            { value: true, label: 'Oui' },
            { value: false, label: 'Non' },
          ]}
        />
        <CustomCheckBox
          value={isLivraisonsAvoirLieuEnMagasin === true}
          setValue={(value) => {
            setIsLivraisonsAvoirLieuEnMagasin(value ? true : false);
          }}
          label="Cochez cette case si les livraisons peuvent avoir lieu en magasin"
        />
        {isLivraisonsAvoirLieuEnMagasin && (
          <div className="ml-2 my-1">
            <CustomTextInput
              image={usericon}
              type="text"
              value={adressesMagasins}
              setValue={setAdressesMagasins}
              placeholder="Indiquez le lien où la liste des magasins figure ou listez directement les adresses des magasins"
            />
          </div>
        )}
      </div>
      <h3 className="text-lg font-large mt-2">
        Quelles sont les modalités de réclamation ?
      </h3>
      <div className="ml-2 my-2">
        <CustomTextInput
          image={usericon}
          type="text"
          value={délaiMaxRéclamation}
          setValue={setDélaiMaxRéclamation}
          placeholder="Délai maximal pour effectuer une réclamation (à compter de la livraison)"
        />
        <TextField
          id="outlined-multiline-static"
          placeholder="Mode de réclamation (courrier, mail, etc..)"
          multiline
          fullWidth
          rows={4}
          value={modeRéclamation}
          onChange={(e) => setModeRéclamation(e.target.value)}
        />
      </div>
    </div>
  );
}

function STEP12(props) {
  const { momentTransfertPropriété, setMomentTransfertPropriété } = props;
  const tooltipText =
    'Il est important de savoir à quel moment la propriété est transférée au client, notamment dans le cas où le paiement ne serait pas comptant mais échelonné ou différé.';
  return (
    <div className="mb-10">
      <h4 className="font-sans text-2xl font-xlarge">
        12. Transfert de la propriété
      </h4>
      <h3 className="text-lg font-large mt-2 underline">
        <Tooltip disableFocusListener disableTouchListener title={tooltipText}>
          Quel est le moment du transfert de la propriété des produits vendus ?
        </Tooltip>
      </h3>
      <div className="ml-2 my-2">
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={momentTransfertPropriété}
          setValue={setMomentTransfertPropriété}
          labelClass=""
          items={[
            {
              value: 'AuMoment',
              label: 'Au moment de l’acceptation de la commande',
            },
            {
              value: 'AprèsPaiement',
              label: 'Après paiement complet (cas le plus fréquent)',
            },
          ]}
        />
      </div>
    </div>
  );
}

function STEP13(props) {
  const {
    isDroitRétractation,
    setIsDroitRétractation,
    isAnnullerCommandAvantLivraison,
    setIsAnnullerCommandAvantLivraison,
    typeAnnullerCommandAvantLivraison,
    setTypeAnnullerCommandAvantLivraison,
    délaisPourAnnulerCommande,
    setDélaisPourAnnulerCommande,
  } = props;
  const tooltipText =
    'Il s’agit ici d’une possibilité d’annulation que vous accordez contractuellement, que le droit de rétractation s’applique ou non.';
  return (
    <div className="mb-10">
      <h4 className="font-sans text-2xl font-xlarge">
        13. Annulation de la commande et droit de retractation
      </h4>
      <h3 className="text-lg font-large mt-2">
        Le client bénéficie-t-il du droit de rétractation ?
      </h3>
      <div className="ml-2 my-2">
        <Alert
          icon={<ReportGmailerrorredIcon fontSize="inherit" />}
          severity="warning"
        >
          Le droit de rétractation est d&apos;ordre public pour les cllients
          consommateurs. Le consommateur ne bénéficie pas du droit de
          rétractation pour certains types de contrats (vente de biens ou
          services) :
        </Alert>
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={isDroitRétractation}
          setValue={setIsDroitRétractation}
          labelClass=""
          items={[
            { value: true, label: 'Oui' },
            { value: false, label: 'Non' },
          ]}
        />
        {isDroitRétractation === false && (
          <Alert
            icon={<ReportGmailerrorredIcon fontSize="inherit" />}
            severity="info"
          >
            Vérifiez bien que vous entrez dans les exceptions légales permettant
            de supprimer le droit de rétractation.
          </Alert>
        )}
      </div>
      <h3 className="text-lg font-large mt-2 underline">
        <Tooltip disableFocusListener disableTouchListener title={tooltipText}>
          Le client bénéficie-t-il de la possibilité d&apos;annuler sa commande
          avant livraison (indépendamment de l&apos;application ou non du droit
          de rétractation légal) ?
        </Tooltip>
      </h3>
      <div className="ml-2 my-2">
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={isAnnullerCommandAvantLivraison}
          setValue={setIsAnnullerCommandAvantLivraison}
          labelClass=""
          items={[
            { value: true, label: 'Oui' },
            { value: false, label: 'Non' },
          ]}
        />
        {isAnnullerCommandAvantLivraison == 'true' && (
          <div className="ml-2 my-2">
            <CustomRadioBox
              row={false}
              isBlue={true}
              value={typeAnnullerCommandAvantLivraison}
              setValue={setTypeAnnullerCommandAvantLivraison}
              labelClass=""
              items={[
                {
                  value: 'produitsPasLivrés',
                  label:
                    'L’annulation est possible tant que les produits ne sont pas livrés',
                },
                {
                  value: 'certainDélai',
                  label:
                    'L’annulation est possible dans un certain délai (et en toutes hypothèses avant la livraison)',
                },
              ]}
            />
            {typeAnnullerCommandAvantLivraison === 'certainDélai' && (
              <div className="ml-2 my-2">
                <CustomTextInput
                  image={usericon}
                  type="text"
                  value={délaisPourAnnulerCommande}
                  setValue={setDélaisPourAnnulerCommande}
                  placeholder="Quels sont les délais de livraison ?"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function STEP14(props) {
  const {
    délaiMaximal_Remboursement_Remplacement_Réparation,
    setDélaiMaximal_Remboursement_Remplacement_Réparation,
    isGarantieContractuelle,
    setIsGarantieContractuelle,
    conditionsGarantieContractuelle,
    setConditionsGarantieContractuelle,
    situationsGarantieApplique,
    setSituationsGarantieApplique,
    situationsGarantiePasApplique,
    setSituationsGarantiePasApplique,
    conditionsApplicationGarantieContractuelle,
    setConditionsApplicationGarantieContractuelle,
  } = props;
  const tooltipText1 =
    'Tarifs (ou gratuité), conditions, produits auxquels la garantie s’applique, etc.';
  const tooltipText2 =
    'Décrire comment elle est mise en oeuvre, comment le client effectue les réclamations, comment le vendeur les traite, etc.';
  return (
    <div className="mb-10">
      <h4 className="font-sans text-2xl font-xlarge">
        14. Responsabilité du Vendeur - Garanties
      </h4>
      <h3 className="text-lg font-large mt-2">
        Quel est le délai maximal de remboursement, remplacement ou réparation
        du(des) bien(s) non conforme(s) ou défectueux conformément à la garantie
        légale ?
      </h3>
      <div className="ml-2 my-2">
        <Alert
          icon={<ReportGmailerrorredIcon fontSize="inherit" />}
          severity="warning"
        >
          Délai suivant la constatation par le Vendeur du défaut de conformité
          ou du vice caché
        </Alert>
        <CustomTextInput
          image={usericon}
          type="text"
          value={délaiMaximal_Remboursement_Remplacement_Réparation}
          setValue={setDélaiMaximal_Remboursement_Remplacement_Réparation}
          placeholder="délai maximal de remboursement, remplacement ou réparation"
        />
      </div>
      <h3 className="text-lg font-large mt-2">
        Une garantie contractuelle est-elle prévue (en plus de la garantie
        légale obligatoire) ?
      </h3>
      <div className="ml-2 my-2">
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={isGarantieContractuelle}
          setValue={setIsGarantieContractuelle}
          labelClass=""
          items={[
            { value: true, label: 'Oui' },
            { value: false, label: 'Non' },
          ]}
        />
        {isGarantieContractuelle == 'true' && (
          <div className="ml-2 my-2">
            <h3 className="text-lg font-large my-2 underline">
              <Tooltip
                disableFocusListener
                disableTouchListener
                title={tooltipText1}
              >
                Conditions de la garantie contractuelle
              </Tooltip>
            </h3>
            <TextField
              id="outlined-multiline-static"
              placeholder="Conditions de la garantie contractuelle"
              multiline
              fullWidth
              rows={4}
              value={conditionsGarantieContractuelle}
              onChange={(e) =>
                setConditionsGarantieContractuelle(e.target.value)
              }
            />
            <h3 className="text-lg font-large my-2">
              Situations dans lesquelles la garantie s&apos;applique
            </h3>
            <TextField
              id="outlined-multiline-static"
              placeholder="Situations dans lesquelles la garantie s'applique"
              multiline
              fullWidth
              rows={4}
              value={situationsGarantieApplique}
              onChange={(e) => setSituationsGarantieApplique(e.target.value)}
            />
            <h3 className="text-lg font-large my-2">
              Situations où elle n&apos;est pas applicable
            </h3>
            <TextField
              id="outlined-multiline-static"
              placeholder="Situations où elle n'est pas applicable"
              multiline
              fullWidth
              rows={4}
              value={situationsGarantiePasApplique}
              onChange={(e) => setSituationsGarantiePasApplique(e.target.value)}
            />
            <h3 className="text-lg font-large my-2 underline">
              <Tooltip
                disableFocusListener
                disableTouchListener
                title={tooltipText2}
              >
                Conditions d&apos;application de la garantie contractuelle.
              </Tooltip>
            </h3>
            <TextField
              id="outlined-multiline-static"
              placeholder="Conditions d'application de la garantie contractuelle."
              multiline
              fullWidth
              rows={4}
              value={conditionsApplicationGarantieContractuelle}
              onChange={(e) =>
                setConditionsApplicationGarantieContractuelle(e.target.value)
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}

function STEP15(props) {
  const {
    finalitéTraitementDonnéesPersonnellesCollectées,
    setFinalitéTraitementDonnéesPersonnellesCollectées,
    isAutreFinalitéTraitementDonnéesPersonnelles,
    setIsAutreFinalitéTraitementDonnéesPersonnelles,
    autreFinalitéTraitementDonnéesPersonnelles,
    setAutreFinalitéTraitementDonnéesPersonnelles,
    dPCC_OuvertureCompte_VenteProduits,
    setDonnéesPersonnellesClientsCollectées_OuvertureCompte_VenteProduits,
    autreDonnéesPersonnellesClientsCollectéesList,
    setAutreDonnéesPersonnellesClientsCollectéesList,
    donnéesPersonnellesCollectées_Entreprise_Partenaires,
    setDonnéesPersonnellesCollectées_Entreprise_Partenaires,
    DPCEP_isPrestataireTransport,
    setDPCEP_IsPrestataireTransport,
    DPCEP_isPrestataireEtablissementPaiement,
    setDPCEP_IsPrestataireEtablissementPaiement,
    DPCEP_isAutres,
    setDPCEP_IsAutre,
    DPCEP_autres,
    setDPCEP_Autre,
    responsableTraitementDonnéesPersonnelles,
    setResponsableTraitementDonnéesPersonnelles,
    responsableTraitementDonnéesPersonnellesIdentité,
    setResponsableTraitementDonnéesPersonnellesIdentité,
    isDPOexiste,
    setIsDPOexiste,
    DPOIdentité,
    setDPOIdentité,
    moyensDontDisposeClientSupprimerDonnéesPersonnelles,
    setMoyensDontDisposeClientSupprimerDonnéesPersonnelles,
  } = props;

  return (
    <div className="mb-10">
      <h4 className="font-sans text-2xl font-xlarge">
        15. Traitement des données personnelles
      </h4>
      <h3 className="text-lg font-large mt-2">
        Quelle est la finalité du traitement des données personnelles collectées
        auprès des clients ?
      </h3>
      <div className="ml-2 my-2">
        <CustomRadioBox
          row={false}
          isBlue={false}
          value={finalitéTraitementDonnéesPersonnellesCollectées}
          setValue={setFinalitéTraitementDonnéesPersonnellesCollectées}
          labelClass=""
          items={[
            {
              value: 'venteProduit_Livraison',
              label:
                'Traitement uniquement pour la vente des produits et leur livraison (par l’entreprise sans passer par un tiers)',
            },
            {
              value: 'venteProduit_Transmission',
              label:
                'Traitement uniquement pour la vente des produit et transmission à un tiers pour leur livraison',
            },
          ]}
        />
        <CustomCheckBox
          value={isAutreFinalitéTraitementDonnéesPersonnelles === true}
          setValue={(value) => {
            setIsAutreFinalitéTraitementDonnéesPersonnelles(
              value ? true : false
            );
          }}
          label="Cochez cette case si vous prévoyez d'autres finalités au titre du traitement de ces données personnelles"
        />
        {isAutreFinalitéTraitementDonnéesPersonnelles && (
          <div className="ml-2 my-2">
            <Alert
              icon={<ReportGmailerrorredIcon fontSize="inherit" />}
              severity="warning"
              className="mb-2"
            >
              <strong>Attention</strong> : le traitement de données à caractère
              personnel doit être justifié par l’un des cas suivants :
              <ul>
                <li>
                  - il est nécessaire à l’exécution d’un contrat avec la
                  personne concernée ou de mesures précontractuelles comme un
                  devis.
                </li>
                <li>
                  - il est nécessaire au respect d’une obligation légale qui
                  vous incombe.
                </li>
                <li>
                  - Il est nécessaire à la sauvegarde des intérêts vitaux de la
                  personne.
                </li>
                <li>
                  - Il est nécessaire à l’exécution d’une mission d’intérêt
                  public ou relevant de l’exercice de l’autorité publique.
                </li>
              </ul>
            </Alert>
            <TextField
              id="outlined-multiline-static"
              multiline
              fullWidth
              rows={4}
              value={autreFinalitéTraitementDonnéesPersonnelles}
              onChange={(e) =>
                setAutreFinalitéTraitementDonnéesPersonnelles(e.target.value)
              }
            />
          </div>
        )}
      </div>

      <h3 className="text-lg font-large mt-2">
        Quelles données personnelles des clients sont collectées pour
        l&apos;ouverture du compte et la vente des produits ?
      </h3>
      <div className="ml-2 my-2">
        <CustomRadioBox
          row={false}
          isBlue={false}
          value={dPCC_OuvertureCompte_VenteProduits}
          setValue={
            setDonnéesPersonnellesClientsCollectées_OuvertureCompte_VenteProduits
          }
          labelClass=""
          items={[
            {
              value: 'basicDonnee',
              label:
                'Uniquement les prénoms, noms, adresse postale, numéro de téléphone et adresse e-mail (en plus des données pour le paiement bancaire)',
            },
            {
              value: 'autreDonnee',
              label: 'Il est collecté d’autres données personnelles',
            },
          ]}
        />
        {dPCC_OuvertureCompte_VenteProduits === 'autreDonnee' && (
          <div className="ml-2 my-2 border rounded p-2">
            <p>
              Listez toutes les données perosnnelles collectées (nom, prénom,
              adresse e-mail, date de naissance, etc.)
            </p>
            <Alert
              icon={<ReportGmailerrorredIcon fontSize="inherit" />}
              severity="warning"
              className="mb-2"
            >
              <strong>Attention</strong> : la collecte de données doit répondre
              au principe de minimisation, c’est-à-dire que l’on ne doit pas
              recueillir plus de données que celles nécessaires à la finalité du
              traitement.
            </Alert>
            <TextField
              id="outlined-multiline-static"
              multiline
              fullWidth
              rows={4}
              value={autreDonnéesPersonnellesClientsCollectéesList}
              onChange={(e) =>
                setAutreDonnéesPersonnellesClientsCollectéesList(e.target.value)
              }
            />
          </div>
        )}
      </div>

      <h3 className="text-lg font-large mt-2">
        Les données personnelles collectées ne sont réservée qu&apos;à
        l&apos;entreprise ou sont-elle aussi destinées à des partenaires ?
      </h3>
      <div className="ml-2 my-2">
        <CustomRadioBox
          row={false}
          isBlue={false}
          value={donnéesPersonnellesCollectées_Entreprise_Partenaires}
          setValue={setDonnéesPersonnellesCollectées_Entreprise_Partenaires}
          labelClass=""
          items={[
            {
              value: 'entreprise',
              label:
                'Les données personnelles collectées ne sont réservées qu’à l’entreprise (le vendeur)',
            },
            {
              value: 'entreprise&partenaires',
              label:
                'Les données personnelles collectées sont réservées à l’entreprise et d’autres prestataires partenaires (exemple : prestataire de de transport, prestataire de paiement, prestataire de maintenance, etc.)',
            },
          ]}
        />
        {donnéesPersonnellesCollectées_Entreprise_Partenaires ===
          'entreprise&partenaires' && (
          <div className="ml-2 my-2 border rounded p-2">
            <h3 className="text-lg font-large mt-2">
              Précisez les catégories de partenaires (cochez les cases
              correspondantes)
            </h3>
            <Alert
              icon={<ReportGmailerrorredIcon fontSize="inherit" />}
              severity="warning"
              className="mb-2"
            >
              Cochez au moins une case
            </Alert>
            <CustomCheckBox
              value={DPCEP_isPrestataireTransport === true}
              setValue={(value) => {
                setDPCEP_IsPrestataireTransport(value ? true : false);
              }}
              label="Prestataire de transport"
            />
            <CustomCheckBox
              value={DPCEP_isPrestataireEtablissementPaiement === true}
              setValue={(value) => {
                setDPCEP_IsPrestataireEtablissementPaiement(
                  value ? true : false
                );
              }}
              label="Prestataire établissement de paiement"
            />
            <CustomCheckBox
              value={DPCEP_isAutres === true}
              setValue={(value) => {
                setDPCEP_IsAutre(value ? true : false);
              }}
              label="Autres"
            />
            {DPCEP_isAutres && (
              <TextField
                placeholder="Précisez la nature / catégorie du partenaire ou prestataire"
                id="outlined-multiline-static"
                multiline
                fullWidth
                rows={4}
                value={DPCEP_autres}
                onChange={(e) => setDPCEP_Autre(e.target.value)}
              />
            )}
          </div>
        )}
      </div>

      <h3 className="text-lg font-large mt-2">
        Qui est le responsable de traitement des données personnelles ?
      </h3>
      <div className="ml-2 my-2">
        <CustomRadioBox
          row={false}
          isBlue={false}
          value={responsableTraitementDonnéesPersonnelles}
          setValue={setResponsableTraitementDonnéesPersonnelles}
          labelClass=""
          items={[
            {
              value: 'vendeur',
              label:
                'Le vendeur lui-même (cas le plus fréquent) (il s’agit de l’entreprise dont vous avez renseigné les informations précédemment)',
            },
            {
              value: 'vendeur_autrePersonnes',
              label: 'Le vendeur et une ou plusieurs autres personnes',
            },
            {
              value: 'autrePersonnes',
              label: 'Une ou plusieurs autres personnes',
            },
          ]}
        />
        {responsableTraitementDonnéesPersonnelles !== 'vendeur' && (
          <div className="ml-2 my-2">
            <TextField
              id="outlined-multiline-static"
              multiline
              fullWidth
              rows={4}
              value={responsableTraitementDonnéesPersonnellesIdentité}
              onChange={(e) =>
                setResponsableTraitementDonnéesPersonnellesIdentité(
                  e.target.value
                )
              }
              placeholder="Précisez l’identité (noms, prénoms ou nom et numéro SIREN de la société, adresse)"
            />
          </div>
        )}
        <CustomCheckBox
          value={isDPOexiste === true}
          setValue={(value) => {
            setIsDPOexiste(value ? true : false);
          }}
          label="Cochez cette case s'il existe un délégué à la protection des données (DPO)"
        />
        {isDPOexiste && (
          <div className="ml-2 my-2">
            <Alert
              icon={<ReportGmailerrorredIcon fontSize="inherit" />}
              severity="info"
              className="mb-2"
            >
              Il ne doit en principe pas s&apos;agir du responsable de
              traitement des données.
            </Alert>
            <TextField
              id="outlined-multiline-static"
              multiline
              fullWidth
              rows={4}
              value={DPOIdentité}
              onChange={(e) => setDPOIdentité(e.target.value)}
              placeholder="Précisez l'identité (noms, prénoms ou nom et numéro SIREN de la société, adresse)"
            />
          </div>
        )}
      </div>

      <h3 className="text-lg font-large mt-2">
        Quels sont les moyens dont dispose le client pour supprimer ses données
        personnelles ?
      </h3>
      <div className="ml-2 my-2">
        <TextField
          id="outlined-multiline-static"
          multiline
          fullWidth
          rows={4}
          value={moyensDontDisposeClientSupprimerDonnéesPersonnelles}
          onChange={(e) =>
            setMoyensDontDisposeClientSupprimerDonnéesPersonnelles(
              e.target.value
            )
          }
          placeholder="Exemple : en se connectant à son compte, sur l'onglet configuration du compte."
          label="Décrire"
        />
      </div>
    </div>
  );
}

function STEP16(props) {
  const {
    nomServiceMédiation,
    setNomServiceMédiation,
    adressePostaleSrviceMédiation,
    setAdressePostaleSrviceMédiation,
    siteInternetServiceMédiation,
    setSiteInternetServiceMédiation,
    adresseEmailServiceMédiation,
    setAdresseEmailServiceMédiation,
  } = props;
  return (
    <div className="mb-10">
      <h4 className="font-sans text-2xl font-xlarge">16. Litiges</h4>
      <h3 className="text-lg font-large my-2">
        A quel service de médiation en cas de litige le site est attaché ?
      </h3>
      <div className="ml-2 my-2">
        <Alert
          icon={<ReportGmailerrorredIcon fontSize="inherit" />}
          severity="warning"
          className="my-2"
        >
          <strong>
            Attention, l&apos;indication d&apos;un médiateur est une obligation
            légale depuis le 1er janvier 2016 : vous devez en choisir un.
          </strong>
          <br />
          Vous pouvez passer cette étape et compléter plus tard le nom du
          médiateur choisi.
        </Alert>
        <CustomTextInput
          image={usericon}
          type="text"
          value={nomServiceMédiation}
          setValue={setNomServiceMédiation}
          placeholder="Nom du service de médiation"
        />
        <CustomTextInput
          image={usericon}
          type="text"
          value={adressePostaleSrviceMédiation}
          setValue={setAdressePostaleSrviceMédiation}
          placeholder="Adresse postale du service de médiation"
        />
        <CustomTextInput
          image={usericon}
          type="text"
          value={siteInternetServiceMédiation}
          setValue={setSiteInternetServiceMédiation}
          placeholder="Site internet du service de médiation"
        />
        <CustomTextInput
          image={usericon}
          type="text"
          value={adresseEmailServiceMédiation}
          setValue={setAdresseEmailServiceMédiation}
          placeholder="Adresse e-mail du service de médiation"
        />
      </div>
    </div>
  );
}

function STEP17(props) {
  const { dateMiseVigueurCGV, setDateMiseVigueurCGV } = props;

  return (
    <div className="mb-10">
      <h4 className="font-sans text-2xl font-xlarge">
        17. Date de mise en vigueur des CGV
      </h4>
      <h3 className="text-lg font-large mt-2">
        Quelle est la date de mise en vigueur des CGV ?
      </h3>
      <div className="ml-2 my-2">
        <CustomTextInput
          image={usericon}
          type="date"
          value={dateMiseVigueurCGV}
          setValue={setDateMiseVigueurCGV}
          placeholder="Quelle est la date de mise en vigueur des CGV ?"
        />
      </div>
    </div>
  );
}

function STEP18(props) {
  const { adresseEmail, setAdresseEmail } = props;

  return (
    <div className="mb-10">
      <h4 className="font-sans text-2xl font-xlarge">
        18. Adresse e-mail où votre document doit être envoyé
      </h4>
      <h3 className="text-lg font-large mt-2">Adresse email</h3>
      <div className="ml-2 my-2">
        <CustomTextInput
          image={usericon}
          type="text"
          value={adresseEmail}
          setValue={setAdresseEmail}
          placeholder="Adresse email"
        />
        <Alert
          icon={<ReportGmailerrorredIcon fontSize="inherit" />}
          severity="info"
          className="my-2"
        >
          Nous ne partagerons vos informations avec personne.
        </Alert>
      </div>
    </div>
  );
}

export default ContratCGVForm;
