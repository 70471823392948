import React from 'react';
import chase from '../../../../assets/images/chase.svg';
import asana from '../../../../assets/images/asana.svg';
import google from '../../../../assets/images/google.svg';
import buzzFeed from '../../../../assets/images/buzzFeed.svg';
import toggl from '../../../../assets/images/toggle.svg';
import './partenaire.css';

export default function Partenaires() {
  return (
    <section className='partenairesContainer'>
      <div className='partenaires'>
        <h1>Nos partenaires</h1>
        <p>Ils nous font confiance</p>
        <div className='partenairesLogoContainer'>
          <img src={chase} />
          <img src={asana} />
          <img src={google} />
          <img src={buzzFeed} />
          <img src={toggl} />
        </div>
      </div>
    </section>
  );
}
