import React from 'react';
import { Link } from 'react-router-dom';
import ContratContentSqueleton from '../../components/contratContentSqueleton/contratContentSqueleton';
import './bailCommercial.css';

export default function InfoBailCommercial() {
  const itemsContent = [
    {title: 'Qu\'est-ce qu\'un bail commercial ?', link: '#definitionBC'},
    {title: 'Les caractéristiques du bail commercial', link: '#caracteristiqueBC'},
    {title: 'La durée du bail commercial', link: '#dureeBC'},
    {title: 'Quels sont les modes de résiliation d\'un bail commercial ?', link: '#modeResiliationBC'},
    {title: 'FAQ', link: '#faq'},
  ];
  return (
    <ContratContentSqueleton
      link='form'
      title='Bail commercial'
      subTitle='Formulaire modèle de bail commercial 3 6 9 conforme aux lois Pinel'
      titleLeftContent=''
      textButton='Remplir mon bail commercial'
      titleContent=''
      rightContent={<BailCommercialContent />}
      itemsContent={itemsContent}
    />
  );
}

function BailCommercialContent() {
  return (
    <div className='bailCommercialContent'>
      <h3 id='definitionBC'>Qu’est-ce qu’un bail commercial ?</h3>
      <p>
        Un bail commercial, aussi connu sous le nom de “bail 3-6-9” est un
        <Link className='linkContent' to='#'> contrat de location </Link>conclu entre un locataire et le
        propriétaire d’un local commercial, pour une <strong>durée de 9 ans </strong>en général. Ce local
        est alors destiné à l’exploitation d’une activité commerciale, artisanale ou industrielle par le
        locataire, telle que l’<Link className='linkContent' to='#'>ouverture d’une épicerie </Link>ou
        d’<Link className='linkContent' to='#'>un restaurant </Link>par exemple.
      </p>
      <h6>Quelles sont les conditions à réunir pour conclure un bail commercial ?</h6>
      <p>
        Afin que le local soit soumis aux statuts des baux commerciaux, il doit répondre à certaines
        conditions, notamment celle d’être destinés à l’exploitation d’une activité commerciale ainsi que
        d’être pleinement dédié à cette activité.
      </p>
      <h6 className='h7'>L’immatriculation du locataire</h6>
      <p>
        Pour bénéficier du statut des baux commerciaux, <strong>le locataire doit être immatriculé
        </strong> au Registre du Commerce et des Sociétés (RCS) ou au Répertoire des Métiers (RM), en
        application de l’<Link className='linkContent' to='#'>article L145-1, I du Code de commerce</Link>.
        Cela signifie que le locataire doit exploiter, dans les locaux loués, une activité de nature :
      </p>
      <ul className='list-wtl'>
        <li>commerciale ;</li>
        <li>artisanale ;</li>
        <li>ou industrielle.</li>
      </ul>
      <p>
        Il est ainsi <strong>impossible de conclure un bail commercial </strong>lorsqu’on exerce une 
        profession libérale, ou une activité civile (et ce, même si la société locataire a une forme 
        commerciale). D’autres types de baux, tels que le <Link className='linkContent' to='#'>bail 
        professionnel </Link>ou le <Link className='linkContent' to='#'>bail rural </Link>seront alors plus 
        adaptés à ces situations.
      </p>
      <h6 className='h7'>La destination des lieux</h6>
      <p>
        Le statut des baux commerciaux ne s’applique que si les locaux sont loués dans le but 
        d’<strong>accueillir et d’exploiter un fond de commerce. </strong>En pratique, il est convenu lors 
        de la rédaction du bail commercial, de l’activité amenée à être exercée dans les lieux loués.
      </p>
      <p>
        Le locataire ne peut en aucun cas modifier cette activité sans obtenir l’accord du bailleur : s’il 
        procède à une modification sans accord préalable, il s’expose à un risque de résiliation du bail.
      </p>
      <p>
        Toutefois, s’il décide d’exercer une <strong>activité dite “connexe” ou complémentaire </strong>à 
        celle indiquée dans le bail commercial, le locataire peut formuler une demande de 
        <Link className='linkContent' to='#'> déspécialisation partielle </Link>de l’activité auprès du 
        bailleur. Ce dernier ne pourra refuser la demande que dans le cas où il est avéré que l’activité 
        supplémentaire n’est pas connexe à celle mentionnée dans le bail initial.
      </p>
      <p className='infoContentRed'>
        <strong>Attention : </strong>toute clause du bail commercial interdisant au locataire d’effectuer 
        une demande de déspécialisation est réputée non-écrite.
      </p>
      <h6>Les formalités à accomplir pour la conclusion d’un bail commercial</h6>
      <h6 className='h7'>Les obligations du bailleur préalablement à la signature du bail</h6>
      <p>
        Pour parfaire le contrat de bail commercial, le bailleur doit rassembler divers documents, qui 
        ensemble constituent les <Link className='linkContent' to='#'> annexes à fournir obligatoirement 
        </Link> au locataire. Il s’agit notamment des documents suivants :
      </p>
      <ul className='list-wtl'>
        <li>L’état des risques naturels, miniers et technologiques ;</li>
        <li>Les risques de pollution des sols ;</li>
        <li>Le diagnostic de performance énergétique (DPE) ;</li>
        <li>Le diagnostic amiante ;</li>
        <li>
          L’annexe verte (ou environnementale), si la superficie totale du bien loué est supérieure à 2000 mètres carrés.
        </li>
      </ul>
      <p>
        Outre ces documents, le bailleur doit également remettre au locataire toute information à sa 
        disposition concernant les sinistres qui ont donné lieu à versement d’indemnité ainsi qu’un 
        <strong>prévisionnel des travaux </strong>qu’il envisage de réaliser dans les 3 années suivant la prise d’effet du bail.
      </p>
      <p>
        Avant l’entrée dans les locaux, il devra également prévoir l’organisation d’une visite 
        d’<Link className='linkContent' to='#'> état des lieux</Link>. Il s’agit d’une formalité obligatoire, à laquelle il est impossible de déroger.
      </p>
      <h6 className='h7'>L’enregistrement du bail commercial</h6>
      <p>
        Premièrement, il est important de noter qu’un bail commercial, même 
        <Link className='linkContent' to='#'> non signé</Link>, a une valeur juridique. Toutefois, dans 
        l’optique de rendre opposable aux tiers le contrat de bail commercial, il est important de le faire 
        enregistrer auprès d’un notaire ou du service des impôts.
      </p>
      <p>
        Cette démarche n’est pas obligatoire, mais reste cependant recommandée afin d’éviter tout litige 
        entre les parties quant à la date de prise d’effet du bail.
      </p>
      <p>
        Cette formalité est payante. L’<Link className='linkContent' to='#'>article 739 du Code général 
        des impôts </Link>précise que le montant des frais d’enregistrement est de 25€.
      </p>
      <p className='infoContentBlue'>
        <strong>🔎 Zoom : </strong>Les parties peuvent ainsi procéder elles-mêmes à la rédaction du contrat de location. Legalplace vous propose un <Link className='linkContent' to='#'> modèle de bail commercial</Link>, en conformité avec les nouvelles réformes et personnalisable à l’aide d’un questionnaire.
      </p>
      <h3 id='caracteristiqueBC'>Quelles sont les caractéristiques du bail commercial ?</h3>
      <h6>Révision triennale du loyer</h6>
      <p>
        Conformément à l’<Link className='linkContent' to='#'>article L145-33 du Code de commerce</Link>, le loyer d’un local commercial doit être en adéquation avec sa <strong>valeur locative</strong>. Ainsi, le montant du loyer est en principe fixé par les parties au moment de la signature du bail.
      </p>
      <p>
        Cependant, l’une des particularités de ce type de bail réside dans la possibilité pour les parties de réviser le montant du loyer tous les 3 ans. Cette révision est néanmoins soumise au respect de la variation de l’indice trimestriel de référence, soit :
      </p>
      <ul className='list-wtl'>
        <li>L’indice des loyers commerciaux (ILC) ;</li>
        <li>
          Ou l’<Link className='linkContent' to='#'>indice des loyers des activités tertiaires (ILAT)</Link>.
        </li>
      </ul>
      <p className='infoContentRed'>
        <strong>Attention : </strong>dans certains cas, un déplafonnement du loyer peut être appliqué.
      </p>
      <h6>Les obligations des parties au bail commercial</h6>
      <p>
        Outre la mise à disposition des documents cités précédemment, le bailleur se doit de garantir au locataire :
      </p>
      <ul className='list-wtl'>
        <li>La jouissance paisible des locaux ;</li>
        <li>La réalisation des travaux de remise en état des locaux.</li>
      </ul>
      <p>
        En contrepartie, le locataire s’engage à payer son loyer à l’échéance, ainsi qu’à limiter son utilisation des locaux aux activités prévues dans le bail initial (et aux activités faisant l’objet d’une demande de déspécialisation). Dans certains cas, il sera également tenu de verser un <strong>dépôt de garantie </strong>au bailleur, pour le prémunir contre les dégradations effectuées au cours du bail.
      </p>
      <p>
        <strong>Bon à savoir : </strong>sous certaines conditions, il est possible pour le bailleur d’imposer au locataire le paiement d’un droit d’entrée dans les locaux aussi nommé “pas-de-porte”. Le montant de ce droit d’entrée peut varier en fonction des caractéristiques du local loué (attractivité de l’emplacement notamment).
      </p>
      <h6>Les modalités de prise en charge des travaux</h6>
      <h6 className='h7'>La répartition de la charge des travaux entre le bailleur et le locataire</h6>
      <p>
        En principe, la répartition des charges entre bailleur et locataire est fixée dans le bail commercial. Cependant, il est d’usage que le propriétaire entretienne les locaux, et les conserve en <strong>bon état </strong>de fonctionnement. A ce titre, il est responsable :
      </p>
      <ul className='list-wtl'>
        <li>des travaux de grosses réparation (charpente, toiture, mise aux normes, etc.) ;</li>
        <li>des éventuels travaux imposés par l’administration.</li>
      </ul>
      <p>
        Le locataire ne doit donc pas être importuné par les travaux autres que les <strong> réparations d’ordre locatives </strong>(peintures, papiers peints, revêtement de sol, etc.).
      </p>
      <p className='infoContentRed'>
        <strong>Attention : </strong>il est fréquent que le contrat de bail n’indique que la mention “le vailleur n’assure que les grosses réparations”. Il est ainsi recommandé de préciser explicitement l’<Link className='linkContent' to='#'>obligation de travaux</Link>, afin de ne pas se retrouver face à des dépenses imprévues.
      </p>
      <h6 className='h7'>Les travaux de mise aux normes du local commercial</h6>
      <p>
        Selon la date de signature du bail et son contenu, les <Link className='linkContent' to='#'> travaux de mise aux normes </Link>du local commercial sont imputables au locataire ou au bailleur. Les conditions sont énoncées dans le tableau ci-après :
      </p>
      <table>
        <tr>
          <th>Date de signature du bail</th>
          <th>Qui paye les travaux de mise aux normes ?</th>
        </tr>
        <tr>
          <td>Avant le 5 novembre 2014</td>
          <td>Le locataire si le bail inclut une clause le stipulant</td>
        </tr>
        <tr>
          <td>Après le 5 novembre 2014 – Bail sans clause dérogatoire</td>
          <td>Le bailleur supporte la totalité des travaux</td>
        </tr>
        <tr>
          <td>Après le 5 novembre 2014 – Bail muni d’une clause dérogatoire</td>
          <td>
            <p>Grosses réparations : bailleur</p>
            <p>Autres réparations : locataire</p>
          </td>
        </tr>
      </table><br/>
      <h3 id='dureeBC'>Quelle est la durée d’un bail commercial ?</h3>
      <p>
        Le bail commercial, de par sa durée minimale de 9 ans, constitue un contrat de location protecteur vis-à-vis du locataire. En effet, ce type de bail permet au locataire d’exploiter son activité dans le même local pendant plusieurs années sans avoir à se soucier de rechercher un autre local à la fin du bail.
      </p>
      <h6>La durée du bail commercial</h6>
      <h6 className='h7'>Cas général</h6>
      <p>
        En principe, un bail commercial est conclu pour une <strong>durée minimale de 9 ans</strong>. Il est toutefois possible pour le locataire de résilier le contrat de bail lors des échéances triennales. C’est pour cette raison que le bail commercial est également nommé le bail 3-6-9 : en référence aux<Link className='linkContent' to='#'> échéances triennales </Link>lors desquelles le locataire peut demander résiliation.
      </p>
      <h6 className='h7'>Location saisonnière</h6>
      <p>
        Ce type de bail a été conçu spécifiquement pour pallier aux besoins de la saison touristique. En effet, il est possible pour les exploitants de prendre une location saisonnière en concluant un bail d’une<strong> durée comprise entre 3 et 6 mois</strong>.
      </p>
      <p>
        Il est possible de renouveler le contrat pour chaque saison (hivernale et estivale), cependant il est interdit d’exploiter le fond de commerce en dehors de ces périodes, sous peine de requalification en bail commercial.
      </p>
      <p>
        Le<Link className='linkContent' to='#'> bail commercial saisonnier </Link>est cependant à distinguer du bail dérogatoire, qui dispose d’un régime particulier.
      </p>
      <h6 className='h7'>Bail dérogatoire</h6>
      <p>
        L’<Link className='linkContent' to='#'>article L145-5 du Code de commerce </Link>prévoit une forme de bail dérogatoire, aussi nommé bail précaire, permettant au parties de conclure un bail pour une<strong> durée maximale de 36 mois </strong>(3 ans). Ce bail doit, comme le bail commercial, être conclu dans le but d’exploiter un fond commercial ou artisanal.
      </p>
      <p className='infoContentBlue'>
        <strong>A noter : </strong>conformément à la Loi Pinel lorsque la durée du bail précaire dépasse les 3 ans et qu’aucune des parties n’a l’intention d’y mettre fin, il sera requalifié de bail commercial.
      </p>
      <h6>Le renouvellement du bail commercial</h6>
      <p>
        Autre particularité du bail commercial standard : le droit du locataire au<Link className='linkContent' to='#'> renouvellement de son bail</Link>. Toute clause contraire du contrat de bail est réputée non écrite. Toutefois, en l’<strong>absence d’exploitation effective </strong>du fond de commerce au cours des 3 années précédant l’expiration du bail, le locataire ne pourra pas se prévaloir de son droit au renouvellement du bail commercial.
      </p>
      <p className='infoContentRed'>
        <strong>Attention : </strong>s’il est possible de convenir d’une<Link className='linkContent' to='#'> tacite reconduction du bail commercial</Link>, sachez que cela peut s’avérer risqué pour le preneur.
      </p>
      <p>
        Pour mettre en œuvre son droit, le locataire doit initier une demande de renouvellement par lettre recommandée avec accusé de réception, dans les 6 mois précédant l’expiration du contrat.
      </p>
      <p>
        De son côté, le bailleur dispose aussi d’un droit au renouvellement du bail, qui est notamment invoqué dans le but de réviser le montant du loyer, généralement à la hausse.
      </p>
      <p className='infoContent'>
        <strong>Bon à savoir : </strong>en cas de révision du loyer, un<Link className='linkContent' to='#'> avenant au bail commercial </Link>doit être rédigé puis signé par les parties afin qu’elles attestent de la pleine et entière connaissance des nouvelles modalités du bail.
      </p>
      <h6>La cession du bail commercial</h6>
      <p>
        La cession du bail commercial est en principe libre. Cela signifie que le<strong> locataire peut céder son bail à un repreneur</strong>, moyennant le paiement d’une indemnité, dont le montant est convenu entre les parties.
      </p>
      <p className='infoContentRed'>
        <strong>Attention : </strong>cette cession peut faire l’objet d’un droit de préemption de la part de la commune où est situé le bien, et ce, dans le but de rétrocéder ce fond à un autre commerçant, artisan, etc.
      </p>
      <p>
        Le repreneur devient donc locataire pour la durée restante du bail, sans qu’il ne soit nécessaire d’établir un nouveau contrat entre lui et le bailleur, et se subroge ainsi dans les droits de l’ancien occupant.
      </p>
      <p>
        Le droit de<Link className='linkContent' to='#'> céder un bail commercial </Link>s’explique par le fait que ce droit constitue un des éléments du fond de commerce, et appartient à ce titre au locataire sortant.
      </p>
      <p className='infoContentBlue'>
        <strong>A noter : </strong>une clause de solidarité peut être intégrée au bail initial. Dans ce cas, l’ancien locataire reste solidaire du paiement des loyers avec le repreneur du bail si ce dernier venait à faire défaut au bailleur.
      </p>
      <h3 id='modeResiliationBC'>Quels sont les modes de résiliation du bail commercial ?</h3>
      <p>
        La<Link className='linkContent' to='#'> résiliation d’un bail commercial </Link>peut intervenir :
      </p>
      <ul className='list-wtl'>
        <li>à l’initiative du bailleur ou du locataire ;</li>
        <li>par la mise en œuvre d’une clause résolutoire.</li>
      </ul>
      <h6>La résiliation par le locataire</h6>
      <p>
        Le locataire peut résilier son bail <strong> sans restriction de motif</strong>, sous réserve de respecter le préavis et les modalités de résiliation fixées dans le contrat. Il est également possible de prévoir au contrat une indemnité due en cas de résiliation anticipée du bail.
      </p>
      <p className='infoContent'>
        <strong>Bon à savoir : </strong>en principe, ni le<Link className='linkContent' to='#'> redressement judiciaire</Link>, ni la liquidation judiciaire ne peuvent entraîner la résiliation automatique d’un bail commercial. Le<Link className='linkContent' to='#'> décès du locataire </Link>non plus.
      </p>
      <h6>La résiliation par le bailleur</h6>
      <p>
        Le bail commercial est un contrat très protecteur pour le locataire. A ce titre, le bailleur ne peut demander la résiliation que dans certains cas précis :
      </p>
      <ul className='list-wtl'>
        <li>Construction, reconstruction de l’immeuble existant ou surélévation de l’immeuble ;</li>
        <li>Réaffectation du local d’habitation accessoire à cet usage ;</li>
        <li>
          Exécution des travaux prescrits ou autorisés dans le cadre d’une opération de restauration immobilière ;
        </li>
        <li>En cas de démolition de l’immeuble dans le cadre d’un projet de renouvellement urbain ;</li>
        <li><Link className='linkContent' to='#'>Cessation d’activité</Link>.</li>
      </ul>
      <p>
        De plus, le formalisme du congé est encadré par l’<Link className='linkContent' to='#'>article L145-9 du Code de commerce </Link>:
      </p>
      <ul className='list-wtl'>
        <li>Le courrier doit être envoyé au moins 6 mois à l’avance ;</li>
        <li>Il doit mentionner le motif pour lequel le congé est envisagé.</li>
      </ul>
      <p>
        Le locataire dispose d’un délai de <strong>2 ans </strong>à compter de la réception du courrier<strong> pour contester </strong>ce congé ou demander une indemnité d’éviction devant le tribunal.
      </p>
      <p className='infoContentBlue'>
        <strong>A noter : </strong>le bailleur qui reprend les locaux, résilie le contrat ou ne le renouvelle pas sans justifier d’un motif grave et légitime doit verser une indemnité d’éviction au locataire en compensation du préjudice causé par la non-poursuite du bail.
      </p>
      <h6>La mise en oeuvre d’une clause résolutoire du contrat de bail</h6>
      <p>
        Une<Link className='linkContent' to='#'> clause résolutoire </Link>peut être insérée dans le bail pour le résilier de façon anticipée en cas de manquements de l’une des parties aux obligations du contrat. Elle est notamment insérée afin de sanctionner les locataires ayant dégradé les locaux ou ne payant pas leurs loyers et/ou leurs charges.
      </p>
      <p>
        Toutefois, la mise en oeuvre de cette clause est strictement encadrée par la loi. Il faut notamment que :
      </p>
      <ul className='list-wtl'>
        <li>
          Le bailleur rapporte la preuve du manquement contractuel ou de la faute imputable au locataire ;
        </li>
        <li>
          Ledit manquement ait été expressément indiqué dans le bail comme faisant l’objet d’une clause résolutoire ;
        </li>
        <li>Le bailleur fasse preuve de bonne foi lors de l’application de la clause ;</li>
        <li>
          Le commandement fait au locataire de s’exécuter ait été infructueux, un mois après sa signification par acte d’huissier.
        </li>
      </ul>
      <h3 id='faq'>FAQ</h3>
      <div className='faq'>
        <h5>Est-il possible de sous-louer les locaux dans le cadre d’un bail commercial ?</h5>
        <p>
          Pour être autorisé à sous-louer un local commercial, le locataire doit respecter deux conditions :
        </p>
        <ul className='list-wtl'>
          <li>Avoir obtenu l’accord préalable du bailleur ;</li>
          <li>
            Informer le bailleur par lettre recommandée avec accusé de réception de son intention de sous-louer, en l’invitant à la signature du contrat de sous-location.
          </li>
        </ul>
      </div>
      <div className='faq'>
        <h5>Existe-t-il une alternative au bail commercial ?</h5>
        <p>
          Il est possible de déroger au régime du bail commercial par la conclusion d’un bail civil, qui, soumis au droit commun des contrats, permet plus de souplesse qu’un bail commercial. Aussi, le bail mixte peut être envisagé lorsque les locaux loués servent à la fois de lieu d’habitation et d’exercice professionnel.
        </p>
      </div>
      <div className='faq'>
        <h5>Combien coûte l’enregistrement d’un bail commercial ?</h5>
        <p>L’enregistrement du bail commercial est une formalité qui coûte 25€.</p>
      </div>
    </div>
  );
}

