import React, { useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import DOMPurify from 'dompurify';
import axios from 'axios';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import getEnvironement from '../../environnement';

export async function loader({params}) {
  let loading = true;
  let data = null;
  let error = null;

  await axios.get(`${getEnvironement().BLOG_API_URL}/${params.blogId}?populate=*`)
    .then((response) => {
      data = response.data;
      loading = false;
    })
    .catch((err) => {
      error = err;
      loading = false;
    });

  return {loading, error, data};
}

export default function DetailsArticle() {
  const {loading, error, data} = useLoaderData();
  if (loading) console.log(loading);
  if (error) console.log(error);

  const [sanitizedContent, setSanitizedContent] = useState();
  useEffect(() => {
    setSanitizedContent(
      DOMPurify.sanitize(
        data.data.attributes.content,
        {
          ALLOWED_TAGS: ['p', 'br', 'strong', 'em', 'b', 'i', 'u', 's', 'sup', 'sub', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol', 'li', 'blockquote', 'figure', 'figcaption', 'a', 'img', 'div', 'span'],
          ALLOWED_ATTR: ['href', 'src', 'alt', 'title', 'style']
        }
      )
    );
  }, [data]);
  return (
    <div className='min-h-screen flex flex-col justify-between'>
      <Header />
      <div className='mb-10'>
        <div className='bg-gradient p-10 min-h-60 mb-10'>
          <p className='font-poppins font-medium text-2xl'>Article {data.data.id} : {data.data.attributes.title}</p>
        </div>
        <div className='my-5 px-10 flex justify-center'>
          <img src={`${getEnvironement().BLOG_URL}${data.data.attributes.image.data.attributes.url}`} alt='article' className='w-4/5'/>
        </div>
        <div className='flex justify-center px-10 mt-10'>
          <div className='w-4/5 text-xl font-poppins font-normal' dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
        </div>
      </div>
      <div className='mt-5'>
        <Footer />
      </div>
    </div>
  );
}
