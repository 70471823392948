import axios from 'axios';
import React, { useEffect, useState } from 'react';
import getEnvironement from '../../environnement';
import { useNavigate } from 'react-router-dom';
import ConfirmAchatPackDialog from './confirmAchatPackDialog';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/userSlice';

function Pack({
  id,
  userId,
  title,
  description,
  maxProducts,
  price,
  setSelectedPack,
  productType,
  currentStep,
  setCurrentStep,
  data,
}) {
  const [openConfirmAchatPackDialog, setOpenConfirmAchatPackDialog] = useState(false);

  const navigate = useNavigate();
  const user = useSelector(userSelector);

  const handelClickPack = () => {
    const numberOfUsersPack = user.packs.filter((pack) => pack.id === id).length;
    if (numberOfUsersPack === 0) {
      setSelectedPack('undefined');
      setOpenConfirmAchatPackDialog(true);
    } else {
      const usersPackUnCompleted = user.packs.filter((pack) => pack.id === id && pack.pivot.isCompleted === 0);
      if (usersPackUnCompleted.length === 0) {
        setSelectedPack('undefined');
        setOpenConfirmAchatPackDialog(true);
      } else {

        console.log(usersPackUnCompleted[0].pivot.id);
        setSelectedPack(usersPackUnCompleted[0].pivot.id);
        setCurrentStep(currentStep + 1);
      }
    }
  };
  return (
    <>
      <ConfirmAchatPackDialog
        open={openConfirmAchatPackDialog}
        onClose={() => setOpenConfirmAchatPackDialog(false)}
        onClickNext={() => navigate('/paiement', {state: {productType, userId: userId, packId: id, data}})}
      />
      <div
        className='shadow-md w-[20vw] p-5 mr-5 mt-5 flex flex-col items-center hover:bg-primary hover:text-[#FFF] cursor-pointer'
        onClick={handelClickPack}
      >
        <p className='text-center'>{title}</p>
        <p className='text-center my-5'>Déscription: {description}</p>
        <p className='text-center'>Nombre maximum de produits: {maxProducts == 0 ? 'illimité' : `${maxProducts}`}</p>
        <p className='text-center mt-5'>Prix: {price} DH</p>
      </div>
    </>
  );
}

export default function Packs({
  userId,
  typePack,
  setSelectedPack,
  productType,
  currentStep,
  setCurrentStep,
  data
}) {
  const [packs, setPacks] = useState([]);

  const getPacks = async () => {
    const url = `${getEnvironement().API_URL}/packs`;
    const data = {typePack};
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    await axios.post(url, data, config)
      .then((response) => setPacks(response.data))
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPacks();
  }, []);
  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Choisir un pack :</p>
      <div className='flex'>
        {
          packs.map((pack) => {
            return (
              <Pack
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                key={pack.id}
                id={pack.id}
                userId={userId}
                title={pack.title}
                description={pack.description}
                maxProducts={pack.maxProducts}
                price={pack.price}
                setSelectedPack={setSelectedPack}
                productType={productType}
                data={data}
              />
            );
          })
        }
      </div>
    </div>
  );
}
