import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../../../../components/footer/footer';
import HeaderContainer from '../../../../components/headerContainer/headerContainer';

export default function ContartLocation() {
  return (
    <div className='contratLocation'>
      <HeaderContainer />
      <Outlet />
      <Footer />
    </div>
  );
}
