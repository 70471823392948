import React from 'react';
import SarlBtnForm from '../components/btn/btnfrom';
import ConsielIcon from '../../../../../assets/icons/conseil.svg';
import ConstructionIcon from '../../../../../assets/icons/constuction.svg';
import BoxIcon from '../../../../../assets/icons/box.svg';
import { Link } from 'react-router-dom';

export default function SarlHeader({ type, addStep, title, imageSrc}) {
  return (
    <div className='bg-white'>
      <div className="block p-10 lg:flex justify-between items-center lg:p-[120px] bg-gradient-to-l from-gradient to-lightprimaryv4">
        <div className="sarl-header-text">
          <h1 className="w-full text-3xl  text-center  mb-4 text-darkblue lg:text-start lg:w-[549px] lg:text-5xl font-bold tracking-tight lg:mb-5">
            {title}
          </h1>
          <p className=" w-full text-center text-sm text-darkblue lg:text-start lg:w-[410px] lg:text-lg">
            La solution simple pour obtenir ses statuts signés et son KBIS
            rapidement et sans soucis
          </p>
        </div>
        <div className="hidden md:block md:w-1/4  md:h-1/4 md:m-auto md:my-4  lg:w-80 lg:h-80">
          <img className='max-h-max h-auto' src={imageSrc} />
        </div>
      </div>
      <div className=" lg:flex lg:flex-col  mx-auto min-w-[80%] lg:w-[80%] lg:justify-center lg:items-center lg:gap-5 lg:px-16 bg-white lg:shadow-md p-8 rounded-xl lg:relative lg:-translate-y-1/3">
        <h3 className='mb-6 lg:mb-0 font-inter text-darkblue text-center text-lg font-bold'>Quel sera votre domaine d’activité ?</h3>
        <div className='flex  justify-center gap-3 rounded-md flex-wrap '>
          <Link className='w-1/5 min-w-[130px]' to={`/${type}/form`}><SarlBtnForm Icon={ConsielIcon} text="Conseil / études / audit" onClick={addStep} /></Link>
          <Link className='w-1/5 min-w-[130px]' to={`/${type}/form`}><SarlBtnForm Icon={ConstructionIcon} text="Construction et travaux" onClick={addStep} /></Link>
          <Link className='w-1/5 min-w-[130px]' to={`/${type}/form`}><SarlBtnForm Icon={ConstructionIcon} text="Automobile et transport" onClick={addStep} /></Link>
          <Link className='w-1/5 min-w-[130px]' to={`/${type}/form`}><SarlBtnForm Icon={BoxIcon} text="Vente en ligne" onClick={addStep} /></Link>
        </div>
      </div>
    </div>
  );
}
