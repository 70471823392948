import React, { useState } from 'react';
import { Alert, Button, TextField } from '@mui/material';
import axios from 'axios';
import getEnvironement from '../../../environnement';
import './forgotPassword.css';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleForgotPassword = () => {
    const url = `${getEnvironement().API_URL}/auth/forgot-password`;
    axios.post(url, { email })
      .then(response => setMessage({ type: 'success', text: response.data.message }))
      .catch(error => setMessage({ type: 'error', text: error.response.data.message }));
  };

  return (
    <div className="forgot-password-container">
      <h2>Forgot Password</h2>
      {message && <Alert severity={message.type}>{message.text}</Alert>}
      <TextField
        label="Email"
        variant="outlined"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button variant="contained" onClick={handleForgotPassword}>
        Send Reset Link
      </Button>
    </div>
  );
}
