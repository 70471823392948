import React from 'react';
import Statistics from './statistics';
import Profile from './profile';
import Records from './records';
import Packs from './packs';
import Contact from './contact';

function Dashbody({ activeTab }) {
  return (
    <div id='mainbody' className="flex-1 px-10 py-4 max-h-screen overflow-y-auto">
      {activeTab === 'dashboard' && <Statistics/>}
      {activeTab === 'profile' && <Profile />}
      {activeTab === 'records' && <Records />}
      {activeTab === 'packs' && <Packs />}
      {activeTab === 'contact' && <Contact />}
    </div>
  );
}

export default Dashbody;