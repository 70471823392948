import React from 'react';
import { Link } from 'react-router-dom';
import ContratContentSqueleton from '../../components/contratContentSqueleton/contratContentSqueleton';
import './infoContratPrestation.css';

export default function InfoContratPrestation() {
  const itemsContent = [
    {title: 'Modèle gratuit de contrat de prestation de service à télécharger', link: '#1'},
    {title: 'Qu’est-ce qu’un contrat de prestation de services ou freelance ?', link: '#2'},
    {title: 'Comment fonctionne le contrat de prestation de services ?', link: '#3'},
    {title: 'Quelles sont les obligations propres à chacune des parties au contrat ?', link: '#4'},
    {title: 'Comment résilier un contrat de prestation de services ?', link: '#5'},
    {title: 'FAQ', link: '#6'},
  ];
  return (
    <ContratContentSqueleton
      link='form'
      title='Contrat de prestation de services ou de sous-traitance'
      subTitle='Contrat de prestation de services – Contrat de freelance – Contrat de sous-traitance'
      titleLeftContent=''
      textButton='Remplir mon contrat de prestation'
      titleContent='A propos du contrat de prestation de service freelance ou sous-traitance'
      rightContent={<ContratPrestationContent />}
      itemsContent={itemsContent}
    />
  );
}

function ContratPrestationContent() {
  return (
    <div className='contratPrestationContent'>
      <h3 id='1'>Modèle gratuit de contrat de prestation de service à télécharger</h3>
      <p>
        LegalPlace vous propose un <strong>modèle gratuit de contrat de prestation de service </strong>
        (parfois nommé <Link className='linkContent' href='#'>contrat freelance</Link>) à jour des 
        législations en vigueur. Ce contrat est à compléter en ligne, à l’aide de notre questionnaire 
        intelligent, et vous permettra de l’adapter pleinement à votre situation.
      </p>
      <p>
        En effet, après avoir répondu au questionnaire, vous obtiendrez un contrat complété, entièrement 
        personnalisé qu’il vous suffira de télécharger, au <strong>format Word ou PDF</strong>.
      </p>

      <h3 id='2'>Qu’est-ce qu’un contrat de prestation de services ou freelance ?</h3>
      <p>
        Défini par l’<Link className='linkContent' href='#'>article 1710 du Code civil </Link>sous 
        l’appellation « contrat de louage », le contrat de prestation de services est un document juridique 
        qui définit les responsabilités et les obligations entre une entreprise et un prestataire de 
        services. <strong>Il décrit les services que le prestataire doit fournir </strong>à l’entreprise et 
        les modalités de paiement pour ces services.
      </p>
      <p>
        Il peut également inclure des <Link className='linkContent' href='#'>clauses sur la confidentialité 
        </Link>et des dispositions relatives à la propriété intellectuelle. Ces contrats sont très courants 
        dans le secteur des services et sont essentiels pour protéger les intérêts des deux parties.
      </p>
      <h6>Quel est l’intérêt de rédiger un contrat de prestation de services ?</h6>
      <p>
        La rédaction d’un contrat de prestation de service essentielle, notamment pour des raisons de 
        <strong>sécurité juridique</strong>, car il permet d’éviter les problèmes liés à un contrat verbal 
        ou à un manque de communication entre les deux parties.
      </p>
      <p>
        Ce contrat définit clairement les termes et conditions de la relation entre les deux parties, ce qui 
        facilite le règlement des litiges et le respect des engagements pris.
      </p>
      <p>
        Cela permet également de sécuriser le <Link className='linkContent' href='#'>recrutement d’un 
        freelance</Link>. Le freelance est à distinguer du salarié, par le fait qu’il n’existe <strong>aucun 
        lien de subordination </strong>entre lui et la société cliente. C’est la raison pour laquelle on 
        emploie le terme de “travailleur indépendant”. Il décide de 
        <Link className='linkContent' href='#'>travailler à son compte </Link>pour diverses sociétés.
      </p>
      <p>
        C’est à lui de négocier les termes de la mission avec son client, ainsi que la rémunération qui va 
        lui être versée. Le freelance bénéficie donc d’une pleine autonomie pour organiser son temps de travail.
      </p>
      <h6>Quelles différences avec le contrat de travail ?</h6>
      <p>
        Un contrat de prestation de service est un contrat par lequel une personne (une entreprise ou une 
        personne indépendante) s’engage à fournir des services à une autre personne (généralement une 
        entreprise) en contrepartie d’une rémunération. Le fournisseur de services <strong>n’est pas un 
        employé de l’entreprise </strong>et n’est pas lié à elle par un 
        <Link className='linkContent' href='#'>contrat de travail</Link>. La principale différence réside 
        donc dans l’absence de <Link className='linkContent' href='#'>lien de subordination </Link>entre les parties.
      </p>
      <p>
        Ainsi, les principales différences entre le contrat de prestation de service et le contrat de 
        travail sont répertoriées dans le <strong>tableau comparatif </strong>suivant :
      </p>
      <table>
        <tr>
          <th>Elément analysé</th>
          <th>Contrat de prestation de service</th>
          <th>Contrat de travail</th>
        </tr>
        <tr>
          <td>Objet du contrat</td>
          <td>Réalisation d’une mission précise</td>
          <td>Exécution d’un travail régulier</td>
        </tr>
        <tr>
          <td>Indépendance ?</td>
          <td>Le prestataire de service est un travailleur indépendant</td>
          <td>Le salarié est sous l’autorité de l’employeur</td>
        </tr>
        <tr>
          <td>Rémunération</td>
          <td>Rémunération</td>
          <td>A l’heure ou à la tâche</td>
        </tr>
        <tr>
          <td>Liberté d’action</td>
          <td>Le prestataire est en droit de refuser des missions</td>
          <td>Le salarié est tenu de respecter l’autorité de son employeur</td>
        </tr>
        <tr>
          <td>Responsabilité</td>
          <td>Le prestataire est responsable de ses propres actes</td>
          <td>Le salarié est responsable des actes commis pendant l’exercice de ses fonctions</td>
        </tr>
        <tr>
          <td>Charges</td>
          <td>Le prestataire paye ses propres charges</td>
          <td>C’est l’employeur qui paie les charges</td>
        </tr>
      </table>
      <p className='infoContentRed'>
        <strong>Attention : </strong>en cas de lien de subordination avéré entre le prestataire et la 
        société cliente, le contrat de prestation de services pourra être requalifié par les tribunaux en 
        contrat de travail classique. En effet, la qualification du contrat ne dépend pas de la volonté 
        commune des parties mais des critères fixés par la loi et la jurisprudence.
      </p>

      <h3 id='3'>Comment fonctionne le contrat de prestation de services ?</h3>
      <p>
        Un contrat de prestation de service est d’abord défini par son objet, la prestation de service, mais 
        aussi par le contenu spécifique du contrat, qu’il est important d’analyser.
      </p>
      <h6>Les caractéristiques d’une prestation de services</h6>
      <p>
        Une prestation de service est une <strong>activité ou un produit fourni par une 
        entreprise </strong>ou une organisation à ses clients ou à d’autres organisations. Les services 
        peuvent inclure des produits finis, des services personnalisés, des conseils ou des informations. 
        De plus, ils peuvent être fournis à l’intérieur d’une entreprise ou à des clients externes.
      </p>
      <p>
        La plupart des relations commerciales actuelles peuvent être regroupées sous l’appellation 
        « prestation de service », excepté la vente de produits, de biens ou de marchandises.
      </p>
      <p>
        Les prestations de service peuvent inclure des services professionnels, des services de soutien, 
        des services de maintenance et des services de traitement de données, de telle sorte qu’il est 
        possible de contracter une multitude de contrat de prestation de services :
      </p>
      <ul className='list-wtl'>
        <li><Link className='linkContent' href='#'>Contrat d’illustrateur freelance</Link>;</li>
        <li><Link className='linkContent' href='#'>Contrat de graphiste freelance</Link>;</li>
        <li><Link className='linkContent' href='#'>Contrat de freelance esthétique</Link>;</li>
        <li><Link className='linkContent' href='#'>Contrat de freelance marketing</Link>;</li>
        <li><Link className='linkContent' href='#'>Contrat de consultant freelance</Link>;</li>
        <li><Link className='linkContent' href='#'>Contrat de formateur freelance</Link>;</li>
        <li><Link className='linkContent' href='#'>Contrat de Community manager freelance</Link>;</li>
        <li><Link className='linkContent' href='#'>Contrat de comptable freelance</Link>.</li>
      </ul>
      <h6>Le contenu et les clauses du contrat de prestation de services</h6>
      <p>
        Afin de limiter les risques de conflits, le contrat de prestation de service doit être rédigé 
        minutieusement. Il est notamment important de prévoir tous les cas de figure dans le contrat, en y 
        ajoutant certaines clauses par exemple.
      </p>
      <h6 className='h7'>Les mentions obligatoires</h6>
      <p>
        Certaines informations sont essentielles et doivent par conséquent figurer dans le document. Il s’agit :
      </p>
      <ul>
        <li>De l’identité des parties ;</li>
        <li>De la description de la mission confiée :</li>
        <li>Des obligations des co-contractants ;</li>
        <li>Des sanctions prévues en cas de manquement aux obligations ;</li>
        <li>Du montant fixé pour la rémunération ;</li>
        <li>De l’échéance de la mission (la durée du contrat pouvant être déterminée ou indéterminée) ;</li>
        <li>De la responsabilité des parties ;</li>
        <li>Des éventuelles modalités de résiliation anticipée du contrat.</li>
      </ul>
      <p className='infoContentBlue'><strong>À noter :</strong> la fixation <Link className='linkContent' href='#'>du prix d’une prestation de services</Link> est libre. Toutefois, des critères sont à prendre en compte tels que les frais fixes et variables engagés par le prestataire des services, les prix du marché pour des prestations équivalentes et la marge voulue.</p>
      <h6 className='h7'>Les clauses additionnelles</h6>
      <p>Le contrat de prestation de services étant très spécifique, il convient d’insérer des clauses permettant d’encadrer la relation contractuelle entre le prestataire de services et la société cliente. Les principales clauses sont les suivantes :</p>
      <ul>
        <li>Confidentialité : insérée dans le but de se prémunir contre les risques de divulgation par le freelance de secrets professionnels, appris pendant son intervention auprès de la société cliente. Cette clause doit préciser les actions a mener par le prestataire pour ne pas porter atteinte à la confidentialité de la société cliente.</li>
        <li><Link className='linkContent' href='#'>Non-concurrence</Link> : par l’insertion de cette clause, les parties protègent leurs intérêts. Il est cependant nécessaire qu’une telle clause soit limitée dans le temps, l’espace et ne vise que des services spécifiques.</li>
        <li><Link className='linkContent' href='#'>Propriété intellectuelle</Link> : le travailleur indépendant peut être tenu à la création d’œuvres qui ne lui appartiennent pas. C’est pourquoi, il est utile d’insérer une clause de propriété intellectuelle dans le contrat afin d’encadrer le transfert de propriété de l’œuvre réalisée.</li>
      </ul>
      <p className='infoContentBlue'><strong>A noter :</strong> Dans le cadre d’un contrat de prestation de services, il est fréquent que la société cliente donne simplement son accord oral pour l’accomplissement de la mission. Dans ce cas, en tant que freelance, pensez à demander une confirmation écrite. En effet, en cas de litige, l’écrit sera nécessaire pour pouvoir rapporter une preuve de la convention établie.</p>

      <h3 id='4'>Quelles sont les obligations propres à chacune des parties au contrat ?</h3>
      <p>Dans le cadre d’un contrat de prestation de service, les parties sont tenues de respecter des obligations imposées par la loi.</p>
      <h6>Les obligations du prestataire de services</h6>
      <p>Concernant l’exercice de sa mission, le freelance n’est tenu qu’à une <strong>obligation de moyens</strong>. Cela signifie qu’il doit tout mettre en œuvre pour qu’elle réussisse, et s’il est prouvé qu’il a fait tout son possible pour la mener à bien, il ne sera pas tenu pour responsable, et ce, même si l’objectif fixé par la société n’a pas été atteint.</p>
      <p>L’<Link className='linkContent' href='#'>article L111-1 du Code de la consommation</Link> ajoute que ce prestataire est tenu d’un devoir d’information, de conseil et de mise en garde envers son client.</p>
      <p className='infoContentBlue'><strong>A noter :</strong> bien entendu, la première obligation du freelance est d’être enregistré en tant que tel auprès des administrations, et de disposer des assurances nécessaires à l’exercice de son activité.</p>
      <h6>Les obligations de la société cliente</h6>
      <p>En contrepartie des prestations réalisées, le client a également des devoirs :</p>
      <ul>
        <li>Rémunération du prestataire ;</li>
        <li>Mise à disposition de tout les moyens nécessaires à la réalisation de la mission du freelance ;</li>
        <li>S’assurer que le prestataire s’acquitte des obligations de déclaration et du paiement des cotisations sociales, si ce dernier a eu recourt à un sous-traitant.</li>
      </ul>
      <p>Concernant l’obligation de paiement, on distingue selon qu’il s’agisse d’une rémunération fixe ou une rémunération variable :</p>
      <ul>
        <li>Fixe : la société cliente est tenue de rémunérer le prestataire en payant la totalité du prix, que la mission soit un succès ou non ;</li>
        <li>Variable : le montant de la rémunération dépend du succès de la mission, la société cliente ne devra alors payé que ce qui a été convenu entre les parties.</li>
      </ul>
      <p className='infoContentRed'><strong>Attention :</strong> si la société cliente se refuse à payer le freelance, il peut de plein droit suspendre l’exécution du contrat jusqu’au versement des sommes convenues.</p>
      
      <h3 id='5'>Comment résilier un contrat de prestation de services ?</h3>
      <p>La résiliation d’un contrat de prestation de service dépend du type de contrat et des conditions spécifiques qui y sont stipulées.</p>
      <p>Un contrat de prestation de services à durée déterminée <strong>ne pourra être résilié avant la fin du terme</strong> prévu dans le contrat. Dans le cadre d’un contrat de prestation de services à durée indéterminée, une clause de résiliation anticipée pourra être prévue au sein du contrat.</p>
      <p>En général, il est possible de résilier un contrat de prestation de service en envoyant une <srtrong>lettre de résiliation</srtrong> au fournisseur de services ou à l’entité qui a conclu le contrat.</p>
      <p>Dans la lettre de résiliation, il est important d’indiquer le nom et les coordonnées du client, la date à laquelle le contrat a été conclu et le type de service fourni. Il est également important d’indiquer la date à laquelle on souhaite que la résiliation prenne effet.</p>
      <p>D’autres informations peuvent être nécessaires, selon les termes du contrat. Dans certains cas, il peut être nécessaire de fournir une preuve de paiement ou une lettre de motivation pour justifier la résiliation.</p>

      <h3 id='6'>FAQ</h3>
      <div className='faq'>
        <h5>Comment rédiger un contrat de prestation de services gratuitement ?</h5>
        <p>
          La rédaction d&apos;un contrat de prestation de services impose de mentionner les informations essentielles : l&apos;identité des parties signataires, l&apos;objet de la mission, la rémunération, etc.
        </p>
      </div>
      <div className='faq'>
        <h5>Le contrat de prestation de service doit-il nécessairement être rédigé par écrit ?</h5>
        <p>
          La loi n’impose aucun formalisme particulier concernant la rédaction du contrat de prestation de services. On peut donc en conclure que le contrat de freelance pourrait très bien résulter uniquement d’un accord oral, à l’instar du bail verbal.
        </p>
      </div>
      <div className='faq'>
        <h5>Comment résilier un contrat à tacite reconduction ?</h5>
        <p>Depuis la loi Chatel, les clients doivent être prévenus de la date de reconduction du contrat, afin de pouvoir le résilier s&apos;ils le souhaitent.</p>
      </div>
    </div>
  );
}

