import React from 'react';
import { Button } from '@mui/material';
import './decouvrir.css';

export default function Decouvrir() {
  return (
    <section className='decouvrirContainer'>
      <div className='yellow'>
        <div className='leftContainer'>
          <h1>Votre marque a une forte personnalité dans le marché?</h1>
          <p>
            LBF Agency votre agence de communication digitale à Casablanca, 
            vous aidera pas à pas à renforcer les valeurs et la personnalité 
            de votre entreprise via une Stratégie Branding élaborée spécialement 
            pour vous afin de tracer l’identité de votre marque d’une manière unique, 
            originale et puissante et lui donner une forme chez votre audience ainsi 
            que gagner leur confiance.
          </p>
          <div className='decouvrirButton'>
            <Button className='button' variant="contained" size='large'>Découvrir</Button>
          </div>
        </div>
      </div>
      <div className='primary'>
        <div className='rightContainer'>
          <h1>Avez-vous besoin d’un Fournisseur Materiel Informatique Maroc ?</h1>
          <p>
            SMARTRONIX est un Fournisseur Materiel Informatique Maroc qui propose à ses clients 
            dans tous le Maroc les Solutions Hardware et Software les plus adaptées.
          </p>
          <div className='decouvrirButton'>
            <Button className='button' variant="contained" size='large'>Découvrir</Button>
          </div>
        </div>
      </div>
      <div className='violet'>
        <div className='leftContainer'>
          <h1>Domptez les flux de travail de votre entreprise grâce à un logiciel de workflow</h1>
          <p>
            Vous êtes à la recherche d’une manière simple et efficace de piloter vos processus métiers, 
            de réduire le temps de traitement de vos réclamations clients ou encore de limiter les risques 
            liés à un dysfonctionnement de votre chaîne de production ? Perforiom est fait pour vous.
          </p>
          <div className='decouvrirButton'>
            <Button className='button' variant="contained" size='large'>Découvrir</Button>
          </div>
        </div>
      </div>
    </section>
  );
}
