import React from 'react';
import { Slider } from '@mui/material';
import { styled } from '@mui/material/styles';

const StepsSlider = styled(Slider)({
  color: '#000',
  height: 5,
  '& .MuiSlider-track': {
    border: 'none',
    background: '#3392FF',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    color: '#3392FF',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-mark': {
    width: 24,
    height: 24,
    borderRadius: '50%',
    backgroundColor: '#EAEAEB',
  },
  '& .MuiSlider-markActive': {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: '#3392FF',
    opacity: 4,
  },
});

export default function StepsProgress({
  defaultValue = 0,
  minValue = 1,
  maxValue = 10,
  steps = 1,
  valueLabelDisplay = 'off',
  id = 'slider',
  marks = false,
  disabled= false,
  addParams = {},
}) {
  return (
    <StepsSlider
      valueLabelDisplay={valueLabelDisplay}
      aria-label={id}
      defaultValue={defaultValue}
      step={steps}
      min={minValue}
      max={maxValue}
      marks={marks}
      disabled={disabled}
      {...addParams}
    />
  );
}
