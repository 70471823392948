import React from 'react';
import InfoComptabiliteHeader from './components/infoComptabiliteHeader/infoComptabiliteHeader';
import AvantageComptabilite from './components/avantageComptabilite/avantageComptabilite';
import VousCherchezPartenaire from './components/vousCherchezPartenaire/vousCherchezPartenaire';
import DecouvrirSatisfaction from './components/decouvrirSatisfaction/decouvrirSatisfaction';
import ConnecteBanque from './components/connecteBanque/connecteBanque';
import QuestionFrequentes from './components/questionsFrequentes/questionFrequentes';
import Header from '../../../../../components/header/header';
import Footer from '../../../../../components/footer/footer';

export default function InfoComptabilite() {
  return (
    <div className='bg-white'>
      <Header/>
      <InfoComptabiliteHeader />
      <AvantageComptabilite />
      <VousCherchezPartenaire />
      <DecouvrirSatisfaction />
      <ConnecteBanque />
      <QuestionFrequentes />
      <Footer/>
    </div>
  );
}
