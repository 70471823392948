import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../../../../components/footer/footer';
import HeaderContainer from '../../../../components/headerContainer/headerContainer';
import AutresDemarchesContentSqueleton from '../components/autresDemarchesContentSqueleton/autresDemarchesContentSqueleton';
import './dissolution.css';

export default function Dissolution() {
  const navigate = useNavigate();

  const itemsContent = [
    {title: 'Quand procéder à la dissolution d’une entreprise en ligne?', link: '#1'},
    {title: 'Comment fermer une entreprise en ligne?', link: '#2'},
    {title: 'Quels sont les documents constituant le dossier de dissolution?', link: '#3'},
    {title: 'Quelles sont les démarches préalables au dépôt du dossier ?', link: '#4'},
    {title: 'Comment s’effectuent les opérations de liquidation de l’entreprise?', link: '#5'},
    {title: 'À combien s’élèvent les frais de fermeture d’une entreprise en ligne?', link: '#6'},
    {title: 'FAQ', link: '#7'},
  ];
  return (
    <div className='dissolution'>
      <HeaderContainer />
      <AutresDemarchesContentSqueleton
        textButton='Dissoudre ma société en ligne'
        titleContent='La dissolution d’une entreprise en ligne'
        rightContent={<CessionContent />}
        itemsContent={itemsContent}
        isAutresDemarche
        onClick={() => navigate('/dissolution/form')}
      />
      <Footer />
    </div>
  );
}

function CessionContent() {
  return (
    <div className='dissolutionContent'>
      <p>La <Link className='linkContent' to='#'>dissolution d’entreprise</Link> en ligne dans le cadre d’une fermeture à l’amiable. Celle imposée par une décision judiciaire est écartée d’office. L’utilisation des canaux numériques pour réaliser cette procédure gagne en notoriété. Les avantages de cette méthode intéressent les dirigeants d’entreprise. Mais avant de penser à la mettre en pratique, il est essentiel de rassembler une décision favorable de la majorité des associés.</p>
      
      <h3 id='1'>Quand procéder à la dissolution d’une entreprise en ligne?</h3>
      <p>Les associés peuvent décider de procéder à la <Link className='linkContent' to='#'>dissolution d’une entreprise en ligne</Link>. Mais il est également possible que cette dernière soit fermée en raison de l’expiration du temps de validité de la société. Sauf clause contraire stipulée dans les statuts, la durée maximale de la vie d’une entreprise est fixée à 99 ans. À l’issue de cette échéance, les actionnaires ont le choix de demander une prorogation.</p>
      <p>Parmi les motifs entraînant la dissolution figure aussi la réalisation ou l’<strong>extinction de l’objet social</strong>. Ce dernier fait référence à l’identification de l’activité que la société prévoit d’exercer effectivement. Il doit être mentionné en toute clarté dans les statuts, lors de la phase de création de l’entreprise. Privé de celui-ci, la société perd sa raison d’être et est condamnée à être dissoute dans les plus brefs délais.</p>
      <p><strong>La réalisation de l’objet social</strong> désigne l’achèvement définitif de l’ensemble des activités, peu importe leur nature, pour lesquelles la société était programmée. En d’autres termes, elle n’a plus d’opérations à réaliser. L’extinction, quant à elle, désigne le fait que l’activité exercée par l’entreprise, et pour laquelle elle a été créée, est devenue impossible.</p>
      <p>Dans la liste, on retrouve également <strong>la réalisation d’une cause prévue dans les statuts</strong>. Lors de la <Link className='linkContent' to='#'>création de l’entreprise</Link>, les associés peuvent définir de manière claire les situations qui engendreront une fermeture irrémédiable de la structure. Elles seront mentionnées dans des clauses du document. Dans le cas où l’une d’entre elles surviendrait effectivement, la procédure de dissolution peut être déclenchée.</p>
      <p>Mais <strong>parmi les causes de dissolution</strong>, on distingue aussi :</p>
      <ul className='list-wtl'>
        <li>L’annulation du contrat de société;</li>
        <li>La décision des associés de procéder à une dissolution anticipée;</li>
        <li>Une décision judiciaire émanant du tribunal de commerce.</li>
      </ul>
      <p>Concernant ce dernier point, la décision peut être obtenue à la suite d’une demande adressée par au moins un des associés. Toutefois, elle doit être justifiée par de justes motifs, notamment en cas de <strong>rupture de l’harmonie au sein de la structure</strong> :</p>
      <ul className='list-wtl'>
        <li>Mésentente entre associés ;</li>
        <li>Refus d’exécuter les obligations ;</li>
        <li>Réunion entre les mains d’un seul associé de toutes les parts d’une société pluripersonnelle.</li>
      </ul>
      <p className='infoContent'><strong>Bon à savoir :</strong> cette décision de la juridiction peut être également motivée par un état de cessation de paiement.</p>
      
      <h3 id='2'>Comment fermer une entreprise en ligne?</h3>
      <p>Les associés disposent de plusieurs moyens pour réaliser leur projet de fermeture de l’entreprise sur Internet. Ils peuvent effectuer librement leur choix, suivant leurs besoins et leurs attentes. Des facteurs tels que <strong>le temps de réalisation, l’argent disponible ou la sécurisation</strong> contribuent à l’influencer.</p>
      <h6>Fermer l’entreprise soi-même</h6>
      <p>Les associés peuvent se concerter et <strong>décider d’un commun accord de se charger eux-mêmes de réaliser la procédure</strong> de fermeture. Pour ce faire, ils ont le choix de désigner l’un d’entre eux, ou même une personne externe pour revêtir le rôle de liquidateur. Dans les entreprises de tailles modestes, ce sont surtout les dirigeants qui héritent de cette tâche.</p>
      <p>Pour se faciliter la tâche, ils peuvent commencer à <strong>rassembler des modèles de documents nécessaires à l’accomplissement de la démarche</strong>. Ils ont la possibilité d’accéder aux sites qui en contiennent, ou s’adresser directement à la chambre de commerce et d’industrie.</p>
      <p>Mais les associés doivent bien choisir leurs sources, afin de réduire les risques d’obtenir des modèles qui manquent de fiabilité et <strong>d’éviter de se heurter à un rejet administratif</strong>. En effet, c’est toute la démarche qui se verra retardée. Il faudra prendre également le soin d’adapter les modèles, suivant les spécificités du statut de la société en question.</p>
      <p>Le <Link className='linkContent' to='#'>liquidateur</Link> aura fort à faire pour réaliser la mission qui lui a été confiée pour la liquidation, tout en veillant à respecter le délai imparti. Pour faciliter ses tâches, il peut <strong>requérir l’aide d’un professionnel, notamment celle de l’expert-comptable</strong> lors de :</p>
      <ul className='list-wtl'>
        <li>L’établissement des comptes de clôture :</li>
        <li>L’évaluation des résultats de la liquidation.</li>
      </ul>
      <p className='infoContent'><strong>Bon à savoir :</strong> toutefois, il peut décider de se charger lui-même des formalités de <Link className='linkContent' to='#'>publication de l’annonce légale</Link>, afin de réduire considérablement la facturation de la prestation.</p>
      <h6>Confier la démarche à un prestataire</h6>
      <p>La nomination du liquidateur permet aux associés de désigner un responsable qui se charge de coordonner les formalités. Toutefois, s’ils en ont les moyens, ils <strong>peuvent décider de solliciter les services d’un professionnel</strong>, que ce soit un :</p>
      <ul className='list-wtl'>
        <li>Avocat ;</li>
        <li>Notaire ;</li>
        <li>Expert-comptable.</li>
      </ul>
      <p>Cette solution, malgré sa longueur et son coût important, constitue le choix le plus rassurant, peu importe le degré de difficulté de la démarche. L’expertise du professionnel dans le domaine juridique est requise pour la <strong>rédaction juridique et les conseils</strong>.</p>
      <p>Mais la société peut avoir recours également à une <strong>plateforme juridique pour réaliser rapidement les démarches</strong> de dissolution d’une entreprise en ligne. Elle s’illustre par son coût abordable, sa rapidité et sa compétence dans le domaine juridique.</p>
      <p>Pour bénéficier de la meilleure option possible, les associés doivent convenir, dès la décision de fermer l’entreprise, du partenaire qu’ils solliciteront pour les accompagner tout au long de la procédure. Outre les professionnels, ils ont donc le choix entre de nombreuses plateformes en ligne. Ils devront faire preuve de prudence et d’esprit critique pour <strong>identifier les sites sérieux</strong> et éviter des désagréments qui peuvent s’avérer coûteux et ennuyeux. Soulignons que des comparateurs se chargent de mettre en évidence les forces et faiblesses de ces partenaires.</p>
      <p className='infoContentBlue'><strong>🔎 Zoom :</strong> LegalPlace peut réaliser pour vous l’ensemble des formalités de dissolution de votre entreprise. Il vous suffit de remplir un formulaire rapide en ligne et de nous envoyer les pièces justificatives requises. Nos formalistes se chargent de toutes les démarches sur le site de l’INPI. De cette manière, vous vous déchargez du poids de cette procédure afin de vous concentrer exclusivement sur la liquidation de votre entreprise.</p>
      
      <h3 id='3'>Quels sont les documents constituant le dossier de dissolution?</h3>
      <p>Le dossier de dissolution d’entreprise en ligne comporte de nombreux éléments. Concrètement, il contient certains actes qui sont destinés à être produits en annexe au <Link className='linkContent' to='#'>RCS</Link>, à savoir un <strong>exemplaire certifié conforme par le représentant légal du procès-verbal de dissolution et de nomination du liquidateur</strong>.</p>
      <p>Il comprend également des pièces justificatives, comme l’attestation fournie par le support d’annonces légales (anciennement <Link className='linkContent' to='#'>journal d’annonces légales</Link>) <strong>pour prouver la parution de l’avis de modification</strong>. Des documents concernant aussi exclusivement le liquidateur sont aussi à prévoir.</p>
      <h6>Pour le liquidateur personne morale</h6>
      <p>Normalement, le liquidateur doit être immatriculé. De ce fait, il faut qu’il soit en mesure de produire un <strong>extrait du registre du commerce et des sociétés</strong>, qui se présente en original de moins de trois mois. Le cas échéant, il est tenu de produire tout type de document, de nature officiel, qui est susceptible de prouver sans ambiguïté son existence légale.</p>
      <h6>Pour le liquidateur personne physique</h6>
      <p>Un liquidateur personne physique doit fournir une <strong>copie recto verso d’une pièce d’identité</strong> pour compléter le dossier de dissolution. Il peut aussi bien s’agir d’une carte nationale d’identité que d’un passeport encore valide.</p>
      <p>De plus, il devra rédiger une <strong>déclaration sur l’honneur de non-condamnation</strong>, qui doit être datée et signée en original par l’intéressé. Cet élément sera par la suite vérifié par le juge commis au Registre du commerce et des sociétés auprès du Casier judiciaire. En dernière position vient l’attestation de filiation du liquidateur, comportant les noms et prénoms des parents. Toutefois, ce document n’est plus exigé si la filiation est mentionnée dans un document déjà produit.</p>

      <h3 id='4'>Quelles sont les démarches préalables au dépôt du dossier ?</h3>
      <p>Le dépôt du dossier sur le site de l’INPI est essentiel pour dissoudre l’entreprise. Celui-ci est composé des éléments susmentionnés, qu’il est possible de numériser <strong>pour être envoyé par l’intermédiaire du site internet dédié</strong>. Toutefois, avant de réaliser cette démarche, il est impératif de soumettre à des formalités préalables.</p>
      <p className='infoContentBlue'><strong>À noter :</strong> à partir du 1er janvier 2023, une innovation est intervenue. En effet, il n’y a plus besoin de recourir au centre de formalités des entreprises (CFE) pour réaliser la démarche. Aujourd’hui, il est obligatoire de passer sur le site internet du guichet des formalités des entreprises.</p>
      <h6>Tenue de l’assemblée générale</h6>
      <p>Si la tenue de l’assemblée générale est commune à toutes les entreprises, chacun dispose de son propre mode de fonctionnement. Pour les <Link className='linkContent' to='#'>sociétés de capitaux</Link> telles que les SA et les SAS, cette AG peut être programmée librement en visioconférence. Cette éventualité n’est <strong>possible au sein de la SARL que si les statuts le prévoient</strong>. C’est au cours de la réunion que les associés s’exprimeront concernant la dissolution de l’entreprise en ligne.</p>
      <p><strong>La décision de dissolution et la nomination de l’associé</strong> sont soumises à des spécificités en matière de majorité et de <Link className='linkContent' to='#'>quorum</Link>. Pour les SA, la majorité est de 2/3 des actionnaires présents. Concernant les SAS, la loi ne fixe aucun chiffre pour la majorité lors des votes et pour le nombre d’associés devant être présent, laissant le soin aux statuts.</p>
      <p><strong>Pour la SCI, toute décision nécessite normalement une unanimité</strong>, mais il est possible de choisir une majorité plus faible à partir du statut. Et pour les <Link className='linkContent' to='#'>SARL</Link>, il faut que 3/4 des associés regroupés au sein des structures constituées antérieurement au 4 août 2005 doivent être présents. Pour celles postérieures à cette date, seuls 2/3 des associés doivent participer à la réunion.</p>
      <p>Le vote ne sera valable que si les associés présents ou représentés possèdent ¼ des parts sociales pour la première convocation et ⅕ des parts sociales pour le second. À l’issue de l’assemblée générale, les associés doivent apposer leur signature, que ce soit électroniquement ou physiquement, sur le procès-verbal récemment rédigé. <strong>Ce document servira à officialiser toutes les décisions qui viennent d’être prises</strong>, concernant la :</p>
      <ul className='list-wtl'>
        <li>Dissolution anticipée de la société ;</li>
        <li>Fin des fonctions du dirigeant ;</li>
        <li>Nomination du liquidateur ;</li>
        <li>Portée des fonctions du liquidateur.</li>
      </ul>
      <p className='infoContent'><strong>Bon à savoir :</strong> la durée de son mandat, de trois ans au maximum, lui permet d’achever les opérations de <Link className='linkContent' to='#'>liquidation</Link>.</p>
      <h6>Rédaction d’une annonce légale</h6>
      <p>L’annonce légale présente l’avantage de <strong>rendre la décision de dissolution d’une entreprise en ligne opposable aux tiers</strong>. Cette publication nécessite de choisir un site d’annonces légales fiable. Pour être valable, elle doit comporter certaines mentions obligatoires :</p>
      <ul className='list-wtl'>
        <li>L’organe possédant la compétence pour entériner la décision de dissolution de la société;</li>
        <li>La dénomination sociale et la forme juridique de l’entreprise;</li>
        <li>L’adresse du siège et le montant du capital social;</li>
        <li>Le numéro SIREN ;</li>
        <li>La date de la prise de décision ;</li>
        <li>La ville du greffe d’immatriculation.</li>
      </ul>
      
      <h3 id='5'>Comment s’effectuent les opérations de liquidation de l’entreprise?</h3>
      <p>Le liquidateur est chargé des travaux consistant à réaliser l’actif et épurer le passif, tout en rendant compte aux associés. Concrètement, il est tenu de réaliser plusieurs types de formalités essentielles. Elles commencent par la <strong>convocation de l’ensemble des associés à une assemblée générale</strong>. Celle-ci constitue une obligation à laquelle il doit se soumettre dans les 6 mois suivant sa nomination. C’est l’occasion de présenter les biens et les dettes de la société, regroupés au sein de son patrimoine. Au cours de la réunion, il pourra faire part également des opérations qu’il envisage de réaliser, accompagné du délai d’exécution.</p>
      <p>Par la suite, il devra mettre son plan à exécution, à commencer par la combinaison «actif/passif». Ensuite, le liquidateur commencera <strong>à vendre des biens de la société pour obtenir des liquidités</strong>. Ceux-ci peuvent être des :</p>
      <ul className='list-wtl'>
        <li>Immeubles ;</li>
        <li>Marchandises ;</li>
        <li>Stocks ;</li>
        <li>Propriétés industrielles (que ce soit des brevets ou des marques) ;</li>
        <li>Actions et autres obligations détenues par l’entreprise ;</li>
        <li>Machines de production.</li>
      </ul>
      <p>L’argent ainsi obtenu devra servir à <strong>s’acquitter de toutes les dettes contractées au nom et pour le compte de l’entreprise</strong>. Elles comprennent divers éléments, tels que les :</p>
      <ul className='list-wtl'>
        <li>Impositions qui restent à payer ;</li>
        <li>Dettes de nature sociale, que ce soit envers les salariés ou les institutions comme l’Urssaf ;</li>
        <li>Emprunts bancaires ;</li>
        <li>Dettes envers les fournisseurs.</li>
      </ul>
      <p>Dès que les dettes sont payées, il appartient au liquidateur de retourner les apports à leurs précédents propriétaires. Par la suite, il pourra commencer la procédure de clôture de la liquidation. Concrètement, elle <strong>consiste à établir le bilan de la liquidation et à effectuer le calcul du résultat</strong>, afin de déterminer le boni ou <Link className='linkContent' to='#'>mali de liquidation</Link>.</p>
      <p>Ces informations lui permettent de <strong>rédiger le rapport des opérations de liquidation</strong>. Il devra présenter ce document, ainsi que les comptes de la liquidation aux associés, qui ont été préalablement convoqués à une assemblée générale. Cette séance leur permet de se prononcer sur certains points essentiels. En effet, il faudra par la suite :</p>
      <ul className='list-wtl'>
        <li>Approuver les comptes et le résultat réalisés par le liquidateur ;</li>
        <li>Lui donner quitus pour le décharger de ses fonctions ;</li>
        <li>Prononcer la clôture des opérations de liquidation.</li>
      </ul>
      <p>Les associés se partageront le <Link className='linkContent' to='#'>boni de liquidation</Link> et chaque part devra tenir compte de leur taux de participation pour la constitution du capital social. Par la suite, <strong>un avis doit être publié dans le même support qui a diffusé la dissolution</strong>. Cette formalité sert à informer les tiers de l’existence de la liquidation, afin de le leur rendre opposable. L’annonce contiendra les éléments suivants :</p>
      <ul className='list-wtl'>
        <li>La date de clôture de la liquidation ;</li>
        <li>L’organe ayant entériné la décision ;</li>
        <li>La dénomination sociale et la forme juridique de l’entreprise;</li>
        <li>L’adresse du siège social ;</li>
        <li>Le montant du capital;</li>
        <li>Le SIREN ;</li>
        <li>La ville du greffe du lieu d’immatriculation de la société.</li>
      </ul>
      <p>Après l’annonce légale, la société ne pourra définitivement être fermée que si la personnalité morale, obtenue lors de sa création, disparait. Cela <strong>n’est possible qu’avec la radiation du registre du commerce</strong> et des sociétés. Il suffit d’en effectuer la demande en ligne, à partir de la plateforme numérique de l’INPI.</p>
      <p>Le représentant de la société doit y créer un compte avant de pouvoir remplir en ligne le <Link className='linkContent' to='#'>formulaire M4</Link>. Avant d’envoyer le dossier, il faudra y ajouter des <strong>versions numériques des pièces justificatives</strong> suivantes :</p>
      <ul className='list-wtl'>
        <li>Le PV de liquidation de l’entreprise;</li>
        <li>Une copie du bilan de liquidation, certifiée conforme par le liquidateur;</li>
        <li>Une attestation prouvant la parution de l’avis dans un support d’annonce légale.</li>
      </ul>
      <p className='list-wtl'><strong>Bon à savoir :</strong> pour s’acquitter des frais de dossier, le représentant légal peut effectuer un paiement à partir d’une carte bancaire.</p>
      
      <h3 id='6'>À combien s’élèvent les frais de fermeture d’une entreprise en ligne?</h3>
      <p>Pour réaliser une opération de dissolution d’entreprise en ligne, il est essentiel de prévoir quelques dépenses. Il convient de savoir que la <strong>diffusion de l’avis dans un SAL coûte entre 150 euros et 200 euros</strong>.</p>
      <p>Quant aux droits d’enregistrement lors de la liquidation, ils s’élèvent à 2,5 % du boni de liquidation. La déclaration de radiation, elle, coûte 13,93 euros. Par ailleurs, les <strong>frais de greffe</strong> sont fixés à :</p>
      <ul className='list-wtl'>
        <li>76,01 euros pour les SASU et les EURL ;</li>
        <li>192,01 euros pour les SARL, SAS et <Link className='linkContent' to='#'>SCI</Link>.</li>
      </ul>
      
      <h3 id='7'>FAQ</h3>
      <h6>Quelles sont les étapes à suivre pour radier une entreprise en ligne ?</h6>
      <p>Le représentant de l’entreprise en question doit créer un compte sur le site spécialisé de l&apos;INPI et payer les droits pour accomplir la démarche. Avant de fournir un certain nombre de pièces justificatives au format PDF, il sera tenu de renseigner un formulaire M4. Ensuite, il pourra effectuer le paiement des frais de fermeture par carte bancaire.</p>
      <h6>Combien de temps dure la fermeture d’une entreprise individuelle ?</h6>
      <p>La durée de la fermeture d’une entreprise individuelle (EI) varie en fonction de la nature de l’activité. Par exemple, il faut 5 jours pour fermer une EI commerciale et 15 jours pour une EI libérale. Par ailleurs, la démarche dure entre 30 jours et 45 jours pour une EI menant une activité artisanale.</p>
      <h6>Pourquoi opter pour une mise en sommeil plutôt qu’une dissolution d’entreprise ?</h6>
      <p>Le dirigeant ou le président peut choisir de mettre une entreprise en sommeil s’il souhaite cesser ses activités de manière temporaire. En effet, la tenue d’une AG n’est pas nécessaire pour prendre une telle décision, sauf si les statuts prévoient le contraire. Cette dernière revient généralement au représentant légal d’une société.</p>
    </div>
  );
}