import React, { useState } from 'react';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import {ReactComponent as Cercle} from '../../../../assets/icons/cercle.svg';
import imageVideo from '../../../../assets/images/videoLandingPage.png';
import imageLanding1 from '../../../../assets/images/ImageLanding-1.png';
import imageLanding2 from '../../../../assets/images/ImageLanding-2.png';
import './pourquoiLegalStation.css';

export default function PourquoiLegalStation() {
  const [play, setPlay] = useState(false);
  return (
    <section className='pourquoiLegalStationContainer'>
      <div className='pourquoiLegalStation'>
        <div className='pourquoiLegalStationText'>
          <h1>Pourquoi choisir Legalstation pour votre entreprise?</h1>
          <p>Les avantages de nos offres font passer la satisfaction de notre chère clientèle au niveau supérieur</p>
          <ul>
            <li>
              <span className='cercle'><Cercle /></span>
              <span className='li-text'>Simple et rapide</span>
            </li>
            <li>
              <span className='cercle'><Cercle /></span>
              <span className='li-text'>Des prix imbattables</span>
            </li>
            <li>
              <span className='cercle'><Cercle /></span>
              <span className='li-text'>Un service de qualité</span>
            </li>
            <li>
              <span className='cercle'><Cercle /></span>
              <span className='li-text'>Une offre personnalisée</span>
            </li>
          </ul>
        </div>
        <div className='pourquoiLegalStationMedia'>
          <div
            className='media imageVideo'
            onClick={() => setPlay(true)}
          >
            <PlayCircleOutlineIcon className='iconPlay' style={{ fontSize: 45 }}/>
            <img src={imageVideo} />
          </div>
          {
            play && (
              <div className='videoContainer' onClick={() => setPlay(false)}>
                <ClearIcon className='deleteIcon' />
                <iframe 
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/XHOmBV4js_E?si=7wNiW3QnOOPqmhxR"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen>
                </iframe>
              </div>
            )
          }
          <div className='imageLandingContainer'>
            <img className='media' src={imageLanding1} />
            <img className='media' src={imageLanding2} />
          </div>
        </div>
      </div>
    </section>
  );
}
