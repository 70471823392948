import React from 'react';
import { Link } from 'react-router-dom';
import ContratContentSqueleton from '../../components/contratContentSqueleton/contratContentSqueleton';
import './bailLocationMeublee.css';

export default function BailLocationMeublee() {
  const itemsContent = [
    {title: 'Modèle gratuit de contrat de location meublée', link: '#1'},
    {title: 'Comment rédiger un contrat de location meublée ?', link: '#2'},
    {title: 'Un dépôt de garantie est-il obligatoire ?', link: '#3'},
    {title: 'Dans quels cas peut-on demander une caution ?', link: '#4'},
    {title: 'A quoi sert l’état des lieux d’une location meublée ?', link: '#5'},
    {title: 'FAQ', link: '#faq'},
  ];
  return (
    <ContratContentSqueleton
      link='form'
      title='Bail de location meublée'
      subTitle='Contrat de location meublée'
      titleLeftContent=''
      textButton='Remplir mon bail de location'
      titleContent='A propos du contrat de location meublée'
      rightContent={<BailLocationMeubleeContent />}
      itemsContent={itemsContent}
    />
  );
}

function BailLocationMeubleeContent() {
  return (
    <div className='bailLocationMeubleeContent'>
      <h3 id='1'>Modèle gratuit de contrat de location meublée</h3>

      <p>L’établissement d’un contrat de location est nécessaire pour la location d’un logement. LegalPlace met à votre disposition un <strong>modèle de bail de location meublée</strong>. Pour cela, il vous suffit de répondre à notre questionnaire afin d’obtenir un modèle conforme à la législation en vigueur.</p>

      <p>Notre modèle de contrat de bail vous permet ainsi de <strong>gagner du temps</strong> et de disposer d’un document conforme. Lorsque le contrat est généré, vous pouvez le télécharger au format Word ou PDF puis l’imprimer.</p>

      <h3 id='2'>Comment rédiger un contrat de location meublée ?</h3>
    
      <p>En tant que propriétaire d’un logement meublée, vous avez la possibilité de louer votre bien à un locataire dans le cadre d’une résidence principale ou secondaire. Dans ce cas, il est nécessaire d’établir un contrat de bail meublé.</p>

      <p>Lors de la rédaction de celui-ci, il est important de veiller au respect de certaines règles spécifiques pour la location meublée : conditions de forme, durée, montant et clauses.</p>

      <h6>La forme du contrat de bail</h6>

      <p>Le contrat de bail doit être établi sous forme d’acte authentique ou d’un <strong>acte sous signature privée</strong>. Il doit être rédigé en autant d’exemplaires qu’il y a de parties (propriétaire, locataire, caution, etc.).</p>

      <p>Par ailleurs, depuis le <strong>1er mai 2015</strong>, les contrats de location pour les logements meublés doivent être conformes au bail type prévu par le décret du 29 mai 2015.</p>

      <p>En outre, le propriétaire peut <strong>prévoir différentes clauses</strong> dans le contrat à condition que celles-ci ne soient pas interdites.</p>

      <p className='infoContentBlue'><strong>A noter :</strong> Certains logements comme les HLM, les logements de fonctions ou <Link className='linkContent' to='#'>locations saisonnières</Link> en sont dispensés.</p>
      
      <h6>Le montant du loyer</h6>

      <p>Le montant du loyer fait partie des informations obligatoires qui doivent être mentionnées dans le contrat de location meublée. Le montant du loyer varie selon que le <strong>logement se situe en zone tendue ou non</strong>. En effet, il existe une réglementation spécifique pour les <Link className='linkContent' to='#'>loyers en zone tendue</Link>.</p>

      <p>En cas de location d’un logement en zone tendue, le montant du loyer est libre lorsqu’il s’agit d’une première location ou d’une relocation après 18 mois de vacance. Toutefois, en cas de relocation, les hausses de loyers sont réglementées.</p>

      <p>Par ailleurs, dans les <strong>zones « très tendues »</strong> (Paris, Lyon et Lille), le loyer est très réglementé. En effet, le montant ne peut pas excéder le loyer de référence majoré. Ce montant est fixé par la préfecture et dépend de différents critères (superficie, quartier, travaux réalisés dans le logement, etc.).</p>

      <h6>La durée du bail</h6>

      <p>Le contrat de location doit également mentionner la <Link className='linkContent' to='#'>durée du bail meublé</Link>. La <strong>durée minimale du bail est de 1 an</strong> renouvelable. En cas de location étudiante, la durée minimale du bail est de <strong>9 mois</strong>.</p>

      <p>Cependant, en cas de location meublée d’une durée de 9 mois, le renouvellement est possible. En effet, le renouvellement prend fin automatiquement à l’issue de cette période.</p>

      <p>Pour prolonger le contrat de location, il est nécessaire de conclure un nouveau contrat de bail.</p>

      <h6>Les clauses obligatoires</h6>

      <p>Le contrat de bail doit contenir les <strong>informations relatives au locataire et au propriétaire</strong> telles que :</p>

      <ul style={{'list-style-type': 'disc', 'marginLeft': '40px'}}>
        <li>Nom et domicile du propriétaire ;</li>
        <li>Nom du ou des locataires ;</li>
        <li>Date de prise d’effet et durée du bail.</li>
      </ul>

      <p>En outre, le contrat de bail doit mentionner les informations suivantes :</p>

      <ul style={{'list-style-type': 'disc', 'marginLeft': '40px'}}>
        <li>Consistance du logement et sa destination ;</li>
        <li>Description du logement ;</li>
        <li>Surface habitable du logement.</li>
      </ul>

      <p>D’autres informations liées au loyer doivent être mentionnées :</p>

      <ul style={{'list-style-type': 'disc', 'marginLeft': '40px'}}>
        <li>Le montant du loyer et ses modalités de paiement ;</li>
        <li>Les règles de révisions du loyer ;</li>
        <li>Modalités de récupération des charges ;</li>
        <li>Montant du dépôt de garantie s’il est prévu.</li>
      </ul><br/>

      <h6>Les clauses interdites</h6>

      <p>Par ailleurs, le contrat de bail ne doit pas comporter de clauses interdites. On considère que ces <Link className='linkContent' to='#'>clauses sont réputées non écrites</Link>.</p>

      <p>Voici quelques clauses interdites :</p>

      <ul style={{'list-style-type': 'disc', 'marginLeft': '40px'}}>
        <li>Concernant l’objet du bail, est interdite la clause qui autorise le propriétaire à diminuer ou à supprimer sans contrepartie équivalente, des prestations prévues au bail ;</li>
        <li>A propos des sommes à payer par le locataire, est considérée comme non écrite la clause qui oblige le locataire à payer des frais autres que le loyer et charges locatives ;</li>
        <li>Concernant la responsabilité des dégradations, est interdite la clause qui interdit au locataire de rechercher la responsabilité du propriétaire.</li>
      </ul><br/>
      
      <h3 id='3'>Un dépôt de garantie est-il obligatoire ?</h3>

      <p>Lorsque vous louez votre logement, vous avez la possibilité de demander à votre locataire un <Link className='linkContent' to='#'>dépôt de garantie</Link>. Celui-ci permet de couvrir d’éventuels manquements du locataire à ses obligations (loyer impayé, dégradation des lieux, etc.).</p>

      <p>Dans le cadre d’une location meublée, le montant du dépôt de garantie doit respecter les conditions suivantes :</p>

      <ul style={{'list-style-type': 'disc', 'marginLeft': '40px'}}>
        <li>Le montant ne doit pas dépasser 2 mois de loyer hors charges ;</li>
        <li>Le montant doit être fixe et ne peut être augmenté en cas de renouvellement.</li>
      </ul>
      
      <p>Le dépôt de garantie est restitué au locataire à la fin du contrat de bail lorsque l’état des lieux de sortie est conforme à l’état d’entrée des lieux. Le propriétaire dispose d’un délai de 1 mois maximum pour restituer la somme.</p>

      <p>Toutefois, si l’état des lieux de sortie n’est pas conforme, il est possible que le montant restitué au locataire soit inférieur notamment en cas de dégradation des lieux. Dans ce cas, le propriétaire dispose d’un délai de 2 mois maximum pour restituer le dépôt de garantie.</p>

      <h3 id='4'>Dans quels cas peut-on demander une caution ?</h3>

      <p>Le propriétaire peut également prévoir une caution dans le bail d’habitation. Il peut s’agir d’une personne physique ou morale qui s’engage à payer les dettes en cas de non-paiement du loyer ou des charges par le locataire.</p>

      <p>Par ailleurs, le propriétaire particulier qui dispose déjà d’une assurance privée contre les risques locatifs n’est autorisé à demander une caution uniquement si le locataire est étudiant ou apprenti.</p>

      <p>L’individu qui se porte caution doit vérifier que certains éléments apparaissent dans l’acte de cautionnement :</p>

      <ul style={{'list-style-type': 'disc', 'marginLeft': '40px'}}>
        <li>Le montant du loyer et les conditions de sa révision ;</li>
        <li>Une mention exprimant la nature et l’étendue de son engagement.</li>
      </ul><br/>
      
      <h3 id='5'>A quoi sert l’état des lieux d’une location meublée ?</h3>
      
      <p>L’état des lieux permet au locataire et au propriétaire de constater l’état du logement avant et après la location. Celui-ci est établi dans un document qui doit être joint au contrat de bail.</p>

      <p>Ce document doit donc décrire l’état du logement et des équipements qu’il comporte. L’état des lieux doit être rédigé par écrit en 2 exemplaires.</p>

      <p>Lors de l’état des lieux de sortie, si le propriétaire constate une dégradation, il peut garder une partie du dépôt de garantie.</p>

      <p className='infoContent'><strong>Bon à savoir :</strong> Il est possible de faire appel à un professionnel pour rédiger l’état des lieux. A l’entrée, les frais seront partagés entre le locataire et le propriétaire. A la sortie, les frais sont à la charge du propriétaire.</p>

      <p>En cas d’état des lieux contradictoire, les parties peuvent faire appel à un commissaire de justice.</p>

      <h3 id='faq'>FAQ</h3><br/>

      <div className='faq'>
        <h5>Comment résilier un contrat de location meublée ?</h5>
        <p>Comme pour le bail vide, le locataire d’un logement meublé peut résilier son contrat à tout moment, en respectant un préavis d’un mois. Il doit pour cela rédiger, à l’intention de son bailleur, une lettre donnant congé. Il est possible de la lui remettre en main propre contre récépissé ou encore de lui envoyer par lettre recommandée.</p>
      </div>

      <div className='faq'>
        <h5>Quid de l’état des lieux pour un contrat de location meublée ?</h5>
        <p>L’état des lieux est indispensable pour tout logement meublé. En raison du nombre d’équipement fourni au locataire, il est en effet important de s’assurer, tant à l’entrée dans les lieux qu’à la sortie, que ce matériel n’a pas été dégradé outre mesure.</p>
      </div>

      <div className='faq'>
        <h5>Qu’est-ce qu’une clause résolutoire ?</h5>
        <p>Une clause résolutoire permet au propriétaire de résilier le bail lorsque le locataire en cas de loyer ou de charges impayés ou de non-versement du dépôt de garantie.</p>
      </div>
    </div>
  );
}