import React from 'react';

function AutoCard({title, image, subtitle, description, children}) {
  return (
    <div className='bg-white shadow-md rounded-xl flex flex-col gap-5 justify-center items-center px-16 h-[460px]'>
      <div><h2 className='text-darkblue text-xl font-medium text-balance text-center w-[200px]'>{title}</h2></div>
      <div><img className='w-[120px] h-[120px]' src={image} alt={title} /></div>
      <div><h3 className='text-darkblue text-center w-[200px]'>{subtitle}</h3></div>
      <div><p className='text-darkblue text-center text-[0.6rem] text-balance w-[200px]'>{description}</p></div>
      {children && <div>{children}</div>}
    </div>
  );
}

export default AutoCard;