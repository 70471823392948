import React from 'react';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import PresentToAllOutlinedIcon from '@mui/icons-material/PresentToAllOutlined';
import './landingFormContrat.css';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

export default function LandingFormContrat({ title, subTitle, link }) {
  return (
    <div className='landingFormContrat'>
      <div className='emptyDiv'/>
      <div className='landingFormContratContent'>
        <h3>{title}</h3>
        <h6>{subTitle}</h6>
        <div className='landingFormContratBloc'>
          <span>
            <div className='landingFormContraIconContainer'>
              <DescriptionOutlinedIcon className='landingFormContraIcon' />
            </div>
            <p className='landingFormContraItem'>Créez votre contrat</p>
          </span>
          <span>
            <div className='landingFormContraIconContainer'>
              <SaveOutlinedIcon className='landingFormContraIcon' />
            </div>
            <p className='landingFormContraItem'>Enregistrez et imprimez</p>
          </span>
          <span>
            <div className='landingFormContraIconContainer'>
              <PresentToAllOutlinedIcon className='landingFormContraIcon' />
            </div>
            <p className='landingFormContraItem'>Signez et partagez</p>
          </span>
        </div>
        <Link to={link}>
          <Button variant='contained'>Remplir ce modèle</Button>
        </Link>
      </div>
    </div>
  );
}
