import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { BlobProvider } from '@react-pdf/renderer';
import axios from 'axios';
import StepProgress from '../../../../components/stepProgress/stepProgress';
import HeaderContainer from '../../../../components/headerContainer/headerContainer';
import CustomTextInput from '../../../../components/inputs/customTextInput/customTextInput';
import getEnvironement from '../../../../environnement';
import number from '../../../../assets/icons/number.png';
import CustomSelect from '../../../../components/inputs/customSelect';
import PDFDocument from './pdfDocument';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, userSelector } from '../../../../store/userSlice';
// import ConfirmAchatPackDialog from '../../confirmAchatPackDialog';

export default function SarlForm() {
  const [currentStep, setCurrentStep] = useState(1);

  // beneficiaire
  const [typeBeneficiaire, setTypeBeneficiaire] = useState('Personne physique');
  const [nomBeneficiaire, setNomBeneficiaire] = useState('');
  const [prenomBeneficiaire, setPrenomBeneficiaire] = useState('');
  const [nationaliteBeneficiaire, setNationaliteBeneficiaire] = useState('');
  const [cinBeneficiaire, setCinBeneficiaire] = useState('');
  const [denominationBeneficiaire, setDenominationBeneficiaire] = useState('');
  const [iceBeneficiaire, setIceBeneficiaire] = useState('');
  const [rcBeneficiaire, setRcBeneficiaire] = useState('');
  const [gsmBeneficiare, setGsmBeneficiare] = useState('');
  const [emailBeneficiaire, setEmailBeneficiaire] = useState('');
  const [adresseBeneficiaire, setAdresseBeneficiaire] = useState('');
  //entreprise
  const [denomination1, setDenomination1] = useState('');
  const [sigle1, setSigle1] = useState('');
  const [denomination2, setDenomination2] = useState('');
  const [sigle2, setSigle2] = useState('');
  const [denomination3, setDenomination3] = useState('');
  const [sigle3, setSigle3] = useState('');
  const [formeJuridique, setFormeJuridique] = useState('');
  const [ville, setVille] = useState('');
  const [activiteCommercial, setActiviteCommercial] = useState('');
  // soussignés
  const [nombreSoussignes, setNombreSoussignes] = useState(1);
  const [soussignes, setSoussignes] = useState([]);
  // siege
  const [villeTribunalCommerce, setVilleTribunalCommerce] = useState('');
  const [siegeSocial, setSiegeSocial] = useState('');
  // duree de la société
  const [dureeSociete, setDureeSociete] = useState('');
  // objet social
  const [nombreObjetSocial, setNombreObjetSocial] = useState(1);
  const [objetsSocial, setObjetsSocial] = useState([]);
  // apport
  const [capitalSocial, setCapitalSocial] = useState('');
  const [nombreApports, setNombreApports] = useState(1);
  const [apports, setApports] = useState([]);
  // part social
  const [nombrePartsSocial, setNombrePartsSocial] = useState(1);
  const [partsSocial, setPartsSocial] = useState([]);
  // pack
  const [pack, setPack] = useState('');
  // email phone
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  const saveData = async (pdfBlob) => {
    const url = `${getEnvironement().API_URL}/entreprise`;
    const data = new FormData();
    data.append('user_id', user.id);
    data.append('typeBeneficiaire', typeBeneficiaire);
    data.append('nomBeneficiaire', nomBeneficiaire);
    data.append('prenomBeneficiaire', prenomBeneficiaire);
    data.append('nationaliteBeneficiaire', nationaliteBeneficiaire);
    data.append('cinBeneficiaire', cinBeneficiaire);
    data.append('denominationBeneficiaire', denominationBeneficiaire);
    data.append('iceBeneficiaire', iceBeneficiaire);
    data.append('rcBeneficiaire', rcBeneficiaire);
    data.append('gsmBeneficiare', gsmBeneficiare);
    data.append('emailBeneficiaire', emailBeneficiaire);
    data.append('adresseBeneficiaire', adresseBeneficiaire);
    data.append('denomination1', denomination1);
    data.append('sigle1', sigle1);
    data.append('denomination2', denomination2);
    data.append('sigle2', sigle2);
    data.append('denomination3', denomination3);
    data.append('sigle3', sigle3);
    data.append('formeJuridique', formeJuridique);
    data.append('ville', ville);
    data.append('activiteCommercial', activiteCommercial);
    data.append('soussignes', JSON.stringify(soussignes));
    data.append('villeTribunalCommerce', villeTribunalCommerce);
    data.append('siegeSocial', siegeSocial);
    data.append('dureeSociete', dureeSociete);
    data.append('objetsSocial', JSON.stringify(objetsSocial));
    data.append('capitalSocial', capitalSocial);
    data.append('apports', JSON.stringify(apports));
    data.append('partsSocial', JSON.stringify(partsSocial));
    data.append('email', email);
    data.append('phone', phone);
    data.append('pack', pack);
    data.append('pdf', pdfBlob, 'document.pdf');
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    try {
      await axios.post(url, data, config)
        .then((response) => {
          if (response.status === 200) {
            dispatch(setUser(response.data));
            navigate('/dashboard');
          }
        });
    } catch (error) {
      console.log('Error saving data', error);
    }
  };

  const handelEnregisterClick = (pdfBlob) => {
    saveData(pdfBlob);
  };

  const getQuestion = (currentStep, pdfBlob) => {
    switch(currentStep) {
    case 1 :
      return (
        <QBeneficiaire
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          typeBeneficiaire={typeBeneficiaire}
          setTypeBeneficiaire={setTypeBeneficiaire}
          nomBeneficiaire={nomBeneficiaire}
          setNomBeneficiaire={setNomBeneficiaire}
          prenomBeneficiaire={prenomBeneficiaire}
          setPrenomBeneficiaire={setPrenomBeneficiaire}
          nationaliteBeneficiaire={nationaliteBeneficiaire}
          setNationaliteBeneficiaire={setNationaliteBeneficiaire}
          cinBeneficiaire={cinBeneficiaire}
          setCinBeneficiaire={setCinBeneficiaire}
          denominationBeneficiaire={denominationBeneficiaire}
          setDenominationBeneficiaire={setDenominationBeneficiaire}
          iceBeneficiaire={iceBeneficiaire}
          setIceBeneficiaire={setIceBeneficiaire}
          rcBeneficiaire={rcBeneficiaire}
          setRcBeneficiaire={setRcBeneficiaire}
          gsmBeneficiare={gsmBeneficiare}
          setGsmBeneficiare={setGsmBeneficiare}
          emailBeneficiaire={emailBeneficiaire}
          setEmailBeneficiaire={setEmailBeneficiaire}
          adresseBeneficiaire={adresseBeneficiaire}
          setAdresseBeneficiaire={setAdresseBeneficiaire}
        />);
    case 2 :
      return (
        <QEntreprise
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          denomination1={denomination1}
          setDenomination1={setDenomination1}
          sigle1={sigle1}
          setSigle1={setSigle1}
          denomination2={denomination2}
          setDenomination2={setDenomination2}
          sigle2={sigle2}
          setSigle2={setSigle2}
          denomination3={denomination3}
          setDenomination3={setDenomination3}
          sigle3={sigle3}
          setSigle3={setSigle3}
          formeJuridique={formeJuridique}
          setFormeJuridique={setFormeJuridique}
          ville={ville}
          setVille={setVille}
          activiteCommercial={activiteCommercial}
          setActiviteCommercial={setActiviteCommercial}
        />
      );
    case 3 :
      return (
        <QSoussignes
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          nombreSoussignes={nombreSoussignes}
          setNombreSoussignes={setNombreSoussignes}
          soussignes={soussignes}
          setSoussignes={setSoussignes}
        />
      );
    case 4 :
      return (
        <QSiegeSocial
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          villeTribunalCommerce={villeTribunalCommerce}
          setVilleTribunalCommerce={setVilleTribunalCommerce}
          siegeSocial={siegeSocial}
          setSiegeSocial={setSiegeSocial}
        />);
    case 5 :
      return (
        <QDureeSociete
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          dureeSociete={dureeSociete}
          setDureeSociete={setDureeSociete}
        />);
    case 6 :
      return (
        <QObjetSocial
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          nombreObjetSocial={nombreObjetSocial}
          setNombreObjetSocial={setNombreObjetSocial}
          objetsSocial={objetsSocial}
          setObjetsSocial={setObjetsSocial}
        />
      );
    case 7 :
      return (
        <QApports
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          soussignes={soussignes}
          capitalSocial={capitalSocial}
          setCapitalSocial={setCapitalSocial}
          apports={apports}
          setApports={setApports}
          nombreApports={nombreApports}
          setNombreApports={setNombreApports}
        />
      );
    case 8 :
      return (
        <QPartsSocial
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          soussignes={soussignes}
          partsSocial={partsSocial}
          setPartsSocial={setPartsSocial}
          nombrePartsSocial={nombrePartsSocial}
          setNombrePartsSocial={setNombrePartsSocial}
        />
      );
    case 9 :
      return (
        <QPacks
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          pack={pack}
          setPack={setPack}
          // data={{
          //   user_id: user.id,
          //   typeBeneficiaire,
          //   nomBeneficiaire,
          //   prenomBeneficiaire,
          //   nationaliteBeneficiaire,
          //   cinBeneficiaire,
          //   denominationBeneficiaire,
          //   iceBeneficiaire,
          //   rcBeneficiaire,
          //   gsmBeneficiare,
          //   emailBeneficiaire,
          //   adresseBeneficiaire,
          //   denomination1,
          //   sigle1,
          //   denomination2,
          //   sigle2,
          //   denomination3,
          //   sigle3,
          //   formeJuridique,
          //   ville,
          //   activiteCommercial,
          //   soussignes,
          //   villeTribunalCommerce,
          //   siegeSocial,
          //   dureeSociete,
          //   objetsSocial,
          //   capitalSocial,
          //   apports,
          //   partsSocial,
          //   selectedPack: 1,
          //   packId: 1,
          //   pdfBlob
          // }}
        />
      );
    case 10:
      return (
        <QEmailTel
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          email={email}
          setEmail={setEmail}
          phone={phone}
          setPhone={setPhone}
          handelEnregisterClick={handelEnregisterClick}
          pdfBlob={pdfBlob}
        />
      );
    default :
      return (<div />);
    }
  };

  return (
    <div>
      <HeaderContainer />
      <div className='h-screen flex flex-col justify-start items-center px-10 mt-10'>
        <h3 className=''>CREATION D&apos;UNE ENTREPRISE</h3>
        <StepProgress numberSteps={10} currentStep={currentStep}/>
        <BlobProvider document={
          <PDFDocument
            denomination={'.............'}
            formeJuridique={formeJuridique}
            ville={ville}
            soussignes={soussignes}
            villeTribunalCommerce={villeTribunalCommerce}
            siegeSocial={siegeSocial}
            dureeSociete={dureeSociete}
            objetsSocial={objetsSocial}
            capitalSocial={capitalSocial}
            apports={apports}
          />
        }>
          {({ blob }) => {
            return (
              <div className='w-fit md:w-[700px]'>
                {getQuestion(currentStep, blob)}
              </div>
            );
          }}
          
        </BlobProvider>
      </div>
    </div>

  );
}

function Personne({
  title,
  setPersonne
}) {
  const [typePersonne, setTypePersonne] = useState('Personne physique');
  const [nomPersonne, setNomPersonne] = useState('');
  const [prenomPersonne, setPrenomPersonne] = useState('');
  const [nationalitePersonne, setNationalitePersonne] = useState('');
  const [villeResidencePersonne, setVilleResidencePersonne] = useState('');
  const [cinPersonne, setCinPersonne] = useState('');
  const [denominationPersonne, setDenominationPersonne] = useState('');
  const [icePersonne, setIcePersonne] = useState('');
  const [rcPersonne, setRcPersonne] = useState('');
  const [gsmPersonne, setGsmPersonne] = useState('');
  const [capitalSocialPersonne, setCapitalSocialPersonne] = useState('');
  const [formeJuridiquePersonne, setFormeJuridiquePersonne] = useState('Société à responsabilité limitée');
  const [emailPersonne, setEmailPersonne] = useState('');
  const [adressePersonne, setAdressePersonne] = useState('');

  /*
    [
      {
        type: 'personne physique',
        nom,
        prenom,
        nationalité,
        dateNaissance,
        cin,
        email,
        gsm,
        villeResidence,
        adresse
      },
      {
        type: 'personne morale',
        denomination,
        ice,
        rc,
        email,
        gsm,
        villeSciege,
        AdressSiege,
        nomRepresentant,
        PrenomRepresentant,
        cinRepresentant
      }
    ]
  */
  useEffect(() => {
    if (typePersonne == 'Personne physique') {
      setPersonne({
        type: 'Personne physique',
        nom: nomPersonne,
        prenom: prenomPersonne,
        nationalite: nationalitePersonne,
        cin: cinPersonne,
        email: emailPersonne,
        gsm: gsmPersonne,
        villeResidence: villeResidencePersonne,
        adresse: adressePersonne
      });
    } else {
      setPersonne({
        type: 'Personne morale',
        denomination: denominationPersonne,
        capitalSocial: capitalSocialPersonne,
        formeJuridique: formeJuridiquePersonne,
        ice: icePersonne,
        rc: rcPersonne,
        villeSciege: villeResidencePersonne,
        adresseSiege: adressePersonne,
        nomRepresentant: nomPersonne,
        prenomRepresentant: prenomPersonne,
        emailRepresantant: emailPersonne,
        gsmRepresentant: gsmPersonne,
        cinRepresentant: cinPersonne
      });
    }
  }, [
    typePersonne,
    nomPersonne,
    prenomPersonne,
    nationalitePersonne,
    villeResidencePersonne,
    cinPersonne,
    denominationPersonne,
    icePersonne,
    rcPersonne,
    gsmPersonne,
    emailPersonne,
    adressePersonne,
    capitalSocialPersonne,
    formeJuridiquePersonne
  ]);

  return (
    <div>
      <p className='text-base font-medium text-neutral mb-2'>{title} :</p>
      <CustomSelect
        value={typePersonne}
        setValue={setTypePersonne}
        defaultValue={'Personne physique'}
        items={[
          {label: 'Personne physique', value: 'Personne physique'},
          {label: 'Personne morale', value: 'Personne morale'},
        ]}
      />
      {
        typePersonne == 'Personne physique'
          ? (
            <>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={nomPersonne}
                  setValue={setNomPersonne}
                  placeholder='Nom' />
                <CustomTextInput
                  image={number}
                  value={prenomPersonne}
                  setValue={setPrenomPersonne}
                  placeholder='Prénom' />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={nationalitePersonne}
                  setValue={setNationalitePersonne}
                  placeholder='Nationalité'
                />
                <CustomTextInput
                  image={number}
                  value={cinPersonne}
                  setValue={setCinPersonne}
                  placeholder='CIN'
                />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={gsmPersonne}
                  setValue={setGsmPersonne}
                  placeholder='GSM'
                />
                <CustomTextInput
                  image={number}
                  value={emailPersonne}
                  setValue={setEmailPersonne}
                  placeholder='E-mail'
                />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={villeResidencePersonne}
                  setValue={setVilleResidencePersonne}
                  placeholder='Ville de résidence'
                />
                <CustomTextInput
                  image={number}
                  value={adressePersonne}
                  setValue={setAdressePersonne}
                  placeholder='Adresse de résidence'
                />
              </div>
            </>
          )
          : (
            <>
              <CustomTextInput
                image={number}
                value={denominationPersonne}
                setValue={setDenominationPersonne}
                placeholder='Dénomination commerciale'
              />
              <div className='flex flex-col md:flex-row'>
                <CustomSelect
                  value={formeJuridiquePersonne}
                  setValue={setFormeJuridiquePersonne}
                  items={[
                    {label: 'Société à responsabilité limitée', value: 'Société à responsabilité limitée'},
                    {label: 'Société à responsabilité limitée à associé unique', value: 'Société à responsabilité limitée à associé unique'},
                    {label: 'Société anonyme', value: 'Société anonyme'},
                    {label: 'Société en nom collectif', value: 'Société en nom collectif'},
                    {label: 'Société en commandite par actions', value: 'Société en commandite par actions'},
                    {label: 'Groupement d\'intérêt économique', value: 'Groupement d\'intérêt économique'},
                    {label: 'Société civile immobilière', value: 'Société civile immobilière'},
                    {label: 'Société en participation', value: 'Société en participation'},
                    {label: 'Société par actions simplifiée', value: 'Société par actions simplifiée'},
                    {label: 'Succursale d’une société étrangère', value: 'Succursale d’une société étrangère'}
                  ]}
                />
                <CustomTextInput
                  image={number}
                  type='number'
                  min={0}
                  value={capitalSocialPersonne}
                  setValue={setCapitalSocialPersonne}
                  placeholder='Montant du capital social'
                />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={icePersonne}
                  setValue={setIcePersonne}
                  placeholder={'Identifiant commun de l\'entreprise (ICE)'}
                />
                <CustomTextInput
                  image={number}
                  value={rcPersonne}
                  setValue={setRcPersonne}
                  placeholder='Numéro de registre de commerce (RC)'
                />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={villeResidencePersonne}
                  setValue={setVilleResidencePersonne}
                  placeholder='Ville du siège social'
                />
                <CustomTextInput
                  image={number}
                  value={adressePersonne}
                  setValue={setAdressePersonne}
                  placeholder='Adresse du sciège social'
                />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={nomPersonne}
                  setValue={setNomPersonne}
                  placeholder='Nom du représentant'
                />
                <CustomTextInput
                  image={number}
                  value={prenomPersonne}
                  setValue={setPrenomPersonne}
                  placeholder='Prénom du représentant'
                />
                <CustomTextInput
                  image={number}
                  value={cinPersonne}
                  setValue={setCinPersonne}
                  placeholder='CIN du représantant'
                />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={gsmPersonne}
                  setValue={setGsmPersonne}
                  placeholder='GSM du représantant'
                />
                <CustomTextInput
                  image={number}
                  value={emailPersonne}
                  setValue={setEmailPersonne}
                  placeholder='E-mail du représantant'
                />
              </div>
            </>
          )
      }
    </div>
  );
}

function range(nombre) {
  const result = [];
  for (let i = 1; i < parseInt(nombre) + 1; i++) {
    result.push(i);
  }
  return result;
}

function supprimerDerniersItems(liste, nombre) {
  return liste.slice(0, nombre);
}

function extraireNoms(liste) {
  return liste.map(item => {
    if (item.data.type === 'Personne physique') {
      return {label: `${item.data.nom} ${item.data.prenom}`, value: `${item.data.nom} ${item.data.prenom}`};
    } else if (item.data.type === 'Personne morale') {
      return {label: item.data.denomination, value: item.data.denomination};
    } else {
      return '';
    }
  });
}

//---------------Questions---------------------
function QBeneficiaire({
  currentStep,
  setCurrentStep,
  typeBeneficiaire,
  setTypeBeneficiaire,
  nomBeneficiaire,
  setNomBeneficiaire,
  prenomBeneficiaire,
  setPrenomBeneficiaire,
  nationaliteBeneficiaire,
  setNationaliteBeneficiaire,
  cinBeneficiaire,
  setCinBeneficiaire,
  denominationBeneficiaire,
  setDenominationBeneficiaire,
  iceBeneficiaire,
  setIceBeneficiaire,
  rcBeneficiaire,
  setRcBeneficiaire,
  gsmBeneficiare,
  setGsmBeneficiare,
  emailBeneficiaire,
  setEmailBeneficiaire,
  adresseBeneficiaire,
  setAdresseBeneficiaire
}) {
  return (
    <div className='w-full border-solid border-1 border-red-400 mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Bénéficiaire :</p>
      <CustomSelect
        value={typeBeneficiaire}
        setValue={setTypeBeneficiaire}
        defaultValue={'Personne physique'}
        items={[
          {label: 'Personne physique', value: 'Personne physique'},
          {label: 'Personne morale', value: 'Personne morale'},
        ]}
      />
      {
        typeBeneficiaire == 'Personne physique'
          ? (
            <>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={nomBeneficiaire}
                  setValue={setNomBeneficiaire}
                  placeholder='Nom' />
                <CustomTextInput
                  image={number}
                  value={prenomBeneficiaire}
                  setValue={setPrenomBeneficiaire}
                  placeholder='Prénom' />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={nationaliteBeneficiaire}
                  setValue={setNationaliteBeneficiaire}
                  placeholder='Nationalité' />
                <CustomTextInput
                  image={number}
                  value={cinBeneficiaire}
                  setValue={setCinBeneficiaire}
                  placeholder='CIN' />
              </div>
            </>
          )
          : (
            <>
              <CustomTextInput
                image={number}
                value={denominationBeneficiaire}
                setValue={setDenominationBeneficiaire}
                placeholder='Dénomination commerciale'
              />
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={iceBeneficiaire}
                  setValue={setIceBeneficiaire}
                  placeholder={'Identifiant commun de l\'entreprise (ICE)'}
                />
                <CustomTextInput
                  image={number}
                  value={rcBeneficiaire}
                  setValue={setRcBeneficiaire}
                  placeholder='Numéro de registre de commerce (RC)'
                />
              </div>
            </>
          )
      }
      <div className='flex flex-col md:flex-row'>
        <CustomTextInput
          image={number}
          value={gsmBeneficiare}
          setValue={setGsmBeneficiare}
          placeholder='GSM'
        />
        <CustomTextInput
          image={number}
          value={emailBeneficiaire}
          setValue={setEmailBeneficiaire}
          placeholder='E-mail'
        />
      </div>
      <CustomTextInput
        image={number}
        value={adresseBeneficiaire}
        setValue={setAdresseBeneficiaire}
        placeholder='Adresse de correspondance du Bénéficiaire'
      />
      <div className='flex gap-5 mt-5'>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
      </div>
    </div>
  );
}

function QEntreprise({
  currentStep,
  setCurrentStep,
  denomination1,
  setDenomination1,
  sigle1,
  setSigle1,
  denomination2,
  setDenomination2,
  sigle2,
  setSigle2,
  denomination3,
  setDenomination3,
  sigle3,
  setSigle3,
  formeJuridique,
  setFormeJuridique,
  ville,
  setVille,
  activiteCommercial,
  setActiviteCommercial,
}) {
  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Dénominations proposées par le Bénéficiaire :</p>
      <div className='flex flex-col md:flex-row'>
        <span className='w-2/3'>
          <CustomTextInput
            image={number}
            value={denomination1}
            setValue={setDenomination1}
            placeholder='Dénomination 1'
          />
        </span>
        <span className='w-1/3'>
          <CustomTextInput
            image={number}
            value={sigle1}
            setValue={setSigle1}
            placeholder='Sigle 1'
          />
        </span>
      </div>
      <div className='flex flex-col md:flex-row'>
        <span className='w-2/3'>
          <CustomTextInput
            image={number}
            value={denomination2}
            setValue={setDenomination2}
            placeholder='Dénomination 2'
          />
        </span>
        <span className='w-1/3'>
          <CustomTextInput
            image={number}
            value={sigle2}
            setValue={setSigle2}
            placeholder='Sigle 2'
          />
        </span>
      </div>
      <div className='flex flex-col md:flex-row'>
        <span className='w-2/3'>
          <CustomTextInput
            image={number}
            value={denomination3}
            setValue={setDenomination3}
            placeholder='Dénomination 3'
          />
        </span>
        <span className='w-1/3'>
          <CustomTextInput
            image={number}
            value={sigle3}
            setValue={setSigle3}
            placeholder='Sigle 3'
          />
        </span>
      </div>
      <p className='text-base font-medium text-neutral mt-5 mb-2'>Forme Juridique :</p>
      <CustomSelect
        value={formeJuridique}
        setValue={setFormeJuridique}
        items={[
          {label: 'Société à responsabilité limitée', value: 'Société à responsabilité limitée'},
          {label: 'Société à responsabilité limitée à associé unique', value: 'Société à responsabilité limitée à associé unique'},
          {label: 'Société anonyme', value: 'Société anonyme'},
          {label: 'Société en nom collectif', value: 'Société en nom collectif'},
          {label: 'Société en commandite par actions', value: 'Société en commandite par actions'},
          {label: 'Groupement d\'intérêt économique', value: 'Groupement d\'intérêt économique'},
          {label: 'Société civile immobilière', value: 'Société civile immobilière'},
          {label: 'Société en participation', value: 'Société en participation'},
          {label: 'Société par actions simplifiée', value: 'Société par actions simplifiée'},
          {label: 'Succursale d’une société étrangère', value: 'Succursale d’une société étrangère'}
        ]}
      />
      <p className='text-base font-medium text-neutral mt-5 mb-2'>Ville :</p>
      <CustomTextInput
        image={number}
        value={ville}
        setValue={setVille}
        placeholder='Ville'
      />
      <p className='text-base font-medium text-neutral mt-5 mb-2'>Activité commercial :</p>
      <CustomSelect
        value={activiteCommercial}
        setValue={setActiviteCommercial}
        items={[
          {value: 'A', label:	'AGRICULTURE, SYLVICULTURE ET PÊCHE'},
          {value: 'B', label:	'INDUSTRIES EXTRACTIVES'},
          {value: 'C', label:	'INDUSTRIE MANUFACTURIÈRE'},
          {value: 'D', label:	'PRODUCTION ET DISTRIBUTION D\'ÉLECTRICITÉ, DE GAZ, DE VAPEUR ET D\'AIR CONDITIONNÉ'},
          {value: 'E', label:	'PRODUCTION ET DISTRIBUTION D\'EAU ; ASSAINISSEMENT, GESTION DES DÉCHETS ET DÉPOLLUTION'},
          {value: 'F', label:	'CONSTRUCTION'},
          {value: 'G', label:	'COMMERCE ; RÉPARATION D\'AUTOMOBILES ET DE MOTOCYCLES'},
          {value: 'H', label:	'TRANSPORTS ET ENTREPOSAGE'},
          {value: 'I', label:	'HÉBERGEMENT ET RESTAURATION'},
          {value: 'J', label:	'INFORMATION ET COMMUNICATION'},
          {value: 'K', label:	'ACTIVITÉS FINANCIÈRES ET D\'ASSURANCE'},
          {value: 'L', label:	'ACTIVITÉS IMMOBILIÈRES'},
          {value: 'M', label:	'ACTIVITÉS SPÉCIALISÉES, SCIENTIFIQUES ET TECHNIQUES'},
          {value: 'N', label:	'ACTIVITÉS DE SERVICES ADMINISTRATIFS ET DE SOUTIEN'},
          {value: 'O', label:	'ADMINISTRATION PUBLIQUE'},
          {value: 'P', label:	'ENSEIGNEMENT'},
          {value: 'Q', label:	'SANTÉ HUMAINE ET ACTION SOCIALE'},
          {value: 'R', label:	'ARTS, SPECTACLES ET ACTIVITÉS RÉCRÉATIVES'},
          {value: 'S', label:	'AUTRES ACTIVITÉS DE SERVICES'},
          {value: 'T', label:	'ACTIVITÉS DES MÉNAGES EN TANT QU\'EMPLOYEURS ; ACTIVITÉS INDIFFÉRENCIÉES DES MÉNAGES EN TANT QUE PRODUCTEURS DE BIENS ET SERVICES POUR USAGE PROPRE'},
          {value: 'U', label:	'ACTIVITÉS EXTRA-TERRITORIALES'},
        ]}
      />
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
      </div>
    </div>
  );
}

function QSoussignes({
  currentStep,
  setCurrentStep,
  nombreSoussignes,
  setNombreSoussignes,
  soussignes,
  setSoussignes,
}) {
  /*
    [
      {id: 1, data: {}},
      {id: 2, data: {}},
    ]
  */
  const modifySoussignesData = (soussignes, setSoussignes, soussigneId, soussigneData) => {
    let soussignesData = soussignes;
    soussignesData[soussigneId - 1] = {id: soussigneId, data: soussigneData};
    setSoussignes(soussignesData);
  };

  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Nombre de soussignés :</p>
      <CustomTextInput
        image={number}
        type='number'
        value={nombreSoussignes}
        setValue={setNombreSoussignes}
        placeholder='Nombre de soussignés'
      />
      {range(nombreSoussignes).map((id) => {
        return (
          <Personne
            key={id}
            title={`Soussigné ${id}`}
            setPersonne={(data) => modifySoussignesData(soussignes, setSoussignes, id, data)}
          />
        );
      })}
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
      </div>
    </div>
  );
}

function QSiegeSocial({
  currentStep,
  setCurrentStep,
  siegeSocial,
  setSiegeSocial,
  villeTribunalCommerce,
  setVilleTribunalCommerce,
}) {
  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Ville du tribunal du commerce :</p>
      <CustomTextInput
        image={number}
        value={villeTribunalCommerce}
        setValue={setVilleTribunalCommerce}
        placeholder='Ville du tribunal du commerce'
      />
      <p className='text-base font-medium text-neutral mb-2'>Siège social :</p>
      <CustomTextInput
        image={number}
        value={siegeSocial}
        setValue={setSiegeSocial}
        placeholder='Siège social'
      />
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
      </div>
    </div>
  );
}

function QDureeSociete({
  currentStep,
  setCurrentStep,
  dureeSociete,
  setDureeSociete,
}) {
  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Durée de la société :</p>
      <CustomTextInput
        image={number}
        min={0}
        type='number'
        value={dureeSociete}
        setValue={setDureeSociete}
        placeholder='Durée de la société en année'
      />
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
          Continuer
        </Button>
      </div>
    </div>
  );
}

function ObjetSocial({
  id,
  setObjetsSocial,
}) {
  const [objetSocial, setObjetSocial] = useState('');

  useEffect(() => {
    setObjetsSocial(objetSocial);
  }, [objetSocial]);

  return (
    <>
      <CustomTextInput
        image={number}
        value={objetSocial}
        setValue={setObjetSocial}
        placeholder={`Objet social ${id}`}
      />
    </>
  );
}

function QObjetSocial({
  currentStep,
  setCurrentStep,
  nombreObjetSocial,
  setNombreObjetSocial,
  objetsSocial,
  setObjetsSocial,
}) {
  useEffect(() => {
    setObjetsSocial(supprimerDerniersItems(objetsSocial, nombreObjetSocial));
  }, [nombreObjetSocial]);

  const modifyObjetsSocialData = (objetsSocial, setObjetsSocial, idObjetSocial, objetSocial) => {
    let objetsSocialData = objetsSocial;
    objetsSocialData[idObjetSocial - 1] = {id: idObjetSocial, objetSocial};
    setObjetsSocial(objetsSocialData);
  };

  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Ajouter / Supprimer un objet social :</p>
      <CustomTextInput
        image={number}
        type='number'
        min={0}
        value={nombreObjetSocial}
        setValue={setNombreObjetSocial}
      />
      <p className='text-base font-medium text-neutral mb-2'>Objet social :</p>
      {range(nombreObjetSocial).map((id) => {
        return (
          <ObjetSocial
            key={id}
            id={id}
            setObjetsSocial={(objetSocial) => modifyObjetsSocialData(objetsSocial, setObjetsSocial, id, objetSocial)}
          />
        );
      })}
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
          Continuer
        </Button>
      </div>
    </div>
  );
}

function Apport({
  id,
  soussignes,
  setApports,
}) {
  const [soussigne, setSoussigne] = useState('');
  const [apport, setApport] = useState('');
  const [autreApport, setAutreApport] = useState('');

  useEffect(() => {
    setApports({
      id,
      soussigne,
      apport,
      autreApport
    });
  }, [
    soussigne,
    apport,
    autreApport
  ]);

  return (
    <>
      <p className='text-base font-medium text-neutral mb-2'>apport {id}</p>
      <div className='flex flex-col md:flex-row'>
        <CustomSelect
          value={soussigne}
          setValue={setSoussigne}
          items={extraireNoms(soussignes)}
        />
        <CustomTextInput
          image={number}
          type='number'
          value={apport}
          setValue={setApport}
          placeholder='Apport en numéraire'
        />
      </div>
      <CustomTextInput
        image={number}
        value={autreApport}
        setValue={setAutreApport}
        placeholder='Autres apports'
      />
    </>
  );
}

function QApports({
  currentStep,
  setCurrentStep,
  soussignes,
  capitalSocial,
  setCapitalSocial,
  apports,
  setApports,
  nombreApports,
  setNombreApports
}) {
  useEffect(() => {
    setApports(supprimerDerniersItems(apports, nombreApports));
  }, [nombreApports]);

  const modifyApportsData = (apports, setApports, idApport, apport) => {
    let apportsData = apports;
    apportsData[idApport - 1] = apport;
    setApports(apportsData);
  };

  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Montant du capital social :</p>
      <CustomTextInput
        image={number}
        type='number'
        min={0}
        placeholder='Montant du capital social'
        value={capitalSocial}
        setValue={setCapitalSocial}
      />
      <p className='text-base font-medium text-neutral mb-2'>Ajouter / Supprimer un apport :</p>
      <CustomTextInput
        image={number}
        type='number'
        min={0}
        value={nombreApports}
        setValue={setNombreApports}
      />
      <p className='text-base font-medium text-neutral mt-5 mb-2'>Apports :</p>
      {range(nombreApports).map((id) => {
        return (
          <Apport
            key={id}
            id={id}
            soussignes={soussignes}
            setApports={(apport) => modifyApportsData(apports, setApports, id, apport)}
          />
        );
      })}
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
          Continuer
        </Button>
      </div>
    </div>
  );
}

function PartSocial({
  id,
  soussignes,
  setPartsSocial,
}) {
  const [soussigne, setSoussigne] = useState('');
  const [partSocial, setPartSocial] = useState('');

  useEffect(() => {
    setPartsSocial({
      id,
      soussigne,
      partSocial
    });
  }, [
    soussigne,
    partSocial
  ]);

  return (
    <>
      <div className='flex flex-col md:flex-row'>
        <CustomSelect
          value={soussigne}
          setValue={setSoussigne}
          items={extraireNoms(soussignes)}
        />
        <CustomTextInput
          image={number}
          type='number'
          value={partSocial}
          setValue={setPartSocial}
          placeholder='Part Social'
        />
      </div>
    </>
  );
}

function QPartsSocial({
  currentStep,
  setCurrentStep,
  soussignes,
  partsSocial,
  setPartsSocial,
  nombrePartsSocial,
  setNombrePartsSocial,
}) {
  useEffect(() => {
    setPartsSocial(supprimerDerniersItems(partsSocial, nombrePartsSocial));
  }, [nombrePartsSocial]);

  const modifyPartsSocialData = (partsSocial, setPartsSocial, idPartSocail, partSocial) => {
    let partsSocialData = partsSocial;
    partsSocialData[idPartSocail - 1] = partSocial;
    setPartsSocial(partsSocialData);
  };

  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Ajouter / Supprimer une part social :</p>
      <CustomTextInput
        image={number}
        type='number'
        min={0}
        value={nombrePartsSocial}
        setValue={setNombrePartsSocial}
      />
      <p className='text-base font-medium text-neutral mt-5 mb-2'>Parts sociales :</p>
      {range(nombrePartsSocial).map((id) => {
        return (
          <PartSocial
            key={id}
            id={id}
            soussignes={soussignes}
            setPartsSocial={(partSocial) => modifyPartsSocialData(partsSocial, setPartsSocial, id, partSocial)}
          />
        );
      })}
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
          Continuer
        </Button>
      </div>
    </div>
  );
}

function Pack({
  name,
  items,
  price,
  selectedPack,
  onClick,
}) {
  return (
    <div
      className={`border w-[32%] p-2 cursor-pointer hover:bg-primary hover:text-white ${selectedPack === name && 'border-2 border-primary'}`}
      onClick={() => onClick(name)}
    >
      <p className='text-xl font-bold py-2'>{name}</p>
      <ul className='list-disc pl-4'>
        {items.map((item, index) => {
          return (<li key={index}>{item}</li>);
        })}
      </ul>
      <p className='text-xl font-bold py-2'>prix: {price} DH</p>
    </div>
  );
}

function QPacks({
  currentStep,
  setCurrentStep,
  pack,
  setPack,
}){
  const handleClickPack = (name) => {
    setPack(name);
    setCurrentStep(currentStep + 1);
  };
  return (
    <div>
      <p className='text-base font-medium text-neutral mb-2'>Choisir votre pack :</p>
      <div className='flex justify-between items-end'>
        <Pack
          name='Base'
          items={[
            'Le certificat négatif',
            'La rédaction et enregistrement des statuts',
            'L\'inscription à la taxe professionnelle',
            'L\'inscription au registre de commerce',
            'L\'identification fiscal',
            'Affiliation à la CNSS',
            'Annonce légale',
            'Model J de la société',
            'Adhésion au service de télédéclaration DGI',
            'Adhésion au service de DAMANCOM',
          ]}
          price={1600}
          selectedPack={pack}
          onClick={(name) => handleClickPack(name)}
        />
        <Pack
          name='Moyen'
          items={[
            '3 mois de comptabilité gratuite',
            'Le certificat négatif',
            'La rédaction et enregistrement des statuts',
            'L\'inscription à la taxe professionnelle',
            'L\'inscription au registre de commerce',
            'L\'identification fiscale',
            'Affiliation à la CNSS',
            'Annonce légale',
            'Model J de la société',
            'Adhésion au service de télédéclaration DGI',
            'Adhésion au service de DAMANCOM',
            '18 mois de domiciliation',
            'Pack Marketing initial',
            'Carte visite',
            'Logo',
            'Add on / ou pass'
          ]}
          price={9800}
          selectedPack={pack}
          onClick={(name) => handleClickPack(name)}
        />
        <Pack
          name='Accéléré'
          items={[
            '12 mois de comptabilité gratuite',
            'Le certificat négatif',
            'La rédaction et enregistrement des statuts',
            'L\'inscription à la taxe professionnelle',
            'L\'inscription au registre de commerce',
            'L\'identification fiscale',
            'Affiliation à la CNSS',
            'Annonce légale',
            'Model J de la société',
            'Adhésion au service de télédéclaration DGI',
            'Adhésion au service de DAMANCOM',
            'Cachet de la société',
            '24 mois de domiciliation',
            'Pack Marketing initial',
            'Carte visite',
            'Logo',
            'Site Web, Nom de domaine, Hébergement',
            'Perforiom : Plateforme de gestion, factures ',
            'Abonnement 12 mois : Pack Standard (2500DH/an) ',
            'Add on / ou pass '
          ]}
          price={14000}
          selectedPack={pack}
          onClick={(name) => handleClickPack(name)}
        />
      </div>
      <div className='flex gap-5 my-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
      </div>
    </div>
  );
}

function QEmailTel({
  currentStep,
  setCurrentStep,
  email,
  setEmail,
  phone,
  setPhone,
  handelEnregisterClick,
  pdfBlob
}) {
  return (
    <div>
      <div className='mb-5'>
        <p className='text-base font-medium text-neutral mb-2'>Adresse email</p>
        <CustomTextInput
          image={number}
          type='email'
          value={email}
          setValue={setEmail}
          placeholder='Email'
        />
      </div>
      <div>
        <p className='text-base font-medium text-neutral mb-2'>Numéro de téléphone</p>
        <CustomTextInput
          image={number}
          type='tel'
          value={phone}
          setValue={setPhone}
          placeholder='Numéro de téléphone'
        />
      </div>
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => handelEnregisterClick(pdfBlob)}
        >
          Enregistrer
        </Button>
      </div>
    </div>
  );
}
