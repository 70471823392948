import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import axios from 'axios';
import StepProgress from '../../../../components/stepProgress/stepProgress';
import HeaderContainer from '../../../../components/headerContainer/headerContainer';
import CustomTextInput from '../../../../components/inputs/customTextInput/customTextInput';
import getEnvironement from '../../../../environnement';
import number from '../../../../assets/icons/number.png';
import CustomSelect from '../../../../components/inputs/customSelect';
import { setUser, userSelector } from '../../../../store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import Packs from '../../packs';

export default function DissolutionForm() {
  const [currentStep, setCurrentStep] = useState(1);

  // entreprise
  const [denomination, setDenomination] = useState('');
  const [sigle, setSigle] = useState('');
  const [formeJuridique, setFormeJuridique] = useState('');
  const [rc, setRc] = useState('');
  // liquidateur
  const [liquidateur, setLiquidateur] = useState('');
  // associés
  const [nombreAssocies, setNombreAssocies] = useState(1);
  const [associes, setAssocies] = useState([]);
  //pack
  const [selectedPack, setSelectedPack] = useState();

  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  const saveData = async () => {
    const url = `${getEnvironement().API_URL}/dissolution`;
    const data = {
      user_id: user.id,
      denomination,
      sigle,
      formeJuridique,
      rc,
      liquidateur,
      associes,
      packId: selectedPack
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      await axios.post(url, data, config)
        .then((response) => {
          if (response.status === 200) {
            dispatch(setUser(response.data));
            navigate('/dashboard');
          }
        });
    } catch (error) {
      console.log('Error saving data', error);
    }
  };

  const getQuestion = (currentStep) => {
    switch(currentStep) {
    case 1 :
      return (
        <QEntreprise
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          denomination={denomination}
          setDenomination={setDenomination}
          sigle={sigle}
          setSigle={setSigle}
          formeJuridique={formeJuridique}
          setFormeJuridique={setFormeJuridique}
          rc={rc}
          setRc={setRc}
        />
      );
    case 2 :
      return (
        <QLiquidateur
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          liquidateur={liquidateur}
          setLiquidateur={setLiquidateur}
        />
      );
    case 3 :
      return (
        <QSoussignes
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          nombreSoussignes={nombreAssocies}
          setNombreSoussignes={setNombreAssocies}
          soussignes={associes}
          setSoussignes={setAssocies}
          handelEnregisterClick={saveData}
        />
      );
    case 4 :
      return (
        <Packs
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          userId={user.id}
          typePack='autres démarches'
          productType='dissolution'
          setSelectedPack={setSelectedPack}
          data={{
            user_id: user.id,
            denomination,
            sigle,
            formeJuridique,
            rc,
            liquidateur,
            associes,
            packId: selectedPack
          }}
        />
      );
    case 5:
      return (
        <div className='mb-10'>
          <p className='text-base font-medium text-neutral mb-2'>Confirmation :</p>
          <div className='flex gap-5 mt-5'>
            <Button
              color='info'
              variant='outlined'
              onClick={() => {
                setCurrentStep(currentStep - 1);
              }}
            >
              {'< Retour'}
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={saveData}
            >
              Enregistrer
            </Button>
          </div>
        </div>
      );
    default :
      return (<div />);
    }
  };

  return (
    <div>
      <HeaderContainer />
      <div className='h-screen flex flex-col justify-start items-center px-10 mt-10'>
        <h3 className=''>DISSOLUTION D&apos;UNE ENTREPRISE</h3>
        <StepProgress numberSteps={5} currentStep={currentStep}/>
        <div className='w-fit md:w-[700px]'>
          {getQuestion(currentStep)}
        </div>
      </div>
    </div>

  );
}

function range(nombre) {
  const result = [];
  for (let i = 1; i < parseInt(nombre) + 1; i++) {
    result.push(i);
  }
  return result;
}

//---------------Questions---------------------
function QEntreprise({
  currentStep,
  setCurrentStep,
  denomination,
  setDenomination,
  sigle,
  setSigle,
  formeJuridique,
  setFormeJuridique,
  rc,
  setRc
}) {
  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Dénomination :</p>
      <div className='flex flex-col md:flex-row'>
        <span className='w-2/3'>
          <CustomTextInput
            image={number}
            value={denomination}
            setValue={setDenomination}
            placeholder='Dénomination'
          />
        </span>
        <span className='w-1/3'>
          <CustomTextInput
            image={number}
            value={sigle}
            setValue={setSigle}
            placeholder='Abréviation'
          />
        </span>
      </div>
      <p className='text-base font-medium text-neutral mb-2'>Registre de commerce :</p>
      <CustomTextInput
        image={number}
        value={rc}
        setValue={setRc}
        placeholder='Numéro de registre de commerce (RC)'
      />
      <p className='text-base font-medium text-neutral mt-5 mb-2'>Forme Juridique :</p>
      <CustomSelect
        value={formeJuridique}
        setValue={setFormeJuridique}
        items={[
          {label: 'Société à responsabilité limitée', value: 'Société à responsabilité limitée'},
          {label: 'Société à responsabilité limitée à associé unique', value: 'Société à responsabilité limitée à associé unique'},
          {label: 'Société anonyme', value: 'Société anonyme'},
          {label: 'Société en nom collectif', value: 'Société en nom collectif'},
          {label: 'Société en commandite par actions', value: 'Société en commandite par actions'},
          {label: 'Groupement d\'intérêt économique', value: 'Groupement d\'intérêt économique'},
          {label: 'Société civile immobilière', value: 'Société civile immobilière'},
          {label: 'Société en participation', value: 'Société en participation'},
          {label: 'Société par actions simplifiée', value: 'Société par actions simplifiée'},
          {label: 'Succursale d’une société étrangère', value: 'Succursale d’une société étrangère'}
        ]}
      />
      <div className='flex gap-5 mt-5'>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
      </div>
    </div>
  );
}

function QLiquidateur({
  currentStep,
  setCurrentStep,
  liquidateur,
  setLiquidateur,
}) {
  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Liquidateur :</p>
      <CustomSelect
        value={liquidateur}
        setValue={setLiquidateur}
        items={[
          {label: 'Le président', value: 'Le président'},
          {label: 'L\'un des associés (hors Président)', value: 'L\'un des associés (hors Président)'},
          {label: 'Un tiers', value: 'Un tiers'},
        ]}
      />
      <p className='w-60 md:w-full px-5 py-3 my-5 bg-active rounded-xl'>
        💡 Le <strong>liquidateur</strong> est la personne chargé de la fermeture de l&apos;entreprise. Il s&apos;agit généralement du représentant légal de la société. Il a pour mission de vendre les actifs, rembourser les dettes et établir un rapport sur les opérations qu&apos;il a réalisé.
      </p>
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
          Continuer
        </Button>
      </div>
    </div>
  );
}

function Personne({
  title,
  setPersonne
}) {
  const [typePersonne, setTypePersonne] = useState('Personne physique');
  const [nomPersonne, setNomPersonne] = useState('');
  const [prenomPersonne, setPrenomPersonne] = useState('');
  const [nationalitePersonne, setNationalitePersonne] = useState('');
  const [villeResidencePersonne, setVilleResidencePersonne] = useState('');
  const [cinPersonne, setCinPersonne] = useState('');
  const [denominationPersonne, setDenominationPersonne] = useState('');
  const [icePersonne, setIcePersonne] = useState('');
  const [rcPersonne, setRcPersonne] = useState('');
  const [gsmPersonne, setGsmPersonne] = useState('');
  const [capitalSocialPersonne, setCapitalSocialPersonne] = useState('');
  const [formeJuridiquePersonne, setFormeJuridiquePersonne] = useState('Société à responsabilité limitée');
  const [emailPersonne, setEmailPersonne] = useState('');
  const [adressePersonne, setAdressePersonne] = useState('');

  /*
    [
      {
        type: 'personne physique',
        nom,
        prenom,
        nationalité,
        dateNaissance,
        cin,
        email,
        gsm,
        villeResidence,
        adresse
      },
      {
        type: 'personne morale',
        denomination,
        ice,
        rc,
        email,
        gsm,
        villeSciege,
        AdressSiege,
        nomRepresentant,
        PrenomRepresentant,
        cinRepresentant
      }
    ]
  */
  useEffect(() => {
    if (typePersonne == 'Personne physique') {
      setPersonne({
        type: 'Personne physique',
        nom: nomPersonne,
        prenom: prenomPersonne,
        nationalite: nationalitePersonne,
        cin: cinPersonne,
        email: emailPersonne,
        gsm: gsmPersonne,
        villeResidence: villeResidencePersonne,
        adresse: adressePersonne
      });
    } else {
      setPersonne({
        type: 'Personne morale',
        denomination: denominationPersonne,
        capitalSocial: capitalSocialPersonne,
        formeJuridique: formeJuridiquePersonne,
        ice: icePersonne,
        rc: rcPersonne,
        villeSciege: villeResidencePersonne,
        adresseSiege: adressePersonne,
        nomRepresentant: nomPersonne,
        prenomRepresentant: prenomPersonne,
        emailRepresantant: emailPersonne,
        gsmRepresentant: gsmPersonne,
        cinRepresentant: cinPersonne
      });
    }
  }, [
    typePersonne,
    nomPersonne,
    prenomPersonne,
    nationalitePersonne,
    villeResidencePersonne,
    cinPersonne,
    denominationPersonne,
    icePersonne,
    rcPersonne,
    gsmPersonne,
    emailPersonne,
    adressePersonne,
    capitalSocialPersonne,
    formeJuridiquePersonne
  ]);

  return (
    <div>
      <p className='text-base font-medium text-neutral mb-2'>{title} :</p>
      <CustomSelect
        value={typePersonne}
        setValue={setTypePersonne}
        defaultValue={'Personne physique'}
        items={[
          {label: 'Personne physique', value: 'Personne physique'},
          {label: 'Personne morale', value: 'Personne morale'},
        ]}
      />
      {
        typePersonne == 'Personne physique'
          ? (
            <>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={nomPersonne}
                  setValue={setNomPersonne}
                  placeholder='Nom' />
                <CustomTextInput
                  image={number}
                  value={prenomPersonne}
                  setValue={setPrenomPersonne}
                  placeholder='Prénom' />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={nationalitePersonne}
                  setValue={setNationalitePersonne}
                  placeholder='Nationalité'
                />
                <CustomTextInput
                  image={number}
                  value={cinPersonne}
                  setValue={setCinPersonne}
                  placeholder='CIN'
                />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={gsmPersonne}
                  setValue={setGsmPersonne}
                  placeholder='GSM'
                />
                <CustomTextInput
                  image={number}
                  value={emailPersonne}
                  setValue={setEmailPersonne}
                  placeholder='E-mail'
                />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={villeResidencePersonne}
                  setValue={setVilleResidencePersonne}
                  placeholder='Ville de résidence'
                />
                <CustomTextInput
                  image={number}
                  value={adressePersonne}
                  setValue={setAdressePersonne}
                  placeholder='Adresse de résidence'
                />
              </div>
            </>
          )
          : (
            <>
              <CustomTextInput
                image={number}
                value={denominationPersonne}
                setValue={setDenominationPersonne}
                placeholder='Dénomination commerciale'
              />
              <div className='flex flex-col md:flex-row'>
                <CustomSelect
                  value={formeJuridiquePersonne}
                  setValue={setFormeJuridiquePersonne}
                  items={[
                    {label: 'Société à responsabilité limitée', value: 'Société à responsabilité limitée'},
                    {label: 'Société à responsabilité limitée à associé unique', value: 'Société à responsabilité limitée à associé unique'},
                    {label: 'Société anonyme', value: 'Société anonyme'},
                    {label: 'Société en nom collectif', value: 'Société en nom collectif'},
                    {label: 'Société en commandite par actions', value: 'Société en commandite par actions'},
                    {label: 'Groupement d\'intérêt économique', value: 'Groupement d\'intérêt économique'},
                    {label: 'Société civile immobilière', value: 'Société civile immobilière'},
                    {label: 'Société en participation', value: 'Société en participation'},
                    {label: 'Société par actions simplifiée', value: 'Société par actions simplifiée'},
                    {label: 'Succursale d’une société étrangère', value: 'Succursale d’une société étrangère'}
                  ]}
                />
                <CustomTextInput
                  image={number}
                  type='number'
                  min={0}
                  value={capitalSocialPersonne}
                  setValue={setCapitalSocialPersonne}
                  placeholder='Montant du capital social'
                />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={icePersonne}
                  setValue={setIcePersonne}
                  placeholder={'Identifiant commun de l\'entreprise (ICE)'}
                />
                <CustomTextInput
                  image={number}
                  value={rcPersonne}
                  setValue={setRcPersonne}
                  placeholder='Numéro de registre de commerce (RC)'
                />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={villeResidencePersonne}
                  setValue={setVilleResidencePersonne}
                  placeholder='Ville du siège social'
                />
                <CustomTextInput
                  image={number}
                  value={adressePersonne}
                  setValue={setAdressePersonne}
                  placeholder='Adresse du sciège social'
                />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={nomPersonne}
                  setValue={setNomPersonne}
                  placeholder='Nom du représentant'
                />
                <CustomTextInput
                  image={number}
                  value={prenomPersonne}
                  setValue={setPrenomPersonne}
                  placeholder='Prénom du représentant'
                />
                <CustomTextInput
                  image={number}
                  value={cinPersonne}
                  setValue={setCinPersonne}
                  placeholder='CIN du représantant'
                />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={gsmPersonne}
                  setValue={setGsmPersonne}
                  placeholder='GSM du représantant'
                />
                <CustomTextInput
                  image={number}
                  value={emailPersonne}
                  setValue={setEmailPersonne}
                  placeholder='E-mail du représantant'
                />
              </div>
            </>
          )
      }
    </div>
  );
}

function QSoussignes({
  currentStep,
  setCurrentStep,
  nombreSoussignes,
  setNombreSoussignes,
  soussignes,
  setSoussignes,
  // handelEnregisterClick
}) {
  /*
    [
      {id: 1, data: {}},
      {id: 2, data: {}},
    ]
  */
  const modifySoussignesData = (soussignes, setSoussignes, soussigneId, soussigneData) => {
    let soussignesData = soussignes;
    soussignesData[soussigneId - 1] = {id: soussigneId, data: soussigneData};
    setSoussignes(soussignesData);
  };

  return (
    <div className='mb-10'>
      <p className='w-60 md:w-full px-5 py-3 mb-5 bg-active rounded-xl'>
        La décision de dissoudre la société doit être prise d&apos;un commun accord de l&apos;ensemble des associés de la société, qui devront tous signer la documentation juridique par signature électronique. Nous vous prions donc de renseigner l&apos;identité <strong>de tous les associés</strong>.
      </p>
      <p className='text-base font-medium text-neutral mb-2'>Nombre d&apos;associés :</p>
      <CustomTextInput
        image={number}
        type='number'
        min={1}
        value={nombreSoussignes}
        setValue={setNombreSoussignes}
        placeholder={'Nombre d\'associés'}
      />
      {range(nombreSoussignes).map((id) => {
        return (
          <Personne
            key={id}
            title={`Associé ${id}`}
            setPersonne={(data) => modifySoussignesData(soussignes, setSoussignes, id, data)}
          />
        );
      })}
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        {/* <Button
          color='primary'
          variant='contained'
          onClick={handelEnregisterClick}
        >
          Enregistrer
        </Button> */}
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
          Continuer
        </Button>
      </div>
    </div>
  );
}
