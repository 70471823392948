import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/userSlice';
import 'react-toastify/dist/ReactToastify.min.css';
import searchIcon from '../../assets/icons/search-normal.png';
import PacksTable from './components/packTable';
export default function Packs() {
  const user = useSelector(userSelector);

  const [packs, setPacks] = useState(user.packs);

  const handelChangeSearchInput = (e) => {
    setPacks(user.packs.filter(pack => pack.pivot.id.toString().startsWith(e.target.value)));
  };

  return (
    <div>
      <div className="flex items-center gap-6 py-2 mb-5">
        <div className='rounded-full flex justify-center items-center w-[3rem] h-[3rem]' style={{backgroundColor: '#3498db'}}>
          <p className='text-white'>{user.nom.charAt(0).toUpperCase()}</p>
        </div>
        <div className='font-inter font-normal font-bold text-2xl'>
          Bonjour, {user.nom}
        </div>
      </div>
      <div className='flex justify-between items-center mb-5'>
        <p className='font-poppins font-medium text-2xl'>Tous les packs</p>
      </div>
      <div className='flex w-1/3 h-10 p-2 mb-5 bg-[#FAFAFA] rounded-lg'>
        <img src={searchIcon}/>
        <input
          type='search'
          placeholder='Chercher par ID'
          onChange={(e) => handelChangeSearchInput(e)}
          className='bg-[#FAFAFA] ml-2 p-2 focus:outline-none w-full'
        />
      </div>
      <PacksTable
        header={[
          'Id_pack',
          'Titre',
          'Type',
          'Déscription',
          'Nbre max produits',
          'Prix',
          'Date',
          'Actions'
        ]}
        data={packs}
      />
    </div>
  );
}
