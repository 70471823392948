import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Header from '../../../../components/header/header';
import Footer from '../../../../components/footer/footer';
import AutoCard from './components/autoCard';
import puzzle from '../../../../assets/images/puzzle.png';
import moto from '../../../../assets/images/moto.png';
function AutoEntreprise() {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <div className='flex flex-col md:flex-row md:flex md:flex-grow md:justify-center items-center gap-10 my-10 lg:p-10'>
        <AutoCard
          title="Créez votre micro-entreprise"
          image={puzzle}
          subtitle="Votre activité est-elle artisanale ?"
          description="Exemples : VTC, boulangerie, métiers du BTP, coiffure, fabrication de vêtements, etc"
        >
          <div className='flex justify-between w-[200px] mb-5'>
            <Button
              variant='contained'
              onClick={() => navigate('/autoEntreprise/form', {state: {typeMicroEntreprise: 'artisanale'}})}
            >
              en savoir +
            </Button>
            <Button 
              variant='contained'
              onClick={() => navigate('/autoEntreprise/form', {state: {typeMicroEntreprise: 'nonArtisanale'}})}
            >
              Non
            </Button>
          </div>
          <div className='text-center'><button className='underline text-[0.8rem]'>Je ne suis pas sûr(e)</button></div>
        </AutoCard>
        <AutoCard
          title="Devenir coursier à vélo"
          image={moto}
          subtitle="On vous aide à lancer votre activité"
          description="Pareil qu’une micro-entreprise mais spécialisé pour
          les plateformes de livraisons"
        >
          <div className='mb-5'>
            <Button
              variant='contained'
              onClick={() => navigate('/autoEntreprise/form', {state: {typeMicroEntreprise: 'coursier'}})}
            >
              en savoir +
            </Button>
          </div>
          <div className='text-center'><p className='underline text-[0.8rem] text-white'>test</p></div>
        </AutoCard>
      </div>
      <Footer />
    </>

  );
}

export default AutoEntreprise;