import React from 'react';
import './headerTitles.css';

export default function HeaderTitles() {
  return (
    <section className='headerTitlesContainer'>
      <div className='headerTitles'>
        <p className='title t1'>Création d&apos;entreprise :</p>
        <p className='title t2'>Comment ça se passe ?</p>
        <p className='title t3'>
          La création d’entreprise au Maroc est processus assez rigoureux 
          et demandeur en termes de temps et d’argent. Nombreux sont les entrepreneurs 
          qui ont opté pour des services proposés par des fiduciaires afin de créer 
          leur société vue que les tarifs sont de plus en plus abordables. 
          LoremIpsum aide depuis plus de 12 ans les porteurs de projets à concrétiser 
          ambitions entrepreneuriales, en proposant des packs de création de sociétés 
          pour tous les budgets et les besoins. Il est possible pour tout entrepreneur 
          de bénéficier de nos offres en nous envoyant sa demande sur le formulaire ci-après, 
          en fixant un rendez-vous avec un expert Fidutraco ou même en utilisant notre 
          assistant en ligne pour gagner du temps.
        </p>
      </div>
    </section>
  );
}
