import React from 'react';
import { Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';

Font.register({
  family: 'abeezee',
  src: 'http://fonts.gstatic.com/s/abeezee/v9/JYPhMn-3Xw-JGuyB-fEdNA.ttf',
});

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    fontFamily: 'abeezee',
  },
  section: {
    marginBottom: 30,
  },
  heading: {
    fontSize: 20,
    fontWeight: 900,
    marginBottom: 20,
    textAlign: 'center',
  },
  subheading: {
    fontSize: 16,
    fontWeight: 900,
    marginBottom: 35,
  },
  paragraph: {
    marginBottom: 10,
    textAlign: 'justify',
    lineHeight: 1.5,
  },
  subparagraph: {
    marginBottom: 35,
    textAlign: 'center',
    lineHeight: 1,
    fontSize: 8,
  },
  listItem: {
    marginLeft: 25,
    lineHeight: 1.5,
    marginBottom: 10,
  },
  sublistItem: {
    marginLeft: 25,
    lineHeight: 1,
    marginBottom: 2.5,
  },
  italicSouligneGray: {
    textDecoration: 'underline',
    color: 'gray',
  },
  laws: {
    color: '#666',
    fontSize: 10,
  },
});

function Page6(props) {
  const {
    chooseDomaineSite,
    finalitéTraitementDonnéesPersonnellesCollectées,
    isAutreFinalitéTraitementDonnéesPersonnelles,
    autreFinalitéTraitementDonnéesPersonnelles,
    dPCC_OuvertureCompte_VenteProduits,
    autreDonnéesPersonnellesClientsCollectéesList,
    donnéesPersonnellesCollectées_Entreprise_Partenaires,
    DPCEP_isPrestataireTransport,
    DPCEP_isPrestataireEtablissementPaiement,
    DPCEP_autres,
    DPCEP_isAutres,
    responsableTraitementDonnéesPersonnelles,
    responsableTraitementDonnéesPersonnellesIdentité,
    isDPOexiste,
    DPOIdentité,
    moyensDontDisposeClientSupprimerDonnéesPersonnelles,
  } = props;
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.subheading}>ARTICLE 9 - Données personnelles</Text>
        {finalitéTraitementDonnéesPersonnellesCollectées === 'venteProduit_Livraison' ? (
          <>
            <Text style={styles.paragraph}>
              Le Client est informé que la collecte de ses données à caractère
              personnel est nécessaire à la vente des Produits et à leur
              délivrance / livraison, confiées au Vendeur. Ces données à
              caractère personnel sont récoltées uniquement pour l’exécution du
              contrat de vente.
            </Text>
          </>
        ) : (
          <>
            <Text style={styles.paragraph}>
              Le Client est informé que la collecte de ses données à caractère
              personnel est nécessaire à la vente des Produits par le Vendeur
              ainsi qu&apos;à leur transmission à des tiers à des fins de livraison
              des Produits. Ces données à caractère personnel sont récoltées
              uniquement pour l’exécution du contrat de vente.
            </Text>
          </>
        )}
        {isAutreFinalitéTraitementDonnéesPersonnelles && (
          <>
            <Text style={styles.paragraph}>
              Le Client est par ailleurs informé que la collecte de ses données
              à caractère personnel est également nécessaire au vu des finalités
              suivantes :
            </Text>
            <Text style={styles.paragraph}>
              {autreFinalitéTraitementDonnéesPersonnelles}
            </Text>
          </>
        )}
        <Text style={styles.subTitle}>
          9.1 Collecte des données à caractère personnel
        </Text>
        <Text style={styles.paragraph}>
          Les données à caractère personnel qui sont collectées sur le site
          {chooseDomaineSite || '________________'} sont les suivantes :
        </Text>
        <Text style={styles.subTitle}>Ouverture de compte</Text>
        <Text style={styles.paragraph}>
          Lors de la création du compte Client / utilisateur :{' '}
        </Text>
        {dPCC_OuvertureCompte_VenteProduits === 'basicDonnee' ? (
          <>
            <Text style={styles.paragraph}>
              Noms, prénoms, adresse postale, numéro de téléphone et adresse
              e-mail.
            </Text>
          </>
        ) : (
          <>
            <Text style={styles.paragraph}>
              {autreDonnéesPersonnellesClientsCollectéesList ||
                '_______________'}
            </Text>
          </>
        )}
        <Text style={styles.subTitle}>Paiement</Text>
        <Text style={styles.paragraph}>
          Dans le cadre du paiement des Produits proposés sur le site
          {chooseDomaineSite || '________________'}, celui-ci enregistre des
          données financières relatives au compte bancaire ou à la carte de
          crédit du Client / utilisateur.
        </Text>
        <Text style={styles.subTitle}>
          9.2 Destinataires des données à caractère personnel
        </Text>
        {donnéesPersonnellesCollectées_Entreprise_Partenaires === 'entreprise' ? (
          <>
            <Text style={styles.paragraph}>
              Les données à caractère personnel sont réservées à l’usage unique
              du Vendeur et de ses salariés.
            </Text>
          </>
        ) : (
          <>
            <Text style={styles.paragraph}>
              Les données à caractère personnel sont utilisées par le Vendeur et
              ses co-contractants pour l’exécution du contrat et pour assurer
              l’efficacité de la prestation de vente et de délivrance des
              Produits.
            </Text>
            <Text style={styles.paragraph}>
              La ou les catégorie(s) de co-contractant(s) est (sont) :
            </Text>

            {DPCEP_isPrestataireTransport && (
              <Text style={styles.listItem}>
                - Les prestataires de transport
              </Text>
            )}
            {DPCEP_isPrestataireEtablissementPaiement && (
              <Text style={styles.listItem}>
                - Les prestataires établissements de paiement
              </Text>
            )}
            {DPCEP_isAutres && (
              <Text style={styles.listItem}>
                - {DPCEP_autres || '__________________'}
              </Text>
            )}
          </>
        )}
        <Text style={styles.subTitle}>9.3 Responsable de traitement</Text>
        {responsableTraitementDonnéesPersonnelles === 'vendeur' && (
          <>
            <Text style={styles.paragraph}>
              Le responsable de traitement des données est le Vendeur, au sens
              de la Loi n° 09-08 relative à la protection des données à
              caractère personnel.
            </Text>
          </>
        )}
        {responsableTraitementDonnéesPersonnelles ===
          'vendeur_autrePersonnes' && (
          <>
            <Text style={styles.paragraph}>
              Les responsables de traitement des données, au sens de la loi
              Informatique et libertés et à compter du 25 mai 2018 du Règlement
              2016/679 sur la protection des données à caractère personnel, sont
              :
            </Text>
            <Text style={styles.listItem}>- le Vendeur, et</Text>
            <Text style={styles.listItem}>- _____________________</Text>
          </>
        )}
        {responsableTraitementDonnéesPersonnelles === 'autrePersonnes' && (
          <>
            <Text style={styles.paragraph}>
              Le(s) responsable(s) de traitement des données, au sens de la loi
              Informatique et libertés et à compter du 25 mai 2018 du Règlement
              2016/679 sur la protection des données à caractère personnel, est
              (sont) :
            </Text>
            <Text style={styles.listItem}>
              -{' '}
              {responsableTraitementDonnéesPersonnellesIdentité ||
                '_____________________'}{' '}
              .
            </Text>
          </>
        )}
        {isDPOexiste && (
          <Text style={styles.listItem}>
            {DPOIdentité || '____________________'}
          </Text>
        )}
        <Text style={styles.subTitle}>9.4 limitation du traitement</Text>
        <Text style={styles.paragraph}>
          Sauf si le Client exprime son accord exprès, ses données à caractère
          personnelles ne sont pas utilisées à des fins publicitaires ou
          marketing.
        </Text>
        <Text style={styles.subTitle}>
          9.5 Durée de conservation des données
        </Text>
        <Text style={styles.paragraph}>
          Le Vendeur conservera les données ainsi recueillies pendant un délai
          de 5 ans, couvrant le temps de la prescription de la responsabilité
          civile contractuelle applicable.
        </Text>
        <Text style={styles.subTitle}>9.6 Sécurité et confidentialité</Text>
        <Text style={styles.paragraph}>
          Le Vendeur met en œuvre des mesures organisationnelles, techniques,
          logicielles et physiques en matière de sécurité du numérique pour
          protéger les données personnelles contre les altérations, destructions
          et accès non autorisés. Toutefois il est à signaler qu’Internet n’est
          pas un environnement complètement sécurisé et le Vendeur ne peut
          garantir la sécurité de la transmission ou du stockage des
          informations sur Internet.
        </Text>
        <Text style={styles.subTitle}>
          9.7 Mise en œuvre des droits des Clients et utilisateurs
        </Text>
        <Text style={styles.paragraph}>
          En application de la règlementation applicable aux données à caractère
          personnel, les Clients et utilisateurs du site{' '}
          {chooseDomaineSite || '________________'}
          disposent des droits suivants :
        </Text>
        <Text style={styles.listItem}>
          * Ils peuvent mettre à jour ou supprimer les données qui les
          concernent de la manière suivante :
        </Text>
        <Text style={styles.paragraph}>
          {moyensDontDisposeClientSupprimerDonnéesPersonnelles ||
            '_______________'}
          .
        </Text>
        <Text style={styles.listItem}>
          * Ils peuvent supprimer leur compte en écrivant à l’adresse
          électronique indiqué à l’article 9.3 « Responsable de traitement »
        </Text>
        <Text style={styles.listItem}>
          * Ils peuvent exercer leur droit d’accès pour connaître les données
          personnelles les concernant en écrivant à l’adresse indiqué à
          l’article 9.3 « Responsable de traitement »
        </Text>
        <Text style={styles.listItem}>
          * Si les données à caractère personnel détenues par le Vendeur sont
          inexactes, ils peuvent demander la mise à jour des informations des
          informations en écrivant à l’adresse indiqué à l’article 9.3 «
          Responsable de traitement »
        </Text>
        <Text style={styles.listItem}>
          * Ils peuvent demander la suppression de leurs données à caractère
          personnel, conformément aux lois applicables en matière de protection
          des données en écrivant à l’adresse indiqué à l’article 9.3 «
          Responsable de traitement »
        </Text>
        <Text style={styles.listItem}>
          * Ils peuvent également solliciter la portabilité des données détenues
          par le Vendeur vers un autre prestataire
        </Text>
        <Text style={styles.listItem}>
          * Enfin, ils peuvent s’opposer au traitement de leurs données par le
          Vendeur
        </Text>
        <Text style={styles.paragraph}>
          Ces droits, dès lors qu’ils ne s’opposent pas à la finalité du
          traitement, peuvent être exercé en adressant une demande par courrier
          ou par E-mail au Responsable de traitement dont les coordonnées sont
          indiquées ci-dessus.
        </Text>
        <Text style={styles.paragraph}>
          Le responsable de traitement doit apporter une réponse dans un délai
          maximum d’un mois.
        </Text>
        <Text style={styles.paragraph}>
          En cas de refus de faire droit à la demande du Client, celui-ci doit
          être motivé.
        </Text>
        <Text style={styles.paragraph}>
          Le Client est informé qu’en cas de refus, il peut introduire une
          réclamation auprès de la Commission Nationale de Contrôle de la
          Protection des Données à Caractère Personnel (CNDP) ou saisir une
          autorité judiciaire.
        </Text>
        <Text style={styles.paragraph}>
          Le Client peut être invité à cocher une case au titre de laquelle il
          accepte de recevoir des mails à caractère informatifs et publicitaires
          de la part du Vendeur. Il aura toujours la possibilité de retirer son
          accord à tout moment en contactant le Vendeur (coordonnées ci-dessus)
          ou en suivant le lien de désabonnement.
        </Text>
      </View>
    </Page>
  );
}

export default Page6;
