import React from 'react';

export default function SideBarPack({
  activeTab,
  setActiveTab
}) {
  const items = [
    'Création d\'entreprise',
    'Comptabilité d\'entreprise',
    'Domiciliation d\'entreprise',
    'Compte professionnel',
    'Modification de statuts',
    'Dissolution d\'entreprise',
    'Gestion des salariés',
    'Autres services',
  ];
  return (
    <div className='bg-gradient2 w-[380px] pt-10'>
      {
        items.map((item) => {
          return (
            <p
              key={item}
              className={`font-large font-inter text-[#051259] cursor-pointer px-10 hover:text-primary my-10 ${activeTab === item && 'text-primary'}`}
              onClick={() => setActiveTab(item)}
            >
              {item}
            </p>
          );
        })
      }
    </div>
  );
}
