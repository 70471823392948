import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { BlobProvider } from '@react-pdf/renderer';
import axios from 'axios';
import StepProgress from '../../../../components/stepProgress/stepProgress';
import HeaderContainer from '../../../../components/headerContainer/headerContainer';
import CustomTextInput from '../../../../components/inputs/customTextInput/customTextInput';
import getEnvironement from '../../../../environnement';
import number from '../../../../assets/icons/number.png';
import CustomSelect from '../../../../components/inputs/customSelect';
import PDFDocument from './pdfDocument';
import { setUser, userSelector } from '../../../../store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import Packs from '../../packs';

export default function AssociationForm() {
  const [currentStep, setCurrentStep] = useState(1);

  // denomination
  const [denomination, setDenomination] = useState('');
  const [abreviation, setAbreviation] = useState('');
  // objet social
  const [nombreObjetSocial, setNombreObjetSocial] = useState(1);
  const [objetsSocial, setObjetsSocial] = useState([]);
  // siege
  const [siegeSocial, setSiegeSocial] = useState('');
  // membres fondateurs
  const [nombreMembresFondateurs, setNombreMembresFondateurs] = useState(1);
  const [MembresFondateurs, setMembresFondateurs] = useState([]);
  //pack
  const [selectedPack, setSelectedPack] = useState();

  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  const saveData = async (pdfBlob) => {
    const url = `${getEnvironement().API_URL}/associations`;
    
    const data = new FormData();
    data.append('user_id', user.id);
    data.append('denomination', denomination);
    data.append('abreviation', abreviation);
    data.append('objetsSocial', JSON.stringify(objetsSocial));
    data.append('siegeSocial', siegeSocial);
    data.append('MembresFondateurs', JSON.stringify(MembresFondateurs));
    data.append('packId', selectedPack);
    data.append('pdf', pdfBlob, 'document.pdf');
    
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    try {
      await axios.post(url, data, config)
        .then((response) => {
          if (response.status === 200) {
            dispatch(setUser(response.data));
            navigate('/dashboard');
          }
        });
    } catch (error) {
      console.log('Error saving data', error);
    }
  };

  const handelEnregisterClick = (pdfBlob) => {
    saveData(pdfBlob);
  };

  const getQuestion = (currentStep, pdfBlob) => {
    switch(currentStep) {
    case 1 :
      return (
        <QDenomination
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          denomination={denomination}
          setDenomination={setDenomination}
          abreviation={abreviation}
          setAbreviation={setAbreviation}
        />
      );
    case 2 :
      return (
        <QObjetSocial
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          nombreObjetSocial={nombreObjetSocial}
          setNombreObjetSocial={setNombreObjetSocial}
          objetsSocial={objetsSocial}
          setObjetsSocial={setObjetsSocial}
        />
      );
    case 3 :
      return (
        <QSiegeSocial
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          siegeSocial={siegeSocial}
          setSiegeSocial={setSiegeSocial}
        />
      );
    case 4 :
      return (
        <QMembresFondateurs
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          nombreMembresFondateurs={nombreMembresFondateurs}
          setNombreMembresFondateurs={setNombreMembresFondateurs}
          MembresFondateurs={MembresFondateurs}
          setMembresFondateurs={setMembresFondateurs}
          handelEnregisterClick={handelEnregisterClick}
          pdfBlob={pdfBlob}
        />
      );
    case 5 :
      return (
        <Packs
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          userId={user.id}
          typePack='entreprise'
          productType='associations'
          setSelectedPack={setSelectedPack}
          data={{
            user_id: user.id,
            denomination,
            abreviation,
            objetsSocial,
            siegeSocial,
            MembresFondateurs,
            selectedPack,
            packId: selectedPack,
            pdfBlob
          }}
        />
      );
    case 6:
      return (
        <div className='mb-10'>
          <p className='text-base font-medium text-neutral mb-2'>Confirmation :</p>
          <div className='flex gap-5 mt-5'>
            <Button
              color='info'
              variant='outlined'
              onClick={() => {
                setCurrentStep(currentStep - 1);
              }}
            >
              {'< Retour'}
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={() => handelEnregisterClick(pdfBlob)}
            >
              Enregistrer
            </Button>
          </div>
        </div>
      );
    default :
      return (<div />);
    }
  };

  return (
    <div>
      <HeaderContainer />
      <div className='h-screen flex flex-col justify-start items-center px-10 mt-10'>
        <h3 className=''>CREATION D&apos;UNE ASSOCIATION</h3>
        <StepProgress numberSteps={6} currentStep={currentStep}/>
        <BlobProvider document={
          <PDFDocument
            denomination={denomination}
            abreviation={abreviation}
            objetsSocial={objetsSocial}
            siegeSocial={siegeSocial}
            MembresFondateurs={MembresFondateurs}
          />
        }>
          {({ blob }) => {
            return (
              <div className='w-fit md:w-[700px]'>
                {getQuestion(currentStep, blob)}
              </div>
            );
          }}
          
        </BlobProvider>
      </div>
    </div>

  );
}

function Personne({
  title,
  setPersonne
}) {
  const [typePersonne, setTypePersonne] = useState('Personne physique');
  const [nomPersonne, setNomPersonne] = useState('');
  const [prenomPersonne, setPrenomPersonne] = useState('');
  const [nationalitePersonne, setNationalitePersonne] = useState('');
  const [villeResidencePersonne, setVilleResidencePersonne] = useState('');
  const [cinPersonne, setCinPersonne] = useState('');
  const [denominationPersonne, setDenominationPersonne] = useState('');
  const [icePersonne, setIcePersonne] = useState('');
  const [rcPersonne, setRcPersonne] = useState('');
  const [gsmPersonne, setGsmPersonne] = useState('');
  const [capitalSocialPersonne, setCapitalSocialPersonne] = useState('');
  const [formeJuridiquePersonne, setFormeJuridiquePersonne] = useState('Société à responsabilité limitée');
  const [emailPersonne, setEmailPersonne] = useState('');
  const [adressePersonne, setAdressePersonne] = useState('');

  /*
    [
      {
        type: 'personne physique',
        nom,
        prenom,
        nationalité,
        dateNaissance,
        cin,
        email,
        gsm,
        villeResidence,
        adresse
      },
      {
        type: 'personne morale',
        denomination,
        ice,
        rc,
        email,
        gsm,
        villeSciege,
        AdressSiege,
        nomRepresentant,
        PrenomRepresentant,
        cinRepresentant
      }
    ]
  */
  useEffect(() => {
    if (typePersonne == 'Personne physique') {
      setPersonne({
        type: 'Personne physique',
        nom: nomPersonne,
        prenom: prenomPersonne,
        nationalite: nationalitePersonne,
        cin: cinPersonne,
        email: emailPersonne,
        gsm: gsmPersonne,
        villeResidence: villeResidencePersonne,
        adresse: adressePersonne
      });
    } else {
      setPersonne({
        type: 'Personne morale',
        denomination: denominationPersonne,
        capitalSocial: capitalSocialPersonne,
        formeJuridique: formeJuridiquePersonne,
        ice: icePersonne,
        rc: rcPersonne,
        villeSciege: villeResidencePersonne,
        adresseSiege: adressePersonne,
        nomRepresentant: nomPersonne,
        prenomRepresentant: prenomPersonne,
        emailRepresantant: emailPersonne,
        gsmRepresentant: gsmPersonne,
        cinRepresentant: cinPersonne
      });
    }
  }, [
    typePersonne,
    nomPersonne,
    prenomPersonne,
    nationalitePersonne,
    villeResidencePersonne,
    cinPersonne,
    denominationPersonne,
    icePersonne,
    rcPersonne,
    gsmPersonne,
    emailPersonne,
    adressePersonne,
    capitalSocialPersonne,
    formeJuridiquePersonne
  ]);

  return (
    <div>
      <p className='text-base font-medium text-neutral mb-2'>{title} :</p>
      <CustomSelect
        value={typePersonne}
        setValue={setTypePersonne}
        defaultValue={'Personne physique'}
        items={[
          {label: 'Personne physique', value: 'Personne physique'},
          {label: 'Personne morale', value: 'Personne morale'},
        ]}
      />
      {
        typePersonne == 'Personne physique'
          ? (
            <>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={nomPersonne}
                  setValue={setNomPersonne}
                  placeholder='Nom' />
                <CustomTextInput
                  image={number}
                  value={prenomPersonne}
                  setValue={setPrenomPersonne}
                  placeholder='Prénom' />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={nationalitePersonne}
                  setValue={setNationalitePersonne}
                  placeholder='Nationalité'
                />
                <CustomTextInput
                  image={number}
                  value={cinPersonne}
                  setValue={setCinPersonne}
                  placeholder='CIN'
                />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={gsmPersonne}
                  setValue={setGsmPersonne}
                  placeholder='GSM'
                />
                <CustomTextInput
                  image={number}
                  value={emailPersonne}
                  setValue={setEmailPersonne}
                  placeholder='E-mail'
                />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={villeResidencePersonne}
                  setValue={setVilleResidencePersonne}
                  placeholder='Ville de résidence'
                />
                <CustomTextInput
                  image={number}
                  value={adressePersonne}
                  setValue={setAdressePersonne}
                  placeholder='Adresse de résidence'
                />
              </div>
            </>
          )
          : (
            <>
              <CustomTextInput
                image={number}
                value={denominationPersonne}
                setValue={setDenominationPersonne}
                placeholder='Dénomination commerciale'
              />
              <div className='flex flex-col md:flex-row'>
                <CustomSelect
                  value={formeJuridiquePersonne}
                  setValue={setFormeJuridiquePersonne}
                  items={[
                    {label: 'Société à responsabilité limitée', value: 'Société à responsabilité limitée'},
                    {label: 'Société à responsabilité limitée à associé unique', value: 'Société à responsabilité limitée à associé unique'},
                    {label: 'Société anonyme', value: 'Société anonyme'},
                    {label: 'Société en nom collectif', value: 'Société en nom collectif'},
                    {label: 'Société en commandite par actions', value: 'Société en commandite par actions'},
                    {label: 'Groupement d\'intérêt économique', value: 'Groupement d\'intérêt économique'},
                    {label: 'Société civile immobilière', value: 'Société civile immobilière'},
                    {label: 'Société en participation', value: 'Société en participation'},
                    {label: 'Société par actions simplifiée', value: 'Société par actions simplifiée'},
                    {label: 'Succursale d’une société étrangère', value: 'Succursale d’une société étrangère'}
                  ]}
                />
                <CustomTextInput
                  image={number}
                  type='number'
                  min={0}
                  value={capitalSocialPersonne}
                  setValue={setCapitalSocialPersonne}
                  placeholder='Montant du capital social'
                />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={icePersonne}
                  setValue={setIcePersonne}
                  placeholder={'Identifiant commun de l\'entreprise (ICE)'}
                />
                <CustomTextInput
                  image={number}
                  value={rcPersonne}
                  setValue={setRcPersonne}
                  placeholder='Numéro de registre de commerce (RC)'
                />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={villeResidencePersonne}
                  setValue={setVilleResidencePersonne}
                  placeholder='Ville du siège social'
                />
                <CustomTextInput
                  image={number}
                  value={adressePersonne}
                  setValue={setAdressePersonne}
                  placeholder='Adresse du sciège social'
                />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={nomPersonne}
                  setValue={setNomPersonne}
                  placeholder='Nom du représentant'
                />
                <CustomTextInput
                  image={number}
                  value={prenomPersonne}
                  setValue={setPrenomPersonne}
                  placeholder='Prénom du représentant'
                />
                <CustomTextInput
                  image={number}
                  value={cinPersonne}
                  setValue={setCinPersonne}
                  placeholder='CIN du représantant'
                />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={gsmPersonne}
                  setValue={setGsmPersonne}
                  placeholder='GSM du représantant'
                />
                <CustomTextInput
                  image={number}
                  value={emailPersonne}
                  setValue={setEmailPersonne}
                  placeholder='E-mail du représantant'
                />
              </div>
            </>
          )
      }
    </div>
  );
}

function range(nombre) {
  const result = [];
  for (let i = 1; i < parseInt(nombre) + 1; i++) {
    result.push(i);
  }
  return result;
}

function supprimerDerniersItems(liste, nombre) {
  return liste.slice(0, nombre);
}

// function extraireNoms(liste) {
//   return liste.map(item => {
//     console.log(item.data.type === 'Personne physique');
//     if (item.data.type === 'Personne physique') {
//       return {label: `${item.data.nom} ${item.data.prenom}`, value: `${item.data.nom} ${item.data.prenom}`};
//     } else if (item.data.type === 'Personne morale') {
//       return {label: item.data.denomination, value: item.data.denomination};
//     } else {
//       return '';
//     }
//   });
// }

//---------------Questions---------------------
function QDenomination({
  currentStep,
  setCurrentStep,
  denomination,
  setDenomination,
  abreviation,
  setAbreviation,
}) {
  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Dénomination :</p>
      <div className='flex justify-between flex-col md:flex-row'>
        <div className='w-3/4'>
          <CustomTextInput
            image={number}
            value={denomination}
            setValue={setDenomination}
            placeholder={'Dénomination de l\'association'}
          />
        </div>
        <div className='w-1/4'>
          <CustomTextInput
            image={number}
            value={abreviation}
            setValue={setAbreviation}
            placeholder={'Abréviation'}
          />
        </div>
      </div>
      <div className='flex gap-5 mt-5'>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
      </div>
    </div>
  );
}

function ObjetSocial({
  id,
  setObjetsSocial,
}) {
  const [objetSocial, setObjetSocial] = useState('');

  useEffect(() => {
    setObjetsSocial(objetSocial);
  }, [objetSocial]);

  return (
    <>
      <CustomTextInput
        image={number}
        value={objetSocial}
        setValue={setObjetSocial}
        placeholder={`Objet social ${id}`}
      />
    </>
  );
}

function QObjetSocial({
  currentStep,
  setCurrentStep,
  nombreObjetSocial,
  setNombreObjetSocial,
  objetsSocial,
  setObjetsSocial,
}) {
  useEffect(() => {
    setObjetsSocial(supprimerDerniersItems(objetsSocial, nombreObjetSocial));
  }, [nombreObjetSocial]);

  const modifyObjetsSocialData = (objetsSocial, setObjetsSocial, idObjetSocial, objetSocial) => {
    let objetsSocialData = objetsSocial;
    objetsSocialData[idObjetSocial - 1] = {id: idObjetSocial, objetSocial};
    setObjetsSocial(objetsSocialData);
  };

  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Ajouter / Supprimer un objet social :</p>
      <CustomTextInput
        image={number}
        type='number'
        min={0}
        value={nombreObjetSocial}
        setValue={setNombreObjetSocial}
      />
      <p className='text-base font-medium text-neutral mb-2'>Objet social :</p>
      {range(nombreObjetSocial).map((id) => {
        return (
          <ObjetSocial
            key={id}
            id={id}
            setObjetsSocial={(objetSocial) => modifyObjetsSocialData(objetsSocial, setObjetsSocial, id, objetSocial)}
          />
        );
      })}
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
          Continuer
        </Button>
      </div>
    </div>
  );
}

function QSiegeSocial({
  currentStep,
  setCurrentStep,
  siegeSocial,
  setSiegeSocial,
}) {
  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Siège social :</p>
      <CustomTextInput
        image={number}
        value={siegeSocial}
        setValue={setSiegeSocial}
        placeholder='Siège social'
      />
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
      </div>
    </div>
  );
}

function QMembresFondateurs({
  currentStep,
  setCurrentStep,
  nombreMembresFondateurs,
  setNombreMembresFondateurs,
  MembresFondateurs,
  setMembresFondateurs,
  // handelEnregisterClick,
  // pdfBlob
}) {
  /*
    [
      {id: 1, data: {}},
      {id: 2, data: {}},
    ]
  */
  const modifyMembresFondateursData = (MembresFondateurs, setMembresFondateurs, MembreFondateurId, MembreFondateurData) => {
    let MembresFondateursData = MembresFondateurs;
    MembresFondateursData[MembreFondateurId - 1] = {id: MembreFondateurId, data: MembreFondateurData};
    setMembresFondateurs(MembresFondateursData);
  };

  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Nombre de fondateurs :</p>
      <CustomTextInput
        image={number}
        type='number'
        min={1}
        value={nombreMembresFondateurs}
        setValue={setNombreMembresFondateurs}
        placeholder='Nombre de membres fondateurs'
      />
      {range(nombreMembresFondateurs).map((id) => {
        return (
          <Personne
            key={id}
            title={`Fondateur ${id}`}
            setPersonne={(data) => modifyMembresFondateursData(MembresFondateurs, setMembresFondateurs, id, data)}
          />
        );
      })}
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        {/* <Button
          color='primary'
          variant='contained'
          onClick={() => handelEnregisterClick(pdfBlob)}
        >
          Enregistrer
        </Button> */}
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
          Continuer
        </Button>
      </div>
    </div>
  );
}
