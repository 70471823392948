import React from 'react';
import './demarcheCreation.css';
import user from '../../../../assets/icons/user.svg';
import sac from '../../../../assets/icons/sac.svg';
import coeur from '../../../../assets/icons/coeur.svg';
import process from '../../../../assets/images/Procces.png';

function BlockIcon({ icon, title }) {
  return (
    <div className='blockIcon'>
      <img src={icon} />
      <p>{title}</p>
    </div>
  );
}


export default function DemarcheCreation() {
  return (
    <section className='demarcheCreationContainer'>
      <div className='demarcheCreation'>
        <h1>Démarches de création</h1>
        <div className='blockIconContainer'>
          <BlockIcon icon={user} title='Personne physique' />
          <BlockIcon icon={sac} title='Personne morale (société)' />
          <BlockIcon icon={coeur} title='Personne morale (société)' />
        </div>
        <img className='process' src={process} />
      </div>
    </section>
  );
}
