import React from 'react';
import Header from '../../components/header/header';
import NavToForm from './components/navToForm/navToForm';
import HeaderTitles from './components/headerTitles/headerTitles';
import PourquoiLegalStation from './components/pourquoiLegalStation/pourquoiLegalStation';
import DemarcheCreation from './components/demarcheCreation/demarcheCreation';
import Pack from './components/pack/pack';
import ContactUs from './components/contactUs/contactUs';
import Decouvrir from './components/decouvrir/decouvrir';
import Partenaires from './components/partenaires/partenaire';
import DernierArticles from './components/dernierArticles/dernierArticles';
import Footer from '../../components/footer/footer';
import './legalStationLandingView.css';

export default function LegalStationLandingView() {
  return (
    <div className='legalStationLandingContainer'>
      <Header active='Acceuil' />
      <HeaderTitles />
      <NavToForm />
      <PourquoiLegalStation />
      <DemarcheCreation />
      <Pack />
      <ContactUs />
      <Decouvrir />
      <Partenaires />
      <DernierArticles />
      <Footer />
    </div>
  );
}
