/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { Button } from '@mui/material';
import StepProgress from '../../../../../../components/stepProgress/stepProgress';
import usericon from '../../../../../../assets/icons/usericon.png';
import locationicon from '../../../../../../assets/icons/location.png';
import cin from '../../../../../../assets/icons/cin.png';
import date from '../../../../../../assets/icons/calendar.png';
import description from '../../../../../../assets/icons/doc.png';
import CustomTextInput from '../../../../../../components/inputs/customTextInput/customTextInput';
import money from '../../../../../../assets/icons/money.png';
import election from '../../../../../../assets/icons/election.png';
import Swal from 'sweetalert2';
import axios from 'axios';
import getEnvironement from '../../../../../../environnement';



function BailLocationMeubleeForm() {
  const [currentStep, setCurrentStep] = useState(1);
  const [nomBailleur, setNomBailleur] = useState('');
  const [prenomBailleur, setPrenomBailleur] = useState('');
  const [adresseBailleur, setAdresseBailleur] = useState('');
  const [nationaliteBailleur, setNationaliteBailleur] = useState('');
  const [cinBailleur, setCinBailleur] = useState('');

  // Locataire
  const [nomLocataire, setNomLocataire] = useState('');
  const [prenomLocataire, setPrenomLocataire] = useState('');
  const [adresseLocataire, setAdresseLocataire] = useState('');
  const [nationaliteLocataire, setNationaliteLocataire] = useState('');
  const [cinLocataire, setCinLocataire] = useState('');

  // Propriété
  const [dateDebutBail, setDateDebutBail] = useState('');
  const [dateFinBail, setDateFinBail] = useState('');
  const [adressePropriete, setAdressePropriete] = useState('');
  const [descriptionAppartement, setDescriptionAppartement] = useState('');

  // Bail
  const [montantLoyer, setMontantLoyer] = useState('');
  const [montantCaution, setMontantCaution] = useState('');
  const [electiondeDomicilePreneur, setElectiondeDomicilePreneur] = useState('');
  const [electionDomicileproprietaire, setElectionDomicileproprietaire] = useState('');
    
    
    

  const getQuestion = (currentStep) => {
    switch (currentStep) {
    case 1:
      return (
        <Q1
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          nomBailleur={nomBailleur}
          setNomBailleur={setNomBailleur}
          prenomBailleur={prenomBailleur}
          setPrenomBailleur={setPrenomBailleur}
          adresseBailleur={adresseBailleur}
          setAdresseBailleur={setAdresseBailleur}
          nationaliteBailleur={nationaliteBailleur}
          setNationaliteBailleur={setNationaliteBailleur}
          cinBailleur={cinBailleur}
          setCinBailleur={setCinBailleur}
            
        />
      );
    case 2:
      return (
        <Q2
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          nomLocataire={nomLocataire}
          setNomLocataire={setNomLocataire}
          prenomLocataire={prenomLocataire}
          setPrenomLocataire={setPrenomLocataire}
          adresseLocataire={adresseLocataire}
          setAdresseLocataire={setAdresseLocataire}
          nationaliteLocataire={nationaliteLocataire}
          setNationaliteLocataire={setNationaliteLocataire}
          cinLocataire={cinLocataire}
          setCinLocataire={setCinLocataire}
        />
        
      );
    case 3:
      return (
        <Q3
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          dateDebutBail={dateDebutBail}
          setDateDebutBail={setDateDebutBail}
          dateFinBail={dateFinBail}
          setDateFinBail={setDateFinBail}
          adressePropriete={adressePropriete}
          setAdressePropriete={setAdressePropriete}
          descriptionAppartement={descriptionAppartement}
          setDescriptionAppartement={setDescriptionAppartement}
        />
      );
    case 4:
      return (
        <Q4
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          montantLoyer={montantLoyer}
          setMontantLoyer={setMontantLoyer}
          montantCaution={montantCaution}
          setMontantCaution={setMontantCaution}
          electiondeDomicilePreneur={electiondeDomicilePreneur}
          setElectiondeDomicilePreneur={setElectiondeDomicilePreneur}
          electionDomicileproprietaire={electionDomicileproprietaire}
          setElectionDomicileproprietaire={setElectionDomicileproprietaire}
          handleClick={handleEnregisterClick}
        />
      );

    default:
      return <Q1
        setCurrentStep={setCurrentStep}
        currentStep={currentStep}
        nomBailleur={nomBailleur}
        setNomBailleur={setNomBailleur}
        prenomBailleur={prenomBailleur}
        setPrenomBailleur={setPrenomBailleur}
        adresseBailleur={adresseBailleur}
        setAdresseBailleur={setAdresseBailleur}
        nationaliteBailleur={nationaliteBailleur}
        setNationaliteBailleur={setNationaliteBailleur}
        cinBailleur={cinBailleur}
        setCinBailleur={setCinBailleur}

       
      />;

    }
  };
  const handleEnregisterClick = () => {
    const url = `${getEnvironement().API_URL}/location-meublee`;
    const data = {
      nomBailleur,
      prenomBailleur,
      adresseBailleur,
      nationaliteBailleur,
      cinBailleur,
      nomLocataire,
      prenomLocataire,
      adresseLocataire,
      nationaliteLocataire,
      cinLocataire,
      dateDebutBail,
      dateFinBail,
      adressePropriete,
      descriptionAppartement,
      montantLoyer,
      montantCaution,
      electiondeDomicilePreneur,
      electionDomicileproprietaire,
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    axios.post(url, data, config)
      .then((response) => {
        if (response.status === 201) {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Location meublee créée avec succès.',
            confirmButtonText: 'OK'
          });
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'Une erreur s\'est produite lors de la création de la location meublée.',
          confirmButtonText: 'OK'
        });
      });
  };

  return (
    <div className=" flex flex-col justify-start items-center px-10 mt-10 bg-white">
      <h3 className=""></h3>
      <StepProgress numberSteps={4} currentStep={currentStep} />
      <div className="w-fit md:w-[700px]">
        {getQuestion(currentStep)}
        <div className="flex justify-between gap-5 my-5">
          <Button
            color="info"
            variant="outlined"
            disabled={currentStep > 1 ? false : true}
            onClick={() => {
              if (currentStep > 1) {
                setCurrentStep(currentStep - 1);
              }
            }}
          >
            {'< Retour'}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              currentStep !== 4
                ? setCurrentStep(currentStep + 1)
                : handleEnregisterClick();
            }}
          >
            {currentStep !== 4 ? 'Continuer': 'signer'}
          </Button>
        </div>
      </div>
    </div>
  );
}

function Q1 (props){
  const {
    nomBailleur,
    setNomBailleur,
    prenomBailleur,
    setPrenomBailleur,
    adresseBailleur,
    setAdresseBailleur,
    nationaliteBailleur,
    setNationaliteBailleur,
    cinBailleur,
    setCinBailleur,
  } = props;
  return (
    <div className="mb-10">
      <h4 className="text-xl font-bold">01 : Détails du Bailleur:</h4>
      <div>
        <CustomTextInput
          image={usericon}
          type="text"
          value={nomBailleur}
          setValue={setNomBailleur}
          placeholder="Nom"
        />
        <CustomTextInput
          image={usericon}
          type="text"
          value={prenomBailleur}
          setValue={setPrenomBailleur}
          placeholder="Prénom"
        />
        <CustomTextInput
          image={locationicon}
          type="text"
          value={adresseBailleur}
          setValue={setAdresseBailleur}
          placeholder="Adresse"
        />
        <CustomTextInput
          image={cin}
          type="text"
          value={nationaliteBailleur}
          setValue={setNationaliteBailleur}
          placeholder="Nationalité"
        />
        <CustomTextInput
          image={cin}
          type="text"
          value={cinBailleur}
          setValue={setCinBailleur}
          placeholder="CIN"
        />
      </div>
    </div>
  );
}
function Q2 (props){
  const {
    nomLocataire,
    setNomLocataire,
    prenomLocataire,
    setPrenomLocataire,
    adresseLocataire,
    setAdresseLocataire,
    nationaliteLocataire,
    setNationaliteLocataire,
    cinLocataire,
    setCinLocataire,
  } = props;
  return (
    <div className="mb-10">
      <h4 className="text-xl font-bold">02 : Détails du Locataire:</h4>
      <div>
        <CustomTextInput
          image={usericon}
          type="text"
          value={nomLocataire}
          setValue={setNomLocataire}
          placeholder="Nom"
        />
        <CustomTextInput
          image={usericon}
          type="text"
          value={prenomLocataire}
          setValue={setPrenomLocataire}
          placeholder="Prénom"
        />
        <CustomTextInput
          image={locationicon}
          type="text"
          value={adresseLocataire}
          setValue={setAdresseLocataire}
          placeholder="Adresse"
        />
        <CustomTextInput
          image={cin}
          type="text"
          value={nationaliteLocataire}
          setValue={setNationaliteLocataire}
          placeholder="Nationalité"
        />
        <CustomTextInput
          image={cin}
          type="text"
          value={cinLocataire}
          setValue={setCinLocataire}
          placeholder="CIN"
        />
      </div>
    </div>
  );
}

function Q3 (props){
  const {
    dateDebutBail,
    setDateDebutBail,
    dateFinBail,
    setDateFinBail,
    adressePropriete,
    setAdressePropriete,
    descriptionAppartement,
    setDescriptionAppartement,

  } = props;
  return (
    <div className="mb-10">
      <h4 className="text-xl font-bold">03 : Détails de la Propriété</h4>
      <div>
        <CustomTextInput
          image={date}
          type="date"
          value={dateDebutBail}
          setValue={setDateDebutBail}
          placeholder="Date debut bail"
        />
        <CustomTextInput
          image={date}
          type="date"
          value={dateFinBail}
          setValue={setDateFinBail}
          placeholder="date fin bail"
        />
        <CustomTextInput
          image={locationicon}
          type="text"
          value={adressePropriete}
          setValue={setAdressePropriete}
          placeholder="Adresse de la propriété"
        />
        <CustomTextInput
          image={description}
          type="text"
          value={descriptionAppartement}
          setValue={setDescriptionAppartement}
          placeholder="Description de l'appartement"
        />
      </div>
    </div>
  );
}

function Q4 (props){
  const {
    montantLoyer,
    setMontantLoyer,
    montantCaution,
    setMontantCaution,
    electiondeDomicilePreneur,
    setElectiondeDomicilePreneur,
    electionDomicileproprietaire,
    setElectionDomicileproprietaire 

  } = props;

  return (
    <div className="mb-10">
      <h4 className="text-xl font-bold">04 : Détails du Bailleur:</h4>
      <div>
        <CustomTextInput
          image={money}
          type="text"
          value={montantLoyer}
          setValue={setMontantLoyer}
          placeholder="Montant du loyer"
        />
        <CustomTextInput
          image={money}
          type="text"
          value={montantCaution}
          setValue={setMontantCaution}
          placeholder="Montant de la caution"
        />
        <CustomTextInput
          image={election}
          type="text"
          value={electiondeDomicilePreneur}
          setValue={setElectiondeDomicilePreneur}
          placeholder="Election de domicile du preneur dans les lieux loués"
        />
        <CustomTextInput
          image={election}
          type="text"
          value={electionDomicileproprietaire}
          setValue={setElectionDomicileproprietaire}
          placeholder="Election de domicile du propriétaire"
        />
      </div>
    </div>
  );
}

export default BailLocationMeubleeForm;