import React from 'react';
import { Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';

Font.register({
  family: 'abeezee',
  src: 'http://fonts.gstatic.com/s/abeezee/v9/JYPhMn-3Xw-JGuyB-fEdNA.ttf',
});

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    fontFamily: 'abeezee',
  },
  section: {
    marginBottom: 30,
  },
  heading: {
    fontSize: 20,
    fontWeight: 900,
    marginBottom: 20,
    textAlign: 'center',
  },
  subheading: {
    fontSize: 16,
    fontWeight: 900,
    marginBottom: 35,
  },
  paragraph: {
    marginBottom: 5,
    textAlign: 'justify',
    lineHeight: 1.5,
  },
  subparagraph: {
    marginBottom: 35,
    textAlign: 'center',
    lineHeight: 1,
    fontSize: 8,
  },
  listItem: {
    marginLeft: 25,
    lineHeight: 1.5,
    marginBottom: 10,
  },
  sublistItem: {
    marginLeft: 25,
    lineHeight: 1,
    marginBottom: 2.5,
  },
  italicSouligneGray: {
    textDecoration: 'underline',
    color: 'gray',
  },
  laws: {
    color: '#666',
    fontSize: 10,
  },
});

function Page2(props) {
  const { chooseDomaineSite, isPrixShow, DuréeValidité, processusCommandes } = props;
  return (
    <Page size="A4" style={styles.page}>
      {/* ARTICLE 2 - Prix */}
      <View style={styles.section}>
        <Text style={styles.subheading}>ARTICLE 2 - Prix</Text>
        <Text style={styles.paragraph}>
          Les Produits sont fournis aux tarifs en vigueur figurant sur le site{' '}
          {chooseDomaineSite || '________________'}, lors de l&apos;
          enregistrement de la commande par le Vendeur.
        </Text>
        <Text style={styles.paragraph}>
          Les prix sont exprimés en Dirhams marocains (MAD), HT et TTC.
        </Text>
        <Text style={styles.paragraph}>
          Les tarifs tiennent compte d&apos;éventuelles réductions qui seraient
          consenties par le Vendeur sur le site{' '}
          {chooseDomaineSite || '________________'}.
        </Text>
        <Text style={styles.paragraph}>
          Ces tarifs sont fermes et non révisables pendant leur période de
          validité mais le Vendeur se réserve le droit, hors période de
          validité, d’en modifier les prix à tout moment.
        </Text>
        <Text style={styles.paragraph}>
          Les prix ne comprennent pas les frais de traitement, d&apos;expédition,
          de transport et de livraison, qui sont facturés en supplément, dans
          les conditions indiquées sur le site et calculés préalablement à la
          passation de la commande.
        </Text>
        <Text style={styles.paragraph}>
          Le paiement demandé au Client correspond au montant total de l&apos;
          achat, y compris ces frais.
        </Text>
        <Text style={styles.paragraph}>
          Une facture est établie par le Vendeur et remise au Client lors de la
          livraison des Produits commandés.
        </Text>
        {isPrixShow && <Text style={styles.paragraph}>
          Certaines commandes peuvent faire l&apos;objet d&apos;un devis préalablement
          accepté. Les devis établis par le Vendeur sont valables pour une durée
          de {DuréeValidité || '________________'} après leur établissement.
        </Text>}
      </View>
      <View style={styles.section}>
        <Text style={styles.subheading}>ARTICLE 3 – Commandes</Text>
        <Text style={styles.paragraph}>
          Il appartient au Client de sélectionner sur le site{' '}
          {chooseDomaineSite || '________________'}
          les Produits qu&apos;il désire commander, selon les modalités suivantes
          :
        </Text>
        <Text style={styles.paragraph}>{processusCommandes || '________________'}.</Text>
        <Text style={styles.paragraph}>
          Les offres de Produits sont valables tant qu&apos;elles sont visibles
          sur le site, dans la limite des stocks disponibles.
        </Text>
        <Text style={styles.paragraph}>
          La vente ne sera considérée comme valide qu’après paiement intégral du
          prix. Il appartient au Client de vérifier l&apos;exactitude de la
          commande et de signaler immédiatement toute erreur.
        </Text>
        <Text style={styles.paragraph}>
          Toute commande passée sur le site{' '}
          {chooseDomaineSite || '________________'} constitue la formation d
          &apos;un contrat conclu à distance entre le Client et le Vendeur.
        </Text>
        <Text style={styles.paragraph}>
          Le Vendeur se réserve le droit d&apos;annuler ou de refuser toute
          commande d&apos;un Client avec lequel il existerait un litige relatif
          au paiement d&apos;une commande antérieure.
        </Text>
        <Text style={styles.paragraph}>
          Le Client pourra suivre l&apos;évolution de sa commande sur le site.
        </Text>
        <Text style={styles.paragraph}>
          Toute annulation de la commande par le Client ne sera possible qu&apos;
          avant la livraison des Produits (indépendamment des dispositions
          relative à l&apos;application ou non du droit de rétractation légal).
        </Text>
      </View>
    </Page>
  );
}

export default Page2;
