import React from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import './customRadioBox.css';

export default function CustomRadioBox({
  label,
  items,
  row = true,
  value,
  setValue,
  isBlue = false,
  labelClass = '',
}) {
  const defaultClasses = isBlue
    ? 'bg-active px-5 shadow-md rounded-lg my-3'
    : 'bg-transparent my-3';

  return (
    <div className={defaultClasses}>
      <FormControl>
        <FormLabel>{label}</FormLabel>
        <RadioGroup
          row={row}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        >
          {items.map((item) => (
            <FormControlLabel
              key={item.value}
              value={item.value}
              control={<Radio />}
              label={item.label}
              className={`labelStyle ${labelClass}`}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
}