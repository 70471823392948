import React from 'react';
import Footer from '../../../../components/footer/footer';
import HeaderContainer from '../../../../components/headerContainer/headerContainer';
import { Link, useNavigate } from 'react-router-dom';
import AutresDemarchesContentSqueleton from '../components/autresDemarchesContentSqueleton/autresDemarchesContentSqueleton';
import './cession.css';

export default function Cession() {
  const navigate = useNavigate();

  const itemsContent = [
    {title: 'Qu’est-ce qu’une cession d’entreprise ?', link: '#1'},
    {title: 'Cession de titres', link: '#2'},
    {title: 'Cession de fonds', link: '#3'},
    {title: 'Cession d’entreprise avec crédit vendeur', link: '#4'},
    {title: 'Quelles sont les étapes pour céder une entreprise ?', link: '#5'},
    {title: 'Quel est le meilleur moment pour vendre ?', link: '#6'},
    {title: 'FAQ', link: '#7'},
  ];
  return (
    <div className='cession'>
      <HeaderContainer />
      <AutresDemarchesContentSqueleton
        titleContent='La cession d’une entreprise'
        textButton='Modifier les status'
        rightContent={<CessionContent />}
        itemsContent={itemsContent}
        isAutresDemarche
        onClick={() => navigate('/cession/form')}
      />
      <Footer />
    </div>
  );
}

function CessionContent() {
  return (
    <div className='cessionContent'>
      <p>La cession d’entreprise est une décision qui peut intervenir pour différentes raisons telles que :</p>
      <ul className='list-wtl'>
        <li>Une évolution de la situation personnelle de l’entrepreneur ;</li>
        <li>La volonté d’assurer la pérennité de la société ;</li>
        <li>L’opportunité de faire une plus-value…</li>
      </ul>
      <p>Cession de titres ou de fonds, pour le dirigeant, ce processus peut prendre du temps. En effet, il a dû suivre certaines formalités pour <Link className='linkContent' to='#'>créer son entreprise</Link>. Et maintenant, il doit considérer quelques étapes pour la céder.</p>

      <h3 id='1'>Qu’est-ce qu’une cession d’entreprise ?</h3>
      <p>La cession d’entreprise est un acte qui permet le transfert partiel ou total de la propriété <strong>des actifs à un tiers, qui fait office de repreneur par le biais d’un acte de vente, de donation ou de fusion</strong>. Elle peut intervenir quelle que soit la forme juridique de l’entreprise (SARL, SA, EURL…). Le transfert survient souvent dans le cadre d’une <Link className='linkContent' to='#'>reprise</Link> (laquelle peut se faire via le dispositif Transentreprise) ou pour une société à vendre en liquidation.</p>

      <h3 id='2'>Cession de titres</h3>
      <p>La cession de titres permet de réaliser le transfert des droits rattachés aux titres à un repreneur. <strong>Selon le type d’entreprise, il peut s’agir d’actions</strong> (cas d’une <Link className='linkContent' to='#'>SAS</Link>, SA…) <strong>ou de parts sociales</strong> (cas d’une SARL). Cette procédure est associée à un certain nombre de formalités.</p>
      <p>Selon les dispositions légales, il est <strong>obligatoire d’informer</strong> au préalable l’<strong>ensemble du personnel</strong> quant aux opérations de transfert en cours. C’est notamment le cas si un accès à la majorité du capital social de l’entreprise est donné. Pour les organisations de moins de 250 salariés, le délai est de 2 mois précédant la signature de tout acte de renoncement aux droits de propriété.</p>
      <p>Il convient aussi de se référer aux clauses admises dans les statuts de la société. Celles qui concernent les dispositions relatives aux différents droits des associés (par exemple, la <Link className='linkContent' to='#'>clause de préemption</Link> dans une SAS).</p>
      <p>Par ailleurs, <strong>la cession de titres doit être formalisée par un acte</strong>. Celui-ci doit être obligatoirement écrit pour la cession de parts sociales. Un exemplaire de cet acte doit être transmis à la société une fois la procédure achevée.</p>
      <p>Enfin, <strong>les statuts doivent être modifiés en conséquence</strong>. Pour finaliser les démarches, un enregistrement des changements effectués auprès des services des impôts doit être réalisé. Il s’agit d’une obligation légale.</p>
      <p className='infoContent'><strong>Bon à savoir :</strong> au niveau de la fiscalité, c’est le cédant qui prend en charge le paiement des impositions associées à la réalisation de la cession de titres.</p>
      
      <h3 id='3'>Cession de fonds</h3>
      <p><strong>La cession de fonds est la vente des éléments d’actifs indispensables à l’exercice d’une activité commerciale</strong>. Il s’agit notamment du fonds de commerce qui est composé des outils de production, du droit au bail ou encore de la marque. Certains éléments sont à intégrer obligatoirement aux fonds cédés. Parmi eux figurent les contrats de travail en cours ou les contrats d’assurance.</p>
      <p>Cet acte nécessite certaines formalités. Il s’agit entre autres des obligations suivantes :</p>
      <ul className='list-wtl'>
        <li>Le respect du droit à l’information des salariés ;</li>
        <li>L’obtention du consentement du conjoint du cédant ;</li>
        <li>La rédaction d’un acte de vente écrit ;</li>
        <li>La publication d’un avis de cession (au BODACC, dans un <Link className='linkContent' to='#'>Journal d’annonces légales</Link>) ;</li>
        <li>L’enregistrement auprès des services des impôts ;</li>
        <li>Le dépôt d’un dossier d’immatriculation…</li>
      </ul>
      <p className='infoContent'><strong>Bon à savoir :</strong> les dettes sont à exclure de la cession de fonds et restent à la charge du cédant.</p>
      
      <h3 id='4'>Cession d’entreprise avec crédit vendeur</h3>
      <p><strong>Pour une PME ou toute autre société avec crédit vendeur, des conditions de paiement différé peuvent être consenties par le cédant</strong>. Dans la pratique, seule une partie du prix convenu est versée au cédant. Ce dernier perçoit le reste de ce qui est dû, selon les conditions fixées dans le contrat de vente. Pour rassurer le cédant, le paiement d’intérêts financiers est convenu en plus des garanties. Il est notamment possible de procéder à une hypothèque.</p>
      <p className='infoContent'><strong>Bon à savoir :</strong> le crédit vendeur peut être appliqué autant pour la cession de titres que pour celle de fonds.</p>
      
      <h3 id='5'>Quelles sont les étapes pour céder une entreprise ?</h3>
      <p>Comment se passe une cession d’entreprise ? Cet acte ne se résume pas en une seule prise de décision de la part du dirigeant ou des associés. En effet, plusieurs étapes sont nécessaires. Certaines étant des préalables indispensables à la prise de décision elle-même.</p>
      <h6>Étape 1 : Réaliser le diagnostic de l’entreprise</h6>
      <p>La réalisation d’un diagnostic permet de disposer d’un document complet d’<Link className='linkContent' to='#'>évaluation de l’entreprise</Link>. Généralement rédigé à la suite d’un audit interne, il porte sur plusieurs volets tels que la situation financière et fiscale, la partie commerciale ou celle portant sur les ressources humaines.</p>
      <p>Afin de déterminer la valeur de l’entreprise, il existe le <Link className='linkContent' to='#'>calcul EBITDA</Link> qui prend en compte plusieurs facteurs financiers et fiscaux.</p>
      <p><strong>Rapport à présenter à l’acheteur potentiel, le diagnostic est une présentation transparente de l’entreprise</strong>. Il comprend ses atouts, ses faiblesses, mais également les opportunités. Le diagnostic peut être un élément décisif dans les négociations.</p>
      <p className='infoContent'><strong>Bon à savoir :</strong> en fonction des informations confidentielles contenues dans le diagnostic de l’entreprise, un accord de confidentialité peut être signé entre le cédant et l’acheteur potentiel.</p>
      <h6>Étape 2 : Choisir le mode de cession</h6>
      <p><strong>Le choix du mode de cession d’une entreprise vise à définir les différents montages juridiques</strong>. En effet, le dirigeant peut opter pour plusieurs possibilités. Par exemple, elle peut être effectuée à titre gratuit, onéreux ou encore à titre temporaire, comme c’est le cas d’une location-gérance.</p>
      <p>Selon la situation de la société, le choix peut être différent. Ainsi, pour la cession d’une entreprise en difficulté, le mode de transfert de propriété <strong>ne sera assurément pas le même</strong> que pour le cas d’une entreprise en pleine expansion.</p>
      <p>Cession de fonds, de parts sociales ou partielles, la décision a diverses conséquences sur le plan financier, juridique ou fiscal. Il convient donc de considérer avec attention les objectifs du cédant avant la prise de décision.</p>
      <p className='infoContent'><strong>Bon à savoir :</strong> dans le cas d’une location-gérance, la propriété de l’organisation est conservée.</p>
      <h6>Étape 3 : Valoriser son entreprise</h6>
      <p><strong>L’évaluation de l’entreprise permet de déterminer sa valeur. Pour les décideurs, elle peut contribuer à l’estimation du prix</strong>. Pour avoir une valeur objective, il est recommandé d’avoir recours à des professionnels. La valorisation vise surtout à disposer d’éléments fiables pour négocier avec les potentiels acheteurs.</p>
      <p>Alors que l’acheteur potentiel a la possibilité de <strong>racheter l’entreprise pour 1 euro</strong>, il existe différentes approches pour valoriser sa société. L’approche patrimoniale en est une. Il est également possible de considérer ses performances.</p>
      <h6>Étape 4 : Déterminer le profil de son repreneur</h6>
      <p>Pour le cédant, <strong>établir le profil du repreneur est un préalable qu’il ne faut pas négliger</strong>. Il s’agit de déterminer les compétences, l’expérience et les ressources financières du repreneur idéal. L’objectif est avant tout d’évaluer sa capacité à reprendre la société. Par exemple, pour reprendre une entreprise artisanale, être un bon commercial ne suffit pas.</p>
      <h6>Étape 5 : Négocier</h6>
      <p>Compte tenu de la valorisation effectuée, de la valeur du marché et de l’évaluation par rapport à la rentabilité, le prix de départ sera certainement discuté par le repreneur. <strong>Dans les négociations, l’objectif est d’optimiser ce prix en mettant en avant les atouts de l’entreprise</strong>.</p>
      <p className='infoContent'><strong>Bon à savoir :</strong> il est recommandé de confier les négociations à des professionnels. Ainsi, il est possible de faire appel à un cabinet d’affaires.</p>
      <h6>Étape 6 : Lettre d’intention</h6>
      <p>La lettre d’intention est un document qui officialise la volonté du repreneur de se porter acquéreur de l’entreprise. Ce courrier vise ainsi à formaliser l’intention d’achat. <strong>Il permet, entre autres objectifs, d’avoir accès aux informations confidentielles de l’entreprise</strong>. Par ailleurs, la lettre d’intention lui assure l’exclusivité dans les négociations.</p>
      <h6>Étape 7 : Contrat de cession</h6>
      <p>Une fois les négociations clôturées, les parties peuvent <strong>formaliser l’accord passé en signant un contrat de cession</strong>. Celui-ci contient toutes les dispositions relatives à l’opération en cours. À cela s’ajoutent les différentes clauses convenues et qui se rapportent au prix, aux modalités du paiement.</p>
      <p className='infoContent'><strong>Bon à savoir :</strong> le contrat de cession engage les parties et précède les formalités administratives.</p>
      
      <h3 id='6'>Quel est le meilleur moment pour vendre ?</h3>
      <p>Le meilleur moment pour vendre son entreprise dépend de plusieurs facteurs. Cependant, du point de vue des experts, <strong>il est recommandé de procéder à la cession lorsque la valeur de l’entreprise est en pleine ascension et juste avant qu’elle ne retombe</strong>.</p>
      <p>D’autres facteurs peuvent également être considérés pour déterminer le moment le plus approprié pour céder son entreprise. Il s’agit, par exemple, de savoir si le marché est favorable ou si l’entrepreneur est prêt à vendre.</p>

      <h3 id='7'>FAQ</h3>
      <h6>Comment se passe une cession d&apos;entreprise ?</h6>
      <p>Une cession d’entreprise se déroule en plusieurs étapes qui peuvent prendre du temps. En effet, pour optimiser cette démarche, il est nécessaire de passer par des étapes préalables. Il s’agit notamment de préparer l’opération en effectuant un certain nombre de diagnostics de l’organisation. Cet audit permettra aussi d’identifier les atouts et les faiblesses de l’entreprise. Il est également utile d’évaluer l’entreprise afin de fixer un prix de cession qui sera proposé aux acheteurs potentiels.</p>
      <h6>Qu&apos;est-ce que la cession d&apos;entreprise ?</h6>
      <p>La cession d’entreprise est le transfert de propriété des éléments nécessaires pour l’exploitation d’une activité. Elle peut être totale ou partielle. Ainsi, il est possible de céder des parts sociales d’une entreprise ou de procéder à la cession d’un fonds de commerce. Dans le cas d’une location-gérance, il n’y a pas de transfert de propriété. Seule l’exploitation de l’activité est transmise à un tiers pour une durée convenue entre les parties.</p>
      <h6>Pourquoi céder son entreprise ?</h6>
      <p>Les motifs pour céder son entreprise diffèrent d’un entrepreneur à un autre. Départ à la retraite, volonté de réaliser une plus-value ou difficultés financières, les raisons invoquées sont nombreuses. Selon les motivations, les modes de cession peuvent également varier. Cession partielle ou totale, immédiate ou différée, les modalités dépendent surtout des objectifs du cédant. Il en est de même du choix des repreneurs potentiels.</p>
    </div>
  );
}