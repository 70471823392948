import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../../../components/header/header';
import building from '../../../../assets/images/building.png';
import { Button } from '@mui/material';

function Mystatus() {
  const navigate = useNavigate();
  return (
    <div>
      <Header />
      <div className=' h-screen flex flex-col justify-center items-center overflow-hidden gap-5 p-10'>
        <img className='w-20' src={building} />
        <div className='text-center text-3xl text-darkblue font-bold tracking-tight'>
          <h1>Choisir la bonne forme de société,<br />
            c’est <span className='text-primary'>essentiel.</span></h1>
        </div>
        <div>
          <p className='text-center'>
            SAS, SASU, SARL, EURL ou SCI ? On vous simplifie ce processus pour vous<br></br>accompagner dans vos démarches de création.
          </p>
        </div>
        <div>
          <Button
            variant='contained'
            onClick={() => navigate('/myStatus/form')}
          >
            Trouver Ma Fomre juridique ➡️
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Mystatus;