import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/LOGO-LS@2x.png';
import './headerContainer.css';

export default function HeaderContainer() {
  return (
    <div className='headerContainer'>
      <div className='logoContainer'>
        <Link to='/'>
          <img className='logo' src={logo} />
        </Link>
      </div>
    </div>
  );
}
