import React from 'react';
import { Link } from 'react-router-dom';
import './navToForm.css';

function NavToFormBlock({ items, title, description, className }) {
  return (
    <>
      <div className={'blocFormItems'}>
        <div className={className}>
          <h6 className='title'>{title}</h6>
          <p className='description'>{description}</p>
        </div>
        <div className={`formItemsContainer ${className}Items`}>
          {items.map(([item, link]) => (
            <Link
              className='formItem'
              key={item}
              to={link}
              onClick={() => window.scrollTo(0, 0)}
            >
              {item}
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}

export default function NavToForm() {
  return (
    <section className='navToFormContainer'>
      <div className='navToForm'>
        <NavToFormBlock
          className='creationEntreprise'
          title='Création d&apos;entreprise'
          description='Création d&apos;entreprise au maroc sur plusieurs villes du royaume'
          items={[
            // ['Créer ma SAS', '/sas'],
            ['Créer ma société', '/maSociete'],
            ['Créer mon statut', '/myStatus'],
            ['Créer mon association', '/association'],
            ['Créer mon auto-entrepreneur', '/autoEntreprise']
          ]}
        />
        <NavToFormBlock
          className='domiciliation'
          title='Domiciliation et Comptabilité'
          description='Domiciliation sur Casablanca, rabat, tanger, marrakech ou laayoune.'
          items={[
            ['Comptabilité', '/comptabilite'],
            ['Création + Experise comptable'],
            ['Domiciliation', '/domiciliation'],
            ['Création + Domiciliation']
          ]}
        />
        <NavToFormBlock
          className='contrat'
          title='Contrat'
          description='Tenue de comptabilité professionnelle et informatisée.'
          items={[
            ['Pacte d\'associés', './PacteAssocies'],
            ['CGV', './contratCGV'],
            ['Contrat de location', './contratLocation'],
            ['Contrat de travail', '/contratTravail'],
            ['Contrat de prestation', './contratPrestation']
          ]}
        />
        <NavToFormBlock
          className='autreDemarche'
          title='Autres démarches'
          description='Vous pouvez créer le module Membres, et Groupes. Nous avons déjà créé 3 modules.'
          items={[
            ['Déclaration de cession', '/cession'],
            ['Demande de carte grise','/carteGrise'],
            ['Changement de statut','/changementStatut'],
            // ['Formations CSE','/cseFormation'],
            ['Dissolution d\'entreprise', '/dissolution']
          ]}
        />
      </div>
    </section>
  );
}
