import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/userSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import ProductsTable from './components/productsTable';
import searchIcon from '../../assets/icons/search-normal.png';
import logo from '../../assets/images/LOGO-LS@2x.png';

export default function DetailsPack() {
  const user = useSelector(userSelector);
  const location = useLocation();
  const navigate = useNavigate();
  
  const {packId} = location.state || {};

  const [products, setProducts] = useState(user.products.filter((product) => product.pack_id === packId));

  const handelChangeSearchInput = (e) => {
    setProducts(user.products.filter((product) => product.pack_id === packId).filter(product => product.product_id.startsWith(e.target.value)));
  };
  return (
    <>
      <div className='py-5 px-[5vw] shadow-md mb-10'>
        <img className='w-[15vw] cursor-pointer' src={logo} alt='legalStationLogo' onClick={() => navigate('/')}/>
      </div>
      <div className='px-10'>
        <div className='flex justify-between items-center mb-5'>
          <p className='font-poppins font-medium text-2xl'>Les produits du pack</p>
        </div>
        <div className='flex w-1/3 h-10 p-2 mb-5 bg-[#FAFAFA] rounded-lg'>
          <img src={searchIcon}/>
          <input
            type='search'
            placeholder='Chercher par ID'
            onChange={(e) => handelChangeSearchInput(e)}
            className='bg-[#FAFAFA] ml-2 p-2 focus:outline-none w-full'
          />
        </div>
        <ProductsTable
          header={[
            'Id_produit', 'Type produit','Date', 'Status', 'Documents'
          ]}
          data={products}
        />
      </div>
    </>
  );
}
