import React from 'react';
import LandingFormContrat from '../landingFormContrat/landingFormContrat';
import HandleContent from '../../../../../components/handleContent/handleContent';
import './contratContentSqueleton.css';

export default function ContratContentSqueleton({
  title,
  subTitle,
  link,
  titleContent,
  titleLeftContent,
  textButton,
  rightContent,
  itemsContent,
}) {
  return (
    <div>
      <LandingFormContrat
        title={title}
        subTitle={subTitle}
        link={link}
      />
      <div className='titleContratContentContainer'>
        <div className='emptyDiv' />
        <h1 className='titleContratContent'>{titleContent}</h1>
      </div>
      <HandleContent
        title={titleLeftContent}
        textButton={textButton}
        items={itemsContent}
        rightContent={rightContent}
      />
    </div>
  );
}
