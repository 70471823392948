import React, { useState } from 'react';
import './step2.css';
import { TextField } from '@mui/material';

export default function Step2({ onClick = () => {} }) {
  const [value, setValue] = useState('');
  return (
    <div className="step2-views">
      <h1 className="step2-title">CRÉATION DE SARL</h1>
      <h3 className="step2-question">
        Quel est le nom que vous avez choisi pour votre entreprise?
      </h3>
      <div className="step2-choices">
        <TextField
          id="label"
          variant="outlined"
          type="text"
          placeholder="lorem"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          sx={{ backgroundColor: '#FFF', width: '100%', height: '100%' }}
        />
      </div>
      <button type="submit" name="btn" onClick={onClick}>
        next
      </button>
    </div>
  );
}
