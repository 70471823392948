import React from 'react';
// import './section4.css';

export default function Section4() {
  return (
    <div className='px-10 md:px-[120px] text-balance text-justify md:text-start mb-[100px]'>
      <h1 className='w-auto text-darkblue text-balance text-center text-3xl font-bold font-inter mb-16'>Services conçus pour vos besoins.</h1>
      <div className=" grid grid-cols-1 gap-6 md:grid-col-2 lg:grid-cols-3">
        <div className="sarl-section4-part-item">
          <h3 className='w-auto text-darkblue text-balance text-center md:text-start text-lg font-bold font-inter mb-3 '>Création de société</h3>
          <p>
            Nous sommes fiers d&#39;avoir accompagné des milliers
            d&#39;entrepreneurs dans la création de leur société. Profitez de
            notre savoir-faire et de notre expérience dans la réalisation de vos
            formalités d&#39;immatriculation.
          </p>
        </div>
        <div className="sarl-section4-part-item">
          <h3 className='w-auto text-darkblue text-balance text-center md:text-start text-lg font-bold font-inter mb-3'>Service simple, rapide, 100% en ligne</h3>
          <p>
            Notre interface simple et intuitive met à votre disposition des
            algorithmes uniques et performants vous garantissant un service
            simple d&#39;utilisation et des documents de qualité.
          </p>
        </div>
        <div className="sarl-section4-part-item">
          <h3 className='w-auto text-darkblue text-balance text-center md:text-start text-lg font-bold font-inter mb-3'>Suivi de vos formalités en temps réel</h3>
          <p>
            Soyez informé en temps réel de l’avancement de votre formalité
            depuis votre espace client, et recevez des alertes par mail de toute
            évolution de son état (dossier incomplet, dossier transmis au
            greffe, Kbis disponible…).
          </p>
        </div>
        <div className="sarl-section4-part-item">
          <h3 className='w-auto text-darkblue text-balance text-center md:text-start text-lg font-bold font-inter mb-3'>Tarifs imbattables</h3>
          <p>
            Savez vous que notre formule express (formule tout inclus) est la
            moins chère en ligne ?
          </p>
          <p>
            Créez votre société au meilleur prix et profitez d&#39;un tarif
            totalement transparent et sans frais cachés.
          </p>
        </div>
        <div className="sarl-section4-part-item">
          <h3 className='w-auto text-darkblue text-balance text-center md:text-start text-lg font-bold font-inter mb-3'>Assistance en ligne</h3>
          <p>
            Contactez notre assistance par téléphone ou par courriel dès que
            vous en avez besoin afin de vous faire aider directement par
            l&#39;un de nos conseillers.
          </p>
        </div>
        <div className="sarl-section4-part-item">
          <h3 className='w-auto text-darkblue text-balance text-center md:text-start text-lg font-bold font-inter mb-3'>Une efficacité prouvée</h3>
          <p>
            Plus de 95% de nos dossiers transmis au greffe sont validés du 1er
            coup. Plus de 100 avis clients vérifiés peuvent être consultés sur
            notre plateforme.
          </p>
        </div>
      </div>
    </div>


  );
}
