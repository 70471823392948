import React from 'react';
import { Outlet } from 'react-router-dom';
import HeaderContainer from '../../../../components/headerContainer/headerContainer';
import Footer from '../../../../components/footer/footer';

function ContractCGV() {
  return (
    <div className="bg-white">
      <HeaderContainer />
      <Outlet />
      <Footer />
    </div>
  );
}

export default ContractCGV;