import React from 'react';
import contratLocationImage from '../../../../../assets/images/contratLocation.png';
import blnmImage from '../../../../../assets/images/contrat-location-non-meublee.jpg.png';
import blmImage from '../../../../../assets/images/contrat-location-meublee.jpg';
import bcImage from '../../../../../assets/images/bail-commercial.jpg';
import bpImage from '../../../../../assets/images/bail-professionnel.jpg.png';
import HeaderImage from '../../../../../components/headerImage/headerImage';
import HandleContent from '../../../../../components/handleContent/handleContent';
import Content from './content/content';
import './infoContratLocation.css';
import { Link } from 'react-router-dom';

export default function InfoContartLocation() {
  const modelesData = [
    {title: 'Bail de location non meublée', modelImage: blnmImage, link: '/contratLocation/bailLocationNonMeublee'},
    {title: 'Bail de location meublée', modelImage: blmImage, link: '/contratLocation/bailLocationMeublee'},
    {title: 'Bail commercial', modelImage: bcImage, link: '/contratLocation/bailCommercial'},
    {title: 'Bail professionnel', modelImage: bpImage, link: '/contratLocation/bailProfessionnel'},
  ];
  const itemsContent = [
    {title: 'Qu’est-ce qu’un contrat de location ?', link: '#definitionContratLocation'},
    {title: 'Quelles sont les réglementations encadrant le contrat de location ?', link: '#reglementationContratLocation'},
    {title: 'Comment rédiger un bail ?', link: '#redactionContratLocation'},
    {title: 'Comment modifier, résilier ou renouveler un contrat de location ?', link: '#modificationContratLocation'},
    {title: 'FAQ', link: '#faq'},
  ];
  const itemsOtherLinksLeft = [
    {
      title: 'Bail saisonnier',
      description: 'Contrat Location Saisonnière - Modèle Bail Saisonnier à Télécharger',
      link: '#'
    },
    {
      title: 'Caution solidaire',
      description: 'Cautionnement solidaire - Formulaire modèle cautionnement pour baux',
      link: '#'
    },
    {
      title: 'Etat des lieux pour bail d\'habitation',
      description: 'Modèle d\'état des lieux d\'entrée et de sortie pour contrat de location de logement',
      link: '#'
    },
    {
      title: 'Quittance de loyer (gratuit)',
      description: 'Modèle de quittance de loyer',
      link: '#'
    },
    {
      title: 'Avenant de renouvellement au bail commercial',
      description: 'Modèle pour renouvellement du bail commercial',
      link: '#'
    },
    {
      title: 'Bail précaire (dérogatoire)',
      description: 'Bail dérogatoire - Bail courte durée - Bail précaire',
      link: '#'
    },
    {
      title: 'Contrat de sous-location',
      description: 'Modèle de contrat de sous-location',
      link: '#'
    },
  ];
  const itemsOtherLinksRight = [
    {
      title: 'Contrat de location de box pour meubles',
      description: 'Contrat de location de box pour meubles - Formulaire modèle de bail box',
      link: '#'
    },
    {
      title: 'Contrat de location de parking, box, ou garage',
      description: 'Contrat de location de parking, box ou garage - Modèle de bail parking, box ou garage à imprimer',
      link: '#'
    },
    {
      title: 'Etat des lieux pour bail commercial',
      description: 'Modèle d\'état des lieux contradictoire pour baux commerciaux',
      link: '#'
    },
    {
      title: 'Lettre de résiliation du bail commercial',
      description: 'Modèle de lettre de résiliation du bail commercial',
      link: '#'
    },
    {
      title: 'Lettre de résiliation de bail (gratuit)',
      description: 'Modèle de lettre de résiliation du bail',
      link: '#'
    },
    {
      title: 'Contrat de location de salle',
      description: 'Bail de location de salle',
      link: '#'
    },
    {
      title: 'Caution du bail commercial',
      description: 'Contrat de location de parking,',
      link: '#'
    },
  ];
  return (
    <div className='contratLocation'>
      <HeaderImage
        title='Contrat de Location - Gratuit'
        image={contratLocationImage}
        modeles={modelesData}
      />
      <div className='otherLinksContratLocation'>
        <div className='leftOtherLinksContratLocation'>
          {itemsOtherLinksLeft.map((item) => (
            <div className='leftOtherLinksContratLocationItem' key={item.title}>
              <Link className='link' to={item.link}>{item.title}</Link>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
        <div className='rightOtherLinksContratLocation'>
          {itemsOtherLinksRight.map((item) => (
            <div className='rightOtherLinksContratLocationItem' key={item.title}>
              <Link className='link' to={item.link}>{item.title}</Link>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
      </div>
      <h1 className='titleContratLocation'>A propos des contrats de location</h1>
      <p className='descriptionContratLocation'>
        Un contrat de location doit être rédigé minutieusement selon le bien immobilier concerné et 
        les raisons qu’a le locataire de louer ce bien. En effet, les mentions du bail diffèrent selon 
        qu’il ait pour but de louer un local d’habitation ou un local commercial. De même il existe des 
        biens loués à titre de garage ou de stockage de biens meubles.
      </p>
      <p className='descriptionContratLocation'>
        Ainsi, l’objet du contrat est essentiel, puisqu’il détermine le contenu du bail et par conséquent 
        la procédure à suivre pour le rédiger.
      </p><br /><br /><br /><br />
      <HandleContent
        title='Contrat de location'
        textButton='Choisir un modèle'
        items={itemsContent}
        rightContent={<Content />}
      />
    </div>
  );
}
