import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import axios from 'axios';
import StepProgress from '../../../../components/stepProgress/stepProgress';
import HeaderContainer from '../../../../components/headerContainer/headerContainer';
import CustomTextInput from '../../../../components/inputs/customTextInput/customTextInput';
import getEnvironement from '../../../../environnement';
import number from '../../../../assets/icons/number.png';
import CustomSelect from '../../../../components/inputs/customSelect';
import { setUser, userSelector } from '../../../../store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import Packs from '../../packs';

export default function ChangementStatutForm() {
  const [currentStep, setCurrentStep] = useState(1);

  // entreprise
  const [denomination, setDenomination] = useState('');
  const [sigle, setSigle] = useState('');
  const [formeJuridique, setFormeJuridique] = useState('');
  const [rc, setRc] = useState('');
  // demandeur
  const [demandeur, setDemandeur] = useState('');
  //pack
  const [selectedPack, setSelectedPack] = useState();

  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  const saveData = async () => {
    const url = `${getEnvironement().API_URL}/changementStatut`;
    const data = {
      user_id: user.id,
      denomination,
      sigle,
      formeJuridique,
      rc,
      demandeur,
      packId: selectedPack
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      await axios.post(url, data, config)
        .then((response) => {
          if (response.status === 200) {
            dispatch(setUser(response.data));
            navigate('/dashboard');
          }
        });
    } catch (error) {
      console.log('Error saving data', error);
    }
  };

  const getQuestion = (currentStep) => {
    switch(currentStep) {
    case 1 :
      return (
        <QEntreprise
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          denomination={denomination}
          setDenomination={setDenomination}
          sigle={sigle}
          setSigle={setSigle}
          formeJuridique={formeJuridique}
          setFormeJuridique={setFormeJuridique}
          rc={rc}
          setRc={setRc}
        />
      );
    case 2 :
      return (
        <QDemandeur
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          setDemandeur={setDemandeur}
          handelEnregisterClick={saveData}
        />
      );
    case 3 :
      return (
        <Packs
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          userId={user.id}
          typePack='autres démarches'
          productType='changementStatut'
          setSelectedPack={setSelectedPack}
          data={{
            user_id: user.id,
            denomination,
            sigle,
            formeJuridique,
            rc,
            demandeur,
            packId: selectedPack
          }}
        />
      );
    case 4:
      return (
        <div className='mb-10'>
          <p className='text-base font-medium text-neutral mb-2'>Confirmation :</p>
          <div className='flex gap-5 mt-5'>
            <Button
              color='info'
              variant='outlined'
              onClick={() => {
                setCurrentStep(currentStep - 1);
              }}
            >
              {'< Retour'}
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={saveData}
            >
              Enregistrer
            </Button>
          </div>
        </div>
      );
    default :
      return (<div />);
    }
  };

  return (
    <div>
      <HeaderContainer />
      <div className='h-screen flex flex-col justify-start items-center px-10 mt-10'>
        <h3 className=''>CHANGEMENT DE STATUT</h3>
        <StepProgress numberSteps={4} currentStep={currentStep}/>
        <div className='w-fit md:w-[700px]'>
          {getQuestion(currentStep)}
        </div>
      </div>
    </div>

  );
}

function Personne({
  type,
  setPersonne
}) {
  const [nomPersonne, setNomPersonne] = useState('');
  const [prenomPersonne, setPrenomPersonne] = useState('');
  const [nationalitePersonne, setNationalitePersonne] = useState('');
  const [villeResidencePersonne, setVilleResidencePersonne] = useState('');
  const [cinPersonne, setCinPersonne] = useState('');
  const [gsmPersonne, setGsmPersonne] = useState('');
  const [emailPersonne, setEmailPersonne] = useState('');
  const [adressePersonne, setAdressePersonne] = useState('');

  useEffect(() => {
    setPersonne({
      type,
      nom: nomPersonne,
      prenom: prenomPersonne,
      nationalite: nationalitePersonne,
      cin: cinPersonne,
      email: emailPersonne,
      gsm: gsmPersonne,
      villeResidence: villeResidencePersonne,
      adresse: adressePersonne
    });
  }, [
    nomPersonne,
    prenomPersonne,
    nationalitePersonne,
    villeResidencePersonne,
    cinPersonne,
    gsmPersonne,
    emailPersonne,
    adressePersonne,
  ]);

  return (
    <>
      <div className='flex flex-col md:flex-row'>
        <CustomTextInput
          image={number}
          value={nomPersonne}
          setValue={setNomPersonne}
          placeholder='Nom' />
        <CustomTextInput
          image={number}
          value={prenomPersonne}
          setValue={setPrenomPersonne}
          placeholder='Prénom' />
      </div>
      <div className='flex flex-col md:flex-row'>
        <CustomTextInput
          image={number}
          value={nationalitePersonne}
          setValue={setNationalitePersonne}
          placeholder='Nationalité'
        />
        <CustomTextInput
          image={number}
          value={cinPersonne}
          setValue={setCinPersonne}
          placeholder='CIN'
        />
      </div>
      <div className='flex flex-col md:flex-row'>
        <CustomTextInput
          image={number}
          value={gsmPersonne}
          setValue={setGsmPersonne}
          placeholder='GSM'
        />
        <CustomTextInput
          image={number}
          value={emailPersonne}
          setValue={setEmailPersonne}
          placeholder='E-mail'
        />
      </div>
      <div className='flex flex-col md:flex-row'>
        <CustomTextInput
          image={number}
          value={villeResidencePersonne}
          setValue={setVilleResidencePersonne}
          placeholder='Ville de résidence'
        />
        <CustomTextInput
          image={number}
          value={adressePersonne}
          setValue={setAdressePersonne}
          placeholder='Adresse de résidence'
        />
      </div>
    </>
  );
}

//---------------Questions---------------------
function QEntreprise({
  currentStep,
  setCurrentStep,
  denomination,
  setDenomination,
  sigle,
  setSigle,
  formeJuridique,
  setFormeJuridique,
  rc,
  setRc
}) {
  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Dénomination :</p>
      <div className='flex flex-col md:flex-row'>
        <span className='w-2/3'>
          <CustomTextInput
            image={number}
            value={denomination}
            setValue={setDenomination}
            placeholder='Dénomination'
          />
        </span>
        <span className='w-1/3'>
          <CustomTextInput
            image={number}
            value={sigle}
            setValue={setSigle}
            placeholder='Abréviation'
          />
        </span>
      </div>
      <p className='text-base font-medium text-neutral mb-2'>Registre de commerce :</p>
      <CustomTextInput
        image={number}
        value={rc}
        setValue={setRc}
        placeholder='Numéro de registre de commerce (RC)'
      />
      <p className='text-base font-medium text-neutral mt-5 mb-2'>Forme Juridique :</p>
      <CustomSelect
        value={formeJuridique}
        setValue={setFormeJuridique}
        items={[
          {label: 'Société à responsabilité limitée', value: 'Société à responsabilité limitée'},
          {label: 'Société à responsabilité limitée à associé unique', value: 'Société à responsabilité limitée à associé unique'},
          {label: 'Société anonyme', value: 'Société anonyme'},
          {label: 'Société en nom collectif', value: 'Société en nom collectif'},
          {label: 'Société en commandite par actions', value: 'Société en commandite par actions'},
          {label: 'Groupement d\'intérêt économique', value: 'Groupement d\'intérêt économique'},
          {label: 'Société civile immobilière', value: 'Société civile immobilière'},
          {label: 'Société en participation', value: 'Société en participation'},
          {label: 'Société par actions simplifiée', value: 'Société par actions simplifiée'},
          {label: 'Succursale d’une société étrangère', value: 'Succursale d’une société étrangère'}
        ]}
      />
      <div className='flex gap-5 mt-5'>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
      </div>
    </div>
  );
}

function QDemandeur({
  currentStep,
  setCurrentStep,
  setDemandeur,
  // handelEnregisterClick,
}) {
  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Informations sur le demandeur :</p>
      <Personne type='demandeur' setPersonne={setDemandeur} />
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        {/* <Button
          color='primary'
          variant='contained'
          onClick={handelEnregisterClick}
        >
          Enregistrer
        </Button> */}
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
          Continuer
        </Button>
      </div>
    </div>
  );
}
