import React from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../../assets/images/LOGO-LS@2x.png';
import DocumentsTable from './documentsTable';

export default function DetailsProduct({
  setOpenDrawer,
  product,
}) {
  return (
    <div className='w-full'>
      <div className='flex p-5 justify-between items-center shadow-md'>
        <img src={logo} alt='logo' className='w-[300px]'/>
        <IconButton onClick={() => setOpenDrawer(false)}>
          <CloseIcon sx={{fontSize: 40}}/>
        </IconButton>
      </div>
      <div className='p-10'>
        <p className='font-poppins font-medium text-2xl mb-5'>Documents reçus :</p>
        <DocumentsTable
          header={['Id_document', 'Nom', 'Date', 'Document']}
          data={product.documents.filter((document) => document.type === 'reçu')}
          isProductPayed={product.is_payed}
        />
      </div>
      <div className='px-10'>
        <p className='font-poppins font-medium text-2xl mb-5'>Documents à légaliser :</p>
        <DocumentsTable
          header={['Id_document', 'Nom', 'Date', 'Document']}
          data={product.documents.filter((document) => document.type === 'légalisé')}
          isProductPayed={product.is_payed}
        />
      </div>
    </div>
  );
}
