import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function AssociationHeader() {
  const navigate = useNavigate();

  return (
    <div className=' p-10 lg:p-[120px] bg-gradient-to-b from-gradient to-white lg:mb-10 flex flex-col justify-center items-center gap-6 border-b-[1px] border-lightgrayv2'>
      <div className='flex flex-col justify-center items-center'>
        <h1 className='text-lg text-center mb-4 text-darkblue lg:text-start lg:text-2xl font-bold lg:mb-2'>Création <span className='text-primary'>d&apos;association</span> simple et rapide !</h1>
        <p className='text-center text-darkblue font-medium'>Statuts, PV d&apos;AG, démarches administratives : nous gérons la<br></br> création de votre association loi 1901 de A à Z !</p>
      </div>
      <div className='w-fit'>
        <Button
          variant='contained'
          onClick={() => navigate('form')}
        >
          Créer mon association
        </Button>
      </div>
    </div>
  );
}

export default AssociationHeader;