import React from 'react';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import DemarcheCreation from './components/demarcheCreation/demarcheCreation';
import HeaderTitles from './components/headerTitles/headerTitles';
import InfoCreationEntreprise from './components/infoCreationEntreprise/infoCreationEntreprise';

export default function CreationEntreprise() {
  return (
    <div className='creationEntrepriseContainer'>
      <Header active='Créer une entreprise' />
      <HeaderTitles />
      <DemarcheCreation />
      <InfoCreationEntreprise />
      <Footer />
    </div>
  );
}
