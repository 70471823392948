import React from 'react';
import contratTravailImage from '../../../../../assets/images/contratTravail.png';
import cdiImage from '../../../../../assets/images/contrat-de-travail-cdi-1.jpg.png';
import cddImage from '../../../../../assets/images/contrat-de-travail-cdd-1.jpg.png';
import HeaderImage from '../../../../../components/headerImage/headerImage';
import HandleContent from '../../../../../components/handleContent/handleContent';
import Content from './content/content';
import './infoContratTravail.css';

export default function InfoContartTravail() {
  const modelesData = [
    {title: 'Contrat de travail à durée indéterminée CDI', modelImage: cdiImage, link: '/contratTravail/cdi'},
    {title: 'Contrat de travail à durée déterminée CDD', modelImage: cddImage, link: '/contratTravail/cdd'},
  ];
  const itemsContent = [
    {title: 'Définition d’un contrat de travail', link: '#definitionContrat'},
    {title: 'Quels sont les contrats de travail existants ?', link: '#contratExistant'},
    {title: 'Les parties au contrat', link: '#partieContrat'},
    {title: 'Le formalisme du contrat de travail', link: '#formalisme'},
    {title: 'Comment rédiger un contrat de travail ?', link: '#redactionContrat'},
    {title: 'Les principales obligations de l’employeur', link: '#obligationEmployeur'},
    {title: 'Les principales obligations du salarié', link: '#obligationSalarie'},
    {title: 'L’utilisation d’un avenant pour modification du contrat de travail', link: '#avenant'},
    {title: 'Les situations entraînant la suspension du contrat de travail', link: '#suspension'},
  ];
  return (
    <div className='contratTravail'>
      <HeaderImage
        title='Contrat de travail'
        image={contratTravailImage}
        modeles={modelesData}
      />
      <h1 className='titleContratTravail'>A propos des contrats de travail</h1>
      <HandleContent
        title='Contrat de travail'
        textButton='Choisir un modèle'
        items={itemsContent}
        rightContent={<Content />}
      />
    </div>
  );
}
