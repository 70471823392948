import React from 'react';
import itemDomiciliationImage1 from '../../../../../../../assets/images/itemDomiciliation1.svg';
import itemDomiciliationImage2 from '../../../../../../../assets/images/itemDomiciliation2.svg';
import itemDomiciliationImage3 from '../../../../../../../assets/images/itemDomiciliation3.svg';
import etapeDomiciliationImage1 from '../../../../../../../assets/images/etapeDomiciliation1.png';
import etapeDomiciliationImage2 from '../../../../../../../assets/images/etapeDomiciliation2.png';
import etapeDomiciliationImage3 from '../../../../../../../assets/images/etapeDomiciliation3.png';
import './pourquoiDomiciliationLegalStation.css';
import { Button } from '@mui/material';

export default function PourquoiDomiciliationLegalStation() {
  return (
    <section className='pourquoiDomiciliationLegalStation'>
      <h1 className='title'>Pourquoi se domicilier avec LegalStation ?</h1>
      <div className='itemPourquoiDomiciliationLegalStationContainer'>
        <Item
          title='Jusqu’à 600 € d’économie'
          itemContent='En domiciliant votre société à Paris vous bénéficiez de la cotisation foncière des entreprises la plus faible de France (16,5% contre 31% en moyenne)'
          imgSrc={itemDomiciliationImage1}
        />
        <Item
          title='Scan et tri des courriers'
          itemContent='Recevez en quasi instantané vos courriers et gérez les de n’importe où grâce à notre plateforme dédiée.'
          imgSrc={itemDomiciliationImage2}
        />
        <Item
          title='Adresse prestigieuse'
          itemContent='Accueillez vos clients et partenaires dans votre siège social à deux pas des Champs Elysées.'
          imgSrc={itemDomiciliationImage3}
        />
      </div>
      <div className='etapesDomiciliation'>
        <h1 className='title etapeDomiciliationTitle'>Pourquoi se domicilier avec LegalStation ?</h1>
        <div className='itemEtapesDomiciliationLegalStationContainer'>
          <Item
            title='Personnalisez votre formule'
            itemContent='Répondez en quelques minutes à notre questionnaire et envoyez-nous vos pièces justificatives.'
            imgSrc={etapeDomiciliationImage1}
          />
          <Item
            title='Signez en ligne votre contrat'
            itemContent='Obtenez votre contrat rapidement et signez-le en ligne.'
            imgSrc={etapeDomiciliationImage2}
          />
          <Item
            title='Votre société est domiciliée !'
            itemContent='C’est bon, vos courriers arrivent chez nous et vous sont renvoyés (par scan et/ou courrier).'
            imgSrc={etapeDomiciliationImage3}
          />
        </div>
        <div className='etapesDomiciliationButtonContainer'>
          <Button variant='contained'>Commencer ma domiciliation</Button>
        </div>
      </div>
    </section>
  );
}


function Item({
  title,
  itemContent,
  imgSrc,
}) {
  return (
    <div className='itemContainer'>
      <img src={imgSrc} alt='' />
      <h3 className='ItemTitle'>{title}</h3>
      <p>{itemContent}</p>
    </div>
  );
}

