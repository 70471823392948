import React from 'react';
import './step1.css';

export default function Step1({ onClick = () => {} }) {
  const choices = [
    {
      icon: '#',
      label: 'Dès que possible',
    },
    {
      icon: '#',
      label: 'Dans la semaine',
    },
    {
      icon: '#',
      label: 'Dans le mois',
    },
    {
      icon: '#',
      label: 'Je ne sais pas encore',
    },
  ];

  return (
    <div className="step1-views">
      <h1 className="step1-title">CRÉATION DE SARL</h1>
      <h3 className="step1-question">
        Quel est le délai que vous avez prévu pour la création de votre
        entreprise?
      </h3>
      <div className="step1-choices">
        {choices.map((item, key) => (
          <div key={key} className="step1-choice" onClick={onClick}>
            <img src={item.icon} alt="icon" />
            <h3>{item.label}</h3>
          </div>
        ))}
      </div>
    </div>
  );
}
