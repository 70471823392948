import React from 'react';
import { Alert } from '@mui/material';
import CustomTextInput from '../../../../../components/inputs/customTextInput/customTextInput';
import CustomSelect from '../../../../../components/inputs/customSelect/customSelect';
import CustomCheckBox from '../../../../../components/inputs/customCheckBox/customCheckBox';

function QEmployeur({
  typeEmployeur,
  nomPrenomEmployeurIndividu,
  setNomPrenomEmployeurIndividu,
  denomination,
  setDenomination,
  forme,
  setforme,
  adresseEmployeurIndividu,
  setAdresseEmployeurIndividu,
  adresseSiege,
  setAdresseSiege,
  numeroRcs,
  setNumeroRcs,
  villeRc,
  setVilleRc,
  nomPrenomRepresentantSociete,
  setNomPrenomRepresentantSociete,
}) {
  return (
    <>
      <h1>01. L&apos;employeur</h1>
      <div className='formContainer'>
        <h3>Quelle est l&apos;identité de l&apos;employeur ?</h3>
        {typeEmployeur == 'individu'
          && (
            <div className='maxWidthBloc'>
              <CustomTextInput
                value={nomPrenomEmployeurIndividu}
                setValue={setNomPrenomEmployeurIndividu}
                label={'nom et prénom'}
              />
              <CustomTextInput
                value={adresseEmployeurIndividu}
                setValue={setAdresseEmployeurIndividu}
                label={'Adresse'}
              />
            </div>
          )
        }
        {typeEmployeur == 'entreprise'
          && (
            <div className='maxWidthBloc'>
              <CustomTextInput
                value={denomination}
                setValue={setDenomination}
                label='Dénomination de la société'
              />
              <CustomTextInput
                value={forme}
                setValue={setforme}
                label='Forme de la société'
              />
              <CustomTextInput
                value={adresseSiege}
                setValue={setAdresseSiege}
                label='Adresse du siège social'
              />
              <CustomTextInput
                value={numeroRcs}
                setValue={setNumeroRcs}
                label='Numéro de registre de commerce'
              />
              <CustomTextInput
                value={villeRc}
                setValue={setVilleRc}
                label='Ville du RCS (greffe du tribunal de commerce où la société est établie)'
              />
              <CustomTextInput
                value={nomPrenomRepresentantSociete}
                setValue={setNomPrenomRepresentantSociete}
                label='Prénom et nom du représentant de la société'
              />
            </div>
          )
        }
      </div>
    </>
  );
}

function QSalarie({
  civilite,
  setCivilite,
  nomSalarie,
  setNomSalarie,
  prenomSalarie,
  setPrenomSalarie,
  dateNaissanceSalarie,
  setDateNaissanceSalarie,
  lieuNaissanceSalarie,
  setLieuNaissanceSalarie,
  cin,
  setCin,
  adresseSalarie,
  setAdresseSalarie,
  nationaliteSalarie,
  setNationaliteSalarie,
}) {
  return (
    <>
      <h1>02. Le salarié</h1>
      <div className='formContainer'>
        <Alert className='infoAlert' severity='info'>Si vous ne savez pas encore, vous pouvez passer cette étape et la compléter ultérieurement avant la signature du contrat.</Alert>
        <h3>Quelle est l&apos;identité du salarié ?</h3>
        <div className='maxWidthBloc'>
          <CustomSelect
            value={civilite}
            setValue={setCivilite}
            label='Civilité'
            items={[
              { label: 'Madame', value: 'Madame' },
              { label: 'Monsieur', value: 'Monsieur' },
            ]}
          />
          <CustomTextInput
            value={nomSalarie}
            setValue={setNomSalarie}
            label='Nom'
          />
          <CustomTextInput
            value={prenomSalarie}
            setValue={setPrenomSalarie}
            label='Prénom'
          />
          <CustomTextInput
            value={dateNaissanceSalarie}
            setValue={setDateNaissanceSalarie}
            type='date'
            label='Date de naissance'
          />
          <CustomTextInput
            value={lieuNaissanceSalarie}
            setValue={setLieuNaissanceSalarie}
            label='Lieu de naissance'
          />
          <CustomTextInput
            value={cin}
            setValue={setCin}
            label='CIN'
          />
          <CustomTextInput
            value={adresseSalarie}
            setValue={setAdresseSalarie}
            label='Adresse'
          />
          <CustomTextInput
            value={nationaliteSalarie}
            setValue={setNationaliteSalarie}
            label='Nationalité'
          />
        </div>
      </div>
    </>
  );
}

function QDureeContrat({
  typeContart,
  dateDebutContrat,
  setDateDebutContrat,
  dateFinContrat,
  setDateFinContrat
}) {
  return (
    <>
      <h1>03. Durée du contrat</h1>
      <div className='formContainer'>
        <div className='maxWidthBloc'>
          <CustomTextInput
            value={dateDebutContrat}
            setValue={setDateDebutContrat}
            type='date'
            label='date de début du contrat'
          />
          {typeContart === 'cdd' 
          && <CustomTextInput
            value={dateFinContrat}
            setValue={setDateFinContrat}
            type='date'
            label='date de fin du contrat'
          />}
        </div>
        <Alert className='infoAlert' severity='info'>Si vous ne savez pas encore, vous pouvez passer cette étape et la compléter ultérieurement avant la signature du contrat.</Alert>
      </div>
    </>
  );
}

function QFonctionExercee({
  fonctionExercce,
  setFonctionExercce,
}) {
  return (
    <>
      <h1>04. fonctions exércées</h1>
      <div className='formContainer'>
        <h3>Quelle est la fonction qui sera exércée par le salarié ?</h3>
        <div className='maxWidthBloc'>
          <CustomTextInput
            value={fonctionExercce}
            setValue={setFonctionExercce}
            multiline
            rows={4}
            label='Précisez'
          />
        </div>
        <Alert className='infoAlert' severity='info'>Si vous ne savez pas encore, vous pouvez passer cette étape et la compléter ultérieurement avant la signature du contrat.</Alert>
      </div>
    </>
  );
}

function QPeriodeEssai({
  isPeriodeEssai,
  setIsPeriodeEssai,
  dureePeriodeEssai,
  setDureePeriodeEssai,
}) {
  return (
    <>
      <h1>05. période d&apos;éssai</h1>
      <div className='formContainer'>
        <h3>éxiste-t-il une période d&apos;éssai ?</h3>
        <div className='maxWidthBloc'>
          <CustomCheckBox
            value={isPeriodeEssai}
            setValue={setIsPeriodeEssai}
            label={'cochez cette case s\'il existe une période d\'essai'}
          />
          {isPeriodeEssai && (
            <CustomTextInput
              value={dureePeriodeEssai}
              setValue={setDureePeriodeEssai}
              type='number'
              label={'Précisez le nombre de mois de la période d\'éssai'}
            />
          )}
        </div>
        <Alert className='infoAlert' severity='info'>Si vous ne savez pas encore, vous pouvez passer cette étape et la compléter ultérieurement avant la signature du contrat.</Alert>
      </div>
    </>
  );
}

function QRemuniration({
  montantRemuneration,
  setMontantRemuneration,
  isRemunerationComplementaire,
  setIsRemunerationComplementaire,
  descriptionMontant,
  setDescriptionMontant,
  periodeVersement,
  setPeriodeVersement,
}) {
  return (
    <>
      <h1>06. Rémunération</h1>
      <div className='formContainer'>
        <h3>Quelle est le montant de la rémunération net mensuelle ?</h3>
        <div className='maxWidthBloc'>
          <CustomTextInput
            value={montantRemuneration}
            setValue={setMontantRemuneration}
            type='number'
            label='Précisez (en DH)'
          />
          <CustomCheckBox
            value={isRemunerationComplementaire}
            setValue={setIsRemunerationComplementaire}
            label='Il est prévu une rémunération complémentaire variable'
          />
          {isRemunerationComplementaire && (
            <>
              <CustomTextInput
                value={descriptionMontant}
                setValue={setDescriptionMontant}
                multiline
                rows={4}
                label='Décrire précisément le montant (fixe ou en pourcentage du salaire), les objectifs à atteindre et la période concernée (mois, trimestre, année)'
              />
              <CustomTextInput
                value={periodeVersement}
                setValue={setPeriodeVersement}
                multiline
                rows={4}
                label='Indiquer la période de versement si les objectifs sont atteints'
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

function QLieuTravail({
  lieutravail,
  setLieuTravail,
  isClauseMobilite,
  setIsClauseMobilite,
}) {
  return (
    <>
      <h1>07. lieu de travail - mobilité</h1>
      <div className='formContainer'>
        <h3>Quel est le lieu de travail ?</h3>
        <div className='maxWidthBloc'>
          <CustomTextInput
            value={lieutravail}
            setValue={setLieuTravail}
            label={'Précisez'}
          />
        </div>
        <h3>éxiste-t-il une clause de mobilité ?</h3>
        <div className='maxWidthBloc'>
          <CustomCheckBox
            value={isClauseMobilite}
            setValue={setIsClauseMobilite}
            label={'cochez cette case s\'il existe une clause de mobilité'}
          />
        </div>
        <Alert className='infoAlert' severity='info'>Si vous ne savez pas encore, vous pouvez passer cette étape et la compléter ultérieurement avant la signature du contrat.</Alert>
      </div>
    </>
  );
}

function QAutresClauses({
  isRemboursementFraisProfessionnels,
  setIsRemboursementFraisProfessionnels,
  isFormation,
  setIsFormation,
  isCouvertureSociale,
  setIsCouvertureSociale,
  isCongesAnnuelsPayes,
  setIsCongesAnnuelsPayes,
  nbreJoursCongesPayes,
  setNbreJoursCongesPayes,
  isAbsence,
  setIsAbsence,
  isClauseMateriel,
  setIsClausemateriel,
  isSecretProfessionnel,
  setIsSecretProfessionnel,
  isClauseNonConcurrence,
  setIsClauseNonConcurrence,
  dureeClauseNonConcurrence,
  setDureeClauseNonConcurrence,
  isClauseExclusivite,
  setIsClauseExclusivite,
  isPreavis,
  setIsPreavis,
  dureePreavis,
  setDureePreavis,
}) {
  return (
    <>
      <h1>08. Autres clauses</h1>
      <div className='formContainer'>
        <h3>Remboursement des frais professionnels :</h3>
        <div className='maxWidthBloc'>
          <CustomCheckBox
            value={isRemboursementFraisProfessionnels}
            setValue={setIsRemboursementFraisProfessionnels}
            label={'cochez cette case si l\'employé remboursera le salarié des frais professionnels engagés par lui dans le cadre de l\'exercice de ses fonctions'}
          />
        </div>
        <h3>Formation :</h3>
        <div className='maxWidthBloc'>
          <CustomCheckBox
            value={isFormation}
            setValue={setIsFormation}
            label={'cochez cette case si le salarié bénéficiera d\'une formation'}
          />
        </div>
        <h3>Couverture sociale :</h3>
        <div className='maxWidthBloc'>
          <CustomCheckBox
            value={isCouvertureSociale}
            setValue={setIsCouvertureSociale}
            label={'cochez cette case si Le salarié bénéficiera de son inscription auprès de la Caisse Nationale de Sécurité Sociale'}
          />
        </div>
        <h3>congés annuels payés :</h3>
        <div className='maxWidthBloc'>
          <CustomCheckBox
            value={isCongesAnnuelsPayes}
            setValue={setIsCongesAnnuelsPayes}
            label={'cochez cette case s\'il éxiste des congés payés'}
          />
          {isCongesAnnuelsPayes
            && <CustomTextInput
              value={nbreJoursCongesPayes}
              setValue={setNbreJoursCongesPayes}
              type='number'
              label={'précisez le nombre de jours de congés payés'}
            />}
        </div>
        <h3>absence et indisponibilité:</h3>
        <div className='maxWidthBloc'>
          <CustomCheckBox
            value={isAbsence}
            setValue={setIsAbsence}
            label={'cochez cette case s\'il éxiste une clause d\'absence'}
          />
        </div>
        <h3>Matériels, documents et propriété:</h3>
        <div className='maxWidthBloc'>
          <CustomCheckBox
            value={isClauseMateriel}
            setValue={setIsClausemateriel}
            label={'cochez cette case s\'il éxiste une clause de matériels, documents et propriété'}
          />
        </div>
        <h3>Secret professionnel:</h3>
        <div className='maxWidthBloc'>
          <CustomCheckBox
            value={isSecretProfessionnel}
            setValue={setIsSecretProfessionnel}
            label={'cochez cette case s\'il éxiste une clause de secret professionnel'}
          />
        </div>
        <h3>clause de non concurrence :</h3>
        <div className='maxWidthBloc'>
          <CustomCheckBox
            value={isClauseNonConcurrence}
            setValue={setIsClauseNonConcurrence}
            label={'cochez cette case s\'il éxiste une clause de non concurrence'}
          />
          {isClauseNonConcurrence
            && <CustomTextInput
              value={dureeClauseNonConcurrence}
              setValue={setDureeClauseNonConcurrence}
              type='number'
              label={'précisez le nombre d\'années de valabilité de cette clause aprés résiliation du contrat'}
            />}
        </div>
        <h3>exclusivité:</h3>
        <div className='maxWidthBloc'>
          <CustomCheckBox
            value={isClauseExclusivite}
            setValue={setIsClauseExclusivite}
            label={'cochez cette case s\'il éxiste une clause d\'exclusivité'}
          />
        </div>
        <h3>préavis :</h3>
        <div className='maxWidthBloc'>
          <CustomCheckBox
            value={isPreavis}
            setValue={setIsPreavis}
            label={'cochez cette case s\'il éxiste une durée de préavis'}
          />
          {isPreavis
            && <CustomTextInput
              value={dureePreavis}
              setValue={setDureePreavis}
              type='number'
              label={'précisez le nombre de mois de préavis'}
            />}
        </div>
      </div>
    </>
  );
}

function QEmail({
  emailDocument,
  setEmailDocument,
}) {
  return (
    <>
      <h1>9. Adresse e-mail où votre document doit être envoyé</h1>
      <div className='formContainer'>
        <div className='maxWidthBloc'>
          <CustomTextInput
            value={emailDocument}
            setValue={setEmailDocument}
            type='email'
            label='Adresse email'
          />
        </div>
        <Alert className='infoAlert' severity='info'>Nous ne partagerons vos informations avec personne</Alert>
      </div>
    </>
  );
}

export {
  QEmployeur,
  QSalarie,
  QDureeContrat,
  QFonctionExercee,
  QPeriodeEssai,
  QLieuTravail,
  QAutresClauses,
  QRemuniration,
  QEmail,
};