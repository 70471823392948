import React from 'react';
import { Outlet } from 'react-router-dom';

function Domiciliation() {
  return (
    <div className='domicialisation'>
      <Outlet />
    </div>
  );
}

export default Domiciliation;