import React from 'react';
import { Document } from '@react-pdf/renderer';
import Page1 from './page1';
import Page2 from './page2';

function PdfContent(props) {
  const {
    // F1
    bailleurSociete,
    bailleurCapital,
    bailleurVille,
    bailleurRepresentant,
    bailleurCin,
    // F2
    locataireSociete,
    locataireCapital,
    locataireSiegeSocial,
    locataireRepresentant,
    locataireQualite,
    locataireCin,
    // F3
    dateDebutBail,
    localUsage,
    localTitreFoncier,
    localSuperficie,
    localActivites,
    // F4
    localDescription,
    localRemiseClesDate,
    // F5
    dureeBail,
    preavisResiliation,
    // F6
    loyerMensuel,
    loyerPrincipal,
    charges,
    taxeEdilite,
    loyerTotal,
    paiementLoyerDate,
    // F7
    majorationLoyer,
    periodeTriennale,
    // F8
    cautionMontant,
    cautionBancaire,
    // F9
    domicileBailleur,
    domicileLocataire,
    // F10
    signatureDate,
  } = props;

  return (
    <Document>
      <Page1
        // F1
        bailleurSociete={bailleurSociete}
        bailleurCapital={bailleurCapital}
        bailleurVille={bailleurVille}
        bailleurRepresentant={bailleurRepresentant}
        bailleurCin={bailleurCin}
        // F2
        locataireSociete={locataireSociete}
        locataireCapital={locataireCapital}
        locataireSiegeSocial={locataireSiegeSocial}
        locataireRepresentant={locataireRepresentant}
        locataireQualite={locataireQualite}
        locataireCin={locataireCin}
        // F3
        dateDebutBail={dateDebutBail}
        localUsage={localUsage}
        localTitreFoncier={localTitreFoncier}
        localSuperficie={localSuperficie}
        localActivites={localActivites}
        // F4
        localDescription={localDescription}
        localRemiseClesDate={localRemiseClesDate}
        // F5
        dureeBail={dureeBail}
        preavisResiliation={preavisResiliation}
      />
      <Page2
        bailleurRepresentant={bailleurRepresentant}
        locataireRepresentant={locataireRepresentant}
        // F6
        loyerMensuel={loyerMensuel}
        loyerPrincipal={loyerPrincipal}
        charges={charges}
        taxeEdilite={taxeEdilite}
        loyerTotal={loyerTotal}
        paiementLoyerDate={paiementLoyerDate}
        // F7
        majorationLoyer={majorationLoyer}
        periodeTriennale={periodeTriennale}
        // F8
        cautionMontant={cautionMontant}
        cautionBancaire={cautionBancaire}
        // F9
        domicileBailleur={domicileBailleur}
        domicileLocataire={domicileLocataire}
        // F10
        signatureDate={signatureDate}
      />
    </Document>
  );
}

export default PdfContent;
