import React from 'react';

function IconTiles({icon, firsttext, secondtext}) {
  return (
    <div className="flex items-center gap-3">
      <img src={icon} className="w-9 h-9" />
      <div>
        <h3 className="font-medium ">{firsttext}</h3>
        <p className="subtitle">{secondtext}</p>
      </div>
    </div>
  );
}

export default IconTiles;
