import React from 'react';
import './infoDomiciliation.css';
import HeaderDomiciliation from './components/headerDomiciliation/headerDomiciliation';
import PourquoiDomiciliationLegalStation from './components/pourquoiDomiciliationLegalstation/pourquoiDomiciliationLegalStation';
import OffreDomiciliation from './components/offre/offre';
import Header from '../../../../../components/header/header';
import Footer from '../../../../../components/footer/footer';

function InfoDomiciliation() {
  return (
    <div className='infoDomiciliation'>
      <Header />
      <HeaderDomiciliation />
      <PourquoiDomiciliationLegalStation />
      <OffreDomiciliation />
      <Footer />
    </div>
  );
}

export default InfoDomiciliation;