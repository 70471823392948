import React from 'react';
import { Document } from '@react-pdf/renderer';
import Page1 from './page1';
import Page2 from './page2';
import Page3 from './page3';
import Page4 from './page4';
import Page5 from './page5';
import Page6 from './page6';
import Page7 from './page7';
import Page8 from './page8';

function CGVPdfContent(props) {
  const {
    // Page1
    chooseDomaineSite,
    vendeur,
    numéroTéléphoneVendeur,
    adresseEmailVendeur,
    isNuméroTVAVendeur,
    numéroTVAVendeur,
    formeSocialeVendeur,
    nomSociétéVendeur,
    montantCapitalVendeur,
    villeImmatriculation_RCSVendeur,
    numéroSIRENVendeur,
    adresseSiègeSocialVendeur,
    nomVendeur,
    adresseDomiciliationVendeur,
    numéroInscriptionRCS_RépertoireMétiers,
    territoires,
    territoiresList,
    produitProposés,
    dateMiseVigueurCGV,
    // Page2
    isPrixShow,
    DuréeValidité,
    processusCommandes,
    // Page3
    isClientCompte,
    clientCompteEmail,
    modesPaiementPossibles,
    isPaiementVirementPossible,
    momentPaiement,
    isEchéancierSansFrais,
    EchéancierSansFrais,
    isExigiblePassationCommande,
    pourcentagePrix,
    joursAprèsCommande,
    Echéancier,
    // Page4
    isLivraisonsAutreZones,
    livraisonsAutreZonesList,
    délaisLivraisons,
    délaisAnnulerCommande,
    isTransporteurIndépendant,
    isLivraisonsAvoirLieuEnMagasin,
    adressesMagasins,
    délaiMaxRéclamation,
    modeRéclamation,
    momentTransfertPropriété,
    // Page5
    délaiMaximal_Remboursement_Remplacement_Réparation,
    isGarantieContractuelle,
    conditionsGarantieContractuelle,
    situationsGarantieApplique,
    situationsGarantiePasApplique,
    conditionsApplicationGarantieContractuelle,
    // Page6
    finalitéTraitementDonnéesPersonnellesCollectées,
    isAutreFinalitéTraitementDonnéesPersonnelles,
    autreFinalitéTraitementDonnéesPersonnelles,
    dPCC_OuvertureCompte_VenteProduits,
    autreDonnéesPersonnellesClientsCollectéesList,
    donnéesPersonnellesCollectées_Entreprise_Partenaires,
    DPCEP_isPrestataireTransport,
    DPCEP_isPrestataireEtablissementPaiement,
    DPCEP_autres,
    DPCEP_isAutres,
    responsableTraitementDonnéesPersonnelles,
    responsableTraitementDonnéesPersonnellesIdentité,
    isDPOexiste,
    DPOIdentité,
    moyensDontDisposeClientSupprimerDonnéesPersonnelles,
    // Page7
    nomServiceMédiation,
    adressePostaleSrviceMédiation,
    siteInternetServiceMédiation,
    adresseEmailServiceMédiation,
  } = props;
  return (
    <Document>
      <Page1
        chooseDomaineSite={chooseDomaineSite}
        vendeur={vendeur}
        numéroTéléphoneVendeur={numéroTéléphoneVendeur}
        adresseEmailVendeur={adresseEmailVendeur}
        isNuméroTVAVendeur={isNuméroTVAVendeur}
        numéroTVAVendeur={numéroTVAVendeur}
        formeSocialeVendeur={formeSocialeVendeur}
        nomSociétéVendeur={nomSociétéVendeur}
        montantCapitalVendeur={montantCapitalVendeur}
        villeImmatriculation_RCSVendeur={villeImmatriculation_RCSVendeur}
        numéroSIRENVendeur={numéroSIRENVendeur}
        adresseSiègeSocialVendeur={adresseSiègeSocialVendeur}
        nomVendeur={nomVendeur}
        adresseDomiciliationVendeur={adresseDomiciliationVendeur}
        numéroInscriptionRCS_RépertoireMétiers={numéroInscriptionRCS_RépertoireMétiers}
        territoires={territoires}
        territoiresList={territoiresList}
        produitProposés={produitProposés}
        dateMiseVigueurCGV={dateMiseVigueurCGV}
      />
      <Page2
        chooseDomaineSite={chooseDomaineSite}
        isPrixShow={isPrixShow}
        DuréeValidité={DuréeValidité}
        processusCommandes={processusCommandes}
      />
      <Page3
        chooseDomaineSite={chooseDomaineSite}
        isClientCompte={isClientCompte}
        clientCompteEmail={clientCompteEmail}
        modesPaiementPossibles={modesPaiementPossibles}
        isPaiementVirementPossible={isPaiementVirementPossible}
        momentPaiement={momentPaiement}
        isEchéancierSansFrais={isEchéancierSansFrais}
        EchéancierSansFrais={EchéancierSansFrais}
        isExigiblePassationCommande={isExigiblePassationCommande}
        pourcentagePrix={pourcentagePrix}
        joursAprèsCommande={joursAprèsCommande}
        Echéancier={Echéancier}
      />
      <Page4
        isLivraisonsAutreZones={isLivraisonsAutreZones}
        livraisonsAutreZonesList={livraisonsAutreZonesList}
        délaisLivraisons={délaisLivraisons}
        délaisAnnulerCommande={délaisAnnulerCommande}
        isTransporteurIndépendant={isTransporteurIndépendant}
        isLivraisonsAvoirLieuEnMagasin={isLivraisonsAvoirLieuEnMagasin}
        adressesMagasins={adressesMagasins}
        délaiMaxRéclamation={délaiMaxRéclamation}
        modeRéclamation={modeRéclamation}
        momentTransfertPropriété={momentTransfertPropriété}
      />
      <Page5
        chooseDomaineSite={chooseDomaineSite}
        délaiMaximal_Remboursement_Remplacement_Réparation={délaiMaximal_Remboursement_Remplacement_Réparation}
        isGarantieContractuelle={isGarantieContractuelle}
        conditionsGarantieContractuelle={conditionsGarantieContractuelle}
        situationsGarantieApplique={situationsGarantieApplique}
        situationsGarantiePasApplique={situationsGarantiePasApplique}
        conditionsApplicationGarantieContractuelle={conditionsApplicationGarantieContractuelle}
      />
      <Page6
        chooseDomaineSite={chooseDomaineSite}
        finalitéTraitementDonnéesPersonnellesCollectées={finalitéTraitementDonnéesPersonnellesCollectées}
        isAutreFinalitéTraitementDonnéesPersonnelles={isAutreFinalitéTraitementDonnéesPersonnelles}
        autreFinalitéTraitementDonnéesPersonnelles={autreFinalitéTraitementDonnéesPersonnelles}
        dPCC_OuvertureCompte_VenteProduits={dPCC_OuvertureCompte_VenteProduits}
        autreDonnéesPersonnellesClientsCollectéesList={autreDonnéesPersonnellesClientsCollectéesList}
        donnéesPersonnellesCollectées_Entreprise_Partenaires={donnéesPersonnellesCollectées_Entreprise_Partenaires}
        DPCEP_isPrestataireTransport={DPCEP_isPrestataireTransport}
        DPCEP_isPrestataireEtablissementPaiement={DPCEP_isPrestataireEtablissementPaiement}
        DPCEP_autres={DPCEP_autres}
        DPCEP_isAutres={DPCEP_isAutres}
        responsableTraitementDonnéesPersonnelles={responsableTraitementDonnéesPersonnelles}
        responsableTraitementDonnéesPersonnellesIdentité={responsableTraitementDonnéesPersonnellesIdentité}
        isDPOexiste={isDPOexiste}
        DPOIdentité={DPOIdentité}
        moyensDontDisposeClientSupprimerDonnéesPersonnelles={moyensDontDisposeClientSupprimerDonnéesPersonnelles}
      />
      <Page7
        chooseDomaineSite={chooseDomaineSite}
        nomServiceMédiation={nomServiceMédiation}
        adressePostaleSrviceMédiation={adressePostaleSrviceMédiation}
        siteInternetServiceMédiation={siteInternetServiceMédiation}
        adresseEmailServiceMédiation={adresseEmailServiceMédiation}
      />
      <Page8
        chooseDomaineSite={chooseDomaineSite}
        vendeur={vendeur}
        formeSocialeVendeur={formeSocialeVendeur}
        nomSociétéVendeur={nomSociétéVendeur}
        adresseSiègeSocialVendeur={adresseSiègeSocialVendeur}
        nomVendeur={nomVendeur}
        adresseDomiciliationVendeur={adresseDomiciliationVendeur}
      />
    </Document>
  );
}

export default CGVPdfContent;
