import React from 'react';
import {
  Page, Text, View, Document, StyleSheet, Font
} from '@react-pdf/renderer';

// font
Font.register({
  family: 'Inter',
  fonts: [
    { src: './font/Inter-Regular.ttf' }, // Normal
    { src: './font/Inter-Bold.ttf', fontWeight: 'bold' }, // Gras
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 50,
  },
  header: {
    margin: 10,
    padding: 10,
    textAlign: 'center',
    fontSize: 14
  },
  body: {
    fontSize: 10,
  },
  section: {
    marginTop: 10,
  },
  titre: {
    textAlign: 'center',
    textDecoration: 'underline',
    marginTop: 15,
  },
  article: {
    textDecoration: 'underline',
    marginTop: 15,
  },
});

function PDFDocument({
  denomination,
  abreviation,
  objetsSocial,
  siegeSocial,
  MembresFondateurs,
}) {

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text fixed>ASSOCIATION {denomination}</Text>
          <Text>===========================</Text>
          <Text>STATUTS</Text>
        </View>
        <View style={styles.body}>
          
          <Text style={styles.titre}>  
            TITRE PREMIER : CREATION – DENOMINATION – OBJET – SIEGE – DUREE
          </Text>
  
          <Text style={styles.article}>ARTICLE 1 : CREATION</Text>
          <View style={styles.section}>
            <Text>
              Il est créée, conformément à la loi n° 75-00 modifiant et complétant le dahir du 3 joumada I 1378 (15 novembre 1958) réglementant le droit d&apos;association, une Association.
            </Text>
          </View>
  
          <Text style={styles.article}>
            ARTICLE 2 : DENOMINATION
          </Text>
          <View style={styles.section}>
            <Text>
            Cette Association prend la dénomination : {denomination}, par abréviation «{abreviation}».
            </Text>
          </View>
          
          <Text style={styles.article}>ARTICLE 3 : OBJET</Text>
          <View style={styles.section}>
            <Text style={styles.text}>
              L’Association n’a pas de but lucratif et se donne pour objet de :
            </Text>
            <View style={styles.section}>
              {objetsSocial.map((item) => {
                return (
                  <Text key={item.id}>- {item.objetSocial}</Text>
                );
              })}
            </View>
          </View>

          <Text style={styles.article}>ARTICLE 4 : SIEGE SOCIAL</Text>
          <View style={styles.section}>
            <Text>
              Le siège social de l’Association est établi à {siegeSocial}.
            </Text>
            <Text style={styles.section}>
              Le transfert du siège dans tout autre lieu que {siegeSocial}. aura lieu par décision de l’Assemblée Générale Extraordinaire.
            </Text>
          </View>

          <Text style={styles.article}>ARTICLE 5 : DUREE</Text>
          <View style={styles.section}>
            <Text>
            La durée de l’Association est illimitée.
            </Text>
          </View>

          <Text style={styles.titre}>TITRE DEUXIEME : L’ADHESION</Text>
          
          <Text style={styles.article}>ARTICLE 6 : GENERALITES</Text>
          <View style={styles.section}>
            <Text>
              Les membres de l&apos;Association doivent concourir à tout ou partie de l&apos;objet défini à l&apos;article 3. Ils constituent l&apos;Assemblée Générale de l&apos;Association.
            </Text>
            <Text style={styles.section}>
              Chaque membre s’engage à verser les cotisations fixées par le Conseil d’Administration et votées en Assemblée Générale. La perte de la qualité de membre au cours d’un exercice n’a aucune incidence sur les cotisations dues au cours de cet exercice.
            </Text>
          </View>

          <Text style={styles.article}>ARTICLE 7 : MEMBRES FOBDATEURS</Text>
          <View style={styles.section}>
            {MembresFondateurs.map((MembresFondateur) => {
              return (
                MembresFondateur.data.type == 'Personne physique'
                  ? (
                    <Text key={MembresFondateur.id} style={styles.section}>
                      - M. {`${MembresFondateur.data.nom} ${MembresFondateur.data.prenom}`}, {MembresFondateur.data.nationalite}, demeurant à {MembresFondateur.data.villeResidence}, {MembresFondateur.data.adresse}, titulaire de la CIN n° {MembresFondateur.data.cin};
                    </Text>
                  )
                  : (
                    <Text key={MembresFondateur.id} style={styles.section}>
                      - {MembresFondateur.data.denomination}, {MembresFondateur.data.formeJuridique} au capital social de {MembresFondateur.data.capitalSocial} Dirhams, dont le siège social est fixé à {MembresFondateur.data.villeSciege}, {MembresFondateur.data.adresseSiege}, immatriculée au registre de commerce sous le n° {MembresFondateur.data.rc} et représentée par M. {`${MembresFondateur.data.nomRepresentant} ${MembresFondateur.data.prenomRepresentant}`}, titulaire de la CIN {MembresFondateur.data.cinRepresentant};
                    </Text>
                  )
              );
            })}
            <Text style={styles.section}>
              Les membres Fondateurs de l’Association sont tous signataires des présents statuts.
            </Text>
            <Text style={styles.section}>
              A partir de la fondation de l’Association, la liste des membres est établie annuellement par le Conseil d’Administration ; elle est communiquée à l’ensemble des personnes intéressées, et tout particulièrement au Comité d&apos;Orientation Stratégique et à l’Assemblée Générale de l’Association.
            </Text>
          </View>

        </View>
      </Page>
    </Document>
  );
}

export default PDFDocument;