import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function Section2() {
  const [expanded, setExpanded] = useState('panel1');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const AccordStyles = {
    num: {
      width: '10%',
      flexShrink: 0,
      color: '#77808B',
      fontSize: 16,
      fontWeight: 600,
    },
    title: {
      color: '#051259',
      fontSize: 16,
      fontWeight: 700,
      fontFamily: 'Raleway, Arial, sans-serif',
      textTransform: 'capitalize',
    },
    details: {
      color: '#CFD3D7',
      fontSize: 16,
      fontWeight: 400,
      fontFamily: 'Roboto, Arial, sans-serif',
      padding: '0 60px',
    },
  };

  return (
    <div className="p-5 flex flex-col gap-5 justify-center items-center w-auto lg:px-[20%]">
      <h1 className="w-auto text-darkblue text-balance text-center text-3xl font-bold font-inter">Questions fréquentes</h1>
      <div className="sarl-section2-part">
        <Accordion
          sx={{ width: '100%' }}
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={AccordStyles.num}>01</Typography>
            <Typography sx={AccordStyles.title}>Qui sommes-nous ?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={AccordStyles.details}>
              Jurisociete.fr est une plateforme de gestion en ligne de toutes
              vos formalités juridiques : création de sociétés, modifications
              statutaires, Ago annuelle.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{ width: '100%' }}
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={AccordStyles.num}>02</Typography>
            <Typography sx={AccordStyles.title}>
              Comment Legalstation se distingue-t-il des autres sites juridiques
              en ligne ?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={AccordStyles.details}>
              Jurisociete.fr est une plateforme de gestion en ligne de toutes
              vos formalités juridiques : création de sociétés, modifications
              statutaires, Ago annuelle.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{ width: '100%' }}
          expanded={expanded === 'panel3'}
          onChange={() => { }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={AccordStyles.num}>03</Typography>
            <Typography sx={AccordStyles.title}>Comment puis-je partager mon avis sur mon expérience Legalstation?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={AccordStyles.details}>
              lorem ipsum dolor sit
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{ width: '100%' }}
          expanded={expanded === 'panel4'}
          onChange={() => { }}
        >
          <AccordionSummary
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography sx={AccordStyles.num}>04</Typography>
            <Typography sx={AccordStyles.title}>
              Que faites-vous de mes données personnelles et comment sont-elles stockées ?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={AccordStyles.details}>
              Jurisociete.fr est une plateforme de gestion en ligne de toutes
              vos formalités juridiques : création de sociétés, modifications
              statutaires, Ago annuelle.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
