import React, { useState } from 'react';
import { Link, useNavigate, } from 'react-router-dom';
import { Button } from '@mui/material';
import casablanca from '../../../assets/icons/casablanca.png';
import rabat from '../../../assets/icons/rabat.png';
import StepProgress from '../../../components/stepProgress/stepProgress';
import HeaderContainer from '../../../components/headerContainer/headerContainer';
import CustomRadioBox from '../../../components/inputs/customRadioBox/customRadioBox';
import './FormeJuridique.css';

export default function FormeJuridique() {
  const [currentStep, setCurrentStep] = useState(1);

  const [chosenActivity, setChosenActivity] = useState('');
  const [societeStatut, setSocieteStatut] = useState('');


  const navigate = useNavigate();

  const handleContinue = () => {
    setCurrentStep(currentStep + 1);
    const selectedPage = societeStatut === 'SAS' ? '/sas/form' : '/sarl/form';
    navigate(selectedPage);
  };



  const getQuestion = (currentStep) => {
    switch (currentStep) {
    case 1:
      return (<Q1 setCurrentStep={setCurrentStep} currentStep={currentStep} setValue={setChosenActivity} />);

    case 2:
      if (chosenActivity == 'Seul') {
        return (<QOne setCurrentStep={setCurrentStep} currentStep={currentStep} setValue={setChosenActivity} />);
      } else {
        return (<Q2 setCurrentStep={setCurrentStep} currentStep={currentStep} societeStatut={societeStatut} setSocieteStatut={setSocieteStatut} handleContinue={handleContinue} />);
      }
    default:
      return (<Q1 setCurrentStep={setCurrentStep} currentStep={currentStep} />);
    }
  };


  return (
    <div className='sarlForm'>
      <HeaderContainer>
        <StepProgress numberSteps={4} currentStep={currentStep} />
        <h3 className='title'>CHOIX DES STATUTS</h3>
        {getQuestion(currentStep)}
      </HeaderContainer>
    </div>

  );
}

function CardItem({
  id, srcIcon, content, setCurrentStep, currentStep, setValue
}) {
  return (
    <div className='cardItemContainer'>
      <Button
        className='cardItem'
        onClick={() => {
          setValue(id);
          setCurrentStep(currentStep + 1);
        }}
      >
        <div className='iconContainer'>
          <img src={srcIcon} alt='' />
        </div>
        <p>{content}</p>
      </Button>
    </div>
  );
}

//---------------Questions---------------------
function Q1({
  currentStep, setCurrentStep, setValue
}) {
  return (
    <div className='Q1'>
      <h6>Vous lancez votre activité :</h6>
      <div className='q1CardContainer'>
        <CardItem
          id='Seul'
          setValue={setValue}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          srcIcon={casablanca}
          content='Seul'
        />
        <CardItem
          id='A Plusieurs'
          setValue={setValue}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          srcIcon={rabat}
          content='A Plusieurs'
        />
      </div>
    </div>
  );
}
function QOne({ currentStep, setCurrentStep, setValue }) {
  return (
    <div className='Q1'>
      <h6>Choisissez le statut de votre société :</h6>
      <div className='q1CardContainer'>
        <CardItem
          id='SASU'
          setValue={setValue}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          srcIcon={casablanca}
          content='SASU'
        />
        <CardItem
          id='EURL'
          setValue={setValue}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          srcIcon={rabat}
          content='EURL'
        />
      </div>
    </div>
  );
}


function Q2({societeStatut, setSocieteStatut, handleContinue }) {
  return (
    <div className='Q5'>
      <h6>Choisissez le statut de votre société :</h6>
      <div className='contentContainer'>
        <div className='radioBoxContainer'>
          <CustomRadioBox
            row={false}
            value={societeStatut}
            setValue={setSocieteStatut}
            items={[
              { label: 'SAS', value: 'SAS' },
              { label: 'SARL', value: 'SARL' },
            ]}
          />
        </div>
        <div className='q2ButtonContainer'>
          <Button variant='contained' onClick={handleContinue}>
                        Continuer
          </Button>
          <Link to="/myStatus" className='link textContent'>
            {'💡 Je ne sais pas quel statut choisir'}
          </Link>
        </div>
      </div>
    </div>
  );
}



