import React from 'react';

export default function Article({
  image,
  rubrique,
  date,
  title,
  description,
  actorName,
  actorProfession,
  isFirstArticle = false,
  onClick,
}) {
  const colors = ['bg-[#3498db]', 'bg-[#2ecc71]', 'bg-[#e74c3c]', 'bg-[#9b59b6]'];
  const avatarColor = colors[Math.floor(Math.random() * colors.length)];

  return (
    <div className={`font-inter flex gap-10 cursor-pointer ${isFirstArticle ? 'flex-row' : 'flex-col'}`} onClick={onClick}>
      <img className={`${isFirstArticle ? 'w-[48.5%]' : 'w-full'} rounded-[25px]`} src={image} alt='article' />
      <div>
        <div className='flex mb-5 items-center'>
          <p className='text-2xl text-primary font-medium'>{rubrique}</p>
          <p className='mx-2 w-5 border border-[#0A093D]' />
          <p className='text-2xl text-[#656464] font-medium'>{date}</p>
        </div>
        <h1 className='text-3xl text-[#0A093D] font-large my-2'>{title}</h1>
        <p className='text-base font-normal text-[#656464] my-2'>{description}</p>
        <div className='flex items-center gap-5 mt-5'>
          <div className={`w-10 h-10 text-white rounded-full ${avatarColor} flex justify-center items-center`}>{actorName.charAt(0).toUpperCase()}</div>
          <div>
            <p className='font-medium text-[#0A093D] text-xl'>{actorName}</p>
            <p className='text-base font-normal text-[#656464]'>{actorProfession}</p>
          </div>
        </div>
      </div>
    </div>
  );
}