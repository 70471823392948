import React from 'react';
import WhyLegal from '../../../../../../assets/images/whyLegal.png';
import WhyTrust from '../../../../../../assets/images/whyTrust.png';
// import './section1.css';

export default function Section1() {
  return (
    <div className="px-10 lg:px-[120px]">
      <h1 className="text-darkblue text-xl font-bold text-center w-auto mb-10 md:w-1/2 md:text-center m-auto">
        Plus de 2000 de personnes nous font confiance en tant
        qu&apos;entrepreneurs.
      </h1>
      <div className="flex flex-col justify-between items-center gap-10 mb-10 lg:flex-row lg:px-20">
        <div className='w-auto lg:w-1/3'>
          <h3 className='text-darkblue text-2xl text-center mb-2 lg:text-start'>Pourquoi Legalstation ?</h3>
          <p className='text-grayv3 text-center m-auto lg:text-start'>
            Libérez votre potentiel en lançant votre propre entreprise et
            laissez-nous gérer les détails juridiques pour vous. Consacrez-vous
            à ce qui vous passionne, nous nous occupons du reste.
          </p>
        </div>
        <div className="sarl-section1-part-image">
          <img src={WhyLegal} alt="img" />
        </div>
      </div>
      <div className="flex flex-col justify-between items-center gap-10 mb-10 lg:flex-row-reverse lg:px-20">
        <div className='w-auto lg:w-1/2'>
          <h3 className='text-darkblue text-2xl text-center mb-2 lg:text-end'>Pourquoi nous faire confiance ?</h3>
          <p className='text-grayv3 text-center m-auto lg:text-end mb-3'>
            Notre service juridique est forgé par des experts en droit, pour
            vous offrir une protection sans faille
          </p>
          <p className='text-grayv3 text-center m-auto lg:text-end mb-3'>
            Vous pouvez dormir sur vos deux oreilles, vos informations sont
            protégées comme un trésor dans un coffre-fort.
          </p>
          <p className='text-grayv3 text-center m-auto lg:text-end mb-3'>
            Vous êtes protégé à 100% - satisfaction garantie ou remboursement
            complet. Vos données sont en sécurité
          </p>
        </div>
        <div className="sarl-section1-part-image">
          <img src={WhyTrust} alt="img" />
        </div>
      </div>
    </div>
  );
}
