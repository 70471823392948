import React from 'react';
import { Button } from '@mui/material';
import domiciliationContactUsImage from '../../../../assets/images/domiciliationLanding.png';
import comptabiliteContactUsImage from '../../../../assets/images/comptabiliteLanding.png';
import './contactUs.css';

export default function ContactUs() {
  return (
    <section className='contactUsContainer'>
      <div className='contactUs'>
        <div className='domiciliationContactUs'>
          <img src={domiciliationContactUsImage} />
          <div className='domiciliationContent'>
            <h1>Domiciliation</h1>
            <p>Afin d’achever sa mission à 100% et pour vous accompagner à la réussite de lancement de votre projet,</p>
            <p>
              Creaste Maroc propose un accompagnement Digital allant de Création de Site Web, Publicité Digitale, le Emailing et le Marketing Digital
            </p>
            <div className='contactUsButton'>
              <Button className='button' variant="contained" size='large'>Contactez nous</Button>
            </div>
          </div>
        </div>
        <div className='comptabiliteContactUs'>
          <img src={comptabiliteContactUsImage} />
          <div className='comptabiliteContent'>
            <h1>Comptabilité</h1>
            <p>Afin d’achever sa mission à 100% et pour vous accompagner à la réussite de lancement de votre projet,</p>
            <p>
              Creaste Maroc propose un accompagnement Digital allant de Création de Site Web, Publicité Digitale, le Emailing et le Marketing Digital
            </p>
            <div className='contactUsButtonComptabilte'>
              <Button className='button' variant="contained" size='large'>Contactez nous</Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
