import React, { useState } from 'react';
import StepProgress from '../../../../../../components/stepProgress/stepProgress';
import { Button } from '@mui/material';
import CustomTextInput from '../../../../../../components/inputs/customTextInput/customTextInput';
import { BlobProvider } from '@react-pdf/renderer';
import getEnvironement from '../../../../../../environnement';
import axios from 'axios';
import PdfContent from '../bailCommercialPdf/pdfcontent';
import usericon from '../../../../../../assets/icons/usericon.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, userSelector } from '../../../../../../store/userSlice';

function BailCommercialForm() {
  // form 1
  const [bailleurSociete, setBailleurSociete] = useState('');
  const [bailleurCapital, setBailleurCapital] = useState('');
  const [bailleurVille, setBailleurVille] = useState('');
  const [bailleurRepresentant, setBailleurRepresentant] = useState('');
  const [bailleurCin, setBailleurCin] = useState('');

  const [locataireSociete, setLocataireSociete] = useState('');
  const [locataireCapital, setLocataireCapital] = useState('');
  const [locataireSiegeSocial, setLocataireSiegeSocial] = useState('');
  const [locataireRepresentant, setLocataireRepresentant] = useState('');
  const [locataireQualite, setLocataireQualite] = useState('');
  const [locataireCin, setLocataireCin] = useState('');

  const [dateDebutBail, setDateDebutBail] = useState('');
  const [localUsage, setLocalUsage] = useState('');
  const [localTitreFoncier, setLocalTitreFoncier] = useState('');
  const [localSuperficie, setLocalSuperficie] = useState('');
  const [localActivites, setLocalActivites] = useState('');

  const [localDescription, setLocalDescription] = useState('');
  const [localRemiseClesDate, setLocalRemiseClesDate] = useState('');

  const [dureeBail, setDureeBail] = useState('');
  const [preavisResiliation, setPreavisResiliation] = useState('');

  const [loyerMensuel, setLoyerMensuel] = useState('');
  const [loyerPrincipal, setLoyerPrincipal] = useState('');
  const [charges, setCharges] = useState('');
  const [taxeEdilite, setTaxeEdilite] = useState('');
  const [loyerTotal, setLoyerTotal] = useState('');
  const [paiementLoyerDate, setPaiementLoyerDate] = useState('');

  const [majorationLoyer, setMajorationLoyer] = useState('');
  const [periodeTriennale, setPeriodeTriennale] = useState('');

  const [cautionMontant, setCautionMontant] = useState('');
  const [cautionBancaire, setCautionBancaire] = useState('');

  const [domicileBailleur, setDomicileBailleur] = useState('');
  const [domicileLocataire, setDomicileLocataire] = useState('');

  const [signatureDate, setSignatureDate] = useState('');

  const [currentStep, setCurrentStep] = useState(1);

  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  const saveData = async (pdfBlob) => {
    const url = `${getEnvironement().API_URL}/contractBailCommercial`;
    if (user == null) {
      return alert('try to sign in pls');
    }

    const data = new FormData();
    data.append('user_id', user.id);
    data.append('bailleurSociete', bailleurSociete);
    data.append('bailleurCapital', bailleurCapital);
    data.append('bailleurVille', bailleurVille);
    data.append('bailleurRepresentant', bailleurRepresentant);
    data.append('bailleurCin', bailleurCin);
    data.append('locataireSociete', locataireSociete);
    data.append('locataireCapital', locataireCapital);
    data.append('locataireSiegeSocial', locataireSiegeSocial);
    data.append('locataireRepresentant', locataireRepresentant);
    data.append('locataireQualite', locataireQualite);
    data.append('locataireCin', locataireCin);
    data.append('dateDebutBail', dateDebutBail);
    data.append('localUsage', localUsage);
    data.append('localTitreFoncier', localTitreFoncier);
    data.append('localSuperficie', localSuperficie);
    data.append('localActivites', localActivites);
    data.append('localDescription', localDescription);
    data.append('localRemiseClesDate', localRemiseClesDate);
    data.append('dureeBail', dureeBail);
    data.append('preavisResiliation', preavisResiliation);
    data.append('loyerMensuel', loyerMensuel);
    data.append('loyerPrincipal', loyerPrincipal);
    data.append('charges', charges);
    data.append('taxeEdilite', taxeEdilite);
    data.append('loyerTotal', loyerTotal);
    data.append('paiementLoyerDate', paiementLoyerDate);
    data.append('majorationLoyer', majorationLoyer);
    data.append('periodeTriennale', periodeTriennale);
    data.append('cautionMontant', cautionMontant);
    data.append('cautionBancaire', cautionBancaire);
    data.append('domicileBailleur', domicileBailleur);
    data.append('domicileLocataire', domicileLocataire);
    data.append('signatureDate', signatureDate);
    data.append('pdf', pdfBlob, 'document.pdf');

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    try {
      await axios.post(url, data, config).then((response) => {
        if (response.status === 200) {
          dispatch(setUser(response.data));
          navigate('/dashboard');
        }
      });
    } catch (error) {
      console.log('Error saving data', error);
    }
  };

  const handleSubmitBailCommercial = (pdfBlob) => {
    if (pdfBlob) {
      // const pdfURL = URL.createObjectURL(pdfBlob);
      // window.open(pdfURL);
      saveData(pdfBlob);
    } else {
      alert('PDF is null or undefined.');
    }
  };

  const getQuestion = (currentStep) => {
    switch (currentStep) {
    case 1:
      return (
        <F1
          bailleurSociete={bailleurSociete}
          setBailleurSociete={setBailleurSociete}
          bailleurCapital={bailleurCapital}
          setBailleurCapital={setBailleurCapital}
          bailleurVille={bailleurVille}
          setBailleurVille={setBailleurVille}
          bailleurRepresentant={bailleurRepresentant}
          setBailleurRepresentant={setBailleurRepresentant}
          bailleurCin={bailleurCin}
          setBailleurCin={setBailleurCin}
        />
      );
    case 2:
      return (
        <F2
          locataireSociete={locataireSociete}
          setLocataireSociete={setLocataireSociete}
          locataireCapital={locataireCapital}
          setLocataireCapital={setLocataireCapital}
          locataireSiegeSocial={locataireSiegeSocial}
          setLocataireSiegeSocial={setLocataireSiegeSocial}
          locataireRepresentant={locataireRepresentant}
          setLocataireRepresentant={setLocataireRepresentant}
          locataireQualite={locataireQualite}
          setLocataireQualite={setLocataireQualite}
          locataireCin={locataireCin}
          setLocataireCin={setLocataireCin}
        />
      );
    case 3:
      return (
        <F3
          dateDebutBail={dateDebutBail}
          setDateDebutBail={setDateDebutBail}
          localUsage={localUsage}
          setLocalUsage={setLocalUsage}
          localTitreFoncier={localTitreFoncier}
          setLocalTitreFoncier={setLocalTitreFoncier}
          localSuperficie={localSuperficie}
          setLocalSuperficie={setLocalSuperficie}
          localActivites={localActivites}
          setLocalActivites={setLocalActivites}
        />
      );
    case 4:
      return (
        <F4
          localDescription={localDescription}
          setLocalDescription={setLocalDescription}
          localRemiseClesDate={localRemiseClesDate}
          setLocalRemiseClesDate={setLocalRemiseClesDate}
        />
      );
    case 5:
      return (
        <F5
          dureeBail={dureeBail}
          setDureeBail={setDureeBail}
          preavisResiliation={preavisResiliation}
          setPreavisResiliation={setPreavisResiliation}
        />
      );
    case 6:
      return (
        <F6
          loyerMensuel={loyerMensuel}
          setLoyerMensuel={setLoyerMensuel}
          loyerPrincipal={loyerPrincipal}
          setLoyerPrincipal={setLoyerPrincipal}
          charges={charges}
          setCharges={setCharges}
          taxeEdilite={taxeEdilite}
          setTaxeEdilite={setTaxeEdilite}
          loyerTotal={loyerTotal}
          setLoyerTotal={setLoyerTotal}
          paiementLoyerDate={paiementLoyerDate}
          setPaiementLoyerDate={setPaiementLoyerDate}
        />
      );
    case 7:
      return (
        <F7
          majorationLoyer={majorationLoyer}
          setMajorationLoyer={setMajorationLoyer}
          periodeTriennale={periodeTriennale}
          setPeriodeTriennale={setPeriodeTriennale}
        />
      );
    case 8:
      return (
        <F8
          cautionMontant={cautionMontant}
          setCautionMontant={setCautionMontant}
          cautionBancaire={cautionBancaire}
          setCautionBancaire={setCautionBancaire}
        />
      );
    case 9:
      return (
        <F9
          domicileBailleur={domicileBailleur}
          setDomicileBailleur={setDomicileBailleur}
          domicileLocataire={domicileLocataire}
          setDomicileLocataire={setDomicileLocataire}
        />
      );
    case 10:
      return (
        <F10
          signatureDate={signatureDate}
          setSignatureDate={setSignatureDate}
        />
      );
    default:
      return (
        <div>
          <h3>not yet</h3>
        </div>
      );
    }
  };

  return (
    <div className="flex flex-col justify-start items-center px-10 bg-white">
      <h3 className="text-md font-bold">Bail Commercial</h3>
      <StepProgress numberSteps={10} currentStep={currentStep} />
      <BlobProvider document={
        <PdfContent
          // F1
          bailleurSociete={bailleurSociete}
          bailleurCapital={bailleurCapital}
          bailleurVille={bailleurVille}
          bailleurRepresentant={bailleurRepresentant}
          bailleurCin={bailleurCin}
          // F2
          locataireSociete={locataireSociete}
          locataireCapital={locataireCapital}
          locataireSiegeSocial={locataireSiegeSocial}
          locataireRepresentant={locataireRepresentant}
          locataireQualite={locataireQualite}
          locataireCin={locataireCin}
          // F3
          dateDebutBail={dateDebutBail}
          localUsage={localUsage}
          localTitreFoncier={localTitreFoncier}
          localSuperficie={localSuperficie}
          localActivites={localActivites}
          // F4
          localDescription={localDescription}
          localRemiseClesDate={localRemiseClesDate}
          // F5
          dureeBail={dureeBail}
          preavisResiliation={preavisResiliation}
          // F6
          loyerMensuel={loyerMensuel}
          loyerPrincipal={loyerPrincipal}
          charges={charges}
          taxeEdilite={taxeEdilite}
          loyerTotal={loyerTotal}
          paiementLoyerDate={paiementLoyerDate}
          // F7
          majorationLoyer={majorationLoyer}
          periodeTriennale={periodeTriennale}
          // F8
          cautionMontant={cautionMontant}
          cautionBancaire={cautionBancaire}
          // F9
          domicileBailleur={domicileBailleur}
          domicileLocataire={domicileLocataire}
          // F10
          signatureDate={signatureDate}
        />
      }>
        {({ blob }) => {
          return (
            <div className="w-fit md:w-[700px]">
              {getQuestion(currentStep)}
              <div className="flex justify-between gap-5 my-5">
                <Button
                  color="info"
                  variant="outlined"
                  disabled={currentStep > 1 ? false : true}
                  onClick={() => {
                    if (currentStep > 1) {
                      setCurrentStep(currentStep - 1);
                    }
                  }}
                >
                  {'< Retour'}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    currentStep !== 10
                      ? setCurrentStep(currentStep + 1)
                      : handleSubmitBailCommercial(blob);
                  }}
                >
                  {currentStep !== 10 ? 'Continuer' : 'ENREGISTRER'}
                </Button>
              </div>
            </div>
          );
        }}

      </BlobProvider>
    </div>
  );
}

function F1(props) {
  const {
    bailleurSociete,
    setBailleurSociete,
    bailleurCapital,
    setBailleurCapital,
    bailleurVille,
    setBailleurVille,
    bailleurRepresentant,
    setBailleurRepresentant,
    bailleurCin,
    setBailleurCin,
  } = props;
  return (
    <div className="mb-10">
      <h4 className="text-xl font-bold">01. Bailleur informations</h4>
      <div className="my-2">
        <div className="grid grid-cols-2 gap-4">
          <CustomTextInput
            image={usericon}
            type="text"
            value={bailleurSociete}
            setValue={setBailleurSociete}
            placeholder="Bailleur Société"
          />
          <CustomTextInput
            image={usericon}
            type="number"
            min={0}
            value={bailleurCapital}
            setValue={setBailleurCapital}
            placeholder="Bailleur Capital"
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <CustomTextInput
            image={usericon}
            type="text"
            value={bailleurVille}
            setValue={setBailleurVille}
            placeholder="Bailleur Ville"
          />
          <CustomTextInput
            image={usericon}
            type="text"
            value={bailleurRepresentant}
            setValue={setBailleurRepresentant}
            placeholder="Bailleur Représentant"
          />
        </div>
        <div className="grid grid-cols-1">
          <CustomTextInput
            image={usericon}
            type="text"
            value={bailleurCin}
            setValue={setBailleurCin}
            placeholder="Bailleur CIN"
          />
        </div>
      </div>
    </div>
  );
}

function F2(props) {
  const {
    locataireSociete,
    setLocataireSociete,
    locataireCapital,
    setLocataireCapital,
    locataireSiegeSocial,
    setLocataireSiegeSocial,
    locataireRepresentant,
    setLocataireRepresentant,
    locataireQualite,
    setLocataireQualite,
    locataireCin,
    setLocataireCin,
  } = props;
  return (
    <div className="mb-10">
      <h4 className="text-xl font-bold">02. Locataire informations</h4>
      <div className="my-2">
        <div className="grid grid-cols-2 gap-4">
          <CustomTextInput
            image={usericon}
            type="text"
            value={locataireSociete}
            setValue={setLocataireSociete}
            placeholder="Locataire Société"
          />
          <CustomTextInput
            image={usericon}
            type="number"
            min={0}
            value={locataireCapital}
            setValue={setLocataireCapital}
            placeholder="Locataire Capital"
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <CustomTextInput
            image={usericon}
            type="text"
            value={locataireSiegeSocial}
            setValue={setLocataireSiegeSocial}
            placeholder="Locataire Siège Social"
          />
          <CustomTextInput
            image={usericon}
            type="text"
            value={locataireRepresentant}
            setValue={setLocataireRepresentant}
            placeholder="Locataire Représentant"
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <CustomTextInput
            image={usericon}
            type="text"
            value={locataireQualite}
            setValue={setLocataireQualite}
            placeholder="Locataire Qualité"
          />
          <CustomTextInput
            image={usericon}
            type="text"
            value={locataireCin}
            setValue={setLocataireCin}
            placeholder="Locataire CIN"
          />
        </div>
      </div>
    </div>
  );
}

function F3(props) {
  const {
    dateDebutBail,
    setDateDebutBail,
    localUsage,
    setLocalUsage,
    localTitreFoncier,
    setLocalTitreFoncier,
    localSuperficie,
    setLocalSuperficie,
    localActivites,
    setLocalActivites,
  } = props;
  return (
    <div className="mb-10">
      <h4 className="text-xl font-bold">03. Informations sur le local</h4>
      <div className="my-2">
        <div className="grid grid-cols-2 gap-4">
          <CustomTextInput
            image={usericon}
            type="date"
            value={dateDebutBail}
            setValue={setDateDebutBail}
            placeholder="Date Début Bail"
          />
          <CustomTextInput
            image={usericon}
            type="text"
            value={localUsage}
            setValue={setLocalUsage}
            placeholder="Usage Local"
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <CustomTextInput
            image={usericon}
            type="text"
            value={localTitreFoncier}
            setValue={setLocalTitreFoncier}
            placeholder="Titre Foncier"
          />
          <CustomTextInput
            image={usericon}
            type="text"
            value={localSuperficie}
            setValue={setLocalSuperficie}
            placeholder="Superficie"
          />
        </div>
        <div className="grid grid-cols-1">
          <CustomTextInput
            image={usericon}
            type="text"
            value={localActivites}
            setValue={setLocalActivites}
            placeholder="Activités"
          />
        </div>
      </div>
    </div>
  );
}

function F4(props) {
  const {
    localDescription,
    setLocalDescription,
    localRemiseClesDate,
    setLocalRemiseClesDate,
  } = props;
  return (
    <div className="mb-10">
      <h4 className="text-xl font-bold">04. Description du local</h4>
      <div className="my-2">
        <div className="grid grid-cols-1 gap-4">
          <CustomTextInput
            image={usericon}
            type="text"
            value={localDescription}
            setValue={setLocalDescription}
            placeholder="Description du Local"
          />
          <CustomTextInput
            image={usericon}
            type="date"
            value={localRemiseClesDate}
            setValue={setLocalRemiseClesDate}
            placeholder="Date de Remise des Clés"
          />
        </div>
      </div>
    </div>
  );
}

function F5(props) {
  const { dureeBail, setDureeBail, preavisResiliation, setPreavisResiliation } =
    props;
  return (
    <div className="mb-10">
      <h4 className="text-xl font-bold">05. Durée du bail</h4>
      <div className="my-2">
        <div className="grid grid-cols-2 gap-4">
          <CustomTextInput
            image={usericon}
            type="number"
            min={0}
            value={dureeBail}
            setValue={setDureeBail}
            placeholder="Durée du Bail (mois)"
          />
          <CustomTextInput
            image={usericon}
            type="number"
            min={0}
            value={preavisResiliation}
            setValue={setPreavisResiliation}
            placeholder="Préavis de Résiliation (mois)"
          />
        </div>
      </div>
    </div>
  );
}

function F6(props) {
  const {
    loyerMensuel,
    setLoyerMensuel,
    loyerPrincipal,
    setLoyerPrincipal,
    charges,
    setCharges,
    taxeEdilite,
    setTaxeEdilite,
    loyerTotal,
    setLoyerTotal,
    paiementLoyerDate,
    setPaiementLoyerDate,
  } = props;
  return (
    <div className="mb-10">
      <h4 className="text-xl font-bold">06. Loyer et Charges</h4>
      <div className="my-2">
        <div className="grid grid-cols-2 gap-4">
          <CustomTextInput
            image={usericon}
            type="number"
            min={0}
            value={loyerMensuel}
            setValue={setLoyerMensuel}
            placeholder="Loyer Mensuel"
          />
          <CustomTextInput
            image={usericon}
            type="number"
            min={0}
            value={loyerPrincipal}
            setValue={setLoyerPrincipal}
            placeholder="Loyer Principal"
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <CustomTextInput
            image={usericon}
            type="number"
            min={0}
            value={charges}
            setValue={setCharges}
            placeholder="Charges"
          />
          <CustomTextInput
            image={usericon}
            type="number"
            min={0}
            value={taxeEdilite}
            setValue={setTaxeEdilite}
            placeholder="Taxe Edilité"
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <CustomTextInput
            image={usericon}
            type="number"
            min={0}
            value={loyerTotal}
            setValue={setLoyerTotal}
            placeholder="Loyer Total"
          />
          <CustomTextInput
            image={usericon}
            type="date"
            value={paiementLoyerDate}
            setValue={setPaiementLoyerDate}
            placeholder="Date de Paiement du Loyer"
          />
        </div>
      </div>
    </div>
  );
}

function F7(props) {
  const {
    majorationLoyer,
    setMajorationLoyer,
    periodeTriennale,
    setPeriodeTriennale,
  } = props;
  return (
    <div className="mb-10">
      <h4 className="text-xl font-bold">07. Majoration du Loyer</h4>
      <div className="my-2">
        <div className="grid grid-cols-2 gap-4">
          <CustomTextInput
            image={usericon}
            type="number"
            min={0}
            value={majorationLoyer}
            setValue={setMajorationLoyer}
            placeholder="Majoration du Loyer"
          />
          <CustomTextInput
            image={usericon}
            type="number"
            min={0}
            value={periodeTriennale}
            setValue={setPeriodeTriennale}
            placeholder="Période Triennale"
          />
        </div>
      </div>
    </div>
  );
}

function F8(props) {
  const {
    cautionMontant,
    setCautionMontant,
    cautionBancaire,
    setCautionBancaire,
  } = props;
  return (
    <div className="mb-10">
      <h4 className="text-xl font-bold">08. Caution</h4>
      <div className="my-2">
        <div className="grid grid-cols-2 gap-4">
          <CustomTextInput
            image={usericon}
            type="number"
            min={0}
            value={cautionMontant}
            setValue={setCautionMontant}
            placeholder="Montant de la Caution"
          />
          <CustomTextInput
            image={usericon}
            type="number"
            min={0}
            value={cautionBancaire}
            setValue={setCautionBancaire}
            placeholder="Caution Bancaire"
          />
        </div>
      </div>
    </div>
  );
}

function F9(props) {
  const {
    domicileBailleur,
    setDomicileBailleur,
    domicileLocataire,
    setDomicileLocataire,
  } = props;
  return (
    <div className="mb-10">
      <h4 className="text-xl font-bold">09. Domicile</h4>
      <div className="my-2">
        <div className="grid grid-cols-2 gap-4">
          <CustomTextInput
            image={usericon}
            type="text"
            value={domicileBailleur}
            setValue={setDomicileBailleur}
            placeholder="Domicile Bailleur"
          />
          <CustomTextInput
            image={usericon}
            type="text"
            value={domicileLocataire}
            setValue={setDomicileLocataire}
            placeholder="Domicile Locataire"
          />
        </div>
      </div>
    </div>
  );
}

function F10(props) {
  const { signatureDate, setSignatureDate } = props;
  return (
    <div className="mb-10">
      <h4 className="text-xl font-bold">10. Signature</h4>
      <div className="my-2">
        <div className="grid grid-cols-1">
          <CustomTextInput
            image={usericon}
            type="date"
            value={signatureDate}
            setValue={setSignatureDate}
            placeholder="Date de Signature"
          />
        </div>
      </div>
    </div>
  );
}

export default BailCommercialForm;
