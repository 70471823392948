import React from 'react';
import { Outlet } from 'react-router-dom';

export default function CreationEntrepriseLayout() {
  return (
    <div className='layout'>
      <Outlet />
    </div>
  );
}
