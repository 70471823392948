import React from 'react';
import { Button } from '@mui/material';
import offreDomiciliationImage1 from '../../../../../../../assets/images/offreDomiciliationImage1.svg';
import offreDomiciliationImage2 from '../../../../../../../assets/images/offreDomiciliationImage2.svg';
import './offre.css';

export default function OffreDomiciliation() {
  return (
    <section className='offreDomiciliation'>
      <h1 className='title'>Une offre pour tous, en fonction de vos besoins</h1>
      <ItemOffreDomiciliation
        title='Créer et domicilier votre entreprise'
        listItem={[
          'Rédaction des statuts incluant la domiciliation avec LegalPlace',
          'Contrat de domiciliation inclus dans la démarche',
          'Immatriculation directement à votre nouvelle adresse',
          'Votre Kbis récupéré en un temps record',
        ]}
        buttonLabel='Créer et domicilier ma société'
        imgSrc={offreDomiciliationImage1}
      />
      <ItemOffreDomiciliation
        title='Transférer votre siège social'
        listItem={[
          'Rédaction des modifications statutaires',
          'Domiciliation et modifications gérées par le même formaliste',
          'Envoi des modifications statutaires au greffe',
          'Votre Kbis récupéré en un temps record',
        ]}
        buttonLabel='Transférer mon siège social'
        imgSrc={offreDomiciliationImage2}
      />
    </section>
  );
}

function ItemOffreDomiciliation({
  title,
  listItem,
  buttonLabel,
  imgSrc
}) {
  return (
    <div className='itemOffreDomiciliation'>
      <div className='leftBlock'>
        <h3>{title}</h3>
        <ul>
          {listItem.map((item) => {
            return <li key={item}>{item}</li>;
          })}
        </ul>
        <Button variant='contained'>{buttonLabel}</Button>
      </div>
      <img src={imgSrc} />
    </div>
  );
}

