import React, { useState } from 'react';
import HeaderContainer from '../../../../../components/headerContainer/headerContainer';
import StepProgress from '../../../../../components/stepProgress/stepProgress';
import { Button } from '@mui/material';
import CustomTextInput from '../../../../../components/inputs/customTextInput/customTextInput';
import CustomRadioBox from '../../../../../components/inputs/customRadioBox/customRadioBox';
import usericon from '../../../../../assets/icons/usericon.png';
import number from '../../../../../assets/icons/number.png';
import bday from '../../../../../assets/icons/bday.png';
import email from '../../../../../assets/icons/email.png';
import Phone from '../../../../../assets/icons/phone.png';
import Cin from '../../../../../assets/icons/cin.png';
import building from '../../../../../assets/icons/building.png';
import getEnvironement from '../../../../../environnement';
import axios from 'axios';
import Swal from 'sweetalert2';


function DomiciliationForm() {
  const [currentStep, setCurrentStep] = useState(1);

  const [genre, setGenre] = useState();
  const [prenom, setPrenom] = useState();
  const [nom, setNom] = useState();
  const [cin, setCin] = useState();
  const [birthday, setBirthDay] = useState();
  const [nombre, setNombre] = useState(1);
  const [adresseEmail, setAdresseEmail] = useState('');
  const [numeroTelephone, setNumeroTelephone] = useState('');
  const [nomEntreprise, setNomEntreprise] = useState('');
  const [formJuridique, setFormJuridique] = useState('');

  const getQuestion = (currentStep) => {
    switch (currentStep) {
    case 1:
      return (<Q1 setCurrentStep={setCurrentStep} currentStep={currentStep} genre={genre}
        setGenre={setGenre}
        prenom={prenom}
        setPrenom={setPrenom}
        nombre={nombre}
        setNombre={setNombre}
        birthday={birthday}
        setBirthDay={setBirthDay}
        nom={nom}
        setNom={setNom}
        cin={cin}
        setCin={setCin} />);

    case 2:
      return (<Q2 setCurrentStep={setCurrentStep} currentStep={currentStep} value={adresseEmail} setValue={setAdresseEmail} />);
    case 3:
      return (<Q3 setCurrentStep={setCurrentStep} currentStep={currentStep} value={numeroTelephone} setValue={setNumeroTelephone} />);
    case 4:
      return (<Q4 setCurrentStep={setCurrentStep} currentStep={currentStep} value={nomEntreprise} setValue={setNomEntreprise} formJuridique={formJuridique} setFormJuridique={setFormJuridique} handelClick={handelEnregisterClick} />);
    default:
      return (<Q1 setCurrentStep={setCurrentStep} currentStep={currentStep} genre={genre}
        setGenre={setGenre}
        prenom={prenom}
        setPrenom={setPrenom}
        nom={nom}
        setNom={setNom} />);
    }
  };

  const handelEnregisterClick = () => {
    console.log('testss');
    const url = `${getEnvironement().API_URL}/domiciliation`;
    const data = {
      genre,
      nom,
      prenom,
      adresseEmail,
      numeroTelephone,
      nomEntreprise,
      formJuridique,
      cin,
      birthday,
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    axios.post(url, data, config)
      .then((response) => {
        if (response.status === 201) {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Domiciliation créée avec succès !',
            confirmButtonText: 'OK'
          });
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'Une erreur est survenue lors de la création de la domiciliation.',
          confirmButtonText: 'OK'
        });
      });
  };
  return (
    <div>
      <HeaderContainer />
      <div className=' h-screen flex flex-col justify-start items-center px-10 mt-10'>
        <h3 className=''>DOMICILIATION</h3>
        <StepProgress numberSteps={4} currentStep={currentStep} />
        <div className='w-fit md:w-[700px]'>
          {getQuestion(currentStep)}
        </div>
      </div>
    </div>


  );
}
function Q1({
  currentStep,
  setCurrentStep,
  genre,
  setGenre,
  prenom,
  setPrenom,
  nom,
  setNom,
  nombre,
  setNombre,
  birthday,
  setBirthDay,
  cin,
  setCin
}) {
  return (
    <div className=''>
      <h3 className='text-base font-medium'>Nombre de represantants:</h3>
      <CustomTextInput
        image={number}
        type='number'
        value={nombre}
        setValue={setNombre}
        placeholder='Numbre'
      />
      <div className='vos-infos'>
        <h3 className='text-base font-medium mt-2'>Represantant :</h3>
        <CustomTextInput
          image={usericon}
          type='text'
          value={prenom}
          setValue={setPrenom}
          placeholder='Prénom'
        />
        <CustomTextInput
          image={usericon}
          type='text'
          value={nom}
          setValue={setNom}
          placeholder='Nom'
        />
        <CustomTextInput
          image={Cin}
          type='text'
          value={cin}
          setValue={setCin}
          placeholder='Cin'
        />
        <h3 className='text-base font-medium mt-4'>Genre</h3>
        <div className='my-2'>
          <CustomRadioBox
            value={genre}
            setValue={setGenre}
            items={[
              { label: 'Madame', value: 'Madame' },
              { label: 'Monsieur', value: 'Monsieur' },
            ]}
          />
        </div>
        <h3 className='text-base font-medium'>Nombre de represantants:</h3>
        <CustomTextInput
          image={bday}
          type='date'
          value={birthday}
          setValue={setBirthDay}
          placeholder='Numbre'
        />
      </div>
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
          Continuer
        </Button>
      </div>
    </div>
  );
}

function Q2({ currentStep, setCurrentStep, value, setValue }) {
  return (
    <div className=''>
      <h3 className='text-base font-medium'>Adresse Email:</h3>
      <div className=''>
        <CustomTextInput
          image={email}
          value={value}
          setValue={setValue}
          type='email'
          placeholder='Votre adresse email'
        />
        <div className='flex gap-5 mt-5'>
          <Button
            color='info'
            variant='outlined'
            onClick={() => {
              setCurrentStep(currentStep - 1);
            }}
          >
            {'< Retour'}
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={() => {
              setCurrentStep(currentStep + 1);
            }}
          >
            Continuer
          </Button>
        </div>
      </div>
    </div>
  );
}

function Q3({ currentStep, setCurrentStep, value, setValue }) {
  return (
    <div className=''>
      <h3 className='text-base font-medium'>Numéro de téléphone :</h3>
      <div className=''>
        <CustomTextInput
          image={Phone}
          value={value}
          setValue={setValue}
          type='text'
          placeholder='Numéro de téléphone '
        />
        <div className='mt-4 bg-active p-5 rounded-lg'>
          <p className='text-wrap font-normal'>👉 Si nous avons besoin de précisions supplémentaires sur votre dossier, nous vous joindrons par téléphone pour plus d&apos;efficacité</p>
        </div>
        <div className='flex gap-5 mt-5'>
          <Button
            color='info'
            variant='outlined'
            onClick={() => {
              setCurrentStep(currentStep - 1);
            }}
          >
            {'< Retour'}
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={() => {
              setCurrentStep(currentStep + 1);
            }}
          >
            Continuer
          </Button>
        </div>
      </div>
    </div>
  );
}
function Q4({
  currentStep, setCurrentStep, value, setValue, formJuridique, setFormJuridique, handelClick
}) {
  return (
    <div className=''>
      <h3 className='text-base font-medium'>Nom de la société à domicilier :</h3>
      <div className=''>
        <CustomTextInput
          image={building}
          value={value}
          setValue={setValue}
          type='text'
          placeholder='Ex : Legalstation'
        />
        <h3 className='text-base font-medium mt-4'>Genre</h3>
        <div className='my-2'>
          <CustomRadioBox
            value={formJuridique}
            setValue={setFormJuridique}
            items={[
              { label: 'SARL', value: 'Sarl' },
              { label: 'SAS', value: 'Sas' },
            ]}
          />
        </div>
        <div className='flex gap-5 mt-5'>
          <Button
            color='info'
            variant='outlined'
            onClick={() => {
              setCurrentStep(currentStep - 1);
            }}
          >
            {'< Retour'}
          </Button>
          <Button
            variant='contained'
            onClick={handelClick}
          >
            Enregistrer
          </Button>
        </div>
      </div>
    </div>
  );
}

export default DomiciliationForm;