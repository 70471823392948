import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import StepProgress from '../../../../../components/stepProgress/stepProgress';
import HeaderContainer from '../../../../../components/headerContainer/headerContainer';
import tick from '../../../../../assets/icons/tick.png';
import nope from '../../../../../assets/icons/close.png';
import CustomRadioBox from '../../../../../components/inputs/customRadioBox/customRadioBox';
import './ComptabiliteForm.css';
import getEnvironement from '../../../../../environnement';
import axios from 'axios';
import { Wallet } from '@mui/icons-material';
import CustomTextInput from '../../../../../components/inputs/customTextInput/customTextInput';
import IconTiles from '../../domiciliation/domiciliationForm/components/iconTiles';
import loc from '../../../../../assets/icons/loc.png';
import turn from '../../../../../assets/icons/turn.png';
import wallet from '../../../../../assets/icons/wallet.png';
import infinity from '../../../../../assets/icons/infinity.png';

export default function ComptabiliteForm() {
  const [currentStep, setCurrentStep] = useState(1);
  const [isSociete, setIsSociete] = useState('');
  const [isSocieteCategorie, setIsSocieteCategorie] = useState('');
  const [genre, setGenre] = useState('');
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [choseDomaine, setChosenDomaine] = useState('');
  const [isAccompagne, setIsAccompagne] = useState('');
  const [exerciceComptable, setExerciceComptable] = useState('');
  const [isNo,setIsNo] = useState('');
  const [adresseEmail, setAdresseEmail] = useState('');
  const [numeroTelephone, setNumeroTelephone] = useState('');
  const [dateDernierBilan, setDateDernierBilan] = useState('');
  const [dateFinExerciceComptable, setDateFinExerciceComptable] = useState('');

  const navigate = useNavigate();

  //test
  
  const handelEnregisterClick = () => {
    console.log('testss');
    const url = `${getEnvironement().API_URL}/domiciliation`;
    const data = {
      isNo,
      exerciceComptable,
      isAccompagne,
      choseDomaine,
      isSociete,
      isSocieteCategorie,
      genre,
      nom,
      prenom,
      adresseEmail,
      numeroTelephone,
      dateDernierBilan,
      
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    axios.post(url, data, config)
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          navigate('/dashboard');
        }
      })
      .catch((err) => console.log(err+ 'error msg'));
  };

  const getQuestion = (currentStep) => {
    switch(currentStep) {
    case 1:
      return <Q1 setCurrentStep={setCurrentStep} currentStep={currentStep} setValue={setIsSociete} />;
    case 2:
      return isSociete === 'micro'? <Q2Micro setCurrentStep={setCurrentStep} currentStep={currentStep} /> : <Q2Societe setCurrentStep={setCurrentStep} currentStep={currentStep} setValue={setIsSocieteCategorie} />;
    case 3:
      if(isSociete === 'societe') {
        if(isSocieteCategorie === 'En cours de création') {
          setIsNo('No');
          setCurrentStep(currentStep + 1);
          break;
          // return <Q3EnCours setCurrentStep={setCurrentStep} currentStep={currentStep} setIsNo={setIsNo} />;
        } else if(isSocieteCategorie === 'Créée depuis moins de 3 mois') {
          return <Q3Mois setCurrentStep={setCurrentStep} currentStep={currentStep} setValue={setChosenDomaine} />;
        } else if(isSocieteCategorie === 'Active depuis moins un an') {
          return <Q3Moins setCurrentStep={setCurrentStep} currentStep={currentStep} exerciceComptable={exerciceComptable} setExerciceComptable={setExerciceComptable} />;
        } else {
          return <Q3Plus setCurrentStep={setCurrentStep} currentStep={currentStep} isAccompagne={isAccompagne} setIsAccompagne={setIsAccompagne} />;
        }
      } else {
        return <Q2Micro setCurrentStep={setCurrentStep} currentStep={currentStep} />;
      }
    case 4:
      if(isSocieteCategorie === 'Créée depuis moins de 3 mois'){
        if (choseDomaine == '1' || choseDomaine == '2' || choseDomaine == '3' || choseDomaine == '7' || choseDomaine == '8') {
          return <QEmail setCurrentStep={setCurrentStep} currentStep={currentStep} value={adresseEmail} setValue={setAdresseEmail} />;
        } else {
          return <QVentes setCurrentStep={setCurrentStep} currentStep={currentStep} />;
        } 
      }
      if( isSocieteCategorie === 'Active depuis moins un an'){
        return <Q3Mois setCurrentStep={setCurrentStep} currentStep={currentStep} setValue={setChosenDomaine} />;
      }
      if( isSocieteCategorie === 'Active depuis plus d un an'){
        return <Q3PlusDernierBilan setCurrentStep={setCurrentStep} currentStep={currentStep} Value={dateDernierBilan} setValue={setDateDernierBilan} />;
      }
      if (isNo == 'No'){
        return <Q3Mois setCurrentStep={setCurrentStep} currentStep={currentStep} setIsNo={setIsNo} setValue={setChosenDomaine} />;
      } else if(isNo == '') {
        return <QEmail setCurrentStep={setCurrentStep} currentStep={currentStep} value={adresseEmail} setValue={setAdresseEmail} />;
        // if (choseDomaine == '1' || choseDomaine == '2' || choseDomaine == '3' || choseDomaine == '7' || choseDomaine == '8') {
        //   return <QEmail setCurrentStep={setCurrentStep} currentStep={currentStep} value={adresseEmail} setValue={setAdresseEmail} />;
        // } else {
        //   return <QVentes setCurrentStep={setCurrentStep} currentStep={currentStep} />;
        // } 
      } else {
        break;
      }
    case 5:
      if(isSocieteCategorie === 'Créée depuis moins de 3 mois'){
        return <QPhone setCurrentStep={setCurrentStep} currentStep={currentStep} value={numeroTelephone} setValue={setNumeroTelephone} />;
        // if (choseDomaine == '1' || choseDomaine == '2' || choseDomaine == '3' || choseDomaine == '7' || choseDomaine == '8') {
        //   return <QPhone setCurrentStep={setCurrentStep} currentStep={currentStep} value={numeroTelephone} setValue={setNumeroTelephone} />;
        // } else {
        //   return <QVentes setCurrentStep={setCurrentStep} currentStep={currentStep} />;
        // } 
      }
      if( isSocieteCategorie === 'Active depuis moins un an'){
        return <QEmail setCurrentStep={setCurrentStep} currentStep={currentStep} value={adresseEmail} setValue={setAdresseEmail} />;
        // if (choseDomaine == '1' || choseDomaine == '2' || choseDomaine == '3' || choseDomaine == '7' || choseDomaine == '8') {
        //   return <QEmail setCurrentStep={setCurrentStep} currentStep={currentStep} value={adresseEmail} setValue={setAdresseEmail} />;
        // } else {
        //   return <QVentes setCurrentStep={setCurrentStep} currentStep={currentStep} />;
        // } 
      }
      if( isSocieteCategorie === 'Active depuis plus d un an'){
        return <Q3PlusFinExerciceComptable setCurrentStep={setCurrentStep} currentStep={currentStep} Value={dateFinExerciceComptable} setValue={setDateFinExerciceComptable} />;
      }
      if (isNo == 'No'){
        return <QEmail setCurrentStep={setCurrentStep} currentStep={currentStep} value={adresseEmail} setValue={setAdresseEmail} />;
        // if (choseDomaine == '1' || choseDomaine == '2' || choseDomaine == '3' || choseDomaine == '7' || choseDomaine == '8') {
        //   return <QEmail setCurrentStep={setCurrentStep} currentStep={currentStep} value={adresseEmail} setValue={setAdresseEmail} />;
        // } else {
        //   return <QVentes setCurrentStep={setCurrentStep} currentStep={currentStep} />;
        // } 
      }
      return <QPhone setCurrentStep={setCurrentStep} currentStep={currentStep} value={numeroTelephone} setValue={setNumeroTelephone} />;
      // if (choseDomaine == '1' || choseDomaine == '2' || choseDomaine == '3' || choseDomaine == '7' || choseDomaine == '8') {
      //   return <QPhone setCurrentStep={setCurrentStep} currentStep={currentStep} value={numeroTelephone} setValue={setNumeroTelephone} />;
      // } else {
      //   return <QVentes setCurrentStep={setCurrentStep} currentStep={currentStep} />;
      // }
    case 6:
      if(isSocieteCategorie === 'Créée depuis moins de 3 mois'){
        return  <Q6 setCurrentStep={setCurrentStep} currentStep={currentStep} handelClick={handelEnregisterClick} />;
      }
      if( isSocieteCategorie === 'Active depuis plus d un an'){
        return <Q3Mois setCurrentStep={setCurrentStep} currentStep={currentStep} setValue={setChosenDomaine} />;
      }
      return <QPhone setCurrentStep={setCurrentStep} currentStep={currentStep}value={numeroTelephone} setValue={setNumeroTelephone} />;
      // if (choseDomaine == '1' || choseDomaine == '2' || choseDomaine == '3' || choseDomaine == '7' || choseDomaine == '8') {
      //   return <QPhone setCurrentStep={setCurrentStep} currentStep={currentStep}value={numeroTelephone} setValue={setNumeroTelephone} />;
      // } else {
      //   return <QVentes setCurrentStep={setCurrentStep} currentStep={currentStep} />;
      // }

    case 7:
      if( isSocieteCategorie === 'Active depuis plus d un an'){
        return <QEmail setCurrentStep={setCurrentStep} currentStep={currentStep} value={adresseEmail} setValue={setAdresseEmail} />;
        // if (choseDomaine == '1' || choseDomaine == '2' || choseDomaine == '3' || choseDomaine == '7' || choseDomaine == '8') {
        //   return <QEmail setCurrentStep={setCurrentStep} currentStep={currentStep} value={adresseEmail} setValue={setAdresseEmail} />;
        // } else {
        //   return <QVentes setCurrentStep={setCurrentStep} currentStep={currentStep} />;
        // } 
      }
      return <Q6 setCurrentStep={setCurrentStep} currentStep={currentStep} handelClick={handelEnregisterClick} />;
    case 8:
      if( isSocieteCategorie === 'Active depuis plus d un an'){
        return <QPhone setCurrentStep={setCurrentStep} currentStep={currentStep}value={numeroTelephone} setValue={setNumeroTelephone} />;
        // if (choseDomaine == '1' || choseDomaine == '2' || choseDomaine == '3' || choseDomaine == '7' || choseDomaine == '8') {
        //   return <QPhone setCurrentStep={setCurrentStep} currentStep={currentStep}value={numeroTelephone} setValue={setNumeroTelephone} />;
        // } else {
        //   return <QVentes setCurrentStep={setCurrentStep} currentStep={currentStep} />;
        // } 
      }
      return <Q6 setCurrentStep={setCurrentStep} currentStep={currentStep} handelClick={handelEnregisterClick} />;
    case 9:
      return <Q6 setCurrentStep={setCurrentStep} currentStep={currentStep} handelClick={handelEnregisterClick} />;
    default:
      return <Q1 setCurrentStep={setCurrentStep} currentStep={currentStep} setGenre={setGenre} prenom={prenom} setPrenom={setPrenom} nom={nom} setNom={setNom} />;
    }
  };
  return (
    <div className='sarlForm'>
      <HeaderContainer/>
      <StepProgress numberSteps={8} currentStep={currentStep}/>
      <h3 className='title'>COMPTABILITE</h3>
      {getQuestion(currentStep)}
    </div>

  );
}

function YesOrNo ({
  srcIcon, Ftext, Stext, setCurrentStep, currentStep, value, setValue
}){
  return (
    <div className='w-56 h-44 overflow-hidden flex flex-col justify-center items-center p-5 border-[1px] border-active hover:bg-active rounded-md'>
      <Button className=' flex flex-col' onClick={() => {
        setValue(value);
        setCurrentStep(currentStep + 1);
      }}>
      
        <img className='w-10 h-10 mb-5' src={srcIcon} alt=''/>

        <div>
          <p className='font-bold  text-balck'>{Ftext}</p>
          <p className='font-sm  text-lightblack lowercase '>{Stext}</p>
        </div>
      </Button>
    </div>
  );
}

function CardItem({
  id, srcIcon, content, setCurrentStep, currentStep, setValue
}) {
  return (
    <div className='cardItemContainer'>
      <Button
        className='cardItem'
        style={{ textTransform: 'lowercase' }}
        onClick={() => {
          setValue(id);
          setCurrentStep(currentStep + 1);
        }}
      >
        <div className='iconContainer'>
          <img src={srcIcon} alt=''/>
        </div>
        <p>{content}</p>
      </Button>
    </div>
  );
}

//---------------Questions---------------------
function Q1({
  currentStep, setCurrentStep, setValue
}) {
  return (
    <div className='Q1'>
      <h6>Vous êtes :</h6>
      <div className='q1CardContainer'>
        <CardItem
          id='societe'
          setValue={setValue}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          srcIcon={Wallet}
          content='Une société (SAS, SARL, SCI)'
        />
        <CardItem
          id='micro'
          setValue={setValue}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          srcIcon={Wallet}
          content='Une micro entreprise ou une entreprise individuelle'
        />
      </div>
    </div>
  );
}

function Q2Micro({currentStep, setCurrentStep}) {
  return (
    <div className='Q2'>

      <div className='flex flex-col justify-center items-center px-20 py-5 h-screen'>
        <div className='flex flex-col items-center gap-3 w-1/2'>
          <p className='text-center text-xl font-semibold mb-5'>Adresse email</p>
          <p className='text-center text-xl font-medium mt-5'>Notre cabinet comptable n&apos;accompagne pas les micro-entreprises (auto-entrepreneurs) ni les entreprises individuelles qui bénéficient d&apos;une comptabilité simplifiée.</p>
          <p className='text-center text-xl font-medium'>Découvrez néanmoins les conseils de nos experts comptables pour votre comptabilité</p>
        </div>
        <Link
          className='link textContent'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Link>
      </div>
    </div>
  );
}

function Q2Societe({currentStep, setCurrentStep, setValue}) {
  return (
    <div className='Q3'>
      <h6>Votre société est :</h6>
      <div className='contentContainer'>
        <div className='q1CardContainer'>
          <CardItem
            id='En cours de création'
            setValue={setValue}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            srcIcon={Wallet}
            content='En cours de création' 
          />
          <CardItem
            id='Créée depuis moins de 3 mois'
            setValue={setValue}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            srcIcon={Wallet}
            content='Créée depuis moins de 3 mois'
          />
          <CardItem
            id='Active depuis moins un an'
            setValue={setValue}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            srcIcon={Wallet}
            content='Active depuis moins d&apos;un an'
          />
          <CardItem
            id='Active depuis plus d un an'
            setValue={setValue}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            srcIcon={Wallet}
            content='Active depuis plus d&apos;un an'
          />
          <Link
            className='link textContent'
            onClick={() => {
              setCurrentStep(currentStep - 1);
            }}
          >
            {'< Retour'}
          </Link>
        </div>
      </div>
    </div>
  );
}
// function Q3EnCours ({currentStep,setCurrentStep,setIsNo}){
//   return <div>
//     <h6>Créez votre société gratuitement avec Legalstation </h6>
//     <div className='contentContainer'>
//       <div className='p-5 md:p-0'>
//         <p className='text-center font-medium'>LegalStation se charge de faire l&apos;immatriculation de votre société main dans la main avec un comptable et vous offre ses frais de création.</p>
//         <br />
//         <p className='text-center font-medium'>LegalStation se charge de faire l&apos;immatriculation de votre société main dans la main avec un comptable et vous offre ses frais de création.</p>
//       </div>
//       <div className='flex flex-col md:flex-row gap-4 justify-center items-center mt-4'>
//         <Button
//           variant='contained'
//           onClick={() => {
//             setCurrentStep(1);
//           }}
//         >
//             Creér Avec legalstation
//         </Button>
//         <Button
//           variant='outlined'
//           onClick={() => {
//             setIsNo('No');
//             setCurrentStep(currentStep + 1);

//           }}
//         >
//             Non Merci
//         </Button>
        
//       </div>
//       <Link
//         className='flex justify-center items-center mt-4'
//         onClick={() => {
//           setCurrentStep(currentStep - 1);
//         }}
//       >
//         {'< Retour'}
//       </Link>
//     </div>
//   </div>;
// }
function Q3Mois({setValue,currentStep,setCurrentStep}){
  return <div className='Q6'>
    <h6>Quel est votre domaine d&apos;activité</h6>
    <div className='flex flex-wrap md:flex-wrap justify-center items-center gap-4 m-5 p-10'>
      <div>
        <YesOrNo
          setValue={setValue}
          value='1'
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          srcIcon={tick}
          Ftext='Consultant et freelance'
          Stext=''
        />
      </div>
      <div className=''>
        <YesOrNo
          setValue={setValue}
          value='2'
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          srcIcon={nope}
          Ftext='Construction et travaux (BTP)'
          Stext=''
        />
      </div>
      <div>
        <YesOrNo
          setValue={setValue}
          value='3'
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          srcIcon={tick}
          Ftext='Taxi - VTC'
          Stext=''
        />
      </div>
      <div className=''>
        <YesOrNo
          setValue={setValue}
          value='4'
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          srcIcon={nope}
          Ftext='Vente en ligne'
          Stext=''
        />
      </div>
      <div>
        <YesOrNo
          setValue={setValue}
          value='5'
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          srcIcon={tick}
          Ftext='Commerce'
          Stext=''
        />
      </div>
      <div className=''>
        <YesOrNo
          setValue={setValue}
          value='6'
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          srcIcon={nope}
          Ftext='Services aux entreprises'
          Stext=''
        />
      </div>
      <div>
        <YesOrNo
          setValue={setValue}
          value='6'
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          srcIcon={tick}
          Ftext='Hôtellerie, café, restauration'
          Stext=''
        />
      </div>
      <div className=''>
        <YesOrNo
          setValue={setValue}
          value='7'
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          srcIcon={nope}
          Ftext='Services à la personne'
          Stext=''
        />
      </div>
      <div>
        <YesOrNo
          setValue={setValue}
          value='8'
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          srcIcon={tick}
          Ftext='Immobilier'
          Stext=''
        />
      </div>
      <div className=''>
        <YesOrNo
          setValue={setValue}
          value='9'
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          srcIcon={nope}
          Ftext='Autre'
          Stext=''
        />
      </div>
      
    </div>
    <Link
      className='link textContent'
      onClick={() => {
        setCurrentStep(currentStep - 1);
      }}
    >
      {'< Retour'}
    </Link>
  </div>;

}
function Q3Moins({currentStep,setCurrentStep, exerciceComptable, setExerciceComptable}) {
  return <div className='QPlus'>
    <div className='contentContainer'>
      <div className='radioBoxContainer'>
        <CustomRadioBox
          row={false}
          value={exerciceComptable}
          setValue={setExerciceComptable}
          items={[
            { label: ' 30 Juin 2024', value: '30 Juin 2024' },
            { label: '30 Septembre 2024', value: '30 Septembre 2024' },
            { label: '31 Decembre 2024', value: '31 Decembre 2024' },
            { label: 'une autre date en 2024', value: 'une autre date en 2024' },
            { label: 'en 2025', value: 'en 2025' },
          ]}
        />
      </div>
      <div className='q2ButtonContainer'>
        <Button
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
          Continuer
        </Button>
        <Link
          className='link textContent'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Link>
      </div>
    </div>
  </div>;

}
function Q3Plus({currentStep,setCurrentStep,isAccompagne,setIsAccompagne}) {
  return <div className='QPlus'>
    <div className='contentContainer'>
      <h6>Votre société est-elle déjà accompagnée par un expert comptable ?</h6>
      <div className='radioBoxContainer'>
        <CustomRadioBox
          row={false}
          value={isAccompagne}
          setValue={setIsAccompagne}
          items={[
            { label: ' Oui, inscrit à Ordre des Experts Comptables', value: 'Experts Comptables' },
            { label: 'Non', value: 'Non' },
          ]}
        />
      </div>
      <div className='q2ButtonContainer'>
        <Button
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
        <Link
          className='link textContent'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Link>
      </div>
    </div>
  </div>;

}

function Q3PlusDernierBilan({currentStep, setCurrentStep, value, setValue}) {
  return <div className='QPlus'>
    <div className='contentContainer'>
      <h6>Quelle est la date du dernier bilan réalisé par votre précédent Expert comptable ?</h6>
      <div className='radioBoxContainer'>
        <CustomRadioBox
          row={false}
          value={value}
          setValue={setValue}
          items={[
            { label: 'Aucun bilan n\'a été réalisé (je suis en retard)', value: 'Aucun bilan n\'a été réalisé (je suis en retard)' },
            { label: 'Premier bilan à venir', value: 'Premier bilan à venir' },
            { label: 'Décembre 2022 (ou avant)', value: 'Décembre 2022 (ou avant)' },
            { label: 'Mars 2023', value: 'Mars 2023' },
            { label: 'Juin 2023', value: 'Juin 2023' },
            { label: 'Septembre 2023', value: 'Septembre 2023' },
            { label: 'Décembre 2023', value: 'Décembre 2023' },
          ]}
        />
      </div>
      <div className='q2ButtonContainer'>
        <Button
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
        <Link
          className='link textContent'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Link>
      </div>
    </div>
  </div>;


}

function Q3PlusFinExerciceComptable({currentStep, setCurrentStep, value, setValue}) {
  return <div className='QPlus'>
    <div className='contentContainer'>
      <h6>Le premier bilan que nos comptables devront faire est pour un exercice comptable se terminant en :</h6>
      <div className='radioBoxContainer'>
        <CustomRadioBox
          value={value}
          setValue={setValue}
          items={[
            { label: 'Décembre 2023', value: 'Décembre 2023' },
            { label: 'En 2024', value: 'En 2024' },
          ]}
        />
      </div>
      <div className='q2ButtonContainer'>
        <Button
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
        <Link
          className='link textContent'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Link>
      </div>
    </div>
  </div>;

}

function QEmail ({currentStep,setCurrentStep,value,setValue}){
  return <div className='Q3Email'>
    <h6>Adresse email</h6>
    <div className='contentContainer'>
      <CustomTextInput
        type='text'
        value={value}
        setValue={setValue}
        placeholder='Votre adresse email'
      />
      <div className='q2ButtonContainer'>
        <Button
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
        <Link
          className='link textContent'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Link>
      </div>
    </div>
  </div>;
}
function QPhone ({currentStep,setCurrentStep,value,setValue}){
  return <div className='QPhone'>
    <h6>Numéro de téléphone</h6>
    <div className='contentContainer'>
      <CustomTextInput
        type='text'
        value={value}
        setValue={setValue}
        placeholder='Numéro de téléphone'
      />
      <div className='q2ButtonContainer'>
        <Button
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
        <Link
          className='link textContent'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Link>
      </div>
    </div>
  </div>;
}
function QVentes({currentStep, setCurrentStep}) {
  return (
    <div className='  p-10 lg:p-[60px] flex flex-col gap-3 justify-center items-center '>
      <h1 className='font-bold text-balance text-xl mb-4'>Domiciliez votre société</h1>
      <div className=' flex flex-col gap-4 md:w-1/2 mb-4'>
        <p className='mb-8'><span className='font-medium'>Votre société est en cours de création ou existe déjà</span> et vous souhaitez <br /> bénéficier des avantages de la domiciliation LegalPlace :</p>
        <IconTiles icon={loc} firsttext='Une adresse prestigieuse' secondtext="Dans l'une des principales villes de France" />
        <IconTiles icon={wallet} firsttext='Des économies' secondtext="La cotisation foncière des entreprises la plus basse de France (pour les société domiciliées à Paris)" />
        <IconTiles icon={turn} firsttext='Plus de flexibilité' secondtext="Adapté à tout type d'activité, sans besoin de signer un bail commercial et sans engagement" />
        <IconTiles icon={infinity} firsttext='Une offre tout en un' secondtext="Une offre comprenant la domiciliation et le scan quotidien de vos courriers" />
      </div>
      <Button
        variant='contained'
        onClick={() => {
          setCurrentStep(currentStep + 1);
        }}
      >
            Demarer
      </Button>
    </div>
  );
}
 

function Q6({currentStep, setCurrentStep, handelClick}) {
  return (
    <div className='Q9'>
      <h6>Merci pour vos réponses ! Plus qu&apos;une étape avant de pouvoir disposer de votre siège social Casablanca.</h6>
      <div className='q1CardContainer'>
        <div className='q2ButtonContainer'>
          <Button
            variant='contained'
            onClick={handelClick}
          >
            Enregistrer
          </Button>
          <Link
            className='link textContent'
            onClick={() => {
              setCurrentStep(currentStep - 1);
            }}
          >
            {'< Retour'}
          </Link>
        </div>
        
      </div>
    </div>
  );
}