import React from 'react';
import { Link } from 'react-router-dom';
import './content.css';

export default function Content() {
  return (
    <div className='contratTravailContent'>
      <h3>Qu’est-ce qu’un contrat de travail ?</h3>
      <h6 id='definitionContrat'>Définition d’un contrat de travail</h6>
      <p>
        Le contrat de travail est un <strong>contrat conclu entre un salarié et un employeur.</strong> 
        Dans le contrat de travail, l’employé s’engage à effectuer un travail pour le compte et 
        <strong>sous la subordination de son employeur, moyennant une rémunération définie.</strong>
      </p>
      <p className='infoContent'>
        <strong>Bon à savoir :</strong> certaines conventions collectives déterminent un seuil 
        de rémunération minimum pour les salariés, comme le prévoit la
        <Link className='linkContent' to='#'> grille de salaire du Syntec</Link>.
      </p>
      <p>La qualification du contrat de travail suppose la réunion de plusieurs critères cumulatifs :</p>
      <ul>
        <li>La <strong>fourniture d’un travail ;</strong></li>
        <li>En contrepartie d’une <strong>rémunération ;</strong></li>
        <li>
          L’existence d’un <Link className='linkContent' to='#'> lien de subordination </Link> 
          entre l’employeur et le salarié.
        </li>
      </ul>
      <p>
        Ainsi, le contrat de travail lie le salarié et l’employeur juridiquement, 
        et <strong>définit la relation de subordination du salarié</strong> par rapport à son employeur, 
        dans le cadre de l’exercice de son travail.
      </p>
      <h6 id='contratExistant'>Quels sont les contrats de travail existants ?</h6>
      <p>
        Il en existe de nombreux types de contrats variables selon le temps de travail, 
        l’activité de l’employeur <strong>ou la nature du travail confié au salarié :</strong>
      </p>
      <ul>
        <li>
          le <Link className='linkContent' to='#'>contrat de travail à durée indéterminée</Link> (CDI) est, 
          par défaut, le contrat de travail s’appliquant à toute relation de travail dans laquelle 
          il existe un lien de subordination. Il ne prévoit pas la date à laquelle il prend fin, 
          contrairement au contrat à durée déterminée.
        </li>
        <li>
          le <Link className='linkContent' to='#'>contrat de travail à durée déterminée</Link> (CDD) 
          conclu pour une durée limitée.
        </li>
        <li>
          le <Link className='linkContent' to='#'>contrat de travail à temps plein</Link> 
          (qui peut être un <Link className='linkContent' to='#'>CDD ou un CDI</Link>) est un contrat 
          au titre duquel le salarié travaille pendant toute la durée légale hebdomadaire 
          (35 heures par semaine).
        </li>
        <li>
          le <Link className='linkContent' to='#'>contrat de travail à temps partiel</Link>, 
          qui peut être conclu pour une durée déterminée ou indéterminée.
        </li>
        <li>le <Link className='linkContent' to='#'>contrat d’apprentissage</Link> ;</li>
        <li>le <Link className='linkContent' to='#'>contrat de professionnalisation</Link> ;</li>
        <li>le <Link className='linkContent' to='#'>contrat de travail temporaire</Link> ;</li>
        <li>le <Link className='linkContent' to='#'>contrat de travail intermittent</Link> ;</li>
        <li>le <Link className='linkContent' to='#'>contrat de travail saisonnier</Link>.</li>
      </ul>
      <p className='infoContent'>
        <strong>À noter :</strong> il existe également le contrat de travail en portage salarial, 
        de plus en plus utilisé, conclu entre une entreprise de portage salarial 
        (qui emploie le salarié et le rémunère), une entreprise cliente 
        (qui bénéficie du travail du salarié et paye l’entreprise de portage salarial) 
        et un salarié (le salarié porté, qui effectuera personnellement, par son travail, 
        la mission désignée au sein de l’entreprise cliente).
      </p>
      <p>
        Au sein même des contrats, on trouve certaines distinctions. 
        La principale distinction est la <strong>différence entre un contrat de travail à temps plein 
        et un contrat de travail à temps partiel.</strong>
      </p>
      <p>
        Un contrat à temps plein est le contrat de travail de base. Ainsi, en l’absence d’écrit, 
        la relation de travail sera, par défaut, <strong>qualifiée de CDI à temps plein.</strong> 
        La durée légale hebdomadaire de travail est de 35 heures. Toutefois, 
        des aménagements peuvent être prévus permettant de prévoir une 
        <Link className='linkContent' to='#'>durée supérieure dans certains cas strictement autorisés </Link>
        (notamment par l’instauration d’un forfait jour lorsque la convention collective l’autorise).
      </p>
      <p>
        En cas de contrat de travail à temps partiel, <strong>un contrat écrit sera nécessaire.</strong> 
        A défaut d’accord, la durée minimale de travail est
        <strong>fixée à 24 heures par semaine.</strong>Toutefois, des exceptions permettent de prévoir des
        <Link className='linkContent' to='#'>durées de travail en dessous de ce minimum légal</Link>
        , notamment à la demande expresse du salarié ou si la convention collective le prévoit.
      </p>
      <p>
        Il est possible, au sein d’un même contrat de travail, de passer du temps partiel au temps complet, 
        ou l’inverse (sous certaines conditions) en <strong>signant un avenant au contrat de travail.</strong> 
        Il est possible d’effectuer <Link className='linkContent' to='#'>un forfait jours</Link> 
        à la place des 35 heures.
      </p>
      <h3>Quelles sont les caractéristiques du contrat de travail ?</h3>
      <h6 id='partieContrat'>Les parties au contrat</h6>
      <p>
        Il s’agit d’un contrat conclu entre un salarié et un employeur, au titre duquel le salarié 
        s’engage à travailler pour le compte et sous la direction de l’employeur, en contrepartie 
        d’une rémunération. La relation entre le salarié et l’employeur fait naître
        <strong>des obligations légales et conventionnelles pour les deux parties.</strong>
      </p>
      <p>
        L’employeur peut être une personne physique ou une personne morale. 
        La personne qui signe le contrat de travail pour le compte de l’employeur doit 
        <strong>avoir le pouvoir d’engager la société :</strong> président, gérant ou directeur 
        dont les fonctions intègrent le recrutement de salariés.
      </p>
      <p>
        Le salarié peut être toute personne physique. Toutefois, il existe des 
        <strong>restrictions concernant les majeurs sous tutelle</strong> 
        et les mineurs de moins de 18 ans.
      </p>
      <h6 id='formalisme'>Le formalisme du contrat de travail</h6>
      <p>
        Hormis le contrat de travail à durée indéterminée à temps plein, qui peut être
        <Link className='linkContent' to='#'>verbal</Link>, les autres contrats de travail 
        sont obligatoirement <strong>matérialisés par un écrit :</strong>
      </p>
      <ul>
        <li>le CDD ;</li>
        <li>le contrat de travail à temps partiel ;</li>
        <li>le contrat de travail intermittent ;</li>
        <li>le contrat de travail temporaire ;</li>
        <li>le contrat de professionnalisation.</li>
      </ul>
      <p>
        Toutefois, si le contrat de travail est verbal, l’employeur a l’obligation de remettre 
        au salarié un document écrit reprenant les informations contenues dans la 
        <Link className='linkContent' to='#'>déclaration préalable à l’embauche</Link>, 
        adressée à l’URSSAF.
      </p>
      <h6 id='redactionContrat'>Comment rédiger un contrat de travail ?</h6>
      <p>
        L’employeur et le salarié peuvent 
        <strong>librement négocier le contenu du contrat de travail.</strong> 
        Cependant, certaines règles doivent être respectées :
      </p>
      <ul>
        <li>
          le contrat doit obligatoirement contenir les 
          <strong>mentions prévues par le Code du travail ;</strong>
        </li>
        <li>
          certaines <strong>clauses peuvent être insérées</strong> 
          (<Link className='linkContent' to='#'>clause de confidentialité</Link> par exemple), 
          à condition de ne pas être contraires à l’ordre public 
          (clause <Link className='linkContent' to='#'>discriminatoire</Link>).
        </li>
      </ul>
      <p>
        Les contrats de travail pour lesquels la loi ou une convention collective applicable 
        imposent un écrit doivent <strong>absolument comporter des mentions obligatoires.</strong>
      </p>
      <p>
        Ainsi, il est fortement recommandé que les éléments essentiels de la relation de travail 
        figurent au contrat de travail, tels que :
      </p>
      <ul>
        <li>la fonction du salarié ;</li>
        <li>le <Link className='linkContent' to='#'>salaire</Link> ;</li>
        <li>les horaires de travail ;</li>
        <li>la durée de la <Link className='linkContent' to='#'>période d’essai</Link> ;</li>
        <li>le lieu de travail et les conditions de mobilité le cas échéant ;</li>
        <li>
          les conditions particulières librement négociées entre le salarié et l’employeur, 
          dans les limites des cadres légaux, conventionnels et jurisprudentiels 
          (clause de confidentialité, 
          <Link className='linkContent' to='#'>clause de non concurrence</Link>, etc.)
        </li>
      </ul>
      <p className='infoContent'>
        <strong>A noter :</strong> le contrat de travail doit être rédigé en langue française, 
        sauf cas particuliers. En cas d&apos;
        <Link className='linkContent' to='#'> embauche d’un salarié étranger</Link>, celui-ci 
        peut demander la traduction du contrat dans sa langue d’origine.
      </p>
      <h3>Comment choisir mon modèle de contrat de travail ?</h3>
      <p>
        LegalPlace propose des modèles de contrats de travail (CDI et CDD), 
        <strong>rédigés par des avocats expérimentés en droit du travail </strong>
        et mis à jour des dispositions du <Link className='linkContent' to='#'>Code du travail</Link>.
      </p>
      <p>
        Il vous suffit simplement de remplir un questionnaire et, par la suite, 
        imprimer ou télécharger votre exemple de contrat sous format PDF ou Word. 
        Ce formulaire, très simple à utiliser, a été conçu par nos experts. 
        LegalPlace vous recommande en option de vous faire assister (par téléphone) 
        par un avocat expérimenté de son réseau.
      </p>
      <h3>Quelles sont les principales obligations incombant au salarié et à l’employeur ?</h3>
      <p>
        Le contrat de travail fait naître des 
        <strong>obligations réciproques à la charge du salarié et de l’employeur.</strong>
      </p>
      <h6 id='obligationEmployeur'>Les principales obligations de l’employeur </h6>
      <p>Le contrat de travail fait naître des obligations incombant à l’employeur :</p>
      <ul>
        <li><strong>rémunérer le salarié ;</strong></li>
        <li><strong>fournir un travail au salarié,</strong> conformément au temps de travail convenu ;</li>
        <li>
          respecter les dispositions légales et, le cas échéant, les dispositions de 
          la convention collective applicable au sein de l’entreprise ;
        </li>
        <li>
          respecter les clauses particulières du contrat de travail, préalablement négociées avec le salarié.
        </li>
      </ul>
      <h6 id='obligationSalarie'>Les principales obligations du salarié </h6>
      <p>Certaines obligations incombent au salarié :</p>
      <ul>
        <li>
          <strong>effectuer son travail</strong> 
          conformément aux dispositions du contrat de travail et aux instructions de l’employeur ;
        </li>
        <li><strong>respecter les heures de travail</strong> convenues ;</li>
        <li>respecter les <strong>autres engagements</strong> pris au titre du contrat de travail ;</li>
        <li>
          <strong>ne pas se comporter de manière déloyale</strong> 
          vis-à-vis de l’entreprise qui l’emploie.
        </li>
      </ul>
      <h3>Modification et suspension du contrat</h3>
      <h6 id='avenant'>L’utilisation d’un avenant pour modification du contrat de travail</h6>
      <p>
        Le CDI s’étalant en principe sur une longue durée, il est fréquent que l’employeur 
        et le salarié souhaitent, au cours de leur relation contractuelle, 
        <strong>modifier certains aspects de leur collaboration.</strong>
      </p>
      <p>
        Lorsqu’il s’agit simplement de modifications liées aux conditions de travail, 
        <strong>un avenant n’est pas nécessairement requis.</strong> En revanche, 
        s’il s’agit de <Link className='linkContent' to='#'>modifications</Link> altérant 
        un des éléments essentiels du contrat de travail, le salarié doit y donner son consentement. 
        Il sera dès lors utile de signer un 
        <Link className='linkContent' to='#'>avenant au contrat de travail</Link>.
      </p>
      <p>
        LegalStation propose plusieurs modèles d’
        <Link className='linkContent' to='#'>avenants</Link> parmi les plus courants, notamment :
      </p>
      <ul>
        <li><strong>L’avenant de passage d’un CDD à un CDI ;</strong></li>
        <li>
          L’avenant de <strong>passage à temps plein</strong> : lorsque le salarié a commencé 
          sa mission en temps partiel et que l’employeur souhaite accroître sa durée de travail 
          en lui proposant une mission à temps plein ;
        </li>
        <li>
          L’avenant de passage à temps partiel, fréquemment utilisé lorsque le salarié souhaite 
          réduire son temps de travail dans l’entreprise ;
        </li>
        <li>L’avenant de <strong>mise à disposition d’un véhicule de fonction ;</strong></li>
        <li>L’avenant d’ajout d’une clause de non-concurrence au contrat de travail.</li>
      </ul>
      <p className='infoContent'>
        <strong>A noter :</strong> sur la forme, l’avenant est un nouveau contrat qui vient purement 
        et simplement s’ajouter au contrat de travail existant, en modifiant seulement les dispositions 
        impactées par ce que les parties veulent changer dans leur relation. Le reste du contrat de 
        travail restera inchangé.
      </p>
      <h6 id='suspension'>Les situations entraînant la suspension du contrat de travail</h6>
      <p>
        Certaines situations empêchent <strong>temporairement le salarié d’exécuter sa 
        prestation de travail</strong> : le contrat de travail est alors suspendu. 
        Les absences ou congés entraînant la suspension du contrat de travail sont les suivants :
      </p>
      <ul>
        <li>Congés payés ;</li>
        <li>Congé sabbatique ;</li>
        <li><Link className='linkContent' to='#'>Congé parental</Link> ;</li>
        <li><Link className='linkContent' to='#'>Congé maternité</Link> ;</li>
        <li><Link className='linkContent' to='#'>Congé paternité</Link> ;</li>
        <li><Link className='linkContent' to='#'>Congé pour création ou reprise d’entreprise</Link> ;</li>
        <li>
          <Link className='linkContent' to='#'>Arrêts de travail</Link> pour maladie professionnelle, 
          accidents de travail ;
        </li>
        <li>Congés de formation.</li>
      </ul>
      <p>
        En principe, le salarié n’est <strong>pas rémunéré pendant la durée de suspension</strong> 
        de son contrat de travail. Toutefois, il <strong>perçoit des indemnités</strong>, 
        notamment en cas de congés payés, de congé maternité, etc.
      </p>
      <p className='infoContent'>
        <strong>Bon à savoir :</strong> le salarié peut également percevoir des indemnités 
        en cas de <Link className='linkContent' to='#'>licenciement économique individuel</Link>.
      </p>
      <h3>Le risque de requalification d’une relation économique en contrat de travail</h3>
      <p>
        Le contrat de travail (CDI à temps plein) est la relation de travail de base, 
        qui <strong>se présume de toute relation économique entre une personne physique 
        et une entreprise</strong>, dès lors qu’il existe un lien de subordination entre les deux.
      </p>
      <p>
        Ainsi, tout autre type de contrat prévoyant des prestations de services 
        (tel que le contrat de prestation de services ou de freelance) peut être 
        <strong>requalifié en contrat de travail</strong> dès lors que dans les faits, 
        le prestataire et son client se comportent comme un salarié et son employeur.
      </p>
      <p>
        C’est la relation concrète entre les parties qui sera appréciée par les juges prud’homaux, 
        peu importe qu’un contrat de prestation de services ait expressément stipulé leur indépendance 
        réciproque, que la prestation soit occasionnelle, etc.
      </p>
      <p>
        La requalification d’une relation en contrat de travail est dès lors possible, 
        <strong>même en l’absence d’un contrat</strong> et de toute relation formalisée par écrit.
      </p>
      <p>
        En cas de requalification en contrat de travail, 
        le <strong>salarié nouvellement qualifié a droit à la protection applicable</strong> aux salariés
        au titre du Code du travail et de la convention collective applicable.
      </p>
      <h3>Quel rôle pour les conventions collectives ou accords collectifs ?</h3>
      <p>
        Les <Link className='linkContent' to='#'>conventions collectives</Link> ou accords collectifs 
        sont des dispositions négociées et signées entre des <strong>groupements d’employeurs et une 
        ou plusieurs organisations syndicales représentatives des salariés. </strong> 
        Une convention collective contient des règles spécifiques applicables à un secteur 
        particulier, venues compléter les dispositions du Code du travail.
      </p>
      <p>
        Les règles posées ont principalement pour objet d’
        <strong>encadrer les conditions d’emploi et de travail des salariés</strong>, 
        en tenant compte des spécificités d’un secteur ou d’un métier, 
        ainsi que d’établir des garanties sociales complémentaires.
      </p>
      <p>
        Elles adaptent donc les règles posées par le Code du travail aux situations particulières 
        d’un secteur d’activité.
      </p>
      <p>
        Ainsi, lorsqu’on rédige un contrat de travail, il convient toujours de le faire à la double 
        lecture du Code du travail et de la convention collective et/ou de l’accord collectif applicables. 
        Une convention collective ne peut qu’<strong>apporter des règles plus favorables au salarié </strong> 
        que le Code du travail.
      </p>
      <p>
        Ainsi, le Code de travail est la réglementation encadrant le contrat de travail, 
        puis la convention collective ou l’accord collectif peuvent apporter 
        <strong>des règles dérogatoires plus favorables</strong> 
        (par exemple, l’instauration d’une période d’essai maximale, d’une indemnité de licenciement 
        plus élevée que l’indemnité légale, etc.).
      </p>
      <p className='infoContent'>
        <strong>À noter :</strong> si une convention collective est applicable à l’entreprise, 
        l’employeur doit informer les salariés et fournir un exemplaire de ce texte au Comité 
        social et économique.
      </p>
    </div>
  );
}
