import React from 'react';

export default function StepProgress({ numberSteps, currentStep }) {
  const getStepsList = (numberStepsValue) => {
    return Array.from({ length: numberStepsValue }, (_, i) => i + 1);
  };

  return (
    <div className="flex justify-start mt-5 mb-12 w-[300px] md:w-[700px] space-x-2">
      {getStepsList(numberSteps).map((item) => (
        <Step key={item} isActive={item <= currentStep} />
      ))}
    </div>
  );
}

function Step({ isActive = false }) {
  return (
    <div className={`w-full h-[5px] rounded-full border border-grayv4 ${isActive ? 'bg-primary' : 'bg-gray-300'}`} />
  );
}
