import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { BlobProvider } from '@react-pdf/renderer';
import axios from 'axios';
import StepProgress from '../../../../components/stepProgress/stepProgress';
import HeaderContainer from '../../../../components/headerContainer/headerContainer';
import CustomTextInput from '../../../../components/inputs/customTextInput/customTextInput';
import getEnvironement from '../../../../environnement';
import number from '../../../../assets/icons/number.png';
import CustomSelect from '../../../../components/inputs/customSelect';
import PDFDocument from './pdfDocument';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, userSelector } from '../../../../store/userSlice';
import Packs from '../../packs';

export default function MonStatusForm() {
  const [currentStep, setCurrentStep] = useState(1);

  //entreprise
  const [denomination, setDenomination] = useState('');
  const [sigle, setSigle] = useState('');
  const [formeJuridique, setFormeJuridique] = useState('');
  const [ville, setVille] = useState('');
  const [activiteCommercial, setActiviteCommercial] = useState('');
  // soussignés
  const [nombreSoussignes, setNombreSoussignes] = useState(1);
  const [soussignes, setSoussignes] = useState([]);
  // siege
  const [villeTribunalCommerce, setVilleTribunalCommerce] = useState('');
  const [siegeSocial, setSiegeSocial] = useState('');
  // duree de la société
  const [dureeSociete, setDureeSociete] = useState('');
  // objet social
  const [nombreObjetSocial, setNombreObjetSocial] = useState(1);
  const [objetsSocial, setObjetsSocial] = useState([]);
  // apport
  const [capitalSocial, setCapitalSocial] = useState('');
  const [nombreApports, setNombreApports] = useState(1);
  const [apports, setApports] = useState([]);
  // part social
  const [nombrePartsSocial, setNombrePartsSocial] = useState(1);
  const [partsSocial, setPartsSocial] = useState([]);
  //pack
  const [selectedPack, setSelectedPack] = useState();

  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  const saveData = async (pdfBlob) => {
    const url = `${getEnvironement().API_URL}/monStatus`;
    
    const data = new FormData();
    data.append('user_id', user.id);
    data.append('denomination', denomination);
    data.append('sigle', sigle);
    data.append('formeJuridique', formeJuridique);
    data.append('ville', ville);
    data.append('activiteCommercial', activiteCommercial);
    data.append('soussignes', JSON.stringify(soussignes));
    data.append('villeTribunalCommerce', villeTribunalCommerce);
    data.append('siegeSocial', siegeSocial);
    data.append('dureeSociete', dureeSociete);
    data.append('objetsSocial', JSON.stringify(objetsSocial));
    data.append('capitalSocial', capitalSocial);
    data.append('apports', JSON.stringify(apports));
    data.append('partsSocial', JSON.stringify(partsSocial));
    data.append('packId', selectedPack);
    data.append('pdf', pdfBlob, 'document.pdf');

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    try {
      await axios.post(url, data, config)
        .then((response) => {
          if (response.status === 200) {
            dispatch(setUser(response.data));
            navigate('/dashboard');
          }
        });
    } catch (error) {
      console.log('Error saving data', error);
    }
  };

  const handelEnregisterClick = (pdfBlob) => {
    saveData(pdfBlob);
  };

  const getQuestion = (currentStep, pdfBlob) => {
    switch(currentStep) {
    case 1 :
      return (
        <QEntreprise
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          denomination={denomination}
          setDenomination={setDenomination}
          sigle={sigle}
          setSigle={setSigle}
          formeJuridique={formeJuridique}
          setFormeJuridique={setFormeJuridique}
          ville={ville}
          setVille={setVille}
          activiteCommercial={activiteCommercial}
          setActiviteCommercial={setActiviteCommercial}
        />
      );
    case 2 :
      return (
        <QSoussignes
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          nombreSoussignes={nombreSoussignes}
          setNombreSoussignes={setNombreSoussignes}
          soussignes={soussignes}
          setSoussignes={setSoussignes}
        />
      );
    case 3 :
      return (
        <QSiegeSocial
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          villeTribunalCommerce={villeTribunalCommerce}
          setVilleTribunalCommerce={setVilleTribunalCommerce}
          siegeSocial={siegeSocial}
          setSiegeSocial={setSiegeSocial}
        />);
    case 4 :
      return (
        <QDureeSociete
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          dureeSociete={dureeSociete}
          setDureeSociete={setDureeSociete}
        />);
    case 5 :
      return (
        <QObjetSocial
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          nombreObjetSocial={nombreObjetSocial}
          setNombreObjetSocial={setNombreObjetSocial}
          objetsSocial={objetsSocial}
          setObjetsSocial={setObjetsSocial}
        />
      );
    case 6 :
      return (
        <QApports
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          soussignes={soussignes}
          capitalSocial={capitalSocial}
          setCapitalSocial={setCapitalSocial}
          apports={apports}
          setApports={setApports}
          nombreApports={nombreApports}
          setNombreApports={setNombreApports}
          handelEnregisterClick={handelEnregisterClick}
        />
      );
    case 7 :
      return (
        <QPartsSocial
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          soussignes={soussignes}
          partsSocial={partsSocial}
          setPartsSocial={setPartsSocial}
          nombrePartsSocial={nombrePartsSocial}
          setNombrePartsSocial={setNombrePartsSocial}
          handelEnregisterClick={handelEnregisterClick}
          pdfBlob={pdfBlob}
        />
      );
    case 8 :
      return (
        <Packs
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          userId={user.id}
          typePack='entreprise'
          productType='monStatus'
          setSelectedPack={setSelectedPack}
          data={{
            user_id: user.id,
            denomination,
            sigle,
            formeJuridique,
            ville,
            activiteCommercial,
            soussignes,
            villeTribunalCommerce,
            siegeSocial,
            dureeSociete,
            objetsSocial,
            capitalSocial,
            apports,
            partsSocial,
            selectedPack,
            packId: selectedPack,
            pdfBlob
          }}
        />
      );
    case 9:
      return (
        <div className='mb-10'>
          <p className='text-base font-medium text-neutral mb-2'>Confirmation :</p>
          <div className='flex gap-5 mt-5'>
            <Button
              color='info'
              variant='outlined'
              onClick={() => {
                setCurrentStep(currentStep - 1);
              }}
            >
              {'< Retour'}
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={() => handelEnregisterClick(pdfBlob)}
            >
              Enregistrer
            </Button>
          </div>
        </div>
      );
    default :
      return (<div />);
    }
  };

  return (
    <div>
      <HeaderContainer />
      <div className='h-screen flex flex-col justify-start items-center px-10 mt-10'>
        <h3 className=''>CREER MON STATUS</h3>
        <StepProgress numberSteps={9} currentStep={currentStep}/>
        <BlobProvider document={
          <PDFDocument
            denomination={denomination}
            formeJuridique={formeJuridique}
            ville={ville}
            soussignes={soussignes}
            villeTribunalCommerce={villeTribunalCommerce}
            siegeSocial={siegeSocial}
            dureeSociete={dureeSociete}
            objetsSocial={objetsSocial}
            capitalSocial={capitalSocial}
            apports={apports}
          />
        }>
          {({ blob }) => {
            return (
              <div className='w-fit md:w-[700px]'>
                {getQuestion(currentStep, blob)}
              </div>
            );
          }}
          
        </BlobProvider>
      </div>
    </div>

  );
}

function Personne({
  title,
  setPersonne
}) {
  const [typePersonne, setTypePersonne] = useState('Personne physique');
  const [nomPersonne, setNomPersonne] = useState('');
  const [prenomPersonne, setPrenomPersonne] = useState('');
  const [nationalitePersonne, setNationalitePersonne] = useState('');
  const [villeResidencePersonne, setVilleResidencePersonne] = useState('');
  const [cinPersonne, setCinPersonne] = useState('');
  const [denominationPersonne, setDenominationPersonne] = useState('');
  const [icePersonne, setIcePersonne] = useState('');
  const [rcPersonne, setRcPersonne] = useState('');
  const [gsmPersonne, setGsmPersonne] = useState('');
  const [capitalSocialPersonne, setCapitalSocialPersonne] = useState('');
  const [formeJuridiquePersonne, setFormeJuridiquePersonne] = useState('Société à responsabilité limitée');
  const [emailPersonne, setEmailPersonne] = useState('');
  const [adressePersonne, setAdressePersonne] = useState('');

  /*
    [
      {
        type: 'personne physique',
        nom,
        prenom,
        nationalité,
        dateNaissance,
        cin,
        email,
        gsm,
        villeResidence,
        adresse
      },
      {
        type: 'personne morale',
        denomination,
        ice,
        rc,
        email,
        gsm,
        villeSciege,
        AdressSiege,
        nomRepresentant,
        PrenomRepresentant,
        cinRepresentant
      }
    ]
  */
  useEffect(() => {
    if (typePersonne == 'Personne physique') {
      setPersonne({
        type: 'Personne physique',
        nom: nomPersonne,
        prenom: prenomPersonne,
        nationalite: nationalitePersonne,
        cin: cinPersonne,
        email: emailPersonne,
        gsm: gsmPersonne,
        villeResidence: villeResidencePersonne,
        adresse: adressePersonne
      });
    } else {
      setPersonne({
        type: 'Personne morale',
        denomination: denominationPersonne,
        capitalSocial: capitalSocialPersonne,
        formeJuridique: formeJuridiquePersonne,
        ice: icePersonne,
        rc: rcPersonne,
        villeSciege: villeResidencePersonne,
        adresseSiege: adressePersonne,
        nomRepresentant: nomPersonne,
        prenomRepresentant: prenomPersonne,
        emailRepresantant: emailPersonne,
        gsmRepresentant: gsmPersonne,
        cinRepresentant: cinPersonne
      });
    }
  }, [
    typePersonne,
    nomPersonne,
    prenomPersonne,
    nationalitePersonne,
    villeResidencePersonne,
    cinPersonne,
    denominationPersonne,
    icePersonne,
    rcPersonne,
    gsmPersonne,
    emailPersonne,
    adressePersonne,
    capitalSocialPersonne,
    formeJuridiquePersonne
  ]);

  return (
    <div>
      <p className='text-base font-medium text-neutral mb-2'>{title} :</p>
      <CustomSelect
        value={typePersonne}
        setValue={setTypePersonne}
        defaultValue={'Personne physique'}
        items={[
          {label: 'Personne physique', value: 'Personne physique'},
          {label: 'Personne morale', value: 'Personne morale'},
        ]}
      />
      {
        typePersonne == 'Personne physique'
          ? (
            <>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={nomPersonne}
                  setValue={setNomPersonne}
                  placeholder='Nom' />
                <CustomTextInput
                  image={number}
                  value={prenomPersonne}
                  setValue={setPrenomPersonne}
                  placeholder='Prénom' />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={nationalitePersonne}
                  setValue={setNationalitePersonne}
                  placeholder='Nationalité'
                />
                <CustomTextInput
                  image={number}
                  value={cinPersonne}
                  setValue={setCinPersonne}
                  placeholder='CIN'
                />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={gsmPersonne}
                  setValue={setGsmPersonne}
                  placeholder='GSM'
                />
                <CustomTextInput
                  image={number}
                  value={emailPersonne}
                  setValue={setEmailPersonne}
                  placeholder='E-mail'
                />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={villeResidencePersonne}
                  setValue={setVilleResidencePersonne}
                  placeholder='Ville de résidence'
                />
                <CustomTextInput
                  image={number}
                  value={adressePersonne}
                  setValue={setAdressePersonne}
                  placeholder='Adresse de résidence'
                />
              </div>
            </>
          )
          : (
            <>
              <CustomTextInput
                image={number}
                value={denominationPersonne}
                setValue={setDenominationPersonne}
                placeholder='Dénomination commerciale'
              />
              <div className='flex flex-col md:flex-row'>
                <CustomSelect
                  value={formeJuridiquePersonne}
                  setValue={setFormeJuridiquePersonne}
                  items={[
                    {label: 'Société à responsabilité limitée', value: 'Société à responsabilité limitée'},
                    {label: 'Société à responsabilité limitée à associé unique', value: 'Société à responsabilité limitée à associé unique'},
                    {label: 'Société anonyme', value: 'Société anonyme'},
                    {label: 'Société en nom collectif', value: 'Société en nom collectif'},
                    {label: 'Société en commandite par actions', value: 'Société en commandite par actions'},
                    {label: 'Groupement d\'intérêt économique', value: 'Groupement d\'intérêt économique'},
                    {label: 'Société civile immobilière', value: 'Société civile immobilière'},
                    {label: 'Société en participation', value: 'Société en participation'},
                    {label: 'Société par actions simplifiée', value: 'Société par actions simplifiée'},
                    {label: 'Succursale d’une société étrangère', value: 'Succursale d’une société étrangère'}
                  ]}
                />
                <CustomTextInput
                  image={number}
                  type='number'
                  min={0}
                  value={capitalSocialPersonne}
                  setValue={setCapitalSocialPersonne}
                  placeholder='Montant du capital social'
                />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={icePersonne}
                  setValue={setIcePersonne}
                  placeholder={'Identifiant commun de l\'entreprise (ICE)'}
                />
                <CustomTextInput
                  image={number}
                  value={rcPersonne}
                  setValue={setRcPersonne}
                  placeholder='Numéro de registre de commerce (RC)'
                />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={villeResidencePersonne}
                  setValue={setVilleResidencePersonne}
                  placeholder='Ville du siège social'
                />
                <CustomTextInput
                  image={number}
                  value={adressePersonne}
                  setValue={setAdressePersonne}
                  placeholder='Adresse du sciège social'
                />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={nomPersonne}
                  setValue={setNomPersonne}
                  placeholder='Nom du représentant'
                />
                <CustomTextInput
                  image={number}
                  value={prenomPersonne}
                  setValue={setPrenomPersonne}
                  placeholder='Prénom du représentant'
                />
                <CustomTextInput
                  image={number}
                  value={cinPersonne}
                  setValue={setCinPersonne}
                  placeholder='CIN du représantant'
                />
              </div>
              <div className='flex flex-col md:flex-row'>
                <CustomTextInput
                  image={number}
                  value={gsmPersonne}
                  setValue={setGsmPersonne}
                  placeholder='GSM du représantant'
                />
                <CustomTextInput
                  image={number}
                  value={emailPersonne}
                  setValue={setEmailPersonne}
                  placeholder='E-mail du représantant'
                />
              </div>
            </>
          )
      }
    </div>
  );
}

function range(nombre) {
  const result = [];
  for (let i = 1; i < parseInt(nombre) + 1; i++) {
    result.push(i);
  }
  return result;
}

function supprimerDerniersItems(liste, nombre) {
  return liste.slice(0, nombre);
}

function extraireNoms(liste) {
  return liste.map(item => {
    if (item.data.type === 'Personne physique') {
      return {label: `${item.data.nom} ${item.data.prenom}`, value: `${item.data.nom} ${item.data.prenom}`};
    } else if (item.data.type === 'Personne morale') {
      return {label: item.data.denomination, value: item.data.denomination};
    } else {
      return '';
    }
  });
}

//---------------Questions---------------------
function QEntreprise({
  currentStep,
  setCurrentStep,
  denomination,
  setDenomination,
  sigle,
  setSigle,
  formeJuridique,
  setFormeJuridique,
  ville,
  setVille,
  activiteCommercial,
  setActiviteCommercial,
}) {
  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Dénomination :</p>
      <div className='flex flex-col md:flex-row'>
        <span className='w-2/3'>
          <CustomTextInput
            image={number}
            value={denomination}
            setValue={setDenomination}
            placeholder='Dénomination'
          />
        </span>
        <span className='w-1/3'>
          <CustomTextInput
            image={number}
            value={sigle}
            setValue={setSigle}
            placeholder='Sigle'
          />
        </span>
      </div>
      <p className='text-base font-medium text-neutral mt-5 mb-2'>Forme Juridique :</p>
      <CustomSelect
        value={formeJuridique}
        setValue={setFormeJuridique}
        items={[
          {label: 'Société à responsabilité limitée', value: 'Société à responsabilité limitée'},
          {label: 'Société à responsabilité limitée à associé unique', value: 'Société à responsabilité limitée à associé unique'},
          {label: 'Société anonyme', value: 'Société anonyme'},
          {label: 'Société en nom collectif', value: 'Société en nom collectif'},
          {label: 'Société en commandite par actions', value: 'Société en commandite par actions'},
          {label: 'Groupement d\'intérêt économique', value: 'Groupement d\'intérêt économique'},
          {label: 'Société civile immobilière', value: 'Société civile immobilière'},
          {label: 'Société en participation', value: 'Société en participation'},
          {label: 'Société par actions simplifiée', value: 'Société par actions simplifiée'},
          {label: 'Succursale d’une société étrangère', value: 'Succursale d’une société étrangère'}
        ]}
      />
      <p className='text-base font-medium text-neutral mt-5 mb-2'>Ville :</p>
      <CustomTextInput
        image={number}
        value={ville}
        setValue={setVille}
        placeholder='Ville'
      />
      <p className='text-base font-medium text-neutral mt-5 mb-2'>Activité commercial :</p>
      <CustomSelect
        value={activiteCommercial}
        setValue={setActiviteCommercial}
        items={[
          {value: 'A', label:	'AGRICULTURE, SYLVICULTURE ET PÊCHE'},
          {value: 'B', label:	'INDUSTRIES EXTRACTIVES'},
          {value: 'C', label:	'INDUSTRIE MANUFACTURIÈRE'},
          {value: 'D', label:	'PRODUCTION ET DISTRIBUTION D\'ÉLECTRICITÉ, DE GAZ, DE VAPEUR ET D\'AIR CONDITIONNÉ'},
          {value: 'E', label:	'PRODUCTION ET DISTRIBUTION D\'EAU ; ASSAINISSEMENT, GESTION DES DÉCHETS ET DÉPOLLUTION'},
          {value: 'F', label:	'CONSTRUCTION'},
          {value: 'G', label:	'COMMERCE ; RÉPARATION D\'AUTOMOBILES ET DE MOTOCYCLES'},
          {value: 'H', label:	'TRANSPORTS ET ENTREPOSAGE'},
          {value: 'I', label:	'HÉBERGEMENT ET RESTAURATION'},
          {value: 'J', label:	'INFORMATION ET COMMUNICATION'},
          {value: 'K', label:	'ACTIVITÉS FINANCIÈRES ET D\'ASSURANCE'},
          {value: 'L', label:	'ACTIVITÉS IMMOBILIÈRES'},
          {value: 'M', label:	'ACTIVITÉS SPÉCIALISÉES, SCIENTIFIQUES ET TECHNIQUES'},
          {value: 'N', label:	'ACTIVITÉS DE SERVICES ADMINISTRATIFS ET DE SOUTIEN'},
          {value: 'O', label:	'ADMINISTRATION PUBLIQUE'},
          {value: 'P', label:	'ENSEIGNEMENT'},
          {value: 'Q', label:	'SANTÉ HUMAINE ET ACTION SOCIALE'},
          {value: 'R', label:	'ARTS, SPECTACLES ET ACTIVITÉS RÉCRÉATIVES'},
          {value: 'S', label:	'AUTRES ACTIVITÉS DE SERVICES'},
          {value: 'T', label:	'ACTIVITÉS DES MÉNAGES EN TANT QU\'EMPLOYEURS ; ACTIVITÉS INDIFFÉRENCIÉES DES MÉNAGES EN TANT QUE PRODUCTEURS DE BIENS ET SERVICES POUR USAGE PROPRE'},
          {value: 'U', label:	'ACTIVITÉS EXTRA-TERRITORIALES'},
        ]}
      />
      <div className='flex gap-5 mt-5'>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
      </div>
    </div>
  );
}

function QSoussignes({
  currentStep,
  setCurrentStep,
  nombreSoussignes,
  setNombreSoussignes,
  soussignes,
  setSoussignes,
}) {
  /*
    [
      {id: 1, data: {}},
      {id: 2, data: {}},
    ]
  */
  const modifySoussignesData = (soussignes, setSoussignes, soussigneId, soussigneData) => {
    let soussignesData = soussignes;
    soussignesData[soussigneId - 1] = {id: soussigneId, data: soussigneData};
    setSoussignes(soussignesData);
  };

  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Nombre de soussignés :</p>
      <CustomTextInput
        image={number}
        type='number'
        min={1}
        value={nombreSoussignes}
        setValue={setNombreSoussignes}
        placeholder='Nombre de soussignés'
      />
      {range(nombreSoussignes).map((id) => {
        return (
          <Personne
            key={id}
            title={`Soussigné ${id}`}
            setPersonne={(data) => modifySoussignesData(soussignes, setSoussignes, id, data)}
          />
        );
      })}
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
      </div>
    </div>
  );
}

function QSiegeSocial({
  currentStep,
  setCurrentStep,
  siegeSocial,
  setSiegeSocial,
  villeTribunalCommerce,
  setVilleTribunalCommerce,
}) {
  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Ville du tribunal du commerce :</p>
      <CustomTextInput
        image={number}
        value={villeTribunalCommerce}
        setValue={setVilleTribunalCommerce}
        placeholder='Ville du tribunal du commerce'
      />
      <p className='text-base font-medium text-neutral mb-2'>Siège social :</p>
      <CustomTextInput
        image={number}
        value={siegeSocial}
        setValue={setSiegeSocial}
        placeholder='Siège social'
      />
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
      </div>
    </div>
  );
}

function QDureeSociete({
  currentStep,
  setCurrentStep,
  dureeSociete,
  setDureeSociete,
}) {
  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Durée de la société :</p>
      <CustomTextInput
        image={number}
        min={0}
        type='number'
        value={dureeSociete}
        setValue={setDureeSociete}
        placeholder='Durée de la société en année'
      />
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
          Continuer
        </Button>
      </div>
    </div>
  );
}

function ObjetSocial({
  id,
  setObjetsSocial,
}) {
  const [objetSocial, setObjetSocial] = useState('');

  useEffect(() => {
    setObjetsSocial(objetSocial);
  }, [objetSocial]);

  return (
    <>
      <CustomTextInput
        image={number}
        value={objetSocial}
        setValue={setObjetSocial}
        placeholder={`Objet social ${id}`}
      />
    </>
  );
}

function QObjetSocial({
  currentStep,
  setCurrentStep,
  nombreObjetSocial,
  setNombreObjetSocial,
  objetsSocial,
  setObjetsSocial,
}) {
  useEffect(() => {
    setObjetsSocial(supprimerDerniersItems(objetsSocial, nombreObjetSocial));
  }, [nombreObjetSocial]);

  const modifyObjetsSocialData = (objetsSocial, setObjetsSocial, idObjetSocial, objetSocial) => {
    let objetsSocialData = objetsSocial;
    objetsSocialData[idObjetSocial - 1] = {id: idObjetSocial, objetSocial};
    setObjetsSocial(objetsSocialData);
  };

  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Ajouter / Supprimer un objet social :</p>
      <CustomTextInput
        image={number}
        type='number'
        min={0}
        value={nombreObjetSocial}
        setValue={setNombreObjetSocial}
      />
      <p className='text-base font-medium text-neutral mb-2'>Objet social :</p>
      {range(nombreObjetSocial).map((id) => {
        return (
          <ObjetSocial
            key={id}
            id={id}
            setObjetsSocial={(objetSocial) => modifyObjetsSocialData(objetsSocial, setObjetsSocial, id, objetSocial)}
          />
        );
      })}
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
          Continuer
        </Button>
      </div>
    </div>
  );
}

function Apport({
  id,
  soussignes,
  setApports,
}) {
  const [soussigne, setSoussigne] = useState('');
  const [apport, setApport] = useState('');
  const [autreApport, setAutreApport] = useState('');

  useEffect(() => {
    setApports({
      id,
      soussigne,
      apport,
      autreApport
    });
  }, [
    soussigne,
    apport,
    autreApport
  ]);

  return (
    <>
      <p className='text-base font-medium text-neutral mb-2'>apport {id}</p>
      <div className='flex flex-col md:flex-row'>
        <CustomSelect
          value={soussigne}
          setValue={setSoussigne}
          items={extraireNoms(soussignes)}
        />
        <CustomTextInput
          image={number}
          type='number'
          value={apport}
          setValue={setApport}
          placeholder='Apport en numéraire'
        />
      </div>
      <CustomTextInput
        image={number}
        value={autreApport}
        setValue={setAutreApport}
        placeholder='Autres apports'
      />
    </>
  );
}

function QApports({
  currentStep,
  setCurrentStep,
  soussignes,
  capitalSocial,
  setCapitalSocial,
  apports,
  setApports,
  nombreApports,
  setNombreApports
}) {
  useEffect(() => {
    setApports(supprimerDerniersItems(apports, nombreApports));
  }, [nombreApports]);

  const modifyApportsData = (apports, setApports, idApport, apport) => {
    let apportsData = apports;
    apportsData[idApport - 1] = apport;
    setApports(apportsData);
  };

  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Montant du capital social :</p>
      <CustomTextInput
        image={number}
        type='number'
        min={0}
        placeholder='Montant du capital social'
        value={capitalSocial}
        setValue={setCapitalSocial}
      />
      <p className='text-base font-medium text-neutral mb-2'>Ajouter / Supprimer un apport :</p>
      <CustomTextInput
        image={number}
        type='number'
        min={0}
        value={nombreApports}
        setValue={setNombreApports}
      />
      <p className='text-base font-medium text-neutral mt-5 mb-2'>Apports :</p>
      {range(nombreApports).map((id) => {
        return (
          <Apport
            key={id}
            id={id}
            soussignes={soussignes}
            setApports={(apport) => modifyApportsData(apports, setApports, id, apport)}
          />
        );
      })}
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
          Continuer
        </Button>
      </div>
    </div>
  );
}

function PartSocial({
  id,
  soussignes,
  setPartsSocial,
}) {
  const [soussigne, setSoussigne] = useState('');
  const [partSocial, setPartSocial] = useState('');

  useEffect(() => {
    setPartsSocial({
      id,
      soussigne,
      partSocial
    });
  }, [
    soussigne,
    partSocial
  ]);

  return (
    <>
      <div className='flex flex-col md:flex-row'>
        <CustomSelect
          value={soussigne}
          setValue={setSoussigne}
          items={extraireNoms(soussignes)}
        />
        <CustomTextInput
          image={number}
          type='number'
          value={partSocial}
          setValue={setPartSocial}
          placeholder='Part Social'
        />
      </div>
    </>
  );
}

function QPartsSocial({
  currentStep,
  setCurrentStep,
  soussignes,
  partsSocial,
  setPartsSocial,
  nombrePartsSocial,
  setNombrePartsSocial,
  // handelEnregisterClick,
  // pdfBlob
}) {
  useEffect(() => {
    setPartsSocial(supprimerDerniersItems(partsSocial, nombrePartsSocial));
  }, [nombrePartsSocial]);

  const modifyPartsSocialData = (partsSocial, setPartsSocial, idPartSocail, partSocial) => {
    let partsSocialData = partsSocial;
    partsSocialData[idPartSocail - 1] = partSocial;
    setPartsSocial(partsSocialData);
  };

  return (
    <div className='mb-10'>
      <p className='text-base font-medium text-neutral mb-2'>Ajouter / Supprimer une part social :</p>
      <CustomTextInput
        image={number}
        type='number'
        min={0}
        value={nombrePartsSocial}
        setValue={setNombrePartsSocial}
      />
      <p className='text-base font-medium text-neutral mt-5 mb-2'>Parts sociales :</p>
      {range(nombrePartsSocial).map((id) => {
        return (
          <PartSocial
            key={id}
            id={id}
            soussignes={soussignes}
            setPartsSocial={(partSocial) => modifyPartsSocialData(partsSocial, setPartsSocial, id, partSocial)}
          />
        );
      })}
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        {/* <Button
          color='primary'
          variant='contained'
          onClick={() => handelEnregisterClick(pdfBlob)}
        >
          Enregistrer
        </Button> */}
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
          Continuer
        </Button>
      </div>
    </div>
  );
}

