import React, { useState } from 'react';
import { Button} from '@mui/material';
import Swal from 'sweetalert2';
import axios from 'axios';
import StepProgress from '../../../../../components/stepProgress/stepProgress';
import CustomRadioBox from '../../../../../components/inputs/customRadioBox/customRadioBox';
import CustomTextInput from '../../../../../components/inputs/customTextInput/customTextInput';
import CustomSelect from '../../../../../components/inputs/customSelect';
import usericon from '../../../../../assets/icons/usericon.png';
import bday from '../../../../../assets/icons/bday.png';
import location from '../../../../../assets/icons/location.png';
import map from '../../../../../assets/icons/map.png';
import num from '../../../../../assets/icons/number.png';
import building from '../../../../../assets/icons/building.png';
import document from '../../../../../assets/icons/doc.png';
import duration from '../../../../../assets/icons/duration.png';
import calendar from '../../../../../assets/icons/calendar.png';
import montant from '../../../../../assets/icons/money.png';
import getEnvironement from '../../../../../environnement';

function ContratPrestationForm() {
  const [currentStep, setCurrentStep] = useState(1);
  const [prestataireGenre, setPrestataireGenre] = useState('Personne Physique');
  const [clientGenre, setClientGenre] = useState('Particulier');
  const [typeClientEntreprise, setTypeClientEntreprise] = useState('Entreprise Individuelle');
  const [registreOption, setRegistreOption] = useState('registreCommerce');

  // State for Personne Physique
  const [nom, setNom] = useState('');
  const [dateNaissance, setDateNaissance] = useState('');
  const [lieuNaissance, setLieuNaissance] = useState('');
  const [adressePhysique, setAdressePhysique] = useState('');
  const [nationalite, setNationalite] = useState('');
  const [seraInscrit, setSeraInscrit] = useState('');

  // State for Societe
  const [denomination, setDenomination] = useState('');
  const [forme, setForme] = useState('');
  const [adresseSociete, setAdresseSociete] = useState('');
  const [numeroRCS, setNumeroRCS] = useState('');
  const [villeRCS, setVilleRCS] = useState('');
  const [nomSignataireSociete, setNomSignataireSociete] = useState('');
  const [qualiteSignataireSociete, setQualiteSignataireSociete] = useState('');

  // State for Association
  const [nomAssociation, setNomAssociation] = useState('');
  const [adresseAssociation, setAdresseAssociation] = useState('');
  const [villePrefecture, setVillePrefecture] = useState('');
  const [numeroEnregistrement, setNumeroEnregistrement] = useState('');
  const [nomSignataireAssociation, setNomSignataireAssociation] = useState('');
  const [qualiteSignataireAssociation, setQualiteSignataireAssociation] = useState('');
  const [numeroSIREN, setNumeroSIREN] = useState('');
  //state for step 2
  const [nomPersonePhysique, setNomPersonePhysique] = useState('');
  const [dateNaissancePersonePhysique, setDateNaissancePersonePhysique] = useState('');
  const [lieuNaissancePersonePhysique, setLieuNaissancePersonePhysique] = useState('');
  const [adressePhysiquePersonePhysique, setAdressePhysiquePersonePhysique] = useState('');
  const [nationalitePersonePhysique, setNationalitePersonePhysique] = useState('');
  // entreprise individuelle
  const [nomEntrepriseIndividuelle, setNomEntrepriseIndividuelle] = useState('');
  const [dateNaissanceEntrepriseIndividuelle, setDateNaissanceEntrepriseIndividuelle] = useState('');
  const [lieuNaissanceEntrepriseIndividuelle, setLieuNaissanceEntrepriseIndividuelle] = useState('');
  const [adressePhysiqueEntrepriseIndividuelle, setAdressePhysiqueEntrepriseIndividuelle] = useState('');
  const [nationaliteEntrepriseIndividuelle, setNationaliteEntrepriseIndividuelle] = useState('');

  // State for Setp 3
  const [objetDePrestation, setObjetDePrestation] = useState('');
  const [autrePrestation, setAutrePrestation] = useState('');
  const [prestationType, setPrestationType] = useState('');
  const [serviceDescription, setServiceDescription] = useState('fabrication d\'un bien');
  const [isTraitance, setIsTraitance] = useState('');

  // State for Step 4
  const [contractType, setContractType] = useState('Le contrat est à durée déterminée (cas le plus fréquent)');
  const [contractDuration, setContractDuration] = useState('');
  const [contractStartDate, setContractStartDate] = useState('');
  const [preavis, setPreavis] = useState('');
  const [isOuiSelected, setIsOuiSelected] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [dateLimiteRealisation, setDateLimiteRealisation] = useState('');
  const [dateFixe, setDateFixe] = useState('');
  const [calendrierDetails, setCalendrierDetails] = useState('');
  const [isDue, setIsDue] = useState('');
  const [montant, setMontant] = useState('');
  // state for step 5
  const [hasDevice, setDevice] = useState('');
  const [hasSignature, setSignature] = useState('');
  // state for step 6
  const [remunerationType, setRemunerationType] = useState('');
  const [montantForfaitaire, setMontantForfaitaire] = useState('');
  const [tauxHoraire, setTauxHoraire] = useState('');
  const [tauxHoraireType, setTauxHoraireType] = useState('');
  const [tauxHoraireValue, setTauxHoraireValue] = useState('');
  const [variableRemuneration, setVariableRemuneration] = useState('');
  const [autreRemuneration, setAutreRemuneration] = useState('');
  const [intervientTime, setIntervientTime] = useState('');
  const [prestatationMoyen, setPrestationMoyen] = useState('');
  // state for step 7
  const [hasInsurance, setHasInsurance] = useState('');
  const [insuranceStatus, setInsuranceStatus] = useState('');
  const [insuranceDetails, setInsuranceDetails] = useState('');
  // state for step 8
  const [isSubcontractingAllowed, setIsSubContractingAllowed] = useState('');
  // state for step 9
  const [confidentialite, setConfidentialite] = useState('');
  const [valableDuration,setValableDuration] = useState('');
  // state for step 10
  const [villeName, setVilleName] = useState('');
  const [signatureName, setSignatureName] = useState('');
  // state for step 11
  const [PdfEmail, setPdfEmail] = useState('');

  const getQuestion = (currentStep) => {
    switch (currentStep) {
    case 1:
      return (
        <Q1
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          prestataireGenre={prestataireGenre}
          setPrestataireGenre={setPrestataireGenre}
          registreOption={registreOption}
          setRegistreOption={setRegistreOption}
          // Pass all state props for Personne Physique
          nom={nom}
          setNom={setNom}
          dateNaissance={dateNaissance}
          setDateNaissance={setDateNaissance}
          lieuNaissance={lieuNaissance}
          setLieuNaissance={setLieuNaissance}
          adressePhysique={adressePhysique}
          setAdressePhysique={setAdressePhysique}
          nationalite={nationalite}
          setNationalite={setNationalite}

          // Pass all state props for Societe
          denomination={denomination}
          setDenomination={setDenomination}
          forme={forme}
          setForme={setForme}
          adresseSociete={adresseSociete}
          setAdresseSociete={setAdresseSociete}
          numeroRCS={numeroRCS}
          setNumeroRCS={setNumeroRCS}
          villeRCS={villeRCS}
          setVilleRCS={setVilleRCS}
          nomSignataireSociete={nomSignataireSociete}
          setNomSignataireSociete={setNomSignataireSociete}
          qualiteSignataireSociete={qualiteSignataireSociete}
          setQualiteSignataireSociete={setQualiteSignataireSociete}
          // Pass all state props for Association
          nomAssociation={nomAssociation}
          setNomAssociation={setNomAssociation}
          adresseAssociation={adresseAssociation}
          setAdresseAssociation={setAdresseAssociation}
          villePrefecture={villePrefecture}
          setVillePrefecture={setVillePrefecture}
          numeroEnregistrement={numeroEnregistrement}
          setNumeroEnregistrement={setNumeroEnregistrement}
          nomSignataireAssociation={nomSignataireAssociation}
          setNomSignataireAssociation={setNomSignataireAssociation}
          qualiteSignataireAssociation={qualiteSignataireAssociation}
          setQualiteSignataireAssociation={setQualiteSignataireAssociation}
          numeroSIREN={numeroSIREN}
          setNumeroSIREN={setNumeroSIREN}
          seraInscrit={seraInscrit}
          setSeraInscrit={setSeraInscrit}
        />
      );
    case 2:
      return (
        <Q2
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          typeClientEntreprise={typeClientEntreprise}
          setTypeClientEntreprise={setTypeClientEntreprise}
          clientGenre={clientGenre}
          setClientGenre={setClientGenre}
          registreOption={registreOption}
          setRegistreOption={setRegistreOption}
          // Pass all state props for Personne Physique
          nom={nomPersonePhysique}
          setNom={setNomPersonePhysique}
          dateNaissance={dateNaissancePersonePhysique}
          setDateNaissance={setDateNaissancePersonePhysique}
          lieuNaissance={lieuNaissancePersonePhysique}
          setLieuNaissance={setLieuNaissancePersonePhysique}
          adressePhysique={adressePhysiquePersonePhysique}
          setAdressePhysique={setAdressePhysiquePersonePhysique}
          nationalite={nationalitePersonePhysique}
          setNationalite={setNationalitePersonePhysique}
          // entreprise individuelle
          nomEntrepriseIndividuelle={nomEntrepriseIndividuelle}
          setNomEntrepriseIndividuelle={setNomEntrepriseIndividuelle}
          dateNaissanceEntrepriseIndividuelle={dateNaissanceEntrepriseIndividuelle}
          setDateNaissanceEntrepriseIndividuelle={setDateNaissanceEntrepriseIndividuelle}
          lieuNaissanceEntrepriseIndividuelle={lieuNaissanceEntrepriseIndividuelle}
          setLieuNaissanceEntrepriseIndividuelle={setLieuNaissanceEntrepriseIndividuelle}
          adressePhysiqueEntrepriseIndividuelle={adressePhysiqueEntrepriseIndividuelle}
          setAdressePhysiqueEntrepriseIndividuelle={setAdressePhysiqueEntrepriseIndividuelle}
          nationaliteEntrepriseIndividuelle={nationaliteEntrepriseIndividuelle}
          setNationaliteEntrepriseIndividuelle={setNationaliteEntrepriseIndividuelle}
          // Pass all state props for Societe
          denomination={denomination}
          setDenomination={setDenomination}
          forme={forme}
          setForme={setForme}
          adresseSociete={adresseSociete}
          setAdresseSociete={setAdresseSociete}
          numeroRCS={numeroRCS}
          setNumeroRCS={setNumeroRCS}
          villeRCS={villeRCS}
          setVilleRCS={setVilleRCS}
          nomSignataireSociete={nomSignataireSociete}
          setNomSignataireSociete={setNomSignataireSociete}
          qualiteSignataireSociete={qualiteSignataireSociete}
          setQualiteSignataireSociete={setQualiteSignataireSociete}
          // Pass all state props for Association
          nomAssociation={nomAssociation}
          setNomAssociation={setNomAssociation}
          adresseAssociation={adresseAssociation}
          setAdresseAssociation={setAdresseAssociation}
          villePrefecture={villePrefecture}
          setVillePrefecture={setVillePrefecture}
          numeroEnregistrement={numeroEnregistrement}
          setNumeroEnregistrement={setNumeroEnregistrement}
          nomSignataireAssociation={nomSignataireAssociation}
          setNomSignataireAssociation={setNomSignataireAssociation}
          qualiteSignataireAssociation={qualiteSignataireAssociation}
          setQualiteSignataireAssociation={setQualiteSignataireAssociation}
          numeroSIREN={numeroSIREN}
          setNumeroSIREN={setNumeroSIREN}
        />
      );
    case 3:
      return (
        <Q3
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          objetDePrestation={objetDePrestation}
          setObjetDePrestation={setObjetDePrestation}
          autrePrestation={autrePrestation}
          setAutrePrestation={setAutrePrestation}
          prestationType={prestationType}
          setPrestationType={setPrestationType}
          serviceDescription={serviceDescription}
          setServiceDescription={setServiceDescription}
          isTraitance={isTraitance}
          setIsTraitance={setIsTraitance}
        />
      );
    case 4:
      return (
        <Q4
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          contractType={contractType}
          setContractType={setContractType}
          contractDuration={contractDuration}
          setContractDuration={setContractDuration}
          contractStartDate={contractStartDate}
          setContractStartDate={setContractStartDate}
          preavis={preavis}
          setPreavis={setPreavis}
          isOuiSelected={isOuiSelected}
          setIsOuiSelected={setIsOuiSelected}
          additionalInfo={additionalInfo}
          setAdditionalInfo={setAdditionalInfo}
          dateLimiteRealisation={dateLimiteRealisation}
          setDateLimiteRealisation={setDateLimiteRealisation}
          dateFixe={dateFixe}
          setDateFixe={setDateFixe}
          calendrierDetails={calendrierDetails}
          setCalendrierDetails={setCalendrierDetails}
          isDue={isDue}
          setIsDue={setIsDue}
          montant={montant}
          setMontant={setMontant}

        />
      );
    case 5:
      return (
        <Q5
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          hasDevice={hasDevice}
          setDevice={setDevice}
          hasSignature={hasSignature}
          setSignature={setSignature}

        />
      );
    case 6:
      return (
        <Q6
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          remunerationType={remunerationType}
          setRemunerationType={setRemunerationType}
          montantForfaitaire={montantForfaitaire}
          setMontantForfaitaire={setMontantForfaitaire}
          tauxHoraire={tauxHoraire}
          setTauxHoraire={setTauxHoraire}
          tauxHoraireType={tauxHoraireType}
          setTauxHoraireType={setTauxHoraireType}
          tauxHoraireValue={tauxHoraireValue}
          setTauxHoraireValue={setTauxHoraireValue}
          variableRemuneration={variableRemuneration}
          setVariableRemuneration={setVariableRemuneration}
          autreRemuneration={autreRemuneration}
          setAutreRemuneration={setAutreRemuneration}
          intervientTime={intervientTime}
          setIntervientTime={setIntervientTime}
          prestatationMoyen={prestatationMoyen}
          setPrestationMoyen={setPrestationMoyen}
            
        />
      );
    case 7:
      return (
        <Q7
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          hasInsurance={hasInsurance}
          setHasInsurance={setHasInsurance}
          insuranceStatus={insuranceStatus}
          setInsuranceStatus={setInsuranceStatus}
          insuranceDetails={insuranceDetails}
          setInsuranceDetails={setInsuranceDetails}
        />
      );
    case 8:
      return (
        <Q8
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          isSubcontractingAllowed={isSubcontractingAllowed}
          setIsSubContractingAllowed={setIsSubContractingAllowed}
        />
      );
    case 9:
      return (
        <Q9
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          valableDuration={valableDuration}
          confidentialite={confidentialite}
          setConfidentialite={setConfidentialite}
          setValableDuration={setValableDuration}
          
        />
      );
    case 10:
      return (
        <Q10
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          villeName={villeName}
          setVilleName={setVilleName}
          signatureName={signatureName}
          setSignatureName={setSignatureName}
        />
      );
    case 11:
      return (
        <Q11
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          PdfEmail={PdfEmail}
          setPdfEmail={setPdfEmail}
          handleClick={handleEnregisterClick}
        />
      );
    default:
      return <Q1
        setCurrentStep={setCurrentStep}
        currentStep={currentStep}
        prestataireGenre={prestataireGenre}
        setPrestataireGenre={setPrestataireGenre}
        registreOption={registreOption}
        setRegistreOption={setRegistreOption}
        // Pass all state props for Personne Physique
        nom={nom}
        setNom={setNom}
        dateNaissance={dateNaissance}
        setDateNaissance={setDateNaissance}
        lieuNaissance={lieuNaissance}
        setLieuNaissance={setLieuNaissance}
        adressePhysique={adressePhysique}
        setAdressePhysique={setAdressePhysique}
        nationalite={nationalite}
        setNationalite={setNationalite}
        // Pass all state props for Societe
        denomination={denomination}
        setDenomination={setDenomination}
        forme={forme}
        setForme={setForme}
        adresseSociete={adresseSociete}
        setAdresseSociete={setAdresseSociete}
        numeroRCS={numeroRCS}
        setNumeroRCS={setNumeroRCS}
        villeRCS={villeRCS}
        setVilleRCS={setVilleRCS}
        nomSignataireSociete={nomSignataireSociete}
        setNomSignataireSociete={setNomSignataireSociete}
        qualiteSignataireSociete={qualiteSignataireSociete}
        setQualiteSignataireSociete={setQualiteSignataireSociete}
        // Pass all state props for Association
        nomAssociation={nomAssociation}
        setNomAssociation={setNomAssociation}
        adresseAssociation={adresseAssociation}
        setAdresseAssociation={setAdresseAssociation}
        villePrefecture={villePrefecture}
        setVillePrefecture={setVillePrefecture}
        numeroEnregistrement={numeroEnregistrement}
        setNumeroEnregistrement={setNumeroEnregistrement}
        nomSignataireAssociation={nomSignataireAssociation}
        setNomSignataireAssociation={setNomSignataireAssociation}
        qualiteSignataireAssociation={qualiteSignataireAssociation}
        setQualiteSignataireAssociation={setQualiteSignataireAssociation}
        numeroSIREN={numeroSIREN}
        setNumeroSIREN={setNumeroSIREN}
      />;
    }
  };

  const handleEnregisterClick = () => {
    console.log('testss');
    const url = `${getEnvironement().API_URL}/prestation`;
    const data = {
      prestataireGenre,
      clientGenre,
      typeClientEntreprise,
      registreOption,
  
      // Personne Physique
      nom,
      dateNaissance,
      lieuNaissance,
      adressePhysique,
      nationalite,
      seraInscrit,
  
      // Societe
      denomination,
      forme,
      adresseSociete,
      numeroRCS,
      villeRCS,
      nomSignataireSociete,
      qualiteSignataireSociete,
  
      // Association
      nomAssociation,
      adresseAssociation,
      villePrefecture,
      numeroEnregistrement,
      nomSignataireAssociation,
      qualiteSignataireAssociation,
      numeroSIREN,
      // Step 2
      nomPersonePhysique,
      dateNaissancePersonePhysique,
      lieuNaissancePersonePhysique,
      adressePhysiquePersonePhysique,
      nationalitePersonePhysique,
      // entreprise individuelle
      nomEntrepriseIndividuelle,
      dateNaissanceEntrepriseIndividuelle,
      lieuNaissanceEntrepriseIndividuelle,
      adressePhysiqueEntrepriseIndividuelle,
      nationaliteEntrepriseIndividuelle,
      // Step 3
      objetDePrestation,
      autrePrestation,
      prestationType,
      serviceDescription,
      isTraitance,
  
      // Step 4
      contractType,
      contractDuration,
      contractStartDate,
      preavis,
      isOuiSelected,
      additionalInfo,
      dateLimiteRealisation,
      dateFixe,
      calendrierDetails,
      isDue,
      montant,
  
      // Step 5
      hasDevice,
      hasSignature,
  
      // Step 6
      remunerationType,
      montantForfaitaire,
      tauxHoraire,
      tauxHoraireType,
      tauxHoraireValue,
      variableRemuneration,
      autreRemuneration,
      intervientTime,
      prestatationMoyen,
  
      // Step 7
      hasInsurance,
      insuranceStatus,
      insuranceDetails,
  
      // Step 8
      isSubcontractingAllowed,
  
      // Step 9
      confidentialite,
      valableDuration,
  
      // Step 10
      villeName,
      signatureName,
  
      // Step 11
      PdfEmail,
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    axios.post(url, data, config)
      .then((response) => {
        if (response.status === 201) {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'prestation créée avec succès !',
            confirmButtonText: 'OK'
          });
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'Une erreur est survenue lors de la création de la prestation.',
          confirmButtonText: 'OK'
        });
      });
  };

  return (
    <div className=' flex flex-col justify-start items-center px-10 mt-10 bg-white'>
      <h3 className=''>CONTRAT DE PRESTATION</h3>
      <StepProgress numberSteps={11} currentStep={currentStep} />
      <div className='w-fit md:w-[700px]'>
        {getQuestion(currentStep)}
      </div>
    </div>
  );
}

function Q1(props) {
  const {
    currentStep,
    setCurrentStep,
    prestataireGenre,
    setPrestataireGenre,
    registreOption,
    setRegistreOption,
    // Props for Personne Physique
    nom,
    setNom,
    dateNaissance,
    setDateNaissance,
    lieuNaissance,
    setLieuNaissance,
    adressePhysique,
    setAdressePhysique,
    nationalite,
    setNationalite,
    // Props for Societe
    denomination,
    setDenomination,
    forme,
    setForme,
    adresseSociete,
    setAdresseSociete,
    numeroRCS,
    setNumeroRCS,
    villeRCS,
    setVilleRCS,
    nomSignataireSociete,
    setNomSignataireSociete,
    qualiteSignataireSociete,
    setQualiteSignataireSociete,
    // Props for Association
    nomAssociation,
    setNomAssociation,
    adresseAssociation,
    setAdresseAssociation,
    villePrefecture,
    setVillePrefecture,
    numeroEnregistrement,
    setNumeroEnregistrement,
    nomSignataireAssociation,
    setNomSignataireAssociation,
    qualiteSignataireAssociation,
    setQualiteSignataireAssociation,
    numeroSIREN,
    setNumeroSIREN,
    seraInscrit,
    setSeraInscrit,
  } = props;

  const renderAdditionalInputs = () => {
    switch (prestataireGenre) {
    case 'Personne Physique':
      return (
        <div className='mt-4'>
          <CustomTextInput image={usericon} type='text' value={nom} setValue={setNom} placeholder='Prénoms et nom' />
          <CustomTextInput image={bday} type='date' value={dateNaissance} setValue={setDateNaissance} placeholder='Date de naissance' />
          <CustomTextInput image={location} type='text' value={lieuNaissance} setValue={setLieuNaissance} placeholder='Lieu de naissance' />
          <CustomTextInput image={location} type='text' value={adressePhysique} setValue={setAdressePhysique} placeholder='Adresse' />
          <CustomTextInput image={map} type='text' value={nationalite} setValue={setNationalite} placeholder='Nationalité' />
        </div>
      );
    case 'Societe':
      return (
        <div className='mt-4'>
          <CustomTextInput image={building} type='text' value={denomination} setValue={setDenomination} placeholder='Dénomination de la société' />
          <CustomTextInput image={building} type='text' value={forme} setValue={setForme} placeholder='Forme de la société' />
          <CustomTextInput image={location} type='text' value={adresseSociete} setValue={setAdresseSociete} placeholder='Adresse du siège social' />
          <CustomTextInput image={num} type='text' value={numeroRCS} setValue={setNumeroRCS} placeholder='Numéro RCS' />
          <CustomTextInput image={map} type='text' value={villeRCS} setValue={setVilleRCS} placeholder='Ville du RCS' />
          <CustomTextInput image={usericon} type='text' value={nomSignataireSociete} setValue={setNomSignataireSociete} placeholder='Prénoms et nom de la personne qui signe pour la société' />
          <CustomTextInput image={usericon} type='text' value={qualiteSignataireSociete} setValue={setQualiteSignataireSociete} placeholder='Qualité de la personne qui signe pour la société' />
        </div>
      );
    case 'Association':
      return (
        <div className='mt-4'>
          <CustomTextInput image={building} type='text' value={nomAssociation} setValue={setNomAssociation} placeholder="Nom de l'association" />
          <CustomTextInput image={location} type='text' value={adresseAssociation} setValue={setAdresseAssociation} placeholder='Adresse du siège social' />
          <CustomTextInput image={map} type='text' value={villePrefecture} setValue={setVillePrefecture} placeholder="Ville de la préfecture où l'association a été enregistrée" />
          <CustomTextInput image={num} type='text' value={numeroEnregistrement} setValue={setNumeroEnregistrement} placeholder="Numéro d'enregistrement de l'association" />
          <CustomTextInput image={usericon} type='text' value={nomSignataireAssociation} setValue={setNomSignataireAssociation} placeholder='Prénoms et nom du signataire' />
          <CustomTextInput image={usericon} type='text' value={qualiteSignataireAssociation} setValue={setQualiteSignataireAssociation} placeholder='Qualité du signataire' />
          <CustomTextInput image={num} type='text' value={numeroSIREN} setValue={setNumeroSIREN} placeholder="Numéro SIREN de l'association" />
        </div>
      );
    default:
      return null;
    }
  };

  const renderRegistreInputs = () => {
    switch (registreOption) {
    case 'registreCommerce':
    case 'repertoireMetiers':
    case 'nonInscrit':
      return (
        <CustomTextInput
          image={num}
          type='text'
          value={numeroSIREN}
          setValue={setNumeroSIREN}
          placeholder='Précisez le numéro Siren'
        />
      );
    case 'nonInscritNumeroSiren':
      return (
        <div className='mt-4'>
          <CustomRadioBox
            isBlue={true}
            row={false}
            value={seraInscrit} 
            setValue={setSeraInscrit}
            items={[
              { label: 'Il sera inscrit au registre du commerce et des sociétés', value: ' Sera inscrit registre' },
              { label: 'Il sera inscrit au répertoire des métiers', value: ' Sera inscrit repertoire' },
            ]}
          />
        </div>
      );
    default:
      return null;
    }
  };

  return (
    <div className='mb-10'>
      <h3 className='text-xl font-bold'>01.Le prestataire de services</h3>
      <h3 className='text-lg'>Le prestataire est-il une personne physique ou une société ?</h3>
      <div className='my-2'>
        <CustomRadioBox
          isBlue={true}
          row={false}
          value={prestataireGenre}
          setValue={setPrestataireGenre}
          items={[
            { label: 'Le prestataire est une personne physique', value: 'Personne Physique' },
            { label: 'Le prestataire est une société', value: 'Societe' },
            { label: 'Le prestataire est une association', value: 'Association' },
          ]}
        />
      </div>
      {renderAdditionalInputs()}
      <div className='mt-4'>
        <h4 className='text-base font-medium'>Sélectionnez ltion de registre</h4>
        <CustomRadioBox
          isBlue={true}
          value={registreOption}
          setValue={setRegistreOption}
          items={[
            { label: 'Il est inscrit au registre du commerce et des sociétés', value: 'registreCommerce' },
            { label: 'Il est inscrit au répertoire des métiers', value: 'repertoireMetiers' },
            { label: 'Il n\'est pas encore inscrit ou n\'a pas encore de numéro Siren', value: 'nonInscritNumeroSiren' },
            { label: 'Il n\'est pas inscrit dans l\'un de ces registres mais dispose d\'un numéro Siren', value: 'nonInscrit' },
          ]}
        />
        {renderRegistreInputs()}
      </div>
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            if (currentStep > 1) {
              setCurrentStep(currentStep - 1);
            }
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
          Continuer
        </Button>
      </div>
    </div>
  );
}
function Q2(props) {
  const {
    currentStep,
    setCurrentStep,
    typeClientEntreprise,
    setTypeClientEntreprise,
    clientGenre,
    setClientGenre,
    nom,
    setNom,
    dateNaissance,
    setDateNaissance,
    lieuNaissance,
    setLieuNaissance,
    adressePhysique,
    setAdressePhysique,
    nationalite,
    setNationalite,
    denomination,
    setDenomination,
    forme,
    setForme,
    adresseSociete,
    setAdresseSociete,
    numeroRCS,
    setNumeroRCS,
    villeRCS,
    setVilleRCS,
    nomSignataireSociete,
    setNomSignataireSociete,
    qualiteSignataireSociete,
    setQualiteSignataireSociete,
    nomAssociation,
    setNomAssociation,
    adresseAssociation,
    setAdresseAssociation,
    villePrefecture,
    setVillePrefecture,
    numeroEnregistrement,
    setNumeroEnregistrement,
    nomSignataireAssociation,
    setNomSignataireAssociation,
    qualiteSignataireAssociation,
    setQualiteSignataireAssociation,
    numeroSIREN,
    setNumeroSIREN,
    nomEntrepriseIndividuelle,
    setNomEntrepriseIndividuelle,
    dateNaissanceEntrepriseIndividuelle,
    setDateNaissanceEntrepriseIndividuelle,
    lieuNaissanceEntrepriseIndividuelle,
    setLieuNaissanceEntrepriseIndividuelle,
    adressePhysiqueEntrepriseIndividuelle,
    setAdressePhysiqueEntrepriseIndividuelle,
    nationaliteEntrepriseIndividuelle,
    setNationaliteEntrepriseIndividuelle,

  } = props;

  const renderTypeClientEntrepriseInputs = (typeClientEntreprise) => {
    switch (typeClientEntreprise) {
    case 'Entreprise Individuelle':
      return (
        <div className='mt-4'>
          <CustomTextInput image={usericon} type='text' value={nomEntrepriseIndividuelle} setValue={setNomEntrepriseIndividuelle} placeholder='Prénoms et nom' />
          <CustomTextInput image={bday} type='date' value={dateNaissanceEntrepriseIndividuelle} setValue={setDateNaissanceEntrepriseIndividuelle} placeholder='Date de naissance' />
          <CustomTextInput image={location} type='text' value={lieuNaissanceEntrepriseIndividuelle} setValue={setLieuNaissanceEntrepriseIndividuelle} placeholder='Lieu de naissance' />
          <CustomTextInput image={location} type='text' value={adressePhysiqueEntrepriseIndividuelle} setValue={setAdressePhysiqueEntrepriseIndividuelle} placeholder='Adresse' />
          <CustomTextInput image={map} type='text' value={nationaliteEntrepriseIndividuelle} setValue={setNationaliteEntrepriseIndividuelle} placeholder='Nationalité' />
        </div>
      );
    case 'Societe':
      return (
        <div className='mt-4'>
          <CustomTextInput image={building} type='text' value={denomination} setValue={setDenomination} placeholder='Dénomination de la société' />
          <CustomTextInput image={building} type='text' value={forme} setValue={setForme} placeholder='Forme de la société' />
          <CustomTextInput image={location} type='text' value={adresseSociete} setValue={setAdresseSociete} placeholder='Adresse du siège social' />
          <CustomTextInput image={num} type='text' value={numeroRCS} setValue={setNumeroRCS} placeholder='Numéro RCS' />
          <CustomTextInput image={map} type='text' value={villeRCS} setValue={setVilleRCS} placeholder='Ville du RCS' />
          <CustomTextInput image={usericon} type='text' value={nomSignataireSociete} setValue={setNomSignataireSociete} placeholder='Prénoms et nom de la personne qui signe pour la société' />
          <CustomTextInput image={usericon} type='text' value={qualiteSignataireSociete} setValue={setQualiteSignataireSociete} placeholder='Qualité de la personne qui signe pour la société' />
        </div>
      );
    case 'Association':
      return (
        <div className='mt-4'>
          <CustomTextInput image={building} type='text' value={nomAssociation} setValue={setNomAssociation} placeholder="Nom de l'association" />
          <CustomTextInput image={location} type='text' value={adresseAssociation} setValue={setAdresseAssociation} placeholder='Adresse du siège social' />
          <CustomTextInput image={map} type='text' value={villePrefecture} setValue={setVillePrefecture} placeholder="Ville de la préfecture où l'association a été enregistrée" />
          <CustomTextInput image={num} type='text' value={numeroEnregistrement} setValue={setNumeroEnregistrement} placeholder="Numéro d'enregistrement de l'association" />
          <CustomTextInput image={usericon} type='text' value={nomSignataireAssociation} setValue={setNomSignataireAssociation} placeholder='Prénoms et nom du signataire' />
          <CustomTextInput image={usericon} type='text' value={qualiteSignataireAssociation} setValue={setQualiteSignataireAssociation} placeholder='Qualité du signataire' />
          <CustomTextInput image={num} type='text' value={numeroSIREN} setValue={setNumeroSIREN} placeholder="Numéro SIREN de l'association" />
        </div>
      );
    
    default:
      break;
    }
  };

  const renderAdditionalInputs = () => {
    switch (clientGenre) {
    case 'Particulier':
      return (
        <div className='mt-4'>
          <CustomTextInput image={usericon} type='text' value={nom} setValue={setNom} placeholder='Prénoms et nom' />
          <CustomTextInput image={bday} type='date' value={dateNaissance} setValue={setDateNaissance} placeholder='Date de naissance' />
          <CustomTextInput image={location} type='text' value={lieuNaissance} setValue={setLieuNaissance} placeholder='Lieu de naissance' />
          <CustomTextInput image={location} type='text' value={adressePhysique} setValue={setAdressePhysique} placeholder='Adresse' />
          <CustomTextInput image={map} type='text' value={nationalite} setValue={setNationalite} placeholder='Nationalité' />
        </div>
      );
    case 'Entreprise ou Association':
      return (
        <div className='mt-4'>
          <CustomRadioBox
            row={false}
            isBlue={true}
            value={typeClientEntreprise}
            setValue={setTypeClientEntreprise}
            items={[
              { label: 'Il s\'agit d\'une entreprise individuelle', value: 'Entreprise Individuelle' },
              { label: 'Le client est une société', value: 'Societe' },
              { label: 'Le client est une association', value: 'Association' },
            ]}
          />
          {renderTypeClientEntrepriseInputs(typeClientEntreprise)}
        </div>
 
      );

    default:
      return null;
    }
  };
  
  return (
    <div className='mb-10'>
      <h3 className='text-xl font-bold'>Le client</h3>
      <h3 className='text-lg'>Le client est-il un particulier, une entreprise ou une association ?</h3>
      <div className='my-2'>
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={clientGenre}
          setValue={setClientGenre}
          items={[
            { label: 'Le client est un particulier', value: 'Particulier' },
            { label: 'Le client est une entreprise ou une association', value: 'Entreprise ou Association' },
          ]}
        />
      </div>
      {renderAdditionalInputs()}
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            if (currentStep > 1) {
              setCurrentStep(currentStep - 1);
            }
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
            Continuer
        </Button>
      </div>
    </div>
  );
}
function Q3(props) {
  const {
    currentStep,
    setCurrentStep,
    serviceDescription,
    setServiceDescription,
    objetDePrestation,
    setObjetDePrestation,
    autrePrestation,
    setAutrePrestation,
    prestationType,
    setPrestationType,
    isTraitance,
    setIsTraitance,
  } = props;


  const renderAdditionalFields = () => {
    switch (objetDePrestation) {
    case 'Fabrication, réparation ou entretien d\'un bien':
      return (
        <CustomSelect
          value={serviceDescription}
          setValue={setServiceDescription}
          items={[
            { label: 'fabrication d\'un bien', value: 'fabrication d\'un bien' },
            { label: 'entretien d\'un bien', value: 'entretien d\'un bien' },
            { label: 'reparation d\'un bien', value: 'reparation d\'un bien' },
          ]}
        />
      );
    case 'Prestation intellectuelle':
      return (
        <>
          <CustomSelect
            value={serviceDescription || 'enseignement'}
            setValue={setServiceDescription}
            items={[
              { label: 'enseignement', value: 'enseignement' },
              { label: 'conseil', value: 'conseil' },
              { label: 'formation', value: 'formation' },
              { label: 'coaching', value: 'coaching' },
              { label: 'formation', value: 'formation' },
              { label: 'rédaction de contenus', value: 'redaction de contenus' },
              { label: 'design', value: 'design' },
              { label: 'sécurité informatique', value: 'sécurité informatique' },
              { label: 'audit technique', value: 'audit echnique' },
            ]}
          />
          <div className='mt-4'>
            <CustomRadioBox
              row={false}
              isBlue={true}
              value={prestationType}
              setValue={setPrestationType}
              items={[
                { label: 'Il s\'agit d\'une prestation ponctuelle', value: 'ponctuelle' },
                { label: 'Il s\'agit d\'une prestation récurrente', value: 'recurrente' },
              ]}
            />
          </div>
        </>
      );
    case 'Apport d\'affaire':
      return (
        <div className='mt-4'>
          <CustomRadioBox
            row={false}
            isBlue={true}
            value={prestationType}
            setValue={setPrestationType}
            items={[
              { label: 'Il s\'agit d\'une prestation ponctuelle', value: 'ponctuelle' },
              { label: 'Il s\'agit d\'une prestation récurrente', value: 'recurrente' },
            ]}
          />
        </div>
      );
    case 'Autre':
      return (
        <>
          <CustomTextInput
            image={document}
            type='text'
            value={autrePrestation}
            setValue={setAutrePrestation}
            placeholder='Précisez'
          />
          <div className='mt-4'>
            <CustomRadioBox
              row={false}
              isBlue={true}
              value={prestationType}
              setValue={setPrestationType}
              items={[
                { label: 'Il s\'agit d\'une prestation ponctuelle', value: 'ponctuelle' },
                { label: 'Il s\'agit d\'une prestation récurrente', value: 'recurrente' },
              ]}
            />
          </div>
        </>
      );
    default:
      return null;
    }
  };

  return (
    <div className='mb-10'>
      <h3 className='text-xl font-bold'>03.Objet de la prestation de services</h3>
      <h3 className='text-lg'>Quelle est l&apos;objet de la ou des prestations de services ?</h3>
      <div>
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={objetDePrestation}
          setValue={setObjetDePrestation}
          items={[
            { label: 'Fabrication, réparation ou entretien d\'un bien', value: 'Fabrication, réparation ou entretien d\'un bien' },
            { label: 'Prestation intellectuelle', value: 'Prestation intellectuelle' },
            { label: 'Apport d\'affaire', value: 'Apport d\'affaire' },
            { label: 'Autre', value: 'Autre' },
          ]}
        />
      </div>
      {renderAdditionalFields()}
      <div>
        <p className='text-lg'>S&apos;agit-il d&apos;une sous-traitance ?</p>
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={isTraitance}
          setValue={setIsTraitance}
          items={[
            { label: 'Oui', value: 'Oui' },
            { label: 'Non', value: 'Non' },
          ]}
        />
        <p className='text-lg'>Description précise de la prestation de services</p>
        <CustomTextInput
          image={document}
          type='text'
          value={autrePrestation}
          setValue={setAutrePrestation}
          placeholder='Précisez'
        />
      </div>
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            if (currentStep > 1) {
              setCurrentStep(currentStep - 1);
            }
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
          Continuer
        </Button>
      </div>
    </div>
  );
}
function Q4(props) {
  const {
    currentStep,
    setCurrentStep,
    contractType,
    setContractType,
    contractDuration,
    setContractDuration,
    contractStartDate,
    setContractStartDate,
    preavis,
    setPreavis,
    isOuiSelected,
    setIsOuiSelected,
    additionalInfo,
    setAdditionalInfo,
    dateLimiteRealisation,
    setDateLimiteRealisation,
    dateFixe,
    setDateFixe,
    calendrierDetails,
    setCalendrierDetails,
    isDue,
    setIsDue,
    montant,
    setMontant,
    
  } = props;

  const renderAdditionalFields = () => {
    switch (contractType) {
    case 'Le contrat est à durée déterminée (cas le plus fréquent)':
      return (
        <div>
          <CustomTextInput
            image={duration}
            type='text'
            value={contractDuration}
            setValue={setContractDuration}
            placeholder='Quelle est la durée du contrat ?'
          />
          <CustomTextInput
            image={calendar}
            type='date'
            value={contractStartDate}
            setValue={setContractStartDate}
            placeholder='Quelle est la date de commencement du contrat ?'
          />
          <CustomTextInput
            image={duration}
            type='text'
            value={preavis}
            setValue={setPreavis}
            placeholder='Précisez le délai de préavis que chaque partie doit respecter si elle souhaite résilier le contrat suite à une faute de '
          />
        </div>
      );
    case 'Le contrat est à durée indéterminée':
      return (
        <div>
          <CustomTextInput
            image={duration}
            type='text'
            value={contractDuration}
            setValue={setContractDuration}
            placeholder='Quelle est la durée du contrat ?'
          />
          <CustomTextInput
            image={calendar}
            type='date'
            value={contractStartDate}
            setValue={setContractStartDate}
            placeholder='Quelle est la date de commencement du contrat ?'
          />
        </div>
      );
    default:
      return null;
    }
  };

  return (
    <div className='mb-10'>
      <h3 className='text-xl font-bold'>0.4 Durée du contrat - Calendrier de réalisation de la prestation de services</h3>
      <h4 className='text-lg'>Le contrat est-il à durée déterminée ou à durée indéterminée ?</h4>
      <div>
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={contractType}
          setValue={setContractType}
          items={[
            { label: 'Le contrat est à durée déterminée (cas le plus fréquent)', value: 'Le contrat est à durée déterminée (cas le plus fréquent)' },
            { label: 'Le contrat est à durée indéterminée', value: 'Le contrat est à durée indéterminée' },
          ]}
        />
      </div>
      {renderAdditionalFields()}
      <div>
        <p>S&apos;agit-il d&apos;un cas fréquent ?</p>
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={isOuiSelected}
          setValue={setIsOuiSelected}
          items={[
            { label: 'Oui (cas le plus fréquent)', value: 'yes' },
            { label: 'Non', value: 'non' },
          ]}
        />
        {isOuiSelected === 'yes' ? (
          <CustomTextInput
            image={document}
            type='text'
            value={additionalInfo}
            setValue={setAdditionalInfo}
            placeholder='Veuillez préciser'
          />
        ) : null}
      </div>
      <div>
        <p>Quelle est la date limite de réalisation de la prestation ?</p>
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={dateLimiteRealisation}
          setValue={setDateLimiteRealisation}
          items={[
            { label: 'Il s\'agit d\'une date fixe', value: 'date_fixe' },
            { label: 'Il existe un calendrier établi par les parties', value: 'calendrier_etabli' },
          ]}
        />
        {dateLimiteRealisation === 'date_fixe' && (
          <CustomTextInput
            image={calendar}
            type='date'
            value={dateFixe}
            setValue={setDateFixe}
            placeholder='Précisez la date limite de réalisation des prestations'
          />
        )}
        {dateLimiteRealisation === 'calendrier_etabli' && (
          <CustomTextInput
            image={calendar}
            type='text'
            value={calendrierDetails}
            setValue={setCalendrierDetails}
            placeholder='Précisez les détails du calendrier établi par les parties'
          />
        )}
      </div>
      <div>
        <p>Des pénalités de retard sont-elles dues en cas de dépassement du calendrier ?</p>
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={isDue}
          setValue={setIsDue}
          items={[
            { label: 'Oui', value: 'yes' },
            { label: 'Non', value: 'non' },
          ]}
        />
        {isDue === 'yes' ? (
          <CustomTextInput
            image={document}
            type='text'
            value={montant}
            setValue={setMontant}
            placeholder='Veuillez préciser'
          />
        ) : null}
      </div>
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            if (currentStep > 1) {
              setCurrentStep(currentStep - 1);
            }
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
          Continuer
        </Button>
      </div>
    </div>
  );
}
function Q5 (props){
  const {
    currentStep,
    setCurrentStep,
    hasDevice,
    setDevice,
    hasSignature,
    setSignature,
  } = props;

  return (
    <div className='mb-5'>
      <h1 className='text-xl font-bold'>Step 5</h1>
      <p className='text-lg'>La prestation a-t-elle donné lieu à l&apos;établissement d&apos;un devis ?</p>
      <CustomRadioBox
        row={false}
        isBlue={true}
        value={hasDevice}
        setValue={setDevice}
        items={[
          { label: 'Oui', value: 'yes' },
          { label: 'Non', value: 'non' },
        ]}
      />
      <p>La prestation a-t-elle donné lieu à la signature d&apos;un bon de commande par le client ?</p>
      <CustomRadioBox
        row={false}
        isBlue={true}
        value={hasSignature}
        setValue={setSignature}
        items={[
          { label: 'Oui', value: 'yes' },
          { label: 'Non', value: 'non' },
        ]}
      />
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            if (currentStep > 1) {
              setCurrentStep(currentStep - 1);
            }
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
          Continuer
        </Button>
      </div>
    </div>
  );
}
function Q6(props) {
  const {
    currentStep,
    setCurrentStep,
    remunerationType,
    setRemunerationType,
    montantForfaitaire,
    setMontantForfaitaire,
    tauxHoraire,
    setTauxHoraire,
    tauxHoraireType,
    setTauxHoraireType,
    tauxHoraireValue,
    setTauxHoraireValue,
    variableRemuneration,
    setVariableRemuneration,
    autreRemuneration,
    setAutreRemuneration,
    intervientTime,
    setIntervientTime,
    prestationMoyen,
    setPrestationMoyen,
  } = props;

  const renderTauxHoraireFields = () => {
    switch (tauxHoraireType) {
    case 'A l\'heure':
      return (
        <CustomTextInput
          image={duration}
          type='text'
          value={tauxHoraireValue}
          setValue={setTauxHoraireValue}
          placeholder='Précisez le taux horaire (hors taxes)'
        />
      );
    case 'Au jour':
      return (
        <CustomTextInput
          image={duration}
          type='text'
          value={tauxHoraireValue}
          setValue={setTauxHoraireValue}
          placeholder='Précisez le taux journalier (hors taxes)'
        />
      );
    case 'Au mois':
      return (
        <CustomTextInput
          image= {duration}
          type='text'
          value={tauxHoraireValue}
          setValue={setTauxHoraireValue}
          placeholder='Précisez le taux mensuel (hors taxes)'
        />
      );
    default:
      return null;
    }
  };

  const renderAdditionalFields = () => {
    switch (remunerationType) {
    case 'Rémunération fixe correspondant à un montant forfaitaire':
      return (
        <CustomTextInput
          image={montant}
          type='text'
          value={montantForfaitaire}
          setValue={setMontantForfaitaire}
          placeholder='Précisez le montant hors taxes'
        />
      );
    case 'Paiement sur la base d\'un taux horaire, journalier ou mensuel':
      return (
        <div>
          <CustomTextInput
            image={duration}
            type='text'
            value={tauxHoraire}
            setValue={setTauxHoraire}
            placeholder='Précisez le taux horaire (hors taxes)'
          />
          <CustomRadioBox
            row={false}
            isBlue={true}
            value={tauxHoraireType}
            setValue={setTauxHoraireType}
            items={[
              { label: 'A l\'heure', value: 'A l\'heure' },
              { label: 'Au jour', value: 'Au jour' },
              { label: 'Au mois', value: 'Au mois' },
            ]}
          />
          {renderTauxHoraireFields()}
        </div>
      );
    case 'Variable':
      return (
        <CustomTextInput
          image={montant}
          type='text'
          value={variableRemuneration}
          setValue={setVariableRemuneration}
          placeholder='Précisez la rémunération variable'
        />
      );
    case 'Autre':
      return (
        <CustomTextInput
          image={document}
          type='text'
          value={autreRemuneration}
          setValue={setAutreRemuneration}
          placeholder='Précisez'
        />
      );
    default:
      return null;
    }
  };

  return (
    <div className='mb-10'>
      <h3 className='text-xl font-bold'>06.
      Rémunération de la prestation de services</h3>
      <h4 className='text-lg'>Comment est déterminée la rémunération ?</h4>
      <div>
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={remunerationType}
          setValue={setRemunerationType}
          items={[
            { label: 'Rémunération fixe correspondant à un montant forfaitaire', value: 'Rémunération fixe correspondant à un montant forfaitaire' },
            { label: 'Paiement sur la base d\'un taux horaire, journalier ou mensuel', value: 'Paiement sur la base d\'un taux horaire, journalier ou mensuel' },
            { label: 'Variable', value: 'Variable' },
            { label: 'Autre', value: 'Autre' },
          ]}
        />
      </div>
      {renderAdditionalFields()}
      <div>
        <p>Quand intervient le paiement de la rémunération ?</p>
        <CustomTextInput
          image={duration}
          type='text'
          value={intervientTime}
          setValue={setIntervientTime}
          placeholder='Précisez'
        />
      </div>
      <div>
        <p>Par quels moyens la prestation est payée ?</p>
        <CustomTextInput 
          image={document}
          type='text'
          value={prestationMoyen}
          setValue={setPrestationMoyen}
          placeholder='Précisez'
        />
        <div className='flex gap-5 mt-5'>
          <Button
            color='info'
            variant='outlined'
            onClick={() => {
              if (currentStep > 1) {
                setCurrentStep(currentStep - 1);
              }
            }}
          >
            {'< Retour'}
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={() => {
              setCurrentStep(currentStep + 1);
            }}
          >
          Continuer
          </Button>
        </div>
      </div>
    </div>
  );
}
function Q7(props) {
  const {
    currentStep,
    setCurrentStep,
    hasInsurance,
    setHasInsurance,
    insuranceStatus,
    setInsuranceStatus,
    insuranceDetails,
    setInsuranceDetails,
  } = props;

  const renderInsuranceStatusFields = () => {
    if (hasInsurance === 'Non') {
      return (
        <div>
          <CustomRadioBox
            row={false}
            isBlue={true}
            value={insuranceStatus}
            setValue={setInsuranceStatus}
            items={[
              { label: 'Il est en cours d\'obtention d\'une assurance et s\'engage à être prochainement assuré', value: 'en cours d\'obtention' },
              { label: 'Il n\'aura pas d\'assurance', value: 'pas d\'assurance' },
            ]}
          />
          {insuranceStatus === 'en cours d\'obtention' && (
            <CustomTextInput
              image={document}
              type='text'
              value={insuranceDetails}
              setValue={setInsuranceDetails}
              placeholder='Précisez les détails de l\&apos;assurance'
            />
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <div className='mb-10'>
      <h1 className='text-xl font-bold'>07.
      Assurance</h1>
      <h4 className='text-lg'>Le prestataire a-t-il une assurance responsabilité civile professionnelle (couvrant sa mission) ?
      </h4>
      <div>
        <CustomRadioBox
          row={false}
          isBlue={true}
          value={hasInsurance}
          setValue={setHasInsurance}
          items={[
            { label: 'Oui', value: 'Oui' },
            { label: 'Non', value: 'Non' },
          ]}
        />
      </div>
      {renderInsuranceStatusFields()}
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
          Continuer
        </Button>
      </div>
    </div>
  );
}
function Q8(props) {
  const {
    currentStep,
    setCurrentStep,
    isSubcontractingAllowed,
    setIsSubContractingAllowed,
  } = props;
  return (
    <div className='my-5'>
      <h1 className='text-xl font-bold'>08.Sous-traitance de la mission</h1>
      <p className='text-lg'>Le prestataire peut-il sous-traiter (sans demander l&apos;accord du client) tout ou partie de sa mission ?</p>
      <CustomRadioBox
        row={false}
        isBlue={true} 
        value={isSubcontractingAllowed}
        setValue={setIsSubContractingAllowed}
        items={[
          { label: 'Oui', value: 'Oui' },
          { label: 'Non', value: 'Non' },
        ]}
      />
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            if (currentStep > 1) {
              setCurrentStep(currentStep - 1);
            }
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
          Continuer
        </Button>
      </div>
    </div>
  );
}
function Q9(props) {
  const {
    currentStep,
    setCurrentStep,
    confidentialite,
    setConfidentialite,
    valableDuration,
    setValableDuration,
  } = props;

  return (
    <div className='my-5'>
      <h1 className='text-xl font-bold'>09.Confidentialité</h1>
      <p className='text-lg'>Souhaitez-vous prévoir une clause de confidentialité ?</p>
      <CustomRadioBox
        row={false}
        isBlue={true}
        value={confidentialite}
        setValue={setConfidentialite}
        items={[
          { label: 'Oui', value: 'yes' },
          { label: 'Non', value: 'non' },
        ]}
      />
      {confidentialite === 'yes' ? (
        <div>
          <p>Pour quelle durée l&apos;obligation de confidentialité restera valable après la fin du contrat ?</p>
          <CustomTextInput
            image=''
            type='text'
            value={valableDuration}
            setValue={setValableDuration}
            placeholder='Veuillez préciser'
          />
        </div>
      ) : null}
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            if (currentStep > 1) {
              setCurrentStep(currentStep - 1);
            }
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
          Continuer
        </Button>
      </div>
    </div>
  );
}

function Q10(props) {
  const {
    currentStep,
    setCurrentStep,
    villeName,
    setVilleName,
    signatureName,
    setSignatureName,
  } = props;

  return (
    <div className='mb-5'>
      <h1 className='text-xl font-bold'>10.Lieu et date de signature du contrat</h1>
      <p className='text-lg'>Nom de la ville où le contrat est signé</p>
      <CustomTextInput
        image={map}
        type='text'
        value={villeName}
        setValue={setVilleName}
        placeholder='Précisez les modalités de résiliation'
      />
      <CustomTextInput
        image={usericon}
        type='text'
        value={signatureName}
        setValue={setSignatureName}
        placeholder='Précisez les modalités de résiliation'
      />
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            if (currentStep > 1) {
              setCurrentStep(currentStep - 1);
            }
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
          Continuer
        </Button>
      </div>
    </div>
  );
}
function Q11(props) {
  const {
    currentStep,
    setCurrentStep,
    PdfEmail,
    setPdfEmail,
    handleClick
  } = props;

  return (
    <div className='mb-5'>
      <h1>Step 11</h1>
      <p>Le contrat est-il signé ?</p>
      <CustomTextInput 
        image={document}
        type='text'
        value={PdfEmail}
        setValue={setPdfEmail}
        placeholder='Précisez les modalités de résiliation'
      />
      <div className='flex gap-5 mt-5'>
        <Button
          color='info'
          variant='outlined'
          onClick={() => {
            if (currentStep > 1) {
              setCurrentStep(currentStep - 1);
            }
          }}
        >
          {'< Retour'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={handleClick}
        >
          Enregistrer
        </Button>
      </div>
    </div>
  );
}

export default ContratPrestationForm;
