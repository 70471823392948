import React, { useState } from 'react';
import StepsProgress from '../components/slider/slider';
import SarlHeader from '../sarlHeader/sarlHeader';
import Step1 from './step1/step1';
import Step2 from './step2/step2';

export default function Steps({type, title,imageSrc}) {
  const [steps, setSteps] = useState(0);
  const stepsProgress = () => {
    switch (steps) {
    case 0:
      return <SarlHeader type={type} title={title} imageSrc={imageSrc} addStep={() => setSteps(steps + 1)} />;
    case 1:
      return <Step1 onClick={() => setSteps(steps + 1)} />;
    case 2:
      return <Step2 onClick={() => setSteps(0)} />;
    default:
      return <SarlHeader type={type}/>;
    }
  };
  return (
    <div>
      {steps !== 0 && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: 150,
          }}
        >
          <StepsProgress
            marks
            defaultValue={1}
            addParams={{
              sx: { width: '80%' },
              value: steps,
            }}
            minValue={0}
            maxValue={15}
            disabled
          />
        </div>
      )}
      {stepsProgress()}
    </div>
  );
}
